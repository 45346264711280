/**
 * Created by andrey on 11.03.2020.
 */
 
if (["android", "amazon", "pliega", "rustore", "gpg"].indexOf(cleverapps.config.source || cleverapps.config.requestParamSource) !== -1) {
    cc.sys.language = AppActivityPlugin.getDefaultLanguage();
    cc.sys.installer = AppActivityPlugin.getInstaller() || "empty";
    cc.sys.totalMemory = AppActivityPlugin.getTotalMemory() || 0;
    cc.sys.os = cc.sys.OS_ANDROID;
    cc.sys.INSTALLER_GOOGLE_PLAY = "com.android.vending";

    if ((cleverapps.config.source || cleverapps.config.requestParamSource) === "gpg") {
        cc.sys.isMobile = false;
    }

    cleverapps.config.androidStaging = cleverapps.config.androidStaging || cleverapps.getRequestParameters().androidStaging;

    if (cleverapps.config.androidStaging) {
        cc.sys.isNativeFlight = !cleverapps.config.androidStagingLocal;
    }

    if (typeof LocalStoragePlugin !== "undefined"
        && LocalStoragePlugin.isInitialized()
        && typeof LocalStoragePlugin.getItem === "function"
        && typeof LocalStoragePlugin.setItem === "function"
        && typeof LocalStoragePlugin.removeItem === "function") {
        cc.sys.localStorage = {
            getItem: function (key) {
                return this.data[key];
            },

            setItem: function (key, value) {
                LocalStoragePlugin.setItem(key, value);
                this.data[key] = value;
            },

            removeItem: function (key) {
                LocalStoragePlugin.removeItem(key);
                delete this.data[key];
            },

            clear: function () {
                LocalStoragePlugin.clear();
                this.data = {};
            },

            getData: function () {
                return this.data;
            },

            init: function () {
                var data = LocalStoragePlugin.getData();
                try {
                    data = decodeURIComponent(data);
                } catch (e) {
                    console.log("error decode localstorage data", e);
                }
                this.data = JSON.parse(data);
            }
        };

        cc.sys.localStorage.init();
    }
}