/**
 * Created by vladislav on 9/7/2022
 */

var Apple = function (source) {
    Platform.call(this, source);
};

Apple.prototype = Object.create(Platform.prototype);
Apple.prototype.constructor = Apple;

Apple.prototype.pushSender = function () {
    return cleverapps.LocalPushes.IosWebViewSender;
};

Apple.prototype._inviteFriendsNative = function (options, callback) {
    this.onInviteFriendsListener = callback;

    var data = {
        title: options.title,
        message: options.message,
        link: this.getExternalUrl("res/applink.html")
    };

    data.action = "sendMessageAllVariants";
    webkitMessage("messages", data);
};

Apple.prototype.onInviteFriends = function (code) {
    if (this.onInviteFriendsListener) {
        this.onInviteFriendsListener(code);
        delete this.onInviteFriendsListener;
    }
};

Apple.prototype.needWindowForLogin = function () {
    return true;
};

Apple.prototype._callNative = function (options) {
    console.log("apple is here");
    if (options.method === "payments.connect") {
        PurchasesPlugin.connect();
    } else {
        console.log(options.method, PurchasesPlugin[options.method]);
    }
};