/**
 * Created by vladislav on 15/03/2023
 */

var DebugSlider = function () {
    this.slider = undefined;

    cleverapps.flags.on("toggle:debugInfo", this.toggle.bind(this));
};

DebugSlider.prototype.isActive = function () {
    return this.slider;
};

DebugSlider.prototype.remove = function () {
    if (this.slider) {
        this.slider.remove();
        this.label.remove();

        this.slider = undefined;
        this.label = undefined;
    }
};

DebugSlider.prototype.toggle = function (visible) {
    if (this.slider) {
        this.slider.style.visibility = visible ? "" : "hidden";
        this.label.style.visibility = visible ? "" : "hidden";
    }
};

DebugSlider.prototype.createTimeScaleSlider = function () {
    this.create({
        action: function (value) {
            cc.director.getScheduler().setTimeScale(value);
        },
        minValue: 0,
        maxValue: 1,
        step: 0.1,
        baseValue: 1
    });
};

DebugSlider.prototype.create = function (options) {
    this.remove();

    options.action(options.baseValue);

    var sliderInput = document.createElement("input");
    sliderInput.type = "range";
    sliderInput.min = options.minValue;
    sliderInput.max = options.maxValue;
    sliderInput.step = options.step;
    sliderInput.value = options.baseValue;

    sliderInput.oninput = function () {
        options.action(parseFloat(sliderInput.value));
        this.label.content.data = sliderInput.value;
    }.bind(this);

    this.slider = document.createElement("div");
    this.slider.classList.add("tool-slider");
    this.slider.appendChild(sliderInput);

    this.label = this.createLabel(sliderInput, options);
    this.slider.appendChild(this.label);

    var close = document.createElement("span");
    close.classList.add("tool-window-close");
    close.onclick = this.remove.bind(this);
    close.innerText = "\u2715";
    this.slider.appendChild(close);

    if (options.caption) {
        var caption = document.createElement("div");
        caption.classList.add("tool-caption");
        caption.innerText = options.caption;
        this.slider.appendChild(caption);
    }

    cc.container.appendChild(this.slider);

    this.toggle(true);
};

DebugSlider.prototype.createLabel = function (input, options) {
    var number = document.createElement("div");
    var numberContent = document.createTextNode(options.baseValue);
    number.appendChild(numberContent);
    number.style.zIndex = "10";

    number.content = numberContent;
    number.classList.add("tool-label");

    var setInputValue = function (value) {
        numberContent.data = value.toString();
        options.action(value);
        input.value = value;
    };

    number.addEventListener("click", function () {
        var value = parseFloat(window.prompt("Enter zoom from " + options.minValue + " to " + options.maxValue));
        if (value >= options.minValue && value <= options.maxValue) {
            setInputValue(value);
        } else {
            if (value < options.minValue) {
                setInputValue(options.minValue);
            }
            if (value > options.maxValue) {
                setInputValue(options.maxValue);
            }
        }
    });
    return number;
};