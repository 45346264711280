/**
 * Created by Aleksandr on 13.10.2022.
 */

cleverapps.PlingaSocial = function () {
    console.log("Choosing plinga");

    cleverapps.Social.call(this);
};

cleverapps.PlingaSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.PlingaSocial.prototype.constructor = cleverapps.PlingaSocial;

cleverapps.PlingaSocial.prototype.init = function (callback) {
    console.log("PlingaSocial init");
    var onFailure = function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.PLINGA_INIT_ERROR);
    };

    cleverapps.loadSdk("//s3.amazonaws.com/imgs3.plinga.de/general/easyXDM.min.js", {
        onSuccess: function () {
            console.log("easyXDM loaded");
            easyXDM.DomHelper.requiresJSON("//s3.amazonaws.com/imgs3.plinga.de/general/json2.min.js");
            cleverapps.loadSdk("//s3.amazonaws.com/imgs3.plinga.de/plingaRpc/plingaRpc.js", {
                onSuccess: function () {
                    plingaRpc.init(function () {
                        console.log("plingaRPC inited");

                        plingaRpc.setAllowFullscreen();
                        callback();
                    });
                },
                onFailure: onFailure
            });
        },
        onFailure: onFailure
    });
};

cleverapps.PlingaSocial.prototype.login = function () {
    plingaRpc.invite();
};

cleverapps.PlingaSocial.prototype.aboutUser = function (usersId, callback) {
    var isSingle = !Array.isArray(usersId);

    plingaRpc.getUsers(isSingle ? [usersId] : usersId, function (users) {
        var usersData = users.map(cleverapps.PlingaSocial.formatUserData);
        callback(isSingle ? usersData[0] : usersData);
    });
};

cleverapps.PlingaSocial.prototype.aboutMe = function (callback) {
    var info = plingaRpc.getOwner();
    callback(cleverapps.PlingaSocial.formatUserData(info));
};

cleverapps.PlingaSocial.prototype.listFriends = function (callback) {
    var list = plingaRpc.getFriends();
    var friends = list.map(cleverapps.PlingaSocial.formatUserData);
    callback(cleverapps.Social.CODE_SUCCEED, friends);
};

cleverapps.PlingaSocial.formatUserData = function (user) {
    return {
        id: user.uid,
        name: user.firstname + " " + user.lastname,
        first_name: user.firstname,
        picture: {
            data: {
                url: user.thumbnailurl
            }
        }
    };
};

cleverapps.PlingaSocial.prototype.inviteFriends = function (options, callback) {
    plingaRpc.invite(options.message);
    callback(cleverapps.Social.CODE_SUCCEED);
};

cleverapps.PlingaSocial.prototype.shareDialog = function (options, callback) {
    var data = {
        target: this.getUserID(),
        title: options.name,
        body: options.description,
        linktext: Messages.get("PlayNow"),
        pic: options.picture
    };

    plingaRpc.post(data, function (params) {
        console.log("post callback", params);
        if (params.globalError_ === false) {
            callback(cleverapps.Social.CODE_SUCCEED);
        } else {
            callback(cleverapps.Social.CODE_FAILED);
        }
    });
    callback(cleverapps.Social.CODE_SUCCEED);
};

cleverapps.PlingaSocial.prototype.sendMessage = function (options, callback) {
    console.log(options);
    plingaRpc.sendMessage({
        target: "userid of the receiver",
        title: "text, which is shown as title",
        body: "text of the post",
        pic: "link to a picture, which is shown in the post",
        linktext: "text to display for the link"
    }, callback);
};

cleverapps.PlingaSocial.prototype.getCode = function () {
    return cleverapps.PlingaSocial.CODE;
};

cleverapps.PlingaSocial.prototype.getAccessToken = function () {
    var getParams = cleverapps.getRequestParameters();
    return "PLINGA_" + getParams.sessionid + "_" + getParams.sessionkey;
};

cleverapps.PlingaSocial.prototype.isLoggedIn = function () {
    return plingaRpc.getOwner().guest !== true;
};

cleverapps.PlingaSocial.prototype.getUserID = function () {
    return plingaRpc.getOwner().uid;
};

cleverapps.PlingaSocial.CODE = "plinga";