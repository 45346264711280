/**
 * Created by Aleksandr on 15.09.2022.
 */

var ClipPreviewBar = function (options) {
    this.options = options;

    this.clipProps = this.options.clipConfig.properties;

    this.onHide = function () {};
    this.onShow = function () {};
    this.onResetTimer = function () {};
    this.onStopTimer = function () {};
};

ClipPreviewBar.prototype.replay = function () {
    ActiveClipScene.open(this.options);
};

ClipPreviewBar.prototype.download = function () {
    this.options.download = true;
    ActiveClipScene.open(this.options);
};

ClipPreviewBar.prototype.edit = function () {
    cleverapps.setUrlHash({ wysiwyg: true });
    window.location.reload();
};

ClipPreviewBar.prototype.changeLanguage = function (language) {
    cleverapps.setUrlHash({ lang: language });
    this.options.language = language;
    ActiveClipScene.open(this.options);
};

ClipPreviewBar.prototype.changeResolution = function (resolution) {
    cleverapps.setUrlHash({ res: resolution });
    this.options.resolutionName = resolution;
    ActiveClipScene.open(this.options);
};

ClipPreviewBar.prototype.resetTimer = function () {
    this.onResetTimer();
};

ClipPreviewBar.prototype.stopTimer = function () {
    this.onStopTimer();
};