/**
 * Created by vladislav on 23.12.2020
 */

var AdsTransition = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this.properties = properties;
        this.eventBus = eventBus;

        this._super();
        this.setAnchorPoint2();

        if (properties.isPreview || properties.listensEvent === Wysiwyg.NOT_SELECTED) {
            this.show();
            return;
        }

        eventBus.on(properties.listensEvent, function (options) {
            setTimeout(function () {
                if (properties.listensEvent === Wysiwyg.EVENTS.PROGRESS_CHANGED) {
                    if (options === 1) {
                        this.show();
                    }
                } else {
                    this.show();
                }
            }.bind(this), properties.delayAfterListenedEvent || 0);
        }.bind(this));
    },

    show: function () {
        if (!bundles[this.properties.spine.bundle] || !bundles[this.properties.spine.bundle].jsons[this.properties.spine.json]) {
            return;
        }

        var spine = new cleverapps.Spine(bundles[this.properties.spine.bundle].jsons[this.properties.spine.json]);
        spine.setAnimation(0, this.properties.spine.animation, false);
        if (this.properties.spine.idle) {
            var loop = this.properties.spine.looped && this.properties.duration;
            spine.addAnimation(0, this.properties.spine.idle, loop);
        }
        this.setContentSize2(spine.getContentSize());

        this.addChild(spine);
        spine.setPositionRound(this.width / 2, this.height / 2);

        if (this.properties.sound && this.properties.sound.sound !== Wysiwyg.NOT_SELECTED) {
            cleverapps.audio.playSound(bundles[this.properties.sound.bundle].urls[this.properties.sound.sound]);
        }

        var trigger = function () {
            if (this.properties.isPreview) {
                spine.setVisible(false);
            }

            var generatesEvents = this.properties.generatesEvents || [];
            generatesEvents.forEach(function (event) {
                this.eventBus.trigger(event);
            }.bind(this));
        }.bind(this);

        if (this.properties.duration) {
            setTimeout(function () {
                trigger();
            }, this.properties.duration);
        } else {
            var count = this.properties.spine.idle ? 0 : 1;
            spine.setCompleteListener(function () {
                if (count++) {
                    trigger();
                }
            });
        }
    }
});

AdsTransition.PROPERTIES = [{
    name: "generatesEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: []
}, {
    name: "listensEvent", type: "string", items: cleverapps.values(Wysiwyg.EVENTS).concat([Wysiwyg.NOT_SELECTED]), value: Wysiwyg.NOT_SELECTED
}, {
    name: "delayAfterListenedEvent", type: "number"
}, {
    name: "delay", type: "number", disabled: true
}, {
    name: "duration", type: "number", placeHolderText: "milliseconds"
}, {
    name: "spine",
    type: "spine",
    bundles: ["ads_transition", "ads_transition_daily_task", "ads_transition_scenery"],
    value: {
        json: "spiral_filling", bundle: "ads_transition", skin: "default", animation: "close", looped: false 
    },
    bubbleUp: true
}, {
    name: "sound", type: "sound", bundles: ["ads_transition", "ads_background_music", "ads_sfx_common"]
}];