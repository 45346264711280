/**
 * Created by r4zi4l on 12.07.2021
 */

var Person = cleverapps.Spine.extend({
    ctor: function (options) {
        options = Person.ParseOptions(options);

        this.role = options.role;

        this.person = PersonsLibrary.getPerson(this.role);

        if (!this.person) {
            throw "Person is undefined - " + this.role;
        }

        this._super(this.person.json);

        this.setOrientation(options.orientation);

        if (!options.skin && this.hasSkin("regular")) {
            options.skin = "regular";
        }
        if (options.skin && this.hasSkin(options.skin)) {
            this.setSkin(options.skin);
        }

        this.emotion = options.emotion;
        this.setAnimation(0, this.emotion, true);
    },

    setSpeaking: function (isSpeaking, interrupt) {
        if (!this.hasAnimation("talk")) {
            return;
        }

        if (this.isSpeaking === isSpeaking) {
            return;
        }

        this.isSpeaking = isSpeaking;

        if (this.isSpeaking) {
            this.setAnimation(0, "talk", false);
            this.setCompleteListener(function () {
                this.setAnimation(0, this.isSpeaking ? "talk" : this.emotion, false);
            }.bind(this));
        } else if (interrupt) {
            this.setAnimation(0, this.emotion, true);
            this.setCompleteListener(function () {});
        } else {
            this.setCompleteListenerOnce(function () {
                this.setAnimation(0, this.emotion, true);
            }.bind(this));
        }
    },

    setActive: function (isActive) {
        if (this.isActive === isActive) {
            return;
        }

        this.isActive = isActive;

        this.setLocalZOrder(this.isActive ? -1 : -2);
    },

    getRole: function () {
        return this.role;
    },

    getOrientation: function () {
        return this.orientation;
    },

    setOrientation: function (orientation) {
        this.orientation = orientation;

        if (this.orientation === "right") {
            this.setScaleX(-1);
        } else {
            this.setScaleX(1);
        }
    },

    show: function (callback) {
        var styles = cleverapps.styles.Person;

        this.stopAllActions();
        this.setPositionRound(this.getOrientation() === "left" ? styles.hidden.left : styles.hidden.right, this.y);
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, this.originalPosition).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                if (callback) {
                    callback();
                }
            })
        ));
    },

    hide: function () {
        this.removeFromParent();
    }
});

Person.ParseOptions = function (options) {
    if (typeof options === "string") {
        options = {
            role: options
        };
    }

    options.emotion = options.emotion || "idle";
    options.orientation = options.orientation || "left";

    return options;
};

cleverapps.styles.Person = {
    width: 600,
    height: 800,

    hidden: {
        left: { align: "left", dx: -300 },
        right: { align: "right", dx: 300 }
    }
};
