/**
 * Created by vladislav on 9/6/2022
 */

var OKPlatform = function (source) {
    Platform.call(this, source || "web_ok");
};

OKPlatform.prototype = Object.create(Platform.prototype);
OKPlatform.prototype.constructor = OKPlatform;

OKPlatform.prototype.getLocalStoragePreffix = function () {
    return "_OK";
};

OKPlatform.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};

OKPlatform.prototype.updateSize = function () {
    cleverapps.platform.listeners.getPageInfo = function (result, data) {
        var height;
        try {
            height = JSON.parse(data).innerHeight;
        } catch (e) {
            height = window && window.innerHeight || document && document.body && document.body.clientHeight || 800;
        }
        var h = height - 75;
        if (h < 600) {
            h = 600;
        }
        FAPI.UI.setWindowSize(760, h);
        cc.view.setFrameSize(cc.view.getFrameSize().width, h);
    };

    [0, 5, 10, 20, 60].forEach(function (timeout) {
        setTimeout(FAPI.UI.getPageInfo, timeout * 1000);
    });
};

OKPlatform.prototype.init = function (callback) {
    cleverapps.loadSdk("//api.ok.ru/js/fapi5.js", {
        onSuccess: this.initFAPI.bind(this, true, callback),
        onFailure: this.initFAPI.bind(this, false, callback)
    });
};

OKPlatform.prototype.initFAPI = function (sdkLoaded, callback) {
    var callCallback = cleverapps.once(function () {
        this.initApiCallback();
        this.updateSize();
        this.initPushNotifications();

        callback();
    }.bind(this));

    this.listeners = {};

    if (sdkLoaded && typeof FAPI !== "undefined") {
        this.onInitSuccess(callCallback);
    } else {
        this.onInitFailure(callCallback);
    }
};

OKPlatform.prototype.initApiCallback = function () {
    window.API_callback = function (method, code, data) {
        console.log("Received API callback " + method, code, data);

        if (this.listeners[method]) {
            var listener = this.listeners[method];
            this.listeners[method] = undefined;
            listener.call(this, code, data);
        }
    }.bind(this);
};

OKPlatform.prototype.onInitSuccess = function (callback) {
    var rParams = FAPI.Util.getRequestParameters();

    if (rParams.mob_platform === "android") {
        cc.sys.os = cc.sys.OS_ANDROID;
    }

    var pushCode = cleverapps.getRequestParameters("?" + rParams.custom_args).push_code;
    var pushId = rParams.custom_args;

    var localPush = pushCode ? cleverapps.LocalPushes.GetTypeByCode(pushCode)
        : cleverapps.LocalPushes.GetTypeById(pushId);

    if (localPush) {
        var event = pushCode && cleverapps.EVENTS.STATS.PUSHES.CLICK_ANDROID;
        cleverapps.localPushes.logClickEvent(localPush.code, event);

        if (!pushCode && pushId && this.source === "mobile_ok") {
            cleverapps.eventLogger.logEvent(cc.sys.os === cc.sys.OS_ANDROID
                ? cleverapps.EVENTS.STATS.PUSHES.ANDROID_OK_CLICK : cleverapps.EVENTS.STATS.PUSHES.MOBILE_OK_CLICK);
        }
    }

    FAPI.init(rParams.api_server, rParams.apiconnection, function () {
        callback();
    }, function (reason) {
        this.onInitFailure.bind(this, callback, reason);
    }.bind(this));
};

OKPlatform.prototype.onInitFailure = function (callback, reason) {
    if (typeof FAPI === "undefined") {
        FAPI = cleverapps.FakeFAPI;
    }

    callback();

    if (reason === "timeout") {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_TIMEOUT);
    } else {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_SCRIPT_ERROR);
    }
};

OKPlatform.prototype.createFAPICallback = function (callback) {
    return function (code, data, error) {
        if (error && error.error_code && parseInt(error.error_code) === 102) {
            this.initFAPI(true, function () {
                cleverapps.throwAsync("OK init after session expired " + JSON.stringify(FAPI.Util.getRequestParameters()));

                callback(code, data, error);
            });
        } else {
            callback(code, data, error);
        }
    }.bind(this);
};

OKPlatform.prototype.isMessagesAllowed = function (callback) {
    FAPI.Client.call({
        method: "group.isMessagesAllowed",
        gid: cleverapps.config.ok.groupId
    }, this.createFAPICallback(function (code, data) {
        if (code !== "ok") {
            callback(false);

            return;
        }

        callback(data.allowed);
    }));
};

OKPlatform.prototype.canJoinGroup = function (callback) {
    this.isInGroup(function (inGroup) {
        if (inGroup) {
            this.isMessagesAllowed(function (allowed) {
                callback(!allowed);
            });
        } else {
            callback(true);
        }
    }.bind(this));
};

OKPlatform.prototype.followOfficialPage = function () {
    this.canJoinGroup(function (canJoin) {
        if (!canJoin) {
            return;
        }

        cleverapps.platform.listeners.joinGroup = function (code, data) {
            var eventName = cleverapps.EVENTS.STATS.OK.JOIN_GROUP + data;
            cleverapps.eventLogger.logEvent(eventName.substr(0, 40));
        };

        FAPI.UI.joinGroup(cleverapps.config.ok.groupId, true);
    });
};

OKPlatform.prototype.isInGroup = function (callback) {
    FAPI.Client.call({
        method: "group.getUserGroupsV2"
    }, this.createFAPICallback(function (code, data) {
        var groups = code === "ok" && data && data.groups || [];

        var inGroup = false;
        for (var i = 0; i < groups.length; i++) {
            var group = groups[i];

            if (group.groupId === cleverapps.config.ok.groupId) {
                inGroup = true;

                break;
            }
        }

        callback(inGroup);
    }));
};

OKPlatform.prototype.initPushNotifications = function () {
};

OKPlatform.prototype.showBannerAd = function (callback) {
    cleverapps.platform.listeners.requestBannerAds = cleverapps.once(function () {
        cleverapps.platform.listeners.showBannerAds = cleverapps.once(function (code, data) {
            if (code !== "ok" || data !== "true") {
                callback({ code: code, data: data });
                return;
            }

            Resolution.setContainerPadding({
                bottom: cc.sys.os === cc.sys.OS_ANDROID && cc.sys.isInApp ? MobileOK.AD_BANNER_HEIGHT_ANDROID : MobileOK.AD_BANNER_HEIGHT
            });

            var requestBannerAds = function (code, data) {
                cleverapps.platform.listeners.requestBannerAds = requestBannerAds;

                if (code === "ok" && data === "ad_shown") {
                    cleverapps.bannerAd.onPaidEvent();
                }

                if (data === "hidden_by_user") {
                    cleverapps.bannerAd.hide();
                }
            };

            cleverapps.platform.listeners.requestBannerAds = requestBannerAds;

            callback();
        });

        FAPI.invokeUIMethod("showBannerAds", "bottom");
    });

    FAPI.invokeUIMethod("requestBannerAds");
};

OKPlatform.prototype.hideBannerAd = function (callback) {
    cleverapps.platform.listeners.hideBannerAds = cleverapps.once(function (code, data) {
        if (code !== "ok") {
            callback({ method: "hideBannerAds", code: code, data: data });
            return;
        }

        cleverapps.platform.listeners.isBannerAdsVisible = cleverapps.once(function (code, data) {
            if (code !== "ok") {
                callback({ method: "isBannerAdsVisible", code: code, data: data });
                return;
            }

            if (data !== "false") {
                callback("banner still shown");
                return;
            }

            Resolution.setContainerPadding();

            cleverapps.platform.listeners.requestBannerAds = undefined;
            cleverapps.platform.listeners.showBannerAds = undefined;
            cleverapps.platform.listeners.hideBannerAds = undefined;
            cleverapps.platform.listeners.isBannerAdsVisible = undefined;

            callback();
        });

        FAPI.invokeUIMethod("isBannerAdsVisible");
    });

    FAPI.invokeUIMethod("hideBannerAds");
};

cleverapps.FakeFAPI = {
    UI: {
        getPageInfo: function () {},
        showInvite: function () {},
        showNotification: function () {},
        postMediatopic: function () {},
        setWindowSize: function () {},
        showPayment: function () {},
        joinGroup: function () {},
        getPushNotificationsStatus: function () {}
    },

    invokeUIMethod: function () {},

    Util: {
        getRequestParameters: function () {
            return {};
        }
    },

    Client: {
        call: function () {}
    }
};