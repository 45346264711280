/**
 * Created by Andrey Popov on 1/21/21.
 */

var Assets = function (wysiwyg) {
    this.selectedTab = Assets.MODES.COMPONENTS;
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.components = Object.keys(Assets.COMPONENTS).filter(function (name) {
        var componentConfig = Assets.COMPONENTS[name];
        if (componentConfig.type && cleverapps.config.type !== componentConfig.type
            || componentConfig.subtype && cleverapps.config.subtype !== componentConfig.subtype
            || componentConfig.meta && cleverapps.meta.getType() !== componentConfig.meta) {
            return false;
        }
        return true;
    }).map(function (name) {
        var assetComponent = new AssetComponent(name, Assets.COMPONENTS[name]);
        assetComponent.on("componentMovedToScene", function (parentId, position) {
            this.wysiwyg.addComponent(assetComponent, parentId, position);
        }.bind(this), this);
        return assetComponent;
    }.bind(this));

    this.scripts = [];
    this.load();
};

Assets.prototype = Object.create(cleverapps.EventEmitter.prototype);
Assets.prototype.constructor = Assets;

Assets.prototype.load = function () {
    cleverapps.RestClient.get(
        "/wysiwyg/scripts", 
        {}, 
        function (data) {
            this.scripts = Object.keys(data).map(function (scriptFileName) {
                var scriptData = this.createScriptData(data[scriptFileName], scriptFileName);
                var scriptComponent = new ScriptComponent(scriptFileName, scriptData);
                scriptComponent.on("scriptMovedToScene", function (parentId) {
                    this.wysiwyg.addScript(scriptComponent, parentId);
                }.bind(this), this);

                return scriptComponent;
            }.bind(this));
            this.scripts.unshift(new ScriptComponent("add_new_script", {
                editCallback: function () {
                    var scriptName = prompt("enter file name for the new script:");
                    this.saveScript(scriptName.replace(" ", "_"), scriptName, "", "");
                }.bind(this)
            }));
            this.trigger("showAssets");
        }.bind(this),
        function (err) {
            console.log(err);
        }
    );
};

Assets.prototype.createScriptData = function (scriptFileContent, scriptFileName) {
    var scriptLines = scriptFileContent.split("\n");
    var scriptName = scriptLines.filter(function (line) {
        return line.indexOf("@name") !== -1;
    }).map(function (line) {
        return line.split("@name")[1].trim();
    });

    if (!scriptFileName) {
        scriptFileName = scriptName[0];
    }

    var scriptParameters = scriptLines.filter(function (line) {
        return line.indexOf("@parameter") !== -1;
    }).map(function (line) {
        return line.split("@parameter")[1].trim();
    }).join("\n");

    return {
        name: scriptFileName,
        exec: scriptFileContent.split("*/\n")[1],
        parameters: scriptParameters,
        editCallback: function () {
            this.wysiwyg.wysiwygPreview.trigger("clearComponents");
            this.wysiwyg.wysiwygPreview.trigger("showScript", scriptFileName);
        }.bind(this),
        deleteCallback: function () {
            this.deleteScript(scriptFileName);
        }.bind(this)
    };
};

Assets.prototype.saveScript = function (fileName, name, exec, parameters) {
    var fileContent = "/**\n * @name " + name;
    if (parameters.length > 0) {
        parameters.split("\n").map(function (param) {
            var parts = param.split(" ");
            var res = {
                name: parts[0].replace(":", "").trim(),
                type: parts[1].trim()
            };
            if (parts.length > 2 && parts[2].startsWith("[") && parts[2].endsWith("]")) {
                res.items = parts[2].replace("[", "").replace("]", "");
            }
            if (parts.length >= 3 && parts[3] === "multiselect") {
                res.multiselect = true;
            }
            return res;
        }).filter(function (param) {
            return Object.keys(InspectorView.EDITORS).indexOf(param.type) !== -1;
        }).forEach(function (param) {
            fileContent += "\n * @parameter " + param.name + ": " + param.type;
            if (param.items) {
                fileContent += " [" + param.items + "]";
            }
            if (param.multiselect) {
                fileContent += " multiselect";
            }
        });
    }
    fileContent += "\n */\n" + exec;

    cleverapps.RestClient.post("/wysiwyg/scripts/edit", {
        name: fileName,
        content: fileContent
    }, this.load.bind(this, undefined));
};

Assets.prototype.deleteScript = function (fileName) {
    cleverapps.RestClient.post("/wysiwyg/scripts/remove", {
        name: fileName
    }, this.load.bind(this, undefined));
};

Assets.prototype.onTabChanged = function (tab) {
    this.selectedTab = tab;
    this.trigger("showAssets");
};

Assets.MODES = {
    COMPONENTS: "Components",
    SCRIPTS: "Scripts"
};

Assets.GROUPS = {
    SIMPLE: "Simple",
    WITHPROGRESS: "With progress",
    GAMEBLOCKS: "Game blocks",
    GAMESPECIFIC: "Game specific"
};

Assets.COMPONENTS = {
    AdsSprite: {
        ctor: function () {
            return AdsSprite;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Sprite",
            icon: bundles.wysiwyg.frames.adssprite
        }
    },
    AdsAdHoc: {
        ctor: function () {
            return AdsAdHoc;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Ad Hoc",
            icon: bundles.wysiwyg.frames.adssprite
        }
    },
    AdsScale9Sprite: {
        ctor: function () {
            return AdsScale9Sprite;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Scale 9 sprite",
            icon: bundles.wysiwyg.frames.adsscale9sprite
        }
    },
    AdsSpine: {
        ctor: function () {
            return AdsSpine;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Spine",
            icon: bundles.wysiwyg.frames.adsspine
        }
    },
    AdsText: {
        ctor: function () {
            return AdsText;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Text",
            icon: bundles.wysiwyg.frames.adstext

        }
    },
    AdsSound: {
        ctor: function () {
            return AdsSound;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Sound",
            icon: bundles.wysiwyg.frames.adssound
        }
    },
    AdsContainer: {
        ctor: function () {
            return AdsContainer;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Container",
            icon: bundles.wysiwyg.frames.adscontainer
        }
    },
    AdsLayerBlur: {
        ctor: function () {
            return AdsLayerBlur;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "LayerBlur",
            icon: bundles.wysiwyg.frames.adslayerblur
        }
    },

    AdsTree: {
        ctor: function () {
            return AdsTree;
        },
        group: Assets.GROUPS.WITHPROGRESS,
        preview: {
            caption: "Spine with stages",
            icon: bundles.wysiwyg.frames.adstree
        }
    },
    AdsCounter: {
        ctor: function () {
            return AdsCounter;
        },
        group: Assets.GROUPS.WITHPROGRESS,
        preview: {
            caption: "Counter",
            icon: bundles.wysiwyg.frames.adscounter
        }
    },
    AdsAgeCounter: {
        ctor: function () {
            return AdsAgeCounter;
        },
        group: Assets.GROUPS.WITHPROGRESS,
        preview: {
            caption: "Age counter",
            icon: bundles.wysiwyg.frames.adsage
        }
    },
    AdsScale: {
        ctor: function () {
            return AdsScale;
        },
        group: Assets.GROUPS.WITHPROGRESS,
        preview: {
            caption: "Scale",
            icon: bundles.wysiwyg.frames.adsscale
        }
    },
    AdsProgressBar: {
        ctor: function () {
            return AdsProgressBar;
        },
        group: Assets.GROUPS.WITHPROGRESS,
        preview: {
            caption: "Progress bar",
            icon: bundles.wysiwyg.frames.adsprogressbar
        }
    },
    AdsTransition: {
        ctor: function () {
            return AdsTransition;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Transition",
            icon: bundles.wysiwyg.frames.adstransition
        }
    },
    AdsShadowLayer: {
        ctor: function () {
            return AdsShadowLayer;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "ShadowLayer",
            icon: bundles.wysiwyg.frames.adsshadowlayer
        }
    },
    AdsFireworks: {
        ctor: function () {
            return AdsFireworks;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Fireworks",
            icon: bundles.wysiwyg.frames.adsfireworks
        }
    },
    AdsLogo: {
        ctor: function () {
            return AdsLogo;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Logo",
            icon: bundles.wysiwyg.frames.adslogo
        }
    },
    AdsPlayButton: {
        ctor: function () {
            return AdsPlayButton;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Play button",
            icon: bundles.wysiwyg.frames.adsplay
        }
    },
    AdsQuestionnaire: {
        ctor: function () {
            return AdsQuestionnaire;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Questionnaire",
            icon: bundles.wysiwyg.frames.adsquestionnaire
        }
    },
    AdsGame: {
        ctor: function () {
            return AdsGame;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Snapshot",
            icon: bundles.wysiwyg.frames.adsgame
        }
    },
    AdsPuzzle15: {
        ctor: function () {
            return AdsPuzzle15;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Puzzle 15",
            icon: bundles.wysiwyg.frames.adspuzzle15
        }
    },
    AdsMenu: {
        ctor: function () {
            return AdsMenu;
        },
        group: Assets.GROUPS.GAMEBLOCKS,
        preview: {
            caption: "Menu",
            icon: bundles.wysiwyg.frames.adsmenu
        }
    },
    AdsFinger: {
        ctor: function () {
            return AdsFinger;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Finger",
            icon: bundles.wysiwyg.frames.adsfinger
        }
    },
    AdsExclamations: {
        ctor: function () {
            return AdsExclamations;
        },
        group: Assets.GROUPS.GAMESPECIFIC,
        type: "board",
        preview: {
            caption: "Exclamations",
            icon: bundles.wysiwyg.frames.adsexclamations
        }
    },
    AdsVideo: {
        ctor: function () {
            return AdsVideo;
        },
        group: Assets.GROUPS.SIMPLE,
        preview: {
            caption: "Video",
            icon: bundles.wysiwyg.frames.adsvideo
        }
    },
    AdsMap2d: {
        ctor: function () {
            return AdsMap2d;
        },
        group: Assets.GROUPS.GAMESPECIFIC,
        type: "merge",
        preview: {
            caption: "Map2d",
            icon: bundles.wysiwyg.frames.adsmap2d
        }
    }
};