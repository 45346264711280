/**
 * Created by Oleg on 26/02/18
 */

var BandButton = cc.Node.extend({
    ctor: function (options) {
        this._super();

        var styles = cleverapps.styles.BandButton;

        this.options = options;

        var contentSize = cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL && styles.mobileSize
            ? styles.mobileSize
            : styles;
        this.setAnchorPoint2();
        this.setContentSize2(contentSize);
        this.setLocalZOrder(3);

        this.updatePosition();

        var bg = cleverapps.UI.createScale9Sprite(bundles.buttons_main.frames.scene_exit_button, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(contentSize);
        this.addChild(bg);
        bg.setPositionRound(this.width / 2, this.height / 2);

        var icon = new cc.Sprite(bundles.buttons_main.frames.close_band_icon);
        this.addChild(icon);
        icon.setPositionRound(styles.icon);

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, function () {
            this.action();
        }.bind(this));
    },

    getDebugId: function () {
        return "BandButton";
    },

    updatePosition: function () {
        var styles = cleverapps.styles.BandButton;

        this.setPositionRound(cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL && styles.mobilePosition
            ? styles.mobilePosition
            : styles.position);
    },

    action: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);
        this.options.onClicked();
    },

    hide: function (silent) {
        this.stopAllActions();

        if (silent) {
            this.setPositionRound(this.hideHandler.hidePosition);
            this.setVisible(false);
            return;
        }

        this.runAction(new cc.Sequence(
            this._animate("close"),
            new cc.Hide()
        ));
    },

    show: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.Show(),
            this._animate("open")
        ));
    },

    _animate: function (direction) {
        var easing = direction === "open" ? cc.easeBackOut(10) : cc.easeBackIn(10);
        var target = direction === "open" ? this.hideHandler.originalPosition : this.hideHandler.hidePosition;
        return new cc.MoveTo(0.3, target).easing(easing);
    }
});

cleverapps.styles.BandButton = {
    width: 85,
    height: 190,

    position: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: 10 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 25 }
    }
};
