/**
 * Created by vladislav on 9/6/2022
 */

var PlatformManager = {
    create: function () {
        var platforms = {
            microsoft: Microsoft,
            msstart: MSStart,
            gdcom: GDCom,
            instant: Instant,
            mobile_ok: MobileOK,
            web_ok: OKPlatform,
            mobile_mm: MobileMyMailRu,
            web_mm: MyMailRu,
            mobile_vk: MobileVk,
            web_vk: VKPlatform,
            sp_mbga: SPMobage,
            mbga: Mobage,
            draugiem: Draugiem,
            fotostrana: FotoStrana,
            cleverapps: CleverApps,
            yandex: Yandex,
            macos: MacOS,
            ios: IOS,
            gpg: GPG,
            android: AndroidPlatform,
            pliega: Pliega,
            plng: Plinga,
            kongregate: Kongregate,
            rustore: Rustore,
            amazon: Amazon,
            mygames: Mygames,
            ios_ch: IOSCh,
            huzcom: Huzcom,
            facebook: FacebookCanvas,
            xiaomi: Xiaomi,
            pwa: PWA,
            test: TestPlatform
        };

        var source = this.getSource();

        console.log(source);

        var PlatformClass = platforms[source] || FacebookCanvas;
        return new PlatformClass();
    },

    getSource: function () {
        var existParameterByName = function (name) {
            return window.location.href.indexOf(name) !== -1;
        };

        if ((cleverapps.config.source === "msstart" || cleverapps.config.requestParamSource === "msstart")
            && !existParameterByName("msstart_sdk_init")) {
            return "pwa";
        }

        if (cleverapps.config.source) {
            return cleverapps.config.source;
        }

        if (["android", "amazon", "ios", "plng", "pliega", "rustore", "ios_ch", "gdcom", "mygames", "kongregate", "msstart", "microsoft"].indexOf(cleverapps.config.requestParamSource) !== -1) {
            return cleverapps.config.requestParamSource;
        }

        if (cleverapps.config.debugMode && cleverapps.config.requestParamSource === "plinga") {
            return "plng";
        }

        if (cc.sys.isNative) {
            if (cc.sys.os === cc.sys.OS_ANDROID) {
                return "android";
            } if (cc.sys.os === cc.sys.OS_IOS) {
                return "ios";
            } if (cc.sys.os === cc.sys.OS_OSX) {
                return "macos";
            }
        }

        if (existParameterByName("msstart_sdk_init")) {
            return "msstart";
        }

        if (existParameterByName("dr_auth_code")) {
            return "draugiem";
        }

        if (existParameterByName("logged_user_id")) {
            if (existParameterByName("mob=true")) {
                return "mobile_ok";
            }
            return "web_ok";
        }

        if (existParameterByName("api.vk.")) {
            if (existParameterByName("vkmobile=true")) {
                return "mobile_vk";
            }
            return "web_vk";
        }

        if (cleverapps.config.mm && existParameterByName("app_id=" + cleverapps.config.mm.appId)) {
            if (existParameterByName("view=app_mobile")) {
                return "mobile_mm";
            }
            return "web_mm";
        }

        if (existParameterByName("yandex")) {
            return "yandex";
        }

        if (existParameterByName("fotostrana.ru")) {
            return "fotostrana";
        }

        if (existParameterByName("source=spmobage")) {
            return "sp_mbga";
        }

        if (existParameterByName("mbga-platform.jp")) {
            return "mbga";
        }

        if (existParameterByName("source=xiaomi")) {
            return "xiaomi";
        }

        if (existParameterByName("source=xsolla") || existParameterByName("source=cleverapps")) {
            return "cleverapps";
        }

        if (cleverapps.isLocalhost() || (cleverapps.isStaging(true) && !cleverapps.isIFrame())) {
            return "test";
        }

        return "facebook";
    }
};