/**
 * Created by vladislav on 16.11.2020
 */

var AdminLevelSlotView = cc.Node.extend({
    ctor: function (adminLevel) {
        this._super();

        this.setAnchorPoint2();
        this.setLocalZOrder(AdminLevelSlotView.BASE_ZORDER);

        this.adminLevel = adminLevel;
        this.updateAdminLevelListeners();

        var styles = cleverapps.styles.AdminLevelSlotView;

        this.setContentSize2(styles);
        this.createLevelNo();

        this.setZOrder();
    },

    createLevelNo: function () {
        var styles = cleverapps.styles.AdminLevelSlotView;

        this.levelNo = cleverapps.UI.generateImageText(this.adminLevel.getHumanNumber(), this.getFont());
        this.addChild(this.levelNo);
        this.levelNo.setPositionRound(styles.text);

        this.levelNo.setVisible(!this.adminLevel.isAddMoreLevel());
    },

    getFont: function () {
        var font = cleverapps.styles.FONTS.ADMIN_LEVEL_NO_TEXT;
        if (this.adminLevel.selected) {
            font = cleverapps.styles.FONTS.ADMIN_LEVEL_NO_SELECTED_TEXT;
        }

        return font;
    },

    updateAdminLevel: function (adminLevel) {
        this.adminLevel = adminLevel;
        this.updateAdminLevelListeners();

        this.removeDragShadow();
        if (adminLevel.dragging) {
            this.addDragShadow();
        }

        this.adminLevel.moveToSlot(this);

        this.updateBg();
    },

    updateAdminLevelListeners: function() {
        if (this.selectHandler) {
            this.selectHandler.clear();
            this.unselectHandler.clear();
        }

        this.selectHandler = this.adminLevel.on('select', this.updateBg.bind(this), this);
        this.unselectHandler = this.adminLevel.on('unselect', this.updateBg.bind(this), this);
    },

    addDragShadow: function() {
        this.removeDragShadow();

        this.dragShadow = new AdminLevelDragShadow(this.adminLevel);
        this.dragShadow.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.dragShadow);
    },

    removeDragShadow: function() {
        if (this.dragShadow) {
            this.dragShadow.removeFromParent();
            delete this.dragShadow;
        }
    },

    setZOrder: function () {
        if (this.adminLevel.selected) {
            this.setLocalZOrder(AdminLevelSlotView.BASE_ZORDER + 1);
        } else {
            this.setLocalZOrder(AdminLevelSlotView.BASE_ZORDER);
        }
    },

    updateBg: function () {
        this.levelNo.setVisible(!this.adminLevel.isAddMoreLevel());

        if (this.adminLevel.isAddMoreLevel()) {
            return;
        }

        this.levelNo.setString(this.adminLevel.getHumanNumber());
        this.levelNo.setFont(this.getFont());

        this.setZOrder();
    }
});

AdminLevelSlotView.BASE_ZORDER = -1;

cleverapps.styles.AdminLevelSlotView = {
    width: 148,
    height: 220,

    text: {
        x: {align: 'center'},
        y: {align: 'top', dy: 20}
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_LEVEL_NO_TEXT: {
        name: 'formal',
        size: 21,
        color: new cc.Color(227, 223, 223, 255)
    },

    ADMIN_LEVEL_NO_SELECTED_TEXT: {
        name: 'formal',
        size: 21,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    }
});