/**
 * Created by andrey on 28.11.19.
 */

// eslint-disable-next-line no-unused-vars
var decodeWebViewData = function (data) {
    if (data) {
        try {
            return decodeURIComponent(data);
        } catch (e) {
            return data;
        }
    }
    return data;
};

(function () {
    cleverapps.config.requestParamSource = cleverapps.getRequestParameters().source;
    var source = cleverapps.config.source || cleverapps.config.requestParamSource;
    // console.log("webview source - " + cleverapps.config.source + " - " + cleverapps.config.requestParamSource);
    var targets = ["microsoft", "gpg", "mygames"];
    var mobTargets = ["android", "amazon", "ios", "pliega", "rustore"];

    if (targets.concat(mobTargets).indexOf(source) !== -1) {
        cc.sys.isNative = true;
        cc.sys.isMobile = mobTargets.indexOf(source) !== -1;

        if (cleverapps.config.requestParamSource) {
            cc.sys.isNativeFlight = true;
        }
    }
}());
