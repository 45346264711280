/**
 * Created by Andrey Popov on 10.11.2021
 */

var AnalyzerMenubar = function (atlasAnalyzer) {
    this.atlasAnalyzer = atlasAnalyzer;

    cleverapps.EventEmitter.call(this);

    this.atlasAnalyzer.bundlesExplorer.on("selectionChanged", this.updateTools.bind(this));
};

AnalyzerMenubar.prototype = Object.create(cleverapps.EventEmitter.prototype);
AnalyzerMenubar.prototype.constructor = AnalyzerMenubar;

AnalyzerMenubar.prototype.updateTools = function () {
};