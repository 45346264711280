/**
 * Created by slava on 4/21/17.
 */

cleverapps.InstantPayments = function () {
    cleverapps.Payments.call(this);

    for (var j in cleverapps.config.products) {
        var product = cleverapps.config.products[j];
        product.loadedId = product.id.instant;
    }

    this.ready = false;
};

cleverapps.InstantPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.InstantPayments.prototype.constructor = cleverapps.InstantPayments;

cleverapps.InstantPayments.prototype.init = function (callback) {
    cleverapps.whenAllInitialized(function () {
        var apis = FBInstant.getSupportedAPIs();
        console.log("Searching payments.purchaseAsync", apis);
        if (apis.indexOf("payments.purchaseAsync") === -1) {
            return;
        }

        if (apis.indexOf("payments.purchaseSubscriptionAsync") !== -1) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_API);
        }

        console.log("Found payments.purchaseAsync support!");
        FBInstant.payments.onReady(function () {
            console.log("Payments ready!");
            this.ready = true;

            this.loadProducts(function (success) {
                if (!success) {
                    setTimeout(this.retryLoadProducts.bind(this), cleverapps.parseInterval("10 seconds"));
                }
            }.bind(this));

            this.loadSubscriptions(function (success) {
                if (!success) {
                    setTimeout(this.retryLoadSubscriptions.bind(this), cleverapps.parseInterval("10 seconds"));
                }
            }.bind(this));

            cleverapps.flags.videoAdsMainMonetization = false;

            callback();
        }.bind(this));
    }.bind(this));
};

cleverapps.InstantPayments.prototype.getSubscriptionStatus = function (options, callback) {
    FBInstant.payments.getSubscriptionsAsync().then(function (subscriptions) {
        console.log(subscriptions);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + "SUCCESS");

        callback({
            active: subscriptions.length > 0
        });
    }).catch(function (e) {
        console.log("error listing subscriptions", e);

        switch (e.code) {
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "NETWORK_FAILURE":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_LIST + e.code + " message: " + e.message });
        }
        callback({});
    });
};

cleverapps.InstantPayments.prototype.isSubscriptionsAvailable = function () {
    if (typeof FBInstant === "undefined") {
        return false;
    }

    var apis = FBInstant.getSupportedAPIs();
    console.log("Searching payments.purchaseSubscriptionAsync", apis);
    return apis.indexOf("payments.purchaseSubscriptionAsync") !== -1;
};

cleverapps.InstantPayments.prototype.retryLoadProducts = function () {
    this.loadProducts(function (success) {
        if (success) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CATALOG + "SUCCESS_SECOND");
        } else {
            setTimeout(this.retryLoadProducts.bind(this), cleverapps.parseInterval("2 minutes"));
        }
    }.bind(this));
};

cleverapps.InstantPayments.prototype.retryLoadSubscriptions = function () {
    this.loadSubscriptions(function (success) {
        if (success) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + "SUCCESS_SECOND");
        } else {
            setTimeout(this.retryLoadSubscriptions.bind(this), cleverapps.parseInterval("2 minutes"));
        }
    }.bind(this));
};

cleverapps.InstantPayments.prototype.openSubscription = function (product, callback) {
    FBInstant.payments.purchaseSubscriptionAsync(product.id.instant).then(function (purchase) {
        console.log("success subscription", purchase);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + "SUCCESS");
        purchase.productId = product.itemId;
        callback(cleverapps.Social.CODE_SUCCEED, purchase);
    }).catch(function (e) {
        console.log("error purchase", e);

        var code = cleverapps.Social.CODE_FAILED;
        if (e && e.code === "USER_INPUT") {
            code = cleverapps.Social.CODE_CANCELLED;
        }

        switch (e.code) {
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "PAYMENTS_NOT_INITIALIZED":
            case "INVALID_PARAM":
            case "NETWORK_FAILURE":
            case "INVALID_OPERATION":
            case "USER_INPUT":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION + e.code + " message: " + e.message });
        }
        callback(code, product);
    });
};

cleverapps.InstantPayments.prototype._purchase = function (product, callback) {
    if (!this.isAvailable()) {
        callback(cleverapps.Social.CODE_FAILED, product, "InstantPayments.NotAvailable");
        return;
    }

    if (product.type === "subscription") {
        this.openSubscription(product, callback);
        return;
    }

    FBInstant.payments.purchaseAsync({
        productID: product.id.instant
    }).then(function (purchase) {
        console.log("success purchase", purchase);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAYMENT + "SUCCESS");
        purchase.paymentId = purchase.paymentID;
        purchase.productId = product.itemId;
        callback(cleverapps.Social.CODE_SUCCEED, purchase);
    }).catch(function (e) {
        console.log("error purchase", e);

        var code = cleverapps.Social.CODE_FAILED;
        if (e && e.code === "USER_INPUT") {
            code = cleverapps.Social.CODE_CANCELLED;
        }

        switch (e.code) {
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "PAYMENTS_NOT_INITIALIZED":
            case "INVALID_PARAM":
            case "NETWORK_FAILURE":
            case "INVALID_OPERATION":
            case "USER_INPUT":
            case "PAYMENTS_PURCHASE_CREATION_FAILED":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAYMENT + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAYMENT + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.PAYMENT + e.code + " message: " + e.message });
        }
        callback(code, product);
    });
};

cleverapps.InstantPayments.prototype.isAvailable = function () {
    if (["1832499670188919",
        "1920167364724450",
        "2076714912381045",
        "2585442014807608" // Andrey Kargapolov
    ].indexOf(cleverapps.user.id) !== -1) {
        return true;
    }
    return this.ready;
}; 

cleverapps.InstantPayments.isAppropriate = function () {
    return cleverapps.platform.oneOf(Instant);
};

cleverapps.InstantPayments.prototype.loadProducts = function (callback) {
    var apis = FBInstant.getSupportedAPIs();
    console.log("Searching payments.getCatalogAsync", apis);
    if (apis.indexOf("payments.getCatalogAsync") === -1) {
        console.log("No support for getCatalogAsync, skip");
        return;
    }

    FBInstant.payments.getCatalogAsync().then(function (catalog) {
        console.log(catalog);

        catalog.forEach(function (product) {
            var ourProduct = cleverapps.config.products[Product.SearchProductId(product.productID)];
            if (ourProduct) {
                ourProduct.loadedPrice = product.price;
            } else {
                cleverapps.throwAsync({ group: "DebugInstantMessage", message: product.productID + " not found" });
            }
        }, this);

        callback(true);
    }.bind(this)).catch(function (e) {
        if (!this.loadProductsErrorReported) {
            switch (e.code) {
                case "PENDING_REQUEST":
                case "CLIENT_UNSUPPORTED_OPERATION":
                case "PAYMENTS_NOT_INITIALIZED":
                case "NETWORK_FAILURE":
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CATALOG + e.code);
                    break;
                default:
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CATALOG + "OTHER");
                    cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.CATALOG + e.code + " message: " + e.message });
            }

            this.loadProductsErrorReported = true;
        }

        callback(false);
    }.bind(this));
};

cleverapps.InstantPayments.prototype.loadSubscriptions = function (callback) {
    var apis = FBInstant.getSupportedAPIs();
    console.log("Searching payments.getSubscribableCatalogAsync", apis);
    if (apis.indexOf("payments.getSubscribableCatalogAsync") === -1) {
        console.log("No support for getSubscribableCatalogAsync, skip");
        return;
    }

    FBInstant.payments.getSubscribableCatalogAsync().then(function (catalog) {
        console.log(catalog);

        catalog.forEach(function (product) {
            var ourProduct = cleverapps.config.products[Product.SearchProductId(product.productID)];
            if (ourProduct) {
                ourProduct.loadedPrice = product.price;
            } else {
                cleverapps.throwAsync({ group: "DebugInstantMessage", message: product.productID + " not found" });
            }
        }, this);

        callback(true);
    }.bind(this)).catch(function (e) {
        if (!this.loadSubscriptionsErrorReported) {
            switch (e.code) {
                case "CLIENT_UNSUPPORTED_OPERATION":
                case "NETWORK_FAILURE":
                case "PENDING_REQUEST":
                case "PAYMENTS_NOT_INITIALIZED":
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + e.code);
                    break;
                default:
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + "OTHER");
                    cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.SUBSCRIPTION_CATALOG + e.code + " message: " + e.message });
            }

            this.loadSubscriptionsErrorReported = true;
        }

        callback(false);
    }.bind(this));
};

cleverapps.InstantPayments.prototype.consumePurchase = function (purchase) {
    var ourProduct = Product.Create(purchase.productID);

    var data = {
        userId: cleverapps.user.id,
        purchase: purchase,
        productCode: purchase.productID,
        status: "",
        restore: purchase.restore
    };

    if (ourProduct) {
        data.price = ourProduct.price;
        data.textPrice = ourProduct.loadedPrice;
        data.productCode = ourProduct.key;
    }

    if (ourProduct.type === "subscription") {
        cleverapps.RestClient.post("/instantpayments/addsubscriptions/", {
            userId: data.userId,
            subscription: data.purchase
        });
        return;
    }

    cleverapps.RestClient.post("/instantpayments/addpayment/", data);

    FBInstant.payments.consumePurchaseAsync(purchase.purchaseToken)
        .then(function () {
            console.log("consume success", purchase);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + "SUCCESS");

            data.status = "consumed";
            cleverapps.RestClient.post("/instantpayments/addpayment/", data);
        })
        .catch(function (e) {
            console.log("consume error", e);

            data.error = e;
            data.status = "consume_error";
            cleverapps.RestClient.post("/instantpayments/addpayment/", data);

            switch (e.code) {
                case "CLIENT_UNSUPPORTED_OPERATION":
                case "PAYMENTS_NOT_INITIALIZED":
                case "NETWORK_FAILURE":
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code);
                    break;
                default:
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.CONSUME + "OTHER");
                    cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.CONSUME + e.code + " message: " + e.message });
            }
        });

    this.setConsumed(purchase.paymentID);
};

cleverapps.InstantPayments.prototype.validate = function (purchase, onSuccess) {
    var data = {
        signedRequest: purchase.signedRequest,
        userId: cleverapps.user.id
    };

    cleverapps.RestClient.post("/instantpayments/validate/", data, function (response) {
        if (response === "ok") {
            onSuccess && onSuccess();
            console.log("Purchase validation success");
        } else {
            console.log("Purchase validation error", response);
        }
    });
};

cleverapps.InstantPayments.prototype._restore = function () {
    if (this.restoreRunning || !this.ready) {
        return;
    }

    this.restoreRunning = true;

    FBInstant.payments.getPurchasesAsync().then(function (purchases) {
        for (var index = 0; index < purchases.length && !this.restoreProduct; index++) {
            var purchase = purchases[index];

            if (!this.isConsumed(purchase.paymentID) && Product.SearchProductId(purchase.productID)) {
                this.restorePurchase = purchase;
                this.restorePurchase.paymentId = purchase.paymentID;
                this.restorePurchase.restore = true;
                this.restoreProduct = Product.Create(purchase.productID);
            }
        }

        this.restoreRunning = false;
    }.bind(this)).catch(function (e) {
        this.restoreRunning = false;
        console.log("error getPurchasesAsync", e);

        switch (e.code) {
            case "CLIENT_UNSUPPORTED_OPERATION":
            case "PAYMENTS_NOT_INITIALIZED":
            case "NETWORK_FAILURE":
            case "UNKNOWN":
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.LIST_PURCHASES + e.code);
                break;
            default:
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.LIST_PURCHASES + "OTHER");
                cleverapps.throwAsync({ group: "DebugMessage", message: cleverapps.EVENTS.STATS.INSTANT.LIST_PURCHASES + e.code + " message: " + e.message });
        }
    }.bind(this));
};
