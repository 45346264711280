/**
 * Created by andrey on 05.08.2020.
 */

var ScramblePlayer = function (level, options) {
    BoardPlayer.call(this, level, options);

    this.finger.replaceParent(this.keypadView.getParent());
    this.finger.relaxPosition = { y: cleverapps.styles.ScenarioScramblePlayer.relaxedFinger.offsetY };
};

ScramblePlayer.prototype = Object.create(BoardPlayer.prototype);
ScramblePlayer.prototype.constructor = ScramblePlayer;

ScramblePlayer.prototype.openWord = function (word, isLastWord, callback) {
    var letters = Game.currentGame.keypad.findWordIndexes(word);

    if (!letters) {
        callback();
        return;
    }

    letters.forEach(function (letter, i) {
        var touchPosition = BaseBlockView.alignInTheGrid(letter.x, letter.y);
        var targetPosition = this.finger.parent.convertToNodeSpace(this.keypadView.lettersContainer.convertToWorldSpace(touchPosition));

        if (i === 0 && !this.finger.isShown) {
            this.finger.fadeIn(targetPosition);
            this.finger.isShown = true;
        }

        this.finger.moveTo(targetPosition);

        if (i === 0) {
            this.finger.press({ noCircle: true });
            this.finger.addAction(function (f) {
                Game.currentGame.keypad.touchBegan(letter);
                f();
            });
        } else {
            this.finger.addAction(function (f) {
                Game.currentGame.keypad.push(letter);
                f();
            });
        }
    }, this);

    this.finger.unPress();
    this.finger.addAction(function (f) {
        Game.currentGame.keypad.touchEnded();
        f();
    });

    this.fingerRelax((letters.length + 4) * 0.15, callback);
};

cleverapps.styles.ScenarioScramblePlayer = {
    relaxedFinger: {
        offsetY: -100
    }
};