/**
 * Created by Denis Kuzin on 22 december 2022
 */

var PaginationView = cleverapps.Layout.extend({
    ctor: function (pagination) {
        this.pagination = pagination;

        var styles = cleverapps.styles.PaginationView;

        this.points = this.listPoints();

        this._super(this.points, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.environment.isEditorScene() ? styles.smallMargin : styles.margin
        });

        if (!cleverapps.environment.isEditorScene()) {
            this.progressBar = this.createProgressBar();
        }

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("pagination", this);

        this.pagination.on("changePage", this.createListener(this.changePage.bind(this)));

        if (this.pagination.getCurrent() !== 0) {
            for (var i = 1; i <= this.pagination.getCurrent(); i++) {
                this.changePage(i, true);
            }
        }
    },

    createProgressBar: function () {
        var styles = cleverapps.styles.PaginationView;

        var progressBar = new ScaledProgressBar({
            progress: bundles.pagination.frames.bar_pagination,
            background: bundles.pagination.frames.bg_pagination
        });
        progressBar.setLength(this.width - styles.progressBar.widthOffset);
        progressBar.setPositionRound(styles.progressBar);
        this.addChild(progressBar);
        progressBar.setLocalZOrder(-1);

        return progressBar;
    },

    listPoints: function () {
        var points = [];

        for (var i = 0; i < this.pagination.getTotal(); i++) {
            points.push(new cc.Sprite(bundles.pagination.frames[i === 0 ? "point_completed" : "point"]));
        }

        return points;
    },

    changePage: function (point, silent) {
        if (!this.progressBar) {
            return;
        }

        var styles = cleverapps.styles.PaginationView.targetOffset;

        var target = new cc.Sprite(bundles.pagination.frames.point_completed);
        this.addChild(target);

        var percent = point / (this.pagination.total - 1) * 100;

        if (silent) {
            this.progressBar.setPercentage(percent);

            target.setPosition(this.points[point].x, this.points[point].y);
            target.setScale(1);

            this.points[point].removeFromParent();

            return;
        }

        this.progressBar.animatedChange(percent);

        target.setPosition(this.points[point].x + styles.x, this.points[point].y + styles.y);
        target.setVisible(false);
        target.setScale(0.3);

        target.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.Show(),
            new cc.Spawn(
                new cc.ScaleTo(0.1, 1.2),
                new cc.MoveTo(0.1, this.points[point].x, this.points[point].y)
            ),
            new cc.CallFunc(function () {
                this.points[point].removeFromParent();
            }.bind(this)),
            new cc.ScaleTo(0.1, 1.0).easing(cc.easeOut(1))
        ));
    }
});

cleverapps.styles.PaginationView = {
    margin: 80,
    smallMargin: 30,

    progressBar: {
        x: { align: "center" },
        y: { align: "center" },

        widthOffset: 20
    },

    targetOffset: {
        x: -16,
        y: 3
    }
};