/**
 * Created by mac on 3/19/23
 */

var Hasher = function () {
    this.value = 0;
};

Hasher.prototype.add = function(a) {
    if (a < 0 || a >= 6) {
        throw "Impossible " + a;
    }
    this.value = (this.value * 197 + a) % 1000000007;
};

var JamPuzzle = function (board, exit) {
    this.parse(board);

    console.log(this)

    this.exit = exit;
};

JamPuzzle.prototype.parse = function (board) {
    this.width = board[0].length;
    this.height = board.length;

    this.lhorizontal = [];
    this.lvertical = [];

    this.state = {
        h: [],
        v: []
    };

    for (var i = 0; i < this.height; i++) {
        this.lhorizontal.push([]);
        this.state.h.push([]);

        var j = 0;
        while (j < this.width) {
            var ch = board[i][j];
            if (ch === " ") {
                j++;
                continue;
            }

            var l = 1;
            while (board[i][j + l] === ch) {
                l++;
            }

            if (l > 1) {
                this.lhorizontal[i].push(l);
                this.state.h[i].push(j);
            }

            j += l;
        }
    }

    for (var j = 0; j < this.width; j++) {
        this.lvertical.push([]);
        this.state.v.push([]);

        var i = 0;
        while (i < this.height) {
            var ch = board[i][j];
            if (ch === " ") {
                i++;
                continue;
            }

            var l = 1;
            while (board[i + l] && board[i + l][j] === ch) {
                l++;
            }

            if (l > 1) {
                this.lvertical[j].push(l);
                this.state.v[j].push(i);
            }

            i += l;
        }
    }
};

JamPuzzle.prototype.hash = function (state) {
    var hasher = new Hasher();

    var i,j,t;

    for (i = 0; i < this.height; i++) {
        t = 0;
        for (j = 0; j < state.h[i].length; j++) {
            hasher.add(state.h[i][j] - t);
            t += state.h[i][j] + this.lhorizontal[i][j];
        }
    }

    for (j = 0; j < this.width; j++) {
        t = 0;
        for (i = 0; i < state.v[j].length; i++) {
            hasher.add(state.v[j][i] - t);
            t += state.h[j][i] + this.lvertical[j][i];
        }
    }

    return hasher.value;
};

JamPuzzle.prototype.isRightSideExit = function () {
    return this.exit.x === this.width - 1;
};

JamPuzzle.prototype.checkWin = function (state) {
    if (this.isRightSideExit()) {
        var row = this.exit.y;
        var last = this.lhorizontal[row].length - 1;

        return this.lhorizontal[row][last] + state.h[row][last] === this.width;
    }
};

JamPuzzle.prototype.add = function (state, root) {
    var hash = this.hash(state);

    if (this.exists[hash]) {
        return;
    }

    this.exists[hash] = true;

    this.queue.push(state);
    this.roots.push(root);

    if (this.checkWin(state)) {
        this.found = this.queue.length - 1;
    }
}

JamPuzzle.prototype.solve = function (state) {
    this.queue = [];
    this.roots = [];
    this.exists = {};

    this.add(state, undefined);

    this.found = undefined;
    var p = 0;

    while (p < this.queue.length && this.found === undefined) {
        var c = this.queue[p];

        var moves = this.makeAllMoves(c);

        moves.forEach(function(move) {
            this.add(move, p);
        }, this);

        p++;
    }

    if (this.found) {
        this.printSolution(this.found);
    }
};

JamPuzzle.prototype.printSolution = function (end) {
    var path = [];

    while (end !== undefined) {
        path.push(end);
        end = this.roots[end];
    }

    path.reverse();
    console.log("Solution: " + path.length);
    for (var i = 1; i < path.length; i++) {
        this.hint(this.queue[path[i]], this.queue[path[i - 1]]);
    }
};

JamPuzzle.prototype.hint = function (state, root) {
    var i,j;

    for (i = 0; i < this.height; i++) {
        for (j = 0; j < state.h[i].length; j++) {
            if (state.h[i][j] !== root.h[i][j]) {
                console.log("horizontal: row " + i + " item " + j + " by" + (state.h[i][j] - root.h[i][j]));
            }
        }
    }

    for (j = 0; j < this.width; j++) {
        for (i = 0; i < state.v[j].length; i++) {
            if (state.v[j][i] !== root.v[j][i]) {
                console.log("vertical: column " + j + " item " + i + " by" + (state.v[j][i] - root.v[j][i]));
            }
        }
    }
};

JamPuzzle.prototype.clone = function (state) {
    return {
        h: state.h.map(function(arr) { return arr.slice(); }),
        v: state.v.map(function(arr) { return arr.slice(); })
    };
};

JamPuzzle.prototype.checkHorizontalMove = function (state, row, item, c) {
    var len = this.lhorizontal[row][item];
    for (var j = c; j < c + len; j++) {
        for (var k = 0; k < state.v[j].length; k++) {
            if (state.v[j][k] <= row && row < state.v[j][k] + this.lvertical[j][k]) {
                return false;
            }
        }
    }

    return true;
};

JamPuzzle.prototype.checkVerticalMove = function (state, column, item, c) {
    var len = this.lvertical[column][item];
    for (var j = c; j < c + len; j++) {
        for (var k = 0; k < state.h[j].length; k++) {
            if (state.h[j][k] <= column && column < state.h[j][k] + this.lhorizontal[j][k]) {
                return false;
            }
        }
    }

    return true;
};

JamPuzzle.prototype.makeAllMoves = function (state) {
    var res = [];

    var i,j,from,to,c,pos;

    for (i = 0; i < this.height; i++) {
        for (j = 0; j < state.h[i].length; j++) {
            from = (j === 0) ? 0 : (state.h[i][j - 1] + this.lhorizontal[i][j - 1]);
            to = (j === state.h[i].length - 1) ? this.width : (state.h[i][j + 1]);

            to -= this.lhorizontal[i][j] - 1;

            for (c = state.h[i][j] - 1; c >= from; c--) {
                if (!this.checkHorizontalMove(state, i, j, c)) {
                    break;
                }

                pos = this.clone(state);
                pos.h[i][j] = c;
                res.push(pos);
            }
            for (c = state.h[i][j] + 1; c < to; c++) {
                if (!this.checkHorizontalMove(state, i, j, c)) {
                    break;
                }
                pos = this.clone(state);
                pos.h[i][j] = c;
                res.push(pos);
            }
        }
    }

    for (j = 0; j < this.width; j++) {
        for (i = 0; i < state.v[j].length; i++) {
            from = (i === 0) ? 0 : (state.v[j][i - 1] + this.lvertical[j][i - 1]);
            to = (i === state.v[j].length - 1) ? this.height : (state.v[j][i + 1]);

            to -= this.lvertical[j][i] - 1;

            for (c = state.v[j][i] - 1; c >= from; c--) {
                if (!this.checkVerticalMove(state, j, i, c)) {
                    break;
                }
                pos = this.clone(state);
                pos.v[j][i] = c;
                res.push(pos);
            }
            for (c = state.v[j][i] + 1; c < to; c++) {
                if (!this.checkVerticalMove(state, j, i, c)) {
                    break;
                }
                pos = this.clone(state);
                pos.v[j][i] = c;
                res.push(pos);
            }
        }
    }

    return res;
};