/**
 * Created by vladislav on 06.09.2021
 */

var ProlongationOfferView = cc.Node.extend({
    ctor: function (offer) {
        var styles = cleverapps.styles.ProlongationOfferView;

        this.offer = offer;

        this._super();
        this.setContentSize(styles);
        this.setAnchorPoint2();

        var button = this.button = new cleverapps.UI.Button({
            type: this.offer.magic && cleverapps.styles.UI.Button.Images.button_blue,
            content: new TextWithIcon(this.offer.text),
            width: styles.width,
            height: styles.height,
            onClicked: this.onClick.bind(this)
        });
        button.forceTarget = true;
        button.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(button);

        var withMoves = this.offer.moves;

        if (withMoves || this.offer.magic) {
            this.createLabel();
        }

        cleverapps.UI.wrap(this);

        if (withMoves && this.offer.bonus) {
            this.createBonusLabel();
        }

        if (this.offer.lottery) {
            Lottery.addIcon(this);
        }
    },

    createBonusLabel: function () {
        var styles = cleverapps.styles.ProlongationOfferView;
        var bonusLabel = new cleverapps.Spine(bundles.prolongation_window.jsons.bonus_json);
        this.label.addChild(bonusLabel);
        bonusLabel.setLocalZOrder(-1);
        bonusLabel.setPositionRound(styles.bonusLabel);
        bonusLabel.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                bonusLabel.setAnimationAndIdleAfter("showUp", "idle");
            })
        ));
    },

    createLabel: function () {
        var styles = cleverapps.styles.ProlongationOfferView;

        var label;
        if (bundles.prolongation_window.jsons.label_moves_json) {
            label = this.label = new cleverapps.Spine(bundles.prolongation_window.jsons.label_moves_json);
            this.addChild(label);
            label.setPositionRound(styles.labelAnimation);
            label.setAnimationAndIdleAfter("open", "idle");
        } else {
            label = this.label = new cc.Sprite(bundles.prolongation_window.frames.label_moves_png);
            this.addChild(label);
            label.setPositionRound(styles.label);

            if (!this.offer.magic) {
                var amount = cleverapps.UI.generateImageText("+" + this.offer.moves, cleverapps.styles.FONTS.PROLONG_BUTTON_AMOUNT_TEXT);
                label.addChild(amount);
                amount.setPositionRound(styles.label.amount);
            }
        }
    },

    animate: function () {
        return new cc.Sequence(
            new cc.ScaleTo(0, 0.5),
            new cc.Show(),
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
        );
    },

    showForce: function (window) {
        if (this.offer.force) {
            cleverapps.forces.create(this, this.offer.force, {
                rects: [window.getGlobalBoundingBox()]
            });
        }
    },

    onClick: function () {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        this.offer.onClick();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.ProlongationOfferView = {
    width: 350,
    height: 90,

    label: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 130 },

        amount: {
            x: { align: "center", dx: -3 },
            y: { align: "center", dy: -3 }
        }
    },

    labelAnimation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 205 }
    },

    bonusLabel: {
        x: { align: "center", dx: 125 },
        y: { align: "center", dy: 73 }
    }
};