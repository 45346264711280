/**
 * Created by vladislav on 9/6/2022
 */

var MyMailRu = function (source) {
    Platform.call(this, source || "web_mm");
};

MyMailRu.prototype = Object.create(Platform.prototype);
MyMailRu.prototype.constructor = MyMailRu;

MyMailRu.prototype.getLocalStoragePreffix = function () {
    return "_MM";
};

MyMailRu.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};

MyMailRu.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};

MyMailRu.prototype.calcChannel = function () {
    var channel = Platform.prototype.calcChannel.call(this);
    if (!channel && document.referrer) {
        var a = document.createElement("a");
        a.href = document.referrer;
        channel = Platform.prototype.calcChannel.call(this, { param: "channel", source: a.search });
    }
    return channel || "";
};