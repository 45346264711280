/**
 * Created by andrey on 26.06.2021.
 */

cleverapps.DailyTasks.PASS_LEVEL = 4;
cleverapps.DailyTasks.WATCH_ADS = 7;
cleverapps.DailyTasks.USE_MULTI_COLOR_CELL = 8;
cleverapps.DailyTasks.USE_BOOSTER = 9;
cleverapps.DailyTasks.COLLECT_COLOR_CELLS = 10;
cleverapps.DailyTasks.SPEND_GOLD = 13;

cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER = 16;
cleverapps.DailyTasks.COLLECT_HERO_CELLS = 24;

cleverapps.DailyTasks.COLLECT_CARDS_BY_VALUE = 25;
cleverapps.DailyTasks.COLLECT_CARDS_BY_SUIT = 26;

cleverapps.DailyTasks.DESTROY_ENT = 28;
cleverapps.DailyTasks.MAKE_ORDER = 29;
cleverapps.DailyTasks.USE_RAINBOW = 30;
cleverapps.DailyTasks.MERGE_SOLDIERS = 31;
cleverapps.DailyTasks.HARVEST_FRUITS = 32;
cleverapps.DailyTasks.USE_WORKER = 33;
cleverapps.DailyTasks.OPEN_CHEST = 34;
cleverapps.DailyTasks.COLLECT_PIXEL = 35;
cleverapps.DailyTasks.MERGE = 36;
cleverapps.DailyTasks.BUY_UNITS_SHOP_ITEMS = 37;
cleverapps.DailyTasks.PLAY_MINESWEEPER = 38;
cleverapps.DailyTasks.SPEND_COINS = 39;
cleverapps.DailyTasks.CREATE_SOURCE = 40;
cleverapps.DailyTasks.COMPLETE_ALL = 41;
// cleverapps.DailyTasks.WATCH_TELLY = 42;
cleverapps.DailyTasks.BUILD = 43;
cleverapps.DailyTasks.GROW_PLANT = 44;

cleverapps.DailyTasks.PASS_DAILY_CUP_LEVEL = 45;
cleverapps.DailyTasks.PASS_LEVEL_HARD = 46;
cleverapps.DailyTasks.GUESS_LONG_WORDS = 47;
cleverapps.DailyTasks.GET_FULL_COMBO = 48;
cleverapps.DailyTasks.USE_BOOSTER_BEFORE = 49;

cleverapps.DailyTasks.PASS_KNOCKOUT = 50;
cleverapps.DailyTasks.PLAY_MINI_GAME = 51;

cleverapps.DailyTasks.ENERGY_LOTTERY = 52;
cleverapps.DailyTasks.OPEN_FOG = 53;
cleverapps.DailyTasks.FINISH_QUEST = 54;
cleverapps.DailyTasks.USE_UP_INSTANT_WORKER = 55;
cleverapps.DailyTasks.LEVEL_UP = 56;
cleverapps.DailyTasks.FINISH_EXPEDITION = 57;

cleverapps.DailyTasks.OPEN_STAR_CHEST = 58;
cleverapps.DailyTasks.UPGRADE_HERO = 59;

cleverapps.DailyTasks.ACTIVE_TASKS = 6;

cleverapps.DailyTasks.DURATION = "1 day";

cleverapps.DailyTasks.CELL_COLORS = ["a", "b", "d"];
cleverapps.DailyTasks.CARD_VALUES = ["j", "q", "k", "a"];

cleverapps.DailyTasks.SWAPPABLE_TASKS = [cleverapps.DailyTasks.WATCH_ADS];

var DailyTasksConfig = {};

DailyTasksConfig[cleverapps.DailyTasks.WATCH_ADS] = {
    reward: function () {
        if (cleverapps.config.type === "merge") {
            return { soft: 25 };
        }
        return undefined;
    },
    dynamic: function () {
        var goal = cleverapps.Random.random(10, 15);
        if (cleverapps.config.type === "merge") {
            return {
                0: goal, 1: goal, 2: goal, 3: goal
            };
        }
        return {
            0: goal,
            1: goal
        };
    },
    icon: "daily_task_icon_watch_ads_png",
    filter: function () {
        return RewardedAdsManager.IsEnabled() && cleverapps.rewardedAdsManager.plugin.isAvailable(RewardedAdsManager.REWARDED);
    }
};

DailyTasksConfig[cleverapps.DailyTasks.SPEND_GOLD] = {
    reward: cleverapps.config.type === "merge" ? { rubies: 6 } : undefined,
    dynamic: function () {
        if (cleverapps.config.type === "merge") {
            return { 0: 5, 1: 5 };
        }
        return { 4: Math.floor(cleverapps.Random.random(500, 750) / 50) * 50 };
    },
    icon: "daily_task_icon_spend_gold_png"
};

DailyTasksConfig[cleverapps.DailyTasks.PASS_LEVEL] = {
    dynamic: function () {
        var goal = cleverapps.Random.random(12, 15);
        return {
            2: goal,
            3: goal
        };
    },
    icon: "daily_task_icon_pass_level_png",
    filter: function () {
        return cleverapps.config.type !== "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.PASS_LEVEL_HARD] = {
    dynamic: function () {
        var goal = cleverapps.Random.random(3, 4);
        return {
            2: goal,
            3: goal
        };
    },
    icon: "daily_task_icon_hard_level_png",
    filter: function () {
        return cleverapps.config.type !== "merge" && !cleverapps.isKnockoutGame();
    }
};

DailyTasksConfig[cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER] = {
    dynamic: function () {
        var easyLetters = ["e", "o", "a"];
        var hardLetters = ["p", "c", "t"];
        if (cleverapps.settings.language === cc.sys.LANGUAGE_RUSSIAN) {
            easyLetters = ["е", "о", "а"];
            hardLetters = ["п", "с", "т"];
        } else if (cleverapps.settings.language === cc.sys.LANGUAGE_JAPANESE) {
            easyLetters = ["あ", "か", "ち"];
            hardLetters = ["は", "ラ", "わ"];
        }

        if (cleverapps.Random.nextDouble() < 0.5) {
            var goal = Math.floor(cleverapps.Random.random(50, 75) / 5) * 5;
            return {
                letter: cleverapps.Random.choose(easyLetters),
                2: goal,
                3: goal
            };
        }
        goal = Math.floor(cleverapps.Random.random(125, 150) / 5) * 5;
        return {
            letter: cleverapps.Random.choose(hardLetters),
            4: goal
        };
    },
    filter: function () {
        var excludedLanguages = ["ko"];

        return cleverapps.config.type === "board" && excludedLanguages.indexOf(cleverapps.settings.language) === -1;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COLLECT_CARDS_BY_VALUE] = {
    dynamic: function () {
        return {
            4: Math.floor(cleverapps.Random.random(25, 50) / 5) * 5,
            cardValue: cleverapps.Random.choose(cleverapps.DailyTasks.CARD_VALUES)
        };
    },
    icon: {
        j: "daily_task_icon_cards_by_value_j_png",
        q: "daily_task_icon_cards_by_value_q_png",
        k: "daily_task_icon_cards_by_value_k_png",
        a: "daily_task_icon_cards_by_value_a_png"
    },
    filter: function () {
        return cleverapps.config.type === "solitaire";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COLLECT_CARDS_BY_SUIT] = {
    dynamic: function () {
        var goal = Math.floor(cleverapps.Random.random(50, 75) / 5) * 5;
        return {
            2: goal,
            3: goal,
            cardSuit: cleverapps.Random.choose(Card.SUITS)
        };
    },
    icon: {
        c: "daily_task_icon_cards_by_suit_c_png",
        d: "daily_task_icon_cards_by_suit_d_png",
        h: "daily_task_icon_cards_by_suit_h_png",
        s: "daily_task_icon_cards_by_suit_s_png"
    },

    filter: function () {
        return cleverapps.config.type === "solitaire";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.GET_FULL_COMBO] = {
    dynamic: function () {
        return {
            4: 15
        };
    },
    icon: "daily_task_icon_full_combo_png",
    filter: function () {
        return cleverapps.config.type === "solitaire";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_MULTI_COLOR_CELL] = {
    dynamic: function () {
        return {
            4: cleverapps.Random.random(25, 30)
        };
    },
    icon: "daily_task_icon_multi_color_cell_png",
    filter: function () {
        return cleverapps.config.type === "match3";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_BOOSTER] = {
    dynamic: function () {
        var goal = cleverapps.Random.random(3, 5);
        return {
            0: goal,
            1: goal
        };
    },
    icon: "daily_task_icon_use_booster_png",
    filter: function () {
        return cleverapps.boosters.listBoosters().length > 0;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_BOOSTER_BEFORE] = {
    dynamic: function () {
        var goal = cleverapps.Random.random(3, 5);
        return {
            0: goal,
            1: goal
        };
    },
    icon: "daily_task_icon_booster_before_png",
    filter: function () {
        return cleverapps.boosters.listBoostersBefore().length > 0;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COLLECT_COLOR_CELLS] = {
    dynamic: function () {
        var mediumGoal = Math.floor(cleverapps.Random.random(500, 1000) / 100) * 100;
        var hardGoal = Math.floor(cleverapps.Random.random(2500, 3000) / 100) * 100;
        return {
            2: mediumGoal,
            3: mediumGoal,
            4: hardGoal,
            color: cleverapps.Random.choose(cleverapps.DailyTasks.CELL_COLORS)
        };
    },
    icon: {
        a: "daily_task_icon_color_cells_a_png",
        b: "daily_task_icon_color_cells_b_png",
        d: "daily_task_icon_color_cells_d_png"
    },
    filter: function () {
        return cleverapps.config.type === "match3";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COLLECT_HERO_CELLS] = {
    dynamic: function () {
        return {
            4: cleverapps.Random.random(25, 30),
            color: cleverapps.Random.choose(cleverapps.DailyTasks.CELL_COLORS)
        };
    },
    icon: {
        a: "daily_task_icon_hero_cells_a_png",
        b: "daily_task_icon_hero_cells_b_png",
        d: "daily_task_icon_hero_cells_d_png"
    },
    filter: function () {
        return cleverapps.config.type === "match3";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.DESTROY_ENT] = {
    reward: { soft: 15 },
    dynamic: function () {
        var goal = cleverapps.Random.random(3, 4);
        return { 0: goal, 1: goal };
    },
    icon: "daily_task_icon_destroy_ent_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.MAKE_ORDER] = {
    reward: { soft: 15 },
    dynamic: function () {
        return { 0: 3, 1: 3 };
    },
    icon: "daily_task_icon_make_order_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_RAINBOW] = {
    reward: { soft: 25 },
    dynamic: function () {
        var goal = cleverapps.Random.random(1, 2);
        return { 2: goal, 3: goal };
    },
    icon: "daily_task_icon_use_rainbow_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.MERGE_SOLDIERS] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(10, 15);
        return { 4: goal };
    },
    icon: "daily_task_icon_merge_soldiers_png",
    filter: function () {
        return cleverapps.config.type === "merge" && cleverapps.config.name !== "wondermerge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.HARVEST_FRUITS] = {
    reward: { soft: 15 },
    dynamic: function () {
        var goal = cleverapps.Random.random(30, 40);
        return { 2: goal, 3: goal };
    },
    icon: "daily_task_icon_harvest_fruit_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_WORKER] = {
    reward: { soft: 15 },
    dynamic: function () {
        var goal = cleverapps.Random.random(25, 50);
        return { 2: goal, 3: goal };
    },
    icon: "daily_task_icon_use_worker_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.OPEN_CHEST] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(20, 30);
        return { 4: goal };
    },
    icon: "daily_task_icon_open_chest_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COLLECT_PIXEL] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(40, 55);
        return { 4: goal };
    },
    icon: "daily_task_icon_collect_pixel_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.MERGE] = {
    reward: { soft: 20 },
    dynamic: function () {
        var data = { mergeAmount: cleverapps.Random.choose([9, 17]) };
        var goal = cleverapps.Random.random(5, 10);
        if (data.mergeAmount === 17) {
            goal = cleverapps.Random.random(3, 4);
        }
        return Object.assign(data, { 2: goal, 3: goal });
    },
    icon: "daily_task_icon_merge_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.BUY_UNITS_SHOP_ITEMS] = {
    reward: { soft: 30 },
    dynamic: function () {
        var goal = cleverapps.Random.random(1, 2);
        return { 0: goal, 1: goal };
    },
    icon: "daily_task_icon_units_shop_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.PLAY_MINESWEEPER] = {
    reward: { soft: 30 },
    dynamic: function () {
        var goal = cleverapps.Random.random(2, 3);
        return { 2: goal, 3: goal };
    },
    icon: "daily_task_icon_minesweeper_png",
    filter: function () {
        return cleverapps.config.name === "mergecraft";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.SPEND_COINS] = {
    reward: { soft: 40 },
    dynamic: function () {
        return { 0: 35, 1: 35 };
    },
    icon: "daily_task_icon_spend_coins_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.CREATE_SOURCE] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(10, 15);
        return { 4: goal };
    },
    icon: "daily_task_icon_create_source_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.ENERGY_LOTTERY] = {
    reward: { soft: 10 },
    dynamic: function () {
        return { 0: 1, 1: 1 };
    },
    icon: "daily_task_icon_energy_lottery_png",
    filter: function () {
        return cleverapps.config.type === "merge" && cleverapps.user.level <= 8
            && Game.currentGame && Game.currentGame.energyLottery && Game.currentGame.energyLottery.isAvailable();
    }
};

DailyTasksConfig[cleverapps.DailyTasks.OPEN_FOG] = {
    reward: { soft: 15 },
    dynamic: function () {
        return { 2: 1, 3: 1 };
    },
    icon: "daily_task_icon_open_fog_png",
    filter: function () {
        return cleverapps.config.type === "merge" && cleverapps.user.level <= 8;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.FINISH_QUEST] = {
    reward: { soft: 20 },
    dynamic: function () {
        var goal = cleverapps.Random.random(3, 5);

        return { 2: goal, 3: goal };
    },
    icon: "daily_task_icon_finish_quest_png",
    filter: function () {
        return cleverapps.config.type === "merge" && cleverapps.user.level <= 8;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.USE_UP_INSTANT_WORKER] = {
    reward: { soft: 45, exp: 100 },
    dynamic: function () {
        return { 4: 1 };
    },
    icon: "daily_task_icon_instant_worker_png",
    filter: function () {
        return cleverapps.config.type === "merge" && cleverapps.user.level <= 8;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.COMPLETE_ALL] = {
    reward: cleverapps.config.type === "merge" ? { unit: { code: "dailytaskchest", stage: 0, amount: 1 } } : undefined,
    dynamic: function () {
        var data = {};
        data[cleverapps.DailyTasks.ACTIVE_TASKS - 1] = cleverapps.DailyTasks.ACTIVE_TASKS - 1;
        return data;
    },
    icon: "daily_task_icon_complete_all_png",
    filter: function () {
        return true;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.BUILD] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(50, 65);
        return { 4: goal };
    },
    icon: "daily_task_icon_build_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.GROW_PLANT] = {
    reward: { soft: 50, exp: 100 },
    dynamic: function () {
        var goal = cleverapps.Random.random(85, 100);
        return { 4: goal };
    },
    icon: "daily_task_icon_grow_plant_png",
    filter: function () {
        return cleverapps.config.type === "merge";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.PASS_DAILY_CUP_LEVEL] = {
    dynamic: function () {
        var goal = cleverapps.Random.random(2, 3);
        return {
            0: goal,
            1: goal
        };
    },
    icon: "daily_task_icon_daily_cup_png",
    filter: function () {
        return cleverapps.dailyCup.isAvailable();
    }
};

DailyTasksConfig[cleverapps.DailyTasks.GUESS_LONG_WORDS] = {
    dynamic: function () {
        return {
            4: 20
        };
    },
    icon: "daily_task_icon_long_words_png",
    filter: function () {
        return cleverapps.config.type === "board";
    }
};

DailyTasksConfig[cleverapps.DailyTasks.PASS_KNOCKOUT] = {
    dynamic: function () {
        return {
            2: 1,
            3: 1
        };
    },
    icon: "daily_task_icon_knockout_png",
    filter: function () {
        return cleverapps.isKnockoutGame();
    }
};

DailyTasksConfig[cleverapps.DailyTasks.PLAY_MINI_GAME] = {
    dynamic: function () {
        return {
            0: 1,
            1: 1
        };
    },
    icon: "daily_task_icon_mini_game_png",
    filter: function () {
        return bundles.dailytasks.frames.daily_task_icon_mini_game_png !== undefined;
    }
};

DailyTasksConfig[cleverapps.DailyTasks.LEVEL_UP] = {
    filter: function () {
        return false;
    }
};
DailyTasksConfig[cleverapps.DailyTasks.FINISH_EXPEDITION] = {
    filter: function () {
        return false;
    }
};
DailyTasksConfig[cleverapps.DailyTasks.OPEN_STAR_CHEST] = {
    filter: function () {
        return false;
    }
};
DailyTasksConfig[cleverapps.DailyTasks.UPGRADE_HERO] = {
    filter: function () {
        return false;
    }
};