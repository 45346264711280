/**
 * Created by mac on 4/7/20
 */

var GameMessageView = cc.Node.extend({
    ctor: function (message, options) {
        this._super();

        options = options || {};
        this.options = options;
        this.styles = cleverapps.styles.GameMessageView;

        var scene = cleverapps.scenes.getRunningScene();

        var background = this.createBackground();
        background.setVisible(false);
        this.addChild(background);
        this.updateSize();

        var text = this.createText(message);
        text.fitTo(scene.width * 0.9);
        background.addChild(text);

        if (options.title) {
            var title = this.createTitle(options.title);
            background.addChild(title);
        }

        if (options.rays && this.styles.rays && cleverapps.config.type === "match3") {
            this.createRays();
        }

        this.createHero();

        this.updatePosition();
        this.show();
    },

    updatePosition: function () {
        var scene = cleverapps.scenes.getRunningScene(this);
        this.background.setPositionRound(scene.width / 2, this.background.y);
        this.direction = this.options.direction || GameMessageView.DIRECTION_TOP_DOWN;

        this.start = cc.p(scene.width / 2, scene.height + this.background.height / 2);
        this.target = cc.p(scene.width / 2, scene.height / 2);
        this.finish = cc.p(scene.width / 2, -this.background.height / 2);
        if (this.direction === GameMessageView.DIRECTION_TOP_TOP) {
            this.finish = start;
        }
    },

    updateSize: function () {
        var sceneSize = cleverapps.UI.getSceneSize();
        this.background.setContentSize2(sceneSize.width * 1.2, this.background.height);
    },

    show: function () {
        this.background.setPositionRound(this.start);
        this.background.setVisible(true);

        this.hero && this.hero.show(false, 0, { easing: true, duration: 0.3 });

        cleverapps.audio.playSound(bundles.gamemessage.urls.game_message_effect);

        this.background.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, this.target).easing(cc.easeBackOut(1)),
            new cc.DelayTime(this.options.delay),
            new cc.CallFunc(function () {
                this.hero && this.hero.hide(false, 0, { easing: true, duration: 0.3 });
                cleverapps.audio.playSound(bundles.gamemessage.urls.game_message_effect);
            }.bind(this)),
            new cc.CallFunc(function () {
                this.background.runAction(new cc.MoveTo(0.5, this.finish).easing(cc.easeBackIn(1)));
            }.bind(this)),
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.removeFromParent();
            }.bind(this))
        ));

        if (this.rays) {
            this.rays.runAction(new cc.Sequence(
                new cc.DelayTime(0.7),
                new cc.CallFunc(function () {
                    this.rays.setAnimation(0, "rays", false);
                }.bind(this))
            ));
        }
    },

    createHero: function () {
        var json = this.options.json || bundles.gamemessage.jsons.game_message_json;

        if (!json) {
            return;
        }

        var hero = new cleverapps.Spine(json, this.styles.hero.scale);
        this.hero = hero;
        this.addChild(hero, 1);
        hero.setPositionRound(this.styles.hero);

        var animation = this.options.skin && hero.hasAnimation("idle_" + this.options.skin) ? "idle_" + this.options.skin : "idle";

        hero.setAnimation(0, animation, false);
        cleverapps.UI.makeHideable(hero, cleverapps.UI.VERTICAL);
        hero.hide(true);
    },

    createText: function (message) {
        message = Messages.has(message) ? Messages.get(message) : message;
        var font = this.options.skin && cleverapps.styles.FONTS["GAME_MESSAGE_" + this.options.skin.toUpperCase() + "_TEXT"]
            || cleverapps.styles.FONTS.GAME_MESSAGE_TEXT;
        var text = cleverapps.UI.generateTTFText(message.toUpperCase(), font);

        text.updatePosition = function () {
            text.setPositionRound(this.styles.text);
        }.bind(this);
        text.updatePosition();
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        return text;
    },

    createTitle: function (message) {
        var image = this.options.skin && bundles.gamemessage.frames["title_bg_" + this.options.skin + "_png"]
            || bundles.gamemessage.frames.title_bg_png;
        var bg = new cc.Sprite(image);

        var font = cleverapps.styles.FONTS.GAME_MESSAGE_TITLE_TEXT;

        message = Messages.has(message) ? Messages.get(message) : message;
        var text = cleverapps.UI.generateTTFText(message.toUpperCase(), font);
        text.fitTo(this.styles.title.width);

        bg.addChild(text);
        text.setPositionRound(this.styles.title);
        bg.setPositionRound(this.styles.title.bg);
        bg.updatePosition = function () {
            bg.setPositionRound(this.styles.title.bg);
        }.bind(this);

        return bg;
    },

    createBackground: function () {
        var image = this.options.skin && bundles.gamemessage.frames["gamemessage_bg_" + this.options.skin + "_png"]
            || bundles.gamemessage.frames.gamemessage_bg_png;

        var background = this.background = new cc.Scale9Sprite(image);
        return background;
    },

    createRays: function () {
        var rays = new cleverapps.Spine(bundles.exclamations.jsons.exclamations_json, this.styles.rays.scale);
        this.rays = rays;
        rays.setPositionRound(this.styles.rays);
        rays.setVisible(false);
        this.background.addChild(rays, -1);
    }
});

GameMessageView.DIRECTION_TOP_DOWN = 0;
GameMessageView.DIRECTION_TOP_TOP = 1;

cleverapps.styles.GameMessageView = {
    text: {
        x: { align: "center" },
        y: { align: "center" }
    },
    title: {
        width: 200,
        bg: {
            x: { align: "center" },
            y: { align: "center", dy: 110 }
        },
        x: { align: "center" },
        y: { align: "center" }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GAME_MESSAGE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: new cc.Color(145, 56, 188, 255),
            size: 1
        }
    },
    GAME_MESSAGE_TITLE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});