/**
 * Created by mac on 9/20/20
 */

var JSQuery = function (selector) {
    this.selector = selector;

    this.objects = [];
    this.add(selector);
};

JSQuery.prototype.add = function (selector) {
    if (selector.node) {
        this.objects.push(selector.node);
        return this.objects;
    }

    var root = selector.root || cleverapps.scenes.getRunningScene();

    var nodes = [root];
    var index = 0;

    var Classes = selector.Class;
    if (Classes && !Array.isArray(Classes)) {
        Classes = [Classes];
    }

    while (index < nodes.length) {
        var node = nodes[index++];

        if (Classes && Classes.some(function (Class) {
            return node instanceof Class;
        })) {
            this.objects.push(node);
        }

        if (selector.debugId && node.getDebugId() === selector.debugId) {
            this.objects.push(node);
        }

        node.getChildren().forEach(function (child) {
            nodes.push(child);
        });
    }

    return this.objects;
};

JSQuery.prototype.empty = function () {
    return this.objects.length === 0;
};

JSQuery.prototype.get = function (index) {
    return this.objects[index || 0];
};

JSQuery.getNumber = function (x, total) {
    if (!Array.isArray(x)) {
        return x;
    }

    // x[0] === '%'
    return x[1] * total / 2;
};

JSQuery.prototype.hide = function () {
    this.objects.forEach(function (object) {
        object.visible = false;
    });

    return this.objects;
};

JSQuery.prototype.show = function () {
    this.objects.forEach(function (object) {
        object.visible = true;
    });

    return this.objects;
};

JSQuery.prototype.styles = function (styles) {
    var size = cleverapps.UI.getSceneSize();

    this.objects.forEach(function (object) {
        if (styles.visible) {
            object.visible = styles.visible;
        }

        if (styles.x) {
            object.x = JSQuery.getNumber(styles.x, size.width);
        }

        if (styles.y) {
            object.y = JSQuery.getNumber(styles.y, size.height);
        }

        if (styles.offsetX) {
            object.x += JSQuery.getNumber(styles.offsetX, size.width);
        }

        if (styles.offsetY) {
            object.y += JSQuery.getNumber(styles.offsetY, size.height);
        }

        if (styles.scale) {
            object.scale = styles.scale;
        }

        if (styles.removed) {
            object.removeFromParent();
        }
    });

    return this.objects;
};

JSQuery.prototype.filter = function (iterator) {
    return Object.create(JSQuery.prototype, {
        objects: {
            value: this.objects.filter(iterator)
        }
    });
};

// eslint-disable-next-line no-unused-vars
var $ = function (selector) {
    if (selector instanceof JSQuery) {
        return selector;
    }

    if (selector instanceof cc.Node) {
        return new JSQuery({
            node: selector
        });
    }

    if (selector.debugId) {
        return new JSQuery(selector);
    }

    if (!selector.Class) {
        return new JSQuery({
            Class: selector
        });
    }

    return new JSQuery(selector);
};