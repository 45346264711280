/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorldLevel = function (bonusWorld, levelNo) {
    this.bonusWorld = bonusWorld;
    this.levelNo = levelNo;

    this.type = this.calcType();

    this.bonusWorld.on('changeProgress', this.onChangeProgress.bind(this));

    cleverapps.EventEmitter.call(this);
};

BonusWorldLevel.prototype = Object.create(cleverapps.EventEmitter.prototype);
BonusWorldLevel.prototype.constructor = BonusWorldLevel;

BonusWorldLevel.prototype.calcType = function () {
    var progress = this.bonusWorld.getProgress();
    if (this.bonusWorld.levelPassed) {
        progress--;
    }
    if (this.levelNo < progress) {
        return BonusWorldLevel.TYPE_PASSED;
    } else if (progress === this.levelNo) {
        return BonusWorldLevel.TYPE_CURRENT;
    } else {
        return BonusWorldLevel.TYPE_NOT_PASSED;
    }
};

BonusWorldLevel.prototype.setType = function (type) {
    this.type = type;

    this.trigger('changeType');
};

BonusWorldLevel.prototype.onChangeProgress = function () {
    if (this.bonusWorld.getProgress() === this.levelNo || this.bonusWorld.getProgress() - 1 === this.levelNo) {
        this.setType(this.calcType());
    }
};

BonusWorldLevel.prototype.onClick = function () {
    var episode = new Episode(BonusWorldConfig[this.bonusWorld.type].episode, this.levelNo);

    episode.getLevel().onPressed();
};

BonusWorldLevel.TYPE_PASSED = 0;
BonusWorldLevel.TYPE_CURRENT = 1;
BonusWorldLevel.TYPE_NOT_PASSED = 2;