/**
 * Created by Andrey Popov on 1/21/21.
 */

var AssetComponentView = AssetView.extend({
    ctor: function (assetComponent) {
        this._super(assetComponent);

        var styles = cleverapps.styles.AssetComponentView;

        if (this.asset.preview.icon) {
            var preview = new cc.Sprite(this.asset.preview.icon);
            if (assetComponent.name === "AdsAdHoc") {
                preview.setColor(cleverapps.styles.COLORS.YELLOW);
            }
            preview.setPositionRound(styles.preview);
            this.addChild(preview);

            var framePreview = new cc.Sprite(this.asset.preview.icon);
            framePreview.setPositionRound(this.componentFrame.width / 2, this.componentFrame.height / 2);
            this.componentFrame.addChild(framePreview);
        }
    }
});

cleverapps.styles.AssetComponentView = {
    preview: {
        x: { align: "center" },
        y: { align: "center", dy: -10 }
    }
};