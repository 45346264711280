/**
 * Created by andrey on 17.05.2023
 */

var IOSAds = function () {
    AdMobAds.call(this);

    AdMobPlugin = {
        init: function () {
            webkitMessage("adMob", {
                action: "init"
            });
        },

        cache: function (name) {
            webkitMessage("adMob", {
                action: "cache",
                name: name
            });
        },

        show: function (name) {
            webkitMessage("adMob", {
                action: "show",
                name: name
            });
        }
    };
};

IOSAds.prototype = Object.create(AdMobAds.prototype);
IOSAds.prototype.constructor = IOSAds;

IOSAds.isAppropriate = function () {
    return cleverapps.platform.oneOf(IOS, IOSCh);
};
