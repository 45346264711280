/**
 * Created by Aleksandr on 06.10.2022.
 */

var Plinga = function () {
    Platform.call(this, "plng");
};

Plinga.prototype = Object.create(Platform.prototype);
Plinga.prototype.constructor = Plinga;

Plinga.prototype.init = function (onSuccess) {
    // eslint-disable-next-line no-unused-vars, camelcase
    plingaRpc_pauseGame = function () {
        cc.director.pause();
    };

    // eslint-disable-next-line no-unused-vars, camelcase
    plingaRpc_resumeGame = function () {
        cc.director.resume();
    };

    onSuccess();
};

Plinga.prototype.getLocalStoragePreffix = function () {
    return "_plinga";
};

// Plinga.prototype.toggleFullScreen = function () {
//     plingaRpc.toggleFullscreen();
// };
//
// Plinga.prototype.exitFullscreen = function () {
//     if (this.isFullScreen()) {
//         this.toggleFullScreen();
//     }
// };

Plinga.prototype.isFullscreenAvailable = function () {
    return false;
};
