/**
 * Created by vladislav on 13.07.20.
 */

var ReturnBooster = function (id) {
    Booster.call(this, id);
};

ReturnBooster.prototype = Object.create(Booster.prototype);
ReturnBooster.prototype.constructor = ReturnBooster;

ReturnBooster.prototype.onExecute = function () {
    Game.currentGame.push3CardsToStack();
};

ReturnBooster.prototype.onGameStarted = function () {
    Game.currentGame.log.on("change", this.updateState.bind(this));
};

ReturnBooster.prototype.updateState = function () {
    if (Game.currentGame.open.isEmpty()) {
        this.onDisableListener();
    } else {
        this.onEnableListener();
    }
};

ReturnBooster.prototype.isForceAvailable = cleverapps.extendFunc(ReturnBooster.prototype.isForceAvailable, function () {
    var cards = Game.currentGame.open.cards;

    var amountByValue = {};
    cards.forEach(function (card) {
        amountByValue[card.value] = (amountByValue[card.value] || 0) + 1;
    });

    if (cleverapps.values(amountByValue).some(function (amount) {
        return amount >= 3;
    })) {
        return false;
    }

    var values = Object.keys(amountByValue).filter(function (value) {
        return amountByValue[value] >= 2;
    });

    var missed = Game.currentGame.table.listMissed();

    var canWin = values.some(function (value) {
        return missed.find(function (card) {
            return card.value === +value;
        });
    });

    return cards.length >= 6 && !canWin || cards.length >= 7;
});