/**
 * Created by vladislav on 11.01.2022
 */

var WindowBoosterBefore = function (booster, level) {
    cleverapps.EventEmitter.call(this);

    this.booster = booster;
    this.level = level;

    this.adBubble = new AdBubble({
        limit: AdsLimits.TYPES.BOOSTERS_BEFORE,
        onSuccess: this.giveAdsReward.bind(this)
    });

    this.adBubble.on("adsUpdate", this.resetState.bind(this));

    this.changeAmountListener = this.booster.on("changeAmount", this.onChangeAmount.bind(this));

    this.resetState();
};

WindowBoosterBefore.prototype = Object.create(cleverapps.EventEmitter.prototype);
WindowBoosterBefore.prototype.constructor = WindowBoosterBefore;

WindowBoosterBefore.prototype.resetState = function () {
    var lanternBonus = Lantern.GetBoosterBonus(this.level, this.booster);

    if (this.booster.isAvailable()) {
        if (lanternBonus) {
            this.setState(WindowBoosterBefore.LANTERN_STATE);
        } else if (this.isSelected() && this.getAmount() > 0) {
            this.setState(WindowBoosterBefore.SELECTED_STATE);
        } else if (this.getAmount() <= 0 && this.adBubble.isAdAvailable()) {
            this.setState(WindowBoosterBefore.AD_STATE);
        } else {
            this.setState(WindowBoosterBefore.ACTIVE_STATE);
        }
    } else if (this.booster.isUpcoming()) {
        this.setState(WindowBoosterBefore.UPCOMING_STATE);
    } else {
        this.setState(WindowBoosterBefore.DISABLED_STATE);
    }

    if (this.isDisabled()) {
        delete this.bonus;
    } else {
        this.bonus = Lantern.GetBoosterBonus(this.level, this.booster);
    }
};

WindowBoosterBefore.prototype.onChangeAmount = function () {
    this.resetState();

    this.trigger("changeAmount");
};

WindowBoosterBefore.prototype.giveAdsReward = function () {
    cleverapps.boosters.add(this.booster.id, 1);

    this.setState(WindowBoosterBefore.SELECTED_STATE);
};

WindowBoosterBefore.prototype.getIcon = function () {
    if (this.state === WindowBoosterBefore.DISABLED_STATE) {
        return bundles.boosters_before.frames.booster_before_lock_png;
    }

    return this.booster.icon;
};

WindowBoosterBefore.prototype.getLevelAvailable = function () {
    return cleverapps.humanReadableNumber({ floatLevel: this.booster.available.level });
};

WindowBoosterBefore.prototype.setState = function (state) {
    this.state = state;

    this.adBubble.setActive(this.isAd());

    this.trigger("update");
};

WindowBoosterBefore.prototype.getAmount = function () {
    return this.booster.getAmount();
};

WindowBoosterBefore.prototype.getForce = function () {
    return this.booster.force;
};

WindowBoosterBefore.prototype.getPrice = function () {
    return this.booster.getPrice().amount;
};

WindowBoosterBefore.prototype.select = function () {
    if (!this.isActive()) {
        return;
    }

    if (this.booster.canExecute() || this.booster.buy()) {
        this.setState(WindowBoosterBefore.SELECTED_STATE);
    }
};

WindowBoosterBefore.prototype.unselect = function () {
    if (this.isSelected()) {
        this.setState(WindowBoosterBefore.ACTIVE_STATE);
    }
};

WindowBoosterBefore.prototype.onClick = function () {
    if (cleverapps.forces.isRunningForce()) {
        cleverapps.forces.closeRunningForce();
    }

    if (this.isLantern()) {
        return;
    }

    if (this.isAd()) {
        this.adBubble.watchAd();
        return;
    }

    if (this.isSelected()) {
        this.unselect();
    } else {
        this.select();
    }
};

WindowBoosterBefore.prototype.isSelected = function () {
    return this.state === WindowBoosterBefore.SELECTED_STATE;
};

WindowBoosterBefore.prototype.isActive = function () {
    return this.state === WindowBoosterBefore.ACTIVE_STATE;
};

WindowBoosterBefore.prototype.isDisabled = function () {
    return this.state === WindowBoosterBefore.DISABLED_STATE || this.state === WindowBoosterBefore.UPCOMING_STATE;
};

WindowBoosterBefore.prototype.isUpcoming = function () {
    return this.state === WindowBoosterBefore.UPCOMING_STATE;
};

WindowBoosterBefore.prototype.isAd = function () {
    return this.state === WindowBoosterBefore.AD_STATE;
};

WindowBoosterBefore.prototype.isLantern = function () {
    return this.state === WindowBoosterBefore.LANTERN_STATE;
};

WindowBoosterBefore.prototype.showTutorialStep = function (f) {
    if (this.booster.isForceAvailable() && this.state === WindowBoosterBefore.ACTIVE_STATE) {
        if (!cleverapps.boosters.has(this.booster.id)) {
            cleverapps.boosters.add(this.booster.id, 1);
        }

        this.trigger("showForce", f);
    } else {
        f();
    }
};

WindowBoosterBefore.prototype.onExit = function () {
    if (this.changeAmountListener) {
        this.changeAmountListener.clear();
        delete this.changeAmountListener;
    }
};

WindowBoosterBefore.ACTIVE_STATE = 0;
WindowBoosterBefore.SELECTED_STATE = 1;
WindowBoosterBefore.DISABLED_STATE = 2;
WindowBoosterBefore.UPCOMING_STATE = 3;
WindowBoosterBefore.AD_STATE = 4;
WindowBoosterBefore.LANTERN_STATE = 5;
