/**
 * Created by Andrey Popov on 5/15/23.
 */

var AdsLogo = cc.Node.extend({
    ctor: function (properties, eventBus, parent, sceneSize) {
        this._super();
        this.setAnchorPoint2();

        var content = undefined;
        this.sceneSize = sceneSize;
        this.properties = cleverapps.clone(properties || {}, true);

        if (properties.asset && bundles.ads_logo.frames[this.properties.asset]) {
            this.properties.image = {
                bundle: "ads_logo",
                image: properties.asset
            };
            content = new AdsSprite(this.properties);
        } else if (this.properties.asset && bundles.ads_logo.jsons[this.properties.asset]) {
            this.properties.spine = {
                bundle: "ads_logo",
                json: properties.asset,
                animation: this.getAnimationName(bundles.ads_logo.jsons[properties.asset]),
                looped: false
            };
            content = new AdsSpine(this.properties);
        }

        if (content) {
            this.setContentSize(content.getContentSize());

            content.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(content);

            var effect = this.properties.effect;
            if (effect && effect.sound !== Wysiwyg.NOT_SELECTED && effect.sound !== SoundPropertyEditor.WITHOUT_MUSIC) {
                this.sound = cleverapps.audio.playSound(bundles[effect.bundle].urls[effect.sound]);
            }
        }
    },

    hide: function () {
        this.setVisible(false);

        if (this.sound) {
            cleverapps.audio.stopSound(this.sound);
        }
    },

    getAnimationName: function (spineBundle) {
        var variation = "vertical";
        if (this.sceneSize.width >= this.sceneSize.height) {
            variation = "horizontal";
        }

        var animations = {
            en: "animation_en",
            ru: "animation_ru"
        };

        var spine = new cleverapps.Spine(spineBundle);
        if (!spine) {
            return "animation";
        }

        var lang = cleverapps.settings.language;
        if (spine.data.animations[variation + "_" + lang]) {
            animations[lang] = variation + "_" + lang;
        } else if (spine.data.animations[variation]) {
            animations[lang] = variation;
        } else if (spine.data.animations["animation_" + lang]) {
            animations[lang] = "animation_" + lang;
        } else if (spine.data.animations.animation_en) {
            animations[lang] = "animation_en";
        } else {
            animations[lang] = "animation";
        }

        return animations[lang] || animations.en;
    }
});

AdsLogo.BUNDLES = ["ads_logo"];

AdsLogo.PROPERTIES = [{
    name: "asset",
    type: "string",
    items: Object.keys(bundles.ads_logo.jsons).concat(Object.keys(bundles.ads_logo.frames)).filter(function (json) {
        return json.indexOf("logo") !== -1;
    }),
    value: "post_logo_json",
    bubbleUp: true
}, {
    name: "effect",
    type: "sound",
    bundles: ["ads_logo"],
    value: { sound: "logo_effect_1", bundle: "ads_logo" },
    bubbleUp: true
}];