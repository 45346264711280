/**
 * Created by Aleksandr on 16.06.2023
 */

var MSStartAds = function () {
    AdsPlugin.call(this, {
        cantLoadAndPlayInParallel: true
    });
};

MSStartAds.prototype = Object.create(AdsPlugin.prototype);
MSStartAds.prototype.constructor = MSStartAds;

MSStartAds.isAppropriate = function () {
    return cleverapps.platform.oneOf(MSStart) && cleverapps.config.debugMode;
};

MSStartAds.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("msstartads", function () {
        cleverapps.timeouts.setTimeout(callback, MSStartAds.LOAD_AFTER_INIT_TIME);
    });
};

MSStartAds.prototype._cache = function (name, callback) {
    $msstart.loadAdsAsync().then(function (data) {
        console.log("MSStart ads: have ads!");
        callback(AdsPlugin.CODE_SUCCEED, data.instanceId);
    }).catch(function () {
        console.log("MSStart ads: NO ads!");
        callback(AdsPlugin.CODE_FAILED);
    });
};

MSStartAds.prototype._playAd = function (name, ad, callback) {
    $msstart.showAdsAsync(ad).then(function (data) {
        data.showAdsCompletedAsync.then(function () {
            callback(AdsPlugin.CODE_REWARD);
        }).catch(function (error) {
            console.log(error);
            callback();
        });
    }).catch(function (error) {
        console.log(error);
        callback();
    });
};

MSStartAds.prototype.getECPM = function () {
    return RewardedAdsManager.eCPM.MSStart;
};

MSStartAds.LOAD_AFTER_INIT_TIME = cleverapps.parseInterval("10 seconds");