/**
 * Created by vladislav on 9/6/2022
 */

var Platform = function (source) {
    console.log("Choosing " + source + " platform");

    this.callbacks = {};

    this.source = source;

    this.onInitializedListeners = {
    };

    this.initialized = false;
};

Platform.prototype.getHelpUrl = function () {
    switch (this.source) {
        case "android":
            return "market://details?id=" + cleverapps.config.android.package;
        case "instant":
            return "https://www.facebook.com/" + cleverapps.config.instant.groupId;
        case "web_ok":
            return "https://ok.ru/group/" + cleverapps.config.ok.groupId;
        case "mobile_ok":
            return "https://m.ok.ru/group/" + cleverapps.config.ok.groupId;
        case "web_vk":
            return "https://vk.com/" + cleverapps.config.vk.groupId;
        case "mobile_vk":
            return "https://m.vk.com/" + cleverapps.config.vk.groupId;
        case "web_mm":
            return "https://my.mail.ru/community/" + cleverapps.config.mm.groupId;
        case "mobile_mm":
            return "https://m.my.mail.ru/community/" + cleverapps.config.mm.groupId;
        case "mbga":
            return "http://yahoo-mbga.jp/group/" + cleverapps.config.mbga.groupId;
        case "sp_mbga":
            return "http://sp.mbga.jp/_grp_view?g=" + cleverapps.config.mbga.groupId;
        case "draugiem":
            return "https://www.draugiem.lv/" + cleverapps.config.draugiem.groupId;
        case "fotostrana":
            return "https://fotostrana.ru/public/" + cleverapps.config.fotostrana.groupId;
        default:
            return "https://www.facebook.com/" + cleverapps.config.facebook.groupId;
    }
};

Platform.prototype.getGameUrl = function () {
    return Platform.guessPlatformURL(cleverapps.config, cleverapps.config.debugMode, this.source);
};

Platform.prototype.getRateUrl = function () {
    switch (this.source) {
        case "test":
            return Platform.guessPlatformURL(cleverapps.config, true, "android");
        case "android":
            return "market://details?id=" + cleverapps.config.android.package;
        case "amazon":
            return "amzn://apps/android?p=" + cleverapps.config.amazon.package;
        case "macos":
        case "ios":
            return "itms-apps://itunes.apple.com/app/id" + cleverapps.config.ios.id;
    }
};

Platform.prototype.getExternalUrl = function (url) {
    if (url[0] === "/") {
        url = url.substr(1);
    }

    if (this instanceof TestPlatform || this instanceof Instant) {
        return url;
    }

    return cleverapps.config.deployment + "/" + url;
};

Platform.prototype.followOfficialPage = function () {};

Platform.prototype.showOfficialPage = function () {
    cc.sys.openURL(this.getHelpUrl());
};

Platform.prototype.canNativeInvite = function () {
    return this instanceof Apple || this instanceof AndroidBase;
};

Platform.prototype.inviteFriends = function (callback) {
    var options = {
        message: Messages.get("InviteFriends.message"),
        title: Messages.get(cleverapps.config.title)
    };

    if (this.canNativeInvite()) {
        this._inviteFriendsNative(options, callback);

        return;
    }

    cleverapps.social.checkConnection(function () {
        cleverapps.social.inviteFriends(options, callback);
    }, function () {
        callback(cleverapps.Social.CODE_FAILED);
    });
};

Platform.prototype.initialize = function (callback) {
    this.init(function () {
        this.setInitialized(true);

        callback();
    }.bind(this));
};

Platform.prototype.init = function (callback) {
    callback();
};

Platform.prototype.setInitialized = function (initialized) {
    this.initialized = initialized;

    if (!this.initialized) {
        return;
    }

    for (var key in this.onInitializedListeners) {
        var listener = this.onInitializedListeners[key];
        listener();
    }

    this.onInitializedListeners = {
    };
};

Platform.prototype.isInitialized = function () {
    return this.initialized !== false;
};

Platform.prototype.whenInitialized = function (name, callback) {
    if (this.isInitialized()) {
        callback();
    } else {
        this.onInitializedListeners[name] = callback;
    }
};

Platform.prototype.getDeviceId = function () {

};

Platform.prototype.canReview = function () {
    return false;
};

Platform.prototype.getLocalStoragePreffix = function () {
    return "";
};

Platform.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_ENGLISH];
};

Platform.prototype.needWindowForLogin = function () {
    return false;
};

Platform.prototype.requestReview = function () {

};

Platform.prototype.canCreateShortcut = function () {
    return false;
};

Platform.prototype.createShortcut = function () {

};

Platform.prototype.reportScore = function (score, callback) {
    callback();
};

Platform.prototype.getCurrentTournamentId = function (callback) {
    callback();
};

Platform.prototype.getAccessToken = function () {

};

Platform.prototype.pushSender = function () {

};

Platform.prototype.isFullscreenAvailable = function () {
    if (cc.sys.isNative || cc.sys.isMobile || cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL || cleverapps.platform.oneOf(Instant)) {
        return false;
    }
    return !!this.getFullscreener();
};

Platform.prototype.getFullscreener = function () {
    var elem = document.documentElement;
    var fullscreener = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen || elem.mozRequestFullscreen;
    if (fullscreener) {
        return fullscreener.bind(elem);
    }
};

Platform.prototype.goFullscreen = function () {
    if (this.isFullScreen()) {
        return;
    }

    var fullscreener = this.getFullscreener();
    if (fullscreener) {
        fullscreener();
    }
};

Platform.prototype.exitFullscreen = function (callback) {
    if (!this.isFullscreenAvailable() || !this.isFullScreen()) {
        callback && callback();
        return;
    }

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozExitFullscreen) {
        document.mozExitFullscreen();
    }

    if (callback) {
        setTimeout(callback, 100);
    }
};

Platform.prototype.isFullScreen = function () {
    return document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullscreenElemen;
};

Platform.prototype.toggleFullScreen = function () {
    if (this.isFullScreen()) {
        this.exitFullscreen();
    } else {
        this.goFullscreen();
    }
};

Platform.prototype.oneOf = function (arr) {
    var platforms = Array.isArray(arr) ? arr : arguments;

    for (var i = 0; i < platforms.length; i++) {
        if (this instanceof platforms[i]) {
            return true;
        }
    }

    return false;
};

Platform.prototype.calcChannel = function (options) {
    if (cc.sys.isNative) {
        return "";
    }

    options = options || {};
    var param = options.param || "channel";

    var params = cleverapps.getRequestParameters(options.source || location.search);
    if (params.custom_args) {
        var customArgs = "";
        try {
            customArgs = decodeURIComponent(params.custom_args);
        } catch (e) {
            cleverapps.throwAsync("calcChannel decodeURIComponent error, params.custom_args - " + params.custom_args);
        }
        if (customArgs.indexOf(param) >= 0) {
            params = cleverapps.getRequestParameters("?" + customArgs);
        }
    }

    return params[param] || "";
};

Platform.prototype.showBannerAd = function (callback) {
    callback();
};

Platform.prototype.hideBannerAd = function (callback) {
    callback();
};

Platform.prototype.getBannerAdStatus = function (callback) {
    callback();
};

Platform.prototype.callNative = function (method, options, callback) {
    this.callbacks[method] = callback;

    console.log("Platform.callNative " + method + " - " + JSON.stringify(options));

    this._callNative(method, options);
};

Platform.prototype._callNative = function () {

};

Platform.prototype.callCallback = function (method, code, options) {
    console.log("Platform.callCallback " + method + " - " + code + " - " + options);

    options = decodeWebViewData(options);
    options = options && JSON.parse(options);

    var callback = this.callbacks[method];
    if (callback) {
        delete this.callbacks[method];
        callback(code, options);
    }
};
