/**
 * Created by andrey on 05.10.2020.
 */

cleverapps.Payments = function () {
    this.onAvailableListeners = {};

    this.purchaseProduct = undefined;
    this.purchaseHandler = undefined;
    this.restoreProduct = undefined;
    this.restorePurchase = undefined;

    this.restoreThrottled = cleverapps.timeredThrottle(10000, this.restore.bind(this));

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.restoreThrottled.bind(this));
    cleverapps.timeouts.setInterval(
        this.restoreThrottled.bind(this),
        cleverapps.parseInterval(cleverapps.config.debugMode ? "1 minute" : "5 minutes")
    );

    this.onInitializedListeners = {
    };
    this.initialized = false;

    this.consumed = [];
    this.load();
};

cleverapps.Payments.prototype.load = function () {
    var info = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.PAYMENTS);
    if (info) {
        this.consumed = (info.consumed || []).slice();
    }
};

cleverapps.Payments.prototype.save = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.PAYMENTS, {
        consumed: this.consumed.slice(-cleverapps.Payments.CONSUMED_STORAGE_LIMIT)
    });
};

cleverapps.Payments.prototype.setConsumed = function (paymentId) {
    if (paymentId && !this.consumed.includes(paymentId)) {
        this.consumed.push(paymentId);
        this.save();
    }
};

cleverapps.Payments.prototype.isConsumed = function (paymentId) {
    return paymentId && this.consumed.includes(paymentId);
};

cleverapps.Payments.prototype.requiresLoginToPay = function () {
    var independentPayments = [cleverapps.MicrosoftPayments, cleverapps.PlingaPayments, cleverapps.WebViewPayments,
        cleverapps.YandexPayments, YooKassaPayments, cleverapps.TestPayments, XsollaPayments];
    return !independentPayments.some(function (classObject) {
        return this instanceof classObject;
    }, this);
};

cleverapps.Payments.prototype.requiresEmailToPay = function () {
    return this instanceof YooKassaPayments;
};

cleverapps.Payments.prototype.restore = function () {
    if (this.restoreProduct || this.purchaseProduct || !this.initialized || this.showRestoreRunning
        || cleverapps.config.debugMode && cleverapps.Tool.restoreCheck) {
        return;
    }

    this._restore();
};

cleverapps.Payments.prototype._restore = function () {
};

cleverapps.Payments.prototype.showRestore = function () {
    var product = this.restoreProduct;
    var purchase = this.restorePurchase;

    var isAvailable = product && cleverapps.environment.isMainScene()
        && (!cleverapps.travelBook.isExpedition() || !product.mainSceneOnly);

    if (!isAvailable || this.showRestoreRunning) {
        return;
    }

    this.showRestoreRunning = true;
    var nextRestore = cleverapps.waitNoMore(cleverapps.parseInterval("5 minutes"), function () {
        delete this.restorePurchase;
        delete this.restoreProduct;
        this.showRestoreRunning = false;
        this.restoreThrottled();
    }.bind(this));

    if (this.isConsumed(purchase && purchase.paymentId)) {
        this.consumePurchase(purchase);
        nextRestore();
        return;
    }

    product.restore(purchase);

    this.validate(purchase, function () {
        product.logPurchase(purchase);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.PRODUCT.RESTORE, { value: product.price });

        nextRestore();
    });
};

cleverapps.Payments.prototype.validate = function () {
};

cleverapps.Payments.prototype.consumePurchase = function (purchase) {
    if (purchase && purchase.paymentId) {
        this.setConsumed(purchase.paymentId);
    }
};

cleverapps.Payments.prototype.purchase = function (product, callback) {
    if (this.purchaseProduct) {
        callback(Product.PURCHASE_PROCESSING);
        return;
    }

    if (!this.initialized) {
        callback(cleverapps.Social.CODE_FAILED);
        return;
    }

    product.openTime = Date.now();

    this.purchaseProduct = product;
    this.purchaseHandler = cleverapps.once(function (code, purchase, errorMessage) {
        callback(code, purchase, errorMessage);
    });

    this._purchase(product, function () {
        this.onPurchaseResult.apply(this, arguments);
    }.bind(this));
};

cleverapps.Payments.prototype._purchase = function (product, callback) {
    callback(cleverapps.Social.CODE_FAILED, product);
};

cleverapps.Payments.prototype.onPurchaseResult = function (code, purchase, error) {
    var success = code === cleverapps.Social.CODE_SUCCEED;
    var processed = false;

    if (success && !purchase) {
        purchase = this.purchaseProduct;
    }

    var purchaseProductId = purchase && Product.SearchProductId(purchase.itemId || purchase.productId || purchase.loadedId);

    console.log("onPurchaseResult - " + code + ", purchaseProductId - " + purchaseProductId
        + ", this.purchaseProduct.itemId - " + (this.purchaseProduct && this.purchaseProduct.itemId));

    if (this.purchaseHandler && this.purchaseProduct && (!success || purchaseProductId === this.purchaseProduct.itemId)) {
        var purchaseHandler = this.purchaseHandler;

        delete this.purchaseProduct;
        delete this.purchaseHandler;
        delete this.notifyActiveListener;
        this.clearCancelTimeout();

        if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck) {
            code = Product.RESTORE_CHECK;
        }
        
        purchaseHandler(code, purchase, error);
        processed = true;
    }

    if (!processed || !success) {
        cleverapps.timeouts.setTimeout(this.restoreThrottled.bind(this), 10000);
    }
};

cleverapps.Payments.prototype.setCancelTimeout = function (timeout) {
    this.clearCancelTimeout();

    if (this.purchaseHandler) {
        var itemId = this.purchaseProduct && this.purchaseProduct.itemId;
        this.cancelTimeout = cleverapps.timeouts.setTimeout(function () {
            console.log("Payment timeout cancel", itemId);
            cleverapps.payments.onPurchaseResult(cleverapps.Social.CODE_CANCELLED);
        }, timeout);
    }
};

cleverapps.Payments.prototype.clearCancelTimeout = function () {
    cleverapps.timeouts.clearTimeout(this.cancelTimeout);
    delete this.cancelTimeout;
};

cleverapps.Payments.prototype.isProductExists = function (key) {
    return cleverapps.config.products[key];
};

cleverapps.Payments.prototype.getSubscriptionStatus = function (options, callback) {
    var source = Subscription.calcSource();
    if (["android", "ios", "macos"].includes(source) && Object.keys(options.tokens).length === 0) {
        return;
    }

    if (levels.user.haveTmpId() && ["facebook", "web_ok", "mobile_ok"].includes(source)) {
        return;
    }

    var params = { source: source, tokens: options.tokens };
    cleverapps.RestClient.post("/subscriptions/get/" + levels.user.id, params, callback);
};

cleverapps.Payments.prototype.initialize = function () {
    this.init(function () {
        this.setInitialized(true);
        cleverapps.whenAllInitialized(this.restoreThrottled.bind(this));
    }.bind(this));
};

cleverapps.Payments.prototype.init = function (callback) {
    callback();
};

cleverapps.Payments.prototype.setInitialized = function (initialized) {
    this.initialized = initialized;

    if (!this.initialized) {
        return;
    }

    for (var key in this.onInitializedListeners) {
        var listener = this.onInitializedListeners[key];
        listener();
    }

    this.onInitializedListeners = {
    };
};

cleverapps.Payments.prototype.whenInitialized = function (name, callback) {
    if (this.initialized) {
        callback();
    } else {
        this.onInitializedListeners[name] = callback;
    }
};

cleverapps.Payments.prototype.notifyActive = function () {
    if (this.notifyActiveListener) {
        console.log("Payments notifyActive");
        this.notifyActiveListener();
        delete this.notifyActiveListener;
    }
};

cleverapps.Payments.prototype.setNotifyActiveListener = function (notifyActiveListener) {
    this.notifyActiveListener = notifyActiveListener;
};

cleverapps.Payments.CONSUMED_STORAGE_LIMIT = 30;