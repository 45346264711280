/**
 * Created by Aleksandr on 15.06.2023
 */

cleverapps.MSStartSocial = function () {
    console.log("Choosing msstart");

    cleverapps.Social.call(this);
};

cleverapps.MSStartSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.MSStartSocial.prototype.constructor = cleverapps.MSStartSocial;

cleverapps.MSStartSocial.prototype.init = function (callback) {
    var onFailure = function () {
        if (typeof $msstart === "undefined") {
            $msstart = cleverapps.FakeMSStart;
        }

        console.error("MSStart Social Init failed");
        callback();
    };

    cleverapps.loadSdk("https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/msstart-v1.0.0-rc.8.min.js", {
        onSuccess: function () {
            $msstart.getSignedInUserAsync()
                .then(function (player) {
                    this.playerId = player.playerId;
                    console.log("MSStart getSignedInUserAsync", player);
                    callback();
                }.bind(this))
                .catch(function (error) {
                    console.log("MSStart getSignedInUserAsync error", error);
                    callback();
                });
        }.bind(this),
        onFailure: onFailure
    });
};

cleverapps.MSStartSocial.prototype.isLoggedIn = function () {
    return !!this.playerId;
};

cleverapps.MSStartSocial.prototype.getUserID = function () {
    return this.playerId;
};

cleverapps.MSStartSocial.prototype.login = function (onSuccess, onFailure) {
    $msstart.signInAsync().then(function (player) {
        this.playerId = player.playerId;
        console.log("MSStart login", player);
        cleverapps.SocialManager.onLogin();
        onSuccess();
    }.bind(this)).catch(function (error) {
        console.log("MSStart login error", error);
        cleverapps.SocialManager.onFailedLogin();
        onFailure && onFailure();
    });
};

cleverapps.MSStartSocial.prototype.shareDialog = function (data, callback) {
    console.log("MS Start share", data);

    $msstart.shareAsync({
        title: data.name,
        text: data.description,
        image: data.picture
    }).then(function () {
        console.log("MSStart share: success!");
        callback(cleverapps.Social.CODE_SUCCEED);
    }).catch(function (error) {
        console.log("MSStart share error", error);
        callback(cleverapps.Social.CODE_FAILED);
    });
};

cleverapps.MSStartSocial.prototype.aboutMe = function (callback) {
    callback({
        name: "MSN Player " + (this.getUserID() + "").substring(0, 18),
        first_name: "MSN Player"
    });
};

cleverapps.MSStartSocial.prototype.getAccessToken = function () {
    return "msstart";
};

cleverapps.FakeMSStart = {
    getSignedInUserAsync: cleverapps.fakePromise(),
    signInAsync: cleverapps.fakePromise(),
    shareAsync: cleverapps.fakePromise(),
    loadAdsAsync: cleverapps.fakePromise(),
    showAdsAsync: cleverapps.fakePromise(),
    showAdsCompletedAsync: cleverapps.fakePromise(),
    showDisplayAdsAsync: cleverapps.fakePromise(),
    hideDisplayAdsAsync: cleverapps.fakePromise(),
    scheduleNotificationAsync: cleverapps.fakePromise()
};