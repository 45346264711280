/**
 * Created by vladislav on 24.02.2021
 */

var AdsMenu = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.setAnchorPoint2();
        this.properties = properties;
        this.eventBus = eventBus;

        this.generatesEvents = this.properties.generatesEvents || [];
        this.listensEvents = this.properties.listensEvents || [];

        if (!properties.isPreview) {
            this.actions = [];

            this.eventBus.on("fingerMoveTo", function (optionId) {
                this.actions.push({
                    moveTo: true,
                    optionId: optionId
                });
            }.bind(this));

            this.eventBus.on("fingerClick", function (where) {
                if (["LEFT", "RIGHT", "UP", "DOWN"].indexOf(where) !== -1) {
                    var lastAction = this.actions[this.actions.length - 1];
                    if (!lastAction || lastAction.where !== where) {
                        this.actions.push({
                            moveTo: true,
                            where: where
                        });
                    }
                }

                this.actions.push({
                    click: true,
                    where: where
                });
            }.bind(this));

            this.eventBus.on("menuArrow", function (options) {
                if (!this.arrows) {
                    this.arrows = {};
                }
                this.arrows[options.direction] = options.component;
                this.arrows[options.direction].baseScale = options.component.getScale();
            }.bind(this));

            if (properties.delay) {
                setTimeout(this.start.bind(this), properties.delay);
            } else {
                this.eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.start.bind(this), this);
            }
        }

        this.setContentSize(this.properties.width, this.properties.height);
    },

    start: function () {
        var replaceParentForNested = function (component, newParent) {
            component.nestedComponents.forEach(function (nestedComponent) {
                nestedComponent.replaceParentSamePlace(newParent);
                nestedComponent.basePosition = nestedComponent.getPosition();
                replaceParentForNested(nestedComponent, newParent);
            });
        };
        this.nestedComponents.forEach(function (option) {
            replaceParentForNested(option, option);

            if (!this.properties.option) {
                cleverapps.UI.applyHover(option);
                cleverapps.UI.onClick(option, this.pick.bind(this, option));
            }

            option.baseScale = option.getScale();
        }.bind(this));

        if (this.properties.visibleOptions < this.nestedComponents.length) {
            this.initScroll();
        }

        var scene = cleverapps.scenes.getRunningScene(this);
        var finger = new FingerView(this.properties.finger ? { image: bundles[this.properties.finger.bundle].frames[this.properties.finger.image] } : {});

        finger.scheduleUpdate();
        finger.update = function () {
            var updateScale = function (component) {
                var locationInNode = component.getRelativeCoordinates(finger);
                var rect = cc.rect(0, 0, component.width, component.height);
                if (cc.rectContainsPoint(rect, locationInNode)) {
                    component.setScale(component.baseScale + 0.05);
                } else {
                    component.setScale(component.baseScale);
                }
            };

            if (this.arrows) {
                for (var i in this.arrows) {
                    updateScale(this.arrows[i]);
                }
            }

            this.nestedComponents.forEach(function (option) {
                updateScale(option);
            });
        }.bind(this);

        finger.setLocalZOrder(1000);
        finger.finger.setOpacity(255);
        finger.setVisible(true);
        scene.addChild(finger);
        finger.setPositionRound(scene.width + finger.width, 0);
        finger.replaceParentSamePlace(this.parent);

        var optionId = this.properties.option;
        var target, option;
        if (optionId !== undefined) {
            if (this.nestedComponents.length < optionId || optionId < 1) {
                return;
            } 
            option = this.nestedComponents[optionId - 1];
            target = finger.getRelativeCoordinates(option);
            finger.y = target.y;
        }

        this.actions.forEach(function (action) {
            if (action.moveTo) {
                finger.addAction(function (f) {
                    if (action.where && !this.arrows) {
                        f();
                        return;
                    }

                    var where = action.where ? this.arrows[action.where] : this.nestedComponents[action.optionId - 1];
                    var pos = finger.getRelativeCoordinates(where);
                    finger.runAction(new cc.Sequence(
                        new cc.MoveTo(0.7, pos.x, pos.y).easing(cc.easeOut(1)),
                        new cc.DelayTime(0.2),
                        new cc.CallFunc(f)
                    ));
                }.bind(this));
            }
            if (action.click) {
                if (this.arrows) {
                    finger.press({ noCircle: true });
                    finger.addAction(function (f) {
                        var arrow = this.arrows[action.where];
                        if (arrow && arrow.onClick) {
                            arrow.onClick();
                        }

                        if (this.properties.clickSound) {
                            cleverapps.audio.playSound(bundles[this.properties.clickSound.bundle].urls[this.properties.clickSound.sound]);
                        }

                        finger.unscheduleUpdate();
                        var scale = arrow.getScale();
                        arrow.runAction(new cc.Sequence(
                            new cc.ScaleTo(0.1, scale * 1.2),
                            new cc.ScaleTo(0.3, scale).easing(cc.easeBackOut()),
                            new cc.CallFunc(function () {
                                finger.scheduleUpdate();
                            })
                        ));

                        f();
                    }.bind(this));
                    finger.unPress();
                    finger.delay(0.2);
                }
            }
        }, this);

        finger.addAction(function (f) {
            var actions = [];

            if (target) {
                actions.push(new cc.MoveTo(0.7, target.x, target.y).easing(cc.easeOut(1)));
            }
            actions.push(new cc.DelayTime(0.2));
            actions.push(new cc.CallFunc(f));

            finger.runAction(new cc.Sequence(actions));
        });
        finger.addAction(function (f) {
            finger.unscheduleUpdate();
            if (this.currentScrollComponent || option) {
                this.pick(this.currentScrollComponent || option, finger);
            }

            f();
        }.bind(this));
        if (this.currentScrollComponent || option) {
            finger.press({ noCircle: true });
        }
        finger.addAction(function (f) {
            if (this.properties.clickSound) {
                cleverapps.audio.playSound(bundles[this.properties.clickSound.bundle].urls[this.properties.clickSound.sound]);
            }
            if (this.properties.selectionSound) {
                cleverapps.audio.playSound(bundles[this.properties.selectionSound.bundle].urls[this.properties.selectionSound.sound]);
            }

            if (this.generatesEvents.indexOf("menuItemClicked") !== -1 && optionId) {
                this.eventBus.trigger("menuItemClicked", optionId);
            }

            f();
        }.bind(this));
        finger.unPress();
        finger.fadeOut();
        finger.run({});
    },

    initScroll: function () {
        if (!this.nestedComponents.length) {
            return;
        }

        var step = this.step = 0;

        this.nestedComponents.forEach(function (component, index) {
            component.setCascadeOpacityEnabled(true);

            if (step === index) {
                if (component.show) {
                    component.show();
                } else {
                    component.setVisible(true);
                }
            } else if (component.hide) {
                component.hide();
            } else {
                component.setVisible(false);
            }
        });

        this.eventBus.on("scrollToNextMenuItem", function (type) {
            this.previousStep = this.step;
            this.step++;
            if (this.step >= this.nestedComponents.length) {
                this.step = 0;
            }
            this.scrollTo(true, type);
        }.bind(this));

        this.eventBus.on("scrollToPreviousMenuItem", function (type) {
            this.previousStep = this.step;
            this.step--;
            if (this.step < 0) {
                this.step = this.nestedComponents.length - 1;
            }
            this.scrollTo(false, type);
        }.bind(this));
    },

    scrollTo: function (right, type) {
        var dx = 0, dy = 0;

        if (type === AdsMenu.SCROLL_DIRECTION.HORIZONTALLY) {
            dx = right ? 1 : -1;
        } else if (type === AdsMenu.SCROLL_DIRECTION.VERTICALLY) {
            dy = right ? 1 : -1;
        }

        var currentComponent = this.currentScrollComponent = this.nestedComponents[this.step];
        var currentBasePosition = currentComponent.getPosition();
        currentComponent.x += -dx * AdsMenu.SCROLL_DISTANCE;
        currentComponent.y += -dy * AdsMenu.SCROLL_DISTANCE;

        if (currentComponent.show) {
            currentComponent.show();
        } else {
            currentComponent.setVisible(true);
        }

        currentComponent.setOpacity(0);

        currentComponent.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.FadeIn(0.1),
                new cc.MoveTo(0.4, currentBasePosition).easing(cc.easeBackOut())
            )
        ));

        var previousComponent = this.nestedComponents[this.previousStep];
        var previousBasePosition = previousComponent.getPosition();
        previousComponent.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveBy(0.2, dx * AdsMenu.SCROLL_DISTANCE, dy * AdsMenu.SCROLL_DISTANCE).easing(cc.easeIn(3)),
                new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.FadeOut(0.1)
                )
            ),
            new cc.CallFunc(function () {
                if (previousComponent.hide) {
                    previousComponent.hide();
                } else {
                    previousComponent.setVisible(false);
                }

                previousComponent.setPosition(previousBasePosition);
            })
        ));
    },

    pick: function (option, finger) {
        var scale = option.getScale();
        option.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.ScaleTo(0.1, scale * 1.1),
            new cc.CallFunc(function () {
                if (option.spine && option.spine.data.skins.backlight) {
                    option.spine.setSkin("backlight");
                }
            }),
            new cc.ScaleTo(0.3, scale).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.SCENE_COMPLETED) !== -1) {
                    setTimeout(function () {
                        this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
                    }.bind(this), 1000);
                }

                if (this.generatesEvents.indexOf("menuItemClicked") !== -1) {
                    this.eventBus.trigger("menuItemClicked");
                }

                if (finger && finger.scheduleUpdate) {
                    finger.scheduleUpdate();
                }
            }.bind(this))
        ));
    }
});

AdsMenu.BUNDLES = ["ads_menu"];
AdsMenu.SCROLL_DISTANCE = 600;

AdsMenu.SCROLL_DIRECTION = {
    HORIZONTALLY: 1,
    VERTICALLY: 2
};

AdsMenu.PROPERTIES = [{
    name: "visibleOptions", type: "number", value: 3, bubbleUp: true
}, {
    name: "option", type: "number", value: "1", bubbleUp: true
}, {
    name: "clickSound", type: "sound", bundles: ["ads_sfx_common", "ads_background_music"], value: { bundle: "ads_sfx_common" }
}, {
    name: "selectionSound", type: "sound", bundles: ["ads_sfx_common", "ads_background_music"], value: { bundle: "ads_sfx_common", sound: "character_selection" }
}, {
    name: "width", type: "number", value: 600
}, {
    name: "height", type: "number", value: 200
}, {
    name: "clipCompletedDelay", type: "number", value: 1000
}, {
    name: "generatesEvents", type: "string", items: [Wysiwyg.EVENTS.SCENE_COMPLETED, "menuItemClicked"], multiselect: true, value: [Wysiwyg.EVENTS.SCENE_COMPLETED]
}, {
    name: "listensEvents", type: "string", items: [Wysiwyg.EVENTS.SCENE_STARTED], multiselect: true, value: [Wysiwyg.EVENTS.SCENE_STARTED]
}, {
    name: "finger", type: "image", bundles: ["ads_finger"], value: { image: "finger_png", bundle: "ads_finger" }
}];