/**
 * Created by vladislav on 26.01.2021
 */

BonusWorld.TYPE_VALENTINES = "valentines";
BonusWorld.TYPE_EASTER = "easter";
BonusWorld.TYPE_SUMMER = "summer";
BonusWorld.TYPE_HALLOWEEN = "halloween";

var BonusWorldConfig = {};

BonusWorldConfig[BonusWorld.TYPE_VALENTINES] = {
    bundle: "bonus_world_valentines",
    episode: "bonus_world_valentines",
    sideBarJson: bundles.sidebar.jsons.bonus_world_valentines_icon_json,
    localizationPrefix: "Valentines"
};

BonusWorldConfig[BonusWorld.TYPE_EASTER] = {
    bundle: "bonus_world_easter",
    episode: "bonus_world_easter",
    sideBarJson: bundles.sidebar.jsons.bonus_world_easter_icon_json,
    localizationPrefix: "Easter"
};

BonusWorldConfig[BonusWorld.TYPE_SUMMER] = {
    bundle: "bonus_world_summer",
    episode: "bonus_world_summer",
    sideBarJson: bundles.sidebar.jsons.bonus_world_summer_icon_json,
    localizationPrefix: "Summer"
};

BonusWorld.getConfigByEpisode = function (episodeNo) {
    for (var type in BonusWorldConfig) {
        var config = BonusWorldConfig[type];
        if (config && config.episode === episodeNo) {
            return config;
        }
    }
};
