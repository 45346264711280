/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsText = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint2();

        this.synth = window.speechSynthesis;

        properties = this.properties = properties || {};
        this.eventBus = eventBus;

        var text = properties.LocalizedText && Wysiwyg.getValueForResolution(properties.LocalizedText) ? Wysiwyg.getValueForResolution(properties.LocalizedText)[cleverapps.settings.language] : properties.text;
        text = this.text = text || "Sample text";

        var scale = properties.scale || 1;

        var title = this.title = cleverapps.UI.generateOnlyText(text, properties.font ? Wysiwyg.getValueForResolution(properties.font) : cleverapps.styles.FONTS.BUTTON_TEXT);
        title.setFontSize(Math.floor(title.getFontSize() * scale));
        if (properties.width && properties.height) {
            var maxWidth = cc.configuration.getMaxTextureSize() / cc.view.getDevicePixelRatio();
            if (properties.width * scale > maxWidth) {
                scale = maxWidth / properties.width;
            }
            title.setFontSize(Math.floor(title.getFontSize() * Math.min(
                2,
                Math.max(1, properties.width * scale / title.width, properties.height * scale / title.height)
            )));
            title.setDimensions(properties.width * scale, 0);
            title.fitTo(undefined, properties.height * scale);
        }
        title.setScale(1 / scale);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.addChild(title);

        this.setContentSize(properties.width || 100, properties.height || 100);
        title.setPositionRound(this.width / 2, this.height / 2);

        if (this.properties.isPreview && cleverapps.wysiwyg.hierarchy.selectedItem.assetName === "AdsText" && properties.width && properties.height) {
            var realTextBox = new cc.Scale9Sprite(bundles.editor_controls.frames.component_frame);
            realTextBox.setContentSize2(title.width * title.scale, title.height * title.scale);
            this.addChild(realTextBox);
            realTextBox.setPositionRound(this.width / 2, this.height / 2);
        }

        eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, function () {
            if (this.visible) {
                this.showText();
                this.speak();
            }
        }.bind(this));
    },

    setVisible: function (visible) {
        this._super(visible);

        if (visible) {
            this.showText();
            this.speak();
        }
    },

    showText: function () {
        if (this.properties.appear === "byLetter") {
            this.title.byLetterAnimation({
                speed: this.properties.typingSpeed || 0.02,
                callback: function () {
                    setTimeout(function () {
                        if (!this.properties.isPreview) {
                            this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
                            this.removeFromParent(true);
                        }
                    }.bind(this), this.properties.beforeCompleteDelay === undefined ? 1700 : this.properties.beforeCompleteDelay);
                }.bind(this)
            });
        } else if (this.properties.appear === "writing") {
            this.title.byLetterAnimation({ speed: this.properties.typingSpeed || 0.15 });
        }
    },

    speak: function () {
        if (this.properties.speechSynthesis && !this.properties.isPreview) {
            var utterThis = new SpeechSynthesisUtterance(this.text);
            if (this.properties.voice === "Male") {
                utterThis.voice = this.synth.getVoices().filter(function (voice) {
                    return voice.lang.indexOf(cleverapps.settings.language) !== -1 && voice.name.indexOf("Male") !== -1;
                })[0];

                if (!utterThis.voice && cleverapps.settings.language === "en") {
                    cleverapps.notification.create("male(" + cleverapps.settings.language + ") voice not found!!!\n" + this.synth.getVoices().map(function (voice) {
                        return voice.lang + " " + voice.name;
                    }).join("\n"));
                }
            }
            utterThis.lang = cleverapps.settings.language;
            this.synth.speak(utterThis);
        }
    }
});

AdsText.PROPERTIES = [{
    name: "LocalizedText", type: "localizedText", bubbleUp: true
}, {
    name: "font", type: "font", bubbleUp: true
}, {
    name: "appear", type: "string", items: ["instant", "byLetter", "writing"], value: "instant"
}, {
    name: "beforeCompleteDelay", type: "number", value: 1700
}, {
    name: "typingSpeed", type: "number", placeHolderText: "in seconds e.g.: 0.02"
}, {
    name: "speechSynthesis", type: "checkBox"
}, {
    name: "voice", type: "string", items: ["Female", "Male"], value: "Female"
}];