/**
 * Created by vladislav on 9/6/2022
 */

var Microsoft = function () {
    Platform.call(this, "microsoft");
};

Microsoft.prototype = Object.create(Platform.prototype);
Microsoft.prototype.constructor = Microsoft;

Microsoft.prototype.getDeviceId = function () {
    return this.deviceId;
};

Microsoft.prototype.init = function (callback) {
    this.onRequestDeviceIdCallback = callback;
    cleverapps.MicrosoftPlugin.requestDeviceId();
};

Microsoft.prototype.pushSender = function () {
    return cleverapps.LocalPushes.MicrosoftSender;
};

Microsoft.prototype.onRequestDeviceId = function (data) {
    this.deviceId = data && data.deviceId;

    if (this.onRequestDeviceIdCallback) {
        this.onRequestDeviceIdCallback();
        delete this.onRequestDeviceIdCallback;
    }
};

Microsoft.prototype.getAccessToken = function () {
    return "microsoft";
};

Microsoft.prototype.canReview = function () {
    return true;
};

Microsoft.prototype.requestReview = function () {
    this.reviewCallback = function (result) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.NATIVE_REVIEW + result);
    };
    cleverapps.MicrosoftPlugin.requestReview();
};

Microsoft.prototype.onReviewFinish = function (res) {
    if (this.reviewCallback) {
        if (res && !res.error) {
            this.reviewCallback(true);
        }
        delete this.reviewCallback;
    }
};

Microsoft.prototype.calcChannel = function () {
    MicrosoftNotifier(JSON.stringify({
        handler: "social",
        method: "getCampaignId"
    }));
};

Microsoft.prototype.onReceiveCampaignId = function (res) {
    console.log("Received campaign Id ", JSON.stringify(res));
    if (res && res.campaignId) {
        cleverapps.user.setChannel(res.campaignId);
        cleverapps.serverLog(cleverapps.user.id + " microsoft onReceiveCampaignId - " + res.campaignId);
    }
};

Microsoft.prototype.needWindowForLogin = function () {
    return true;
};

Microsoft.prototype.isFullscreenAvailable = function () {
    return true;
};

Microsoft.prototype.toggleFullScreen = function () {
    MicrosoftNotifier(JSON.stringify({
        handler: "app",
        method: "toggleFullScreen"
    }));
};

cleverapps.MicrosoftPlugin = {
    requestDeviceId: function () {
        MicrosoftNotifier(JSON.stringify({
            handler: "social",
            method: "getDeviceId"
        }));
    },

    requestReview: function () {
        MicrosoftNotifier(JSON.stringify({
            handler: "store",
            method: "review"
        }));
    },

    FBLogin: function () {
        MicrosoftNotifier(JSON.stringify({
            handler: "social",
            method: "FBLogin",
            FBAppId: cleverapps.config.instant.appId
        }));
    }
};
