/**
 * Created by mac on 4/11/20
 */

cleverapps.UI.HideHandler = function (target, direction) {
    this.target = target;
    this.initialized = false;
    this.isShow = true;
    this.paused = false;

    this.setDirection(direction);

    if (!target.hide) {
        target.hide = target.createListener(this.hide.bind(this));
    }

    if (!target.show) {
        target.show = target.createListener(this.show.bind(this));
    }

    target.isShown = target.createListener(function () {
        return this.isShow;
    }.bind(this));

    switch (this.direction) {
        case cleverapps.UI.SCALE_ANIMATION:
            if (!target.hideAnimation) {
                target.hideAnimation = target.createListener(this.scaleHideAnimation.bind(this));
            }
            if (!target.showAnimation) {
                target.showAnimation = target.createListener(this.scaleShowAnimation.bind(this));
            }
            break;
        case cleverapps.UI.OPACITY_ANIMATION:
            if (!target.hideAnimation) {
                target.hideAnimation = target.createListener(this.opacityHideAnimation.bind(this));
            }
            if (!target.showAnimation) {
                target.showAnimation = target.createListener(this.opacityShowAnimation.bind(this));
            }
            break;
        default:
            if (!target.hideAnimation) {
                target.hideAnimation = target.createListener(this.hideAnimation.bind(this));
            }
            if (!target.showAnimation) {
                target.showAnimation = target.createListener(this.showAnimation.bind(this));
            }
    }

    target.showForDuration = target.createListener(this.showForDuration.bind(this));

    target.toggle = target.createListener(this.toggle.bind(this));

    target.pauseHideHandler = target.createListener(this.pauseHideHandler.bind(this));
    target.resumeHideHandler = target.createListener(this.resumeHideHandler.bind(this));

    if (target.parent) {
        this.initialize();
    }
};

cleverapps.UI.HideHandler.prototype.pauseHideHandler = function () {
    this.paused = true;
};

cleverapps.UI.HideHandler.prototype.resumeHideHandler = function () {
    this.paused = false;
};

cleverapps.UI.HideHandler.prototype.toggle = function (visible, silent) {
    if (visible) {
        this.show(silent);
    } else {
        this.hide(silent);
    }
};

cleverapps.UI.HideHandler.prototype.showForDuration = function (callback, options) {
    options = options || {};

    var callbackDelay = 500 || options.callbackDelay;
    var duration = 2000 || options.duration;

    this.clearTimeouts();

    this.show();

    this.callbackTimeout = setTimeout(function () {
        callback && callback();
    }, callbackDelay);

    this.hideTimeout = setTimeout(function () {
        this.hide();

        this.clearTimeouts();
    }.bind(this), duration);
};

cleverapps.UI.HideHandler.prototype.postponeHiding = function (duration) {
    if (!this.hideTimeout) {
        return;
    }

    clearTimeout(this.hideTimeout);

    this.hideTimeout = setTimeout(function () {
        this.hide();
        this.clearTimeouts();
    }.bind(this), duration);
};

cleverapps.UI.HideHandler.prototype.clearTimeouts = function () {
    if (this.callbackTimeout) {
        clearTimeout(this.callbackTimeout);
        delete this.callbackTimeout;
    }

    if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
        delete this.hideTimeout;
    }
};

cleverapps.UI.HideHandler.prototype.showAnimation = function (silent, delay, params) {
    params = params || {};
    this.target.stopAllActions();

    if (silent) {
        this.target.setPosition(this.originalPosition);
        this.target.visible = true;
        return;
    }

    this.target.runAction(
        new cc.Sequence(
            new cc.DelayTime(delay || 0),
            new cc.Show(),
            new cc.MoveTo(params.duration || 0.6, this.originalPosition).easing(cc.easeElasticOut(0.6))
        )
    );
};

cleverapps.UI.HideHandler.prototype.hideAnimation = function (silent, delay, params) {
    params = params || {};
    this.target.stopAllActions();

    if (silent) {
        this.target.setPosition(this.hidePosition);
        this.target.visible = false;
        return;
    }

    this.target.runAction(new cc.Sequence(
        new cc.DelayTime(delay || 0),
        new cc.MoveTo(params.duration || 0.15, this.hidePosition).easing(cc.easeBackIn()),
        new cc.CallFunc(function () {
            this.target.visible = false;
        }.bind(this))
    ));
};

cleverapps.UI.HideHandler.prototype.opacityShowAnimation = function (silent, delay, params) {
    params = params || {};
    this.target.stopAllActions();

    var baseOpacity = this.target.baseOpacity || 255;

    if (silent && !delay) {
        this.target.visible = true;
        this.target.setOpacity(baseOpacity);
        return;
    }

    this.target.visible = true;
    this.target.runAction(
        new cc.Sequence(
            new cc.DelayTime(delay || 0),
            new cc.FadeTo(silent ? 0 : (params.duration || 0.3), baseOpacity)
        )
    );
};

cleverapps.UI.HideHandler.prototype.opacityHideAnimation = function (silent, delay) {
    this.target.stopAllActions();

    if (silent && !delay) {
        this.target.setOpacity(0);
        this.target.visible = false;
        return;
    }

    this.target.runAction(new cc.Sequence(
        new cc.DelayTime(delay || 0),
        new cc.FadeTo(silent ? 0 : 0.15, 0),
        new cc.CallFunc(function () {
            this.target.visible = false;
        }.bind(this))
    ));
};

cleverapps.UI.HideHandler.prototype.scaleShowAnimation = function (silent, delay) {
    this.target.stopAllActions();

    var scaleX = this.target.baseScaleX || this.target.baseScale || 1;
    var scaleY = this.target.baseScaleY || this.target.baseScale || 1;

    if (silent && !delay) {
        this.target.visible = true;
        this.target.setScale(scaleX, scaleY);
        return;
    }

    this.target.visible = true;
    this.target.runAction(
        new cc.Sequence(
            new cc.DelayTime(delay || 0),
            new cc.ScaleTo(silent ? 0 : 0.3, scaleX, scaleY)
        )
    );
};

cleverapps.UI.HideHandler.prototype.scaleHideAnimation = function (silent, delay) {
    this.target.stopAllActions();

    if (silent && !delay) {
        this.target.setScale(0);
        this.target.visible = false;
        return;
    }

    this.target.runAction(new cc.Sequence(
        new cc.DelayTime(delay || 0),
        new cc.ScaleTo(silent ? 0 : 0.15, 0),
        new cc.CallFunc(function () {
            this.target.visible = false;
        }.bind(this))
    ));
};

cleverapps.UI.HideHandler.prototype.show = function (silent, delay, params) {
    if (this.isShow === true && !silent) {
        return;
    }

    this.isShow = true;

    if (!this.initialized || this.paused) {
        return;
    }

    this.clearTimeouts();

    if (this.target.onShow) {
        this.target.onShow(silent, delay, params);
    }
    this.target.showAnimation(silent, delay, params);
};

cleverapps.UI.HideHandler.prototype.hide = function (silent, delay, params) {
    var force = params && params.force;
    if (this.isShow === false && !silent && !force) {
        return;
    }

    this.isShow = false;

    if (!this.initialized || this.paused) {
        return;
    }

    if (this.target.onHide) {
        this.target.onHide(silent, delay, params);
    }
    this.target.hideAnimation(silent, delay, params);
};

cleverapps.UI.HideHandler.prototype.refresh = function () {
    if ([cleverapps.UI.SCALE_ANIMATION, cleverapps.UI.OPACITY_ANIMATION].includes(this.direction)) {
        return;
    }

    if (cc.pointEqualToPoint(this.hidePosition, this.target.getPosition(), 0.5)) {
        if (cleverapps.config.debugMode && this.isShow) {
            console.warn("refresh hidePosition", this.target);
        }
        return;
    }

    this.initialized = false;

    delete this.target.originalPosition;
    delete this.target.hidePosition;

    if (this.target.parent) {
        this.initialize();
    }
};

cleverapps.UI.HideHandler.prototype.initialize = function () {
    if (this.initialized) {
        return;
    }

    this.initialized = true;

    if (this.target.originalPosition) {
        this.originalPosition = this.target.originalPosition;
    } else {
        this.originalPosition = this.target.getPosition();
    }

    if (this.target.hidePosition) {
        this.hidePosition = this.target.calculatePositionRound(this.target.hidePosition);
    } else {
        var absoluteOriginalPosition = this.target.getParent().convertToWorldSpace(this.originalPosition);
        var winSize = cleverapps.UI.getWinSize(), d;

        var direction = this.direction;
        if (direction === cleverapps.UI.AUTO) {
            var position = this.target.getPosition();

            this.target.setPositionRound(this.originalPosition);

            var box = this.target.getBoundingBox();

            this.target.setPositionRound(position);

            var deltaX = Math.max(0, Math.min(box.x, winSize.width - box.x - box.width));
            var deltaY = Math.max(0, Math.min(box.y, winSize.height - box.y - box.height));
            direction = deltaX > deltaY ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;
        }

        var width = this.target.width * this.target.scaleX;
        var height = this.target.height * this.target.scaleY;
        var middle = cc.p(
            absoluteOriginalPosition.x + width * (0.5 - this.target.anchorX),
            absoluteOriginalPosition.y + height * (0.5 - this.target.anchorY)
        );

        var hideMiddle;

        if (direction === cleverapps.UI.VERTICAL) {
            d = (absoluteOriginalPosition.y > winSize.height / 2) ? (winSize.height - middle.y) : -middle.y;
            hideMiddle = cc.p(middle.x, middle.y + 2 * d);
        } else {
            d = (absoluteOriginalPosition.x > winSize.width / 2) ? (winSize.width - middle.x) : -middle.x;
            hideMiddle = cc.p(middle.x + 2 * d, middle.y);
        }

        this.hidePosition = this.target.getParent().convertToNodeSpace(cc.p(
            hideMiddle.x + width * (this.target.anchorX - 0.5),
            hideMiddle.y + height * (this.target.anchorY - 0.5)
        ));
    }

    if (!this.paused) {
        if (this.isShow === false) {
            this.target.hideAnimation(true);
        } else {
            this.target.showAnimation(true);
        }
    }
};

cleverapps.UI.HideHandler.prototype.setDirection = function (direction) {
    this.direction = direction || cleverapps.UI.HORIZONTAL;
};