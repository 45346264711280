/**
 * Created by vladislav on 9/6/2022
 */

var AndroidBase = function (source) {
    Platform.call(this, source);
};

AndroidBase.prototype = Object.create(Platform.prototype);
AndroidBase.prototype.constructor = AndroidBase;

AndroidBase.prototype.pushSender = function () {
    return cleverapps.LocalPushes.AndroidWebViewSender;
};

AndroidBase.prototype.canReview = function () {
    return typeof ReviewPlugin !== "undefined";
};

AndroidBase.prototype.requestReview = function () {
    this.onRequestReviewListener = function (result) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.NATIVE_REVIEW + result);
    };

    console.log("Android Review - start");
    ReviewPlugin.requestReview();
};

AndroidBase.prototype.onRequestReview = function (result) {
    console.log("Android Review - finish, result - " + result);
    if (this.onRequestReviewListener) {
        this.onRequestReviewListener(result);
        delete this.onRequestReviewListener;
    }
};

AndroidBase.prototype._inviteFriendsNative = function (options, callback) {
    this.onInviteFriendsListener = callback;

    var data = {
        title: options.title,
        message: options.message,
        link: this.getExternalUrl("res/applink.html")
    };

    MessagesPlugin.sendMessageAllVariants(JSON.stringify(data));
};

AndroidBase.prototype.onInviteFriends = function (code) {
    if (this.onInviteFriendsListener) {
        this.onInviteFriendsListener(code);
        delete this.onInviteFriendsListener;
    }
};

AndroidBase.prototype.needWindowForLogin = function () {
    return true;
};

AndroidBase.prototype.calcChannel = function (options) {
    if (options.isNewUser) {
        cleverapps.Adversting.getInstallReferrer();
    }
};

AndroidBase.prototype.closeApplication = function () {
    if (typeof AppActivityPlugin !== "undefined") {
        AppActivityPlugin.closeApplication();
    }
};