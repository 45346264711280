/**
 * Created by andrey on 23.10.2020.
 */

var AdminLevelDragShadow = cc.Scale9Sprite.extend({
    ctor: function(adminLevel) {
        this.adminLevel = adminLevel;

        var frame = cc.spriteFrameCache.getSpriteFrame(bundles.admin.frames.selected_button);
        var size = frame.getRect();
        this._super(frame, cc.rect(size.width / 2 - 1, size.height / 2 - 1, 2, 2));
        this.setContentSize2(cleverapps.styles.AdminLevelsView.cellSize);
        this.setLocalZOrder(-2);

        this.createText();

        adminLevel.on('updateEpisode', this.onUpdateEpisode.bind(this), this);
    },

    createText: function () {
        if (this.text) {
            this.text.removeFromParent();
        }

        var original = this.adminLevel.originalEpisodeNo;
        var episodeNo = this.adminLevel.episodeNo;

        var msg = "";
        if (original !== episodeNo) {
            msg = "To episode:\n" + (episodeNo);
        }

        var text = this.text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);
        text.setPositionRound(this.width / 2, this.height / 2);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        this.addChild(text);
    },

    onUpdateEpisode: function() {
        this.createText();
    }
});