/**
 * Created by Andrey Popov on 20.01.2021.
 */
var WysiwygScene = cleverapps.FixedWidthScene.extend({
    ctor: function (wysiwyg, clipIdToLoad, generate) {
        this.isWide = true;

        this.wysiwyg = wysiwyg;
        this.clipIdToLoad = clipIdToLoad;
        this.generate = generate;

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_WYSIWYG);

        var size = cleverapps.UI.getWinSize(true);

        var sceneViewHeight = size.height - cleverapps.styles.AssetsView.height - cleverapps.styles.WysiwygMenubarView.height - 2 * cleverapps.styles.WysiwygScene.margin;
        var sceneViewWidth = size.width - cleverapps.styles.HierarchyView.width - cleverapps.styles.InspectorView.width;

        var hierarchyView = this.hierarchyView = new HierarchyView(this.wysiwyg.hierarchy, { height: sceneViewHeight });
        var wysiwygPreviewView = this.wysiwygPreviewView = new WysiwygPreviewView(this.wysiwyg.wysiwygPreview, {
            height: sceneViewHeight,
            width: sceneViewWidth
        });
        var inspectorView = this.inspectorView = new InspectorView(this.wysiwyg.inspector, { height: sceneViewHeight });

        hierarchyView.setLocalZOrder(2);
        wysiwygPreviewView.setLocalZOrder(1);
        inspectorView.setLocalZOrder(2);
        var horizontalLayout = new cleverapps.Layout([hierarchyView, wysiwygPreviewView, inspectorView], {
            direction: cleverapps.UI.HORIZONTAL
        });

        var menubarView = new WysiwygMenubarView(this.wysiwyg.wysiwygMenubar);
        menubarView.setLocalZOrder(1);

        var assetsView = new AssetsView(cleverapps.wysiwyg.assets);
        var verticalLayout = new cleverapps.Layout([menubarView, horizontalLayout, assetsView], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.WysiwygScene.margin
        });

        verticalLayout.setAnchorPoint2(0, 0);
        verticalLayout.setPositionRound(0, 0);
        this.scene.addChild(verticalLayout);

        cleverapps.config.nologin = true;

        if (this.clipIdToLoad) {
            this.wysiwyg.hierarchy.loadClip(this.clipIdToLoad, function () {
                if (this.generate) {
                    this.wysiwyg.generateVideo(this.wysiwyg.hierarchy.getSelectedClip(), function () {
                        if (cleverapps.config.workerTaskId) {
                            var callback = cleverapps.waitNoMore(5000, function () {
                                window.close();
                            });
                            cleverapps.RestClient.get("http://localhost:3201/chrome/close", {}, callback, callback);
                        }
                    });
                }
            }.bind(this));
        } else {
            this.wysiwyg.hierarchy.loadDirectory(this.wysiwyg.directory);
        }
    },

    updateSize: function () {
        this.setBackground();
    },

    setBackground: function () {
        if (this.background) {
            this.background.removeFromParent();
        }
        var winSize = cleverapps.UI.getWinSize(true);
        var background = this.background = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        background.setColor(new cc.Color(255, 255, 255, 255));
        background.setContentSize2(winSize.width, winSize.height);
        background.setAnchorPoint(0, 0);
        background.setPositionRound(0, -cleverapps.UI.getSceneOffsetY());
        background.setLocalZOrder(-10);
        this.updateBackgroundScale();
        this.scene.addChild(background);
    },

    _closeAction: function () {
        cleverapps.audio.unmute();
        cleverapps.config.wysiwygMode = false;

        this._super();
    },

    getAudioStyles: function () {
        return {
            res: undefined,
            delay: 0
        };
    },

    listBundles: function () {
        return ["wysiwyg", "admin"];
    }
});

WysiwygScene.open = function (wysiwyg, clipId) {
    cleverapps.audio.mute();

    cleverapps.meta.display({
        focus: "wysiwyg",
        action: function (f) {
            cleverapps.config.wysiwygMode = true;
            wysiwyg.wysiwygPreview.forceUpdate = true;
            cleverapps.scenes.replaceScene(new WysiwygScene(wysiwyg, clipId), f);
        }
    });
};

cleverapps.styles.WysiwygScene = {
    margin: 4
};

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!cleverapps.config.debugMode || !params.wysiwyg) {
        return;
    }

    cleverapps.config.workerTaskId = params.workerTaskId;
    cleverapps.config.nologin = true;

    cleverapps.DataLoader.resetClientSession = function () {
        cleverapps.DataLoader.alerted = true;
        cleverapps.DataLoader.enabled = false;
        cleverapps.DataLoader.corrupted = false;
    };

    var calcResolutionBase = cleverapps.Settings.prototype.calcResolution;
    cleverapps.Settings.prototype.calcResolution = function () {
        if (resolutions.highstaging) {
            this.resolution = resolutions.highstaging;
        } else if (resolutions.highstagingpng) {
            this.resolution = resolutions.highstagingpng;
        }

        return calcResolutionBase();
    };

    cleverapps.Synchronizer.prototype._sync = function () {
        cleverapps.Synchronizer.prototype._sync = function () {};
        cleverapps.flags.nologin = true;
    };

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (returnClassOnly) {
            return WysiwygScene;
        }

        cleverapps.wysiwyg = new Wysiwyg(params.directory);

        cleverapps.config.wysiwygMode = true;
        var scene = new WysiwygScene(cleverapps.wysiwyg, params.clipId, params.generate);
        cleverapps.scenes.replaceScene(scene, f);
    };
}());