/**
 * Created by mac on 10/8/20
 */

var GitButtonsView = cc.Node.extend({
    ctor: function (gitButtons) {
        this._super();
        this.setAnchorPoint2();

        this.gitButtons = gitButtons;

        var styles = cleverapps.styles.GitButtonsView;
        this.pushButton = this.createButton(bundles.git.frames.push_icon, gitButtons.push.bind(gitButtons));
        this.revertButton = this.createButton(bundles.git.frames.revert_icon, gitButtons.revert.bind(gitButtons));

        var layout = new cleverapps.Layout([this.pushButton, this.revertButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.addChild(layout);
        this.setContentSize2(layout.getContentSize());
        layout.setPositionRound(this.width / 2, this.height / 2);

        gitButtons.checkStatus();
        gitButtons.on("statusChanged", this.drawStatus.bind(this), this);
        this.drawStatus();

        this.setPositionRound(styles.position);

        this.pushButton.setCascadeOpacityEnabled(true);
        this.pushButton.setCascadeColorEnabled(true);

        this.revertButton.setCascadeOpacityEnabled(true);
        this.revertButton.setCascadeColorEnabled(true);
    },

    createButton: function (icon, onClick) {
        var styles = cleverapps.styles.GitButtonsView;

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(icon),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: onClick
        });
    },

    setButtonState: function (button, state) {
        switch (state) {
            case GitButtonsView.ENABLE_BUTTON:
                button.enable();
                button.setColor(new cc.Color(255, 255, 255, 255));
                button.setOpacity(255);
                break;
            case GitButtonsView.DISABLE_BUTTON:
                button.disable();
                button.setColor(new cc.Color(255, 255, 255, 255));
                button.setOpacity(100);
                break;
            case GitButtonsView.ERROR_BUTTON:
                button.disable();
                button.setColor(new cc.Color(255, 0, 0, 255));
                button.setOpacity(255);
                break;
        }
    },

    drawStatus: function () {
        this.pushButton.setVisible(true);
        this.revertButton.setVisible(true);

        if (this.loading) {
            this.loading.removeFromParent();
            this.loading = undefined;
        }

        switch (this.gitButtons.status) {
            case GitButtons.NEED_PUSH:
                this.setButtonState(this.pushButton, GitButtonsView.ENABLE_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.DISABLE_BUTTON);
                break;
            case GitButtons.CAN_PUSH:
                this.setButtonState(this.pushButton, GitButtonsView.ENABLE_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ENABLE_BUTTON);
                break;
            case GitButtons.NO_CHANGES:
                this.setButtonState(this.pushButton, GitButtonsView.DISABLE_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.DISABLE_BUTTON);
                break;
            case GitButtons.RUNNING_COMMAND:
                this.setButtonState(this.pushButton, GitButtonsView.DISABLE_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.DISABLE_BUTTON);
                this.pushButton.setVisible(false);
                this.revertButton.setVisible(false);

                this.loading = new cc.Sprite(bundles.git.frames.loading);
                this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
                this.loading.setPositionRound(this.width / 2, this.height / 2);
                this.addChild(this.loading);
                break;
            case GitButtons.PUSH_ERROR:
                this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ENABLE_BUTTON);
                break;
            case GitButtons.STATUS_ERROR:
                this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ERROR_BUTTON);
                break;
            case GitButtons.REVERT_ERROR:
                this.setButtonState(this.pushButton, GitButtonsView.ERROR_BUTTON);
                this.setButtonState(this.revertButton, GitButtonsView.ERROR_BUTTON);
                break;
        }
    }
});

GitButtonsView.ENABLE_BUTTON = 0;
GitButtonsView.DISABLE_BUTTON = 1;
GitButtonsView.ERROR_BUTTON = 2;

cleverapps.styles.GitButtonsView = {
    button: {
        width: 60,
        height: 60
    },
    margin: 10,
    position: {
        x: { align: "left", dx: 10 },
        y: { align: "top", dy: -70 }
    }
};