/**
 * Created by spepa on 24.01.2023
 */

CopyPaste = function () {
    this.buffer = [];

    if (["tile3", "solitaire"].indexOf(cleverapps.config.type) !== -1) {
        this.handler = TileHandler;
    }

    this.onChangeListener = function () {};
};

CopyPaste.prototype.copy = function () {
    if (!this.handler) {
        cleverapps.notification.create("Copy not implemented");
        return;
    }

    this.buffer = this.handler.listItems().map(this.handler.serialize);
    this.update();
};

CopyPaste.prototype.paste = function () {
    if (this.buffer.length === 0) {
        return;
    }

    var commands = this.buffer.map(this.handler.deserialize);
    Editor.currentEditor.history.add({
        redo: function () {
            commands.forEach(function (action) {
                action.redo();
            });
        },
        undo: function () {
            commands.forEach(function (action) {
                action.undo();
            });
        }
    });
};

CopyPaste.prototype.update = function () {
    this.onChangeListener(this.handler.listItems().length > 0, this.buffer.length > 0);
};