/**
 * Created by denis on 3 june 2020
 */

var FingerControl = {
    // tmp function
    // replaced by pointTo
    pointToLocation: function (parent, position, callback) {
        var finger = new FingerViewEx();
        parent.addChild(finger);

        finger.setLocalZOrder(999);
        finger.fadeIn(position);
        finger.delay(0.5);
        finger.press();
        finger.addAction(function (next) {
            cleverapps.audio.playSound(bundles.main.urls.click_effect);
            next();
        });
        finger.unPress();
        finger.fadeOut();
        finger.run({
            looped: false,
            onFinish: function () {
                finger.removeFromParent();

                if (callback) {
                    callback();
                }
            }
        });
    },

    pointTo: function (targetNode, onPress, onUnPress, callback) {
        var scene = cleverapps.scenes.getRunningScene(targetNode).scene;
        var worldPos = scene.getRelativeCoordinates(targetNode);

        var finger = new FingerViewEx();
        scene.addChild(finger);

        finger.setLocalZOrder(999);
        finger.fadeIn({
            x: worldPos.x,
            y: worldPos.y
        });
        finger.delay(0.5);
        finger.press();
        finger.addAction(function (next) {
            onPress && onPress();
            next();
        });
        finger.unPress();
        finger.addAction(function (next) {
            onUnPress && onUnPress();
            next();
        });
        finger.fadeOut();
        finger.run({
            looped: false,
            onFinish: function () {
                finger.removeFromParent();
                callback && callback();
            }
        });
    }
};

FingerControl.MainScene = {
    pressPlayButton: function (callback) {
        var playButton = $(PlayButtonView).get();
        FingerControl.pointTo(playButton, function () {
            playButton.button && playButton.button.onTouchBegan();
            cleverapps.audio.playSound(bundles.main.urls.click_effect);
        }, function () {
            playButton.button && playButton.button.onTouchEnded();
        }, function () {
            callback();
        });
    },

    pressCurrentLevel: function (callback) {
        FingerControl.MainScene.pressLevel(levels.user.level, callback);
    },

    pressLevel: function (levelNo, callback) {
        var pagesView = $(PagesView).get();
        pagesView.container.getChildren().forEach(function (episodeView) {
            if (episodeView.pageNo === levels.user.episode) {
                var levelNode = episodeView.levelViews[levelNo];
                FingerControl.pointTo(levelNode, function () {
                    levelNode.onTouchBegan();
                    cleverapps.audio.playSound(bundles.main.urls.click_effect);
                }, function () {
                    levelNode.onTouchEnded();
                }, function () {
                    callback();
                });
            }
        });
    }
};

FingerControl.VictoryWindow = {
    pressButton: function (buttonClass, idleAction, callback) {
        var button = $(buttonClass).get();
        FingerControl.pointTo(button, function () {
            if (idleAction) {
                button.action();
            }
            callback();
        });
    }
};

FingerControl.LeadersWindow = {
    pressTab: function (tabName, callback) {
        var currentWindow = cleverapps.windows.currentWindow();
        if (currentWindow) {
            FingerControl.pointTo(currentWindow.tabs.buttonsById[tabName], function () {
                currentWindow.tabs.activateTab(tabName);
                callback();
            });
        }
    }
};