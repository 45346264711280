/**
 * Created by slava on 4/21/17.
 */

cleverapps.MyMailRuPayments = function () {
    cleverapps.RestPayments.call(this);

    for (var j in cleverapps.config.products) {
        var product = cleverapps.config.products[j];

        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedId = product.id.mymailru + "";
    }
};

cleverapps.MyMailRuPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
cleverapps.MyMailRuPayments.prototype.constructor = cleverapps.MyMailRuPayments;

cleverapps.MyMailRuPayments.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("mymailrupayments", function () {
        this.listeners = {
            status: function () {
            },
            income: function () {
            }
        };

        mailru.events.listen(mailru.app.events.paymentDialogStatus, function (event) {
            this.listeners.status(event);
        }.bind(this));

        mailru.events.listen(mailru.app.events.incomingPayment, function (event) {
            this.listeners.income(event);
        }.bind(this));

        callback();
    }.bind(this));
};

cleverapps.MyMailRuPayments.prototype.calcLoadedPrice = function (price) {
    var loadedPrice = Math.floor(price * cleverapps.RUB_DISCOUNTED_RATE) + " млк";

    if (cleverapps.config.debugMode && price < 10) {
        loadedPrice = "1 млк";
    }

    return loadedPrice;
};

cleverapps.MyMailRuPayments.prototype._purchaseOpen = function (product, callback) {
    this.listeners.status = function (event) {
        console.log("PAYMENT DIALOG", event);

        if (event.status === "closed") {
            this.setCancelTimeout(500);
        }
    }.bind(this);

    this.listeners.income = function (event) {
        console.log("INCOM", event);
        
        if (event.status === "success") {
            callback(cleverapps.Social.CODE_SUCCEED, product);
        } else {
            callback(cleverapps.Social.CODE_CANCELLED);
        }
    };

    cleverapps.platform.exitFullscreen(function () {
        mailru.app.payments.showDialog({
            service_id: product.id.mymailru,
            service_name: product.title,
            mailiki_price: parseInt(product.loadedPrice)
        });
    });
};

cleverapps.MyMailRuPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.MyMailRuPayments.isAppropriate = function () {
    return cleverapps.platform instanceof MyMailRu;
};
