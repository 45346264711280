/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorldMissionLogic = function (mission, isNewMission) {
    this.mission = mission;

    var feature = cleverapps.eventManager.getFeatureEvent("bonusworld");
    var type = feature && feature.options.bonusWorldType || BonusWorld.TYPE_VALENTINES;

    this.bonusWorld = new BonusWorld(mission, type);

    if (this.mission.iconForce) {
        this.mission.iconForce.text = "" + this.bonusWorld.config.localizationPrefix + ".BonusWorld.Force";
    }

    if (isNewMission) {
        this.clearSavedGames();

        cleverapps.forces.clearForce(Forces.BONUS_WORLD.id);
    }
};

BonusWorldMissionLogic.prototype.hasIntent = function () {
    return cleverapps.config.type !== "merge";
};

BonusWorldMissionLogic.prototype.willUseIntent = function (level) {
    return level.episode.isBonusWorldLevel();
};

BonusWorldMissionLogic.prototype.processEvent = function (options) {
    this.bonusWorld.onLevelPassed(options.level);
};

BonusWorldMissionLogic.prototype.clearSavedGames = function () {
    for (var levelNo = 0; levelNo < Episode.LEVELS_PER_EPISODE; levelNo++) {
        cleverapps.GameSaver.removeSave(cleverapps.GameSaver.getStoreSlot(BonusWorldConfig[this.bonusWorld.type].episode, levelNo));
    }
};