/**
 * Created by spepa on 24.10.2022
 */

var LandmarkDonorIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.landmarkdonor_icon_json
    });

    this.resetState();
};

LandmarkDonorIcon.prototype = Object.create(SideBarIcon.prototype);
LandmarkDonorIcon.prototype.constructor = LandmarkDonorIcon;

LandmarkDonorIcon.prototype.resetState = function () {
    this.available = false;
    if (cleverapps.config.editorMode || !cleverapps.environment.isMainScene() || !cleverapps.travelBook.isExpedition()) {
        return;
    }

    var data = LandmarkDonor.WORLDS[cleverapps.travelBook.getCurrentPage().id];
    if (!data || !Game.currentGame.fogs.isOpened(data.fog)) {
        return;
    }

    var donors = LandmarkDonor.GetCurrentDonorUnits();
    for (var i in donors) {
        var landmark = LandmarkDonor.GetDonorLandmark(donors[i].code);
        if (!cleverapps.unitsLibrary.isOpened(landmark)) {
            this.available = true;
            this.setAttention(Boolean(this.findWithProgress()));
            break;
        }
    }
};

LandmarkDonorIcon.prototype.findWithProgress = function () {
    var donors = LandmarkDonor.GetCurrentDonorUnits();
    for (var i in donors) {
        if (LandMarkDonorWindow.PENDING_STAGES[donors[i].code] > 0) {
            return donors[i];
        }
    }
};

LandmarkDonorIcon.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "LandmarkDonorIconClicked",
        action: function (f) {
            new LandMarkDonorWindow(this.findWithProgress());
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};

LandmarkDonorIcon.prototype.getForce = function () {
    return Landmarks.getForce(cleverapps.travelBook.getCurrentPage().id);
};