/**
 * Created by Andrey Popov on 27.02.20.
 */

cleverapps.LocalPushes.IosWebViewSender = {
    sendPush: function (info, timeout) {
        var data = cleverapps.LocalPushes.GetPushData(info);

        var title = data.title;
        var message = data.text;

        var id = info.id;
        var code = info.code;

        if (!message) {
            cleverapps.throwAsync("empty ios push - " + code);
            return;
        }
 
        webkitMessage("localPushes", {
            action: "sendPush",
            title: title,
            message: message,
            timeout: timeout,
            id: id,
            code: code
        });
    },

    cancelPush: function (info) {
        webkitMessage("localPushes", {
            action: "cancelPush",
            id: info.id,
            code: info.code
        });
    }
};