/**
 * Created by Andrey Popov on 10.11.2021
 */

var BundlesExplorer = function (atlasAnalyzer, tab) {
    this.atlasAnalyzer = atlasAnalyzer;

    cleverapps.EventEmitter.call(this);

    this.loadedBundles = Object.keys(cleverapps.bundleLoader.handles);

    this.selectedItem = undefined;
    this.selectedTab = tab || "Main";

    this.rebuildBundlesList();
};

BundlesExplorer.prototype = Object.create(cleverapps.EventEmitter.prototype);
BundlesExplorer.prototype.constructor = BundlesExplorer;

BundlesExplorer.prototype.rebuildBundlesList = function () {
    this.items = Object.keys(bundles).filter(function (name) {
        if (BundlesExplorer.SKIP_BUNDLES_WITH_PREFIXES.filter(function (prefix) {
            return name.indexOf(prefix) !== -1;
        }).length > 0) {
            return false;
        }

        var hasImages = false;
        var urls = (new Bundle(name)).listUrls();
        urls.forEach(function (url) {
            if (typeof url === "string" && url.indexOf(".png") !== -1) {
                hasImages = true;
            }
        });

        if (this.selectedTab === "Debug" && !bundles[name].debug) {
            return false;
        }

        if (this.selectedTab !== "Debug" && bundles[name].debug) {
            return false;
        }

        var isEpisode = name.startsWith("episode_") && Number.isInteger(Number.parseInt(name.split("_")[1]));
        if (this.selectedTab === "Episodes" && !isEpisode) {
            return false;
        }
        if (this.selectedTab !== "Episodes" && isEpisode) {
            return false;
        }

        return !bundles[name].injectTo && hasImages;
    }.bind(this));

    if (this.selectedTab === "Episodes") {
        this.items.sort(function (a, b) {
            return Number.parseInt(a.split("_")[1]) - Number.parseInt(b.split("_")[1]);
        });
    }

    this.trigger("rebuildItems");
};

BundlesExplorer.prototype.selectItem = function (tab, texture, frame) {
    if (tab) {
        this.selectedTab = tab;
        this.trigger("selectedTabChanged", tab);
    }

    if (texture) {
        this.selectedItem = texture;
        this.trigger("selectionChanged", tab, texture, frame);
    }

    cleverapps.setUrlHash({ atlasAnalyzer: true, tab: tab, bundleName: texture });
};

BundlesExplorer.prototype.onTabChanged = function (tab) {
    this.selectedTab = tab;
    this.rebuildBundlesList();
    cleverapps.setUrlHash({
        atlasAnalyzer: true, tab: this.selectedTab, bundleName: undefined, frame: undefined 
    });
};

BundlesExplorer.prototype.moveDown = function () {
    var index = 0;
    if (this.selectedItem) {
        var selectedItemIndex = this.items.indexOf(this.selectedItem);
        if (selectedItemIndex !== this.items.length - 1) {
            index = ++selectedItemIndex;
        }
    }

    this.selectItem(undefined, this.items[index]);
};

BundlesExplorer.prototype.moveUp = function () {
    var index = 0;
    if (this.selectedItem) {
        var selectedItemIndex = this.items.indexOf(this.selectedItem);
        if (selectedItemIndex !== 0) {
            index = --selectedItemIndex;
        }
    }

    this.selectItem(undefined, this.items[index]);
};

BundlesExplorer.SKIP_BUNDLES_WITH_PREFIXES = [
    "rotate_phone",
    "store_links",
    "ads_backgrounds_",
    "simple_background_icon_"
];