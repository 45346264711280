/**
 * Created by andrey on 23.12.2022
 */

cleverapps.RestPayments = function () {
    cleverapps.Payments.call(this);

    this.restoreProduct = undefined;
};

cleverapps.RestPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.RestPayments.prototype.constructor = cleverapps.RestPayments;

cleverapps.RestPayments.prototype._purchase = function (product) {
    this.startPurchaseChecker(product);

    this._purchaseOpen(product, function () {
        this.onPurchaseResult.apply(this, arguments);
    }.bind(this));
};

cleverapps.RestPayments.prototype._purchaseOpen = function (product, callback) {
    callback(cleverapps.Social.CODE_FAILED, product);
};

cleverapps.RestPayments.prototype.onPurchaseResult = function () {
    cleverapps.Payments.prototype.onPurchaseResult.apply(this, arguments);

    this.stopPurchaseChecker();
};

cleverapps.RestPayments.prototype._restore = function () {
    if (this.restoreRunning) {
        return;
    }

    var path = "/payments/restore/" + cleverapps.platform.source + "/" + encodeURIComponent(cleverapps.user.id);
    var data = {
        version: 2,
        consumed: this.consumed,
        yookassa: cleverapps.payments instanceof YooKassaPayments
    };

    this.restoreRunning = true;
    cleverapps.RestClient.post(path, data, function (payment) {
        var productId = payment && payment.productId;

        if (productId && Product.Create(productId)) {
            this.restoreProduct = Product.Create(productId);
            this.restorePurchase = Product.Create(productId);
            this.restorePurchase.paymentId = payment.paymentId;
            this.restorePurchase.restore = true;
        }
        this.restoreRunning = false;
    }.bind(this), function () {
        this.restoreRunning = false;
    }.bind(this));
};

cleverapps.RestPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.RestPayments.prototype.validate = function (product, onSuccess) {
    this.startChecker({
        method: "validate",
        product: product,
        onSuccess: onSuccess
    });
};

cleverapps.RestPayments.prototype.stopPurchaseChecker = function () {
    if (this.purchaseChecker) {
        this.purchaseChecker.stop();
        delete this.purchaseChecker;
    }
};

cleverapps.RestPayments.prototype.startPurchaseChecker = function (product, options) {
    this.stopPurchaseChecker();

    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck) {
        return;
    }

    if (this.purchaseHandler) {
        this.purchaseChecker = this.startChecker(Object.assign({}, options, {
            method: "open",
            product: product,
            onSuccess: function () {
                this.onPurchaseResult(cleverapps.Social.CODE_SUCCEED, product);
            }.bind(this)
        }));
    }
};

cleverapps.RestPayments.prototype.startChecker = function (options) {
    options = options || {};

    var timeout;
    var onSuccess = cleverapps.once(options.onSuccess);
    var timeouts = options.timeouts || cleverapps.RestPayments.TIMEOUTS;
    timeouts = timeouts.slice().reverse();

    var clearTimeout = function () {
        if (timeout) {
            cleverapps.timeouts.clearTimeout(timeout);
            timeout = undefined;
        }
    };

    var check = options.check || function () {
        var path = "/payments/" + options.method + "/" + cleverapps.platform.source + "/" + encodeURIComponent(cleverapps.user.id);
        var product = options.product;

        var data = {
            type: product.type,
            restore: product.restore,
            paymentId: product.paymentId,
            productId: product.loadedId,
            itemId: product.itemId,
            version: 2,
            yookassa: cleverapps.payments instanceof YooKassaPayments
        };

        if (product.openTime) {
            data.openInterval = Math.ceil((Date.now() - product.openTime + 1) / 60000);
        }

        cleverapps.RestClient.post(path, data, function (response) {
            // console.log("postWithTimeouts response", response);
            if (typeof response === "object" && response.paymentId) {
                product.paymentId = product.paymentId || response.paymentId;
                response = "ok";
            }
            if (response === "ok") {
                onSuccess();
                checker.stop();
            }
        });
    };

    var runOnce = function () {
        check();

        clearTimeout();

        if (timeouts.length > 0) {
            timeout = cleverapps.timeouts.setTimeout(runOnce, cleverapps.parseInterval(timeouts.pop()));
        }
    };

    runOnce();

    var checker = { stop: clearTimeout };
    return checker;
};

cleverapps.RestPayments.TIMEOUTS = [
    "1 second", "1 second", "3 seconds"
].concat(cleverapps.arrayFill(12, "5 seconds")).concat([
    "10 seconds", "10 seconds", "10 seconds",
    "20 seconds", "20 seconds", "20 seconds",
    "1 minute", "1 minute", "3 minutes", "5 minutes", "5 minutes"
]);
