/**
 * Created by razial on 30.01.2023
 */

cleverapps.UI.animateCard = function (options) {
    options = options || {};

    var getTargetPosition = function () {
        var position = options.position;

        if (typeof position === "function") {
            position = position();
        }

        return position.parent.convertToWorldSpace(position.point);
    };

    var movingNode = cleverapps.scenes.getMovingNode(options.cardView);

    var cardView = options.cardView;

    cardView.realParent = cardView.parent === movingNode ? cardView.realParent : cardView.parent;
    var animationId = cardView.animationId = cardView.animationId + 1 || 1;

    var position = getTargetPosition();
    var duration = options.duration || 0.7;

    var animation = options.animation;

    var isRightDirection = cardView.parent.convertToWorldSpace(cardView).x <= position.x;
    if (animation === cleverapps.UI.animateCard.ANIMATIION_BIT) {
        animation = isRightDirection ? "bit" : "bit2";
    }

    var actions = [];

    actions.push(new cc.ReplaceParent(movingNode));

    if (animation) {
        var animationTime = options.animationTime || duration;
        actions.push(new cc.SpineAction(cardView.animation, animation, animationTime, function () {
            cardView.setIdleAnimation();
        }));
    }

    if (options.delay) {
        actions.push(new cc.DelayTime(options.delay));
    }

    var finalP = movingNode.convertToNodeSpace(position);
    if (options.midpoint) {
        var firstP = movingNode.convertToNodeSpace(cardView.parent.convertToWorldSpace(cardView));
        var secondP = cc.p(firstP.x + options.midpoint.x * (isRightDirection ? 1 : -1), firstP.y + options.midpoint.y);
        actions.push(new cc.BezierTo(duration, [firstP, secondP, finalP]).easing(cc.easeIn(1)));
    } else {
        var move = new cc.MoveTo(duration, finalP);
        actions.push(options.easing ? move.easing(options.easing) : move);
    }

    if (options.replaceParentBack !== false) {
        actions.push(new cc.CallFunc(function () {
            if (animationId === cardView.animationId) {
                cardView.replaceParentSamePlace(cardView.realParent, {
                    keepScale: true
                });
                cardView.setScale(cardView.card.getScale());
            }
        }));
    }

    actions.push(new cc.CallFunc(function () {
        if (cardView.parent) {
            var position = cardView.parent.convertToNodeSpace(getTargetPosition());
            cardView.setPositionRound(position);
        }
    }));

    return new cc.Sequence(actions);
};

cleverapps.UI.animateCard.ANIMATIION_BIT = "bit";