/**
 * Created by Stepan
 */

cleverapps.YandexSocial = function () {
    console.log("Choosing html5-yandex");

    cleverapps.Social.call(this);
    this.ysdk = cleverapps.FakeYandex;

    this.canReviewGame = false;
 
    if (!["tripeaks", "fairy", "mergecraft"].includes(cleverapps.config.name)) {
        cleverapps.config.debugMode = false;
    }
};

cleverapps.YandexSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.YandexSocial.prototype.constructor = cleverapps.YandexSocial;

cleverapps.YandexSocial.prototype.getSuggestedLanguage = function () {
    if (this.ysdk.environment.i18n) {
        return this.ysdk.environment.i18n.lang;
    }
    return undefined;
};

cleverapps.YandexSocial.prototype.init = function (callback) {
    var onSuccess = cleverapps.once(function () {
        if (typeof YaGames !== "undefined" && typeof YaGames.init === "function") {
            YaGames.init().then(function (ysdk) {
                this.ysdk = ysdk;

                this.initPlayer(callback).catch(function () {
                    console.log("No user init");
                    callback();
                });
            }.bind(this));
        }
    }.bind(this));

    var loadYsdk = function () {
        cleverapps.loadSdk("//yandex.ru/games/sdk/v2", {
            onSuccess: onSuccess,
            onFailure: function () {
                console.log("Failed to load yandex sdk");
            }
        });
    };

    if (typeof Promise === "undefined") {
        cleverapps.loadSdk("//cdn.jsdelivr.net/bluebird/latest/bluebird.core.min.js", {
            onSuccess: loadYsdk,
            onFailure: function () {
                console.log("Failed to load bluebird");
            }
        });
    } else {
        loadYsdk();
    }
};

cleverapps.YandexSocial.prototype.initPlayer = function (callback) {
    return this.ysdk.getPlayer({ signed: true }).then(function (player) {
        this.playerMode = player.getMode();
        this.playerUniqueID = player.getUniqueID();
        this.accessToken = player.signature;

        if (this.playerMode === "lite") {
            callback();
            return;
        }

        this.user = cleverapps.YandexSocial.formatUserData(player);

        this.ysdk.feedback.canReview().then(function (res) {
            this.canReviewGame = res.value;
        }.bind(this));

        this.ysdk.notifications.allow();

        console.log(this.user);
        var payload = this.ysdk.environment.payload && JSON.parse(this.ysdk.environment.payload);
        console.log(payload);
        if (payload && payload.push_code) {
            cleverapps.localPushes.logClickEvent(payload.push_code);
        }
        callback();
    }.bind(this));
};

cleverapps.YandexSocial.prototype.login = function (onSuccess, onFailure) {
    this.ysdk.auth.openAuthDialog().then(function () {
        this.initPlayer(function () {
            if (this.playerMode !== "lite") {
                cleverapps.SocialManager.onLogin();
                onSuccess();
            } else {
                // For some reason playerMod could stay "lite" even after yandex reports allegedly successful login, stay unlogged
                onFailure();
            }
        }.bind(this)).catch(function () {
            onFailure();
        });
    }.bind(this)).catch(function () {
        onFailure();
    });
};

cleverapps.YandexSocial.prototype.isLoggedIn = function () {
    return this.user !== undefined;
};

cleverapps.YandexSocial.prototype.getUserID = function () {
    return this.user ? this.user.id : undefined;
};

cleverapps.YandexSocial.prototype.getAccessToken = function () {
    return "YA_" + (this.accessToken || 0);
};

cleverapps.YandexSocial.prototype.aboutMe = function (callback) {
    if (this.user) {
        callback(this.user);
    }
};

cleverapps.YandexSocial.formatUserData = function (user) {
    return {
        id: user.getUniqueID(),
        name: user.getName(),
        first_name: user.getName(),
        picture: {
            data: {
                url: user.getPhoto("small")
            }
        }
    };
};

cleverapps.FakeYandex = {
    environment: {},
    getPlayer: cleverapps.fakePromise(),
    adv: {
        showBannerAdv: cleverapps.fakePromise(),
        hideBannerAdv: cleverapps.fakePromise()
    }
};
