/**
 * Created by vladislav on 9/6/2022
 */

var MobileMyMailRu = function () {
    MyMailRu.call(this, "mobile_mm");
};

MobileMyMailRu.prototype = Object.create(MyMailRu.prototype);
MobileMyMailRu.prototype.constructor = MobileMyMailRu;

MobileMyMailRu.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};