/**
 * Created by Andrey Popov on 30.03.2023
 */

var ScoreView = cc.Scale9Sprite.extend({
    ctor: function (score) {
        this._super(bundles.game.frames.score_bg);

        var styles = cleverapps.styles.UI.ScoreView;

        this.setAnchorPoint2();
        this.setContentSize(styles.bg);

        this.scoreView = cleverapps.UI.generateOnlyText(score.getPoints(), cleverapps.styles.FONTS.SCORE_TEXT);
        this.scoreView.setPositionRound(styles.text);
        this.addChild(this.scoreView);

        this.setVisible(false);

        this.setCascadeOpacityEnabled(true);
        score.on("onShow", this.showScore.bind(this), this);
        score.on("onChange", this.updateScore.bind(this), this);
        score.on("onHide", this.hideScore.bind(this), this);
    },

    updateScore: function (points, isBigValue) {
        this.scoreView.countToAnimation(points, 0.4);
        this.scoreView.fitTo(cleverapps.styles.UI.ScoreView.maxWidth);

        if (isBigValue) {
            this.scoreView.runAction(
                new cc.Sequence(
                    new cc.ScaleTo(0.3, 1.4),
                    new cc.ScaleTo(0.1, 1.0)
                )
            );
        }
    },

    showScore: function () {
        this.setOpacity(0);

        this.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.FadeIn(0.5)
        ));
    },

    hideScore: function () {
        this.runAction(new cc.FadeOut(0.3));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCORE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.UI.ScoreView = {
    bg: {
        width: 228,
        height: 94
    },

    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    maxWidth: 170
};