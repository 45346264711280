/**
 * Created by iamso on 23.11.2020.
 */

var AdminLogEntryView = cleverapps.Layout.extend({
    ctor: function (entry, logView) {
        this.entry = entry;
        this.logView = logView;

        this._super(this.createContentItems(), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminLogEntryView.margin
        });

        this.addBg();
        this.addAuthor();
        entry.on('deleteLogEntry', this.delete.bind(this), this);
        entry.on('updateLogEntry', this.updateEntry.bind(this), this);
    },

    addBg: function () {
        if (this.logView.views.length % 2 === 0) {
            var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
            bg.setContentSize(this.getContentSize());
            bg.setPositionRound(bg.width / 2, this.height / 2);
            this.addChild(bg, -1);
        }
    },

    addAuthor: function () {
        var author = cleverapps.UI.generateOnlyText(this.entry.userName, cleverapps.styles.FONTS.ADMIN_LOG_FONT);
        author.setOpacity(70);
        this.addChild(author);
        author.setPositionRound(this.width - author.width / 2 - cleverapps.styles.AdminLogEntryView.authorPadding, this.height / 2);
    },

    createContentItems: function () {
        var styles = cleverapps.styles.AdminLogEntryView;

        var dateTxt = this.dateTxt = cleverapps.UI.generateImageText(this.entry.date.toLocaleString('ru').replace(',', ''), cleverapps.styles.FONTS.ADMIN_LOG_FONT);
        var dateCont = new cc.Node();
        dateCont.setAnchorPoint2();
        dateCont.setContentSize2(styles.dateWidth, styles.entryHeight);
        dateCont.addChild(dateTxt);
        dateTxt.setPositionRound(dateCont.width / 2, dateCont.height / 2);

        var separator = cleverapps.UI.createScale9Sprite(bundles.admin.frames.log_entry_separator, cleverapps.UI.Scale9Rect.TwoPixelXY);
        separator.setContentSize(separator.width, styles.entryHeight);

        var text = this.entry.getMessage();
        if (text.length > styles.maxMsgLength) {
            text = text.substr(0, styles.maxMsgLength) + '...';
        }
        var message = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.ADMIN_LOG_FONT);
        message.setDimensions(styles.msgWidth - styles.msgPadding, 0);
        message.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        message.fitTo(undefined, styles.entryHeight);
        var msgCont = new cc.Node();
        msgCont.setAnchorPoint2();
        msgCont.setContentSize2(styles.msgWidth, styles.entryHeight);
        msgCont.addChild(message);
        message.setPositionRound(message.width / 2, msgCont.height / 2);

        return [dateCont, separator, msgCont];
    },

    delete: function () {
        this.removeFromParent();
        this.logView.updateContent();
    },

    updateEntry: function () {
        this.dateTxt.setString(this.entry.date.toLocaleString('ru').replace(',', ''));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_LOG_FONT: {
        name: 'formal',
        size: 15,
        color: cleverapps.styles.COLORS.BLACK
    }
});

cleverapps.styles.AdminLogEntryView = {
    maxMsgLength: 200,
    dateWidth: 160,
    msgWidth: 600,
    msgPadding: 80,
    entryHeight: 36,
    margin: 5,
    authorPadding: 20
};