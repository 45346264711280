/**
 * Created by Aleksandr on 28.12.2022
 */

var BlocksOrangery = {
    init: function () {
        var formRows = [[{
            eraser: true,
            itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
            minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
        }], []];
        var formPerRow = 7;

        for (var c = 0; c < BlocksGame.COLORS.length; c++) {
            formRows[1].push({
                color: c,
                itemIcon: this.createTextureByColor(BlocksGame.COLORS[c].rgb),
                minimizedIcon: this.createTextureByColor(BlocksGame.COLORS[c].rgb)
            });
        }

        for (var i = 0; i < Forms.ALL_POSSIBLE_VARIANTS.length; i++) {
            if (i % formPerRow === 0) {
                formRows.push([]);
            }
            var form = Forms.ALL_POSSIBLE_VARIANTS[i];

            formRows[Math.floor(i / formPerRow) + 2].push({
                form: form,
                color: i % BlocksGame.COLORS.length,
                itemIcon: new PieceView(-1, form, i % BlocksGame.COLORS.length),
                minimizedIcon: new PieceView(-1, form, i % BlocksGame.COLORS.length)
            });
        }

        this.tabs = [{
            icon: bundles.blocks.frames.cell_bg_red_png,
            rows: formRows
        }];
    },

    createTextureByColor: function (color) {
        var colorSprite = new cc.Sprite();
        var w = 60;
        var h = 60;
        
        var texture = new cc.Texture2D();
        var len = w * h * 3;
        var data = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            var colorPart;
            if (i % 3 === 0) {
                colorPart = color.r;
            } else if (i % 3 === 1) {
                colorPart = color.g;
            } else if (i % 3 === 2) {
                colorPart = color.b;
            }

            data[i] = colorPart;
        }
        texture.initWithData(data, cc.Texture2D.PIXEL_FORMAT_RGB888, w, h, w * h);

        colorSprite.initWithTexture(texture, cc.rect(0, 0, w, h));
        return colorSprite;
    },

    getItemCode: function (item) {
        if (item.form) {
            return Forms.ToKey(item.form);
        }
        if (item.color !== undefined) {
            return "Color " + item.color;
        }
        return "Eraser";
    }
};
