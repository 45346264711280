/**
 * Created by mac on 2/3/23
 */

var ToolMenuItem = function (toolEntry, parent, key) {
    this.entry = toolEntry;
    this.parent = parent;
    this.key = key;
};

ToolMenuItem.SUB_ITEM = "•  ";
ToolMenuItem.ACTIVE_ITEM = "※ ";

ToolMenuItem.prototype.isRoot = function () {
    return this.parent === null;
};

ToolMenuItem.prototype.isFolder = function () {
    return this.children() !== undefined;
};

ToolMenuItem.prototype.isFlag = function () {
    return this.entry && this.entry.flag;
};

ToolMenuItem.prototype.children = function () {
    var children = undefined;

    if (this.entry instanceof ToolFolder) {
        children = this.entry.listItems();
    } else if (typeof this.entry === "object" && !this.isFlag()) {
        children = this.entry;
    }

    if (!children) {
        return undefined;
    }

    return Object.keys(children).filter(function (key) {
        return (children[key] instanceof ToolFolder) ? children[key].listItems() : children[key];
    }).map(function (key) {
        return new ToolMenuItem(children[key], this, key);
    }, this);
};

ToolMenuItem.prototype.clickHandler = function () {
    if (this.isFolder()) {
        cleverapps.toolModel._navigate(this);
    } else if (this.isFlag()) {
        cleverapps.flags.toggle(this.entry.flag);
        cleverapps.toolModel.refresh();
    } else {
        this.entry();
    }
};

ToolMenuItem.prototype.extractPath = function () {
    var path = [];

    for (var item = this; item; item = item.parent) {
        path.push(item.key);
    }

    return path.reverse().slice(1).join("/");
};

ToolMenuItem.prototype.getDebugId = function () {
    if (!this.isFolder() && cleverapps.gameModes.captureToolActions) {
        return this.extractPath();
    }
};