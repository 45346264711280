/**
 * Created by Andrey Popov on 1/26/21.
 */

var EditBoxPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;

    if (initialValue !== undefined) {
        this.value = initialValue;
    } else if (property.multiselect) {
        this.value = [];
    } else {
        this.value = "";
    }

    var propertyDescription = {
        name: property.name,
        type: property.type,
        items: property.items,
        getPreview: property.getPreview,
        multiselect: property.multiselect,
        largeField: property.largeField,
        link: property.link,
        placeHolderText: property.placeHolderText,
        allowOverride: property.allowOverride,
        getter: function () {
            return this.value;
        }.bind(this),
        setter: function (value) {
            this.value = value;
        }.bind(this)
    };

    if (property.allowOverride) {
        var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;

        delete propertyDescription.getter;
        delete propertyDescription.setter;

        propertyDescription.getters = [
            function () {
                return this.value.general;
            }.bind(this), function () {
                if (this.value[resolution] === undefined && property.value) {
                    var initialValue = typeof property.value === "function" ? property.value() : property.value;
                    this.value[resolution] = initialValue[resolution];
                }
                return this.value[resolution];
            }.bind(this)
        ];
        propertyDescription.setters = [function (value) {
            if (!this.value.general) {
                this.value = {};
            }
            this.value.general = value;
        }.bind(this), function (value) {
            if (value === undefined) {
                delete this.value[resolution];
            } else {
                this.value[resolution] = value;
            }
        }.bind(this)];
    }

    this.propertyEditors = [new PropertyEditor(propertyDescription, this)];
};

EditBoxPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    var value = this.property.type === "number" ? parseFloat(sender.getString()) : sender.getString();
    if (this.property.type === "number" && isNaN(value)) {
        value = sender.getString() === "" ? undefined : 0;
    }
    sender.customSetter(value);
    this.onChangeValueCallback(this.property.name, this.value);

    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};
 
EditBoxPropertyEditor.prototype.editBoxTextChanged = function (sender, text) {
    if (this.property.type === "number") {
        sender.setString(text.trim().replace(/(?:\r\n|\r|\n)/g, ""));
    }
};