/**
 * Created by mac on 4/15/20
 */

Level.prototype.edit = function (f) {
    var actions = [
        function (f) {
            this.load(f);
        }.bind(this),

        function (f) {
            this.loadStatistics(f);
        }.bind(this),

        function (f) {
            EditorScene.open({ level: this, callback: f });
        }.bind(this)
    ];

    if (f) {
        cleverapps.meta.compound(f, actions);
    } else {
        cleverapps.meta.display({
            focus: "LevelPressedEditor",
            actions: actions
        });
    }
};

Level.prototype.loadStatistics = function (f) {
    if (cleverapps.config.type === "merge" || cleverapps.config.type === "battlefield" || cleverapps.config.type === "tile3") {
        f();
        return;
    }

    var hashes = this.episode.getLevelHashes(this.levelNo);
    var hashesIds = Object.keys(hashes).join(",");

    this.playedProductionMaxVersion = -1;

    var onSuccess = function (data) {
        data.forEach(function (line) {
            this.playedProductionMaxVersion = Math.max(this.playedProductionMaxVersion, parseInt(hashes[line.hash].version));
        }.bind(this));

        f();
    }.bind(this);

    cleverapps.RestClient.get(DataSource.ReleaseAdminPath("basic/") + hashesIds, {}, onSuccess);
};

if (typeof Loader !== "undefined") {
    if (Loader.prototype._loadNext === undefined) {
        Loader.prototype._loadNext = Loader.prototype.loadNext;
        Loader.prototype.loadNext = function (row, col, data) {
            if (cleverapps.environment.isEditorScene()) {
                if (data[0] === Loader.WILDCARD) {
                    var cell = new EditorRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === BombCell.CODES[0] && data[2] === Loader.WILDCARD) {
                    cell = new EditorBombRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === FireworkCell.CODES[0] && data[2] === Loader.WILDCARD) {
                    cell = new EditorFireworkRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === WaffleCell.CODES[0] && data[1] === Loader.WILDCARD) {
                    cell = new EditorWaffleRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === TruffleCell.CODES[0] && (data[2] === Loader.WILDCARD || data[1] === Loader.WILDCARD)) {
                    cell = new EditorTruffleRandomCell(col, row, data[1]);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === LollipopCell.CODES[0] && data[1] === Loader.WILDCARD) {
                    cell = new EditorLollipopRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === BagDecoratorCell.CODES[0] && data[1] === Loader.WILDCARD) {
                    cell = new EditorBagDecoratorRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === ColorCookieManCell.CODES[0] && data[1] === Loader.WILDCARD) {
                    cell = new EditorColorCookieManRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === ColorBombCell.CODES[0] && data[2] === Loader.WILDCARD) {
                    cell = new EditorColorBombRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
                if (data[0] === SmallPetardCell.CODES[0] && data[2] === Loader.WILDCARD) {
                    cell = new EditorSmallPetardRandomCell(col, row);
                    cell.load(data, this);
                    return cell;
                }
            }

            return this._loadNext(row, col, data);
        };
    }
}