/**
 * Created by denis on 31 july 2020
 */

var GamePlayer = function (level, options) {
    cleverapps.EventEmitter.call(this);

    this.level = level || (Game.currentGame ? Game.currentGame.level.content : 0);
    this.options = options || {};

    this.game = options.currentGame || Game.currentGame;
    this.finger = new FingerViewEx(this.options.finger);

    var scene = this.options.scene || cleverapps.scenes.getRunningScene();
    this.scene = scene;
};

GamePlayer.prototype = Object.create(cleverapps.EventEmitter.prototype);
GamePlayer.prototype.constructor = GamePlayer;

GamePlayer.prototype.initFinger = function () {
    this.scene.addChild(this.finger);
    this.finger.setLocalZOrder(999);

    this.fingerInited = true;
};

GamePlayer.prototype.play = function (options, callback) {
    cleverapps.meta.disableInput(true);

    var player = this.player = new ActionPlayer();

    var actions = (typeof options === "number" || Array.isArray(options)) ? options : options.actions;
    if (typeof actions === "number") {
        var amount = actions;
        actions = [];
        for (var i = 0; i < amount; i++) {
            actions.push("any");
        }
    }
    this.actions = actions.filter(function (action) {
        return !action.disabled;
    });

    var lastActionTime = 0;
    this.actions.forEach(function (action, index) {
        var delay = options.delay || 0;
        if (index === 0) {
            delay += options.initialDelay || 0;
        }

        player.add(ActionPlayer.delay(delay));

        if (index === 0 && options.onStartPlay) {
            player.add(function (f) {
                options.onStartPlay();
                f();
            });
        }
        player.add(function (f) {
            var duration = 1;
            if (this.actions[index + 1] && this.actions[index + 1].beforeStartDelay) {
                duration = this.actions[index + 1].beforeStartDelay;
            }
            var playActionInt = function () {
                if (!this.playActionBase(action, f, duration)) {
                    var actionStart = Date.now();
                    this.playAction(action, function () {
                        lastActionTime = Date.now() - actionStart;
                        f();
                    }, duration);
                }
            }.bind(this);

            if (action.beforeActionSeed) {
                cleverapps.Random.seed(action.beforeActionSeed);
            }

            if (action.beforeStartDelay) {
                var waitTime = Math.min(action.beforeStartDelay * 1000 - lastActionTime, 10000);
                if (waitTime > 0 && waitTime < 6000) {
                    setTimeout(playActionInt, waitTime);
                    console.log("waitTime: " + waitTime);
                    return;
                }
            }

            playActionInt();
        }.bind(this));
    }.bind(this));

    player.add(function (f) {
        this.afterLastAction(f);
    }.bind(this));

    player.play(function () {
        cleverapps.meta.disableInput(false);
        callback && callback();
    });
};

GamePlayer.prototype.stop = function () {
    cleverapps.meta.disableInput(false);
    this.player && this.player.stop();
    this.finger && this.finger.removeFromParent();
};

GamePlayer.prototype.afterLastAction = function (f) {
    f();
};

GamePlayer.prototype.playActionBase = function (action, f, duration) {
    if (!this.fingerInited) {
        this.initFinger();
    }

    switch (action.type) {
        case "click_uielement":
            this.clickUIElement(action, duration, f);
            break;
        case "click_map":
            this.clickMap(action, duration, f);
            break;
        case "finish":
            this.fingerFadeOut(function () {
                setTimeout(f, 500);
            });
            break;
        default:
            return false;
    }

    return true;
};

GamePlayer.prototype.clickUIElement = function (action, duration, callback) {
    var target = $({ debugId: action.id }).objects[0];
    if (!target) {
        setTimeout(this.clickUIElement.bind(this, action, duration, callback), 50);
        return;
    }

    var targetBox = target.getGlobalBoundingBox();
    var offset = action.location || cc.p(targetBox.width / 2, targetBox.height / 2);

    this.clickElement(target, {
        duration: duration,
        callback: callback,
        targetLocation: action.location,
        clickCoordinates: cc.p(offset.x + targetBox.x, offset.y + targetBox.y)
    });
};

GamePlayer.prototype.clickMap = function (action, duration, callback) {
    var target = $({ debugId: action.id }).objects[0];
    var mapView = Game.currentGame.map.getView();

    var coordinates = mapView.getCoordinatesByCell(cc.p(action.cell.x + action.cellOffset.x, action.cell.y + action.cellOffset.y));
    var location = mapView.convertToWorldSpace(coordinates);

    this.clickElement(target, {
        duration: duration,
        callback: callback,
        targetLocation: location,
        clickCoordinates: location
    });
};

GamePlayer.prototype.clickElement = function (target, options) {
    this.fingerClickAction(target, function () {
        if (target.clickPointerHandler && target.clickPointerHandler.handleClick) {
            var fakeTouch = {
                getLocation: function () {
                    return options.targetLocation;
                },
                getStartLocation: function () {
                    return options.targetLocation;
                }
            };
            var isConfirmed = !target.clickPointerHandler.filter || target.clickPointerHandler.filter(fakeTouch);
            if (isConfirmed) {
                target.clickPointerHandler.handleClick(fakeTouch);
            }
        }
    }, {
        callback: function () {
            setTimeout(options.callback, Math.max(500, options.duration * 1000 - 1000));
        },
        doNotFollowFinger: true,
        location: options.clickCoordinates
    });
};

GamePlayer.prototype.fingerClickAction = function (target, action, options) {
    options = options || {};

    var location = options.location;
    if (!location) {
        var targetBox = target.getGlobalBoundingBox();
        location = cc.p(targetBox.x + targetBox.width / 2, targetBox.y + targetBox.height / 2);
    }
    var position = this.finger.getParent().convertToNodeSpace(location);

    if (!this.finger.isShown) {
        this.finger.fadeIn(position);
        this.finger.addAction(function (f) {
            this.finger.isShown = true;
            f();
        }.bind(this));
    }

    this.finger.moveTo(position);
    this.finger.press();
    this.finger.addAction(function (f) {
        action();
        f();
    });
    this.finger.unPress();
    this.finger.runOnce(options.callback);
};

GamePlayer.prototype.fingerRelax = function (delay, f) {
    this.finger.relax(delay);
    this.finger.runOnce(f);
};

GamePlayer.prototype.fingerFadeOut = function (f) {
    this.finger.fadeOut();
    this.finger.runOnce(f);
};

GamePlayer.Create = function (level, options) {
    var ClassName = BoardPlayer;
    if (cleverapps.config.type === "tile3") {
        ClassName = Tile3Player;
    } else if (cleverapps.config.type === "solitaire") {
        ClassName = SolitairePlayer;
    } else if (cleverapps.config.name === "scramble") {
        ClassName = ScramblePlayer;
    } else if (cleverapps.config.name === "differences") {
        ClassName = DifferencesPlayer;
    } else if (cleverapps.config.type === "match3") {
        ClassName = Match3Player;
    } else if (cleverapps.config.type === "merge") {
        ClassName = MergeCraftPlayer;
    } else if (cleverapps.config.type === "blocks") {
        ClassName = BlocksPlayer;
    }
    return new ClassName(level, options);
};