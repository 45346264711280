/**
 * Created by andrey on 17.10.19.
 */

cleverapps.IOSWebViewFacebook = function () {
    console.log("Choosing ios-facebook (webview)");

    FacebookPlugin = {
        init: function () {
            webkitMessage("facebook", { 
                action: "init"
            });
        },
        logPurchase: function (amount, currency) {
            console.log("logPurchase success " + amount + " " + currency);
            webkitMessage("facebook", {
                action: "logPurchase",
                amount: amount,
                currency: currency
            });
        },
        dialog: function (jsonInfo) {
            webkitMessage("facebook", {
                action: "dialog",
                jsonInfo: JSON.parse(jsonInfo)
            });
        },
        login: function (permissions) {
            webkitMessage("facebook", {
                action: "login",
                permissions: permissions
            });
        },
        logout: function () {
            webkitMessage("facebook", {
                action: "logout"
            });
        },
        api: function (path, method, paramsJson, tag) {
            webkitMessage("facebook", {
                action: "api",
                path: path,
                method: method,
                paramsJson: JSON.parse(paramsJson),
                tag: tag
            });
        },
        inviteFriendsWithInviteIds: function (inviteIdsJson, title, text) {
            webkitMessage("facebook", {
                action: "inviteFriendsWithInviteIds",
                inviteIdsJson: JSON.parse(inviteIdsJson),
                title: title,
                text: text
            });
        },
        enableDebug: function (enable) {
            webkitMessage("facebook", {
                action: "enableDebug",
                enable: enable
            });
        }
    };

    cleverapps.MobileFacebook.call(this);
};

cleverapps.IOSWebViewFacebook.prototype = Object.create(cleverapps.MobileFacebook.prototype);
cleverapps.IOSWebViewFacebook.prototype.constructor = cleverapps.IOSWebViewFacebook;

cleverapps.IOSWebViewFacebook.prototype.shareDialog = function (data, callback) {
    this.listeners.onShared = callback;
    FacebookPlugin.dialog(JSON.stringify(this.getShareDialogData(data)));
};

cleverapps.IOSWebViewFacebook.prototype.request = function (options, callback) {
    this.listeners.onRequest = callback;
    FacebookPlugin.inviteFriendsWithInviteIds(
        options.to ? JSON.stringify(options.to) : null,
        options.title,
        options.message
    );
};

cleverapps.IOSWebViewFacebook.prototype.onRequest = function (code) {
    if (this.listeners.onRequest) {
        this.listeners.onRequest(code);
        delete this.listeners.onRequest;
    }
};

cleverapps.IOSWebViewFacebook.prototype.addApiJob = function (apiMethod, httpMethod, params, callback) {
    cleverapps.MobileFacebook.prototype.addApiJob.call(this, apiMethod, httpMethod, JSON.stringify(params), callback);
};