/**
 * Created by andrey on 01.08.2022
 */

var WinAnimationAction = function (f) {
    var outcome = Game.currentGame.outcome;

    var action = WinAnimations.Victory;
    if (GameBase.OUTCOME_LOST === outcome) {
        action = WinAnimations.Lose;
    } else if (GameBase.OUTCOME_GAVEUP === outcome) {
        action = WinAnimations.GiveUp;
    }

    action.call(cleverapps.scenes.getRunningScene(), f);
};

var WinAnimations = {
    Victory: function (f) {
        f();
    },

    Lose: function (f) {
        f();
    },

    GiveUp: function (f) {
        WinAnimations.Lose.call(this, f);
    }
};