/**
 * Created by iamso on 29.05.19.
 */

WINDOW_ANIMATIONS = {
    default: {
        open: function (callback) {
            var baseScale = this.window.scale;
            this.window.setScale(0.8 * baseScale);
            this.window.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, baseScale).easing(cc.easeBackOut()),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            callback();
        }
    },

    fallIn: {
        open: function (callback) {
            var baseScale = this.window.scale;

            this.window.setScale(3.2 * baseScale);
            this.performRecursive(function (node) {
                if (node !== this) {
                    node.setCascadeOpacityEnabled(true);
                }
            });
            this.window.setOpacity(30);

            this.window.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.5, baseScale).easing(cc.easeIn(1.5)),
                    new cc.FadeIn(0.5).easing(cc.easeIn(2))
                ),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            callback();
        }
    },

    fromLeft: {
        open: function (callback) {
            var originalPos = this.window.basePosition;
            this.window.setPositionX(-originalPos.x);
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, originalPos.x, originalPos.y).easing(cc.easeBackOut()),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, -this.window.x, this.window.y).easing(cc.easeBackIn()),
                new cc.CallFunc(callback)
            ));
        }
    },

    fromBelow: {
        open: function (callback) {
            var originalPos = this.window.basePosition;
            this.window.setPositionY(0);
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, originalPos.x, originalPos.y).easing(cc.easeBackOut()),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.4, this.window.x, 0).easing(cc.easeBackIn()),
                new cc.CallFunc(callback)
            ));
        }
    },

    fromLeftToRight: {
        open: function (callback) {
            var originalPos = this.window.basePosition;
            this.window.setPositionX(-this.window.width / 2);
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, originalPos.x, originalPos.y).easing(cc.easeBackOut()),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            this.window.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, this.width + this.window.width / 2, this.window.y).easing(cc.easeBackIn()),
                new cc.CallFunc(callback)
            ));
        }
    },

    bezierCircle: {
        open: function (callback) {
            var target = cc.p(this.window.basePosition.x, this.window.basePosition.y);
            var first = cc.p(target.x + this.width * 0.2, this.height - this.window.height / 2);
            var second = cc.p(target.x + this.width * 0.35, target.y - this.window.height / 6);

            var baseScale = this.window.scale;
            this.window.setScale(0.3 * baseScale);
            this.window.setPosition(first);
            var time = 0.25;

            this.window.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.BezierTo(time, [first, second, target]),
                    new cc.ScaleTo(time, baseScale).easing(cc.easeIn(3))
                ),
                new cc.CallFunc(callback)
            ));
        },

        close: function (callback) {
            var target = cc.p(this.window.basePosition.x, this.window.basePosition.y);
            var first = cc.p(target.x - this.width * 0.2, this.height - this.window.height / 2);
            var second = cc.p(target.x - this.width * 0.35, target.y - this.window.height / 2);

            this.window.setPosition(target);
            var time = 0.25;

            this.window.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.BezierTo(time, [target, second, first]),
                    new cc.ScaleTo(time, 0.3).easing(cc.easeOut(0.33))
                ),
                new cc.CallFunc(callback)
            ));
        }
    },

    instant: {
        open: function (callback) {
            callback();
        },

        close: function (callback) {
            callback();
        }
    },

    force: {
        open: function (callback) {
            callback();
        },

        close: function (callback) {
            this.dialogue.close(callback);
        }
    }
};

AVAILABLE_MOVE_ANIMATIONS = {
    default: function (view, dir, move) {
        var baseScale = view.scale;
        var baseX = view.x;
        var baseY = view.y;
        var deltaX = -dir.col * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.16;
        var deltaY = dir.row * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.16;

        if (dir.row === 0) {
            deltaY -= cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.075;
        }

        view.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.Repeat(new cc.Sequence(
                    new cc.Spawn(
                        new cc.ScaleTo(0.35, baseScale * 1.1),
                        new cc.MoveTo(0.35, baseX - deltaX, baseY - deltaY).easing(cc.easeIn(3.5))
                    ),
                    new cc.Spawn(
                        new cc.ScaleTo(0.35, baseScale),
                        new cc.MoveTo(0.35, baseX, baseY).easing(cc.easeOut(3.5))
                    )
                ), 3),
                new cc.DelayTime(3)
            )
        ))
    },

    homescapes: function (view, dir, move) {
        var baseScaleX = view.getScaleX();
        var baseScaleY = view.getScaleY();
        var baseX = view.x;
        var baseY = view.y;
        var baseRot = view.getRotation();
        if (view.cell.x === move.start.x && view.cell.y === move.start.y) {
            var targetX = move.dir.col * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.2;
            var targetY = -move.dir.row * cleverapps.styles.BaseCellView.CELL_SIZE_PX * 0.2;
            view.setLocalZOrder(10);
            view.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.Spawn(
                        new cc.Sequence(
                            new cc.MoveBy(0.45, targetX, targetY),
                            new cc.MoveBy(0.2, -0.3 * targetX, -0.3 * targetY),
                            new cc.MoveBy(0.2, 0.3 * targetX, 0.3 * targetY),
                            new cc.MoveTo(0.4, baseX, baseY).easing(cc.easeOut(1))
                        ),
                        new cc.Sequence(
                            new cc.ScaleTo(0.45, baseScaleX * 1.1, baseScaleY * 1.1),
                            new cc.DelayTime(0.4),
                            new cc.ScaleTo(0.4, baseScaleX, baseScaleY).easing(cc.easeOut(1))
                        )
                    ),
                    new cc.DelayTime(0.5)
                )
            ));
        } else {
            view.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.Spawn(
                        new cc.Sequence(
                            new cc.ScaleTo(0.35, baseScaleX * 1.1, baseScaleY * 1.1),
                            new cc.DelayTime(0.35),
                            new cc.ScaleTo(0.35, baseScaleX, baseScaleY)
                        ),
                        new cc.Sequence(
                            new cc.RotateTo(0.35, baseRot - 5).easing(cc.easeIn(2)),
                            new cc.RotateTo(0.175, baseRot + 5),
                            new cc.RotateTo(0.175, baseRot - 5),
                            new cc.RotateTo(0.35, baseRot)
                        )
                    ),
                    new cc.DelayTime(0.6)
                )
            ));
        }
    }
};

WRONG_MOVE_ANIMATIONS = {
    default: function (view, destination) {
        var t = 0.1;
        var move = cleverapps.styles.BaseCellView.wrongMove.move;

        var sign = function (value) {
            return value > 0 ? 1 : (value < 0 ? -1 : 0);
        };
        var moveX = move * sign(destination.x - view.cell.x);
        var moveY = move * sign(view.cell.y - destination.y);

        var scaleX = 0.9, scaleY = 1.1;
        if (view.cell.x === destination.x) {
            scaleX = 1.1;
            scaleY = 0.9;
        }

        Game.counter.setTimeout(function () {}.bind(view), 100);
        view.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(t, scaleX, scaleY),
                    new cc.MoveBy(t, new cc.p(moveX, moveY))
                ),
                new cc.Spawn(
                    new cc.ScaleTo(t, 1, 1),
                    new cc.MoveBy(t, new cc.p(-moveX, -moveY))
                )
            ),
            new cc.CallFunc(view.squeeze.bind(view, 1))
        ));
    }
};