/**
 * Created by vladislav on 9/6/2022
 */

var GPG = function () {
    AndroidBase.call(this, "gpg");
};

GPG.prototype = Object.create(AndroidBase.prototype);
GPG.prototype.constructor = GPG;

GPG.prototype.pushSender = function () {
    return undefined;
};