/**
 * Created by mac on 4/20/20
 */

cleverapps.UI.StateButton = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.buttonLabelStyles = [cleverapps.styles.FONTS.SWITCH_BUTTON_OFF, cleverapps.styles.FONTS.SWITCH_BUTTON_ON];

        var stateOff = new cc.Sprite(bundles.switch_state_button.frames.switch_state_button);
        var stateOn = new cc.Sprite(bundles.switch_state_button.frames.switch_state_2_button || bundles.switch_state_button.frames.switch_state_button);
        if (!bundles.switch_state_button.frames.switch_state_2_button) {
            stateOn.setScaleX(-1);
            this.buttonLabelStyles.reverse();
        }

        this.setContentSize2(stateOff.width, stateOff.height);
        this.setAnchorPoint2();

        this.onClick = options.onClick;
        this.fitTo = (options.fitTo || 0.4) * this.width;

        this.state = 0;

        this.states = [stateOff, stateOn];
        stateOn.visible = false;

        this.states.forEach(function (state) {
            state.setPosition(this.width / 2, this.height / 2);
            this.addChild(state);
        }, this);

        var styles = cleverapps.styles.UI.StateButton;

        this.labels = [options.off, options.on].map(function (txt, index) {
            var label = new cleverapps.UI.generateTTFText(txt, this.buttonLabelStyles[index]);
            label.setPositionRound((index === 0) ? styles.offText : styles.onText);
            label.fitTo(this.fitTo);

            var wrapper = new cc.Node();
            wrapper.setContentSize2(this.width / 2, this.height);
            wrapper.setAnchorPoint2();
            wrapper.setPosition(index === 0 ? this.width / 4 : this.width / 4 * 3, this.height / 2);
            this.addChild(wrapper);

            wrapper.addChild(label);
            return label;
        }, this);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.action.bind(this));
    },

    setState: function (state) {
        if (this.state === state) {
            return;
        }

        this.states[this.state].visible = false;
        this.labels[this.state].setFont(this.buttonLabelStyles[1]);
        this.labels[this.state].fitTo(this.fitTo);

        this.state = state;

        this.states[this.state].visible = true;
        this.labels[this.state].setFont(this.buttonLabelStyles[0]);
        this.labels[this.state].fitTo(this.fitTo);
    },

    action: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);

        this.setState((this.state === 0) ? 1 : 0);
        this.onClick(this.state);
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    COLOR_SWITCH_BUTTON_OFF: new cc.Color(85, 93, 70, 255)
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SWITCH_BUTTON_ON: {
        size: 38,
        color: cleverapps.styles.COLORS.WHITE
    },

    SWITCH_BUTTON_OFF: {
        size: 24,
        color: cleverapps.styles.COLORS.COLOR_SWITCH_BUTTON_OFF
    }
});

cleverapps.styles.UI.StateButton = {
    onText: {
        x: { align: 'center', dx: -8 },
        y: { align: 'center', dy: 0 }
    },

    offText: {
        x: { align: 'center', dx: 8 },
        y: { align: 'center', dy: 0 }
    }
};