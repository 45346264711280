/**
 * Created by vladislav on 23.08.2019
 */

var PackProductTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = PackProductTile.styles = PackProductTile.styles || cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.PackProductTile, true);

        options = options || {};

        this._super(tileModel, styles, options);
    },

    createDescription: function () {
        return new TileRewardsBlock(this.tileModel, {
            bgSize: {
                height: this.tileModel.getProductLimit() && this.styles.limit.rewardsBlock.height || this.styles.rewardsBlock.height,
                width: this.styles.rewardsBlock && this.styles.rewardsBlock.width
            },
            font: cleverapps.styles.FONTS.PACK_TILE_REWARDS_TEXT,
            icons: {},
            paddingY: this.styles.description.paddingY
        });
    }
});

cleverapps.styles.PackProductTile = {

    limit: {
        rewardsBlock: {
            height: 70
        }
    },

    rewardsBlock: {}
};
