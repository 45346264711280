/**
 * Created by Andrey Popov on 24.03.21
 */

cleverapps.setRestDummies = function (enabled) {
    var dummifyFunc = function (ClassObj, funcName, dummyFunc) {
        ClassObj.prototype["super_" + funcName] = ClassObj.prototype[funcName];
        ClassObj.prototype[funcName] = dummyFunc;
    };
    var restoreFunc = function (ClassObj, funcName) {
        if (ClassObj.prototype["super_" + funcName]) {
            ClassObj.prototype[funcName] = ClassObj.prototype["super_" + funcName];
            delete ClassObj.prototype["super_" + funcName];
        }
    };

    if (enabled) {
        dummifyFunc(cleverapps.Payments, "getSubscriptionStatus", function (options, callback) {
            callback();
        });
        if (typeof UserClan !== "undefined") {
            dummifyFunc(UserClan, "loadRequest", function (onSuccess) {
                onSuccess(cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.clan);
            });
            dummifyFunc(ClanMessages, "loadMessagesRequest", function (onSuccess) {
                onSuccess(cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.clan);
            });
        }
        if (typeof KnockoutGame !== "undefined") {
            dummifyFunc(KnockoutGame, "checkConnection", function (f) {
                f();
            });
        }
    } else {
        restoreFunc(cleverapps.Payments, "getSubscriptionStatus");
        if (typeof UserClan !== "undefined") {
            restoreFunc(UserClan, "loadRequest");
            restoreFunc(ClanMessages, "loadMessagesRequest");
        }
        if (typeof KnockoutGame !== "undefined") {
            restoreFunc(KnockoutGame, "checkConnection");
        }
    }
};

cleverapps.importSnapshot = function () {
    var snapshotId = window.location.pathname.substring(1);

    if (/^[0-9a-z]*$/.test(snapshotId) === false) {
        snapshotId = undefined;
    }

    if (window.location.hash) {
        var params = cleverapps.getRequestParameters(window.location.hash, true);
        snapshotId = params.snapshot;
    }

    if (!snapshotId || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.config.importMode = true;
    cleverapps.setRestDummies(true);

    TestSocial.prototype.isLoggedIn = function () {
        return true;
    };
    cleverapps.BaseFB.prototype.isLoggedIn = function () {
        return true;
    };

    cleverapps.DataLoader.resetClientSession = function () {
        cleverapps.DataLoader.alerted = true;
        cleverapps.DataLoader.enabled = false;
        cleverapps.DataLoader.corrupted = false;
    };

    ExitControlButton.isAvailableAtSceneSuper = ExitControlButton.isAvailableInEpisode;
    ExitControlButton.isAvailableInEpisode = function () {
        if (cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition()) {
            return false;
        }
        return ExitControlButton.isAvailableAtSceneSuper();
    };

    cleverapps.Synchronizer.prototype._sync = function () {
        cleverapps.Synchronizer.prototype._sync = function () {};

        console.log("loading snapshot start");

        cleverapps.snapshots.getSnapshot(snapshotId, function (snapshot) {
            console.log("loading snapshot finished", snapshot.users);

            cleverapps.loadedSnapshot = snapshot;

            cleverapps.snapshots.importData(snapshot);

            cleverapps.synchronizer._onSyncProcessed();
        });

        cleverapps.flags.norest = true;
        cleverapps.flags.nologin = true;
    };
};
