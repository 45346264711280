/**
 * Created by mac on 3/19/23
 */

var Car = function (options) {
    this.x = options.x;
    this.y = options.y;

    this.width = options.width;
    this.height = options.height;

    this.onMoveListener = function () {};
};

Car.prototype.distanceTo = function (x, y) {
    if (this.isHorizontal() && this.y === y) {
        if (this.x <= x) {
            return x - this.x - this.width;
        } else {
            return x - this.x;
        }
    }

    if (this.isVertical() && this.x === x) {
        if (this.y <= y) {
            return y - this.y - this.height;
        } else {
            return y - this.y;
        }
    }

    return Infinity;
};

Car.prototype.moveTo = function (x, y) {
    var distance = this.distanceTo(x, y);
    if (this.isHorizontal()) {
        this.x += distance;
    } else {
        this.y += distance;
    }

    this.onMoveListener();
};

Car.prototype.isHorizontal = function () {
    return this.height === 1;
};

Car.prototype.isVertical = function () {
    return this.width === 1;
};

Car.prototype.isGoal = function () {
    return Game.currentGame.goal === this;
};

Car.prototype.visibleY = function () {
    var ry = Game.currentGame.jamPuzzle.height - this.y - 1;
    return this.isVertical() ? (ry + 1) : ry;
};

Car.TYPES = {
    HORIZONTAL: 1,
    VERTICAL: 2
};