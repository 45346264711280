/**
 * Created by razial on 13.01.2022
 */

var WarehouseIcon = function () {
    SideBarIcon.call(this, {
        animation: undefined,
        targets: "fmresource",
        control: "WarehouseIcon"
    });
};

WarehouseIcon.prototype = Object.create(SideBarIcon.prototype);
WarehouseIcon.prototype.constructor = WarehouseIcon;

WarehouseIcon.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "WarehouseIconClicked",
        action: function (f) {
            new WarehouseWindow();
            cleverapps.meta.onceNoWindowsListener = f;
        }
    });
};

WarehouseIcon.prototype.resetState = function () {
    this.available = Game.currentGame && Game.currentGame.harvested
        && cleverapps.travelBook.getCurrentPage().id === "farm" && !cleverapps.config.editorMode;
};
