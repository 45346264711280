/**
 * Created by andrey on 04.05.2021.
 */

if (["ios", "macos", "ios_ch"].indexOf(cleverapps.config.source || cleverapps.config.requestParamSource) !== -1) {
    var AudioPlugin = {
        sendBatch: function (batch) {
            webkitMessage("audioPlugin", {
                action: "sendBatch",
                batch: batch
            });
        }
    }; 
}