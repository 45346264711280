/**
 * Created by andrey on 15.01.18.
 */

cleverapps.LocalPushes.SocialSender = {
    batch: {},

    _sendBatch: function () {
        var path = cleverapps.RestClient.getBase() + "/" + cleverapps.config.name + "-push/sendBatch";
        // path = "http://localhost/mergecraft-push/sendBatch";
        // path = "http://localhost:5006/sendBatch";
        var batch = this.batch;

        var data = {
            userId: cleverapps.LocalPushes.GetPushUserId(cleverapps.user.id, cleverapps.platform.source),
            language: cleverapps.settings.language,
            batch: batch
        };

        this.batch = {};

        console.log("Sending batch - ", batch);
        cleverapps.RestClient.post(path, data, function () {
            console.log("Success push", batch);
        }, function () {
            console.log("Failure push", batch);
        }, {
            fullPath: true
        });
    },

    sendPush: function (info, timeout) {
        if (levels.user.haveTmpId()) {
            return;
        }

        if (cleverapps.platform.oneOf(Yandex)
            && cleverapps.YandexPushesMap[cleverapps.config.name][info.code.toUpperCase()] === undefined) {
            return;
        }

        this.batch[info.id] = Math.floor(timeout / 1000);

        this.sendBatch();
    },

    cancelPush: function (info) {
        if (levels.user.haveTmpId()) {
            return;
        }

        this.batch[info.id] = 0;

        this.sendBatch();
    }
};

cleverapps.LocalPushes.SocialSender.sendBatch = cleverapps.accumulate(
    cleverapps.parseInterval("10 seconds"),
    cleverapps.LocalPushes.SocialSender._sendBatch
);