/**
 * Created by andrey on 02.06.2020
 */

var AdjustPluginIOS = {
    init: function (appToken, environment) {
        console.log("IOSWebViewAdjustPluginAdapter init");
        webkitMessage("adjust", {
            action: "init",
            appToken: appToken,
            environment: environment
        });
    },

    logPurchase: function (eventToken, transactionID, amount, currency) {
        console.log("IOSWebViewAdjustPluginAdapter logPurchase");
        webkitMessage("adjust", {
            action: "logPurchase",
            eventToken: eventToken,
            transactionID: transactionID,
            amount: amount,
            currency: currency
        });
    },

    logEvent: function (eventToken, amount, currency) {
        console.log("IOSWebViewAdjustPluginAdapter logEvent");
        webkitMessage("adjust", {
            action: "logEvent",
            eventToken: eventToken,
            amount: amount,
            currency: currency
        });
    }
};