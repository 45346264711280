/**
 * Created by Andrey Popov on 14.02.2023
 */

cleverapps.UI.animateScale = function (options) {
    options = options || {};

    if (!options.overScaling) {
        return new cc.ScaleTo(options.duration, options.scale);
    }

    return new cc.Sequence(
        new cc.ScaleTo(4 * options.duration / 5, options.scale * options.overScaling).easing(cc.easeIn(1)),
        new cc.ScaleTo(options.duration / 5, options.scale).easing(cc.easeOut(1))
    );
};