/**
 * Created by slava on 4/4/17.
 */

cleverapps.Tutorial = function () {
    this.tutorialSteps = new cleverapps.TutorialSteps();
    this.steps = this.tutorialSteps.list(this.nextStep.bind(this));

    this.onStartedListener = function () {};
    this.onFinishedListener = function () {};
};

cleverapps.Tutorial.prototype.setGame = function (game) {
    this.game = game;
    this.tutorialSteps.setGame(this.game);
};

cleverapps.Tutorial.prototype.stop = function () {
    this.running = false;
    this.step = this.steps.length;
};

cleverapps.Tutorial.prototype.start = function () {
    this.running = true;
    this.setStep(0);
    this.onStartedListener();
};

cleverapps.Tutorial.prototype.remindStep = function () {
    if (this.isRunning()) {
        this.steps[this.step].call(this, this.game);
    }
};

cleverapps.Tutorial.prototype.setStep = function (step) {
    this.step = step;

    if (this.step >= this.steps.length) {
        this.running = false;
    }
    
    var params = { "step": step };

    if (!this.isRunning()) {
        this.onFinishedListener();
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.GENERAL.TUTORIAL_COMPLETE, params);
    } else {
        var eventName = cleverapps.EVENTS.STATS.TUTORIAL_STEP + (step + 1);
        cleverapps.eventLogger.logEvent(eventName, params);
        if (step === 0) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.TUTORIAL_BEGIN);
        }
    }

    this.remindStep();
};

cleverapps.Tutorial.prototype.nextStep = function () {
    if (this.isRunning()) {
        this.setStep(this.step + 1);
    }
};

cleverapps.Tutorial.prototype.isRunning = function () {
    return this.running;
};