/**
 * Created by vladislav on 9/6/2022
 */

var FotoStrana = function () {
    Platform.call(this, "fotostrana");

    cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE = cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE_FS;
};

FotoStrana.prototype = Object.create(Platform.prototype);
FotoStrana.prototype.constructor = FotoStrana;

FotoStrana.prototype.getLocalStoragePreffix = function () {
    return "_FS";
};

FotoStrana.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};