/**
 * Created by Andrey Popov on 1/21/21.
 */

var AssetView = cc.Node.extend({
    ctor: function (asset) {
        this._super();

        this.asset = asset;

        var styles = cleverapps.styles.AssetView;

        this.setAnchorPoint2();
        this.setContentSize(styles);

        var componentFrame = this.componentFrame = new cc.Node();
        componentFrame.setAnchorPoint2();
        componentFrame.setPositionRound(styles.frame);
        componentFrame.setVisible(false);
        this.addChild(componentFrame);

        var dragTargetFrame = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.attach_frame);
        dragTargetFrame.setAnchorPoint2();
        dragTargetFrame.setVisible(false);
        this.addChild(dragTargetFrame);

        cleverapps.UI.onDrag(this, {
            onDragMove: function (touch) {
                this.componentFrame.replaceParentSamePlace(cleverapps.scenes.getRunningScene().scene);
                this.componentFrame.setVisible(true);

                this.componentFrame.setPositionRound(this.componentFrame.parent.convertTouchToNodeSpace(touch));

                var target = this.findDragTarget(touch);
                if (target) {
                    dragTargetFrame.replaceParent(target.view);
                    dragTargetFrame.setContentSize(target.view.width, Math.max(target.view.height, 30));
                    dragTargetFrame.setPositionRound(target.view.width / 2, target.view.height / 2);
                    dragTargetFrame.setVisible(true);
                } else {
                    dragTargetFrame.replaceParent(this);
                    dragTargetFrame.setVisible(false);
                }
            }.bind(this),
            onDragEnd: function (touch) {
                this.componentFrame.replaceParentSamePlace(this);
                this.componentFrame.setPositionRound(styles.frame);
                this.componentFrame.setVisible(false);

                var target = this.findDragTarget(touch);
                if (target) {
                    var point = target.view.convertTouchToNodeSpace(touch);
                    point.x = Math.round(point.x);
                    point.y = Math.round(point.y);

                    this.asset.moveToScene(target.id, {
                        x: { align: "center", dx: target.ignorePosition ? 0 : point.x - target.view.width / 2 },
                        y: { align: "center", dy: target.ignorePosition ? 0 : point.y - target.view.height / 2 }
                    });
                } else {
                    cleverapps.notification.create("Drag to clip or hierarchy element");
                }

                dragTargetFrame.replaceParent(this);
                dragTargetFrame.setVisible(false);
            }.bind(this)
        });

        cleverapps.UI.applyHover(this);
    },

    findDragTarget: function (touch) {
        var wysiwygScene = cleverapps.scenes.getRunningScene();
        var preview = wysiwygScene.wysiwygPreviewView;
        if (preview.clipScenes && preview.clipScenes.length === 1) {
            var clipScene = preview.clipScenes[0];
            var point = clipScene.convertTouchToNodeSpace(touch);
            if (cc.rectContainsPoint(cc.rect(0, 0, clipScene.width, clipScene.height), point)) {
                var targetComponent = undefined;
                Object.keys(clipScene.componentViews).forEach(function (componentId) {
                    var componentView = clipScene.componentViews[componentId];
                    var componentPoint = componentView.convertTouchToNodeSpace(touch);
                    if (cc.rectContainsPoint(cc.rect(0, 0, componentView.width, componentView.height), componentPoint)) {
                        targetComponent = {
                            id: parseInt(componentId),
                            view: componentView
                        };
                    }
                });

                return targetComponent || {
                    id: clipScene.clipSceneConfig.id,
                    view: clipScene
                };
            }
        }

        var hierarchyView = wysiwygScene.hierarchyView;
        point = hierarchyView.convertTouchToNodeSpace(touch);
        if (cc.rectContainsPoint(cc.rect(0, 0, hierarchyView.width, hierarchyView.height), point)) {
            var targetItem = undefined;
            hierarchyView.hierarchyItemViews.forEach(function (hierarchyItemView) {
                var itemPoint = hierarchyItemView.convertTouchToNodeSpace(touch);
                if (cc.rectContainsPoint(cc.rect(0, 0, hierarchyItemView.width, hierarchyItemView.height), itemPoint)
                    && [HierarchyItem.TYPE.SCENE, HierarchyItem.TYPE.COMPONENT].indexOf(hierarchyItemView.hierarchyItem.type) !== -1) {
                    targetItem = {
                        id: hierarchyItemView.hierarchyItem.id,
                        view: hierarchyItemView,
                        ignorePosition: true
                    };
                }
            });

            if (targetItem) {
                return targetItem;
            }
        }
    }
});

cleverapps.styles.AssetView = {
    width: 110,
    height: 110,

    frame: {
        x: { align: "center" },
        y: { align: "center" }
    }
};