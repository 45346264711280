/**
 * Created by Andrey Popov on 05.04.23
 */

var BlocksPlayer = function (level, options) {
    GamePlayer.call(this, level, options);

    this.actionsDone = 0;
};

BlocksPlayer.prototype = Object.create(GamePlayer.prototype);
BlocksPlayer.prototype.constructor = BlocksPlayer;

BlocksPlayer.prototype.playAction = function (action, f, duration) {
    if (!Game.currentGame || Game.currentGame.counter.isActive() || $(PiecesView).get() === undefined) {
        setTimeout(this.playAction.bind(this, action, f, duration), 50);
        return;
    }

    var styles = cleverapps.styles.BlocksPlayer;

    action = cleverapps.clone(action, true);

    if (action.type === "setPieces") {
        Game.currentGame.pieces.nextForms = action.nextForms;
        Game.currentGame.pieces.nextColors = action.nextColors;
        setTimeout(f, 30);
    } else if (action.type === "play") {
        var pieceView = $(PiecesView).get().slots[action.piece].pieceView;
        if (!pieceView) {
            setTimeout(this.playAction.bind(this, action, f, duration), 20);
            return;
        }

        var stepDuration = Math.min(1200, Math.max(200, duration));

        var pieceCenter = Game.currentGame.pieces.getCenter(action.piece);

        var finger = this.finger;

        var startPosition = this.scene.convertToNodeSpace(pieceView.getParent().convertToWorldSpace(pieceView));
        var targetCell = cc.p(action.x + pieceCenter.x, action.y + pieceCenter.y);
        var targetPosition = this.scene.convertToNodeSpace($(GridView).get().convertToWorldSpace(GridView.cellToPosition(targetCell)));

        if (finger.finger.opacity === 0) {
            finger.fadeIn(cc.p(startPosition.x + styles.fingerDislodgedPos.x, startPosition.y + styles.fingerDislodgedPos.y));
            cleverapps.audio.playSound(bundles.game.urls.openlevel_effect);
        }

        finger.moveTo(startPosition, {
            duration: stepDuration / 2000
        });
        finger.press();
        finger.addAction(function (callback) {
            var fingerInitial = finger.finger.getPosition();

            cleverapps.audio.playSound(bundles.game.urls.drag_effect);
            pieceView.animateStartDrag();
            Game.currentGame.dragStartPiece(action.piece);

            finger.scheduleUpdate();
            finger.update = function () {
                pieceView.setPositionRound(finger.finger.x - fingerInitial.x, finger.finger.y - fingerInitial.y);

                var world = pieceView.parent.convertToWorldSpace(pieceView.getPosition());
                var cellPosition = GridView.positionToCell($(GridView).get().convertToNodeSpace(world));
                Game.currentGame.dragMovePiece(action.piece, cellPosition.x, cellPosition.y);
            };

            callback();
        });
        finger.moveTo(targetPosition, {
            duration: stepDuration / 2000
        });

        finger.unPress();

        finger.addAction(function (callback) {
            finger.unscheduleUpdate();
            pieceView.animateEndDrag();
            Game.currentGame.dragEndPiece(action.piece, action.x + pieceCenter.x, action.y + pieceCenter.y);

            if (this.options.progressChanged) {
                this.options.progressChanged(++this.actionsDone / this.actions.filter(function (action) {
                    return action.type && action.type === "play";
                }).length);
            }

            callback();
        }.bind(this));

        if (duration > 1200) {
            finger.delay((duration - 1200) / 1000);
        }

        finger.runOnce(f);
    } else {
        f();
    }
};

cleverapps.styles.BlocksPlayer = {
    fingerDislodgedPos: {
        x: 140,
        y: 140
    }
};