/**
 * Created by Denis "dalorveen" Kuzin on 17 september 2020
 */

var DifferencesPlayer = function (level, options) {
    GamePlayer.call(this, level, options);

    this.areas = Game.currentGame.areas;

    this.finger.relaxPosition = { y: -this.finger.finger.height };

    this.photoViews = $(PhotoViews).get();
};

DifferencesPlayer.prototype = Object.create(GamePlayer.prototype);
DifferencesPlayer.prototype.constructor = DifferencesPlayer;

DifferencesPlayer.prototype.playAction = function (action, f) {
    if (action === "relax") {
        this.fingerRelax(1.0, f);
    } else if (typeof action === "number") {
        this.discover(this.areas[action], f);
    } else {
        for (var i = 0; i < this.areas.length; i++) {
            var area = this.areas[i];
            if (!area.discovered && area.x === action.area.x && area.y === action.area.y) {
                this.discover(area, action.image, f);
                return;
            }
        }
    }
};

DifferencesPlayer.prototype.discover = function (area, imageId, callback) {
    var touchPosition = { x: area.x, y: area.y };

    var photos = (imageId === "a")
        ? [this.photoViews.imageB, this.photoViews.imageA] : [this.photoViews.imageA, this.photoViews.imageB];
    var targetPosition = this.scene.convertToNodeSpace(photos[0].convertToWorldSpace(touchPosition));

    if (!this.finger.isShown) {
        var spawnPosition = this.scene.convertToNodeSpace(this.photoViews.convertToWorldSpace({
            x: this.photoViews.width / 2,
            y: this.photoViews.height / 2
        }));
        this.finger.fadeIn(
            cc.p(spawnPosition.x, spawnPosition.y),
            cc.p(spawnPosition.x, 0)
        );
        this.finger.delay(0.1);

        this.finger.isShown = true;
    }

    this.finger.moveZigzag(targetPosition);

    targetPosition = this.scene.convertToNodeSpace(photos[1].convertToWorldSpace(touchPosition));
    this.finger.moveTo(targetPosition);

    this.finger.press();
    this.finger.addAction(function (f) {
        this.photoViews.onClick(imageId, touchPosition);

        f();
    }.bind(this));
    this.finger.unPress();

    this.finger.runOnce(callback);
};