/**
 * Created by mac on 9/25/20
 */

var EpisodeStatistics = function () {
    DataSource.call(this);
};

EpisodeStatistics.prototype = Object.create(DataSource.prototype);
EpisodeStatistics.prototype.constructor = EpisodeStatistics;

EpisodeStatistics.prototype.calculateData = function (episode, f) {
    var hashes = episode.getHashes();

    if (Object.keys(hashes).length === 0) {
        this.data = [];
        f();
        return;
    }

    var hashesIds = Object.keys(hashes).join(",");

    if (cleverapps.config.type === "merge") {
        return;
    }

    cleverapps.RestClient.get(DataSource.ReleaseAdminPath("basic/") + hashesIds, {}, function (data) {
        this.data = this.processData(episode, hashes, data);

        // console.log("Response episode: ", this.data);
        f();
    }.bind(this), function () {
        console.log("Error getting statistics for episode", episode);
    });
};

EpisodeStatistics.prototype.baseParams = function (hashes, data) {
    var result = {};
    data.forEach(function (line) {
        Object.assign(line, hashes[line.hash]);
        result[line.levelNo + "_" + line.version] = line;
    });
    return result;
};

EpisodeStatistics.prototype.getLevelData = function (episode, levelNo, data) {
    var versions = Object.keys(episode.getLevelVersions(levelNo)).map(function (version) {
        return parseInt(version);
    }).sort(function (a, b) {
        return a - b;
    }).reverse();

    var result = versions.map(function (version) {
        return data[levelNo + "_" + version];
    }).find(function (line) {
        return line;
    });

    if (!result) {
        result = {
            levelNo: levelNo,
            version: -1,
            difficulty: 0,
            hints: 0,
            minutes: 0
        };
    }

    if (result.difficulty) {
        result.difficulty = Math.round(result.difficulty);
    }
    if (result.hints) {
        result.hints = Math.round(result.hints);
    }

    result.currentVersion = episode.getMaxVersion(episode.getLevelVersions(levelNo));

    return result;
};

EpisodeStatistics.prototype.processData = function (episode, hashes, data) {
    data = this.baseParams(hashes, data);

    var result = episode.getLevels().map(function (level, levelNo) {
        return this.getLevelData(episode, levelNo, data);
    }.bind(this));

    return result;
};