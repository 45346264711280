/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsScale9Sprite = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint2();

        properties = properties || {};

        if (properties.image && properties.image.bundle && bundles[properties.image.bundle]) {
            var resource = bundles[properties.image.bundle].frames[properties.image.image] || bundles[properties.image.bundle].urls[properties.image.image];
            if (resource) {
                var sprite = cleverapps.UI.createScale9Sprite(resource, cleverapps.UI.Scale9Rect.TwoPixelXY);
                sprite.setContentSize(properties.width || sprite.width, properties.height || sprite.height);
                this.addChild(sprite);

                this.setContentSize(sprite.getContentSize());
                sprite.setPositionRound(this.width / 2, this.height / 2);

                if (properties.flipX) {
                    sprite.setScaleX(-1);
                }
                if (properties.flipY) {
                    sprite.setScaleY(-1);
                }
                properties.rotation && sprite.setRotation(properties.rotation);
            }
        }
    }
});

AdsScale9Sprite.PROPERTIES = [{
    name: "image", type: "image", bundles: ["ads_title", "ads_frames", "ads_window"], value: { image: "newyork_bg", bundle: "ads_title" }, bubbleUp: true
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}];