/**
 * Created by andrey on 20.02.18.
 */

var WildcardBooster = function (id) {
    Booster.call(this, id);

    this.onCardCreatedListener = function () {};
};

WildcardBooster.prototype = Object.create(Booster.prototype);
WildcardBooster.prototype.constructor = WildcardBooster;

WildcardBooster.prototype.onGameStarted = function () {
    Game.currentGame.userStatus.on("inactive_very_short", this.hintAttempt.bind(this));
    Game.currentGame.userStatus.on("active", this.onUserActive.bind(this));
};

WildcardBooster.prototype.onExecute = function () {
    var card = new Wildcard();
    this.onCardCreatedListener(card);
    Game.currentGame.shiftWildcard(card);

    var msg = cleverapps.Random.mathChoose(cleverapps.exclamation.getCongratulations());
    cleverapps.exclamation.show(msg);
};

WildcardBooster.prototype.showTutorialStep = function () {
    if (!cleverapps.environment.isSceneWithPreview() && this.isForceAvailable()) {
        this.showForce();
        return true;
    }
};

WildcardBooster.prototype.isForceAvailable = function () {
    if (!Booster.prototype.isForceAvailable.call(this) || !this.isAvailable()) {
        return false;
    }

    var game = Game.currentGame;

    if (game.cardsTutorial && game.cardsTutorial.current || game.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }

    var noMoves = game.table.listMissed().length === 0;
    var comboIsClose = game.combo.level === cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE - 1;

    return noMoves && comboIsClose;
};

WildcardBooster.prototype.hintAttempt = function () {
    if (cleverapps.environment.isSceneWithPreview() || cleverapps.config.editorMode) {
        return;
    }

    var noMoves = Game.currentGame.table.listMissed().length === 0;
    var activeCombo = Game.currentGame.combo && Game.currentGame.combo.level > 0;

    if (noMoves && activeCombo) {
        this.onUserInactive();
    }
};