/**
 * Created by mac on 3/20/23
 */

var Grid = cc.Node.extend({
    ctor: function (game) {
        this._super();

        this.setContentSize2(cleverapps.styles.JamScene.cell.width * game.jamPuzzle.width, cleverapps.styles.JamScene.cell.height * game.jamPuzzle.height);
        this.setAnchorPoint2();

        var border = new cc.Scale9Sprite(bundles.jam.frames.grid_border_png);
        border.setContentSize2(this.width + cleverapps.styles.Grid.border.dx, this.height + cleverapps.styles.Grid.border.dy);
        border.setPositionRound(this.width / 2, this.height / 2);
        border.setLocalZOrder(-1);
        this.addChild(border);

        cleverapps.UI.onClick(this, function (point) {
            var x = Math.floor(point.x / cleverapps.styles.JamScene.cell.width);
            var y = Math.floor(point.y / cleverapps.styles.JamScene.cell.height);
            game.handleClick(x, y);
        }, {
            ignoreScale: true
        });
    }
});

cleverapps.styles.Grid = {
    border: {
        dx: 10,
        dy: 10
    }
};