/**
 * Created by Aleksandr on 02.02.2023
 */

if (typeof cleverapps === "undefined" && typeof exports !== "undefined") {
    var cleverapps = require("../utils");
    cleverapps.RestPayments = function () {};
}

cleverapps.KongregatePayments = function () {
    cleverapps.RestPayments.call(this);

    for (var itemId in cleverapps.config.products) {
        var product = cleverapps.config.products[itemId];
        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedId = itemId;
    }
};

cleverapps.KongregatePayments.prototype = Object.create(cleverapps.RestPayments.prototype);
cleverapps.KongregatePayments.prototype.constructor = cleverapps.KongregatePayments;

cleverapps.KongregatePayments.prototype.calcLoadedPrice = function (price) {
    return Math.ceil(price) * 10 + " {{";
};

cleverapps.KongregatePayments.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("kongregatepayments", function () {
        callback();
    });
};

cleverapps.KongregatePayments.prototype._purchaseOpen = function (product) {
    cleverapps.platform.exitFullscreen(function () {
        kongregate.mtx.purchaseItemsRemote(product.itemId, function (result) {
            if (result.success) {
                this.onPurchaseResult(cleverapps.Social.CODE_SUCCEED, this.purchaseProduct);
            } else {
                this.onPurchaseResult(cleverapps.Social.CODE_FAILED);
            }
        }.bind(this));
    }.bind(this));
};

cleverapps.KongregatePayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.KongregatePayments.isAppropriate = function () {
    return cleverapps.platform instanceof Kongregate;
};
