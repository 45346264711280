/**
 * Created by Andrey Popov on 10/20/22.
 */

var CapturedScenario = function () {
    this.steps = [];
    this.lastActionTime = Date.now();
};

CapturedScenario.prototype.pushAction = function (action) {
    action.beforeStartDelay = (Date.now() - this.lastActionTime) / 1000;
    action.beforeActionSeed = cleverapps.Random.state;

    if (action.beforeStartDelay > CapturedScenario.SAME_ACTION_INTERVAL) {
        this.steps.push(action);
        this.lastActionTime = Date.now();
    }
};

CapturedScenario.SAME_ACTION_INTERVAL = 0.01;