/**
 * Created by andrey on 05.06.18.
 */

cleverapps.DailyTask = function (type, goal, data) {
    this.type = type;
    this.goal = goal;

    this.progress = 0;

    this.data = data || {};
    this.reward = data.reward;
    this.swappable = cleverapps.DailyTasks.SWAPPABLE_TASKS.indexOf(type) !== -1;

    this.onUpdateListener = function () {};
};

cleverapps.DailyTask.prototype.getSaveInfo = function () {
    return {
        type: this.type,
        progress: this.progress,
        collected: this.collected,
        goal: this.goal,
        data: this.data
    };
};

cleverapps.DailyTask.prototype.addProgress = function (amount, options) {
    options = typeof amount === "object" ? amount : options;
    amount = typeof amount !== "number" ? 1 : amount;

    if (amount > 0) {
        return this.setProgress(this.progress + amount, options);
    }
};

cleverapps.DailyTask.prototype.isApplicable = function (options) {
    switch (this.type) {
        case cleverapps.DailyTasks.COLLECT_COLOR_CELLS:
            return this.data.color === options.color;
        case cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER:
            return this.data.letter === options.letter;
        case cleverapps.DailyTasks.COLLECT_CARDS_BY_VALUE:
            return this.data.cardValue === options.cardValue;
        case cleverapps.DailyTasks.COLLECT_CARDS_BY_SUIT:
            return this.data.cardSuit === options.cardSuit;
        case cleverapps.DailyTasks.COLLECT_HERO_CELLS:
            return this.data.color === options.color;
        case cleverapps.DailyTasks.MERGE:
            return this.data.mergeAmount <= options.mergeAmount;
    }
    return true;
};

cleverapps.DailyTask.prototype.notifyProgress = function () {
    if (this.progress === this.lastProgress) {
        return;
    }

    if (this.lastProgress === undefined) {
        this.lastProgress = 0;
    }

    var title = this.getTitle();
    cleverapps.notification.create(title.text, {
        toReplace: title.replace,
        image: bundles.notification.frames.icon,
        progress: {
            amount: this.lastProgress,
            newAmount: this.progress,
            total: this.goal
        }
    });

    this.lastProgress = this.progress;
};

cleverapps.DailyTask.prototype.setProgress = function (amount, options) {
    if (!this.isApplicable(options) || this.isFinished()) {
        return;
    }
    amount = typeof amount !== "number" ? 1 : amount;

    if (amount > this.goal) {
        amount = this.goal;
    }

    if (amount !== this.progress) {
        this.progress = amount;

        this.onUpdateListener();
        this.save();

        if (this.isFinished()) {
            cleverapps.dailyTasks.taskWasFinished(this);
        }

        if (this.type !== cleverapps.DailyTasks.COMPLETE_ALL) {
            setTimeout(this.notifyProgress.bind(this), 0);
        }
    }
};

cleverapps.DailyTask.prototype.isFinished = function () {
    return this.progress >= this.goal;
};

cleverapps.DailyTask.prototype.collect = function () {
    if (this.isFinished() && !this.collected) {
        this.collected = true;
        this.save();

        this.onUpdateListener();

        cleverapps.dailyTasks.onChangeStateListener();
    }
};

cleverapps.DailyTask.prototype.expire = function () {
    if (!this.isFinished()) {
        this.expired = true;
        this.onUpdateListener();
    }
};

cleverapps.DailyTask.prototype.givePrize = function () {
    if (this.isFinished() && !this.collected) {
        cleverapps.dailyTasks.collect(this);
        RewardWindow.createDailyTaskWindow(this);
    }
};

cleverapps.DailyTask.prototype.save = function () {
    cleverapps.dailyTasks.save();
};

cleverapps.DailyTask.prototype.getTitle = function () {
    var replace = {};

    if (cleverapps.DailyTasks.COLLECT_COLOR_CELLS === this.type) {
        replace.color = Messages.get("ColorCell." + this.data.color + ".Title");
    }

    if (cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER === this.type && this.data.letter) {
        replace.letter = this.data.letter.toUpperCase();
    }

    if (cleverapps.DailyTasks.COLLECT_HERO_CELLS === this.type) {
        replace.heroes = Messages.get("DailyTask.24." + this.data.color);
    }

    if (cleverapps.DailyTasks.COLLECT_CARDS_BY_VALUE === this.type) {
        var value = parseInt(this.data.cardValue) || this.data.cardValue;

        replace.cards = typeof value === "number" ? value : Messages.get("DailyTask.25." + value);
    }

    if (cleverapps.DailyTasks.COLLECT_CARDS_BY_SUIT === this.type) {
        replace.cards = Messages.get("DailyTask.26." + this.data.cardSuit);
    }

    if (cleverapps.DailyTasks.MERGE === this.type) {
        replace.amount = this.data.mergeAmount;
    }

    if ([cleverapps.DailyTasks.SPEND_GOLD, cleverapps.DailyTasks.SPEND_COINS].indexOf(this.type) !== -1) {
        replace.amount = this.goal;
    }

    var text = "DailyTask." + this.type + ".Title";

    return {
        text: text,
        replace: replace
    };
};

cleverapps.DailyTask.getIcon = function (type, data) {
    if (cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER === type) {
        var char = CharView.Create(data.letter, {
            bgImage: bundles.dailytasks.frames.letter_bg_png
        });
        if (cleverapps.config.name === "scramble") {
            char.setScale(0.8);
        }
        return char;
    }

    var typeByData = data.color || data.cardValue || data.cardSuit;
    var image = typeByData ? DailyTasksConfig[type].icon[typeByData] : DailyTasksConfig[type].icon;

    return new cc.Sprite(bundles.dailytasks.frames[image]);
};

cleverapps.DailyTask.prototype.getIcon = function () {
    return cleverapps.DailyTask.getIcon(this.type, this.data);
};

cleverapps.DailyTask.prototype.getReward = function () {
    if (cleverapps.config.type === "merge") {
        var mergeReward = DailyTasksConfig[this.type].reward;
        if (typeof mergeReward === "function") {
            mergeReward = mergeReward();
        }
        return cleverapps.clone(mergeReward, true);
    }

    var slot = cleverapps.dailyTasks.getTaskSlot(this);
    var rewards = RewardsConfig.DailyTask;
    var reward = cleverapps.Random.choose(rewards[slot]);
    if (["board", "match3"].indexOf(cleverapps.config.type) !== -1) {
        reward = cleverapps.user.prepareRewardByRich(reward);
    }
    return cleverapps.clone(reward, true);
};

cleverapps.DailyTask.Create = function (info) {
    var task = new cleverapps.DailyTask(info.type, info.goal, info.data);
    task.progress = info.progress > 0 ? info.progress : 0;
    task.collected = info.collected;
    return task;
};
