/**
 * Created by andrey on 11.03.2020.
 */

if (["ios", "macos"].indexOf(cleverapps.config.source || cleverapps.config.requestParamSource) !== -1) {
    cc.sys.os = cc.sys.OS_IOS;

    var webkitMessage = function (handlerCode, data, attempt) {
        var handler = window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[handlerCode];

        if (!handler) {
            var allInitialized = cleverapps && cleverapps.allInitialized;
            attempt = attempt || 0;
            if (!allInitialized && attempt < 10) {
                setTimeout(function () {
                    webkitMessage(handlerCode, data, attempt + 1);
                }, 1000);
            }
            return;
        }

        var str;
        try {
            str = JSON.stringify(data);
        } catch (e) {
            console.log("Stringify data error handler - " + handlerCode + " method - " + (data && data.method) + " " + e);
            return;
        }

        try {
            handler.postMessage(str);
        } catch (e) {
            console.log("Post message to IOS failed. " + e);
        }
    };

    // ios can't serialize HTMLElements, so we need to skip them
    var isNodeOrElement = function (o) {
        return (
            typeof Node === "object" ? o instanceof Node
                : o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
        ) || (
            typeof HTMLElement === "object" ? o instanceof HTMLElement
                : o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
        );
    };

    console.log = function () {
        for (var i = 0; i < arguments.length; i++) {
            if (isNodeOrElement(arguments[i])) {
                return;
            }
        }

        webkitMessage("console", {
            method: "log",
            message: arguments[0],
            params: Array.from(arguments).slice(1)
        });
    };
    console.warn = function () {
        for (var i = 0; i < arguments.length; i++) {
            if (isNodeOrElement(arguments[i])) {
                return;
            }
        }
        webkitMessage("console", {
            method: "warn",
            message: arguments[0],
            params: Array.from(arguments).slice(1)
        });
    };
    console.debug = function () {
        for (var i = 0; i < arguments.length; i++) {
            if (isNodeOrElement(arguments[i])) {
                return;
            }
        }
        webkitMessage("console", {
            method: "debug",
            message: arguments[0],
            params: Array.from(arguments).slice(1)
        });
    };
    console.info = function () {
        for (var i = 0; i < arguments.length; i++) {
            if (isNodeOrElement(arguments[i])) {
                return;
            }
        }
        webkitMessage("console", {
            method: "info",
            message: arguments[0],
            params: Array.from(arguments).slice(1)
        });
    };
    console.error = function () {
        for (var i = 0; i < arguments.length; i++) {
            if (isNodeOrElement(arguments[i])) {
                return;
            }
        }
        webkitMessage("console", {
            method: "error",
            message: arguments[0],
            params: Array.from(arguments).slice(1)
        });
    };

    cc.sys.localStorage = {
        clear: function () {
            cleverapps.webViewData.localStorageData = {};
            webkitMessage("localStorage", {
                action: "clear"
            });
        },

        setItem: function (key, value) {
            cleverapps.webViewData.localStorageData[key] = value;
            webkitMessage("localStorage", {
                action: "setItem",
                key: key,
                value: value
            });
        },

        removeItem: function (key) {
            delete cleverapps.webViewData.localStorageData[key];
            webkitMessage("localStorage", {
                action: "removeItem",
                key: key
            });
        },

        getItem: function (key) {
            return cleverapps.webViewData.localStorageData[key];
        },

        getData: function () {
            return cleverapps.webViewData.localStorageData;
        }
    };

    cc.game._run = cc.game.run;
    cc.game.run = function () {
        var self = this;
        var args = arguments;
        cleverapps.webViewData = new cleverapps.WebViewData();
        cleverapps.webViewData.init(function () {
            cc.sys.language = cleverapps.webViewData.langCode;
            if (cleverapps.webViewData.source === "macos") {
                cc.sys.os = cc.sys.OS_OSX;
                cleverapps.config.source = "macos";
                cc.sys.isMobile = false;
            } else if (cleverapps.webViewData.source === "ios_ch") {
                cleverapps.config.source = "ios_ch";
                cleverapps.config.languages = ["en", "zh"];
            }

            console.log("After webview init");
            console.log(cleverapps.config.source);
            console.log(cleverapps.webViewData.source);

            cc.game._run.apply(self, args);
        });
    };

    var AdjustPlugin = AdjustPluginIOS;
}