/**
 * Created by vladimir on 12.10.17
 */

var EditorLevelView = cc.Node.extend({
    ctor: function () {
        this._super();

        var winSize = cleverapps.UI.getWinSize(true);
        this.setPosition(winSize.width / 2, winSize.height + cleverapps.styles.EditorLevelView.y);

        var title = cleverapps.UI.generateTTFText("LevelNo:", cleverapps.styles.FONTS.EDITOR_TEXT);

        this.levelNo = new IncDecBlock({
            range: [1, cleverapps.episodes._calcAmountEpisodes(true) * Episode.LEVELS_PER_EPISODE],
            onChange: this.gotoNewLevel.bind(this),
            icons: "leftright",
            value: LevelManager.getInstance().level.getHumanReadableNumber()
        });

        cleverapps.UI.wrap(this.levelNo);

        var content = new cleverapps.Layout([title, this.levelNo], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.EditorLevelView.margin
        });
        this.addChild(content);
    },
    
    gotoNewLevel: function (levelNo) {
        levelNo--;
        var episodeNo = Math.floor(levelNo / Episode.LEVELS_PER_EPISODE);
        if (typeof LevelManager.getInstance().level.episodeNo === "string") {
            episodeNo = LevelManager.getInstance().level.episodeNo;

            var content = LevelManager.getInstance().episode.content;
            content = Array.isArray(content) ? content : content.levels;
            if (levelNo >= content.length) {
                return;
            }
        }

        LevelManager.getInstance().setVersion(undefined);
        LevelManager.getInstance().setLevel({
            episodeNo: episodeNo,
            levelNo: levelNo % Episode.LEVELS_PER_EPISODE
        });

        cleverapps.setUrlHash({
            episode: episodeNo,
            level: levelNo % Episode.LEVELS_PER_EPISODE
        });

        LevelManager.getInstance().editLevel();
    }
});

cleverapps.styles.EditorLevelView = {
    y: -40,
    margin: 10
};