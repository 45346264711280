/**
 * Created by Aleksandr on 03.04.2023
 */

var WindowScoreView = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.WindowScoreView;

        var score = this.score = Game.currentGame.score.getPoints();
        var highScore = cleverapps.loop.getInfo().highscore;
        
        var title = cleverapps.UI.generateOnlyText(Messages.get(score >= highScore ? "WindowScoreView.newRecord" : "WindowScoreView.yourScore"), cleverapps.styles.FONTS.LOOP_SCORE_TITLE_TEXT);
        this.animatedAmount = cleverapps.UI.generateOnlyText(0, cleverapps.styles.FONTS.LOOP_SCORE_VALUE_TEXT);

        var items = [new cleverapps.Layout([title, this.animatedAmount], { direction: cleverapps.UI.VERTICAL })];

        if (score >= highScore) {
            items.unshift(new cc.Sprite(bundles.game.frames.new_high_score_icon));
        } else {
            var bestScoreTitle = cleverapps.UI.generateOnlyText(Messages.get("WindowScoreView.bestScore"), cleverapps.styles.FONTS.LOOP_SCORE_SECONDARY_TITLE_TEXT);
            var bestScoreAmount = new TextWithIcon("@@" + highScore, {
                font: cleverapps.styles.FONTS.LOOP_SCORE_SECONDARY_VALUE_TEXT,
                icons: {
                    "@@": bundles.game.frames.high_score_icon
                }
            });
            this.bestScoreLayout = new cleverapps.Layout([bestScoreTitle, bestScoreAmount], {
                direction: cleverapps.UI.VERTICAL
            });
            items.push(this.bestScoreLayout);
        }

        if (score !== 0) {
            cleverapps.audio.playSound(bundles.blocks.urls.scoring_effect);
        }

        this._super(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },
    
    showUp: function () {
        var delay = 1;
        this.animatedAmount.countToAnimation(this.score, 1);

        if (this.bestScoreLayout) {
            this.bestScoreLayout.setCascadeOpacityEnabledRecursively(true);
            this.bestScoreLayout.setVisible(false);
            this.bestScoreLayout.runAction(new cc.Sequence(
                new cc.FadeTo(0, 0),
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.FadeIn(0.3).easing(cc.easeOut(1.2))
            ));
            delay += 0.3;
        }

        return delay * 1000;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LOOP_SCORE_TITLE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    LOOP_SCORE_VALUE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    LOOP_SCORE_SECONDARY_TITLE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    LOOP_SCORE_SECONDARY_VALUE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.WindowScoreView = {
    margin: 50
};