/**
 * Created by Andrey Popov on 25.02.20.
 */

cleverapps.WebViewData = function () {
};

cleverapps.WebViewData.prototype.init = function (callback) {
    webkitMessage("localStorage", {
        action: 'init'
    });

    this.initCallback = callback;
};

cleverapps.WebViewData.prototype.onInit = function (
    encodedLocalStorageData,
    data) {
        var parsedData = JSON.parse(encodedLocalStorageData);
        Object.keys(parsedData).forEach(function (key) {
            try {
                parsedData[key] = decodeURIComponent(parsedData[key]);
            } catch (e) {
                console.log("LocalStorage key - " + key + " decode error.");
            }
        });
        this.localStorageData = parsedData;

        data = JSON.parse(decodeWebViewData(data));
        console.log('WebViewData.init data - ' + JSON.stringify(data));

        this.sourceApplicationUrl = data.sourceApplicationUrl;
        this.deviceVersion = data.deviceVersion;
        this.deviceName = data.deviceName;
        this.advertisingId = data.advertisingId;
        this.langCode = data.langCode;
        this.source = data.source;
        this.appleSocialAvailable = data.appleSocial;

        console.log("device version " + this.deviceVersion + " device name " + this.deviceName);
        console.log("advertisingId " + this.advertisingId);
        console.log("langCode " + this.langCode);
        console.log("appleSocialAvailable " + this.appleSocialAvailable);

        // console.log("LocalStorage data loaded: " +  JSON.stringify(this.localStorageData));

        if (this.initCallback) {
            this.initCallback();
            delete this.initCallback;
        }
};