/**
 * Created by Denis Kuzin on 08 july 2021
 */
var AdsLayerBlur = cc.Node.extend({
    ctor: function (properties, eventBus, parent, sceneSize) {
        this._super();

        this.properties = properties;

        var width = sceneSize.width;
        var height = sceneSize.height;

        this.setAnchorPoint2();
        this.setContentSize2(width, height);

        var source = this.source = new cc.Node();
        source.setContentSize2(width, height);
        source.setAnchorPoint(0, 0);
        this.addChild(source);

        var destination = this.destination = new cc.Node();
        destination.setContentSize2(width, height);
        destination.setAnchorPoint(0, 0);
        this.addChild(destination, 999);

        properties.listensEvents.forEach(function (event) {
            eventBus.on(event, this.init.bind(this), this);
        }.bind(this));

        properties.listensEventsToRestore.forEach(function (event) {
            eventBus.on(event, function () {
                setTimeout(function () {
                    this.state = AdsLayerBlur.STATES.BLUR_IN;
                }.bind(this), properties.delayBeforeRestore * 1000 || 0);
            }.bind(this), this);
        }.bind(this));
    },

    onRiseTimeFinished: function () {
        if (this.properties.listensEventsToRestore.length) {
            return;
        }

        setTimeout(function () {
            this.state = AdsLayerBlur.STATES.BLUR_IN;
        }.bind(this), this.properties.delayBeforeRestore * 1000 || 0);
    },

    init: function () {
        if (!this.properties.riseTime || !this.properties.blurOffset) {
            return;
        }

        this.restore();

        var parent = this.parent;
        while (parent.parent) {
            if (parent && parent.clip) {
                var clipBg = this.clipBg = parent.content.getChildByTag("clipBg");
                if (clipBg) {
                    clipBg.realParent = clipBg.parent;
                    clipBg.replaceParentSamePlace(this.source);
                }
                break;
            }

            parent = parent.parent;
        }

        var replaceParentForNested = function (component) {
            component.realParent = component.parent;
            component.replaceParentSamePlace(this.source);

            if (component.nestedComponents) {
                component.nestedComponents.forEach(function (nestedComponent) {
                    replaceParentForNested(nestedComponent.parent);
                });
            }
        }.bind(this);
        this.nestedComponents.forEach(function (component) {
            replaceParentForNested(component);
        });

        var blurResult = new cc.Sprite();
        blurResult.setAnchorPoint2(0, 0);
        this.destination.addChild(blurResult);
        blurResult.setPosition(0, 0);

        var blurStrength = this.properties.blurStrength || 0;
        blurStrength = (blurStrength + 1) / 4;

        var blurTexture = [];
        var numberOfPasses = this.properties.numberOfPasses || 1;

        for (var i = 0; i < numberOfPasses + 1; i++) {
            blurTexture.push(new cc.RenderTexture(this.width, this.height));
        }

        var program = cc.shaderCache.getProgram(this.properties.program);
        var isCurtain = [cleverapps.Shaders.BLUR_CURTAIN_KEY, cleverapps.Shaders.BLUR_CURTAIN_REVERSE_KEY].indexOf(this.properties.program) !== -1;
        var currentBlurOffset = 0;
        var currenExtent = 0;
        this.state = AdsLayerBlur.STATES.BLUR_OUT;
        this.scheduleUpdate();
        this.update = function (dt) {
            var adjustment = function (sign) {
                var tmp;
                if (isCurtain) {
                    currentBlurOffset = this.properties.blurOffset;
                    tmp = currenExtent;
                    currenExtent += sign * dt / this.properties.riseTime;
                    if (sign >= 0) {
                        currenExtent = Math.min(currenExtent, 1);
                    } else {
                        currenExtent = Math.max(currenExtent, 0);
                    }
                    return tmp !== currenExtent;
                } 
                tmp = currentBlurOffset;
                currentBlurOffset += sign * this.properties.blurOffset * dt / this.properties.riseTime;
                if (sign >= 0) {
                    currentBlurOffset = Math.min(currentBlurOffset, this.properties.blurOffset);
                } else {
                    currentBlurOffset = Math.max(currentBlurOffset, 0);
                }
                return tmp !== currentBlurOffset;
            }.bind(this);

            if (this.state === AdsLayerBlur.STATES.BLUR_IN) {
                if (!adjustment(-1)) {
                    this.unscheduleUpdate();
                    blurResult.setVisible(false);
                    this.restore();
                    return;
                }
            } else if (this.state === AdsLayerBlur.STATES.BLUR_OUT) {
                if (!adjustment(1)) {
                    this.state = AdsLayerBlur.STATES.NONE;
                    this.onRiseTimeFinished();
                }
            }

            blurTexture[0].begin();
            this.source.setVisible(true);

            this.source.visit();

            this.source.setVisible(false);
            blurTexture[0].end();

            for (var i = 0; i < numberOfPasses; i++) {
                program.use();
                program.setUniformLocationWith1f("u_blurOffset", currentBlurOffset);
                program.setUniformLocationWith1f("u_blurStrength", blurStrength);
                program.setUniformLocationWith1f("u_extent", currenExtent);

                var blur = new cc.Sprite(blurTexture[i].getSprite().getTexture());
                blur.setAnchorPoint(0, 0);
                if (isCurtain) {
                    if (!(i % 2)) {
                        blur.setShaderProgram(program);
                    }
                } else {
                    blur.setShaderProgram(program);
                }

                blurTexture[i + 1].begin();
                blur.setScaleX(cc.visibleRect.width / this.width);
                blur.setScaleY(cc.visibleRect.height / this.height);
                blur.visit();
                blurTexture[i + 1].end();
            }

            var sprite = new cc.Sprite(blurTexture[numberOfPasses].getSprite().getTexture());
            blurResult.setSpriteFrame(sprite.getSpriteFrame());

            if (!(numberOfPasses % 2)) {
                blurResult.setFlippedY(true);
            }
        };
    },

    restore: function () {
        this.destination.removeAllChildren(true);

        this.source.setPosition(0, 0);
        this.source.setScale(1);

        if (this.clipBg && this.clipBg.realParent) {
            this.clipBg.replaceParentSamePlace(this.clipBg.realParent);
            this.clipBg.realParent = undefined;
        }

        if (this.nestedComponents) {
            var replaceParentForNested = function (component) {
                if (component.realParent) {
                    component.replaceParentSamePlace(component.realParent);
                    component.realParent = undefined;
                }

                if (component.nestedComponents) {
                    component.nestedComponents.forEach(function (nestedComponent) {
                        replaceParentForNested(nestedComponent.parent);
                    });
                }
            };
            this.nestedComponents.forEach(function (component) {
                replaceParentForNested(component);
            });
        }
    }
});

AdsLayerBlur.STATES = {
    NONE: 0,
    BLUR_OUT: 1,
    BLUR_IN: 2
};

AdsLayerBlur.PROPERTIES = [{
    name: "program",
    type: "string",
    items: [cleverapps.Shaders.BLUR_FADE_KEY, cleverapps.Shaders.BLUR_CURTAIN_KEY, cleverapps.Shaders.BLUR_CURTAIN_REVERSE_KEY],
    value: cleverapps.Shaders.BLUR_FADE_KEY
}, {
    name: "blurOffset", type: "number", value: 0.001
}, {
    name: "blurStrength", type: "number", items: [0, 1, 2, 3], value: 3
}, {
    name: "riseTime", type: "number", value: 2
}, {
    name: "numberOfPasses", type: "number", value: 1
}, {
    name: "listensEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.SCENE_STARTED]
}, {
    name: "delayBeforeRestore", type: "number"
}, {
    name: "listensEventsToRestore", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: []
}];