/**
 * Created by Denis Kuzin on 17 january 2023
 */

var Tile3Player = function (level, options) {
    GamePlayer.call(this, level, options);

    this.finger.relaxPosition = { x: this.scene.width + this.finger.finger.width };
};

Tile3Player.prototype = Object.create(GamePlayer.prototype);
Tile3Player.prototype.constructor = Tile3Player;

Tile3Player.prototype.playAction = function (action, f) {
    if (action.type === "show_finger") {
        f();
    } else if (action.type === "play") {
        this.playTableCard(action.index, f);
    }
};

Tile3Player.prototype.playTableCard = function (cardIndex, f) {
    var waitTime = 5000;
    var interval = 100;

    var nextTry = function () {
        var cardView = this.game.table.cards[cardIndex].onGetView();

        if (!cardView && waitTime > 0) {
            waitTime -= interval;
            setTimeout(nextTry, interval);
            return;
        }

        var action = function () {
            this.game.table.cardClicked(cardView.card || cardView.decorator);
        }.bind(this);

        var targetBox = cardView.getGlobalBoundingBox();
        var location = cc.p(targetBox.x + targetBox.width / 2, targetBox.y + targetBox.height / 2);

        if (cardView) {
            this.fingerClickAction(cardView, action, { doNotFollowFinger: true, callback: f, location: location });
        } else {
            f();
        }
    }.bind(this);

    nextTry();
};