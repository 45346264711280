/**
 * Created by mac on 9/25/20
 */

var LevelStatistics = function () {
    DataSource.call(this);
};

LevelStatistics.prototype = Object.create(DataSource.prototype);
LevelStatistics.prototype.constructor = LevelStatistics;

LevelStatistics.prototype.calculateData = function (adminLevel, f) {
    var hashes = adminLevel.hashes;
    var hashesIds = Object.keys(hashes).join(',');

    if (cleverapps.config.type === 'merge') {
        return;
    }

    cleverapps.RestClient.get(DataSource.ReleaseAdminPath("basic/") + hashesIds, {}, function (response) {
        response = response.sort(function(a, b) {
           return parseInt(hashes[b.hash].version) - parseInt(hashes[a.hash].version)
        });

        // console.log("response: ", response);

        if (response.length === 0) {
            this.data = [{}];
            this.addPredictionData(adminLevel, this.data[0]);
            f();
            return;
        }

        this.data = response.map(function (versionData) {
            for (var key in versionData) {
                versionData[key] = Math.round(versionData[key]);
            }

            versionData.version = hashes[versionData.hash].version;

            delete versionData.hash;

            this.addPredictionData(adminLevel, versionData);

            return versionData;
        }, this);

        f();
    }.bind(this), function () {
        console.log("Error getting statistics for level", adminLevel);

        this.data = [{}];
        this.addPredictionData(adminLevel, this.data[0]);
        f();
    }.bind(this));
};

LevelStatistics.prototype.addPredictionData = function (adminLevel, data) {
    if (adminLevel.rate) {
        data.rate = adminLevel.rate;
    }

    if (cleverapps.config.subtype === 'stacks') {
        data.wordsNo = adminLevel.words.length;
        data.letters = adminLevel.words.reduce(function(a, c) { return a + c.length; }, 0);

        data.hardness = BlockMixer.predictHardness(adminLevel.words);

        cleverapps.Random.seed(0);
        var mixer = new BlockMixer({
            quick: true
        });

        for (var i = 0; i < 10; i ++) {
            var position = mixer.mix(adminLevel.words, {
                details: true
            });
            var res = mixer.calculate(position);
            if (res !== -1) {
                data.operations = Math.round(res);
                break;
            }
        }
    }
};
