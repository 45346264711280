/**
 * Created by r4zi4l on 01.08.2022
 */

/* eslint-disable no-global-assign, no-proto, new-parens */

var DateShift = {
    Date: Date,

    setShift: function (delta) {
        delta = parseInt(delta) || 0;

        cc.director.setNextDeltaTimeZero(true);

        if (!delta) {
            Date = DateShift.Date;
            this.hideTime();
        } else {
            Date = function () {
                var args = Array.prototype.concat.apply([undefined], arguments);
                var date = new (Function.prototype.bind.apply(DateShift.Date, args));
                return new DateShift.Date(arguments.length > 1 || arguments.length === 1 && typeof arguments[0] === "string" ? date.getTime() : date.getTime() + delta);
            };

            Date.__proto__ = DateShift.Date;

            Date.now = function () {
                return DateShift.Date.now() + delta;
            };

            this.showTime();
        }

        if (cleverapps.eventManager) {
            cleverapps.eventManager.setCurrentFeatures();
        }
    },

    setCurrent: function (date) {
        this.setShift(date - DateShift.Date.now());
    },

    showTime: function () {
        if (this.profiler) {
            return;
        }

        var container = this.profiler = document.createElement("div");
        container.style.position = "absolute";
        container.style.padding = "3px";
        container.style.backgroundColor = "rgb(0, 0, 34)";
        container.style.bottom = "90px";
        container.style.left = "0px";
        container.style.color = "rgb(0, 255, 255)";
        container.style.font = "bold 12px Helvetica, Arial";
        cc.container.appendChild(container);

        var time = this.profilerTime = document.createElement("div");
        container.appendChild(time);

        this.profilerListener = cc.eventManager.addCustomListener(cc.Director.EVENT_AFTER_VISIT, this.updateProfiler.bind(this));
        // cc.eventManager.addListener(this.profilerListener, 1);
    },

    hideTime: function () {
        if (!this.profiler) {
            return;
        }

        cc.eventManager.removeListener(this.profilerListener);
        this.profiler.remove();
        this.profiler = undefined;
        this.profilerTime = undefined;
        this.profilerListener = undefined;
    },

    updateProfiler: function () {
        var time = new Date();

        var format = function (value) {
            switch (String(value).length) {
                case 0: return "00";
                case 1: return "0" + value;
                default: return value;
            }
        };

        var text = format(time.getDate()) + "." + format(time.getMonth() + 1) + "." + time.getFullYear()
            + " " + format(time.getHours()) + ":" + format(time.getMinutes()) + ":" + format(time.getSeconds());

        if (text !== this.profilerTime.innerText) {
            this.profilerTime.innerText = text;
        }
    },

    getDelta: function () {
        return Date.now() - this.Date.now();
    }
};

cc.Director.prototype.setDisplayStats = cleverapps.extendFunc(cc.Director.prototype.setDisplayStats, function (displayStats) {
    this._super(displayStats);

    if (displayStats && Date !== DateShift.Date) {
        DateShift.showTime();
    } else {
        DateShift.hideTime();
    }
});
