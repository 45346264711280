/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles = {};

cleverapps.styles.ALIGN_BOTTOM = "b";

cleverapps.ASPECT_RATIO = 1920 / 1080;

cleverapps.styles.SCENE_HEIGHT = 1334;

cleverapps.styles.SCENE_MIN_WIDTH = Math.round(cleverapps.styles.SCENE_HEIGHT / cleverapps.ASPECT_RATIO / 1.137);
cleverapps.styles.SCENE_MAX_WIDTH = Math.round(cleverapps.styles.SCENE_HEIGHT * cleverapps.ASPECT_RATIO * 1.644);

cleverapps.styles.SQUARE_MODE_WIDTH = 1.97 * cleverapps.styles.SCENE_MIN_WIDTH;
cleverapps.styles.HORIZONTAL_MODE_WIDTH = 0.52 * cleverapps.styles.SCENE_MAX_WIDTH;

cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE = 150;
cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE_FS = 350;

cleverapps.styles.SIDEBAR_WIDTH = 35;
cleverapps.styles.MIN_SIDEBAR_X = 2;

cleverapps.styles.COLORS = {
    BLACK: new cc.Color(0, 0, 0, 255),
    BLACK_STROKE: new cc.Color(0, 0, 0, 90),
    COLOR_GRAY: new cc.Color(200, 200, 200, 255),
    COLOR_DARK_GRAY: new cc.Color(150, 150, 150, 255),
    COLOR_VERY_DARK_GRAY: new cc.Color(100, 100, 100, 255),
    WHITE: new cc.Color(255, 255, 255, 255),
    GREEN: new cc.Color(13, 119, 0, 255),
    YELLOW: new cc.Color(255, 255, 0, 255),
    PURPLE: new cc.Color(101, 24, 143, 255),
    HOVER: new cc.Color(230, 230, 230, 255),
    COLOR_BROWN: new cc.Color(60, 45, 25, 255),
    COLOR_RED: new cc.Color(255, 0, 0, 255),
    COLOR_BLUE: new cc.Color(0, 177, 249, 255),
    MESSAGE_GREEN: new cc.Color(4, 160, 41, 255),
    MESSAGE_RED: new cc.Color(246, 42, 71, 255),
    MESSAGE_PINK: new cc.Color(245, 0, 138, 255),
    LIFE_GREEN: new cc.Color(24, 200, 61, 255),
    PINK: new cc.Color(255, 102, 102, 255),
    COLOR_DARK_BROWN: new cc.Color(120, 85, 85, 255),
    HERO_STATS_UPGRADE: new cc.Color(57, 237, 7, 255),
    REWARDS_TEXT_STROKE_COLOR: new cc.Color(76, 98, 109, 255),
    GRAY_SCALE_COLOR: new cc.Color(135, 135, 135, 255),
    GRAY_SCALE_RESTORE_COLOR: new cc.Color(255, 255, 255, 255),
    DAILY_TOURNAMENT_TIMER: new cc.Color(130, 70, 40, 255),
    DARK_RED: new cc.Color(200, 0, 0, 255),
    COLOR_DARK_YELLOW: new cc.Color(255, 194, 9, 255),
    COLOR_BROWN_2: new cc.Color(173, 61, 0, 255),
    COLOR_WINDOW_TEXT: new cc.Color(130, 70, 40, 255),
    COLOR_VERYLIGHTBROWN: new cc.Color(220, 191, 144, 255),
    TOOLTIP_COLOR: new cc.Color(148, 49, 19, 255),
    COINS_REWARD_YELLOW: new cc.Color(255, 244, 55, 255),
    COINS_REWARD_STROKE_COLOR: new cc.Color(149, 67, 23, 255),
    MINIGAME_REWARD_TEXT: new cc.Color(66, 68, 80),
    DARK_TEXT_COLOR: new cc.Color(255, 255, 255, 255),
    DRAGGING_UNIT_COLOR: new cc.Color(180, 255, 180, 255),

    LIGHT_TEXT_STROKE_COLOR: new cc.Color(0, 0, 0, 125),
    LIGHT_TEXT_SHADOW_COLOR: new cc.Color(9, 0, 0, 100)
};

cleverapps.styles.DECORATORS = {
    WHITE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(0, -4),
        blur: 5
    },

    BROWN_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_BROWN_2,
        size: 3
    },

    BUTTON_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(0, 0),
        blur: 5
    },

    BUTTON_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    },

    WINDOW_WHITE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 1
    },

    REWARDS_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.REWARDS_TEXT_STROKE_COLOR,
        size: 3
    },

    DAILY_TOURNAMENT_TITLE_STROKE: {
        color: new cc.Color(116, 17, 168, 255),
        size: 4
    },

    TILE_SHOP_WINDOW_STROKE: {
        color: new cc.Color(150, 111, 72, 255),
        size: 2
    },

    TILE_SHOP_WINDOW_TITLE_SHADOW: {
        color: new cc.Color(112, 109, 109, 255),
        direction: cc.size(0, -4),
        blur: 0
    },

    IMAGE_FONT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 1
    },

    IMAGE_FONT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(0, -1),
        blur: 3
    },

    TRANSPARENT_BLACK_2PX: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 2
    },

    WHITE_STROKE: {
        color: cleverapps.styles.COLORS.WHITE,
        size: 2
    },

    LIGHT_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
        size: 1
    },

    LIGHT_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, -3),
        blur: 3
    },

    RED_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_RED,
        size: 2
    },

    BLUE_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_BLUE,
        size: 2
    },

    MESSAGE_RED_STROKE: {
        color: cleverapps.styles.COLORS.MESSAGE_RED,
        size: 4
    },

    MESSAGE_PINK_STROKE: {
        color: cleverapps.styles.COLORS.MESSAGE_PINK,
        size: 4
    },

    MESSAGE_GREEN_STROKE: {
        color: cleverapps.styles.COLORS.MESSAGE_GREEN,
        size: 4
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts({}, {
    VICTORY_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    FBLOGINORINVITE_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    BUTTON_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.BUTTON_TEXT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.BUTTON_TEXT_STROKE
    },

    STRICT_TEXT: {
        size: 30,
        color: new cc.Color(83, 97, 106, 255),
        font: bundles.chat.urls.strict_font_ttf
    },

    STRICT_WHITE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        font: bundles.chat.urls.strict_font_ttf
    },

    STRICT_BLACK_TEXT: {
        size: 18,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.chat.urls.strict_font_ttf
    },
    
    TOURNAMENTSTORY_OFFERS_TEXT: {
        size: 16,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.BUTTON_TEXT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.BUTTON_TEXT_STROKE
    },

    TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    VICTORY_CHEST_TEXT: {
        name: "default",
        size: 50
    },

    SMALL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SMALL_TEXT_BROWN: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    WINDOW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },
    
    DAILY_TASK_BOOSTER_AMOUNT_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    WINDOW_SMALL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    REWARDS_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.REWARDS_TEXT_STROKE
    },

    SMALL_TEXT_ERROR: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_RED
    },

    EPISODE_TITLE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    WHITE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    RED_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    BIG_BROWN_TITLE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    SMALL_BROWN_TITLE_TEXT: {
        size: 15,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },
    
    SMALL_WHITE_TEXT: {
        name: "default",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    FORCE_MESSAGE_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    DAILY_TOURNAMENT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.DAILY_TOURNAMENT_TIMER
    },

    WHITE_TITLE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TOOLTIP_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.TOOLTIP_COLOR
    },

    TOOLTIP_FONT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.TOOLTIP_COLOR,
        size: 40
    },

    BOOSTERS_BEFORE_UNLOCK: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    },

    UNDERLINE: {
        name: "default",
        size: 25
    },

    MESSAGE_TEXT_NORMAL: {
        size: 77,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.MESSAGE_GREEN_STROKE
    },

    MESSAGE_TEXT_WARNING: {
        size: 77,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.MESSAGE_RED_STROKE
    },

    MESSAGE_TEXT_CONGRATS: {
        size: 77,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.MESSAGE_PINK_STROKE
    }
});

cleverapps.styles.SpineSkins = [];