/**
 * Created by slava on 23.03.17.
 */

cleverapps.Social = function () {
    this.onInitializedListeners = {
    };

    this.initialized = false;
};

cleverapps.Social.prototype.getSuggestedLanguage = function () {
    return undefined;
};

cleverapps.Social.prototype.initialize = function (callback) {
    this.init(function () {
        this.setInitialized(true);

        callback();
    }.bind(this));
};

cleverapps.Social.prototype.init = function (callback) {
    callback();
};

cleverapps.Social.prototype.isLoggedIn = function () {
};

cleverapps.Social.prototype.isInitialized = function () {
    return this.initialized !== false;
};

cleverapps.Social.prototype.setInitialized = function (initialized) {
    this.initialized = initialized;

    if (!this.initialized) {
        return;
    }

    for (var key in this.onInitializedListeners) {
        var listener = this.onInitializedListeners[key];
        listener();
    }

    this.onInitializedListeners = {
    };
};

cleverapps.Social.prototype.whenInitialized = function (name, callback) {
    if (this.isInitialized()) {
        callback();
    } else {
        this.onInitializedListeners[name] = callback;
    }
};

cleverapps.Social.prototype.getUserID = function () {
};

cleverapps.Social.prototype.getAccessToken = function () {
};

cleverapps.Social.prototype.login = function () {
};

cleverapps.Social.prototype.logout = function () {
};

cleverapps.Social.prototype.inviteFriends = function () {
};

cleverapps.Social.prototype.request = function () {
};

cleverapps.Social.prototype.shareDialog = function () {
};

cleverapps.Social.prototype.listFriends = function (callback) {
    callback(cleverapps.Social.CODE_SUCCEED, []);
};

cleverapps.Social.prototype.listInvitableFriends = function (callback) {
    callback(cleverapps.Social.CODE_SUCCEED, []);
};

cleverapps.Social.prototype.aboutMe = function (callback) {
    this.aboutUser("me", callback);
};

cleverapps.Social.prototype.aboutUser = function () {
};

cleverapps.Social.prototype.deleteAllRequests = function () {
};

cleverapps.Social.prototype.api = function () {
};

cleverapps.Social.prototype.sendActivity = function () {
};

cleverapps.Social.prototype.markAchievement = function () {
};

cleverapps.Social.prepareId = function (uid) {
    return "" + uid;
};

cleverapps.Social.prototype.checkConnection = function (onSuccess, onFailure, options) {
    var afterLogin = function () {
        onSuccess && onSuccess();
    };

    if (cleverapps.flags.nologin) {
        afterLogin();
        return;
    }

    if (typeof onFailure === "object") {
        options = onFailure;
        onFailure = function () {};
    }

    onFailure = onFailure || function () {};
    options = options || {};

    if (!this.isInitialized()) {
        cleverapps.notification.create("SocialManager.Initialization");
        onFailure();
        return;
    }

    options = Object.assign({}, options);
    options.onSuccess = afterLogin;
    options.onFailure = onFailure;

    if (!this.isLoggedIn()) {
        if (options.withWindow || cleverapps.platform.needWindowForLogin()) {
            new FbConnectWindow(options);
        } else {
            this.login(afterLogin, onFailure);
        }
    } else {
        afterLogin();
    }
};

cleverapps.Social.prototype.sendRequest = function (to, type, onSuccess) {
    if (!Array.isArray(to)) {
        to = [to];
    }

    this.checkConnection(function () {
        this.request({
            "to": to,
            "message": Messages.get(type + ".message"),
            "title": Messages.get(type + ".title")
        }, function (code) {
            if (code === cleverapps.Social.CODE_SUCCEED) {
                levels.friendSorter.markUsed(to);

                var friends = cleverapps.friends.listIds();
                var sentToFriends = to.filter(function (sentId) {
                    return friends.indexOf(sentId) !== -1;
                });

                if (sentToFriends.length > 0) {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_FRIEND, { value: sentToFriends.length });
                }

                if (to.length - sentToFriends.length > 0) {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_INVITE, { value: to.length - sentToFriends.length });
                }

                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_SENT_PREFIX + type, { value: to.length });

                if (onSuccess) {
                    onSuccess();
                }
            } else {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MESSAGE_CANCEL_PREFIX + type, { value: to.length });

                if (cleverapps.Social.CANCEL_CODES.indexOf(code) === -1) {
                    var eventName = cleverapps.EVENTS.MESSAGE_ERROR + "_" + cleverapps.platform.source;
                    if (typeof code === "number") {
                        eventName += "_" + code;
                    }
                    cleverapps.eventLogger.logEvent(eventName);
                }
            }
        });
    }.bind(this));
};

cleverapps.Social.prototype.getCodes = function () {
    return this.getCode().split("-").sort();
};

cleverapps.Social.prototype.getCode = function () {
    return cleverapps.platform.source;
};

cleverapps.Social.CODE_SUCCEED = 0;
cleverapps.Social.CODE_FAILED = 1;
cleverapps.Social.CODE_CANCELLED = 2;

cleverapps.Social.MAX_INVITE_RANDOM_FRIENDS = 10;

cleverapps.Social.CANCEL_CODES = [
    cleverapps.Social.CODE_CANCELLED
];

cleverapps.Social.ACTIVITY = {
    LVL_PASSED: 0,
    DAILY_TASK: 1
};

cleverapps.Social.ACTIVITIES_CONFIG = {};

cleverapps.Social.ACTIVITIES_CONFIG[cleverapps.Social.ACTIVITY.LVL_PASSED] = {
    probability: 0.25,
    messages: [{
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body1"
    }, {
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body2"
    }, {
        title: "Activity.PassLevel.title",
        body: "Activity.PassLevel.body3"
    }
    ]
};

cleverapps.Social.ACTIVITIES_CONFIG[cleverapps.Social.ACTIVITY.DAILY_TASK] = {
    probability: 0.5,
    messages: [{
        title: "Activity.DailyTask.title",
        body: "Activity.DailyTask.body1"
    }, {
        title: "Activity.DailyTask.title",
        body: "Activity.DailyTask.body2"
    }]
};
