/**
 * Created by vladislav on 15.10.2020
 */

var AdsTree = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint2();

        this.stage = 0;

        properties = properties || {};
        if (typeof properties.animationType === "string") {
            this.type = cleverapps.styles.AdsTree[properties.animationType.toUpperCase()];
        } else {
            this.type = properties.animationType || cleverapps.styles.AdsTree.TREE;
        }

        this.animation = new cleverapps.Spine(this.type.animation);
        this.animation.setAnimation(0, "idle_0", true);
        this.animation.setPositionRound(this.type);
        this.addChild(this.animation);

        if (this.type.loopedEffect) {
            this.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.CallFunc(function () {
                        cleverapps.audio.playSound(this.type.loopedEffect);
                    }.bind(this)),
                    new cc.DelayTime(6)
                )
            ));
        }

        this.setContentSize(cleverapps.styles.AdsTree);

        eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);

        if (properties.isPreview) {
            var previewProgress = 0;
            var updateInterval = setInterval(function () {
                this.incProgress(previewProgress);
                previewProgress += 0.05;
                if (previewProgress >= 1) {
                    clearInterval(updateInterval);
                }
            }.bind(this), 300);
        }
    },

    incProgress: function (progress) {
        var stage = Math.floor((this.type.stages - 1) * progress);
        if (stage !== this.stage) {
            this.stage = stage;
            this.animation.setAnimationAndIdleAfter("transition_" + (this.stage - 1), "idle_" + this.stage);
            cleverapps.audio.playSound(this.type.growEffect);

            if (this.type.additionalEffect) {
                setTimeout(function () {
                    cleverapps.audio.playSound(this.type.additionalEffect.sound);
                }.bind(this), this.type.additionalEffect.delay || 0);
            }
        } else {
            this.animation.setAnimationAndIdleAfter("transition_" + (this.stage) + "_pass", "idle_" + this.stage);
            cleverapps.audio.playSound(this.type.passEffect);
        }
    }
});

AdsTree.BUNDLES = ["ads_tree"];

AdsTree.PROPERTIES = [{
    name: "animationType", type: "string", items: ["tree", "dog", "cat", "snowman"], bubbleUp: true
}];

cleverapps.styles.AdsTree = {
    TREE: {
        animation: bundles.ads_tree.jsons.tree_json,
        stages: 11,
        x: { align: "center", dx: 100 },
        y: { align: "center", dy: 280 },
        growEffect: bundles.ads_tree.urls.progress_effect,
        passEffect: bundles.ads_tree.urls.foliage_effect
    },
    DOG: {
        animation: bundles.ads_tree.jsons.dog_json,
        stages: 7,
        x: { align: "center", dx: 100 },
        y: { align: "center", dy: 150 },
        growEffect: bundles.ads_tree.urls.progress_effect,
        passEffect: bundles.ads_tree.urls.dog_yip_effect
    },
    CAT: {
        animation: bundles.ads_tree.jsons.cat_json,
        stages: 7,
        x: { align: "center", dx: 100 },
        y: { align: "center", dy: 250 },
        growEffect: bundles.ads_tree.urls.progress_effect,
        passEffect: bundles.ads_tree.urls.cat_meow_effect,
        loopedEffect: bundles.ads_tree.urls.cat_purr_effect
    },
    SNOWMAN: {
        animation: bundles.ads_tree.jsons.snowman_json,
        stages: 11,
        x: { align: "center", dx: 100 },
        y: { align: "center", dy: 80 },
        growEffect: bundles.ads_tree.urls.snowman_progress_effect,
        passEffect: bundles.ads_tree.urls.snowman_pass_effect
    },
    width: 200,
    height: 400
};