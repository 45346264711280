/**
 * Created by slava on 24.03.17.
 */

var ForceView = Window.extend({
    ctor: function (element, force, options) {
        this.forceConfig = force;
        this.options = options || {};
        this.force = force;

        this._super(element, force);
    },

    beforeResize: function () {
        if (this.element) {
            this.backNodesFromForce();
        }
    },

    afterResize: function () {
        if (this.element) {
            this.upNodesForForce();
        }
    },

    updatePosition: function () {
        this.updateRects();
        if (this.text) {
            this.createDialogue();
        }
    },

    moveNodeAndKeepScale: function (node) {
        var parent = node.getParent();
        var scale = parent.adjustScaleTo(this);

        var container = new cc.Node();
        container.setScale(scale.x, scale.y);
        this.addChild(container);

        node.realParent = parent;
        node.realPosition = node.getPosition();
        node.replaceParentSamePlace(container);
    },

    upNodesForForce: function () {
        if (this.options.highlights) {
            this.options.highlights.forEach(function (node) {
                this.moveNodeAndKeepScale(node);
            }, this);
        }

        if (this.element !== ForceView.NO_ELEMENT) {
            this.moveNodeAndKeepScale(this.element);
        }
    },

    backNodesFromForce: function () {
        if (this.element.parent) {
            if (this.element && this.element !== ForceView.NO_ELEMENT) {
                this.returnNode(this.element);
            }
        }

        if (this.options.highlights) {
            this.options.highlights.forEach(function (node) {
                if (node.getParent()) {
                    this.returnNode(node);
                }
            }, this);
        }
    },

    returnNode: function (node) {
        node.replaceParentSamePlace(node.realParent);
        node.setPosition(node.realPosition);
        node.realPosition = undefined;
    },

    updateRects: function () {
        this.rects = this.options.rects || [];
        if (this.element && this.element !== ForceView.NO_ELEMENT) {
            this.rects.push(this.element.getGlobalBoundingBox());
        }
    },

    onWindowLoaded: function (element, force) {
        this.id = force.id;

        this._super({
            name: "forcewindow" + this.id,
            closeButton: false,
            styles: cleverapps.styles.Force
        });

        this.setLocalZOrder(31);

        cleverapps.toolbar.freezeUpdates();

        this.element = element;
        this.element.realParent = undefined;

        this.updateRects();

        this.upNodesForForce();

        if (element !== ForceView.NO_ELEMENT && force.finger !== false) {
            var scaleX = this.options.scaleX || 1;
            var scaleY = this.options.scaleY || 1;

            var fingerPos = cc.p(0, 0);
            var fingerStartPos;
            var fingerElement = element;
            var fingerDelay;
            var fingerDelayOnBegin = false;
            var fingerDelayOnBeginAndFinish = true;

            if (force.finger) {
                var fingerStyle = force.finger;
                if (typeof fingerStyle === "function") {
                    fingerStyle = fingerStyle();
                }
                if (fingerStyle.mobile && cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL) {
                    fingerStyle = fingerStyle.mobile;
                }

                fingerPos = cc.p(fingerStyle.x || 0, fingerStyle.y || 0);
                if (fingerStyle.startY) {
                    fingerStartPos = cc.p(0, fingerStyle.startY);
                }
                if (fingerStyle.useForceTarget) {
                    fingerElement = this.findForceTarget(element);
                }
                if (fingerStyle.delay) {
                    fingerDelay = fingerStyle.delay;
                }
                if (fingerStyle.delayOnBeginAndFinish === false) {
                    fingerDelayOnBegin = true;
                    fingerDelayOnBeginAndFinish = false;
                }
            }

            this.finger = new FingerView({ scaleX: scaleX, scaleY: scaleY });

            this.finger.updatePosition = fingerElement.createListener(function () {
                this.finger.setPosition(this.convertToNodeSpace(fingerElement.parent.convertToWorldSpace(fingerElement.getPosition())));
            }.bind(this));

            this.finger.standardLoop({
                fadeInPosition: fingerPos,
                fadeInStartPosition: fingerStartPos,
                delayOnBeginAndFinish: fingerDelayOnBeginAndFinish,
                delayOnBegin: fingerDelayOnBegin,
                loops: 1,
                delay: fingerDelay,
                onIterationFinish: this.finger.updatePosition
            });

            this.finger.updatePosition();
            this.addChild(this.finger);
        }
        if (element !== ForceView.NO_ELEMENT && force.pointer) {
            this.pointer = new PointerView({
                target: element,
                pointerPosition: force.pointer.position
            });
            element.addChild(this.pointer);
            this.rects.push(this.pointer.getGlobalBoundingBox());
        }

        var text = this.text = typeof (force.text) === "function" ? force.text(force, element) : force.text;
        if (text) {
            this.createDialogue();
        }

        if (element === ForceView.NO_ELEMENT || this.forceConfig.closeByTouchInShadow) {
            this.addCloseByTouchInShadow();
        }

        if (element !== ForceView.NO_ELEMENT) {
            this.watchElementInterval = setInterval(function () {
                // console.log('watchElementInterval', this.element.parent)
                if (!this.element.parent || !this.element.visible) {
                    if (cleverapps.forces.isRunningForce()) {
                        cleverapps.forces.closeRunningForce();
                    } else {
                        this.close();
                        cleverapps.forces.clearForce(this.id);
                    }
                }
            }.bind(this), 5000);
        }
    },

    createDialogue: function () {
        if (this.dialogue) {
            this.dialogue.removeFromParent();
        }

        this.dialogue = new MinimalDialogue({
            text: this.text,
            person: this.force.person,
            rects: this.rects,
            forcePosition: typeof (this.force.position) === "function" ? this.force.position() : this.force.position
        });
        this.addChild(this.dialogue);
        this.dialogue.display();
    },

    onClose: function () {
        if (this.pointer) {
            this.pointer.removeFromParent();
            delete this.pointer;
        }
        if (this.watchElementInterval) {
            clearInterval(this.watchElementInterval);
        }
        this.backNodesFromForce();

        cleverapps.toolbar.unfreezeUpdates();
    },

    addCloseByTouchInShadow: function () {
        this.clickArea = new cc.Node();
        this.clickArea.setContentSize(this.getContentSize());
        this.addChild(this.clickArea);
        cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function () {
                if (cleverapps.forces.isRunningForce()) {
                    cleverapps.forces.closeRunningForce();
                }
            }
        }, this.clickArea);
    },

    stop: function () {
    },

    listBundles: function () {
        return ["dialogues_minimal"];
    },

    findForceTarget: function (element) {
        var targets = [element];

        for (var i = 0; i < targets.length; ++i) {
            var target = targets[i];

            if (target.forceTarget) {
                return target;
            }

            targets = targets.concat(target.children);
        }
        return element;
    }
});

cleverapps.styles.Force = {
    windowShowUpAnimation: {
        name: "instant",
        force: true
    }
};

ForceView.NO_ELEMENT = "emptyForceElement";
