/**
 * Created by vladislav on 14.09.2020
 */

var AdsScale = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint2();
        var styles = this.styles = cleverapps.styles.AdsScale;

        properties = properties || {};
        var listensEvents = properties.listensEvents || [];
        if (properties.type) {
            styles = this.styles = cleverapps.overrideStyles(styles, styles[properties.type], true);
        } else if (styles.type) {
            styles = this.styles = cleverapps.overrideStyles(styles, styles[styles.type], true);
        }

        var scale = new cc.Sprite(styles.base);
        this.setContentSize2(scale.getContentSize());
        this.addChild(scale);
        scale.setPositionRound(this.width / 2, this.height / 2 + styles.offsetY);

        var icon;
        if (styles.pointer.json) {
            icon = this.icon = new cleverapps.Spine(styles.pointer.json);
            if (styles.pointer.idleAnimation) {
                icon.setAnimation(0, styles.pointer.idleAnimation, true);
            }
            icon.setVisible(true);
        } else {
            icon = this.icon = new cc.Sprite(styles.pointer.icon);
        }

        this.addChild(icon);
        icon.setPositionRound(styles.pointer.startX, styles.pointer.y + styles.offsetY);

        if (styles.bg) {
            var bg = cleverapps.UI.createScale9Sprite(styles.bg.image);
            bg.setContentSize2(styles.bg);
            this.addChild(bg, -1);
            bg.setPositionRound(this.width / 2, this.height / 2);
        }

        this.setScale(styles.scale || 1);
        this.setPositionRound(styles);

        this.setLocalZOrder(-1);

        if (properties.type && [AdsScale.TYPES.COLORED_CUPID, AdsScale.TYPES.SOLID_CUPID].indexOf(properties.type) !== -1) {
            this.createCupid();
        }

        if (listensEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);
        }
    },

    createCupid: function () {
        this.icon.setPositionRound(this.styles.cupid.x, this.styles.pointer.y + this.styles.offsetY);
        this.icon.setVisible(false);
        this.icon.setLocalZOrder(1);

        var cupid = this.cupid = new cleverapps.Spine(this.styles.pointer.json);

        cupid.setAnimation(0, this.styles.cupid.shoot, false);
        cupid.setTimeScale(0);

        this.addChild(cupid);
        cupid.setPositionRound(this.styles.cupid.x, this.styles.pointer.y + this.styles.offsetY);

        var heart = this.heart = new cleverapps.Spine(this.styles.pointer.json);
        heart.setAnimation(0, this.styles.heart.idle, true);
        this.addChild(heart);
        heart.setPositionRound(this.styles.heart.x, this.styles.pointer.y + this.styles.offsetY);
    },

    incProgress: function (progress) {
        if (this.cupid) {
            this.incProgressCupid(progress);
            return;
        }

        var styles = this.styles.pointer;
        this.icon.stopAllActions();
        var pos = styles.startX + (this.width - styles.finishXOffset - styles.startX) * progress;

        if (styles.json && progress === 1) {
            var triumphPosition = { x: pos, y: this.icon.y };

            if (this.styles.triumphPosition) {
                triumphPosition = cleverapps.overrideStyles(triumphPosition, this.styles.triumphPosition, true);
                triumphPosition.y += this.styles.offsetY;
            }

            this.icon.setAnimation(0, styles.name, false);
            this.icon.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.MoveTo(0.6, triumphPosition.x, triumphPosition.y),
                new cc.CallFunc(function () {
                    this.icon.setCompleteListener(function () {
                        if (styles.triumphAnimation) {
                            this.icon.setAnimation(0, styles.triumphAnimation, false);
                            cleverapps.audio.playSound(bundles.ads_scale.urls.electro_brain_effect);
                        }
                    }.bind(this));
                }.bind(this))
            ));
        } else {
            this.icon.runAction(new cc.Spawn(
                new cc.MoveTo(0.6, pos, this.icon.y).easing(cc.easeBackOut()),
                new cc.Sequence(
                    new cc.ScaleTo(0.4, 1.2),
                    new cc.ScaleTo(0.1, 1)
                )
            ));
        }
    },

    incProgressCupid: function (progress) {
        this.icon.stopAllActions();

        var pos = this.cupid.x + (this.heart.x - this.cupid.x) * progress;

        if (progress === 1) {
            this.icon.runAction(new cc.Sequence(
                new cc.MoveTo(0.3, pos, this.icon.y),
                new cc.CallFunc(function () {
                    var scene = cleverapps.scenes.getRunningScene(this).scene;

                    this.heart.replaceParentSamePlace(scene);
                    this.heart.setScale(this.scale);
                    this.heart.setLocalZOrder(2);

                    this.hit();
                    cleverapps.audio.playSound(bundles.ads_scale.urls.arrow_hit_effect);

                    var endOffset = this.styles.heart.endOffset;
                    if (endOffset) {
                        var endOffsetX = endOffset.x || 0;
                        var endOffsetY = endOffset.y || 0;
                        this.heart.runAction(new cc.Spawn(
                            new cc.Sequence(
                                new cc.DelayTime(0.32),
                                new cc.MoveTo(1.2, scene.width / 2 + endOffsetX, scene.height / 2 + endOffsetY).easing(cc.easeExponentialInOut())
                            ),
                            new cc.Sequence(
                                new cc.DelayTime(1.0),
                                new cc.CallFunc(function () {
                                    cleverapps.audio.playSound(bundles.ads_scale.urls.valentine_victory_effect);
                                })
                            )
                        ));
                    }
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        } else {
            cleverapps.audio.playSound(bundles.ads_scale.urls.arrow_fly_effect);

            if (!this.isFlyingArrow) {
                this.shoot(function () {
                    this.icon.runAction(new cc.MoveTo(0.6, pos, this.icon.y));
                }.bind(this));
            } else {
                this.icon.runAction(new cc.Spawn(
                    new cc.MoveTo(0.6, pos, this.icon.y).easing(cc.easeBackOut()),
                    new cc.Sequence(
                        new cc.ScaleTo(0.4, 1.2),
                        new cc.ScaleTo(0.1, 1)
                    )
                ));
            }
        }
    },

    shoot: function (callback) {
        this.cupid.setCompleteListener(function () {
            this.icon.setVisible(true);

            this.cupid.setCompleteListener(function () {});
            this.cupid.setAnimation(0, this.styles.cupid.idle, true);
            this.isFlyingArrow = true;

            callback && callback();
        }.bind(this));

        this.cupid.setTimeScale(1.0);
    },

    hit: function () {
        this.setLocalZOrder(1);
        this.heart.setAnimationAndIdleAfter(this.styles.heart.shine, this.styles.heart.shineIdle);
    }
});

cleverapps.styles.AdsScale = {
    x: { align: "center" },
    y: { align: "top", dy: -250 },
    base: bundles.ads_scale.frames.einstein_brown_bg,
    scale: 1.15,
    offsetY: 0,

    pointer: {
        json: bundles.ads_scale.jsons.brain_pointer,
        name: "brown",
        idleAnimation: "brown_idle",
        y: 100,
        startX: 100,
        finishXOffset: 72
    },

    brownBrain: {
        triumphPosition: { x: 573, y: 144 },
        pointer: {
            name: "brown",
            triumphAnimation: "brown_triumph"
        }
    },

    cuteBrain: {
        base: bundles.ads_scale.frames.cute_brain_bg,
        pointer: {
            json: bundles.ads_scale.jsons.brain_cute_pointer,
            name: "animation",
            idleAnimation: false,
            finishXOffset: 130
        }
    },

    whiteBrain: {
        base: bundles.ads_scale.frames.einstein_yellow_bg,
        triumphPosition: { x: 573, y: 144 },
        pointer: {
            json: bundles.ads_scale.jsons.brain_pointer,
            name: "white",
            idleAnimation: "white_idle",
            triumphAnimation: "white_triumph"
        }
    },

    witch: {
        y: { align: "top", dy: -200 },
        base: bundles.ads_scale.frames.witch_bg,
        pointer: {
            json: bundles.ads_scale.jsons.witch_pointer_json,
            name: "animation",
            idleAnimation: false,
            y: 130,
            startX: 20,
            finishXOffset: 170
        }
    },

    xmas: {
        base: bundles.ads_scale.frames.xmas_bar,
        pointer: {
            json: false,
            icon: bundles.ads_scale.frames.xmas_santa_bar,
            y: 75,
            startX: 60,
            finishXOffset: 70
        }
    },

    coloredCupid: {
        base: bundles.ads_scale.frames.cupid_heart_scale,
        zOrder: 1,
        pointer: {
            y: 70,
            idleAnimation: "fly",
            json: bundles.ads_scale.jsons.colorcupid_heart_json
        },
        cupid: {
            x: { align: "left", dx: -90 },
            idle: "idle_cupid",
            shoot: "shoot"
        },
        heart: {
            x: { align: "right", dx: 53 },
            idle: "idle_heart",
            shine: "shine",
            shineIdle: "shine_idle",
            endOffset: { x: -65 }
        }
    },

    solidCupid: {
        base: bundles.ads_scale.frames.cupid_heart_scale,
        pointer: {
            y: 70,
            idleAnimation: "fly",
            json: bundles.ads_scale.jsons.solidcupid_heart_json
        },
        cupid: {
            x: { align: "left", dx: -90 },
            idle: "idle_cupid",
            shoot: "shoot"
        },
        heart: {
            x: { align: "right", dx: 53 },
            idle: "idle_heart",
            shine: "shine",
            shineIdle: "shine_idle"
        }
    }
};

AdsScale.TYPES = {
    WITCH: "witch",
    BROWN_BRAIN: "brownBrain",
    CUTE_BRAIN: "cuteBrain",
    WHITE_BRAIN: "whiteBrain",
    XMAS: "xmas",
    COLORED_CUPID: "coloredCupid",
    SOLID_CUPID: "solidCupid"
};

AdsScale.BUNDLES = ["ads_scale"];

AdsScale.PROPERTIES = [{
    name: "type", type: "string", items: cleverapps.values(AdsScale.TYPES), bubbleUp: true
}, {
    name: "listensEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.PROGRESS_CHANGED]
}];