/**
 * Created by slava on 20/2/18
 */

var Chooser = cc.Node.extend({
    ctor: function (table, options) {
        this._super();
        this.table = table;

        this.options = options || {};

        this.onSelect = function () {};
        this.onBeforeSelect = function () {};

        var views = [];
        this.initViews = {};

        for (var key in table) {
            var view = table[key];
            this.initViews[key] = view;
            this.addChildForKey(view, key);

            views.push(view);
        }

        var columns = this.options.columns || 8;
        var rows = [];
        for (var i = 0; i < views.length; i += columns) {
            rows.push(views.slice(i, i + columns));
        }

        rows.reverse();

        var firstElementInRows = rows.map(function (row) {
            return row[0];
        });

        var height = cleverapps.UI.arrangeWithMargins(firstElementInRows, {
            direction: cleverapps.UI.VERTICAL
        });

        var width = 0;
        rows.forEach(function (row) {
            var firstElement = row[0];

            var rowWidth = cleverapps.UI.arrangeWithMargins(row, {
                margin: this.options.margin,
                direction: cleverapps.UI.HORIZONTAL,
                y: firstElement.y
            });

            if (rowWidth > width) {
                width = rowWidth;
            }
        }.bind(this));

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(width, height);
        this.setLocalZOrder(100);

        if (this.options.bg) {
            var bg = cleverapps.UI.createScale9Sprite(this.options.bg);
            bg.setContentSize(this.getContentSize());
            bg.setPosition(this.width / 2, this.height / 2);
            bg.setLocalZOrder(-1);
            this.addChild(bg);
        }

        if (this.options.removeOnAsideClick) {
            cc.eventManager.addListener({
                event: cc.EventListener.TOUCH_ONE_BY_ONE,
                swallowTouches: false,
                onTouchBegan: function (touch) {
                    var point = this.convertTouchToNodeSpace(touch);
                    if (!cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point)) {
                        this.removeFromParent();
                        return false;
                    }
                }.bind(this)
            }, this);
        }
    },

    setSelected: function (key) {
        if (this.selected) {
            this.removeSelection(this.selected);
            if (this.subChooser) {
                this.subChooser.removeFromParent();
            }
        }

        this.selected = key;

        if (key !== undefined) {
            this.showSelected(key);
        }
    },

    showSelected: function (key) {
        var view = this.table[key];

        if (this.options.scaleSelected) {
            view.setScale(this.options.scaleSelected);
        } else {
            view.selection = new cc.Sprite(bundles.editor ? bundles.editor.frames.editor_select : bundles.avatar.frames.avatar_frame);

            view.selection.setScale(Math.max(view.height, view.width) / view.selection.height);
            view.selection.setPosition(view.width / 2, view.height / 2);
            view.selection.setLocalZOrder(4);
            view.addChild(view.selection);
        }
    },

    removeSelection: function (key) {
        var view = this.table[key];

        if (this.options.scaleSelected) {
            view.setScale(1);
        } else {
            var selection = view.selection;
            selection.removeFromParent();
            delete view.selection;
        }
    },

    addChildForKey: function (view, key) {
        this.addChild(view);

        if (!this.options.noControl) {
            cc.eventManager.addListener({
                event: cc.EventListener.TOUCH_ONE_BY_ONE,
                swallowTouches: true,
                onTouchBegan: function (touch) {
                    if (!this.visible) {
                        return false;
                    }

                    var point = view.convertTouchToNodeSpace(touch);
                    if (cc.rectContainsPoint(cc.rect(0, 0, view.width, view.height), point)) {
                        this.runAction(new cc.Sequence(new cc.DelayTime(0), new cc.CallFunc(function () {
                            this.onBeforeSelect.call(this, key, view);
                            if (!this.disabled) {
                                if (this.selected && key === this.selected) {
                                    if (this.options.noUnselect) {
                                        return;
                                    }
                                    this.removeSelection(this.selected);
                                    this.selected = undefined;
                                    if (this.subChooser) {
                                        this.subChooser.removeFromParent();
                                    }
                                    return;
                                }
                                this.setSelected(key);
                                this.onSelect.call(this, key, view);
                            }
                        }.bind(this))));
                        return true;
                    }
                    
                    if (view === this.initViews[key] && key === this.selected) {
                        if (this.subChooser) {
                            this.subChooser.removeFromParent();
                        }
                        this.setSelected(key);
                    }
                    
                    return false;
                }.bind(this)
            }, view);
        }
    }
});