/**
 * Created by iamso on 22.07.21
 */

cleverapps.YandexPayments = function () {
    cleverapps.Payments.call(this);

    for (var i in cleverapps.config.products) {
        var product = cleverapps.config.products[i];

        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedId = product.id.yandex;
    }
};

cleverapps.YandexPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.YandexPayments.prototype.constructor = cleverapps.YandexPayments;

cleverapps.YandexPayments.prototype.calcLoadedPrice = function (price) {
    return Math.floor(price * cleverapps.RUB_DISCOUNTED_RATE) + "&&";
};

cleverapps.YandexPayments.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("yandexpayments", function () {
        cleverapps.social.ysdk.getPayments({ signed: true }).then(function (payments) {
            this.yaPayments = payments;

            if (cleverapps.config.debugMode || cleverapps.Random.nextDouble() < 0.01) {
                this.checkPrices();
            }

            callback();
        }.bind(this)).catch(function (err) {
            console.log("Payments unavailable", err);
        });
    }.bind(this));
};

cleverapps.YandexPayments.prototype.checkPrices = function () {
    this.yaPayments.getCatalog().then(function (products) {
        var errors = [];

        this.catalogProductIds = {};

        products.forEach(function (product) {
            this.catalogProductIds[product.id] = true;

            var ourProduct = Product.Create(product.id);
            if (ourProduct && parseInt(ourProduct.loadedPrice) !== parseInt(product.priceValue)) {
                errors.push(product.id);
            }
        }, this);

        if (errors.length > 0) {
            cleverapps.throwAsync("Yandex inconsistent products prices: " + errors.join(","));
        }
    }.bind(this)).catch(function (err) {
        console.log("Failed prices check", err);
    });
};

cleverapps.YandexPayments.prototype._purchase = function (product, callback) {
    if (!this.yaPayments) {
        console.log("Payments unavailable");
        return;
    }

    if (!product.id.yandex || this.catalogProductIds && !this.catalogProductIds[product.id.yandex]) {
        cleverapps.throwAsync("Unregistered yandex product: " + product.itemId
            + ", catalogProducts - " + JSON.stringify(Object.keys(this.catalogProductIds)));
        callback(cleverapps.Social.CODE_FAILED);
        return;
    }

    this.yaPayments.purchase({
        id: product.id.yandex,
        developerPayload: JSON.stringify({
            userId: cleverapps.user.id,
            itemId: product.itemId
        })
    }).then(function (purchase) {
        purchase.paymentId = purchase.purchaseToken;
        purchase.productId = product.itemId;
        console.log("success", purchase);
        callback(cleverapps.Social.CODE_SUCCEED, purchase);
    }).catch(function (e) {
        console.log("error", e);
        callback(cleverapps.Social.CODE_FAILED);
    });
};

cleverapps.YandexPayments.prototype.consumePurchase = function (purchase) {
    var data = {
        signature: purchase.signature,
        purchaseToken: purchase.purchaseToken,
        restore: purchase.restore
    };

    cleverapps.RestClient.post("/yandexpayments/addPayment", data);

    // console.log("consume start", purchase);

    this.yaPayments.consumePurchase(purchase.purchaseToken).then(function () {
        console.log("consume success", purchase);
    }).catch(function (e) {
        console.log("consume error", e);
    });
    
    this.setConsumed(purchase.purchaseToken);
};

cleverapps.YandexPayments.prototype._restore = function () {
    if (!cleverapps.social.isLoggedIn() || !this.yaPayments || this.restoreRunning || this.restoreProduct) {
        return;
    }

    this.restoreRunning = true;

    this.yaPayments.getPurchases().then(function (purchases) {
        this.restoreRunning = false;

        console.log("getPurchases", purchases);

        for (var index = 0; index < purchases.length && !this.restoreProduct; index++) {
            var purchase = purchases[index];
            var data = JSON.parse(purchase.developerPayload);
            var ourProduct = Product.Create(data.itemId);

            if (this.isConsumed(purchase.purchaseToken)) {
                this.consumePurchase(purchase);
            } else if (ourProduct) {
                this.restoreProduct = ourProduct;
                this.restorePurchase = purchase;
                this.restorePurchase.paymentId = purchase.purchaseToken;
                this.restorePurchase.signature = purchases.signature;
                this.restorePurchase.restore = true;
            }
        }
    }.bind(this)).catch(function (e) {
        this.restoreRunning = false;
        console.log("error getPurchases", e);
    }.bind(this));
};

cleverapps.YandexPayments.prototype.validate = function (purchase, onSuccess) {
    var data = {
        signature: purchase.signature,
        purchaseToken: purchase.purchaseToken
    };

    cleverapps.RestClient.post("/yandexpayments/validate", data, function (res) {
        if (res === "ok") {
            onSuccess && onSuccess();
        }
    });
};

cleverapps.YandexPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.YandexPayments.isAppropriate = function () {
    return cleverapps.platform instanceof Yandex;
};
