/**
 * Created by mac on 5/23/17.
 */

var levels = {};

levels.reset = function () {
    cleverapps.episodes.reset();
    if (cleverapps.hose) {
        cleverapps.hose.reset();
        cleverapps.hose.setCurrentEpisode(levels.user.episode);
    }

    if (levels.tutorial) {
        levels.tutorial.stop();
    }

    if (cleverapps.social.isLoggedIn()) {
        cleverapps.SocialManager.onLogin();
    } else {
        cleverapps.synchronizer.syncWhenReady();
    }
};

levels.initUser = function () {
    var isNewUser = cleverapps.DataLoader.haveSavedData() === false;

    if (isNewUser) {
        console.log("Creating new user");
        cleverapps.synchronizer.reset();
    }

    cleverapps.user = levels.user = new levels.User(isNewUser);

    return isNewUser;
};

levels.initialize = function () {
    cleverapps.rewardedAdsManager.init();
    cleverapps.LocalizationManager.run();
    cleverapps.localPushes.init();

    if (cleverapps.hose) {
        cleverapps.hose.setCurrentEpisode(cleverapps.user.episode);
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.APP_INITIALIZE);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.RENDER_TYPE + (cc._renderType === cc.game.RENDER_TYPE_CANVAS ? "canvas" : "webgl"));

    if (cleverapps.DataLoader.checksumMatch !== undefined) {
        cleverapps.eventLogger.logEvent(cleverapps.DataLoader.checksumMatch ? cleverapps.EVENTS.DEBUG.CLEARANCE.DATALOADER_CHECKSUM_MATCH : cleverapps.EVENTS.DEBUG.CLEARANCE.DATALOADER_CHECKSUM_DIFFERENT);
    }

    cleverapps.Shaders.initialize();

    cleverapps.wideMode.refresh();

    if (cleverapps.config.debugMode) {
        cleverapps.administrator = new Administrator();

        cleverapps.taskManager = new TaskManager();

        cleverapps.toolModel = new ToolModel();
        cleverapps.shortcuts = new Shortcuts();
        cleverapps.sceneDebugger = new SceneDebugger();
        cleverapps.drawCallsDebugger = new DrawCallsDebugger();
        cleverapps.debugSlider = new DebugSlider();
        cleverapps.debugStats = new DebugStats();
    }

    cleverapps.flags.update();

    if (cleverapps.social instanceof cleverapps.VKSocial) {
        cleverapps.vkPromo = new VkPromo();
    }

    // NOTE! should be last!
    cleverapps.placements = new Placements();

    cc.renderer.assignedZStep = 1 / 100000;
};
