/**
 * Created by andrey on 17.10.19.
 */

cleverapps.AndroidWebViewFacebook = function () {
    console.log("Choosing android-facebook (webview)");

    cleverapps.MobileFacebook.call(this);
};

cleverapps.AndroidWebViewFacebook.prototype = Object.create(cleverapps.MobileFacebook.prototype);
cleverapps.AndroidWebViewFacebook.prototype.constructor = cleverapps.AndroidWebViewFacebook;

cleverapps.AndroidWebViewFacebook.prototype.shareDialog = function (data, callback) {
    this.listeners.onShared = callback;
    FacebookPlugin.dialog(JSON.stringify(this.getShareDialogData(data)));
};

cleverapps.AndroidWebViewFacebook.prototype.request = function (options, callback) {
    // if (callback) {
    //     callback(cleverapps.Social.CODE_SUCCEED);
    // }

    this.listeners.onRequest = callback;

    FacebookPlugin.inviteFriendsWithInviteIds(options.to ? JSON.stringify(options.to) : null, options.title, options.message);
};
cleverapps.AndroidWebViewFacebook.prototype.onRequest = function (code) {
    if (this.listeners.onRequest) {
        this.listeners.onRequest(code);
        delete this.listeners.onRequest;
    }
};

cleverapps.AndroidWebViewFacebook.prototype.addApiJob = function (apiMethod, httpMethod, params, callback) {
    cleverapps.MobileFacebook.prototype.addApiJob.call(this, apiMethod, httpMethod, JSON.stringify(params), callback);
};