/**
 * Created by Andrey Popov on 5/5/21
 */

var XsollaPayments = function () {
    cleverapps.RestPayments.call(this);

    this.sandbox = Boolean(cleverapps.config.debugMode);

    for (var itemId in cleverapps.config.products) {
        var product = cleverapps.config.products[itemId];
        product.loadedId = itemId;
    }
};

XsollaPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
XsollaPayments.prototype.constructor = XsollaPayments;

XsollaPayments.prototype.init = function (callback) {
    cleverapps.loadSdk("https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js", {
        onSuccess: callback
    });
};

XsollaPayments.prototype.onPurchaseResult = function () {
    this.stopStatusChecker();

    cleverapps.RestPayments.prototype.onPurchaseResult.apply(this, arguments);
};

XsollaPayments.prototype._purchase = function (product) {
    this.getAuthToken(function (auth) {
        this.getPaymentToken(auth, product, function (order) {
            this.openWidget(product, order);
        }.bind(this));
    }.bind(this));
};

XsollaPayments.prototype.getPaymentToken = function (auth, product, callback) {
    if (XsollaPayments.DEBUG_IDS.includes(cleverapps.user.id) && product.price < 10) {
        product.loadedId = product.itemId = "testProduct";
    }

    // https://developers.xsolla.com/ru/doc/pay-station/how-to/how-to-get-payment-token/
    cleverapps.RestClient.post("https://store.xsolla.com/api/v2/project/" + auth.project + "/payment/item/" + product.itemId, {
        quantity: 1,
        sandbox: this.sandbox
    }, function (response) {
        console.log("payment token", response);

        if (!response || !response.token) {
            this.onPurchaseResult(cleverapps.Social.CODE_FAILED, "empty payment token");
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.XSOLLA.INIT_WIDGET_FAILED);
            return;
        }

        callback(response.token);
    }, function (error) {
        this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, error);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.XSOLLA.INIT_WIDGET_FAILED);
        console.log("Failed to create Xsolla order: " + error);
    }.bind(this), {
        authorization: "Bearer " + auth.token
    });
};

XsollaPayments.prototype.getAuthToken = function (callback) {
    var data = {
        userid: cleverapps.user.id,
        name: cleverapps.info.getValue("name")
    };

    var auth = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.XSOLLA2_PAYMENTS_AUTH);

    if (auth && JSON.stringify(auth.data) === JSON.stringify(data)
        && auth.created + XsollaPayments.AUTH_TOKEN_LIFETIME > Date.now() && auth.created < Date.now()) {
        console.log("auth cache", auth);

        callback(auth);
        return;
    }

    cleverapps.RestClient.post(
        "/xsollapayments/auth/", 
        data, 
        function (response) {
            console.log("auth response", JSON.stringify(response.error || response.auth || response));

            if (response.error || !response.auth) {
                this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, response.error);
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.XSOLLA.INIT_WIDGET_FAILED);
                return;
            }

            var auth = response.auth;
            auth.data = data;
            auth.created = Date.now();

            cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.XSOLLA2_PAYMENTS_AUTH, auth);

            callback(auth);
        }.bind(this),
        function (error) {
            this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, error);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.XSOLLA.INIT_WIDGET_FAILED);
        }.bind(this)
    );
};

XsollaPayments.prototype.openWidget = function (product, paymentToken) {
    var result = false;

    XPayStationWidget.init({
        lightbox: {
            spinner: "round",
            width: null,
            height: null
        },
        sandbox: this.sandbox,
        access_token: paymentToken
    });

    var onOpen = cleverapps.once(function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.XSOLLA.OPEN_WIDGET);
    });
    XPayStationWidget.on(XPayStationWidget.eventTypes.OPEN, onOpen);

    XPayStationWidget.open();

    XPayStationWidget.on(XPayStationWidget.eventTypes.STATUS, function (event, data) {
        var paymentInfo = data && data.paymentInfo;
        if (!paymentInfo) {
            return;
        }

        if (cleverapps.config.debugMode) {
            console.log("Xsolla payment event: " + JSON.stringify(paymentInfo));
        }

        var paymentStatus = paymentInfo.status;
        var paymentId = paymentInfo.invoice;

        if (["invoice", "done", "delivering"].includes(paymentStatus) && paymentId) {
            product.paymentId = product.paymentId || paymentId;
        }

        if (paymentStatus === "done") {
            result = true;
        } else if (paymentStatus === "troubled") {
            this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, product);
        }
    }.bind(this));

    var onClose = cleverapps.once(function () {
        if (!result) {
            this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED);
        }

        XPayStationWidget.off(XPayStationWidget.eventTypes.OPEN);
        XPayStationWidget.off(XPayStationWidget.eventTypes.CLOSE);
        XPayStationWidget.off(XPayStationWidget.eventTypes.STATUS);

        this.onWidgetClosed();
    }.bind(this));
    XPayStationWidget.on(XPayStationWidget.eventTypes.CLOSE, onClose);
};

XsollaPayments.prototype.onWidgetClosed = function () {
    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !this.purchaseHandler || !this.purchaseProduct) {
        return;
    }

    if (this.purchaseProduct) {
        this.startStatusChecker();
    }
};

XsollaPayments.prototype.stopStatusChecker = function () {
    if (this.statusChecker) {
        this.statusChecker.stop();
        delete this.statusChecker;
    }
};

XsollaPayments.prototype.startStatusChecker = function () {
    this.stopStatusChecker();

    var purchase = this.purchaseProduct;

    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !purchase || !purchase.paymentId) {
        return;
    }

    var running;

    var check = function () {
        if (running) {
            return;
        }
        running = true;
        var onFinish = cleverapps.waitNoMore(10000, cleverapps.once(function () {
            running = false;
        }));

        console.log("statusCheck: " + purchase.paymentId);
        cleverapps.RestClient.get("/xsollapayments/get/" + purchase.paymentId, {}, function (response) {
            onFinish();

            if (response.error || !response.payment) {
                console.log(response.error || "no payment");
                return;
            }

            var payment = response.payment;

            console.log("statusCheck success: " + JSON.stringify(payment));

            if (["paid", "done"].includes(payment.status)) {
                purchase.loadedPrice = payment.textPrice || purchase.loadedPrice;
                this.onPurchaseResult(cleverapps.Social.CODE_SUCCEED, purchase);
            }
        }.bind(this));
    }.bind(this);

    this.statusChecker = this.startChecker({
        check: check
    });
};

XsollaPayments.prototype.isAvailable = function () {
    return true;
};

XsollaPayments.isAppropriate = function () {
    return cleverapps.platform.oneOf(MSStart, CleverApps);
};

XsollaPayments.AUTH_TOKEN_LIFETIME = cleverapps.parseInterval("1 hour");

if (cleverapps.config.debugMode) {
    XsollaPayments.AUTH_TOKEN_LIFETIME = cleverapps.parseInterval("1 second");
}

XsollaPayments.DEBUG_IDS = [
    "e913873c-3848-4155-a881-edb315e30692", // andrey k tripeaks,
    "6e83dcbf-29fd-40f9-abed-83cf80d6194a", // andrey k mergecraft
    "54de395d-d6e0-4f83-8ed9-7b68e4de19e0" // andrey k riddles
];
