/**
 * Created by Andrey Popov on 10.11.2021
 */

var TextureImage = function (atlasAnalyzer) {
    this.atlasAnalyzer = atlasAnalyzer;

    cleverapps.EventEmitter.call(this);

    this.textureMap = undefined;
    this.texturePng = undefined;
    this.selectedTextureFrame = undefined;

    this.atlasAnalyzer.bundlesExplorer.on("selectionChanged", this.showTexture.bind(this));
};

TextureImage.prototype = Object.create(cleverapps.EventEmitter.prototype);
TextureImage.prototype.constructor = TextureImage;

TextureImage.prototype.showTexture = function (tab, selectedItem, frameToSelect) {
    this.trigger("showLoading");

    this.textureMap = undefined;
    this.texturePng = undefined;

    var urls = (new Bundle(selectedItem)).listUrls();

    var resourceBundles = [];
    if (selectedItem === "episodes_resources") {
        var episodeId = 0;
        var bundle = "episode_" + episodeId + "_island";
        while (bundles[bundle]) {
            resourceBundles.push(bundle);

            urls.push(bundles[bundle].urls.json);

            episodeId++;
            bundle = "episode_" + episodeId + "_island";
        }
    }

    cc.loader.load(urls, function (status) {
        if (status) {
            console.error("load failed: ", selectedItem);
            return;
        }

        this.texturePng = bundles[selectedItem].urls.png;

        this.trigger("showTexture", this.texturePng);

        if (!bundles[selectedItem].urls.json) {
            return;
        }

        var textureJsons = cc.loader.cache[bundles[selectedItem].urls.json];

        if (textureJsons.plist) {
            this.textureMap = this.parsePlist(selectedItem, textureJsons.plist);
        }

        if (textureJsons.atlas) {
            var atlas = this.parseAtlas(selectedItem, textureJsons.atlas);
            if (this.textureMap) {
                var existingFrames = new Set(this.textureMap.frames.map(function (frame) {
                    return frame.name.replace(".png", "");
                }));
                atlas.frames.forEach(function (frame) {
                    if (!existingFrames.has(frame.name.replace(".png", ""))) {
                        this.textureMap.frames.push(frame);
                    }
                }.bind(this));
            } else {
                this.textureMap = atlas;
            }
        }

        if (!this.textureMap) {
            console.error("missing description for ", selectedItem);
            return;
        }

        var textureInfo = textureJsons.texture_info_debug_json && JSON.parse(textureJsons.texture_info_debug_json);
        this.textureMap.texture.byteSize = textureInfo && textureInfo.byteSize || "";

        if (resourceBundles.length) {
            textureJsons = cleverapps.clone(textureJsons, true);
            resourceBundles.forEach(function (bundle) {
                var jsons = cleverapps.clone(cc.loader.cache[bundles[bundle].urls.json], true);
                Object.keys(jsons).forEach(function (jsonName) {
                    jsons[jsonName].resourceBundle = bundle;
                });

                Object.assign(textureJsons, jsons);
            });
        }

        var spineImages = this.getSpineImagesMap(selectedItem, textureJsons);
        this.textureMap.frames.forEach(function (frame) {
            if (spineImages[frame.name.replace(".png", "")]) {
                spineImages[frame.name.replace(".png", "")].forEach(function (jsonName) {
                    this.calculateFrameLinks(jsonName, frame);
                }.bind(this));
            } else {
                this.calculateFrameLinks(frame.name, frame);
            }
        }.bind(this));

        if (this.textureMap.frames.length > 0) {
            var selectedFrame = this.textureMap.frames[0];
            if (frameToSelect) {
                var candidates = this.textureMap.frames.filter(function (frame) {
                    return frame.name === frameToSelect;
                });
                if (candidates.length > 0) {
                    selectedFrame = candidates[0];
                }
            }

            this.trigger("selectTextureFrame", selectedFrame, true);
        }
    }.bind(this));
};

TextureImage.prototype.parseAtlas = function (name, atlas) {
    var lines = atlas.split("\n").filter(function (line) {
        return line.length > 0;
    });

    var size = lines[1].replace("size: ", "").split(",");
    var map = {
        texture: {
            name: name,
            fileName: lines[0],
            size: {
                width: parseInt(size[0]),
                height: parseInt(size[1])
            }
        },
        frames: []
    };

    for (var i = 5; i < lines.length; i += 7) {
        map.frames.push({
            name: lines[i],
            position: {
                x: parseInt(lines[i + 2].split("xy: ")[1].split(",")[0]),
                y: parseInt(size[1]) - parseInt(lines[i + 2].split(", ")[1])
            },
            size: {
                width: parseInt(lines[i + 3].split("size: ")[1].split(",")[0]),
                height: parseInt(lines[i + 3].split(", ")[1])
            }
        });
    }

    return map;
};

TextureImage.prototype.parsePlist = function (name, plist) {
    var parsedPList = cc.plistParser.parse(plist);

    var size = parsedPList.metadata.size.replace("{", "").replace("}", "").split(",");
    var map = {
        texture: {
            name: name,
            fileName: parsedPList.metadata.textureFileName,
            size: {
                width: parseInt(size[0]),
                height: parseInt(size[1])
            }
        },
        frames: []
    };

    Object.keys(parsedPList.frames).forEach(function (frameName) {
        var textureRect = parsedPList.frames[frameName].textureRect;
        var rectData = textureRect.substring(1, textureRect.length - 1).split(",");

        map.frames.push({
            name: frameName,
            position: {
                x: parseInt(rectData[0].substring(1)),
                y: parseInt(size[1]) - parseInt(rectData[1].substring(0, rectData[1].length - 1))
            },
            size: {
                width: parseInt(rectData[2].substring(1)),
                height: parseInt(rectData[3].substring(0, rectData[3].length - 1))
            }
        });
    });

    return map;
};

TextureImage.prototype.getSpineImagesMap = function (selectedItem, textureJsons) {
    var spineImagesMap = {};

    Object.keys(textureJsons).forEach(function (jsonName) {
        if (["atlas", "plist"].indexOf(jsonName) !== -1 || !textureJsons[jsonName].slots) {
            return;
        }

        var addToMap = function (key) {
            var bundle = textureJsons[jsonName].resourceBundle || selectedItem;
            if (spineImagesMap[key]) {
                spineImagesMap[key].push(bundles[bundle].jsons[jsonName]);
            } else {
                spineImagesMap[key] = [bundles[bundle].jsons[jsonName]];
            }
        };

        var skins = textureJsons[jsonName].skins;
        Object.keys(skins).forEach(function (skinName) {
            Object.keys(skins[skinName]).forEach(function (nodeName) {
                Object.keys(skins[skinName][nodeName]).forEach(function (skinNodeProperty) {
                    var skinNodeData = skins[skinName][nodeName][skinNodeProperty];
                    var attachedImg = skinNodeData.path || skinNodeData.name;
                    if (!attachedImg) {
                        attachedImg = skinNodeProperty;
                    }
                    addToMap(attachedImg);
                });
            });
        });

        var animations = textureJsons[jsonName].animations;
        Object.keys(animations).forEach(function (animationName) {
            Object.keys(animations[animationName]).forEach(function (nodeName) {
                Object.keys(animations[animationName][nodeName]).forEach(function (skinNodeProperty) {
                    var nodeProperty = animations[animationName][nodeName][skinNodeProperty];
                    if (nodeProperty && nodeProperty.attachment) {
                        nodeProperty.attachment.forEach(function (attachmentItem) {
                            if (attachmentItem.attachment || attachmentItem.name) {
                                addToMap(attachmentItem.attachment || attachmentItem.name);
                            }
                        });
                    }
                });
            });
        });
    });

    return spineImagesMap;
};

TextureImage.prototype.calculateFrameLinks = function (frameName, frame) {
    var unpacked = undefined;
    var json = processVirtualJson(frameName);
    if (json) {
        unpacked = unpackedMap[json];
        if (unpacked) {
            unpacked = unpacked.substring(0, unpacked.lastIndexOf("/")) + "/images/" + frame.name.replace(".png", "") + ".png";
        }
    }

    var sprite = processVirtualImage(frameName);
    if (sprite && !unpacked) {
        if (sprite[0] !== "#") {
            sprite = "#" + sprite;
        }

        unpacked = unpackedMap[sprite] || unpackedMap[sprite.replace(".png", ".jpg")];
    }

    if (unpacked) {
        var repoInd = unpacked.indexOf("/");
        var repo = unpacked.substring(0, unpacked.indexOf("/"));
        var linkWithoutRepo = unpacked.substring(repoInd);
        frame.link = unpacked;
        frame.gitLink = "https://github.com/rvvslv/" + repo + "/tree/master/" + linkWithoutRepo;
    }
};

TextureImage.prototype.handleTextureClick = function (x, y) {
    for (var i = 0; i < this.textureMap.frames.length; i++) {
        var frame = this.textureMap.frames[i];

        if (x >= frame.position.x && x <= frame.position.x + frame.size.width
            && y >= frame.position.y - frame.size.height && y <= frame.position.y) {
            this.selectFrame(frame);
            break;
        }
    }
};

TextureImage.prototype.selectFrame = function (frame, animate) {
    this.selectedTextureFrame = frame;
    this.trigger("selectTextureFrame", frame, animate);

    cleverapps.setUrlHash({
        atlasAnalyzer: true,
        tab: this.atlasAnalyzer.bundlesExplorer.selectedTab,
        bundleName: this.atlasAnalyzer.bundlesExplorer.selectedItem,
        frame: frame.name
    });
};

TextureImage.prototype.clear = function () {
    this.trigger("clear");

    delete this.textureMap;
    delete this.texturePng;
    delete this.selectedTextureFrame;
};
