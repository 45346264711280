/**
 * Created by Aleksandr on 01.02.2023
 */

var Kongregate = function () {
    Platform.call(this, "kongregate");
};

Kongregate.prototype = Object.create(Platform.prototype);
Kongregate.prototype.constructor = Kongregate;

Kongregate.prototype.init = function (onSuccess) {
    onSuccess();
};

Kongregate.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};

Kongregate.prototype.getLocalStoragePreffix = function () {
    return "_kongregate";
};

Kongregate.prototype.isFullscreenAvailable = function () {
    return false;
};
