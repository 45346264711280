/**
 * Created by slava on 4/21/17.
 */

cleverapps.OKPayments = function () {
    cleverapps.RestPayments.call(this);

    for (var itemId in cleverapps.config.products) {
        var product = cleverapps.config.products[itemId];

        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedId = product.id.ok || itemId;
    }
};

cleverapps.OKPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
cleverapps.OKPayments.prototype.constructor = cleverapps.OKPayments;

cleverapps.OKPayments.prototype.calcLoadedPrice = function (price) {
    var loadedPrice = Math.floor(price * cleverapps.RUB_DISCOUNTED_RATE) + "**";
    
    if (cleverapps.config.debugMode) {
        loadedPrice = Math.max(1, Math.round(price / 3)) + "**";
    }

    return loadedPrice;
};

cleverapps.OKPayments.prototype._purchaseOpen = function (product, callback) {
    if (FAPI === cleverapps.FakeFAPI) {
        callback(cleverapps.Social.CODE_CANCELLED);
        return;
    }

    var listener = function (result) {
        if (result === cleverapps.OKSocial.CODE_SUCCEED) {
            callback(cleverapps.Social.CODE_SUCCEED, product);
        } else {
            callback(result === "cancel" ? cleverapps.Social.CODE_CANCELLED : cleverapps.Social.CODE_FAILED);
        }
    };

    cleverapps.platform.exitFullscreen(function () {
        if (product.type === "subscription") {
            cleverapps.platform.listeners.showPaymentSubscription = listener;
            FAPI.invokeUIMethod("showPaymentSubscription", product.id.ok, parseInt(product.loadedPrice));
        } else {
            cleverapps.platform.listeners.showPayment = listener;
            FAPI.UI.showPayment(
                product.description, 
                product.title, 
                product.itemId, 
                parseInt(product.loadedPrice), 
                null,
                JSON.stringify({ type: cleverapps.platform.oneOf(MobileOK) ? "mobile" : "social" }), 
                "ok", 
                "true"
            );
        }
    });
};

cleverapps.OKPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.OKPayments.isAppropriate = function () {
    return cleverapps.platform.oneOf(OKPlatform, MobileOK);
};
