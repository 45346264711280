/**
 * Created by mac on 7/26/18
 */

var WardrobeWindow = Window.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.wardrobeBundle = cleverapps.skins.getSlot("wardrobeBundle");

        var styles = cleverapps.styles.WardrobeWindow;

        var onGame = cleverapps.environment.isGameScene();

        this._super({
            name: "wardrobewindow",
            content: this.generateContent(),
            styles: styles.Window,
            help: function () {
                this.close();
                new WardrobeRulesWindow(mission);
            }.bind(this),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                type: cleverapps.styles.UI.Button.Images.button_red,
                text: onGame ? "OK" : "Play",
                onPressed: function () {
                    this.close();
                    cleverapps.meta.distract({
                        focus: "WardrobePlayButtonPressed",
                        action: function (f) {
                            cleverapps.meta.wantsToPlay(f);
                        }
                    });
                }.bind(this)
            }
        });
    },

    generateUpperBanner: function (content) {
        var styles = cleverapps.styles.WardrobeWindow.banner;
        this.banner = new cc.Sprite(cleverapps.skins.getSlot("wardrobeWindowBanner"));
        var bannerLayout = new ccui.Layout();
        bannerLayout.setAnchorPoint2();
        bannerLayout.setPositionRound(styles.position.x + content.width / 2, content.height + styles.position.y);
        bannerLayout.setContentSize2(this.banner.width, this.banner.height * 2);
        bannerLayout.setClippingEnabled(true);
        content.addChild(bannerLayout);

        this.banner.setPositionRound(bannerLayout.width / 2, this.banner.height / 2);
        bannerLayout.addChild(this.banner);

        this.refreshPerson();
    },

    refreshPerson: function () {
        var styles = cleverapps.styles.WardrobeWindow.person;

        this.banner.removeAllChildren();

        var container = new cc.Node();
        container.setAnchorPoint2();
        container.setContentSize2(styles.width, styles.height);

        this.person = new Person("hero");
        if (styles.scale) {
            this.person.setScale(styles.scale);
        }
        container.addChild(this.person);
        this.person.setPositionRound(container.width / 2, container.height / 2);

        this.banner.addChild(container);
        container.setPositionRound(this.banner.width / 2 + styles.position.x, this.banner.height / 2 + styles.position.y);
    },

    refreshStagesScroll: function () {
        var styles = cleverapps.styles.WardrobeWindow.stages;

        this.stages.removeAllChildren();

        var stageNodes = [];
        for (var i = 0; i < WardrobeWindow.stages.length; i++) {
            stageNodes.unshift(this.generateStageContent(i));
        }
        var stagesList = new cleverapps.Layout(stageNodes, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        this.stagesScroll = new cleverapps.UI.ScrollView(stagesList, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_LINEAR
        });
        this.stagesScroll.setContentSize2(styles.width, styles.height - styles.padding * 2);
        this.stagesScroll.setPositionRound(this.stages.width / 2, this.stages.height / 2);
        this.stages.addChild(this.stagesScroll);

        var currentStage = this.mission.logic.getCurrentStage().stage;
        if (currentStage >= stageNodes.length) {
            currentStage = stageNodes.length - 1;
        }

        this.needScroll = stageNodes[currentStage];
    },

    addTimer: function () {
        var leftTime = this.mission.finishTime() - Date.now();
        if (leftTime <= 0) {
            return;
        }

        var timer = new cleverapps.CountDown(leftTime, {
            onFinish: this.close.bind(this)
        });
        var styles = cleverapps.styles.WardrobeWindow.timer;

        var timerViewParams = {
            font: styles.font,
            timerPosition: { x: 0, y: styles.offsetY }
        };

        if (styles.bg) {
            var background = cleverapps.UI.createScale9Sprite(styles.bg.image);
            background.setContentSize2(styles.bg.width, styles.bg.height);
            timerViewParams.background_content = background;
        }

        var textTimer = new cleverapps.CountDownView(timer, timerViewParams);
        this.banner.addChild(textTimer);

        textTimer.setPositionRound(styles.position);
    },

    generateContent: function () {
        var styles = cleverapps.styles.WardrobeWindow;

        var content = new cc.Node();
        content.setContentSize2(styles.width, styles.height);
        content.setAnchorPoint2();

        this.generateUpperBanner(content);

        this.stages = new cc.Scale9Sprite(styles.stages.bg);
        this.stages.setContentSize2(styles.stages.width, styles.stages.height);

        var text = cleverapps.UI.generateOnlyText("WardrobeDescText", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(content.width, 0);
        text.fitTo(undefined, styles.text.height);

        var stagesContent = new cleverapps.Layout([this.stages, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.content.margin
        });

        stagesContent.setPositionRound(content.width / 2 + styles.content.position.x, content.height / 2 + styles.content.position.y);

        content.addChild(stagesContent);
        if (styles.zOrder) {
            content.setLocalZOrder(styles.zOrder);
        }

        this.refreshStagesScroll();
        this.addTimer();

        return content;
    },

    scrollToNeedStage: function (delay) {
        if (this.needScroll !== false) {
            var currentStage = this.mission.logic.getCurrentStage().stage;
            if (currentStage < WardrobeWindow.stages.length - 1) {
                this.stagesScroll.runAction(new cc.Sequence(
                    new cc.DelayTime(delay),
                    new cc.CallFunc(function () {
                        this.stagesScroll.scrollTo(this.needScroll, (WardrobeWindow.stages.length - 1 - currentStage) * 0.5);
                        this.needScroll = false;
                    }.bind(this))
                ));
            }
        }
    },

    onShow: function () {
        this._super();
        this.scrollToNeedStage(0.2);
    },

    buyDecoration: function (stage, price, amount) {
        if (levels.user.spendHard(cleverapps.EVENTS.SPENT.WARDROBE, price)) {
            this.close();
            this.mission.update(amount);

            this.mission.logic.stageToShow = stage + 1;
        }
    },

    generateStageContent: function (stage) {
        var styles = cleverapps.styles.WardrobeWindow.stages.stage;
        var currentStage = this.mission.logic.getCurrentStage();
        var progress = 0, passed = false, current = false;
        if (currentStage.stage > stage) {
            progress = WardrobeWindow.stages[stage];
            passed = true;
        } else if (currentStage.stage === stage) {
            progress = currentStage.progress;
            current = true;
        }

        var node = new cc.Scale9Sprite(styles.bg);
        node.setContentSize2(styles.width, styles.height);
        node.setAnchorPoint2();

        var content = new cc.Node();
        content.setAnchorPoint2();
        content.setContentSize2(node.getContentSize());
        content.setPositionRound(node.width / 2, node.height / 2);
        content.setCascadeOpacityEnabled(true);
        node.addChild(content);

        var icon = new cc.Sprite(this.wardrobeBundle.frames["stage" + (stage + 1) + "_icon"]);
        icon.setPositionRound(styles.icon.position.x, styles.icon.position.y + content.height / 2);
        icon.setScale(styles.icon.scale);
        content.addChild(icon);
        if (passed && styles.icon.passedMark) {
            var mark = new cc.Sprite(styles.icon.passedMark.icon);
            mark.setPositionRound(icon.x + styles.icon.passedMark.position.x, icon.y + styles.icon.passedMark.position.y);
            mark.setScale(styles.icon.passedMark.scale);
            content.addChild(mark);
        }

        if (!current) {
            var text = cleverapps.UI.generateOnlyText(passed ? "WardrobeCompleteText" : "WardrobeStageTaskText", cleverapps.styles.FONTS.WINDOW_TEXT, { amount: WardrobeWindow.stages[stage] });
            text.fitTo(styles.text.width);
            text.setPositionRound(styles.text.position.x, styles.text.position.y + content.height / 2);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            content.addChild(text);
        }

        if (current) {
            var progressBar = new ScaledProgressBar({
                background: bundles.progress_bar.frames.bg_wardrobe,
                progress: bundles.progress_bar.frames.bar_wardrobe
            });
            progressBar.setLength(styles.progress.width);
            progressBar.setPercentage(progress / WardrobeWindow.stages[stage] * 100);

            var currentText = cleverapps.UI.generateOnlyText("WardrobeCurrentText", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            currentText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            currentText.fitTo(progressBar.width);

            var currentProgressText = cleverapps.UI.generateImageText(progress + "/" + WardrobeWindow.stages[stage], cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            currentProgressText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

            var currentDesc = new cleverapps.Layout([currentText, progressBar, currentProgressText], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.currentDesc.margin
            });
            currentDesc.setPositionRound(styles.currentDesc.position.x, styles.currentDesc.position.y + content.height / 2);
            content.addChild(currentDesc);

            var buyButton = new cleverapps.UI.Button({
                text: "$$" + WardrobeWindow.prices[stage],
                width: styles.button.width,
                height: styles.button.height,
                onClicked: function () {
                    this.buyDecoration(stage, WardrobeWindow.prices[stage], WardrobeWindow.stages[stage] - progress);
                }.bind(this),
                type: cleverapps.styles.UI.Button.Images.small_button_green
            });

            var buyText = cleverapps.UI.generateOnlyText("WardrobeBuyText", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            buyText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            buyText.setDimensions(styles.buttonContainer.width, 0);
            buyText.fitTo(undefined, styles.buttonContainer.height);

            var buttonContainer = new cleverapps.Layout([buyButton, buyText], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.buttonContainer.margin
            });

            buttonContainer.setPositionRound(styles.buttonContainer.position.x, styles.buttonContainer.position.y + content.height / 2);

            content.addChild(buttonContainer);
        }

        var prizeStyles = WardrobeWindow.prizes[stage + 1];
        if (prizeStyles && !passed) {
            var presentNode = new cc.Node();
            presentNode.setAnchorPoint2();
            presentNode.setPositionRound(styles.present.x, styles.present.y);
            presentNode.setContentSize2(styles.present.width, styles.present.height);

            var present = new cleverapps.Spine(styles.present.animation.json, styles.present.scale);
            present.setAnimation(0, styles.present.animation.animation, true);
            if (styles.present.animation.skin) {
                present.setSkin(styles.present.animation.skin);
            }
            if (prizeStyles.skin) {
                present.setSkin(prizeStyles.skin);
            }
            presentNode.addChild(present);
            present.setPositionRound(presentNode.width / 2, presentNode.height / 2);

            cleverapps.tooltipManager.create(presentNode, {
                rewards: prizeStyles.prize,
                text: "WardrobePrizeRewardTitle",
                position: cleverapps.styles.UI.Tooltip.LOCATION.above
            });

            content.addChild(presentNode, styles.present.zOrder ? styles.present.zOrder : -1);
        }

        if (!passed && !current) {
            text.setOpacity(80);
        }

        return node;
    }
});

WardrobeWindow.prototype.listBundles = function () {
    return ["wardrobe_window"];
};

WardrobeWindow.stages = [1, 2, 3, 4, 5, 5, 5, 5, 5, 5, 5, 5];
WardrobeWindow.prices = [100, 150, 300, 350, 650, 1000, 1250, 1500, 2000, 2500, 3000, 3500];
WardrobeWindow.prizes = {
    4: {
        prize: {
            hard: 100
        },
        skin: "pink"
    },

    8: {
        prize: {
            hard: 300
        },
        skin: "blue"
    },

    12: {
        prize: {
            hard: 500
        },
        skin: "green"
    }
};

cleverapps.styles.WardrobeWindow = {
    zOrder: 3,
    width: 520,
    height: 800,

    timer: {
        font: cleverapps.styles.FONTS.SMALL_WHITE_TEXT,
        offsetY: -2,

        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 4 }
        },

        bg: {
            width: 180,
            height: 50,
            image: bundles.progress_bar.frames.bg_wardrobe
        }
    },

    person: {
        scale: 0.6,
        width: 200,
        height: 200,
        position: {
            x: -180,
            y: 20
        }
    },

    banner: {
        position: {
            x: 0,
            y: 166
        }
    },

    stages: {
        bg: bundles.wardrobe_window.frames.window_table_bg,
        width: 596,
        height: 506,
        padding: 14,
        margin: 14,

        stage: {
            bg: bundles.table.frames.row_player_png,
            width: 566,
            height: 150,

            present: {
                x: 134,
                y: 44,
                iconDelta: 20,
                width: 100,
                height: 100,
                scale: 0.35,
                zOrder: 1,
                animation: {
                    json: bundles.windows.jsons.present_json,
                    animation: "animation"
                }
            },

            currentDesc: {
                margin: 6,
                position: {
                    x: 270,
                    y: 0
                }
            },

            buttonContainer: {
                width: 140,
                height: 60,
                position: {
                    x: 470,
                    y: 0
                },
                margin: 2
            },

            button: {
                width: 160,
                height: 74
            },

            progress: {
                width: 200
            },

            text: {
                width: 330,
                position: {
                    x: 360,
                    y: 0
                }
            },

            icon: {
                scale: 1,
                position: {
                    x: 80,
                    y: 0
                },
                passedMark: {
                    icon: bundles.checkbox.frames.check_mark_png,
                    scale: 1,
                    position: {
                        x: 60,
                        y: -26
                    }
                }
            }
        }
    },

    content: {
        margin: 30,
        position: {
            x: 0,
            y: -40
        }
    },

    text: {
        height: 180
    },

    button: {
        width: 250,
        height: 100
    },

    Window: {
        HelpButton: {
            x: { align: "left", dx: 64 },
            y: { align: "bottom", dy: 64 }
        }
    }
};
