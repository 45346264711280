/**
 * Created by vladislav on 9/6/2022
 */

var Draugiem = function () {
    Platform.call(this, "draugiem");
};

Draugiem.prototype = Object.create(Platform.prototype);
Draugiem.prototype.constructor = Draugiem;

Draugiem.prototype.getLocalStoragePreffix = function () {
    return "_draugiem";
};

Draugiem.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_LATVIAN, cc.sys.LANGUAGE_RUSSIAN];
};