/**
 * Created by slava on 5/3/19
 */

(function () {
    var guessPlatformURL = function (config, debugMode, source) {
        switch (source) {
            case "facebook":
                var data = config.facebook;
                return "https://apps.facebook.com/" + (data.shortname ? data.shortname : data.appId);
            case "android":
                return "https://play.google.com/store/apps/details?id=" + config.android.package;
            case "amazon":
                return "https://www.amazon.com/dp/" + config.amazon.id;
            case "macos":
            case "ios":
                return "https://apps.apple.com/app/id" + config.ios.id;
            case "instant":
                return "https://www.facebook.com/instantgames/play/" + config.instant.appId;
            case "web_ok":
                return "https://ok.ru/game/" + (config.ok.shortname || config.ok.appId);
            case "mobile_ok":
                return "https://m.ok.ru/game/" + (config.ok.shortname || config.ok.appId);
            case "web_vk":
                return "https://vk.com/" + (config.vk.shortname || config.vk.appId);
            case "mobile_vk":
                return "vk://vk.com/" + (config.vk.shortname || config.vk.appId);
            case "web_mm":
                return "https://my.mail.ru/apps/" + config.mm.appId;
            case "mobile_mm":
                return "https://m.my.mail.ru/apps/" + config.mm.appId;
            case "mbga":
                if (debugMode) {
                    return "https://sb.yahoo-mbga.jp/game/" + config.mbga.appId + "/play";
                }
                return "https://yahoo-mbga.jp/game/" + config.mbga.appId + "/play";
            case "sp_mbga":
                return "http://sp.mbga.jp/_pf_install?game_id=" + config.mbga.appId;
            case "draugiem":
                return "https://www.draugiem.lv/" + (parseInt(config.draugiem.appId) ? "applications/" : "") + config.draugiem.appId;
            case "fotostrana":
                return "https://fotostrana.ru/app/" + config.fotostrana.appId;
            case "yandex":
                return "https://yandex.ru/games/play/" + config.yandex.appId;
            case "gdcom":
                return "https://revision.gamedistribution.com/" + config.gdcom.appId;
            case "plng":
                return "https://revision.gamedistribution.com/" + config.plng.appId;
            case "msstart":
                return "https://www.msn.com/en-us/play/" + (config.name.replace(" ", "-") + "/cg-" + config.msstart.appId).toLowerCase();
            case "xsolla":
                return config.deployment + (debugMode ? "/publish/html5-staging/" : "/publish/html5/");
        }
    };

    if (typeof exports !== "undefined") {
        module.exports = guessPlatformURL;
    } else {
        Platform.guessPlatformURL = guessPlatformURL;
    }
}());
