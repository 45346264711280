/**
 * Created by vladislav on 9/6/2022
 */

var Instant = function () {
    Platform.call(this, "instant");
};

Instant.prototype = Object.create(Platform.prototype);
Instant.prototype.constructor = Instant;

Instant.prototype.init = function (callback) {
    var onInitialized = function () {
        this.preparePlatform();

        console.log("Request entrypoint");
        FBInstant.getEntryPointAsync().then(function (entrypoint) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.ENTRY_POINT + (entrypoint || "empty"));

            if (cleverapps.config.debugMode) {
                console.log("Instant entrypoint: " + entrypoint);
                setTimeout(function () {
                    cleverapps.notification.create("Instant entrypoint: " + entrypoint);
                }, 2000);
            }
        });

        FBInstant.player.getASIDAsync().then(function (asid) {
            console.log("Instant ASID: " + asid);

            if (asid) {
                cleverapps.info.setKeyValue("asid", asid, true);
            }
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.LOCALE + (FBInstant.getLocale() || "empty"));
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PLATFORM + (FBInstant.getPlatform() || "empty"));

        var entryPointData = FBInstant.getEntryPointData() || {};
        if (entryPointData.push_code) {
            cleverapps.localPushes.logClickEvent(entryPointData.push_code);
        }

        callback();
    }.bind(this);

    var onLoaded = function () {
        var startWaitUser = Date.now();
        var waitUserID = function () {
            if (FBInstant.player.getID() || Date.now() - startWaitUser > 30000) {
                onInitialized();
                return;
            }

            setTimeout(waitUserID, 100);
        };

        waitUserID();
    };

    cleverapps.loadSdk("//connect.facebook.net/en_US/fbinstant.7.1.js", {
        onSuccess: function () {
            FBInstant.initializeAsync().then(function () {
                LoaderScene.whenLoaded(function () {
                    FBInstant.startGameAsync().then(onLoaded);
                });
            });
        }
    });
};

Instant.prototype.preparePlatform = function () {
    var platform = FBInstant.getPlatform();

    console.log("FB platform", platform);

    switch (FBInstant.getPlatform()) {
        case "IOS":
            cc.sys.os = cc.sys.OS_IOS;
            cc.sys.isInApp = /\b(FB_IAB|FBAV|FBAN|FBDV|FBMD|FBSN|FBSV|FBSS|FBID)\b/.test(window && window.navigator && window.navigator.userAgent);
            break;
        case "IOS_IAB":
            cc.sys.os = cc.sys.OS_IOS;
            cc.sys.isInApp = true;
            break;
        case "ANDROID":
            cc.sys.os = cc.sys.OS_ANDROID;
            cc.sys.isInApp = true;
            break;
        case "WEB":
        case "MOBILE_WEB":
    }
};

Instant.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};

Instant.prototype.canCreateShortcut = function () {
    return !cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.SHORTCUT_CREATED);
};

Instant.prototype.createShortcut = function () {
    if (cleverapps.pendingRequests) {
        return;
    }

    FBInstant.canCreateShortcutAsync()
        .then(function (canCreateShortcut) {
            if (cleverapps.pendingRequests || !canCreateShortcut) {
                return;
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SHORTCUT_SHOW);

            cleverapps.countPendingRequests(FBInstant.createShortcutAsync())
                .then(function () {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.SHORTCUT);
                    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.SHORTCUT_CREATED, 1);
                })
                .catch(function () {
                    // Shortcut not created
                });
        });
};

Instant.prototype.followOfficialPage = function () {
    if (cleverapps.pendingRequests) {
        return;
    }

    FBInstant.community.canFollowOfficialPageAsync()
        .then(function (canFollow) {
            if (cleverapps.pendingRequests || !canFollow) {
                return;
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAGE_SHOW);
            cleverapps.countPendingRequests(FBInstant.community.followOfficialPageAsync())
                .then(function () {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAGE);
                })
                .catch(function (e) {
                    switch (e.code) {
                        case "USER_INPUT":
                            break;
                        case "PAGE_NOT_LINKED":
                            if (cleverapps.config.debugMode) {
                                cleverapps.notification.create("No page linked for staging");
                                break;
                            }
                        // eslint-disable-next-line no-fallthrough
                        default:
                            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAGE_ERROR);
                            cleverapps.throwAsync("followOfficialPageAsync - " + e.code + " " + e.message);
                    }
                });
        })
        .catch(function (e) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.PAGE_ERROR);
            cleverapps.throwAsync("canFollowOfficialPageAsync - " + e.code + " " + e.message);
        });
};

Instant.prototype.showOfficialPage = function () {
    this.followOfficialPage();
};

Instant.prototype.calcChannel = function () {
    cleverapps.platform.whenInitialized("calcChannel", function () {
        var data = FBInstant.getEntryPointData() || {};
        var channel = data.channel || data.fb_instant_game_campaign_id || data.fb_instant_game_adset_id || data.fb_instant_game_ad_id;
        if (channel) {
            cleverapps.user.setChannel(channel);
        }
    });
};

Instant.prototype.reportScore = function (score, callback) {
    FBInstant.postSessionScoreAsync(score).then(function () {
        this.getCurrentTournamentId(callback);
    }.bind(this)).catch(function () {
        callback();
    });
};

Instant.prototype.getCurrentTournamentId = function (callback) {
    FBInstant.getTournamentAsync().then(function (tournament) {
        var expired = new Date(tournament.getEndTime() * 1000) < new Date();
        callback(expired ? undefined : String(tournament.getID()));
    }).catch(function () {
        callback();
    });
};

Instant.FULL_AD_ID = function (id) {
    return cleverapps.config.instant.appId + "_" + id;
};

Instant.prototype.showBannerAd = function (callback) {
    callback = cleverapps.once(callback);

    setTimeout(function () {
        callback("loadBannerAdAsync timeout");
    }, Instant.AD_BANNER_TIMEOUT);

    FBInstant.loadBannerAdAsync(Instant.FULL_AD_ID(cleverapps.config.instant.banner))
        .then(function () {
            var height = Instant.AD_BANNER_HEIGHT;

            if (cc.sys.isMobile && cc.sys.isInApp) {
                height = Instant.AD_BANNER_HEIGHT_MOBILE;

                // in FB app on iPhone devices banners are raised to the height of the 'Home Indicator'
                // All iPhone devices that doesn't have 'Home button' have 'Home Indicator' (e.g. all devices since iPhone X + iPhone SE)
                if (cc.sys.os === cc.sys.OS_IOS) {
                    var viewport = Resolution.getFrameSize();
                    if (Math.max(viewport.width, viewport.height) > 800) {
                        height = Instant.AD_BANNER_HEIGHT_MOBILE + Instant.IPOHNE_HOME_INDICATOR_HEIGHT;
                    }
                }
            }

            Resolution.setContainerPadding({
                bottom: height
            });

            callback();
        })
        .catch(function (e) {
            callback(e || true);
        });
};

Instant.prototype.hideBannerAd = function (callback) {
    callback = cleverapps.once(callback);

    setTimeout(function () {
        callback("hideBannerAdAsync timeout");
    }, Instant.AD_BANNER_TIMEOUT);

    FBInstant.hideBannerAdAsync()
        .then(function () {
            Resolution.setContainerPadding();
            callback();
        })
        .catch(function (e) {
            callback(e || true);
        });
};

Instant.AD_BANNER_TIMEOUT = cleverapps.parseInterval("30 seconds");
Instant.AD_BANNER_HEIGHT = 74;
Instant.AD_BANNER_HEIGHT_MOBILE = 50;

Instant.IPOHNE_HOME_INDICATOR_HEIGHT = 34;