/**
 * Created by vladislav on 09.07.2021
 */

cleverapps.applyContainerStrategy = function (view, sceneSize) {
    var containerStyle = cc.container.style;

    var frameX = this.containerRect.x,
        frameY = this.containerRect.y,
        frameW = this.containerRect.width,
        frameH = this.containerRect.height;

    var sceneW = sceneSize.width,
        sceneH = sceneSize.height;

    var scaleX = frameW / sceneW,
        scaleY = frameH / sceneH;

    var containerW, containerH;

    if (scaleX < scaleY) {
        containerW = frameW;
        containerH = sceneH * scaleX;
    } else {
        containerW = sceneW * scaleY;
        containerH = frameH;
    }

    containerW = Math.round(containerW);
    containerH = Math.round(containerH);

    this._setupContainer(view, containerW, containerH);

    if (view._isRotated) {
        containerStyle.left = frameH + frameY + "px";
        containerStyle.top = frameX + "px";
    } else {
        containerStyle.left = frameX + "px";
        containerStyle.top = frameY + "px";
    }

    var sides = Math.round((frameW - containerW) / 2);

    containerStyle.padding = "0";
    containerStyle.paddingLeft = sides + "px";
    containerStyle.paddingRight = sides + "px";
};

var ProportionalToFrameWithBorders;
if (engine === "cocosjs") {
    ProportionalToFrameWithBorders = cc.ContainerStrategy.extend({
        ctor: function (containerRect) {
            this.containerRect = containerRect;
        },

        apply: cleverapps.applyContainerStrategy
    });
} else {
    ProportionalToFrameWithBorders = function (containerRect) {
        this.containerRect = containerRect;

        cc.ResolutionPolicy.ContainerStrategy.call(this);
    };

    ProportionalToFrameWithBorders.prototype = Object.create(cc.ResolutionPolicy.ContainerStrategy.prototype);
    ProportionalToFrameWithBorders.prototype.constructor = ProportionalToFrameWithBorders;

    ProportionalToFrameWithBorders.prototype.apply = cleverapps.applyContainerStrategy;

    ProportionalToFrameWithBorders.prototype._setupContainer = function (view, w, h) {
        if (cc.sys.os === cc.sys.OS_ANDROID) {
            document.body.style.width = (view._isRotated ? h : w) + "px";
            document.body.style.height = (view._isRotated ? w : h) + "px";
        }

        cc.game.container.style.width = cc.game.canvas.style.width = w + "px";
        cc.game.container.style.height = cc.game.canvas.style.height = h + "px";
        var devicePixelRatio = view.getDevicePixelRatio();
        cc.game.canvas.width = w * devicePixelRatio;
        cc.game.canvas.height = h * devicePixelRatio;
    };
}
