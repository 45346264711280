/**
 * Created by vladislav on 03.09.2020
 */

var AdsProgressBar = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        properties = properties || {};
        var listensEvents = properties.listensEvents || [];
        this.setAnchorPoint2();

        this.type = properties.type === AdsProgressBar.TYPES.IQ ? AdsProgressBar.TYPES.IQ : AdsProgressBar.TYPES.COIN;

        var styles = cleverapps.styles.AdsProgressBar;

        var width = properties.width || styles.bar.width;

        var bar = this.bar = new ScaledProgressBar({
            background: properties.barBg ? bundles[properties.barBg.bundle].frames[properties.barBg.image] : styles.bar_bg_png,
            progress: properties.bar ? bundles[properties.bar.bundle].frames[properties.bar.image] : styles.bar_png
        });
        bar.setAnchorPoint2(0, 0);
        bar.setLength(width);
        bar.setLocalZOrder(styles.bar.zOrder);

        this.setContentSize2(bar.getContentSize());

        this.addChild(bar, 1);
        bar.setPositionRound(styles.bar.x, styles.bar.y);

        var iconPosition = Wysiwyg.getValueForResolution(properties.iconPosition);

        if (this.type === AdsProgressBar.TYPES.IQ) {
            this.bar.setPercentage(38);

            var text = cleverapps.UI.generateOnlyText("IQ= " + this.bar.percentage * 2, properties.font ? Wysiwyg.getValueForResolution(properties.font) : cleverapps.styles.FONTS.ADS_PROGRESSBAR_FONT);
            this.addChild(text);
            text.setLocalZOrder(2);
            text.setPositionRound(iconPosition);

            if (!properties.isPreview) {
                var updateInterval = setInterval(function () {
                    if (!this.bar.blocked && this.bar.percentage > 25 && this.bar.percentage <= 98) {
                        this.bar.animatedChange(this.bar.percentage - 3);
                    }

                    if (this.bar.percentage > 98) {
                        text.setString("IQ=200");
                        var baseScale = this.getScale();
                        this.runAction(new cc.Sequence(new cc.ScaleTo(0.4, baseScale * 1.2), new cc.ScaleTo(0.3, baseScale).easing(cc.easeBackOut())));
                        clearInterval(updateInterval);
                    } else {
                        text.setString((this.bar.percentage * 2 < 100 ? "IQ= " : "IQ=") + Math.round(this.bar.percentage * 2));
                    }
                }.bind(this), 100);
            }
        } else if (properties.icon) {
            var icon = new cc.Sprite(bundles[properties.icon.bundle].frames[properties.icon.image]);
            this.addChild(icon);

            if (iconPosition) {
                icon.setScale(properties.iconScale);
                icon.setPositionRound(iconPosition);
            } else {
                icon.setScale(styles.icon.scale);
                icon.setPositionRound(styles.icon);
            }

            icon.setLocalZOrder(2);
        }

        this.setPositionRound(styles);

        this.setLocalZOrder(-1);

        if (listensEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);
        }
    },

    incProgress: function (progress) {
        if (this.type === AdsProgressBar.TYPES.IQ) {
            this.bar.blocked = true;
            var minIQ = 76, maxIQ = 200;
            this.bar.animatedChange(Math.round(progress * (maxIQ - minIQ) + minIQ) / 2, {
                callback: function () {
                    this.bar.blocked = false;
                }.bind(this)
            });
        } else {
            this.bar.animatedChange(progress * 100);
            cleverapps.audio.playSound(cleverapps.styles.AdsProgressBar.sound);
        }
    }
});

AdsProgressBar.TYPES = {
    COIN: "coin",
    IQ: "iq"
};

AdsProgressBar.BUNDLES = ["ads_bar"];

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADS_PROGRESSBAR_FONT: {
        size: 70,
        color: new cc.Color(255, 191, 69, 255),
        stroke: {
            color: new cc.Color(204, 120, 4, 255),
            size: 2
        }
    }
});

AdsProgressBar.PROPERTIES = [{
    name: "type", type: "string", items: cleverapps.values(AdsProgressBar.TYPES), bubbleUp: true
}, {
    name: "bar", type: "image", bundles: ["ads_bar"], value: { image: "gray_bar", bundle: "ads_bar" }, bubbleUp: true
}, {
    name: "barBg", type: "image", bundles: ["ads_bar"], value: { image: "bar_bg_brown", bundle: "ads_bar" }, bubbleUp: true
}, {
    name: "icon", type: "image", bundles: ["ads_bar"], value: { image: "coin", bundle: "ads_bar" }
}, {
    name: "iconScale", type: "number", value: 1
}, {
    name: "iconPosition", type: "position", value: { x: { align: "left", dx: -40 }, y: { align: "center" } }
}, {
    name: "listensEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.PROGRESS_CHANGED]
}, {
    name: "font", type: "font", value: cleverapps.styles.FONTS.ADS_PROGRESSBAR_FONT
}];

cleverapps.styles.AdsProgressBar = {
    bar: {
        x: 1,
        y: 2,
        width: 500,
        zOrder: -1
    },

    icon: {
        x: { align: "left", dx: -40 },
        y: { align: "center" },
        scale: 1.4
    }
};