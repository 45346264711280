/**
 * Created by denis on 26/12/19
 */

cleverapps.Flags = function () {
    cleverapps.EventEmitter.call(this);

    this.init();

    cleverapps.whenAllInitialized(function () {
        cleverapps.paymentsHistory.onAddPaymentListeners.flags = this.update.bind(this);
        cleverapps.payments.whenInitialized("flags", this.update.bind(this));
    }.bind(this));
};

cleverapps.Flags.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Flags.prototype.constructor = cleverapps.Flags;

cleverapps.Flags.prototype.init = function () {
    this.norest = cleverapps.platform.oneOf(Huzcom, Pliega, Playable) || cleverapps.config.norest;
    this.noLogEvents = cleverapps.platform.oneOf(Huzcom, Pliega, Rustore, Playable);
    this.noinvites = cleverapps.platform.oneOf(Huzcom, GDCom, Plinga, Pliega, Rustore, IOSCh, CleverApps, Playable, Yandex, Microsoft, MacOS, Mygames, MSStart, PWA);
    this.norequest = this.noinvites || cleverapps.platform.oneOf(AndroidPlatform, IOS, FacebookCanvas, Instant, MSStart, PWA);
    this.noshares = cleverapps.platform.oneOf(AndroidPlatform, Amazon, IOS, Pliega, Plinga, Rustore, SPMobage, IOSCh, Playable, Yandex, Microsoft, MacOS, Draugiem, CleverApps, PWA);
    this.nocommunity = cleverapps.platform.oneOf(Huzcom, GDCom, Plinga, Pliega, Rustore, Playable, Yandex, Microsoft, MSStart, PWA);
    this.videoAdsMainMonetization = cleverapps.platform.oneOf(GDCom, Rustore, IOSCh, Xiaomi);
    this.nofirebase = cleverapps.platform.oneOf(Pliega, Rustore, IOSCh, Playable, Microsoft, Mygames, MSStart, PWA);
    this.nofacebookevents = cleverapps.platform.oneOf(Pliega, Rustore, IOSCh, Playable, Microsoft, MacOS, Mygames, MSStart, PWA);
    this.nologin = cleverapps.platform.oneOf(cleverapps.SocialManager.NoSocialPlatforms) || cleverapps.config.nologin;
    this.softCurrencyRealProduct = cleverapps.platform.oneOf(Mobage, SPMobage);
};

cleverapps.Flags.prototype.toggle = function (flag) {
    this[flag] = !this[flag];

    this.trigger("toggle:" + flag, this[flag]);
};

cleverapps.Flags.prototype.get = function (flag) {
    return this[flag];
};

cleverapps.Flags.prototype.reset = function () {
    this.init();
    this.update();
};

cleverapps.Flags.prototype.update = function () {
    var nopayments = !cleverapps.payments.isAvailable() || Object.keys(cleverapps.PaymentsManager.PRODUCTS).length === 0;

    if (nopayments) {
        this.videoAdsMainMonetization = true;
    }

    var keepAnnoying = cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_NONE;

    if (!keepAnnoying) {
        this.norequest = true;
        levels.friendRequests.deleteFakeRequests();
    }

    this.norest = this.norest || cleverapps.user.cheater;

    AdsLimits.initialize();
};
