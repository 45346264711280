/**
 * Created by mac on 7/26/18
 */

var WardrobeReceiveWindow = Window.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        var styles = cleverapps.styles.WardrobeReceiveWindow;

        this._super({
            name: "wardrobereceivewindow",
            content: this.generateContent(),
            closeButton: false,
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "OK",
                onPressed: this.close.bind(this)
            },
            showSound: bundles.main.urls.reward_window_effect
        });
    },

    onShow: function () {
        this._super();
    },

    generateContent: function () {
        var styles = cleverapps.styles.WardrobeReceiveWindow;

        var content = new cc.Node();
        content.setContentSize2(styles.width, styles.height);
        content.setAnchorPoint2();

        this.personNode = new cc.Node();
        this.personNode.setContentSize2(styles.personNode.width, styles.personNode.height);
        this.personNode.setAnchorPoint2();

        this.person = new Person("hero");
        if (styles.person.scale) {
            this.person.setScale(styles.person.scale);
        }
        this.personNode.addChild(this.person);
        this.person.setPositionRound(styles.person.position);

        var text = cleverapps.UI.generateTTFText("WardrobeRecieveText", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(content.width, 0);

        var itemContent = new cleverapps.Layout([text, this.personNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.content.margin
        });

        itemContent.setPositionRound(content.width / 2 + styles.content.position.x, content.height / 2 + styles.content.position.y);

        content.addChild(itemContent);

        return content;
    }
});

WardrobeReceiveWindow.prototype.listBundles = function () {
    return ["wardrobe_window"];
};

cleverapps.styles.WardrobeReceiveWindow = {
    width: 520,
    height: 500,

    personNode: {
        width: 300,
        height: 500
    },

    person: {
        scale: 0.9,
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    content: {
        margin: 50,
        position: {
            x: 0,
            y: 90
        }
    },

    button: {
        width: 250,
        height: 100
    }
};
