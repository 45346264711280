/**
 * Created by andrey on 07.04.2020.
 */

cc.game.pause = cleverapps.extendFunc(cc.game.pause, function () { 
    if (this.isPaused()) {
        return;
    }

    this._super.apply(this, arguments);

    cleverapps.eventBus && cleverapps.eventBus.trigger("gamePause");
});

cc.game.resume = cleverapps.extendFunc(cc.game.resume, function () {
    if (!this.isPaused()) {
        return;
    }

    this._super.apply(this, arguments);

    cleverapps.eventBus && cleverapps.eventBus.trigger("gameResume");
});

cc.game.resumeNative = function () {
    if (!this.config) {
        setTimeout(this.resumeNative.bind(this), 1000);
        return;
    }

    if (this.isPaused()) {
        this.resume();
    }
};
