/**
 * Created by r4zi4l on 17.06.2022
 */

var Reward = function (type, value, options) {
    this.type = type;
    this.value = value;
    this.options = options || {};

    if (this.options.onFinish) {
        this.options.onFinish = cleverapps.once(this.options.onFinish);
    }

    if (["unlimitedLives", "worker"].indexOf(this.type) !== -1) {
        this.value = Product.FormatTimePeriod(this.value);
    }
};

Reward.prototype.receiveReward = function () {
    if (this.getAmount()) {
        this.handler = RewardTypes[this.type].handler.call(this, this.value, this.options);
    }
};

Reward.prototype.onAnimationFinished = function () {
    if (this.handler) {
        this.handler();
    }

    if (this.options.onFinish) {
        this.options.onFinish();
    }

    var amount = this.getAmount();
    if (!amount) {
        return;
    }

    if (this.sourceDelta) {
        cleverapps.UI.animateDelta(amount, {
            icon: this.getSmallIcon(),
            iconScale: this.sourceDelta.iconScale,
            x: this.sourceDelta.x,
            y: this.sourceDelta.y,
            parent: this.sourceDelta.parent,
            font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
        });
    }

    var target = cleverapps.aims.getTarget(this.getFlyingTarget());
    if (target.aim.noTargetDelta || target.aim.controls && target.aim.controls.indexOf("MenuBar") === 0) {
        return;
    }

    var sound;
    if (this.type === "mission" && this.value.missionType === Mission.TYPE_SOFTFEAST) {
        sound = bundles.main.urls.coins_effect;
    }

    cleverapps.scenes.getRunningScene(target).runAction(new cc.Sequence(
        new cc.CollectEffect(target),
        new cc.PlaySound(sound)
    ));

    if (!target.aim.withoutDelta) {
        cleverapps.UI.animateDelta(+amount || 0, {
            x: cleverapps.styles.Reward.sidebarDelta.x,
            y: cleverapps.styles.Reward.sidebarDelta.y,
            font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT,
            parent: target
        });
    }
};

Reward.prototype.getAmount = function () {
    if (Array.isArray(this.value)) {
        return this.value;
    }
    if (["units", "battlePass", "kraken", "growthFund", "troop"].indexOf(this.type) !== -1) {
        return this.value.amount || 1;
    }
    if (typeof this.value === "object") {
        return this.value.amount || this.value.level || this.value.time || +this.value.subscription || 0;
    }
    return this.value || 0;
};

Reward.prototype.getIcon = function () {
    var params = {};

    if (this.type === "mission") {
        var missionType = Mission.ParseCompoundType(this.value.missionType).type;
        params.frame = RewardTypes[this.type].icon[missionType] || Mission.getCollectIcon(missionType);
    } else if (this.type === "boosters") {
        params.frame = RewardTypes.boosters.icon[this.value.id];
    } else if (this.type === "battlePass") {
        params.json = bundles.passbuyticket_window.jsons[this.value.missionType === Mission.TYPE_SALEPASS ? "purchase_ticket_json" : "ticket_json"];
        params.animation = "animation";
    } else if (this.type === "units") {
        params.unit = this.value;
    } else if (this.type === "wardrobe") {
        params.json = bundles.person_hero.jsons.hero_json;
        params.scale = 0.6;
        params.animation = "idle";
    } else if (this.type === "herolevels") {
        params.json = bundles.heroes.jsons[this.value.color.toUpperCase() + "_hero_spine_json"];
        params.scale = 1.5;
        params.skin = match3.heroes.getCurrentSkin();
        params.animation = "idle";
    } else if (this.type === "piggyBank") {
        params.json = bundles.piggy_bank.jsons.piggy_bank_reward_json;
        params.animation = "idle";
    } else if (this.type === "clover" && Game.currentGame && Game.currentGame.secondaryMission) {
        params.frame = Game.currentGame.secondaryMission.getIcon();
    } else if (this.type === "cup") {
        params.frame = RewardTypes[this.type].icon[this.value.type];
    } else if (this.type === "keys") {
        params.key = this.value;
    } else if (this.type === "troop") {
        params.troop = this.value;
    } else {
        params.frame = RewardTypes[this.type].icon;
    }

    var icon;

    if (params.troop) {
        icon = WarriorView.getUnitImage(WarriorLines[params.troop.code], params.troop.stage);
    } else if (params.unit) {
        icon = UnitView.getUnitImage(params.unit, { alignAnchorX: true, preferStatic: true });
    } else if (params.json) {
        icon = new cleverapps.Spine(params.json, params.scale || 1);
        icon.setAnimation(0, params.animation, true);

        if (params.skin) {
            icon.setSkin(params.skin);
        }
    } else if (params.frame) {
        icon = new cc.Sprite(params.frame);
    } else if (params.key) {
        icon = new QuestItemView(params.key, true);
    } else {
        cleverapps.throwAsync("Reward icon is undefined - " + this.type);
        icon = new cc.Node();
    }

    return icon;
};

Reward.prototype.getSmallIcon = function () {
    var params = {};

    if (this.type === "boosters") {
        params.frame = RewardTypes.boosters.smallIcon[this.value.id];
    } else if (this.type === "mission") {
        params.frame = RewardTypes.mission.smallIcon[Mission.ParseCompoundType(this.value.missionType).type];
    } else if (this.type === "cup") {
        params.frame = RewardTypes[this.type].smallIcon[this.value.type];
    } else {
        params.frame = RewardTypes[this.type].smallIcon;
    }

    var icon;

    if (params.frame) {
        icon = new cc.Sprite(params.frame);
    } else {
        icon = this.getIcon();
    }

    cleverapps.UI.fitToBox(icon, {
        height: cleverapps.styles.Reward.smallIconHeight
    });

    return icon;
};

Reward.prototype.getFlyingAmount = function () {
    var amount = this.getAmount();

    if (["unlimitedLives", "worker"].indexOf(this.type) !== -1) {
        return 1;
    }

    if (this.type === "wands") {
        if (amount <= 3) {
            return amount;
        }
        return Math.min(5, Math.floor(amount / 50) + 3);
    }

    if (this.type === "energy") {
        return Math.min(5, Math.floor(amount / 5) + 1);
    }

    if (this.type === "mission") {
        if (this.value.missionType === Mission.TYPE_KRAKENFEAST) {
            return Math.min(3, amount);
        }
        if (this.value.missionType === Mission.TYPE_SOFTFEAST) {
            if (amount >= 500) {
                return 4;
            }
            if (amount >= 150) {
                return 3;
            }
            if (amount) {
                return 2;
            }
        }
        if (this.value.missionType === Mission.TYPE_LIVESFEAST) {
            if (amount >= 20) {
                return 3;
            }
            if (amount >= 10) {
                return 2;
            }
            if (amount) {
                return 1;
            }
        }
    }

    if (amount >= 100) {
        return 8;
    }
    if (amount >= 50) {
        return 5;
    }
    if (amount >= 3) {
        return 3;
    }
    return amount;
};

Reward.prototype.getFlyingTarget = function () {
    if (this.options.target) {
        return this.options.target;
    }

    if (this.type === "boosters") {
        return "boosters" + this.value.id;
    }

    if (this.type === "mission") {
        return "mission" + this.value.missionType;
    }

    if (this.type === "units" && this.options.toMainWorld) {
        return "mainWorld";
    }

    if (this.type === "cup") {
        return "cup" + this.value.type;
    }

    if (this.type === "gold" || this.type === "rubies") {
        return "hard";
    }

    if (this.type === "coins") {
        return "soft";
    }

    if (this.type === "unlimitedLives" || this.type === "energy") {
        return "lives";
    }

    return this.type;
};

Reward.prototype.getText = function (prefix) {
    if (this.type === "unlimitedLives") {
        return this.value.title;
    }

    if (this.type === "battlePass") {
        return "PassProduct.Title";
    }

    if (this.type === "worker") {
        return this.value.subscription ? prefix + (this.value.amount || 1) : Messages.get(this.value.title, { amount: this.value.time });
    }

    if (this.type === "growthFund") {
        return "Shop.growthFund.title";
    }

    if (this.type === "piggyBank" && cleverapps.piggyBank.isFull()) {
        return "VictoryWindowBase.piggyBankFull";
    }

    var text = prefix ? String(prefix) : "";

    if (this.type === "boosters" || this.type === "keys" || this.type === "randomBoosters") {
        text += this.value.amount;
    } else if (this.type === "units") {
        text += this.value.amount || 1;
    } else if (this.type === "troop") {
        text += this.value.amount || 1;
    } else if (this.type === "herolevels") {
        text += Math.max(0, Math.min(Heroes.HEROES_MAX_LEVEL, this.value.level));
    } else if (Array.isArray(this.value)) {
        text += this.value.join("-");
    } else if (this.type === "kraken") {
        text += "3";
    } else {
        text += this.value;
    }

    return text;
};

Reward.prototype.listControls = function () {
    return cleverapps.toArray(RewardTypes[this.type].controls) || [];
};

Reward.prototype.listBundles = function () {
    return cleverapps.toArray(RewardTypes[this.type].bundle) || [];
};

Reward.prototype.collectRewardsAnimation = function (source, options) {
    options = options || {};

    var amount = this.getAmount();
    if (!amount) {
        return;
    }

    if (cleverapps.config.type === "merge") {
        if (source instanceof Unit) {
            source = source.onGetView();
        } else if (source instanceof FogTile) {
            source = Game.currentGame.map.getView().getCell(source.x, source.y);
        }

        options.fromMap2d = source instanceof UnitView;
    }

    var scene = cleverapps.scenes.getRunningScene(source);
    if (!source || !source.parent) {
        scene.runAction(new cc.Sequence(
            new cc.DelayTime(options.delay / 1000 || 0),
            new cc.CallFunc(this.onAnimationFinished.bind(this))
        ));
        return;
    }

    if (options.fromMap2d && !options.withoutDelta) {
        var styles = cleverapps.styles.Reward.jumpCollect;
        var scale = options.beginScale || styles.flyingIcons.scale;
        var animations = Game.currentGame.map.getView().animations;
        var position = animations.convertToNodeSpace(source.parent.convertToWorldSpace(source.getPosition()));

        this.sourceDelta = {
            iconScale: scale,
            x: position.x,
            y: position.y + styles.delta.dy + (options.deltaOffset || 0),
            parent: animations
        };
    }

    var targetType = this.getFlyingTarget();
    var target = cleverapps.aims.getTarget(targetType);

    var flyingAnimation = Reward.COLLECT_ANIMATION;
    if (options.flyingAnimation) {
        flyingAnimation = options.flyingAnimation;
    } else if (RewardTypes[this.type].flyingAnimation) {
        flyingAnimation = RewardTypes[this.type].flyingAnimation;
    } else if (target.aim.flyingAnimation) {
        flyingAnimation = target.aim.flyingAnimation;
    }

    switch (flyingAnimation) {
        case Reward.SPAWN_UNITS_ANIMATION:
            this.spawnUnitsAnimation(this.spawnedUnits, source, this.onAnimationFinished.bind(this), options);
            break;

        case Reward.SPAWN_CARDS_ANIMATION:
            this.spawnCardsAnimation(this.spawnedCards, source, target, this.onAnimationFinished.bind(this), options);
            break;

        case Reward.JUMP_COLLECT_ANIMATION:
            this.jumpCollectAnimation(source, target, this.onAnimationFinished.bind(this), options);
            break;

        case Reward.COLLECT_ANIMATION:
            this.collectAnimation(source, target, this.onAnimationFinished.bind(this), options);
            break;

        case Reward.NO_ANIMATION:
            scene.runAction(new cc.Sequence(
                new cc.DelayTime(options.delay / 1000 || 0),
                new cc.CallFunc(this.onAnimationFinished.bind(this))
            ));
    }

    if (target.aim.scrollsToTarget && target.aim.scrollsToTarget()) {
        scene.scroll.runAction(new cc.Sequence(
            new cc.DelayTime((options.delay || 0) + 1),
            new cc.ScrollAction(target, {
                duration: 2,
                visibleBox: {
                    left: 0.4,
                    right: 0.4,
                    top: 0.4,
                    bottom: 0.4
                }
            })
        ));
    }
};

Reward.prototype.collectAnimation = function (source, target, callback, options) {
    source.stopAllActions();
    source.runAction(new cc.Sequence(
        new cc.DelayTime(options.delay / 1000 || 0),
        cleverapps.UI.animateCollect(source, target, {
            path: options.path,
            adjustToTarget: true
        }),
        new cc.CallFunc(callback),
        new cc.RemoveSelf()
    ));
};

Reward.prototype.spawnUnitsAnimation = function (spawnedUnits, source, callback, options) {
    source.replaceParentSamePlace(cleverapps.scenes.getMovingNode(source), {
        keepScale: true
    });

    source.runAction(new cc.Sequence([
        new cc.DelayTime(0.4 + options.delay / 1000),
        new cc.ScaleTo(0.2, 1.3),
        new cc.CallFunc(function () {
            Game.currentGame.spawnAction(spawnedUnits, [], source, {
                fromNode: true,
                startScale: source.adjustScaleTo(Game.currentGame.map.getView().animations),
                startAnchors: { anchorX: 0.5, anchorY: 0.5 }
            });
        }),
        new cc.DelayTime(0.2),
        new cc.Hide(),
        new cc.DelayTime(0.5),
        new cc.CallFunc(callback),
        new cc.RemoveSelf()
    ]));
};

Reward.prototype.spawnCardsAnimation = function (spawnedCards, source, target, callback, options) {
    var scene = cleverapps.scenes.getRunningScene(source).scene;
    var position = scene.convertToNodeSpace(source.parent.convertToWorldSpace(source));

    cleverapps.aims.showTarget(target);

    var actions = [];
    actions.push(new cc.DelayTime(options.delay / 1000));

    spawnedCards.forEach(function (card, index) {
        actions.push(new cc.DelayTime(index * 0.3));
        actions.push(new cc.CallFunc(function () {
            cleverapps.aims.showTarget(target);

            var ViewClass = card.getViewClass();
            var view = new ViewClass(card);
            view.setPositionRound(position);
            scene.addChild(view);

            Game.currentGame.hand.push(card, {
                insertIndex: cleverapps.Random.random(Game.currentGame.hand.stack.length)
            });

            cleverapps.audio.playSound(bundles.game.urls.plus_card_effect);
        }));
    });

    actions.push(new cc.CallFunc(callback));

    scene.runAction(new cc.Sequence(actions));
};

Reward.prototype.jumpCollectAnimation = function (source, target, callback, options) {
    options = options || {};

    var styles = cleverapps.styles.Reward.jumpCollect;
    var scale = options.beginScale || styles.flyingIcons.scale;
    var delay = options.delay / 1000 || 0;
    var amount = this.getFlyingAmount();
    var waiter = cleverapps.wait(amount, callback);

    cleverapps.arrayFill(amount).forEach(function (value, index) {
        var movingNode = cleverapps.scenes.getMovingNode(source);

        var item = this.getSmallIcon();
        item.setPositionRound(movingNode.convertToNodeSpace(source.parent.convertToWorldSpace(source.getPosition())));
        movingNode.addChild(item);

        item.setScale(scale * 0.5);
        item.setLocalZOrder(1 / (1 + index));

        item.setVisible(false);
        item.runAction(new cc.Sequence(
            new cc.DelayTime(delay + index * 0.1 + Math.random() * 0.1),
            new cc.Show(),
            cleverapps.UI.animateJumpCollect(item, target, {
                collectMovingNode: options.fromMap2d && cleverapps.windows.isActive() && cleverapps.scenes.getMovingNode(source),
                jumpScale: scale * 1.2,
                jumpOffset: index / amount,
                collectScale: target.finalScale || scale
            }),
            new cc.CallFunc(waiter),
            new cc.RemoveSelf()
        ));
    }.bind(this));
};

Reward.NO_ANIMATION = 1;
Reward.COLLECT_ANIMATION = 2;
Reward.SPAWN_UNITS_ANIMATION = 3;
Reward.SPAWN_CARDS_ANIMATION = 4;
Reward.JUMP_COLLECT_ANIMATION = 5;

cleverapps.styles.Reward = {
    sidebarDelta: {
        x: { align: "center" },
        y: { align: "center", dy: 100 }
    },

    smallIconHeight: 70,

    second: {
        y: 400
    },

    jumpCollect: {
        flyingIcons: {
            scale: 0.6
        },

        delta: {
            dy: 80
        },

        jumpRight: {
            x1: 50,
            x2: 150,
            y1: -50,
            y2: -100
        },

        jumpLeft: {
            x1: -50,
            x2: -150,
            y1: -50,
            y2: -100
        },

        jumpBoth: {
            x1: -50,
            x2: 50,
            y1: -50,
            y2: -100
        }
    },

    flyHint: {
        speed: 3000
    }
};
