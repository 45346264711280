/**
 * Created by r4zi4l on 28.07.2022
 */

var Snapshots = function () {
    var delta = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.SNAPSHOT_TIME_DELTA);
    DateShift.setShift(delta);
};

Snapshots.prototype.reset = function () {
    DateShift.setShift(0);
    cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.SNAPSHOT_TIME_DELTA);
};

Snapshots.prototype.extractData = function (callback) {
    var waiter = cleverapps.wait(2, function () {
        var extractors = {};
        for (var type in CustomSyncers.extractors) {
            if (type.indexOf("units_") === 0) {
                continue;
            }

            extractors[type] = CustomSyncers.extractors[type];
        }

        for (type in Snapshots.extractors) {
            extractors[type] = Snapshots.extractors[type];
        }

        var data = {};
        data.date = Date.now();
        data.slot = Game.currentGame && Game.currentGame.slot;

        for (type in extractors) {
            var extractor = extractors[type];
            if (!extractor) {
                continue;
            }

            if (extractor.useSlots) {
                if (data.slot) {
                    data[type] = extractor(data.slot);
                }
            } else {
                data[type] = extractor();
            }
        }

        callback(data);
    });

    if (cleverapps.userClan) {
        cleverapps.userClan.load(waiter, waiter, {
            ignoreNoRest: true
        });
        cleverapps.clanMessages.loadMessages(waiter);
    } else {
        waiter();
        waiter();
    }
};

Snapshots.prototype.importData = function (snapshot) {
    // migration from local checkpoints
    if (snapshot.data) {
        Object.keys(snapshot.data).forEach(function (key) {
            var tokens = key.split("_");

            if (tokens[0] !== "units") {
                return;
            }

            var code = tokens[1];
            var slot = tokens[2] || "";

            if (!snapshot.data["units" + slot]) {
                snapshot.data["units" + slot] = {};
            }

            snapshot.data["units" + slot][code] = snapshot.data[key];
            delete snapshot.data[key];
        });

        snapshot = snapshot.data;
    }

    // migration from old snapshots
    if (snapshot.sync) {
        snapshot.sync.date = snapshot.date;
        snapshot.sync.actions = snapshot.actions;
        snapshot.sync.settings = snapshot.settings;
        snapshot = snapshot.sync;
    }

    cleverapps.snapshots.reset();

    if (cleverapps.localPushes) {
        cleverapps.localPushes.reset();
    }

    if (cleverapps.unitSavers) {
        cleverapps.unitSavers.reset();
    }

    if (cleverapps.GameSaver) {
        cleverapps.GameSaver.reset();
    }

    if (!cleverapps.config.importMode) {
        snapshot.users.id = cleverapps.user.id;
    }

    snapshot.achievements = snapshot.achievements || 0;
    if (snapshot.date) {
        var delta = snapshot.date - Date.now();
        DateShift.setShift(delta);
        cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.SNAPSHOT_TIME_DELTA, delta);
    }
    if (!snapshot.abtest) {
        cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.ABTEST);
    }

    var type;
    var importers = {};

    for (type in Snapshots.importers) {
        importers[type] = Snapshots.importers[type];
    }

    for (type in CustomSyncers.importerData) {
        if (!importers[type]) {
            importers[type] = CustomSyncers.importerData[type];
        }
    }

    for (type in importers) {
        if (type in snapshot) {
            var importer = importers[type];
            var data = cleverapps.clone(snapshot[type], true);

            if (importer.useSlots) {
                if (snapshot.slot) {
                    importer(snapshot.slot, data);
                }
            } else {
                importer(data);
            }
        }
    }
};

Snapshots.prototype.canCreate = function () {
    if (cleverapps.meta.focus === "Scene.intro") {
        cleverapps.notification.create("can't take snapshot while intro is playing");
        return false;
    }

    var environment = cleverapps.environment;
    if (environment.isEditorScene() || environment.isAdministratorScene()
        || environment.isWysiwygScene() || environment.isAtlasAnalyzerScene()) {
        cleverapps.notification.create("Can't take snapshot on current scene");
        return false;
    }

    if (Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.notification.create("Can't take snapshot if outcome is victory");
        return false;
    }

    return true;
};

Snapshots.prototype.createSnapshot = function (data, onSuccess) {
    if (!this.canCreate()) {
        return;
    }

    onSuccess = onSuccess || function (targetUrl) {
        cleverapps.copyToClipboard(targetUrl, function () {
            cleverapps.notification.create("snapshot copied to clipboard");
        });
        this.openInNewTab(targetUrl);
    }.bind(this);

    var create = function (data) {
        var id = Math.random().toString(36).slice(2);
        var standUrl = projects[cleverapps.config.name].stand.deployment;
        var restUrl = standUrl + "/" + cleverapps.config.name + "-rest/snapshots/create/" + id;
        var targetUrl = standUrl + "/" + id;

        cleverapps.RestClient.post(restUrl, data, function () {
            onSuccess(targetUrl);
        }, function (error) {
            console.log("connection error", error);
            cleverapps.notification.create(error.errorMessage || error.statusText || error);
        }, {
            ignoreNoRest: true
        });
    };

    if (data) {
        create(data);
    } else {
        this.extractData(create);
    }
};

Snapshots.prototype.openInNewTab = function (url) {
    setTimeout(window.open.bind(window, url, "_blank"), 0);
};

Snapshots.prototype.getSnapshot = function (id, callback) {
    var onSuccess = function (response) {
        callback(response);
    };

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || response.statusText || response || "no snapshot found");
        callback();
    };

    var standUrl = projects[cleverapps.config.name].stand.deployment;
    var restUrl = standUrl + "/" + cleverapps.config.name + "-rest/snapshots/get/" + id;

    cleverapps.RestClient.get(restUrl, {}, onSuccess, onError);
};

Snapshots.prototype.exportToJson = function (snapshot) {
    var element = document.createElement("a");
    element.href = URL.createObjectURL(new Blob([JSON.stringify(snapshot, null, 4)], {
        type: "application/json"
    }));
    element.setAttribute("download", cleverapps.config.name + "_" + cleverapps.user.getHumanReadableNumber() + ".json");
    element.style.display = "none";

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

Snapshots.prototype.importFromUrl = function (callback) {
    var url = prompt("enter snapshot url");

    var id = url && url.replace(/.*snapshot=([A-Za-z0-9]+).*/, "$1");
    if (id === url) {
        id = url && url.replace(/.*labsystech.ru\/([A-Za-z0-9]+).*/, "$1");
    }
    if (!id) {
        return;
    }

    this.getSnapshot(id, function (snapshot) {
        callback(snapshot);
    });
};

Snapshots.prototype.record = function () {
    if (!this.canCreate()) {
        return;
    }

    this.extractData(function (data) {
        cleverapps.capturedSnapshot = cleverapps.clone(data, true);
        cleverapps.actionsRecording = new CapturedScenario();

        RecordingOverrides.override();
    });
};

Snapshots.prototype.stopRecording = function () {
    cleverapps.actionsRecording.pushAction({
        type: "finish"
    });

    RecordingOverrides.restore();

    var data = cleverapps.capturedSnapshot;
    data.actions = cleverapps.actionsRecording.steps;

    delete cleverapps.capturedSnapshot;
    delete cleverapps.actionsRecording;
    this.createSnapshot(data);
};

Snapshots.prototype.playActions = function (actions) {
    cleverapps.snapshotBar.hideButtons();
    cleverapps.snapshotBar.showLabel("**playback");

    console.log("actions: ", actions);

    GamePlayer.Create(undefined, {
        finger: {
            image: bundles.finger.frames.tutorial_hand_png
        }
    }).play({
        actions: actions,
        delay: 0,
        initialDelay: 0
    }, function () {
        cleverapps.snapshotBar.removeLabel();
        cleverapps.snapshotBar.showButtons();
    });
};
