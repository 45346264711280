/**
 * Created by mac on 5/2/20
 */

var ActionPlayer = function (actions) {
    this.actions = [];
    this.add(actions);
};

ActionPlayer.prototype.add = function (actions) {
    if (Array.isArray(actions)) {
        actions.forEach(function (action) {
            this.add(action);
        }.bind(this));
    } else if (actions) {
        this.actions.push(actions);
    }
};

ActionPlayer.prototype._playNext = function () {
    if (this.index === this.actions.length || this._stopped) {
        return;
    }

    this.actions[this.index++](cleverapps.once(this._playNext.bind(this)));
};

ActionPlayer.prototype.play = function (onComplete) {
    this.index = 0;

    if (onComplete) {
        this.actions.push(onComplete);
    }

    this._playNext();
};

ActionPlayer.prototype.stop = function () {
    this._stopped = true;
};

ActionPlayer.delay = function (delay) {
    return function (f) {
        setTimeout(f, delay);
    };
};