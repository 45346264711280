/**
 * Created by vladislav on 16.09.2020
 */

var AdsAgeCounter = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.setAnchorPoint2();

        properties = properties || {};
        var listensEvents = properties.listensEvents || [];

        var styles = cleverapps.styles.AdsAgeCounter;

        this.type = properties.type;
        this.silent = properties.silent === "yes";
        this.completionAnimation = properties.completionAnimation;

        this.startAge = 90;
        if (cleverapps.settings.language === "ru") {
            this.ages = [86, 76, 65, 58, 55, 49, 45, 38, 29, 25, 18];
        }
        this.finishAge = 18;
        this.currentAge = this.startAge;

        var prefixText = properties.localizedPrefix ? Wysiwyg.getValueForResolution(properties.localizedPrefix)[cleverapps.settings.language]
            : properties.ageCounterPrefix || "";

        var postfixText = properties.localizedPostfix ? Wysiwyg.getValueForResolution(properties.localizedPostfix)[cleverapps.settings.language]
            : properties.ageCounterPostfix || "";

        var prefix = cleverapps.UI.generateOnlyText(prefixText.toUpperCase(), Wysiwyg.getValueForResolution(properties.font));
        var postfix = cleverapps.UI.generateOnlyText(postfixText.toUpperCase(), Wysiwyg.getValueForResolution(properties.font));
        var age = this.age = cleverapps.UI.generateOnlyText(this.startAge, Wysiwyg.getValueForResolution(properties.fontAge));

        var counterLayout;
        if (this.type === AdsAgeCounter.TYPE_UNDERSCORE) {
            var underscore = new cc.Sprite(bundles.ads_counter.frames.underscore_png);
            counterLayout = new cleverapps.Layout([age, underscore], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.underscore.margin
            });
        } else {
            var json;
            if (this.type === AdsAgeCounter.TYPE_BRAIN) {
                json = bundles.ads_counter.jsons.brain_json;
            } else if (this.type === AdsAgeCounter.TYPE_HEART) {
                json = bundles.ads_counter.jsons.heart_json;
            }

            var brainContainer = new cc.Node();
            brainContainer.setAnchorPoint2();
            brainContainer.setContentSize2(styles.brain);

            this.brain = new cleverapps.Spine(json);
            this.brain.setAnimation(0, "idle", true);
            this.brain.addChild(age);
            age.setPositionRound(this.brain.width / 2, this.brain.height / 2);
            brainContainer.addChild(this.brain);
            this.brain.setPositionRound(brainContainer.width / 2, brainContainer.height / 2);
            counterLayout = brainContainer;
        }

        var layout = new cleverapps.Layout([prefix, counterLayout, postfix], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        if (this.type === AdsAgeCounter.TYPE_UNDERSCORE) {
            counterLayout.y += styles.underscore.offsetY;
        }

        this.addChild(layout);
        cleverapps.UI.wrap(this);
        layout.setPositionRound(this.width / 2, this.height / 2);

        if (listensEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);
        }

        eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.showUp.bind(this), this);
    },

    showUp: function () {
        if (this.silent) {
            return;
        }

        var parent = this.getParent().getParent();

        var zOrder = this.getParent().getLocalZOrder();
        this.getParent().setLocalZOrder(100);

        var targetPosition = this.getPosition();
        var position = this.getParent().convertToNodeSpace(parent.convertToWorldSpace(cc.p(parent.width / 2, parent.height / 2)));

        this.setPositionRound(position);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.0),
            new cc.MoveTo(0.2, targetPosition).easing(cc.easeOut(2)),
            new cc.CallFunc(function () {
                this.getParent().setLocalZOrder(zOrder);
            }.bind(this))
        ));
    },

    incProgress: function (progress) {
        if (this.ages) {
            this.currentAge = this.ages[Math.round((this.ages.length - 1) * progress)];
        } else {
            this.currentAge = Math.floor(this.startAge - (this.startAge - this.finishAge) * progress);
        }
        this.age.countToAnimation(this.currentAge, 0.4);

        this.age.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 1.3),
                new cc.ScaleTo(0.1, 1.0)
            )
        );

        if (progress === 1) {
            setTimeout(this.onProgressCompleted.bind(this), 1000);
        }
    },

    onProgressCompleted: function () {
        if (this.brain) {
            this.getParent().setLocalZOrder(20);
            var parent = this.getParent().getParent();

            if (this.completionAnimation === "new") {
                var spine = new cleverapps.Spine(bundles.ads_counter.jsons.brain_gym, cleverapps.styles.AdsAgeCounter.newCompletionAnimation.scale);
                spine.setAnimation(0, "animation", false);
                parent.addChild(spine, 20);
                spine.setPositionRound(parent.width / 2, parent.height / 2);
            } else if (this.completionAnimation === "old") {
                this.brain.setAnimation(0, "idle2", false);

                var position = this.brain.getParent().convertToNodeSpace(parent.convertToWorldSpace(cc.p(parent.width / 2, parent.height / 2)));
                this.brain.runAction(new cc.MoveTo(0.8, position));

                this.age.runAction(new cc.ScaleTo(0.5, 2.0));
            }

            if (this.type === AdsAgeCounter.TYPE_HEART) {
                cleverapps.audio.playSound(bundles.ads_counter.urls.heartbeat_effect, { loop: true });
                setTimeout(function () {
                    cleverapps.audio.cache[bundles.ads_counter.urls.heartbeat_effect].stop();
                }, 2500);
            }
        }
    }
});

AdsAgeCounter.TYPE_UNDERSCORE = "underscore";
AdsAgeCounter.TYPE_BRAIN = "brain";
AdsAgeCounter.TYPE_HEART = "heart";

AdsAgeCounter.DEFAULT = {
    localizedPrefix: {
        en: "Your brain is",
        de: "Dein Gehirn ist",
        es: "Tu cerebro tiene",
        fr: "Votre cerveau a",
        it: "Il tuo cervello ha",
        ja: "あなたの脳は",
        nl: "Je brein is",
        pt: "Seu cérebro tem",
        ru: "Твоему мозгу"
    },
    localizedPostfix: {
        en: "years old",
        de: "Jahre alt",
        es: "años",
        fr: "ans",
        it: "anni",
        ja: "歳です",
        nl: "jaar oud",
        pt: "anos",
        ru: "лет"
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADS_AGE_COUNTER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },
    ADS_AGE_COUNTER_TEXT_AGE: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    }
});

AdsAgeCounter.BUNDLES = ["ads_counter"];

AdsAgeCounter.PROPERTIES = [{
    name: "localizedPrefix", type: "localizedText", value: AdsAgeCounter.DEFAULT.localizedPrefix
}, {
    name: "localizedPostfix", type: "localizedText", value: AdsAgeCounter.DEFAULT.localizedPostfix
}, {
    name: "type", type: "string", items: [AdsAgeCounter.TYPE_UNDERSCORE, AdsAgeCounter.TYPE_BRAIN, AdsAgeCounter.TYPE_HEART], value: AdsAgeCounter.TYPE_UNDERSCORE, bubbleUp: true
}, {
    name: "font", type: "font", value: cleverapps.styles.FONTS.ADS_AGE_COUNTER_TEXT
}, {
    name: "fontAge", type: "font", value: cleverapps.styles.FONTS.ADS_AGE_COUNTER_TEXT_AGE
}, {
    name: "listensEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.PROGRESS_CHANGED]
}, {
    name: "silent", type: "string", items: ["no", "yes"], value: "no"
}, {
    name: "completionAnimation", type: "string", items: ["old", "new", "silent"], value: "old"
}];

cleverapps.styles.AdsAgeCounter = {
    x: { align: "center" },
    y: { align: "top", dy: -300 },

    underscore: {
        margin: -15,
        offsetY: 10
    },

    brain: {
        width: 130,
        height: 130
    },

    newCompletionAnimation: {
        scale: 2.0
    },

    margin: 10
};