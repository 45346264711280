/**
 * Created by mac on 10/5/20
 */

var AdminLevelView = cc.Node.extend({
    ctor: function (adminLevel, adminLevelsView) {
        this._super();
        this.adminLevel = adminLevel;
        this.adminLevelsView = adminLevelsView;
        this.setAnchorPoint2();
        this.setContentSize2(cleverapps.styles.AdminLevelView);

        this.createBg();
        this.createContent();
        this.createControls();
        this.createTipIcon();
        this.createAttention();

        var styles = cleverapps.styles.AdminLevelView;

        this.flagsPanel = new AdminLevelFlagsPanel(adminLevel);
        this.flagsPanel.setPositionRound(styles.flagsPanel);
        this.addChild(this.flagsPanel);

        adminLevel.on("select", this.select.bind(this), this);
        if (adminLevel.selected) {
            this.select();
        }
        adminLevel.on("unselect", this.unselect.bind(this), this);

        if (!cleverapps.config.changeLevelsDisabled) {
            cleverapps.UI.onDrag(this, {
                followPointer: true,
                onDragMove: this.onDragMove.bind(this),
                onDragEnd: this.onDragEnd.bind(this)
            });
        }

        cleverapps.UI.onClick(this, this.onClick.bind(this));

        adminLevel.on("changeDifficulty", this.updateDifficultyBar.bind(this), this);
        adminLevel.on("startDrag", this.startDrag.bind(this), this);
        adminLevel.on("stopDrag", this.stopDrag.bind(this), this);
        adminLevel.on("update", this.onUpdate.bind(this), this);
        adminLevel.on("moveToSlot", this.moveToSlot.bind(this), this);

        this.updateDifficultyBar();

        this.updateCurrentIndicators();
    },

    onClick: function () {
        cleverapps.meta.display({
            focus: "AdminLevelClick",
            action: function (f) {
                this.adminLevel.onClick(f);
            }.bind(this)
        });
    },

    onDragMove: function () {
        var index = this.adminLevelsView.getNearestSlotIndex(this.parent.convertToWorldSpace(this.getPosition()));
        this.adminLevel.handleDragMove(index);
    },

    onDragEnd: function () {
        this.adminLevel.handleDragEnd();
    },

    startDrag: function () {
        this._oldZOrder = this.getLocalZOrder();
        this.setLocalZOrder(100);

        this._oldParent = this.getParent();
        this.replaceParentSamePlace(cleverapps.scenes.getRunningScene(this).scene);

        this.controls.setVisible(false);

        this.setRotation(10);

        this.updateCurrentIndicators();
    },

    stopDrag: function () {
        this.setLocalZOrder(this._oldZOrder);

        this.replaceParentSamePlace(this._oldParent);

        this.controls.setVisible(true);

        this.setRotation(0);

        this.updateCurrentIndicators();
    },

    moveToSlot: function (slotView) {
        this.replaceParentSamePlace(slotView);

        this.stopActionByTag(AdminLevelView.MOVE_ACTION_TAG);

        var position = cc.p(slotView.width / 2, slotView.height / 2);
        if (cleverapps.administrator.adminLevels.draggingLevel) {
            var action = this.runAction(new cc.MoveTo(0.3, position));
            action.setTag(AdminLevelView.MOVE_ACTION_TAG);
        } else {
            this.setPositionRound(position);
        }
    },

    onUpdate: function () {
        this.updateCurrentIndicators();
        this.flagsPanel.updateFlags();

        this.attention.setVisible(!this.adminLevel.isValid());
    },

    createAttention: function () {
        this.attention = new cc.Sprite(bundles.admin.frames.in_process);
        this.attention.setVisible(!this.adminLevel.isValid());
        this.attention.setPositionRound(cleverapps.styles.AdminLevelView.attention);
        this.addChild(this.attention);
    },

    createTipIcon: function () {
        var tipIcon = new cc.Sprite(bundles.admin.frames.tool_tip_button);
        tipIcon.setPositionRound(cleverapps.styles.AdminLevelView.tip);
        this.addChild(tipIcon);

        tipIcon.setVisible(this.adminLevel.comments);
        this.adminLevel.on("change:comment", function (comments) {
            tipIcon.setVisible(comments !== "");
        }, this);
    },

    updateDifficultyBar: function () {
        var styles = cleverapps.styles.AdminLevelView;

        if (this.difficultyBar) {
            this.difficultyBar.removeFromParent();
            delete this.difficultyBar;
        }

        if (this.adminLevel.difficulty === undefined) {
            return;
        }

        var difficulty = this.adminLevel.difficulty;

        var g = difficulty < 50 ? 255 : Math.floor(255 - (difficulty * 2 - 100) * 255 / 100);
        var r = difficulty > 50 ? 255 : Math.floor((difficulty * 1.5) * 255 / 100);
        var color = new cc.Color(r, g, 0, 255);

        this.difficultyBar = new cc.Scale9Sprite(bundles.admin.frames.difficulty_bar);
        this.difficultyBar.setAnchorPoint2(0, 0.5);
        this.difficultyBar.setColor(color);
        this.difficultyBar.setContentSize2(Math.floor(styles.difficulty.width * difficulty / 100), Math.floor(styles.difficulty.height));
        this.addChild(this.difficultyBar, 1);
        this.difficultyBar.setPositionRound(styles.difficulty.x, this.miniature.y - this.miniature.height / 2);

        this.difficultyBar.setScaleX(0);

        this.difficultyBar.runAction(new cc.ScaleTo(0.5, 1.0, 1.0).easing(cc.easeOut(2)));
    },

    createBg: function () {
        var img = this.adminLevel.selected ? bundles.admin.frames.lvl_selected_bg : bundles.admin.frames.lvl_bg;
        var bg = this.bg = cleverapps.UI.createScale9Sprite(img, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createContent: function () {
        var styles = cleverapps.styles.AdminLevelView;

        var miniature = this.miniature = AdminLevelMiniature.generate(this.adminLevel);
        this.addChild(miniature);
        miniature.setPositionRound(styles.miniature);

        var game = Game.currentGame;
        miniature.addCleaner(function () {
            game && game.stop();
        });

        var features = this.adminLevel.getFeatures();
        if (features.length > 0) {
            var featuresView = new cleverapps.Layout(features.map(function (feature) {
                return new cc.Sprite(bundles.admin.frames["feature_" + feature]);
            }), {
                direction: cleverapps.UI.HORIZONTAL
            });
            this.addChild(featuresView);
            featuresView.setPositionRound(styles.features);
        }

        var font = cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT;
        var string = this.adminLevel.getHint();

        if (cleverapps.UI.ImageFont.IsApplicable(font, string)) {
            this.text = new cleverapps.UI.ImageFont(string, font);
        } else {
            this.text = cleverapps.UI.generateOnlyText(string, font);
        }

        var text = this.text;
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.text.height);
        this.addChild(text);
        text.setPositionRound(styles.text);
    },

    createControls: function () {
        var styles = cleverapps.styles.AdminLevelView.controls;

        this.buttons = AdminLevelView.BUTTONS.filter(function (data) {
            if (data.available && !data.available()) {
                return false;
            }
            return true;
        }).map(function (data) {
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                content: new cc.Sprite(data.icon),
                width: styles.width,
                height: styles.height,
                hint: data.hint,
                onClicked: function () {
                    data.action(this.adminLevel);
                }.bind(this)
            });
            button.visible = false;

            cleverapps.UI.applyHover(button);

            return button;
        }.bind(this));

        var controls = this.controls = new cleverapps.Layout(this.buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        controls.setPositionRound(styles);
        this.addChild(controls);
    },

    updateCurrentIndicators: function () {
        this.removeCurrentFlag();
        if (this.adminLevel.isCurrent() && !this.adminLevel.dragging) {
            this.addCurrentFlag();
        }
    },

    removeCurrentFlag: function () {
        if (this.currentFlag) {
            this.currentFlag.removeFromParent();
            delete this.currentFlag;
        }
    },

    addCurrentFlag: function () {
        this.currentFlag = new cc.Sprite(bundles.admin.frames.current_arrow);
        this.addChild(this.currentFlag);
        this.currentFlag.setScale(0.7);
        this.currentFlag.setPositionRound(cleverapps.styles.AdminLevelView.currentFlag);
    },

    select: function () {
        this.text.setFont(cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT_SELECTED);
        this.bg.setSpriteFrame(new cc.Sprite(bundles.admin.frames.lvl_selected_bg).getSpriteFrame());

        var showControls = this.buttons.map(function (button) {
            return new cc.Sequence(
                new cc.DelayTime(0.05),
                new cc.CallFunc(function () {
                    button.visible = true;
                    button.opacity = 0;
                    button.runAction(new cc.FadeIn(0.2));
                })
            );
        });

        this.buttonsAction = this.runAction(new cc.Sequence(showControls));
        this.setScale(1);
    },

    unselect: function () {
        this.text.setFont(cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);
        this.bg.setSpriteFrame(new cc.Sprite(bundles.admin.frames.lvl_bg).getSpriteFrame());

        if (this.buttonsAction && !this.buttonsAction.isDone()) {
            this.stopAction(this.buttonsAction);
        }

        this.buttons.forEach(function (button) {
            button.visible = false;
        });
    }
});

AdminLevelView.MOVE_ACTION_TAG = "moveAction";

AdminLevelView.BUTTONS = [{
    icon: bundles.admin.frames.clone_icon,
    hint: "Clone level",
    available: function () {
        return !cleverapps.config.changeLevelsDisabled;
    },
    action: function (adminLevel) {
        cleverapps.administrator.adminLevels.copyLevel(adminLevel);
    }
}, {
    icon: bundles.admin.frames.copy_icon,
    hint: "Copy level to clipboard",
    available: function () {
        return ["merge", "tile3"].indexOf(cleverapps.config.type) !== -1;
    },
    action: function (adminLevel) {
        cleverapps.administrator.adminLevels.copyLevelToClipboard(adminLevel);
    }
}, {
    icon: bundles.admin.frames.edit_icon,
    hint: "Edit level",
    available: function () {
        return EditorScene.HasEditor();
    },
    action: function (adminLevel) {
        adminLevel.editLevel();
    }
}, {
    icon: bundles.admin.frames.play_icon,
    hint: "Play level",
    action: function () {
        cleverapps.meta.display({
            focus: "AdminPlayLevel",
            action: function (f) {
                LevelManager.getInstance().playLevel(f);
            }
        });
    }
}];

AdminLevelView.create = function (adminLevel, adminLevelsView) {
    if (adminLevel.isAddMoreLevel()) {
        return new AddLevelView(adminLevel);
    }

    return new AdminLevelView(adminLevel, adminLevelsView);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_LEVEL_TEXT_SELECTED: {
        name: "formal",
        size: 21
    },
    ADMIN_LEVEL_TEXT: {
        name: "formal",
        size: 21,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    }
});

cleverapps.styles.AdminLevelView = {
    width: 148,
    height: 220,

    difficulty: {
        width: 130,
        height: 5,
        x: 5
    },

    attention: {
        x: { align: "right", dx: -10 },
        y: { align: "top", dy: -10 }
    },

    controls: {
        height: 45,
        width: 45,
        margin: 4,
        x: { align: "center" },
        y: { align: "bottom", dy: -52 }
    },

    hardLevel: {
        x: { align: "left", dx: 6 },
        y: { align: "top", dy: -6 }
    },

    tip: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 80 }
    },

    miniature: {
        x: { align: "center" },
        y: { align: "top", dy: -5 }
    },

    features: {
        x: { align: "center" },
        y: { align: "bottom", dy: 20 }
    },

    text: {
        width: 140,
        height: 36,
        x: { align: "center" },
        y: { align: "bottom", dy: 7 }
    },

    currentFlag: {
        x: { align: "left", dx: 25 },
        y: { align: "top", dy: 28 }
    },

    flagsPanel: {
        x: { align: "right", dx: 0 },
        y: { align: "top", dy: 33 }
    }
};