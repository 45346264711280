/**
 * Created by spepa on 20.02.2020.
 */

var AdminFbProductsButton = cc.Node.extend({
    ctor: function (adminFBProducts) {
        this._super();
        this.setAnchorPoint2();

        this.adminFBProducts = adminFBProducts;
        this.adminFBProducts.onChangeStatus = this.createListener(this.changeStatus.bind(this));

        var styles = cleverapps.styles.AdminFbProductsButton;
        this.button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(bundles.admin.frames.fbProduct_icon),
            width: styles.width,
            height: styles.height,
            onClicked: this.process.bind(this)
        });

        this.addChild(this.button);
        this.setContentSize2(this.button.getContentSize());
        this.button.setPositionRound(this.width / 2, this.height / 2);
    },

    process: function () {
        this.adminFBProducts.processUpdate();
    },

    changeStatus: function () {
        if (this.loading) {
            this.loading.runAction(new cc.RemoveSelf());
            delete this.loading;
            this.button.setVisible(true);
        } else {
            this.button.setVisible(false);
            this.loading = new cc.Sprite(bundles.git.frames.loading);
            cleverapps.UI.fitToBox(this.loading, this.getContentSize());
            this.addChild(this.loading);
            this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
            this.loading.setPositionRound(this.width / 2, this.height / 2);
        }
    }
});

cleverapps.styles.AdminFbProductsButton = {
    width: 40,
    height: 40,
    x: { align: 'left', dx: 10},
    y: { align: 'top', dy: -20}
};