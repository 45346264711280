/**
 * Created by vladislav on 15/03/2023
 */

var DebugStats = function () {
    this.setEnabled(cleverapps.config.showFPS);

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        cc.director.setDisplayStats(this.enabled);
    }.bind(this));
};

DebugStats.prototype.toggle = function () {
    this.setEnabled(!this.enabled);
};

DebugStats.prototype.setEnabled = function (enabled) {
    this.enabled = enabled;

    cc.director.setDisplayStats(this.enabled);
};

if (engine === "creator") {
    cc.profiler.generateCanvas = cleverapps.extendFunc(cc.profiler.generateCanvas, function () {
        this._super.apply(this, arguments);

        cc.profiler._ctx.fillStyle = "black";
        cc.profiler._ctx.fillRect(0, 0, 500, 500);
        cc.profiler._ctx.fillStyle = "#00EFFF";
    });
}
