/**
 * Created by vladislav on 9/6/2022
 */

var SPMobage = function () {
    Platform.call(this, "sp_mbga");
};

SPMobage.prototype = Object.create(Platform.prototype);
SPMobage.prototype.constructor = SPMobage;

SPMobage.prototype.getLocalStoragePreffix = function () {
    return "_sp_mbga";
};

SPMobage.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_JAPANESE];
};