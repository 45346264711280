/**
 * Created by Denis Kuzin on 03 january 2021
 */

var MergeCraftPlayer = function (level, options) {
    options = options || {};
    GamePlayer.call(this, level, options);
    this.actionsDone = 0;
};

MergeCraftPlayer.prototype = Object.create(GamePlayer.prototype);
MergeCraftPlayer.prototype.constructor = MergeCraftPlayer;

MergeCraftPlayer.prototype.initFinger = function () {
    this.finger.replaceParent(Game.currentGame.map.getView().animations);
    this.finger.setLocalZOrder(11);
    this.fingerInited = true;
};

MergeCraftPlayer.prototype.playAction = function (action, finishActionCallback, duration) {
    if (!Game.currentGame || Game.currentGame.counter.isActive()) {
        setTimeout(this.playAction.bind(this, action, finishActionCallback, duration), 50);
        return;
    }

    if (this.lastActionWasSwipe && action.type !== Merge.HARVEST) {
        this.finger.turnInto();
        this.finger.unPress();
    }

    var progressFilter = function (a) {
        if (this.options.progressFilterItems && this.options.progressFilterItems.length) {
            return a.type && this.options.progressFilterItems.indexOf(a.type) !== -1;
        }

        return a.type && ["zoom_to", "delay"].indexOf(a.type) === -1;
    }.bind(this);

    var callback;
    if (this.options.progressChanged && progressFilter(action)) {
        callback = function () {
            finishActionCallback();
            this.options.progressChanged(++this.actionsDone / this.actions.filter(progressFilter).length);
        }.bind(this);
    } else {
        callback = finishActionCallback;
    }

    if (action.type === "zoom_to") {
        Game.currentGame.map.getView().scroll.runAction(
            new cc.Sequence(
                new cc.ZoomAction(duration, {
                    zoom: action.zoom
                }),
                new cc.CallFunc(callback)
            )
        );
    } else if (action.type === "scroll_to") {
        if (cleverapps.gameModes.scrollByFinger && this.finger.isShown && action.pointerTouchCell) {
            this.scrollByFinger(action, duration, callback);
        } else {
            Game.currentGame.map.getView().scroll.runAction(
                new cc.Sequence(
                    new cc.CellScrollAction(action.cellPosition, {
                        duration: duration
                    }).easing(cc.easeInOut(2)),
                    new cc.CallFunc(callback)
                )
            );
        }
    } else if (action.type === "move") {
        this.moveUnit(action, callback);
    } else if (action.type === Merge.MERGE) {
        if (cleverapps.gameModes.automerge) {
            callback();
        } else {
            this.moveUnit(action, callback);
        }
    } else if (action.type === "startQuest") {
        var config = cleverapps.values(Game.currentGame.quests.config).filter(function (config) {
            return config.trigger && config.trigger.unit && Unit.Equals({ code: action.unit.code, stage: action.unit.stage - 1 }, config.trigger.unit);
        })[0];

        if (!config && action.unit && Game.currentGame.quests.config[Unit.GetKey(action.unit)]) {
            config = Game.currentGame.quests.config[Unit.GetKey(action.unit)];
        }

        if (!config || this.questShown) {
            callback();
            return;
        }

        this.questShown = true;

        cleverapps.meta.display({
            focus: "StartQuest",
            action: function (f) {
                var window = new StartQuestWindow([new Quest(config.quest)]);
                setTimeout(function () {
                    cleverapps.meta.freeFocus("StartQuest");
                    window.close();
                    callback();
                }, Math.min(3000, duration * 1000));
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    } else {
        callback();
    }
};

MergeCraftPlayer.prototype.scrollByFinger = function (action, duration, callback) {
    var touchCell = Game.currentGame.map.getView().alignInIsometricGrid(action.pointerTouchCell.x, action.pointerTouchCell.y);
    this.finger.moveTo(touchCell);

    this.finger.press();

    this.finger.addAction(function (f) {
        var mapView = Game.currentGame.map.getView();
        var oldParent = this.finger.finger.getParent();
        this.finger.finger.replaceParentSamePlace(mapView.animations);
        Game.currentGame.map.getView().scroll.runAction(
            new cc.Sequence(
                new cc.CellScrollAction(action.cellPosition, {
                    duration: Math.max(duration - 0.4, 0.05)
                }).easing(cc.easeInOut(2)),
                new cc.CallFunc(function () {
                    this.finger.finger.replaceParentSamePlace(oldParent);
                    f();
                }.bind(this))
            )
        );
    }.bind(this));

    this.finger.unPress();

    this.finger.runOnce(callback);
};

MergeCraftPlayer.prototype.moveUnit = function (action, callback) {
    var units = action.units;

    var isMergeable = units.length > 2;
    var preferredCode = isMergeable && Game.currentGame.map.getUnit(units[1].x, units[1].y) && Game.currentGame.map.getUnit(units[2].x, units[2].y)
        && Game.currentGame.map.getUnit(units[1].x, units[1].y).code === Game.currentGame.map.getUnit(units[2].x, units[2].y).code
        ? Game.currentGame.map.getUnit(units[1].x, units[1].y).code : undefined;

    var startCell = isMergeable ? this.findCell(units[0], preferredCode, units.slice(1)) : units[0];
    var startUnit = Game.currentGame.map.getUnit(startCell.x, startCell.y);
    var startUnitView = Game.currentGame.map.getView().listTiles(Map2d.LAYER_UNITS, startCell.x, startCell.y)[0];
    var startPoint = startUnitView.getPosition();

    if (!this.finger.isShown) {
        this.finger.delay(0.5);
        this.finger.fadeIn(startPoint);
        this.finger.addAction(function (f) {
            this.finger.isShown = true;
            f();
        }.bind(this));
    } else {
        this.finger.moveTo(startPoint);
    }

    this.finger.press();

    var targetUnit = undefined;
    this.finger.addAction(function (f) {
        var cell = isMergeable ? this.findCell(units[0], preferredCode, units.slice(1)) : units[0];
        targetUnit = Game.currentGame.map.getView().listTiles(Map2d.LAYER_UNITS, cell.x, cell.y)[0];
        targetUnit.handleDragStart();
        setTimeout(f, 300);
    }.bind(this));

    this.finger.addAction(function (f) {
        targetUnit.setLocalZOrder(-1);
        this.finger.scheduleUpdate();
        this.finger.update = function (unitView) {
            unitView.setPosition(this.finger.finger.x, this.finger.finger.y);

            if (this.finger.pressed) {
                var pos = Game.currentGame.map.getView().getCellByCoordinates(this.finger.finger, true);
                unitView.unit.handleDragMove(pos.x, pos.y);
            }
        }.bind(this, targetUnit);
        f();
    }.bind(this));

    var endCell = isMergeable ? this.findCell(units[1], preferredCode) : units[1];
    var endPoint = Game.currentGame.map.getView().alignInIsometricGrid(endCell.x, endCell.y);

    if (action.dragCell) {
        endPoint = cc.pAdd(endPoint, Map2dView.IsoToScreen(action.dragCell.x - endCell.x, action.dragCell.y - endCell.y));
    }

    if (isMergeable && startUnit && startUnit.stage === Families[startUnit.code].units.length - 2) {
        this.finger.addAction(function (f) {
            Game.currentGame.map.getView().scroll.scrollTo(Game.currentGame.map.getView().alignInIsometricGrid(endCell.x, endCell.y), this.finger.getMoveDuration(endPoint), { easing: cc.easeInOut(2) });
            f();
        }.bind(this));
    }
    this.finger.moveTo(endPoint);

    this.finger.unPress();

    this.finger.addAction(function (f) {
        targetUnit.setLocalZOrder(0);
        this.finger.unscheduleUpdate();

        var pos = Game.currentGame.map.getView().getCellByCoordinates(endPoint, true);
        targetUnit.unit.handleDragMove(pos.x, pos.y);
        targetUnit.unit.onMarkNormal();
        targetUnit.unit.handleDragEnd();
        f();
    }.bind(this));

    this.finger.dislodge(isMergeable ? 1 : 0.5, cleverapps.styles.MergeCraftPlayer.fingerWaitPos);
    this.finger.runOnce(callback);
};

MergeCraftPlayer.prototype.findCell = function (unit, preferredCode, exclude) {
    var searchPaths = [
        { x: 0, y: 0 }, { x: -1, y: 0 }, { x: -1, y: -1 }, { x: 0, y: -1 }, { x: 1, y: -1 },
        { x: 1, y: 0 }, { x: 1, y: 1 }, { x: 0, y: 1 }, { x: -1, y: 1 }
    ];

    for (var i = 0; i < searchPaths.length; i++) {
        var x = unit.x + searchPaths[i].x;
        var y = unit.y + searchPaths[i].y;
        if (exclude && exclude.filter(function (cell) {
            return cell.x === x && cell.y === y;
        }).length > 0) {
            continue;
        }

        var mapUnit = Game.currentGame.map.getUnit(x, y);
        if (preferredCode && mapUnit && mapUnit.code !== preferredCode) {
            continue;
        }

        var unitView = Game.currentGame.map.getView().listTiles(Map2d.LAYER_UNITS, x, y)[0];
        if (mapUnit && unitView) {
            return cc.p(x, y);
        }
    }
    return unit;
};

cleverapps.styles.MergeCraftPlayer = {
    mergeOffset: {
        x: 22,
        y: 27
    },

    fingerDislodgedPos: {
        x: 100,
        y: -100
    },

    fingerWaitPos: {
        x: 100,
        y: 50
    }
};
