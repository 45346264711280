/**
 * Created by Andrey Popov on 1/28/21.
 */

var DropDown = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.availableItems = options.availableItems;
        this.multiselect = options.multiselect;
        this.previewGenerator = options.previewGenerator;
        this.options = options;

        this.itemsLoaded = false;
        this.searchString = "";

        this.setContentSize(options.size);
        this.setAnchorPoint2();

        var styles = cleverapps.styles.DropDown;

        if (!this.options.noExpander) {
            var expander = cleverapps.UI.createSprite(bundles.editor_controls.frames.dropdown);
            expander.setPositionRound(styles.expander);
            this.addChild(expander);
        }

        cleverapps.UI.onClick(this, this.toggleDropDown.bind(this));

        this.setString(this.parseItems(options.value), true);

        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,

            onKeyPressed: function (key) {
                if (!this.dropDownContent || !this.dropDownContent.visible) {
                    return;
                }

                if (key === cc.KEY.dash || (key >= 48 && key <= 90)) {
                    this.searchString += String.fromCharCode(key).toLowerCase();
                } else if (key === cc.KEY.backspace && this.searchString.length > 0) {
                    this.searchString = this.searchString.substring(0, this.searchString.length - 1);
                }

                this.showValue();

                this.buildDropDownContent();
                this.toggleDropDown();
            }.bind(this)
        }, this);
    },

    prepareBundles: function () {
        var bundlesToLoad = [];
        (this.options.bundlesToLoad || []).forEach(function (bundleName) {
            if (bundleName.endsWith("*")) {
                bundlesToLoad.concat(Object.keys(bundles).filter(function (bundleName) {
                    return bundleName.startsWith(bundleName.slice(0, -1));
                }));
            } else {
                bundlesToLoad.push(bundleName);
            }
        });
        return cleverapps.unique(bundlesToLoad);
    },

    toggleDropDown: function () {
        if (!this.dropDownContent) {
            if (this.itemsLoaded) {
                this.buildDropDownContent();
            } else {
                this.showLoading(true);
                cleverapps.bundleLoader.loadBundles(this.prepareBundles(), {
                    onSuccess: function () {
                        this.itemsLoaded = true;
                        this.buildDropDownContent();
                        this.toggleDropDown();
                        this.showLoading(false);
                    }.bind(this)
                });
                return;
            }
        }

        if (this.dropDownContent.visible) {
            this.dropDownContent.setVisible(false);
            this.searchString = "";
            $(cc.EditBox).show();
        } else {
            var styles = cleverapps.styles.DropDown;
            var pos = cleverapps.scenes.getRunningScene().convertToNodeSpace(this.dropDownContent.convertToWorldSpace());
            if (pos.y < styles.dropUp.yThreshold) {
                this.dropDownContent.setPositionRound(this.dropDownContent.x, this.height);
            } else {
                this.dropDownContent.setPositionRound(this.dropDownContent.x, -this.dropDownContent.height);
            }

            this.dropDownContent.setVisible(true);
            $(cc.EditBox).hide();
        }
    },

    showLoading: function (visible) {
        if (this.loading) {
            this.loading.removeFromParent();
            delete this.loading;
        }

        if (visible) {
            this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
            this.loading.setScale(0.5);
            this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
            this.loading.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(this.loading);
        }
    },

    showValue: function () {
        if (this.shownValue) {
            this.shownValue.removeFromParent();
            delete this.shownValue;
        }

        if (this.searchString && this.searchString.length > 0) {
            this.shownValue = cleverapps.UI.generateOnlyText(this.searchString, cleverapps.styles.FONTS.SEARCH_DROP_DOWN_TEXT);
        } else if (this.value) {
            var stringValue = this.multiselect ? this.value.join(", ") : this.value;
            if (stringValue.length > 22) {
                stringValue = stringValue.replaceAll("landscape_", "").replaceAll("portrait_", "")
                    .replaceAll("square_", "").substring(0, 22) + "...";
            }
            this.shownValue = cleverapps.UI.generateOnlyText(stringValue, this.options.font || cleverapps.styles.FONTS.DROP_DOWN_TEXT);
            if (!this.multiselect && this.availableItems.indexOf(this.value) === -1) {
                this.shownValue.setFontFillColor(cleverapps.styles.COLORS.DARK_RED);
            }
        }

        if (this.shownValue) {
            this.shownValue.setDimensions(this.width, this.height);
            this.shownValue.setVerticalAlignment(this.options.verticalAlignment !== undefined ? this.options.verticalAlignment : cc.VERTICAL_TEXT_ALIGNMENT_TOP);
            this.shownValue.setHorizontalAlignment(this.options.horizontalAlignment !== undefined ? this.options.horizontalAlignment : cc.TEXT_ALIGNMENT_LEFT);
            this.shownValue.setAnchorPoint2();
            this.shownValue.setPositionRound(this.width / 2, this.height / 2);

            this.addChild(this.shownValue);
        }
    },

    setDelegate: function (delegate) {
        this.delegate = delegate;
    },

    getString: function () {
        return this.value;
    },

    setString: function (value, initial) {
        if (this.multiselect && !initial) {
            if (value === "SELECT ALL") {
                this.value = this.parseItems(this.availableItems);
            } else if (value === "UNSELECT ALL") {
                this.value = [];
            } else {
                var index = this.value.indexOf(value);
                if (index === -1) {
                    this.value.push(value);
                } else {
                    this.value.splice(index, 1);
                }
            }
        } else {
            this.value = value;
        }

        this.searchString = "";

        this.showValue();

        if (!initial) {
            this.toggleDropDown();
        }
    },

    buildDropDownContent: function () {
        var styles = cleverapps.styles.DropDown;

        if (this.dropDownContent) {
            this.dropDownContent.removeFromParent();
        }
        if (this.clickPastDropDown) {
            cc.eventManager.removeListener(this.clickPastDropDown);
            this.clickPastDropDown = undefined;
        }

        var dropDownWidth = this.width + (this.previewGenerator ? styles.preview.width : 0);
        var availableItems = this.parseItems(this.availableItems);
        if (this.multiselect) {
            availableItems.push("SELECT ALL");
            availableItems.push("UNSELECT ALL");
        }

        var itemNodes = availableItems.filter(function (item) {
            if (!this.searchString || this.searchString.length === 0) {
                return true;
            }
            return item.toLowerCase().indexOf(this.searchString) !== -1;
        }.bind(this)).map(function (item) {
            var preview = this.previewGenerator && this.previewGenerator(item);
            var isSelected = this.multiselect ? this.value.indexOf(item) !== -1 : item === this.value;
            var itemFont = this.options.font || (isSelected ? cleverapps.styles.FONTS.SELECTED_DROP_DOWN_TEXT : cleverapps.styles.FONTS.DROP_DOWN_TEXT);

            var itemText = cleverapps.UI.generateOnlyText(item, itemFont);

            itemText.setDimensions(dropDownWidth, 0);
            itemText.setHorizontalAlignment(this.options.horizontalAlignment !== undefined ? this.options.horizontalAlignment : cc.TEXT_ALIGNMENT_LEFT);
            var itemNode = itemText;
            if (preview) {
                cleverapps.UI.fitToBox(preview, styles.preview);
                itemText.setDimensions(dropDownWidth - styles.preview.width - styles.preview.margin, 0);
                itemText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
                itemNode = new cleverapps.Layout([preview, itemText], {
                    direction: cleverapps.UI.HORIZONTAL,
                    margin: styles.preview.margin
                });
            }
            cleverapps.UI.onClick(itemNode, function () {
                this.setString(item);
                if (this.delegate) {
                    this.delegate.editBoxEditingDidEnd(this);
                }
                if (!this.multiselect) {
                    $(cc.EditBox).show();
                }
            }.bind(this));

            return itemNode;
        }.bind(this));
        var items = new cleverapps.Layout(itemNodes, { direction: cleverapps.UI.VERTICAL, align: cleverapps.UI.ALIGN_START });

        var scrollHeight = styles.scroll.height;
        if (items.height < scrollHeight) {
            scrollHeight = items.height;
        }

        var background = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(dropDownWidth + 2 * styles.scroll.bgPadding, scrollHeight + 2 * styles.scroll.bgPadding);

        var scroll = new cleverapps.UI.ScrollView(items, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize2(dropDownWidth, scrollHeight);
        scroll.setPositionRound(background.width / 2, background.height / 2);
        background.addChild(scroll);

        var dropDownContent = this.dropDownContent = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component);
        dropDownContent.setContentSize2(dropDownWidth, background.height);

        background.setPositionRound(dropDownContent.width / 2, dropDownContent.height / 2);
        dropDownContent.addChild(background);
        dropDownContent.setVisible(false);
        dropDownContent.setAnchorPoint2(0, 0);
        dropDownContent.setPositionRound(this.width - dropDownWidth, -dropDownContent.height);
        dropDownContent.setLocalZOrder(100);

        this.addChild(dropDownContent);

        this.clickPastDropDown = cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: false,
            onTouchBegan: function (touch) {
                var ddPoint = dropDownContent.convertTouchToNodeSpace(touch);
                var clickToDropDown = cc.rectContainsPoint(cc.rect(0, 0, dropDownContent.width, dropDownContent.height), ddPoint);
                var fieldPoint = this.convertTouchToNodeSpace(touch);
                var clickToField = cc.rectContainsPoint(cc.rect(0, 0, dropDownWidth, this.height), fieldPoint);
                if (dropDownContent.visible && !clickToDropDown && !clickToField) {
                    this.toggleDropDown();
                }
                return true;
            }.bind(this)
        }, this);
    },

    parseItems: function (items) {
        return typeof items === "function" ? items() : cleverapps.clone(items);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DROP_DOWN_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    SELECTED_DROP_DOWN_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    SEARCH_DROP_DOWN_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_BLUE,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.styles.DropDown = {
    expander: {
        x: { align: "right", dx: -8 },
        y: { align: "center" }
    },

    scroll: {
        height: 500,
        bgPadding: 2
    },

    preview: {
        width: 90,
        height: 90,
        margin: 5
    },

    dropUp: {
        yThreshold: 110,
        dy: 30
    }
};