/**
 * Created by Andrey Popov on 10.11.2021
 */

var ImageSpy = function (atlasAnalyzer) {
    this.atlasAnalyzer = atlasAnalyzer;

    cleverapps.EventEmitter.call(this);

    this.atlasAnalyzer.textureImage.on("selectTextureFrame", this.showTextureFrameInfo.bind(this));
};

ImageSpy.prototype = Object.create(cleverapps.EventEmitter.prototype);
ImageSpy.prototype.constructor = ImageSpy;

ImageSpy.prototype.setFileSize = function (frame) {
    var url = "//" + location.host + (cleverapps.isLocalhost() ? "/" + cleverapps.config.name : "");
    url += "/" + frame.link;

    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var size = Math.round(xhr.getResponseHeader("Content-Length") / 100) / 10 + " kB";
            this.trigger("updateProperty", {
                propName: "Image size",
                value: frame.size.width + "x" + frame.size.height + ", " + size
            });
        }
    }.bind(this);
    xhr.send();
};

ImageSpy.prototype.showTextureFrameInfo = function (frame) {
    var textureMap = this.atlasAnalyzer.textureImage.textureMap;
    var href = "";
    if (cleverapps.isLocalhost()) {
        var url = new URL(location.href);
        url.hash = "";
        href = url.href;
    }

    var bundlesThatAddImages = Object.keys(bundles).filter(function (bundleName) {
        return bundles[bundleName].injectTo && bundles[bundleName].injectTo.indexOf(textureMap.texture.name) !== -1;
    }).map(function (bundleName) {
        return {
            name: bundleName
        };
    });

    var framesWithoutLink = textureMap.frames.filter(function (frame) {
        return !frame.link;
    });

    this.trigger("updateTextureData", {
        "Texture": {
            value: textureMap.texture.name + " " + textureMap.texture.size.width + "x" + textureMap.texture.size.height + " " + textureMap.texture.byteSize,
            link: href + bundles[textureMap.texture.name].urls.png
        },
        "Image name": {
            value: frame.name,
            onClick: function (item) {
                cleverapps.copyToClipboard(item);
            }
        },
        "Image size": {
            value: frame.size.width + "x" + frame.size.height
        },
        "Image path": {
            value: frame.link,
            link: frame.gitLink,
            errorMessage: frame.link ? undefined : "Unknown image path! Check image usage!"
        },
        "Questionable images": {
            hideIfTrue: framesWithoutLink.length === 0,
            values: framesWithoutLink,
            onClick: function (item) {
                cleverapps.atlasAnalyzer.textureImage.selectFrame(item, true);
            },
            error: true
        },
        "Bundles that add images": {
            hideIfTrue: bundlesThatAddImages.length === 0,
            values: bundlesThatAddImages,
            onClick: function (item) {
                cleverapps.copyToClipboard(item.name);
            }
        }
    });

    this.setFileSize(frame);
};
