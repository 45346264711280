/**
 * Created by andrey on 20.07.2020
 */

cleverapps.AndroidSocial = function () {
    console.log("Choosing Android Social");

    cleverapps.Social.call(this);

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.refresh.bind(this));
    setInterval(this.refresh.bind(this), cleverapps.parseInterval("10 minutes"));
};

cleverapps.AndroidSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.AndroidSocial.prototype.constructor = cleverapps.AndroidSocial;

cleverapps.AndroidSocial.prototype.init = function (callback) {
    this.onInitCallback = cleverapps.once(callback);
    this.socialInitializing = cleverapps.AndroidSocial.CODE;

    AndroidSocialPlugin.init && AndroidSocialPlugin.init();
};

cleverapps.AndroidSocial.prototype.onInit = function () {
    console.log("AndroidSocial.onInit");
    delete this.socialInitializing;

    if (this.onInitCallback) {
        this.onInitCallback();
        delete this.onInitCallback;

        console.log("AndroidSocial.onInit callback execute");
    }
};

cleverapps.AndroidSocial.prototype.refresh = function () {
    AndroidSocialPlugin.refresh && AndroidSocialPlugin.refresh();
};

cleverapps.AndroidSocial.prototype.isLoggedIn = function () {
    return AndroidSocialPlugin.isLoggedIn && AndroidSocialPlugin.isLoggedIn();
};

cleverapps.AndroidSocial.prototype.getUserID = function () {
    var id = AndroidSocialPlugin.getUserID && AndroidSocialPlugin.getUserID();
    if (id) {
        return "android_" + id;
    }
};

cleverapps.AndroidSocial.isAndroidUserId = function (id) {
    return id && id.indexOf("android_") === 0;
};

cleverapps.AndroidSocial.prototype.getAccessToken = function () {
    var accessToken = AndroidSocialPlugin.getAccessToken && AndroidSocialPlugin.getAccessToken();
    if (accessToken && cleverapps.config.androidStaging) {
        accessToken = "androidStaging_" + accessToken;
    }
    return accessToken;
};

cleverapps.AndroidSocial.prototype.onLogin = function (code) {
    if (this.onLoginListener) {
        this.onLoginListener(code);
        delete this.onLoginListener;
    }
};

cleverapps.AndroidSocial.prototype.login = function (onSuccess, onFailure) {
    this.onLoginListener = function (code) {
        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.SocialManager.onLogin();
            onSuccess();
        } else {
            cleverapps.SocialManager.onFailedLogin();
            onFailure && onFailure();
        }
    };
    AndroidSocialPlugin.login && AndroidSocialPlugin.login();
};

cleverapps.AndroidSocial.prototype.aboutMe = function (callback) {
    if (!AndroidSocialPlugin.aboutMe) {
        return;
    }

    var data = AndroidSocialPlugin.aboutMe();
    data = decodeWebViewData(data);
    data = JSON.parse(data);

    if (data && data.picture && data.picture.data && data.picture.data.url) {
        data.picture.data.url = decodeWebViewData(data.picture.data.url);
    }

    console.log("AndroidSocial.aboutMe - " + JSON.stringify(data));

    callback(data);
};

cleverapps.AndroidSocial.prototype.getCode = function () {
    return cleverapps.AndroidSocial.CODE;
};

cleverapps.AndroidSocial.isAvailable = function () {
    return cc.sys.isNative && cc.sys.os === cc.sys.OS_ANDROID && cleverapps.platform.oneOf(AndroidPlatform, GPG);
};

cleverapps.AndroidSocial.CODE = "androidsocial";
