/**
 * Created by andrey on 27.11.2020.
 */

var AdminDebugButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.AdminDebugButton;

        this.icon = new cc.Sprite(bundles.admin.frames.release_icon);

        this._super({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: this.icon,
            width: styles.width,
            height: styles.height,
            onClicked: this.onPressed.bind(this),
            hint: "Toggle debug"
        });

        this.adminLevels = cleverapps.administrator.adminLevels;
        this.adminEpisodes = cleverapps.administrator.adminEpisodes;

        this.adminEpisodes.on("setActive", this.onChangeEpisode.bind(this), this);
        this.adminLevels.on("reset", this.update.bind(this), this);
        this.update();
    },

    onPressed: function () {
        cleverapps.meta.display({
            focus: "ToggleDebug",
            action: function (f) {
                var bundle = this.adminEpisodes.getActive().getBundle();

                cleverapps.administrator.adminEpisodes.setDebug(!bundle.debug, function () {
                    this.update();

                    f();
                }.bind(this));
            }.bind(this)
        });
    },

    onChangeEpisode: function (episode) {
        if (this.episodeUpdateHandler) {
            this.episodeUpdateHandler.clear();
        }

        this.episodeUpdateHandler = episode.on("change:debug", this.update.bind(this), this);
    },

    update: function () {
        var episode = this.adminEpisodes.getActive();
        if (!episode || !episode.getBundle()) {
            this.visible = false;
            return;
        }

        this.visible = true;
        this.icon.setSpriteFrame(bundles.admin.frames[episode.debug ? "debug_icon" : "release_icon"]);

        if (episode.debug === AdminEpisode.DEBUG_DISABLED) {
            this.disable();
        } else {
            this.enable();
        }
    }
});

cleverapps.styles.AdminDebugButton = {
    width: 45,
    height: 45
};