/**
 * Created by Andrey Popov on 31.07.2023.
 */
var ActiveClipScene = cleverapps.FixedWidthScene.extend({
    ctor: function (options) {
        this.options = options;
        cleverapps.flags.noTransitionAnimation = true;

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_WYSIWYG);

        this.frameCapture = this.options.clipConfig.properties.frameCapture;
        this.caption = this.options.clipConfig.properties.caption;

        this.clipPreviewBar = new ClipPreviewBar(this.options);
        if (!this.options.generate && !this.options.download) {
            var barView = new ClipPreviewBarView(this.clipPreviewBar);
            this.addChild(barView);
        }

        cleverapps.debugStats.setEnabled(false);
        CocosTransitions.init();

        this.run(this.options);
    },

    run: function (options) {
        var player = new ActionPlayer();

        cleverapps.styles.SCENE_MIN_WIDTH = 600;
        cleverapps.styles.SCENE_MAX_WIDTH = 30000;

        Resolution.setupDesignResolution(ActiveClipScene.calculateSize(options.resolutionName, !options.download && !options.generate));

        player.add(function (f) {
            if (bundles["language_" + options.language]) {
                cleverapps.bundleLoader.loadBundle("language_" + options.language);
            }

            cleverapps.settings.setLanguage(options.language);
            if (cleverapps.loadedSnapshot) {
                cleverapps.snapshots.importData(cleverapps.loadedSnapshot);
            }
            f = cleverapps.wait(2, f);
            cleverapps.wysiwyg.loadExtraBundles(f);
            Messages.preload(options.language, f);
        });

        player.add(function (f) {
            cleverapps.audio.unmute();
            cc.audioEngine.stopAllEffects();

            var music = options.clipConfig.properties.music;
            if (!options.generate && music && music.bundle) {
                cleverapps.audio.playSound(bundles[music.bundle].urls[music.sound]);
            }

            f();
        });

        this.clipSceneNodes = [];
        options.scenes.forEach(function (sceneConfig, clipSceneIndex) {
            if (clipSceneIndex > 0) {
                player.add(cleverapps.Recorder.pause);
            }

            player.add(this.runClipScene.bind(this, clipSceneIndex, sceneConfig));
        }.bind(this));

        player.add(this.stopRecording.bind(this));

        player.play();

        if (options.clipConfig.properties.cutOff) {
            var endCardSceneIndex = options.scenes.length - 1;
            var endCardScene = options.scenes[endCardSceneIndex];

            this.cutOffTimeout = setTimeout(function () {
                if (this.clipSceneNodes.length === options.scenes.length || endCardSceneIndex < 1) {
                    return;
                }

                player.stop();
                this.runClipScene(endCardSceneIndex, endCardScene, function () {
                    cleverapps.Recorder.stop(this.stopRecording.bind(this));
                }.bind(this));
            }.bind(this), ActiveClipScene.STANDART_CLIP_DURATION - endCardScene.properties.duration);
        }
    },

    stopRecording: function (f) {
        var onFinish = function (f) {
            this.clipSceneNodes.forEach(function (clipScene) {
                clipScene.destroy();
                clipScene.removeFromParent();
            });

            var window = cleverapps.windows.currentWindow();
            if (window) {
                window.close();
            }

            if (this.options.onFinish) {
                setTimeout(this.options.onFinish, 1000);
            }

            f && f();
        }.bind(this);

        if (this.cutOffTimeout) {
            clearTimeout(this.cutOffTimeout);
            delete this.cutOffTimeout;
        }

        this.clipPreviewBar.stopTimer();
        cleverapps.audio.mute();
        cleverapps.audio.eject();
        
        if (this.frameCapture && !this.frameCapture.video && this.frameCapture.frequency > 0 && this.frameCapture.amount > 0) {
            setTimeout(function () {
                cleverapps.Recorder.stop(function () {
                    if (this.options.download) {
                        cleverapps.Recorder.screenshot(this.caption);
                    }
                    onFinish(f);
                }.bind(this));
            }.bind(this), this.frameCapture.frequency * 1000 * this.frameCapture.amount);
        } else {
            cleverapps.Recorder.stop(function () {
                if (this.options.download) {
                    cleverapps.Recorder.saveVideo(this.caption);
                }
                onFinish(f);
            }.bind(this));
        }
    },

    runClipScene: function (clipSceneIndex, sceneConfig, f) {
        var runningScene = this;       
        var sceneTransition = this.options.clipConfig.properties.sceneTransition;        
        if (sceneTransition && cc["Transition" + sceneTransition]) {
            var newScene = new cc.Scene();
            newScene.scene = cleverapps.scenes.getRunningScene().scene;
            newScene.onTransitionAnimationDidStart = newScene.isReadyToResize = newScene.updateSize = newScene.updatePosition = function () {};
                        
            cc.director.runScene(new cc["Transition" + sceneTransition](this.options.clipConfig.properties.transitionTime / 1000, newScene));
            var transitionSound = this.options.clipConfig.properties.transitionSound;
            if (transitionSound) {
                cleverapps.audio.playSound(bundles[transitionSound.bundle].urls[transitionSound.sound]);
            }
            runningScene = newScene;
        }

        var clipSceneNode = new ClipScene(sceneConfig, {
            size: Wysiwyg.VIDEO_SIZES[this.options.resolutionName],
            onInit: function () {
                this.clipPreviewBar.resetTimer();
                DateShift.hideTime();

                if (clipSceneIndex > 0) {
                    cleverapps.Recorder.resume();
                } else if (this.options.generate || this.options.download) {
                    var params = {};                                                       
                    if (this.frameCapture && this.frameCapture.frequency > 0) {
                        params.frameCapture = Object.assign({}, this.frameCapture, {
                            frequency: this.frameCapture.frequency * 1000,
                            amount: this.frameCapture.amount,
                            path: this.options.path.replace(this.caption, this.options.resolutionName) + this.options.language + "/",
                            name: this.caption,
                            filenamePrefix: this.frameCapture.filenamePrefix
                        });
                    }

                    cleverapps.Recorder.start(params);
                }
            }.bind(this),
            onComplete: f
        });

        if (clipSceneIndex > 0 && this.clipSceneNodes[clipSceneIndex - 1] && this.clipSceneNodes[clipSceneIndex - 1].snapshotComponent && clipSceneNode.snapshotComponent) {
            this.clipSceneNodes[clipSceneIndex - 1].destroy();
            this.clipSceneNodes[clipSceneIndex - 1].removeFromParent();

            Game.currentGame = undefined;
        }

        clipSceneNode.setPositionRound(runningScene.width / 2, runningScene.height / 2);
        clipSceneNode.setScale(runningScene.width / clipSceneNode.width);
        runningScene.addChild(clipSceneNode);

        this.clipSceneNodes.push(clipSceneNode);
    },

    getAudioStyles: function () {
        return {
            res: undefined,
            delay: 0
        };
    },

    listBundles: function () {
        return ["wysiwyg", "admin"];
    }
});

ActiveClipScene.calculateSize = function (res, isPreview) {
    var resolution = Wysiwyg.VIDEO_SIZES[res];
    var width = resolution.width;
    var height = resolution.height;

    if (isPreview) {
        var windowSize = Resolution.getFrameSize();
        var scale = Math.min(windowSize.width / width, windowSize.height / height, 1);
        width *= scale;
        height *= scale;
    }

    return cc.size(width, height);
};

ActiveClipScene.open = function (options) {
    var scene = cc.director.getRunningScene();
    if (scene.clipSceneNodes) {
        scene.clipSceneNodes.forEach(function (clipScene) {
            clipScene.destroy();
        });
    }

    cleverapps.config.wysiwygMode = true;
    cleverapps.config.nologin = true;

    cleverapps.wysiwyg.wysiwygPreview.selectedResolution = options.resolutionName;

    cleverapps.meta.display({
        focus: "activeclip",
        action: function (f) {
            var scene = new ActiveClipScene(options);
            cleverapps.scenes.replaceScene(scene, f);
        }
    });
};

ActiveClipScene.STANDART_CLIP_DURATION = 30000;

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!cleverapps.config.debugMode || !params.clipId || params.wysiwyg) {
        return;
    }

    cleverapps.DataLoader.resetClientSession = function () {
        cleverapps.DataLoader.alerted = true;
        cleverapps.DataLoader.enabled = false;
        cleverapps.DataLoader.corrupted = false;
    };

    cleverapps.Synchronizer.prototype._sync = function () {
        cleverapps.Synchronizer.prototype._sync = function () {};
        cleverapps.flags.nologin = true;
    };

    Resolution.__getFrameSize = Resolution.getFrameSize;
    setTimeout(function () {
        var frameSize = ActiveClipScene.calculateSize(params.res || "landscape_1920x1080", !params.download && !params.generate);
        Resolution.getFrameSize = function () {
            return frameSize;
        };
    }, 0);

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (returnClassOnly) {
            return ActiveClipScene;
        }

        if (!cleverapps.wysiwyg) {
            cleverapps.wysiwyg = new Wysiwyg(params.directory);
        }
        var wysiwyg = cleverapps.wysiwyg;
        wysiwyg.hierarchy.loadClip(params.clipId, function () {
            var clip = wysiwyg.hierarchy.getSelectedClip();

            wysiwyg.prepareScenes(clip, function (scenes) {
                f();

                Resolution.getFrameSize = Resolution.__getFrameSize;
                delete Resolution.__getFrameSize;
                
                ActiveClipScene.open({
                    clipConfig: clip,
                    scenes: scenes,
                    language: params.lang || "en",
                    resolutionName: params.res || clip.properties.resolutions[0],
                    download: params.download,
                    path: ""
                });
            });
        });
    };
}());