/**
 * Created by Andrey Popov on 27.01.2021
 */

var AdsSprite = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint2();

        this.disableHeightChange = true;

        properties = properties || {};

        var resource;
        if (properties.image && properties.image.bundle
            && bundles[properties.image.bundle]
            && (bundles[properties.image.bundle].frames[properties.image.image] || bundles[properties.image.bundle].urls[properties.image.image])) {
            resource = {
                bundle: properties.image.bundle,
                resource: properties.image.image
            };
        } else if (properties.gameImage && properties.gameImage.bundle
            && bundles[properties.gameImage.bundle] && bundles[properties.gameImage.bundle].frames[properties.gameImage.resource]) {
            resource = properties.gameImage;
        }

        if (resource) {
            var sprite = cleverapps.UI.createSprite(bundles[resource.bundle].frames[resource.resource] || bundles[resource.bundle].urls[resource.resource]);
            var scale = 1;
            var width = properties.width;
            var height = properties.height;

            if (width) {
                scale = width / sprite.width;
            }

            if (height) {
                scale = Math.min(scale, height / sprite.height);
            }

            sprite.setScale(scale);
            this.addChild(sprite);

            this.setContentSize(sprite.width * scale, sprite.height * scale);
            sprite.setPositionRound(this.width / 2, this.height / 2);

            if (properties.flipX) {
                sprite.setScaleX(-1 * scale);
            }
            if (properties.flipY) {
                sprite.setScaleY(-1 * scale);
            }

            properties.rotation && sprite.setRotation(properties.rotation);
        }
    }
});

AdsSprite.PROPERTIES = [{
    name: "image", type: "image", bundles: ["ads_menu", "ads_screenshot_merge", "ads_frames", "ads_window", "screenshots", "ads_sprites"], value: { image: "cat_0", bundle: "ads_menu" }, bubbleUp: true
}, {
    name: "gameImage",
    resourceType: "sprite",
    type: "resource"
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}, {
    name: "scale", type: "number", disabled: true
}];