/**
 * Created by spepa on 20.12.2022
 */

var PeriodicSaleLogic = function (mission, isNewMission) {
    this.mission = mission;

    if (isNewMission) {
        cleverapps.forces.clearForce(Forces.PERIODIC_SALE.id);
    }
};

PeriodicSaleLogic.prototype.getLots = function () {
    if (this.isBonusSale()) {
        var packs = PeriodicSaleLogic.BONUS_LOTS;
        
        var payerClass = this.mission.details.payer;
        if (payerClass === undefined) {
            payerClass = cleverapps.paymentsHistory.classify();
            if (payerClass === cleverapps.PaymentsHistory.BRACKET_UNDECIDED) {
                payerClass = cleverapps.PaymentsHistory.BRACKET_NONE;
            }
            payerClass = Math.min(payerClass, packs.length - 2);
            this.mission.update(0, { payer: payerClass });
        }

        return packs.slice(payerClass, payerClass + 2);
    }

    return PeriodicSaleLogic.LOTS[cleverapps.travelBook.getCurrentPage().id];
};

PeriodicSaleLogic.prototype.isSoldLot = function (lotId) {
    return this.mission.result & (1 << lotId);
};

PeriodicSaleLogic.prototype.countSoldLots = function () {
    return this.getLots().filter(function (lot) {
        return this.isSoldLot(lot.id);
    }.bind(this)).length;
};

PeriodicSaleLogic.prototype.isAllSold = function () {
    return this.getLots().length === this.countSoldLots();
};

PeriodicSaleLogic.prototype.lotBought = function (lotId) {
    var newValue = this.mission.result ^ (1 << lotId);
    var delta = newValue - this.mission.result;
    this.mission.update(delta);
};

PeriodicSaleLogic.prototype.isBonusSale = function () {
    var event = cleverapps.eventManager.getFeatureEvent(this.mission.feature);
    return Boolean(event && event.options.bonusSale);
};

PeriodicSaleLogic.prototype.isAvailable = function () {
    return !cleverapps.flags.videoAdsMainMonetization;
};

PeriodicSaleLogic.FindProductLot = function (productId) {
    var sale = cleverapps.missionManager.findByType(Mission.TYPE_PERIODIC_SALE);
    if (sale && sale.isAvailable() && sale.isRunning() && sale.logic.isBonusSale()) {
        var lots = sale.logic.getLots();
        var hasEnergy = lots.some(function (lot) {
            return lot.reward.energy;
        });

        if (!hasEnergy || cleverapps.travelBook.getCurrentPage().withEnergy()) {
            return lots.find(function (lot) {
                return lot.product === productId && !sale.logic.isSoldLot(lot.id);
            });
        }
    }
};

PeriodicSaleLogic.NeedTileDecor = function (tileModel) {
    return PeriodicSaleLogic.FindProductLot(tileModel.product.key) !== undefined;
};

PeriodicSaleLogic.addProductBonus = function (product) {
    var lot = PeriodicSaleLogic.FindProductLot(product.key);
    if (lot) {
        cleverapps.missionManager.findByType(Mission.TYPE_PERIODIC_SALE).logic.lotBought(lot.id);
        return Product.AddUpRewards(product.reward, cleverapps.clone(lot.reward));
    }
};

PeriodicSaleLogic.LOTS = {
    main: [
        {
            id: 0, price: 129, oldPrice: 500, reward: { units: { code: "herochest", stage: 2, amount: 5 } }
        },
        {
            id: 1, price: 249, oldPrice: 1000, reward: { units: { code: "crystal", stage: 3, amount: 5 } }
        },
        {
            id: 2, price: 499, oldPrice: 2000, reward: { units: { code: "wands", stage: 3, amount: 5 } }
        }
    ]
};

PeriodicSaleLogic.BONUS_LOTS = [
    {
        id: 0, product: "gold500", profit: "+42%", reward: { hard: 20, soft: 100, energy: 100 }
    },
    {
        id: 1, product: "gold1800", profit: "+32%", reward: { hard: 100, soft: 300, energy: 200 }
    },
    {
        id: 2, product: "gold3800", profit: "+37%", reward: { hard: 300, soft: 800, energy: 400 }
    },
    {
        id: 3, product: "gold6000", profit: "+39%", reward: { hard: 500, soft: 1500, energy: 600 }
    },
    {
        id: 4, product: "gold25000", profit: "+43%", reward: { hard: 3000, soft: 7000, energy: 1000 }
    }
];