/**
 * Created by spepa on 24.04.2023
 */

var BonusSaleLot = cc.Scale9Sprite.extend({
    ctor: function (lot, logic) {
        this._super(bundles.periodic_sale.frames.tile_bg, cleverapps.UI.getScale9Rect(bundles.periodic_sale.frames.tile_bg, cleverapps.UI.Scale9Rect.TwoPixelXY));
        this.setContentSize2(cleverapps.styles.BonusSaleLot);

        this.lot = lot;
        this.logic = logic;
        this.product = Product.Create(this.lot.product);

        this.createContent();
        this.clickHandler = cleverapps.UI.onClick(this, this.buyProduct.bind(this));
        this.updateState();
    },

    createContent: function () {
        var styles = cleverapps.styles.BonusSaleLot.content;

        var content = new cleverapps.Layout([
            this.createRewards(),
            this.createButton()
        ], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        content.setAnchorPoint(0.5, 0);
        content.setPositionRound(styles);
        this.addChild(content, 3);

        this.createIcon(content);
    },

    createIcon: function (content) {
        var styles = cleverapps.styles.BonusSaleLot.content.icon;

        var icon = new cc.Sprite(bundles.periodic_sale.frames[this.product.icon]);
        var sparkle = new cleverapps.Spine(bundles.periodic_sale.jsons.shop_sparkles_large_json);
        sparkle.setPosition(icon.width / 2, icon.height / 2);
        sparkle.setAnimation(0, "animation", true);
        icon.addChild(sparkle);

        var rewardBg = cleverapps.UI.createScale9Sprite(bundles.periodic_sale.frames.reward_title, cleverapps.UI.Scale9Rect.TwoPixelXY);
        rewardBg.setContentSize2(styles.reward.bg);
        rewardBg.setPositionRound(styles.reward.bg);
        icon.addChild(rewardBg);

        var reward = new RewardsListComponent(this.product.reward, {
            columns: 1,
            noPrefix: true,
            small: true,
            font: cleverapps.styles.FONTS.BONUSSALE_PRODUCT_REWARD,
            textDirection: cleverapps.UI.HORIZONTAL
        });
        reward.setPositionRound(styles.reward);
        rewardBg.addChild(reward);

        icon.setPositionRound(this.width / 2, content.y + content.height * (1 - content.anchorY) + styles.offset + icon.height / 2);
        this.addChild(icon);
    },

    createRewards: function () {
        var styles = cleverapps.styles.BonusSaleLot.reward;

        var rewards = new RewardsListComponent(this.lot.reward, {
            columns: 1,
            noPrefix: true,
            small: true,
            font: cleverapps.styles.FONTS.BONUSSALE_REWARDS,
            textDirection: cleverapps.UI.HORIZONTAL,
            margin: {
                x: styles.colMargin,
                y: styles.rowMargin
            },
            icons: {
                energy: bundles.reward_icons_merge.frames.energy_icon
            }
        });

        var bg = cleverapps.UI.createScale9Sprite(bundles.periodic_sale.frames.rewards_bg_png, { x: 0.5, y: 0.7 });
        bg.setContentSize2(styles.bg);
        rewards.setPositionRound(bg.width / 2 + styles.offsetX, bg.height / 2 + styles.offsetY);
        bg.addChild(rewards);

        return bg;
    },

    createButton: function () {
        var styles = cleverapps.styles.BonusSaleLot.button;
        this.button = new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            onClicked: this.buyProduct.bind(this),
            width: cleverapps.styles.BonusSaleLot.button.width,
            height: cleverapps.styles.BonusSaleLot.button.height
        });

        var badge = new cc.Sprite(bundles.periodic_sale.frames.sale_badge_png);
        var text = cleverapps.UI.generateOnlyText(this.lot.profit + "\n" + Messages.get("FREE"), cleverapps.styles.FONTS.BONUSSALE_BADGE);
        text.fitTo(styles.badge.text.width);
        text.setPositionRound(styles.badge.text);
        text.setRotation(styles.badge.text.rotation);
        badge.addChild(text);
        badge.setPositionRound(styles.badge);
        this.button.addChild(badge);

        Lottery.addIcon(this.button);
        return this.button;
    },

    buyProduct: function () {
        if (!this.logic.isSoldLot(this.lot.id)) {
            this.product.buy(function (success) {
                if (success) {
                    this.updateState();
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPENT.PERIODIC_LOT + this.lot.id);
                }
            }.bind(this));
        }
    },

    updateState: function () {
        if (this.logic.isSoldLot(this.lot.id)) {
            this.button.disable();
            this.button.setString(Messages.get("SoldOut"));

            if (this.clickHandler) {
                this.clickHandler.remove();
                this.clickHandler = undefined;
            }

            if (this.button.lotteryIcon) {
                this.button.lotteryIcon.removeFromParent();
            }
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUSSALE_REWARDS_TITLE: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUSSALE_REWARDS: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        size: 40
    },

    BONUSSALE_BADGE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW,
        size: 23
    },

    BONUSSALE_PRODUCT_REWARD: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 50
    }
});

cleverapps.styles.BonusSaleLot = {
    width: 390,
    height: 670,

    content: {
        margin: 20,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 15 },

        icon: {
            offset: 90,

            reward: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 10 },

                bg: {
                    width: 412,
                    height: 115,

                    x: { align: "center", dx: 3 },
                    y: { align: "bottom", dy: -110 }
                }
            }
        }
    },

    button: {
        width: 260,
        height: 95,
        badge: {
            x: { align: "right", dx: 90 },
            y: { align: "center", dy: 15 },
            text: {
                x: { align: "center", dx: 12 },
                y: { align: "center", dy: 5 },
                rotation: 32,

                width: 90
            }
        }
    },

    reward: {
        offsetY: -50,
        offsetX: -20,

        colMargin: 0,
        rowMargin: -10,

        bg: {
            width: 279,
            height: 300
        }
    }
};