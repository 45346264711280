/**
 * Created by vladislav on 23/03/2023
 */

var PageScrollView = cleverapps.UI.ScrollView.extend({
    ctor: function (items, options) {
        var direction = options.direction === cleverapps.UI.ScrollView.DIR_VERTICAL
            ? cleverapps.UI.VERTICAL
            : cleverapps.UI.HORIZONTAL;

        this.page = options.page || 0;

        this.items = items;
        this.onChangePage = options.onChangePage;

        var content = new cleverapps.Layout(items, {
            direction: direction
        });

        if (this.getPagesAmount() < 2) {
            this.touchScrollDisabled = true;
        }

        this._super(content, Object.assign(options, {
            scrollBarEnabled: false,
            scrollHandler: PageScrollHandler
        }));

        this.pageSize = items[0].getContentSize();

        this.setContentSize(this.pageSize);

        this.scrollTo(this.items[this.page]);
    },

    getPagesAmount: function () {
        return this.items.length;
    }
});

var PageScrollHandler = function (target, options) {
    cleverapps.UI.ScrollHandler.call(this, target, options);
};

PageScrollHandler.prototype = Object.create(cleverapps.UI.ScrollHandler.prototype);
PageScrollHandler.prototype.constructor = PageScrollHandler;

PageScrollHandler.prototype.handleRelease = function () {
    this.inTouch = false;

    var pos = this.target.getContainerPosition();

    var page;
    if (this.target.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL) {
        var x = pos.x + (this.target.innerContent.width - this.target.width) / 2;
        page = cleverapps.clamp(Math.round(x / this.target.pageSize.width), 0, this.target.items.length - 1);
    } else {
        var y = pos.y + (this.target.innerContent.height - this.target.height) / 2;
        page = cleverapps.clamp(Math.round(y / this.target.pageSize.height), 0, this.target.items.length - 1);
    }

    this.target.page = page;

    this.target.scrollTo(this.target.items[page], 0.3, {
        easing: cc.easeIn(2),
        callback: function () {
            if (this.target.onChangePage) {
                this.target.onChangePage(page);
            }
        }.bind(this)
    });
};