/**
 * Created by andrey on 19.12.19.
 */

cleverapps.NoPayments = function () {
    cleverapps.Payments.call(this);
};

cleverapps.NoPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.NoPayments.prototype.constructor = cleverapps.NoPayments;

cleverapps.NoPayments.isAppropriate = function () {
    return cleverapps.platform.oneOf(Huzcom, Pliega, Rustore, IOSCh, PWA, GPG)
        || cleverapps.platform.oneOf(AndroidPlatform) && !cleverapps.WebViewPayments.isAppropriate()
        || cleverapps.platform.oneOf(Mobage, SPMobage) && ["mbga.jp:63100202", "sb.mbga.jp:63100202"].includes(cleverapps.user.id);
};

cleverapps.NoPayments.prototype.isAvailable = function () {
    return false;
};
