/**
 * Created by denis on 22.01.20.
 */

var DailyLevelWindow = Window.extend({
    onWindowLoaded: function (options) {
        this.options = options;
        var styles = cleverapps.styles.DailyLevelWindow;

        this._super({
            title: "DailyLevelWindow.title",
            name: "dailylevelwindow",
            content: this.getContent(),
            styles: cleverapps.styles.DailyLevelWindow.window,
            button: {
                text: "Play",
                width: styles.button.width,
                height: styles.button.height,
                onPressed: function () {
                    this.close();
                }.bind(this)
            }
        });
    },

    getPerson: function () {
        return "hero";
    },

    getContent: function () {
        var styles = cleverapps.styles.DailyLevelWindow;

        if (bundles.game.frames.label_50_ok_png) {
            var label = new cc.Sprite(bundles.game.frames.label_50_ok_png);
            label.rotation = -15;
        }

        var info = this.createText();

        return new cleverapps.Layout(
            [label, info],
            {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.contentObjectsMargin,
                padding: { x: 0, y: 0 }
            }
        );
    },

    createText: function () {
        var styles = cleverapps.styles.DailyLevelWindow;

        var info = cleverapps.UI.generateTTFText("DailyLevelWindow.text", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT, {
            left: cleverapps.dailyLevel.levelsToPass
        });
        info.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        info.setDimensions(cc.size(styles.textWidth, 0));
        return info;
    }
});

cleverapps.styles.DailyLevelWindow = {
    textWidth: 600,
    contentObjectsMargin: 50,
    button: {
        width: 200,
        height: 100
    }
};
