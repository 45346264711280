/**
 * Created by Andrey Popov on 30.06.2021
 */

var AdsQuestionnaire = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.setAnchorPoint2();
        this.properties = properties;
        this.eventBus = eventBus;

        this.stage = -1;

        if (!properties.isPreview) {
            if (properties.delay) {
                setTimeout(this.write.bind(this), properties.delay);
            } else {
                this.eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.write.bind(this), this);
            }
        }

        this.setScale(this.properties.scale || 1);
    },

    write: function () {
        this.stage++;

        var hand = this.getHand();
        var clipView = this.getParent().getParent();

        this.getTexts().forEach(function (nestedComponent, index) {
            nestedComponent.replaceParentSamePlace(clipView);
            if (!hand) {
                nestedComponent.visible = this.stage > index;
            }
        }.bind(this));

        if (this.stage === 0) {
            this.finger = new FingerViewEx(this.properties.finger ? { image: bundles[this.properties.finger.bundle].frames[this.properties.finger.image] } : {});
            this.addChild(this.finger);
            this.finger.setLocalZOrder(999);
            this.finger.fadeIn({
                x: this.width,
                y: 0
            });
        }

        if (hand) {
            hand.replaceParentSamePlace(clipView);
            this.finger.visible = false;
        }

        if (!this.properties.isPreview) {
            this.finger.addAction(function (f) {
                if (this.stage === 0) {
                    clipView.runAction(new cc.Spawn(
                        new cc.MoveBy(0.5, 0, -clipView.height * 0.02),
                        new cc.ScaleTo(0.5, 1.2)
                    ));
                } else {
                    clipView.runAction(new cc.MoveBy(0.8, 0, clipView.height * 0.05));
                }
                f();
            }.bind(this));
        }

        var fingerOffsetX = 0;
        var fingerOffsetY = 0;
        if (this.properties.fingerOffsetsX && this.properties.fingerOffsetsX.split(",").length > 0) {
            fingerOffsetX = -parseInt(this.properties.fingerOffsetsX.split(",")[this.stage]) - clipView.width / 2;
        }
        if (this.properties.fingerOffsetsY && this.properties.fingerOffsetsY.split(",").length > 0) {
            fingerOffsetY = parseInt(this.properties.fingerOffsetsY.split(",")[this.stage]) - clipView.height / 2;
        }

        var text = this.getTexts()[this.stage];

        this.finger.addAction(function (f) {
            var textPos = text.getPosition();
            var position = {
                x: hand ? (textPos.x - fingerOffsetX) : (textPos.x - text.width / 2 - fingerOffsetX),
                y: textPos.y + fingerOffsetY
            };
            var duration = this.finger.getMoveDuration(position);

            this.finger.runAction(new cc.Sequence(
                new cc.MoveTo(duration, position),
                new cc.CallFunc(function () {
                    f();
                })
            ));

            if (hand) {
                hand.spine.setAnimation(0, "idle", true);
                hand.runAction(new cc.MoveTo(duration, position));
            }
        }.bind(this));

        this.finger.addAction(function (f) {
            if (this.properties.writeSound) {
                cleverapps.audio.playSound(bundles[this.properties.writeSound.bundle].urls[this.properties.writeSound.sound]);
            }

            if (!hand) {
                text.visible = true;
                text.title.byLetterAnimation({ speed: this.properties.writingCharDelay });
            }

            setTimeout(function () {
                if (this.stage < this.getTexts().length - 1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.PROGRESS_CHANGED, (this.stage + 1) / this.getTexts().length);
                    this.write();
                } else {
                    if (this.finger) {
                        this.finger.removeFromParent();
                        this.finger = undefined;
                    }

                    if (hand) {
                        hand.runAction(new cc.MoveBy(0.8, 200, -500));
                    }

                    this.eventBus.trigger(Wysiwyg.EVENTS.PROGRESS_CHANGED, 1);

                    setTimeout(function () {
                        if (this.properties.isPreview) {
                            this.stage = -1;
                            this.write();
                        } else {
                            this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
                        }
                    }.bind(this), 2000);
                }
            }.bind(this), 1000);
            f();
        }.bind(this));

        this.finger.addAction(function (f) {
            var textPos = text.getPosition();
            var position = {
                x: hand ? (textPos.x - fingerOffsetX) : (textPos.x + Math.max(text.width, 100) / 2 - fingerOffsetX),
                y: textPos.y + fingerOffsetY
            };
            var duration = this.finger.getMoveDuration(position);

            this.finger.runAction(new cc.Sequence(
                new cc.MoveTo(duration, position),
                new cc.CallFunc(function () {
                    f();
                })
            ));

            if (hand) {
                hand.spine.setAnimationAndIdleAfter("text_" + (this.stage + 1), "idle");
            }
        }.bind(this));

        this.finger.runOnce();
    },

    getTexts: function () {
        return this.nestedComponents.filter(function (component) {
            return component instanceof AdsText;
        });
    },

    getHand: function () {
        return this.nestedComponents.filter(function (component) {
            return component instanceof AdsSpine;
        })[0];
    }
});

AdsQuestionnaire.PROPERTIES = [{
    name: "writeSound", type: "sound", bundles: ["ads_sfx_common", "ads_background_music"], value: { bundle: "ads_sfx_common" }
}, {
    name: "fingerOffsetsX", type: "string"
}, {
    name: "fingerOffsetsY", type: "string"
}, {
    name: "writingCharDelay", type: "number", value: 0.05
}];