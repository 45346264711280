/**
 * Created by andrey on 20.07.2020
 */

cleverapps.SelectSocial = function () {
    console.log("Choosing SelectSocial");

    this.socials = {};

    cleverapps.Social.call(this);
};

cleverapps.SelectSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.SelectSocial.prototype.constructor = cleverapps.SelectSocial;

cleverapps.SelectSocial.prototype.onInit = function () {
    console.log("SelectSocial.onInit - " + this.socialInitializing);
    if (this.socialInitializing) {
        var social = this.socials[this.socialInitializing];
        delete this.socialInitializing;
        social.onInit.apply(social, arguments);
    }
};

cleverapps.SelectSocial.prototype.init = function (callback) {
    this.initSocials();

    var callback1 = function () {
        for (var key in this.socials) {
            var social = this.socials[key];
            if (social.isLoggedIn()) {
                cleverapps.social = social;
                console.log("Change social to " + key);
                break;
            }
        }

        callback();
    }.bind(this);

    var socials = Object.keys(this.socials);

    var nextInit = function () {
        if (socials.length === 0) {
            callback1();
            return;
        }

        var social = this.socialInitializing = socials.shift();
        this.socials[social].initialize(nextInit);
    }.bind(this);

    nextInit();
};

cleverapps.SelectSocial.prototype.initSocials = function () {
    var isTmpId = cleverapps.user.haveTmpId();
    var isAndroidId = cleverapps.AndroidSocial.isAndroidUserId(cleverapps.user.id);
    var isAppleId = cleverapps.AppleSocial.isAppleUserId(cleverapps.user.id);
    var isFBId = !isTmpId && !isAndroidId && !isAppleId;

    if (isTmpId && cleverapps.SelectSocial.isMobileFBAvailable() || isFBId) {
        this.socials.facebook = cleverapps.SelectSocial.selectMobileFB();
    }

    if (isTmpId && cleverapps.AndroidSocial.isAvailable() || isAndroidId) {
        this.socials.androidsocial = new cleverapps.AndroidSocial();
    }

    if (isTmpId && cleverapps.AppleSocial.isAvailable() || isAppleId) {
        this.socials.applesocial = new cleverapps.AppleSocial();
    }

    console.log("SelectSocial.initSocials - " + cleverapps.user.id + " - " + Object.keys(this.socials).join(","));
};

cleverapps.SelectSocial.isMobileFBAvailable = function () {
    return true;
};

cleverapps.SelectSocial.selectMobileFB = function () {
    return cc.sys.os === cc.sys.OS_ANDROID ? new cleverapps.AndroidWebViewFacebook()
        : new cleverapps.IOSWebViewFacebook();
};

cleverapps.SelectSocial.prototype.onLogin = function (code) {
    if (this.socialLogining) {
        var social = this.socials[this.socialLogining];
        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.social = social;
            console.log("Change social to " + this.socialLogining);
        }

        delete this.socialLogining;
        social.onLogin.apply(social, arguments);
    }
};

cleverapps.SelectSocial.prototype.login = function (onSuccess, onFailure, options) {
    this.socialLogining = options.source;
    var social = this.socials[this.socialLogining];
    social.login(onSuccess, onFailure);
};

cleverapps.SelectSocial.prototype.getCode = function () {
    return Object.keys(this.socials).sort().join("-");
};
