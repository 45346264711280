/**
 * Created by iamso on 07.10.2019.
 */

var MissionRulesOptionsBuilder = {
    build: function (mission) {
        var json = cleverapps.skins.getSlot("missionRulesWindow", {
            bundle: this.bundle,
            type: mission.type
        });

        return {
            name: mission.getName() + "GuideWindow",
            stagesContent: function () {
                return this.createSpineStages(json || bundles[this.bundleName].jsons.rules_json);
            }
        };
    }
};
