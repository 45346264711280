/**
 * Created by slava on 4/21/17.
 */

if (typeof exports !== "undefined") {
    var cleverapps = require("../utils");
    if (typeof window !== "undefined" && window.CRMClientApp) {
        cleverapps.config.source = undefined;
    } else {
        var Context = require("../../common/context");
        cleverapps.config = Context.getConfig();
    }

    // eslint-disable-next-line no-unused-vars
    var bundles = {
        reward_icons: {
            frames: {}
        }
    };

    var RewardsConfig = {
        Packs: []
    };

    var LivesProductTile;
    var PackProductTile;
    var SubscriptionTileModel;
    var SubscriptionTile;
    var GrowthFundTile;
    var GrowthFundTileModel;
    var BuyLivesTileModel;
    var PiggyBank, PackManager, GrowthFund, Offer;
}

cleverapps.PaymentsManager = {
    init: function () {
        if (cleverapps.platform.oneOf(Mobage, SPMobage)) {
            Object.assign(cleverapps.PaymentsManager.PRODUCTS, cleverapps.PaymentsManager.MBGA_COIN_PRODUCTS);
        }

        var products = cleverapps.clone(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS, true);
        Object.assign(products, cleverapps.clone(cleverapps.PaymentsManager.PRODUCTS, true));
        cleverapps.override(products, cleverapps.config.products || {});
        Object.keys(products).forEach(function (productId) {
            if (products[productId] === false) {
                delete products[productId];
            }
        });
        cleverapps.config.products = products;

        var payments = null;

        if (cleverapps.NoPayments.isAppropriate()) {
            payments = new cleverapps.NoPayments();
        } else if (YooKassaWebPayments.isAppropriate()) {
            payments = new YooKassaWebPayments();
        } else if (YooKassaPayments.isAppropriate()) {
            payments = new YooKassaPayments();
        } else if (cleverapps.IOSPayments.isAppropriate()) {
            payments = new cleverapps.IOSPayments();
        } else if (cleverapps.WebViewPayments.isAppropriate()) {
            payments = new cleverapps.WebViewPayments();
        } else if (XsollaPayments.isAppropriate()) {
            payments = new XsollaPayments();
        } else if (cleverapps.TestPayments.isAppropriate()) {
            payments = new cleverapps.TestPayments();
        } else if (cleverapps.InstantPayments.isAppropriate()) {
            payments = new cleverapps.InstantPayments();
        } else if (cleverapps.SPMobagePayments.isAppropriate()) {
            payments = new cleverapps.SPMobagePayments();
        } else if (cleverapps.OKPayments.isAppropriate()) {
            payments = new cleverapps.OKPayments();
        } else if (cleverapps.MobileMyMailRuPayments.isAppropriate()) {
            payments = new cleverapps.MobileMyMailRuPayments();
        } else if (cleverapps.MyMailRuPayments.isAppropriate()) {
            payments = new cleverapps.MyMailRuPayments();
        } else if (cleverapps.MobileVkPayments.isAppropriate()) {
            payments = new cleverapps.MobileVkPayments();
        } else if (cleverapps.VKPayments.isAppropriate()) {
            payments = new cleverapps.VKPayments();
        } else if (cleverapps.MobagePayments.isAppropriate()) {
            payments = new cleverapps.MobagePayments();
        } else if (cleverapps.FotoStranaPayments.isAppropriate()) {
            payments = new cleverapps.FotoStranaPayments();
        } else if (cleverapps.DraugiemPayments.isAppropriate()) {
            payments = new cleverapps.DraugiemPayments();
        } else if (cleverapps.YandexPayments.isAppropriate()) {
            payments = new cleverapps.YandexPayments();
        } else if (cleverapps.MicrosoftPayments.isAppropriate()) {
            payments = new cleverapps.MicrosoftPayments();
        } else if (cleverapps.PlingaPayments.isAppropriate()) {
            payments = new cleverapps.PlingaPayments();
        } else if (cleverapps.KongregatePayments.isAppropriate()) {
            payments = new cleverapps.KongregatePayments();
        } else if (cleverapps.FacebookPayments.isAppropriate()) {
            payments = new cleverapps.FacebookPayments();
        } else if (cleverapps.MygamesPayments.isAppropriate()) {
            payments = new cleverapps.MygamesPayments();
        }

        return payments;
    },

    switch: function () {
        if (cleverapps.payments && cleverapps.payments.stop) {
            cleverapps.payments.stop();
        }

        cleverapps.payments = cleverapps.PaymentsManager.init();
        cleverapps.payments.initialize();
        PaymentOverlay.initialize();

        cleverapps.flags.videoAdsMainMonetization = false;
        cleverapps.flags.update();
    }
};

cleverapps.PaymentsManager.DEPRECATED_PRODUCTS = {};

cleverapps.PaymentsManager.PRODUCTS = {};
cleverapps.PaymentsManager.MBGA_COIN_PRODUCTS = {};

cleverapps.PaymentsManager.initializeProducts = function () {
    Object.assign(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS, {
        pack500: { // deprecated since 22.06.2022
            projects: ["mergecraft", "wondermerge"],
            price: 5.99,
            image: false
        },

        pack2100: { // deprecated since 22.06.2022
            projects: ["mergecraft", "wondermerge"],
            price: 25.99,
            image: false
        },

        pack14000: { // deprecated since 22.06.2022
            projects: ["mergecraft", "wondermerge"],
            price: 129.99,
            image: false
        },

        pack3800: { // deprecated since 22.06.2022
            projects: ["mergecraft", "wondermerge"],
            price: 25.99,
            image: false
        },

        pack25000: { // deprecated since 22.06.2022
            projects: ["mergecraft", "wondermerge"],
            price: 129.99,
            image: false
        },

        addMoves: { // deprecated since 25.04.2023
            projects: ["heroes", "riddles", "spades", "tripeaks"],
            title: "Shop.AddMoves.Title",
            description: "Shop.AddMoves.Description",
            boughtMessage: "Shop.AddMoves.BoughtMessage",
            price: 0.99,
            restoreReward: {
                hard: 200
            }
        },

        addMoves1: { // deprecated since 25.04.2023
            projects: ["adventure", "heroes", "riddles", "runes", "spades", "tripeaks"],
            title: "Shop.AddMoves1.Title",
            description: "Shop.AddMoves1.Description",
            boughtMessage: "Shop.AddMoves.BoughtMessage",
            price: 0.99,
            moves: 5,
            bonus: 1,
            restoreReward: {
                hard: 200
            }
        },

        addMoves2: { // deprecated since 25.04.2023
            projects: ["adventure", "heroes", "riddles", "runes", "spades", "tripeaks"],
            title: "Shop.AddMoves2.Title",
            description: "Shop.AddMoves2.Description",
            boughtMessage: "Shop.AddMoves.BoughtMessage",
            price: 1.99,
            moves: 10,
            bonus: 1,
            restoreReward: {
                hard: 400
            }
        },

        addMoves3: { // deprecated since 25.04.2023
            projects: ["adventure", "heroes", "riddles", "runes", "spades", "tripeaks"],
            title: "Shop.AddMoves3.Title",
            description: "Shop.AddMoves3.Description",
            boughtMessage: "Shop.AddMoves.BoughtMessage",
            price: 2.99,
            moves: 15,
            bonus: 1,
            restoreReward: {
                hard: 600
            }
        },

        addMoves5: { // deprecated since 25.04.2023
            projects: ["adventure", "heroes", "riddles", "runes", "spades", "tripeaks"],
            title: "Shop.AddMoves5.Title",
            description: "Shop.AddMoves5.Description",
            boughtMessage: "Shop.AddMoves.BoughtMessage",
            price: 4.99,
            moves: 15,
            bonus: 2,
            restoreReward: {
                hard: 1000
            }
        }
    });

    if (cleverapps.config.name === "tripeaks") {
        cleverapps.override(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS, {
            addMoves: {
                id: {
                    yandex: "addMoves"
                },
                image: "/res/fbproducts/addmoves0.png"
            },

            addMoves1: {
                price: 1.49
            }
        });
    }

    if (["riddles", "tripeaks", "heroes", "adventure", "runes"].indexOf(cleverapps.config.name) !== -1) {
        cleverapps.override(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS, {
            addMoves1: {
                id: {
                    yandex: "addMoves1"
                },
                image: "/res/fbproducts/addmoves.png"
            },

            addMoves2: {
                id: {
                    yandex: "addMoves2"
                },
                image: "/res/fbproducts/addmoves.png"
            },

            addMoves3: {
                id: {
                    yandex: "addMoves3"
                },
                image: "/res/fbproducts/addmoves.png"
            },

            addMoves5: {
                id: {
                    yandex: "addMoves5"
                },
                image: "/res/fbproducts/addmoves.png"
            }
        });
    }

    if (cleverapps.config.type === "match3") {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            buyExp10: {
                title: "Shop.BuyExp10.Title",
                description: "Shop.BuyExp10.Description",
                boughtMessage: "Shop.BuyExp10.BoughtMessage",
                price: 9.99,
                reward: {
                    exp: 10
                }
            },
            buyExp150: {
                title: "Shop.BuyExp150.Title",
                description: "Shop.BuyExp150.Description",
                boughtMessage: "Shop.BuyExp150.BoughtMessage",
                price: 99.99,
                reward: {
                    exp: 150
                }
            }
        });
    }

    if (!["merge", "tile3", "none"].includes(cleverapps.config.type)) {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            piggybank500: {
                title: "Shop.PiggyBank1.Title",
                description: "Shop.PiggyBank1.Description",
                price: 2.99,
                restoreLogic: PiggyBank,
                reward: {
                    hard: 500
                }
            },
            piggybank1800: {
                title: "Shop.PiggyBank2.Title",
                description: "Shop.PiggyBank2.Description",
                price: 9.99,
                restoreLogic: PiggyBank,
                reward: {
                    hard: 1800
                }
            },
            piggybank6000: {
                title: "Shop.PiggyBank3.Title",
                description: "Shop.PiggyBank3.Description",
                price: 29.99,
                restoreLogic: PiggyBank,
                reward: {
                    hard: 6000
                }
            },
            piggybank25000: {
                title: "Shop.PiggyBank4.Title",
                description: "Shop.PiggyBank4.Description",
                price: 99.99,
                restoreLogic: PiggyBank,
                reward: {
                    hard: 25000
                }
            }
        });
    }

    if (["merge", "tile3"].includes(cleverapps.config.type)) {
        Object.assign(cleverapps.PaymentsManager.MBGA_COIN_PRODUCTS, {
            coins350: {
                id: {},
                title: "Shop.Coins350.Title",
                description: "Shop.Coins350.Description",
                price: 1.99,
                reward: {
                    soft: 350
                },
                icon: "shop_coins_very_small_png",
                image: "/res/fbproducts/soft_currency_very_small_200.png?v=2",
                boughtMessage: "Product.SoftCurrencyBought"
            },
            coins1100: {
                id: {},
                title: "Shop.Coins1100.Title",
                description: "Shop.Coins1100.Description",
                price: 3.99,
                reward: {
                    soft: 1100
                },
                icon: "shop_coins_small_png",
                image: "/res/fbproducts/soft_currency_small_200.png?v=2",
                boughtMessage: "Product.SoftCurrencyBought"
            },
            coins3000: {
                id: {},
                title: "Shop.Coins3000.Title",
                description: "Shop.Coins3000.Description",
                price: 7.99,
                reward: {
                    soft: 3000
                },
                icon: "shop_coins_medium_png",
                image: "/res/fbproducts/soft_currency_medium_200.png?v=2",
                boughtMessage: "Product.SoftCurrencyBought"
            },
            coins7000: {
                id: {},
                title: "Shop.Coins7000.Title",
                description: "Shop.Coins7000.Description",
                price: 17.99,
                reward: {
                    soft: 7000
                },
                icon: "shop_coins_large_png",
                image: "/res/fbproducts/soft_currency_large_200.png?v=2",
                boughtMessage: "Product.SoftCurrencyBought"
            }
        });
    }

    if (cleverapps.config.type === "merge") {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            expeditionPack: {
                title: "Shop.expeditionPack.title",
                description: "Shop.expeditionPack.description",
                boughtMessage: "Shop.expeditionPack.boughtMessage",
                price: 9.99,
                restoreLogic: Offer,
                restoreReward: {
                    hard: 1200
                }
            },

            growthFund: {
                title: "Shop.growthFund.title",
                description: "Shop.growthFund.description",
                boughtMessage: "Shop.growthFund.boughtMessage",
                value: "800%",
                icon: "growth_fund_icon",
                mainSceneOnly: true,
                price: 24.99,
                clanGift: true,
                restoreLogic: GrowthFund,
                restoreReward: {
                    hard: 2800
                },
                TileModelClass: GrowthFundTileModel,
                ViewClassName: GrowthFundTile
            }
        });
    }

    if (cleverapps.config.lives && !["tile3", "none"].includes(cleverapps.config.type)) {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            unlimitedLives: {
                title: "UnlimitedLives.Title",
                description: "UnlimitedLives.Description",
                boughtMessage: "UnlimitedLivesBought",
                image: "/res/fbproducts/unlimitedlives.png",
                price: 2.99,
                reward: {
                    unlimitedLives: "3 days"
                },
                icon: "unlimitedLives_png",
                animation: "animation",
                ViewClassName: LivesProductTile,
                TileModelClass: BuyLivesTileModel
            }
        });
    }

    if (!["none"].includes(cleverapps.config.type)) {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            gold500: {
                title: "Shop.Gold500.Title",
                description: "Shop.Gold500.Description",
                price: 2.99,
                reward: {
                    hard: 500
                },
                icon: "shop_gold_very_small_png"
            },
            gold1800: {
                title: "Shop.Gold1800.Title",
                description: "Shop.Gold1800.Description",
                price: 9.99,
                oldPrice: 1.99,
                label: 1800,
                reward: {
                    hard: 1800
                },
                icon: "shop_gold_small_png"
            },
            gold3800: {
                title: "Shop.Gold3800.Title",
                description: "Shop.Gold3800.Description",
                price: 19.99,
                reward: {
                    hard: 3800
                },
                icon: "shop_gold_medium_png"
            },
            gold6000: {
                title: "Shop.Gold6000.Title",
                description: "Shop.Gold6000.Description",
                price: 29.99,
                clanGift: true,
                oldPrice: 5.99,
                label: 6000,
                reward: {
                    hard: 6000
                },
                icon: "shop_gold_large_png"
            },
            gold25000: {
                title: "Shop.Gold25000.Title",
                description: "Shop.Gold25000.Description",
                price: 99.99,
                clanGift: true,
                reward: {
                    hard: 25000
                },
                icon: "shop_gold_huge_png"
            },

            starterPack0: {
                title: "StarterPack0.Title",
                description: "StarterPack0.Description",
                boughtMessage: "StarterPack.Bought",
                boughtMessageParams: {
                    title: "StarterPack0.Title"
                },
                restoreLogic: PackManager,
                reward: RewardsConfig.Packs[0],
                stage: 0,
                mainSceneOnly: true,
                price: 0.99,
                available: {
                    level: 2.65
                }
            },
            starterPack: {
                title: "StarterPack1.Title",
                description: "StarterPack1.Description",
                boughtMessage: "StarterPack.Bought",
                boughtMessageParams: {
                    title: "StarterPack1.Title"
                },
                restoreLogic: PackManager,
                reward: RewardsConfig.Packs[1],
                stage: 1,
                mainSceneOnly: true,
                price: 4.99,
                available: {
                    level: 4.65
                }
            },
            starterPack2: {
                title: "StarterPack2.Title",
                description: "StarterPack2.Description",
                boughtMessage: "StarterPack.Bought",
                boughtMessageParams: {
                    title: "StarterPack2.Title"
                },
                restoreLogic: PackManager,
                reward: RewardsConfig.Packs[2],
                stage: 2,
                mainSceneOnly: true,
                price: 29.99,
                available: {
                    level: 6.65
                }
            }
        });
    }

    if (!["none"].includes(cleverapps.config.type)) {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            subsWeek: {
                title: "Shop.SubsWeek.Title",
                description: "Shop.SubsWeek.Description",
                boughtMessage: "Shop.SubsWeek.BoughtMessage",
                icon: "prem_tile_icon",
                price: 9.99,
                clanGift: true,
                trial: "1 day",
                period: "2 weeks",
                type: "subscription",
                projects: ["riddles", "heroes", "wordsoup", "crocword", "olympics", "tripeaks", "scramble", "magicwords", "mergecraft", "wondermerge", "fairy", "runes", "differences"]
            },
            subsMonth: {
                title: "Shop.SubsMonth.Title",
                description: "Shop.SubsMonth.Description",
                boughtMessage: "Shop.SubsMonth.BoughtMessage",
                icon: "prem_tile_icon",
                price: 14.99,
                clanGift: true,
                period: "1 month",
                type: "subscription",
                projects: ["riddles", "heroes", "wordsoup", "crocword", "olympics", "tripeaks", "scramble", "magicwords", "mergecraft", "wondermerge", "fairy", "runes", "differences"],
                TileModelClass: SubscriptionTileModel,
                ViewClassName: SubscriptionTile
            },

            // TEST staging OK subscriptions
            subsWeekTest: {
                title: "Shop.SubsWeek.Title",
                description: "Shop.SubsWeek.Description",
                boughtMessage: "Shop.SubsWeek.BoughtMessage",
                icon: "prem_tile_icon",
                price: 0.05,
                trial: "1 day",
                period: "2 weeks",
                type: "subscription",
                projects: ["riddles", "heroes", "wordsoup", "crocword", "fairy"],
                image: "/res/fbproducts/subsweek.png"
            },
            subsMonthTest: {
                title: "Shop.SubsMonth.Title",
                description: "Shop.SubsMonth.Description",
                boughtMessage: "Shop.SubsMonth.BoughtMessage",
                icon: "prem_tile_icon",
                price: 0.1,
                period: "1 month",
                type: "subscription",
                projects: ["riddles", "heroes", "wordsoup", "crocword", "fairy"],
                image: "/res/fbproducts/subsmonth.png"
            }
        });
    }

    if (["mergecraft", "wondermerge", "riddles", "tripeaks", "heroes", "scramble", "wordsoup", "crocword", "olympics", "magicwords", "adventure", "runes", "differences"].indexOf(cleverapps.config.name) !== -1) {
        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.gold500, {
            id: {
                facebook: "500gold.html",
                android: "500gold",
                ios: "500gold",
                instant: "500gold",
                yandex: "500gold"
            },
            image: "/res/fbproducts/gold2_200.png?v=3"
        });

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.gold1800, {
            id: {
                facebook: "1800gold.html",
                android: "1800gold",
                ios: "1800gold",
                instant: "1800gold",
                yandex: "gold1800"
            },
            image: "/res/fbproducts/gold3_200.png?v=2"
        });

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.gold3800, {
            id: {
                facebook: "3800gold.html",
                android: "3800gold",
                ios: "3800gold",
                instant: "3800gold",
                yandex: "gold3800"
            },
            image: "/res/fbproducts/heap_gold.png?v=4"
        });

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.gold6000, {
            id: {
                facebook: "6000gold.html",
                android: "6000gold",
                ios: "6000gold",
                instant: "6000gold",
                yandex: "gold6000"
            },
            image: "/res/fbproducts/gold4_200.png?v=1"
        });

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.gold25000, {
            id: {
                facebook: "25000gold.html",
                android: "25000gold",
                ios: "25000gold",
                instant: "25000gold",
                yandex: "gold25000"
            },
            image: "/res/fbproducts/gold5_200.png?v=1"
        });

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS, {
            starterPack0: {
                id: {
                    yandex: "StarterPack0"
                },
                image: "/res/fbproducts/starter_pack0.png?v=1"
            },
            starterPack: {
                id: {
                    yandex: "starterPack"
                },
                image: "/res/fbproducts/starter_pack.png?v=1"
            },
            starterPack2: {
                id: {
                    yandex: "starterPack2"
                },
                image: "/res/fbproducts/starter_pack2.png?v=1"
            }
        });

        if (cleverapps.config.type === "merge") {
            cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.expeditionPack, {
                image: "/res/fbproducts/expedition_pack.png"
            });

            cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.growthFund, {
                image: "/res/fbproducts/growth_fund.png"
            });
        }

        if (!["merge", "tile3"].includes(cleverapps.config.type)) {
            cleverapps.override(cleverapps.PaymentsManager.PRODUCTS, {
                piggybank500: {
                    image: "/res/fbproducts/piggy_bank.png?v=1"
                },
                piggybank1800: {
                    image: "/res/fbproducts/piggy_bank.png?v=1"
                },
                piggybank6000: {
                    image: "/res/fbproducts/piggy_bank.png?v=1"
                },
                piggybank25000: {
                    image: "/res/fbproducts/piggy_bank.png?v=1"
                }
            });
        }

        if (cleverapps.PaymentsManager.PRODUCTS.unlimitedLives) {
            cleverapps.override(cleverapps.PaymentsManager.PRODUCTS.unlimitedLives, {
                id: {
                    yandex: "unlimitedLives"
                }
            });
        }

        cleverapps.override(cleverapps.PaymentsManager.PRODUCTS, {
            subsWeek: {
                image: "/res/fbproducts/premium.png"
            },
            subsMonth: {
                image: "/res/fbproducts/premium.png"
            },
            subsWeekTest: {
                image: "/res/fbproducts/premium.png"
            },
            subsMonthTest: {
                image: "/res/fbproducts/premium.png"
            }
        });
    }

    if (cleverapps.config.name !== "spades" && (["match3", "solitaire"].indexOf(cleverapps.config.type) !== -1 || cleverapps.config.subtype === "stacks")) {
        Object.assign(cleverapps.PaymentsManager.PRODUCTS, {
            sweetPack: {
                title: "TileShop.sweetPack.Title",
                description: "TileShop.sweetPack.Description",
                image: "/res/fbproducts/sweet_pack_200.png",
                boughtMessage: "Shop.sweetPack.boughtMessage",
                price: 5.99,
                reward: {
                    hard: 500,
                    boosters: {
                        0: 1,
                        1: 1
                    }
                },
                icon: "pack500_png",
                ViewClassName: PackProductTile
            },

            jamPack: {
                title: "TileShop.jamPack.Title",
                description: "TileShop.jamPack.Description",
                image: "/res/fbproducts/jam_pack_200.png",
                boughtMessage: "Shop.jamPack.boughtMessage",
                price: 12.99,
                reward: {
                    hard: 1800,
                    boosters: {
                        0: 1,
                        1: 1,
                        2: 1
                    }
                },
                icon: "pack1800_png",
                ViewClassName: PackProductTile
            },

            tastyPack: {
                title: "TileShop.tastyPack.Title",
                description: "TileShop.tastyPack.Description",
                image: "/res/fbproducts/tasty_pack_200.png?v=1",
                boughtMessage: "Shop.tastyPack.boughtMessage",
                price: 25.99,
                reward: {
                    hard: 3800,
                    boosters: {
                        0: 3,
                        1: 3,
                        2: 3
                    }
                },
                icon: "pack3800_png",
                ViewClassName: PackProductTile
            },

            honeyPack: {
                title: "TileShop.honeyPack.Title",
                description: "TileShop.honeyPack.Description",
                image: "/res/fbproducts/honey_pack_200.png?v=1",
                boughtMessage: "Shop.honeyPack.boughtMessage",
                price: 39.99,
                reward: {
                    hard: 6000,
                    boosters: {
                        0: 5,
                        1: 5,
                        2: 5
                    }
                },
                icon: "pack6000_png",
                ViewClassName: PackProductTile
            },

            epicPack: {
                title: "TileShop.epicPack.Title",
                description: "TileShop.epicPack.Description",
                image: "/res/fbproducts/epic_pack_200.png?v=1",
                boughtMessage: "Shop.epicPack.boughtMessage",
                price: 129.99,
                reward: {
                    hard: 25000,
                    boosters: {
                        0: 20,
                        1: 20,
                        2: 20
                    }
                },
                icon: "pack25000_png",
                ViewClassName: PackProductTile
            }
        });
    }

    if (["merge", "tile3"].includes(cleverapps.config.type)) {
        cleverapps.PaymentsManager.PRODUCTS.gold500.reward = { hard: 200 };
        cleverapps.PaymentsManager.PRODUCTS.gold1800.reward = { hard: 900 };
        cleverapps.PaymentsManager.PRODUCTS.gold3800.reward = { hard: 2100 };
        cleverapps.PaymentsManager.PRODUCTS.gold6000.reward = { hard: 3500 };
        cleverapps.PaymentsManager.PRODUCTS.gold25000.reward = { hard: 14000 };
    }

    if (cleverapps.config.type === "merge") {
        cleverapps.PaymentsManager.PRODUCTS.starterPack0.available = {
            level: 6.2
        };
        cleverapps.PaymentsManager.PRODUCTS.starterPack.available = {
            level: 8.2
        };
        cleverapps.PaymentsManager.PRODUCTS.starterPack2.available = {
            level: 10.2
        };
    }

    if (cleverapps.config.type === "solitaire" && cleverapps.config.name !== "spades") {
        cleverapps.PaymentsManager.PRODUCTS.sweetPack.reward.boosters = { 10: 2, 11: 2, 12: 2 };
        cleverapps.PaymentsManager.PRODUCTS.jamPack.reward.boosters = { 10: 3, 11: 3, 12: 3 };
        cleverapps.PaymentsManager.PRODUCTS.tastyPack.reward.boosters = { 10: 5, 11: 5, 12: 5 };
        cleverapps.PaymentsManager.PRODUCTS.honeyPack.reward.boosters = { 10: 7, 11: 7, 12: 7 };
        cleverapps.PaymentsManager.PRODUCTS.epicPack.reward.boosters = { 10: 30, 11: 30, 12: 30 };
    }

    if (cleverapps.config.type === "match3") {
        cleverapps.PaymentsManager.PRODUCTS.sweetPack.reward.boosters = { 5: 1, 6: 1, 7: 1 };
        cleverapps.PaymentsManager.PRODUCTS.jamPack.reward.boosters = { 5: 2, 6: 2, 7: 2 };
        cleverapps.PaymentsManager.PRODUCTS.tastyPack.reward.boosters = {
            0: 3, 1: 3, 5: 3, 6: 3, 7: 3
        };
        cleverapps.PaymentsManager.PRODUCTS.honeyPack.reward.boosters = {
            0: 5, 1: 5, 5: 5, 6: 5, 7: 5
        };
        cleverapps.PaymentsManager.PRODUCTS.epicPack.reward.boosters = {
            0: 18, 1: 18, 5: 18, 6: 18, 7: 18
        };
    }

    if (["android", "ios", "macos"].indexOf(cleverapps.config.source) !== -1) {
        cleverapps.PaymentsManager.PRODUCTS.subsWeek.price = 4.99;
        cleverapps.PaymentsManager.PRODUCTS.subsWeek.trial = "3 days";
        cleverapps.PaymentsManager.PRODUCTS.subsWeek.period = "1 week";
        cleverapps.PaymentsManager.PRODUCTS.subsWeek.title = "Shop.SubsWeek.ThreeDays.Title";
        cleverapps.PaymentsManager.PRODUCTS.subsWeek.description = "Shop.SubsWeek.ThreeDays.Description";
    }

    if (cleverapps.config.name === "scramble") {
        cleverapps.PaymentsManager.PRODUCTS.sweetPack.reward.boosters = { 13: 2, 14: 2, 15: 2 };
        cleverapps.PaymentsManager.PRODUCTS.jamPack.reward.boosters = { 13: 3, 14: 3, 15: 3 };
        cleverapps.PaymentsManager.PRODUCTS.tastyPack.reward.boosters = { 13: 5, 14: 5, 15: 5 };
        cleverapps.PaymentsManager.PRODUCTS.honeyPack.reward.boosters = { 13: 7, 14: 7, 15: 7 };
        cleverapps.PaymentsManager.PRODUCTS.epicPack.reward.boosters = { 13: 30, 14: 30, 15: 30 };
    }

    if (cleverapps.config.name === "mergecraft") {
        Object.assign(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS, { // deprecated since 31.07.2023
            gold1: {
                title: "Shop.Gold1.Title",
                description: "Shop.Gold1.Description",
                price: 0.99,
                reward: {
                    hard: 65
                },
                icon: "shop_gold_tiny_png",
                id: {
                    android: "1gold",
                    ios: "1gold",
                    instant: "1gold"
                },
                image: "/res/fbproducts/gold1_200.png?v=3"
            },
            gold5: {
                title: "Shop.Gold5.Title",
                description: "Shop.Gold5.Description",
                price: 4.99,
                reward: {
                    hard: 455
                },
                icon: "shop_gold_very_small_png",
                id: {
                    android: "5gold",
                    ios: "5gold",
                    instant: "5gold"
                },
                image: "/res/fbproducts/gold2_200.png?v=3"
            },
            gold10: {
                title: "Shop.Gold10.Title",
                description: "Shop.Gold10.Description",
                price: 9.99,
                reward: {
                    hard: 1000
                },
                icon: "shop_gold_small_png",
                id: {
                    android: "10gold",
                    ios: "10gold",
                    instant: "10gold"
                },
                image: "/res/fbproducts/gold3_200.png?v=2"
            },
            gold20: {
                title: "Shop.Gold20.Title",
                description: "Shop.Gold20.Description",
                price: 19.99,
                reward: {
                    hard: 2220
                },
                icon: "shop_gold_medium_png",
                id: {
                    android: "20gold",
                    ios: "20gold",
                    instant: "20gold"
                },
                image: "/res/fbproducts/heap_gold.png?v=4"
            },
            gold50: {
                title: "Shop.Gold50.Title",
                description: "Shop.Gold50.Description",
                price: 49.99,
                reward: {
                    hard: 7145
                },
                icon: "shop_gold_large_png",
                id: {
                    android: "50gold",
                    ios: "50gold",
                    instant: "50gold"
                },
                image: "/res/fbproducts/gold4_200.png?v=1"
            },
            gold100: {
                title: "Shop.Gold100.Title",
                description: "Shop.Gold100.Description",
                price: 99.99,
                reward: {
                    hard: 14285
                },
                icon: "shop_gold_huge_png",
                id: {
                    android: "100gold",
                    ios: "100gold",
                    instant: "100gold"
                },
                image: "/res/fbproducts/gold5_200.png?v=1"
            }
        });
    }

    Object.keys(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS).forEach(function (key) {
        var data = cleverapps.PaymentsManager.DEPRECATED_PRODUCTS[key];

        if (data.projects && data.projects.indexOf(cleverapps.config.name) === -1) {
            delete cleverapps.PaymentsManager.DEPRECATED_PRODUCTS[key];
        }
    });

    Object.keys(cleverapps.PaymentsManager.PRODUCTS).forEach(function (key) {
        var data = cleverapps.PaymentsManager.PRODUCTS[key];

        if (["klondike", "woodenblock", "tetris"].indexOf(cleverapps.config.name) !== -1) {
            delete cleverapps.PaymentsManager.PRODUCTS[key];
            return;
        }

        if (data.projects && data.projects.indexOf(cleverapps.config.name) === -1) {
            delete cleverapps.PaymentsManager.PRODUCTS[key];
        }
    });

    var MyMailRuIds = {
        buyExp10: 1,
        buyExp150: 2,

        addMoves1: 8,
        addMoves2: 9,
        addMoves3: 10,
        addMoves5: 11,

        piggybank500: 12,
        piggybank1800: 13,
        piggybank6000: 14,
        piggybank25000: 15,

        addMoves: 20,

        gold500: 21,
        gold1800: 22,
        gold3800: 39,
        gold6000: 23,
        gold25000: 24,

        starterPack0: 25,
        starterPack: 26,
        starterPack2: 27,

        subsWeek: 31,
        subsMonth: 32,
        subsWeekTest: 33,
        subsMonthTest: 34,

        sweetPack: 35,
        jamPack: 36,
        tastyPack: 40,
        honeyPack: 37,
        epicPack: 38,

        unlimitedLives: 64,

        growthFund: 73,
        expeditionPack: 74,

        coins350: 75,
        coins1100: 76,
        coins3000: 77,
        coins7000: 78
    };

    Object.keys(cleverapps.PaymentsManager.PRODUCTS).concat(Object.keys(cleverapps.PaymentsManager.MBGA_COIN_PRODUCTS), Object.keys(cleverapps.PaymentsManager.DEPRECATED_PRODUCTS)).forEach(function (key) {
        var data = cleverapps.PaymentsManager.MBGA_COIN_PRODUCTS[key] || cleverapps.PaymentsManager.PRODUCTS[key] || cleverapps.PaymentsManager.DEPRECATED_PRODUCTS[key];

        if (["macos", "ios"].indexOf(cleverapps.config.source) !== -1) {
            if (data.price >= 100) {
                data.price = 99.99;
            }
        }

        data.key = key;
        data.id = data.id || {};

        var platforms = ["android", "ios", "instant", "yandex"];

        if (data.type === "subscription") {
            platforms.push("ok");
        }

        platforms.forEach(function (platform) {
            var id = key.toLowerCase();
            if (data.id[platform] === undefined) {
                data.id[platform] = id;
            }
        });

        if (data.id.facebook === undefined) {
            data.id.facebook = key.toLowerCase() + ".html";
        }

        if (MyMailRuIds[key]) {
            data.id.mymailru = MyMailRuIds[key];
            data.id.mbga = MyMailRuIds[key];
        }

        if (data.image === undefined) {
            data.image = "/res/fbproducts/" + key.toLowerCase() + ".png";
        }
    });
};

cleverapps.PaymentsManager.getProductId = function (itemId, source) {
    var product = cleverapps.PaymentsManager.PRODUCTS[itemId] || cleverapps.PaymentsManager.DEPRECATED_PRODUCTS[itemId];
    if (!product) {
        return;
    }

    if (["android", "instant", "yandex", "ios"].includes(source)) {
        return product.id[source];
    }

    switch (source) {
        case "facebook":
            return product.id.facebook.replace(".html", "");
        case "mbga":
        case "sp_mbga":
            return product.id.mbga;
        case "fotostrana":
        case "web_mm":
        case "mobile_mm":
            return product.id.mymailru;
    }

    return itemId;
};

if (typeof exports !== "undefined") {
    cleverapps.PaymentsManager.initializeProducts();
    module.exports = cleverapps.PaymentsManager;
}
