/**
 * Created by iamso on 20.12.2021
 */

var AdjustPluginMS = {
    init: function(appToken, environment) {
        console.log("MicrosoftAdjustPluginAdapter init");
        MicrosoftNotifier(JSON.stringify({
            handler: 'adjust',
            method: 'init',
            appToken: appToken,
            environment: environment
        }));
    },

    logPurchase: function(eventToken, transactionID, amount, currency) {
        console.log("MicrosoftAdjustPluginAdapter logPurchase ", amount, currency);

        // cleverapps.throwAsync({ group: 'webview2', message: 'before AdjustPlugin.logPurchase ' + amount + ' ' + currency });

        MicrosoftNotifier(JSON.stringify({
            handler: 'adjust',
            method: 'logPurchase',
            eventToken: eventToken,
            amount: amount,
            currency: currency
        }));
    },

    logEvent: function(eventToken, amount, currency) {
        console.log("MicrosoftAdjustPluginAdapter logEvent");
        MicrosoftNotifier(JSON.stringify({
            handler: 'adjust',
            method: 'logEvent',
            eventToken: eventToken,
            amount: amount,
            currency: currency
        }));
    }
};