/**
 * Created by vladislav on 9/6/2022
 */

var Mygames = function () {
    Platform.call(this, "mygames");
};

Mygames.prototype = Object.create(Platform.prototype);
Mygames.prototype.constructor = Mygames;

Mygames.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};

Mygames.prototype.canReview = function () {
    return true;
};

Mygames.prototype.requestReview = function () {
    window.electronApi.showReview(cleverapps.config.mygames.gcId);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.SOCIAL_REVIEW + true);
};

Mygames.prototype.isFullscreenAvailable = function () {
    return !!this.getFullscreener();
};