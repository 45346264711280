/**
 * Created by mac on 9/25/20
 */

var AdminEpisodes = function (administrator) {
    this.administrator = administrator;

    cleverapps.EventEmitter.call(this);

    this.onUpdateHoverEpisodeListener = function () {};
    this.onUpdateEpisodeListener = function () {};
};

AdminEpisodes.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminEpisodes.prototype.constructor = AdminEpisodes;

AdminEpisodes.prototype.updateDebugs = function () {
    var inherits = false;
    var lastNumber = -1;

    this.episodes.slice(0, this.episodes.length - 1).forEach(function (episode) {
        var isNumber = cleverapps.isNumber(episode.episodeNo);
        var bundle = episode.getBundle();
        if (!bundle) {
            return;
        }

        var debug = bundle.debug;

        if (cleverapps.config.type === "merge") {
            episode.setDebug(debug);
            return;
        }

        if (isNumber && inherits || isNumber && lastNumber < (parseInt(episode.episodeNo) - 1)) {
            debug = AdminEpisode.DEBUG_DISABLED;
        }
        if (isNumber && debug) {
            inherits = true;
        }

        if (bundle.episode.levelsAmount < Episode.LEVELS_PER_EPISODE || !isNumber) {
            debug = AdminEpisode.DEBUG_DISABLED;
        }

        if (isNumber) {
            lastNumber = parseInt(episode.episodeNo);
        }

        episode.setDebug(debug);
    });
};

AdminEpisodes.prototype.next = function (sign) {
    var pos = this.episodes.indexOf(this.active) + sign;

    if (this.episodes[pos] === undefined || this.episodes[pos].isAddMoreEpisode()) {
        return undefined;
    }

    return this.episodes[pos];
};

AdminEpisodes.prototype.updateIndexes = function () {
    this.episodes.forEach(function (episode, index) {
        if (episode.index !== undefined) {
            episode.index = index;
        }
    });
};

AdminEpisodes.prototype.getInsertionIndex = function (episodeNo) {
    var index = 0;
    while (index < this.total) {
        var prevEpisodeNo = this.episodes[index].episodeNo;
        if (AdminEpisodes.EPISODE_NUMBERS_COMPARATOR(prevEpisodeNo, episodeNo) >= 0) {
            break;
        }
        index++;
    }
    return index;
};

AdminEpisodes.prototype.insertToArray = function (episodeNo) {
    var index = this.getInsertionIndex(episodeNo);

    var episode = AdminEpisode.create(index, episodeNo);
    this.episodes.splice(index, 0, episode);
    this.episodesByName[episodeNo] = episode;
    this.total = this.episodes.length - 1;

    this.updateIndexes();
    this.updateDebugs();

    this.trigger("insert", episode);
};

AdminEpisodes.prototype.removeFromArray = function (episodeNo) {
    var episode = this.episodesByName[episodeNo];
    var index = episode.index;
    this.episodes.splice(index, 1);
    episode.purge();
    this.total = this.episodes.length - 1;
    delete this.episodesByName[episodeNo];

    this.updateIndexes();
    this.updateDebugs();

    this.trigger("remove", episode);
};

AdminEpisodes.prototype.renameInArray = function (oldEpisodeNo, newEpisodeNo) {
    var episode = this.episodesByName[oldEpisodeNo];
    var index = episode.index;
    this.episodes.splice(index, 1);
    delete this.episodesByName[oldEpisodeNo];
    this.total = this.episodes.length - 1;

    var newIndex = this.getInsertionIndex(newEpisodeNo);

    episode.setEpisodeNo(newEpisodeNo);
    episode.index = newIndex;
    this.episodes.splice(newIndex, 0, episode);
    this.episodesByName[newEpisodeNo] = episode;
    this.total = this.episodes.length - 1;

    this.updateIndexes();
    this.updateDebugs();
    this.trigger("rename", episode, oldEpisodeNo);
};

AdminEpisodes.prototype.createNew = function (callback) {
    var episodeNo = parseInt(this.episodes[this.total - 1].episodeNo) + 1;
    var data = {
        language: cleverapps.settings.language
    };

    var onSuccess = function (response) {
        this.saveToStorage(episodeNo, response.data);
        cleverapps.episodes.reset();
        this.insertToArray(episodeNo);
        this.setActive(this.episodesByName[episodeNo], callback);

        cleverapps.administrator.log.createEntry({
            type: AdminLog.CREATE_EPISODE,
            episodeNo: episodeNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Create episode error");
        callback();
    };

    cleverapps.RestClient.post("/admin/newepisode/" + episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.remove = function (episodeNo, callback) {
    var language = cleverapps.settings.language;
    var data = {
        language: language
    };
    var episode = this.episodesByName[episodeNo];

    var onSuccess = function () {
        this.removeFromStorage(episodeNo);
        this.removeFromArray(episodeNo);
        cleverapps.episodes.reset();

        var prevEpisode = this.episodes[Math.max(0, episode.index - 1)];
        if (prevEpisode && !prevEpisode.isAddMoreEpisode()) {
            this.setActive(prevEpisode, callback);
        } else {
            callback();
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.DELETE_EPISODE,
            episodeNo: episodeNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Remove episode error");
        callback();
    };

    cleverapps.RestClient.post("/admin/removeepisode/" + episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.copy = function (episodeNo, callback) {
    var data = {
        language: cleverapps.settings.language
    };

    var onSuccess = function (response) {
        var newEpisodeNo = response.episodeNo;
        this.saveToStorage(newEpisodeNo, response.data);
        this.insertToArray(newEpisodeNo);
        this.setActive(this.episodesByName[newEpisodeNo], callback);

        cleverapps.administrator.log.createEntry({
            type: AdminLog.COPY_EPISODE,
            episodeNo: episodeNo,
            newEpisodeNo: newEpisodeNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Copy episode error");
        callback();
    };

    cleverapps.RestClient.post("/admin/copyepisode/" + episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.rename = function (episodeNo, newEpisodeNo, callback) {
    var language = cleverapps.settings.language;
    var data = {
        language: language,
        newEpisodeNo: newEpisodeNo
    };

    var onSuccess = function (response) {
        this.removeFromStorage(episodeNo);
        this.saveToStorage(newEpisodeNo, response.data);
        this.renameInArray(episodeNo, newEpisodeNo);
        cleverapps.episodes.reset();

        cleverapps.administrator.adminLevels.reset(callback);

        cleverapps.administrator.log.createEntry({
            type: AdminLog.RENAME_EPISODE,
            episodeNo: episodeNo,
            newEpisodeNo: newEpisodeNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Rename episode error");
        callback();
    };

    cleverapps.RestClient.post("/admin/renameepisode/" + episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.upload = function (content, callback) {
    var data = {
        language: cleverapps.settings.language,
        content: content
    };

    var episode = this.getActive();

    var onSuccess = function (response) {
        var uploadedEpisodeNo = response.episodeNo;
        this.saveToStorage(uploadedEpisodeNo, response.data);
        cleverapps.administrator.adminLevels.reset(callback);
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Upload episode error");
        callback();
    };

    cleverapps.RestClient.post("/admin/uploadepisode/" + episode.episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.setDebug = function (debug, callback) {
    var data = {
        language: cleverapps.settings.language,
        params: {
            debug: debug
        }
    };

    var episode = this.getActive();

    var onSuccess = function (response) {
        this.saveToStorage(episode.episodeNo, response.data);
        this.updateDebugs();
        cleverapps.episodes.reset();

        cleverapps.administrator.log.createEntry({
            type: AdminLog.EPISODE_SET_DEBUG,
            episodeNo: episode.episodeNo,
            debug: debug
        });

        callback();
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Upload episode params error");
        callback();
    };

    cleverapps.RestClient.post("/admin/updateparams/" + episode.episodeNo, data, onSuccess, onError);
};

AdminEpisodes.prototype.getActive = function () {
    return this.active;
};

AdminEpisodes.prototype.setActive = function (episode, f, suggestedLevel) {
    if (this.active === episode) {
        f && f();
        return;
    }

    if (this.active !== undefined) {
        this.active.unselect();
    }

    this.active = episode;
    this.active.select();

    if (cleverapps.config.adminMode) {
        cleverapps.setUrlHash({ episode: this.active.episodeNo });
    }

    cleverapps.administrator.adminLevels.reset(f, suggestedLevel);
    cleverapps.administrator.log.onChangeEpisode(episode);

    this.trigger("setActive", episode);
};

AdminEpisodes.prototype.hover = function (episode) {
    if (this.hoverEpisode !== episode) {
        this.hoverEpisode = episode;
        this.onUpdateHoverEpisodeListener();
    }
};

AdminEpisodes.prototype.unhover = function (episode) {
    if (this.hoverEpisode === episode) {
        delete this.hoverEpisode;
        this.onUpdateHoverEpisodeListener();
    }
};

AdminEpisodes.prototype.reset = function (f) {
    delete this.hoverEpisode;

    var names = AdminEpisodes.GetEpisodeNames(cleverapps.settings.language);

    var episodes = [];
    var episodesByName = {};

    for (var i = 0; i < names.length; i++) {
        var name = names[i];
        var episode = AdminEpisode.create(i, name);

        episodes.push(episode);
        episodesByName[name] = episode;
    }

    this.total = episodes.length;

    var addMoreEpisode = AdminEpisode.getAddMoreEpisode();
    episodesByName[addMoreEpisode.episodeNo] = addMoreEpisode;
    episodes.push(addMoreEpisode);

    this.episodes = episodes;
    this.episodesByName = episodesByName;

    this.updateDebugs();

    this.trigger("reset");

    var params = cleverapps.getRequestParameters(window.location.hash);
    var episodeNo = params.episode;
    var levelNo = params.level !== undefined ? params.level : cleverapps.user.level;

    if (!cleverapps.config.adminMode && cleverapps.user.isPassedAll()) {
        f();
        return;
    }

    var activeEpisode = this.episodesByName[episodeNo] || this.episodesByName[cleverapps.user.episode];
    if (activeEpisode) {
        this.setActive(activeEpisode, f, levelNo);
    } else {
        f();
    }
};

AdminEpisodes.prototype.getGuessedEpisode = function (episodeNo) {
    return this.episodesByName[episodeNo];
};

AdminEpisodes.prototype.update = function () {
    this.episodes.forEach(function (episode) {
        episode.onUpdate();
    });
};

AdminEpisodes.prototype.updateCurrent = function () {
    this.episodes.forEach(function (episode) {
        episode.onUpdateCurrent();
    });
};

AdminEpisodes.GetEpisodeNames = function (language) {
    var episodeBundles = cleverapps.values(bundles).filter(function (bundle) {
        return bundle.episode && bundle.episode.episodeNo !== "coming_soon";
    });

    if (cleverapps.config.type === "board") {
        episodeBundles = episodeBundles.filter(function (bundle) {
            return bundle.episode.language === language;
        });
    }

    var episodeNumbers = episodeBundles.map(function (bundle) {
        return bundle.episode.episodeNo;
    });

    episodeNumbers = episodeNumbers.sort(AdminEpisodes.EPISODE_NUMBERS_COMPARATOR);

    return episodeNumbers;
};

AdminEpisodes.prototype.updateFromServer = function (callback) {
    var data = {
        language: cleverapps.settings.language,
        episodes: []
    };
    this.episodes.forEach(function (episode) {
        if (!episode.isAddMoreEpisode()) {
            data.episodes.push(episode.episodeNo);
        }
    });

    var onSuccess = function (response) {
        var added = response.added;
        var removed = response.removed;

        Object.keys(added).forEach(function (episodeNo) {
            this.saveToStorage(episodeNo, added[episodeNo]);
        }.bind(this));

        removed.forEach(function (episodeNo) {
            this.removeFromStorage(episodeNo);
        }.bind(this));

        if (Object.keys(added).length > 0 || removed.length > 0) {
            this.reset(callback);
        } else {
            callback();
        }
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Load episodes changes error");
        callback();
    };

    cleverapps.RestClient.post("/admin/loadepisodeschanges/", data, onSuccess, onError);
};

AdminEpisodes.SaveToStorage = function (episodeNo, data) {
    var bundleName = Episode.BundleId(episodeNo);
    var jsonPath = bundleName + ".json";
    var levelsLink = bundleName + "/levels.json";

    cc.loader.cache[jsonPath] = { levels: data };
    cc.loader.cache[levelsLink] = data;

    var episodeLevels = (data.levels || data).map(function (level) {
        if (Array.isArray(level)) {
            level = level[level.length - 1];
        }
        return level;
    });

    var episode = {
        episodeNo: episodeNo,
        levelsAmount: episodeLevels.length,
        levels: episodeLevels.map(function (level) {
            return {
                hard: level.hard || undefined,
                tricky: level.tricky || undefined,
                bundles: level.bundles && level.bundles.length && level.bundles || undefined,
                units: level.units || undefined,
                tiles: level.tiles || undefined,
                expedition: level.expedition || undefined,
                mapSize: level.map && {
                    width: level.map[0].length,
                    height: level.map.length
                } || undefined
            };
        })
    };

    var bundle = {
        storage: true,
        episode: episode,
        jsons: {
            levels: levelsLink
        },
        urls: {
            json: jsonPath
        }
    };

    bundle.debug = episodeLevels.length < Episode.LEVELS_PER_EPISODE || data.debug || !cleverapps.isNumber(episodeNo);

    if (cleverapps.config.type === "board") {
        episode.language = cleverapps.settings.language;
    }

    bundles[bundleName] = bundle;

    cleverapps.bundleLoader.loadBundle(bundleName); // prevent unload from cache json
};

AdminEpisodes.prototype.saveToStorage = function (episodeNo, data) {
    var bundleName = Episode.BundleId(episodeNo);
    var oldDebug = bundles[bundleName] && bundles[bundleName].debug;

    AdminEpisodes.SaveToStorage(episodeNo, data);

    var bundle = bundles[bundleName];
    if (oldDebug !== bundle.debug) {
        this.updateDebugs();
    }
    this.onUpdateEpisodeListener(episodeNo);
    if (this.episodesByName[episodeNo]) {
        this.episodesByName[episodeNo].onUpdate();
    }

    this.administrator.adminGitButtons.checkStatus();
    this.administrator.simpleMethaDataProvider && this.administrator.simpleMethaDataProvider.reset();
};

AdminEpisodes.prototype.removeFromStorage = function (episodeNo) {
    var bundleName = Episode.BundleId(episodeNo);
    delete bundles[bundleName];
    delete cleverapps.bundleLoader.handles[bundleName];

    this.onUpdateEpisodeListener(episodeNo);

    this.administrator.adminGitButtons.checkStatus();
    this.administrator.simpleMethaDataProvider && this.administrator.simpleMethaDataProvider.reset();
};

AdminEpisodes.prototype.scrollToEp = function (episodeNo) {
    this.trigger("scrollToEp", episodeNo);
};

AdminEpisodes.prototype.gatherDifficultyData = function (callback) {
    var serverData = {};
    var difficultyData = [];
    var hashesByEps = {};
    var ind = 0;

    var calcDifficulty = function () {
        for (var epNo in hashesByEps) {
            var levelsDifficulty = hashesByEps[epNo].map(function (lvlHash) {
                return serverData[lvlHash] || 0;
            });

            var avg;
            if (levelsDifficulty.some(function (difficulty) {
                return difficulty >= 95;
            })) {
                avg = 100;
            } else {
                var sum = 0;
                levelsDifficulty.forEach(function (difficulty) {
                    sum += 1 / (1 - difficulty / 100);
                });
                avg = Math.round((1 - 1 / (sum / levelsDifficulty.length)) * 100);
            }

            difficultyData.push({
                Episode: epNo,
                Difficulty: avg
            });
        }

        callback(difficultyData);
    };

    var gatherHashes = function (adminEpisode) {
        if (++ind === this.episodes.length) {
            calcDifficulty();
            return;
        }

        if (adminEpisode.isNumeric() && !adminEpisode.debug) {
            var episode = new Episode(adminEpisode.episodeNo);
            episode.loadData(function () {
                hashesByEps[episode.episodeNo] = episode.getLastVersionHashes();
                episode.destructor();
                gatherHashes(this.episodes[ind]);
            }.bind(this));
        } else {
            gatherHashes(this.episodes[ind]);
        }
    }.bind(this);

    cleverapps.RestClient.get(DataSource.ReleaseAdminPath("epdifficulty/get"), {}, function (data) {
        if (data.error) {
            console.log("Failed getting difficulty statistics", data.error);
        } else {
            data.forEach(function (level) {
                serverData[level.hash] = level.difficulty;
            });
            gatherHashes(this.episodes[0]);
        }
    }.bind(this), function (err) {
        console.log("Error getting difficulty statistics", err);
    });
};

AdminEpisodes.ResetAction = function (f) {
    cleverapps.administrator.adminEpisodes.reset(f);
};

AdminEpisodes.UpdateFromServerAction = function (f) {
    cleverapps.administrator.adminEpisodes.updateFromServer(f);
};

AdminEpisodes.EPISODE_NUMBERS_COMPARATOR = function (number1, number2) {
    var int1 = parseInt(number1);
    var int2 = parseInt(number2);

    if (int1 !== number1) {
        return -1;
    }

    if (int2 !== number2) {
        return 1;
    }

    if (int1 === int2) {
        return (number1 + "").localeCompare(number2 + "");
    }
    return int1 - int2;
};