/**
 * Created by vladislav on 9/6/2022
 */

var AndroidPlatform = function () {
    AndroidBase.call(this, "android");
};

AndroidPlatform.prototype = Object.create(AndroidBase.prototype);
AndroidPlatform.prototype.constructor = AndroidPlatform;

AndroidPlatform.prototype.init = function (callback) {
    callback();
};

AndroidPlatform.prototype._callNative = function (method, options) {
    AndroidNative.callNative(method, JSON.stringify(options));
};