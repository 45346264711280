/**
 * Created by vladislav on 25.01.2022
 */

cleverapps.overrideStyles(cleverapps.styles.Decorators, {
    web_top: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_web_top,
        scale: 1.0,
        zOrder: 1,
        x: { align: "left", dx: 10 },
        y: { align: "top", dy: 0 }
    },

    web_bottom: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_web_bottom,
        scale: 1.0,
        zOrder: 0,
        x: { align: "left", dx: 16 },
        y: { align: "bottom", dy: 25 }
    },

    candle_animation: {
        type: Decors.TYPE_ANIMATION,
        lovesBackground: true,
        json: bundles.halloween.jsons.halloween_candle_json,
        animation: "animation",
        scale: 1.0,
        zOrder: 5,
        x: { align: "left", dx: 352 },
        y: { align: "top", dy: 135 }
    },

    owls_animation: {
        type: Decors.TYPE_ANIMATION,
        lovesBackground: true,
        json: bundles.halloween.jsons.halloween_owls_json,
        animation: "animation",
        scale: 0.6,
        zOrder: 5,
        x: { align: "center", dx: 13 },
        y: { align: "top", dy: 743 }
    },

    pumpkins: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_pumpkins,
        scale: 1.0,
        zOrder: 1,
        x: { align: "left", dx: 76 },
        y: { align: "top", dy: 126 }
    },

    forest: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_decor_forest,
        scale: 1.2,
        zOrder: -2,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 345 }
    },

    roots_top_left: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_roots_top_left,
        scale: 1.0,
        zOrder: 1,
        x: { align: "left", dx: -30 },
        y: { align: "top", dy: 55 }
    },

    roots_top_right: {
        type: Decors.TYPE_IMAGE,
        lovesBackground: true,
        image: bundles.halloween.frames.halloween_roots_top_right,
        scale: 1.0,
        zOrder: 1,
        x: { align: "right", dx: 36 },
        y: { align: "top", dy: 68 }
    },

    pumpkin_animation: {
        type: Decors.TYPE_ANIMATION,
        lovesBackground: true,
        fearHigh: true,
        json: bundles.halloween.jsons.halloween_jack_json,
        animation: "animation",
        scale: 0.9,
        zOrder: 5,
        x: { align: "center", dx: -50 },
        y: { align: "top", dy: -270 }
    },

    xmas_window_right_top: {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: 85 },
        y: { align: "top", dy: 30 },
        lovesBackground: true
    },

    xmas_window_right_bottom: {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_right",
        x: { align: "right", dx: 11 },
        y: { align: "bottom", dy: -18 },
        lovesBackground: true
    },

    xmas_window_left_bottom: {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_left",
        x: { align: "left", dx: -18 },
        y: { align: "bottom", dy: -23 },
        lovesBackground: true
    },

    xmas_window_left_top: {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: -88 },
        y: { align: "top", dy: 30 },
        lovesBackground: true
    },

    valentines_decor_left: {
        type: Decors.TYPE_IMAGE,
        image: bundles.valentines.frames.valentines_window_decor_cloud_2_png,
        lovesBackground: true,
        scale: {
            x: -1.0,
            y: 1.0
        },
        zOrder: 1,
        x: { align: "left", dx: -55 },
        y: { align: "bottom", dy: -60 }
    },

    valentines_decor_right: {
        type: Decors.TYPE_IMAGE,
        image: bundles.valentines.frames.valentines_window_decor_cloud_2_png,
        lovesBackground: true,
        scale: 1.0,
        zOrder: 1,
        x: { align: "right", dx: 55 },
        y: { align: "bottom", dy: -60 }
    },

    valentines_decor_top_1: {
        type: Decors.TYPE_IMAGE,
        image: bundles.valentines.frames.valentines_window_cloud_up2_png,
        lovesBackground: true,
        scale: {
            x: -1.0,
            y: 1.0
        },
        zOrder: -2,
        x: { align: "left", dx: 19 },
        y: { align: "top", dy: 61 }
    },

    valentines_decor_top_2: {
        type: Decors.TYPE_IMAGE,
        image: bundles.valentines.frames.valentines_window_cloud_up2_png,
        lovesBackground: true,
        scale: 1,
        zOrder: -2,
        x: { align: "right", dx: -21 },
        y: { align: "top", dy: 61 }
    },

    valentines_decor_heart_top_1: {
        type: Decors.TYPE_IMAGE,
        image: bundles.valentines.frames.valentines_window_cloud_up_heart_png,
        lovesBackground: true,
        scale: {
            x: -1.0,
            y: 1.0
        },
        zOrder: 0,
        x: { align: "left", dx: -30 },
        y: { align: "top", dy: 55 }
    },

    window_bottom_left: {
        type: Decors.TYPE_IMAGE,
        image: bundles.easter.frames.window_bottom_left,
        lovesBackground: true,
        zOrder: -1,
        scale: 1.0,
        x: { align: "left", dx: -122 },
        y: { align: "bottom", dy: -77 }
    },

    window_bottom_right: {
        type: Decors.TYPE_IMAGE,
        image: bundles.easter.frames.window_bottom_right,
        lovesBackground: true,
        zOrder: -1,
        scale: 1.0,
        x: { align: "right", dx: 114 },
        y: { align: "bottom", dy: -66 }
    },

    memorial_decor_top: {
        type: Decors.TYPE_IMAGE,
        image: bundles.memorial.frames.memorial_decor_top,
        lovesBackground: true,
        scale: {
            x: -1,
            y: 1
        },
        zOrder: -1,
        x: { align: "right", dx: 7 },
        y: { align: "top", dy: 87 }
    },

    memorial_decor_bottom_left: {
        type: Decors.TYPE_IMAGE,
        image: bundles.memorial.frames.memorial_decor_bottom_left,
        lovesBackground: true,
        scale: 1,
        zOrder: 1,
        x: { align: "left", dx: 7 },
        y: { align: "bottom", dy: -40 }
    },

    memorial_decor_bottom_right: {
        type: Decors.TYPE_IMAGE,
        image: bundles.memorial.frames.memorial_decor_bottom_right,
        lovesBackground: true,
        scale: 1,
        zOrder: 1,
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: -40 }
    },

    shadow_liana_left: {
        lovesWindow: true,
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.shadow_liana_left,
        animation: "open",
        idle: "idle",
        position: [{
            x: { align: "center", anchor: "right", dx: -700 },
            y: { align: "top", dy: 230 }
        }, {
            x: { align: "center", anchor: "right", dx: -700 },
            y: { align: "top", dy: 230 }
        }, {
            x: { align: "left", dx: -160 },
            y: { align: "top", dy: 230 }
        }]
    },

    shadow_liana_right: {
        lovesWindow: true,
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.shadow_liana_right,
        animation: "open",
        idle: "idle",
        position: [{
            x: { align: "center", anchor: "left", dx: 700 },
            y: { align: "top", dy: 130 }
        }, {
            x: { align: "center", anchor: "left", dx: 700 },
            y: { align: "top", dy: 130 }
        }, {
            x: { align: "right", dx: 160 },
            y: { align: "top", dy: 130 }
        }]
    },

    aquatic_flower: {
        lovesWindow: true,
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.aquatic_flower,
        animation: "open",
        idle: "animation",
        position: [{
            x: { align: "center", dx: 640 },
            y: { align: "bottom", dy: -5 }
        }, {
            x: { align: "center", dx: 640 },
            y: { align: "bottom", dy: -5 }
        }, {
            x: { align: "right", dx: 30 },
            y: { align: "bottom", dy: -5 }
        }]
    },

    shop_bonusSale_decor: {
        image: bundles.tile_shop.frames.bonussale_decor,
        x: { align: "left", dx: -80 },
        y: { align: "top", dy: 98 },
        filter: function (tileModel) {
            return PeriodicSaleLogic.NeedTileDecor(tileModel);
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SceneDecors, {
    xmas_menubar: {
        image: bundles.menubar.frames.xmas_menubar,
        x: { align: "left", dx: 25 },
        y: { align: "top", dy: 28 }
    },

    xmas_menubar_level: {
        image: bundles.menubar.frames.xmas_menubar_level,
        x: { align: "left", dx: 49 },
        y: { align: "top", dy: 45 }
    },

    xmas_control_buttons: {
        image: bundles.controlbuttons.frames.xmas_control_buttons,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 9 }
    },

    xmas_sidebar: {
        image: bundles.sidebar.frames.xmas_sidebar,
        x: { align: "center", dx: -2 },
        y: { align: "bottom", dy: -3 }
    },

    xmas_toolbar: [{
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "left", dx: -29 },
        y: { align: "top", dy: 34 }
    }, {
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "right", dx: 29 },
        y: { align: "top", dy: 33 },
        scale: {
            x: -1,
            y: 1
        }
    }],

    sceneControlButtonValentines: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: -10 },
            image: bundles.valentines.frames.valentines_scene_button_decor_png
        }
    ],
    menuBarItemValentines: [
        {
            x: { align: "left", dx: -20 },
            y: { align: "top", dy: -20 },
            image: bundles.valentines.frames.valentines_menu_bar_decor_1_png
        },
        {
            x: { align: "right", dx: 8 },
            y: { align: "top", dy: -10 },
            image: bundles.valentines.frames.valentines_menu_bar_decor_2_png
        }
    ],
    toolbarViewValentines: [
        {
            x: { align: "left", dx: -130 },
            y: { align: "center", dy: 23 },
            image: bundles.valentines.frames.valentines_toolbar_decor_1_png,
            scale: {
                x: -1,
                y: 1
            },
            zOrder: -1
        },
        {
            x: { align: "right", dx: 130 },
            y: { align: "center", dy: 23 },
            image: bundles.valentines.frames.valentines_toolbar_decor_1_png,
            zOrder: -1
        }
    ],

    toolbarViewEaster: [
        {
            x: { align: "left", dx: -151 },
            y: { align: "center", dy: 11 },
            scale: {
                x: -1,
                y: 1
            },
            zOrder: -1,
            image: bundles.easter.frames.toolbar_right
        },
        {
            x: { align: "right", dx: 151 },
            y: { align: "center", dy: 11 },
            zOrder: -1,
            image: bundles.easter.frames.toolbar_right
        }
    ],

    toolbarViewMemorial: [
        {
            x: { align: "left", dx: -165 },
            y: { align: "center", dy: 40 },
            scale: {
                x: 1.0,
                y: 1.0
            },
            zOrder: -1,
            image: bundles.memorial.frames.toolbar_decor
        },
        {
            x: { align: "right", dx: 165 },
            y: { align: "center", dy: 40 },
            scale: {
                x: -1.0,
                y: 1.0
            },
            zOrder: -1,
            image: bundles.memorial.frames.toolbar_decor
        }
    ]
});