/**
 * Created by mac on 7/26/18
 */

var WardrobeRulesWindow = Window.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.wardrobeBundle = cleverapps.skins.getSlot("wardrobeBundle");
        var styles = cleverapps.styles.WardrobeRulesWindow;

        this._super({
            name: "wardroberuleswindow",
            content: this.generateContent(),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "OK"
            }
        });
    },

    scrollToStage: function (stage) {
        var needX = 0;
        if (stage > 0) {
            needX = this.stageNodes[this.skin - 1].x - this.stagesLayout.width / 2;
            if (needX < 0) {
                needX = 0;
            }
            if (needX > this.stagesList.width - this.stagesLayout.width) {
                needX = this.stagesList.width - this.stagesLayout.width;
            }
        }
        this.stagesList.runAction(new cc.MoveTo(0.3, { x: -needX, y: this.stagesList.y }));
    },

    updateSkin: function () {
        if (this.skin === undefined) {
            this.skin = 0;
        } else {
            if (this.skin !== 0) {
                this.stageNodes[this.skin - 1].setOpacity(100);
            }
            this.skin = (this.skin + 1) % (WardrobeWindow.stages.length + 1);
            if (this.skin !== 0) {
                this.stageNodes[this.skin - 1].setOpacity(255);
            }
        }
        this.person.setSkin("skin_" + this.skin);

        var state = cleverapps.styles.WardrobeRulesWindow.states[this.skin];

        if (state.mirror) {
            this.personNode.setScaleX(-1);
        } else {
            this.personNode.setScaleX(1);
        }

        this.scrollToStage(this.skin);

        if (state.animation) {
            this.person.setAnimationAndIdleAfter(state.animation, "idle");
        }

        if (state.sound) {
            cleverapps.audio.playSound(state.sound);
        }

        this.person.runAction(new cc.Sequence(
            new cc.DelayTime(state.time),
            new cc.CallFunc(function () {
                this.updateSkin();
            }.bind(this))
        ));
    },

    onClose: function () {
        new WardrobeWindow(this.mission);
    },

    generateContent: function () {
        var styles = cleverapps.styles.WardrobeRulesWindow;

        var content = new cc.Node();
        content.setContentSize2(styles.width, styles.height);
        content.setAnchorPoint2();

        var personNode = this.personNode = new cc.Node();
        personNode.setAnchorPoint2();
        personNode.setContentSize(styles.person.width, styles.person.height);

        this.person = new Person("hero");
        if (styles.person.scale) {
            this.person.setScale(styles.person.scale);
        }
        personNode.addChild(this.person);
        this.person.setPositionRound(styles.person.position);

        this.stageNodes = [];
        for (var i = 0; i < WardrobeWindow.stages.length; i++) {
            var icon = new cc.Sprite(this.wardrobeBundle.frames["stage" + (i + 1) + "_icon"]);
            icon.setScale(styles.icon.scale);
            icon.setOpacity(100);
            this.stageNodes.push(icon);
        }
        this.stagesList = new cleverapps.Layout(this.stageNodes, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.icon.margin
        });

        this.stagesLayout = new ccui.Layout();
        this.stagesLayout.addChild(this.stagesList);
        this.stagesLayout.setClippingEnabled(true);
        this.stagesLayout.setContentSize2(styles.icons.width, styles.icons.height);
        this.stagesLayout.setAnchorPoint2();

        this.stagesList.setAnchorPoint2(0, 0.5);
        this.stagesList.setPositionRound(0, this.stagesLayout.height / 2);

        this.updateSkin();

        var text = cleverapps.UI.generateTTFText("WardrobeRulesText", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(content.width, 0);
        text.fitTo(undefined, styles.text.height);

        var itemContent = new cleverapps.Layout([personNode, this.stagesLayout, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.content.margin
        });

        itemContent.setPositionRound(content.width / 2 + styles.content.position.x, content.height / 2 + styles.content.position.y);

        content.addChild(itemContent);

        return content;
    }
});

WardrobeRulesWindow.prototype.listBundles = function () {
    return ["wardrobe_window"];
};

cleverapps.styles.WardrobeRulesWindow = {
    width: 520,
    height: 780,

    icons: {
        width: 500,
        height: 110
    },

    icon: {
        scale: 0.55,
        margin: 15
    },

    person: {
        scale: 1,
        width: 200,
        height: 260,
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    text: {
        height: 260
    },

    content: {
        margin: 30,
        position: {
            x: 0,
            y: -40
        }
    },

    button: {
        width: 250,
        height: 100
    },

    states: [
        {
            time: 1,
            mirror: false
        },
        {
            time: 1,
            mirror: false
        },
        {
            animation: "idle_jump",
            time: 2,
            mirror: false
        },
        {
            time: 1,
            mirror: true
        },
        {
            animation: "idle_jump",
            time: 2,
            mirror: true
        },
        {
            time: 1,
            mirror: false
        },
        {
            animation: "idle_jump",
            time: 2,
            mirror: false
        },
        {
            time: 1,
            mirror: true
        },
        {
            animation: "idle_jump",
            time: 2,
            mirror: true
        },
        {
            time: 1,
            mirror: false
        },
        {
            animation: "idle_jump",
            time: 2,
            mirror: false
        },
        {
            time: 1,
            mirror: true
        },
        {
            animation: "idle_jump",
            time: 5,
            mirror: true
        }
    ]
};
