/**
 * Created by slava on 4/21/17.
 */

cleverapps.MyMailRuSocial = function () {
    if (!(this instanceof cleverapps.MobileMyMailRuSocial)) {
        console.log("Choosing html5-MyMailRu");
    }

    cleverapps.Social.call(this);
};

cleverapps.MyMailRuSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.MyMailRuSocial.prototype.constructor = cleverapps.MyMailRuSocial;

cleverapps.MyMailRuSocial.prototype.init = function (callback) {
    this.listeners = {
        friendsInvitation: function () {},
        friendsRequest: function () {},
        streamPublish: function () {}
    };

    var callCallback = cleverapps.once(function () {
        if (!(this instanceof cleverapps.MobileMyMailRuSocial)) {
            if (window.outerHeight && window.innerHeight) {
                var maxH = window.outerHeight - 250;
                mailru.app.utils.setHeight(maxH);
                cc.view.setFrameSize(window.innerWidth, maxH);
            }

            mailru.events.listen(mailru.app.events.friendsInvitation, function (event) {
                this.listeners.friendsInvitation(event);
            }.bind(this));

            mailru.events.listen(mailru.app.events.friendsRequest, function (event) {
                this.listeners.friendsRequest(event);
            }.bind(this));

            mailru.events.listen(mailru.common.events.streamPublish, function (event) {
                this.listeners.streamPublish(event);
            }.bind(this));

            mailru.events.listen(mailru.app.events.readHash, function (result) {
                if (result && result.channel) {
                    levels.user.setChannel(result.channel);
                }
            });
            mailru.app.utils.hash.read();

            setTimeout(callback, 300);
        } else {
            this.createHeaderAndFooter(callback);
        }
    }.bind(this));

    var onFailure = function (reason) {
        if (typeof mailru === "undefined") {
            mailru = cleverapps.FakeMailRU;
        }
        callCallback();

        if (reason === "timeout") {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_TIMEOUT);
        } else {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_SCRIPT_ERROR);
        }
    };

    var onSuccess = function () {
        mailru.loader.require("api", function () {
            mailru.app.init(cleverapps.config.mm.privateKey);
            callCallback();
        });
    };

    cleverapps.loadSdk("//connect.mail.ru/js/loader.js", { onSuccess: onSuccess, onFailure: onFailure });
};

cleverapps.MyMailRuSocial.prototype.getRequestParameters = function () {
    var res = {};
    var url = window.location.search;
    if (url) {
        url = url.substr(1); // Drop the leading '?' / '#'
        var nameValues = url.split("&");

        for (var i = 0; i < nameValues.length; i++) {
            var nameValue = nameValues[i].split("=");
            var name = nameValue[0];
            var value = nameValue[1];
            value = decodeURIComponent(value.replace(/\+/g, " "));
            res[name] = value;
        }
    }
    return res;
};

cleverapps.MyMailRuSocial.prototype.isLoggedIn = function () {
    return true;
};

cleverapps.MyMailRuSocial.prototype.getUserID = function () {
    return this.getRequestParameters().vid;
};

cleverapps.MyMailRuSocial.prototype.getAccessToken = function () {
    var names = [], params = this.getRequestParameters();
    for (var name in params) {
        if (name !== "sig") {
            names.push(name);
        }
    }
    names.sort();
    var str = "";
    for (var i = 0; i < names.length; i++) {
        str += names[i] + "=" + params[names[i]];
    }
    return "MM_" + params.sig + "_" + str;
};

cleverapps.MyMailRuSocial.prototype.login = function (onSuccess) {
    onSuccess();
};

cleverapps.MyMailRuSocial.prototype.inviteFriends = function (options, callback) {
    this.listeners.friendsInvitation = function (event) {
        if (event.status === "opened") {
            return;
        }
        callback(event.data && event.data.length ? cleverapps.Social.CODE_SUCCEED : cleverapps.Social.CODE_CANCELLED);
    };

    cleverapps.platform.exitFullscreen(function () {
        mailru.app.friends.invite({ text: options.message });
    });
};

cleverapps.MyMailRuSocial.prototype.request = function (options, callback) {
    this.listeners.friendsRequest = function (event) {
        if (event.status === "opened") {
            return;
        }
        if (event.data && event.data.length) {
            callback(cleverapps.Social.CODE_SUCCEED, event.data);
        } else {
            callback(cleverapps.Social.CODE_CANCELLED);
        }
    };

    cleverapps.platform.exitFullscreen(function () {
        mailru.app.friends.request({
            text: options.message,
            image_url: Share.getData("default").picture,
            friends: options.to
        });
    });
};

cleverapps.MyMailRuSocial.prototype.shareDialog = function (data, callback) {
    this.listeners.streamPublish = function (event) {
        if (event.status === "opened") {
            return;
        }
        if (event.status === "publishSuccess") {
            callback(cleverapps.Social.CODE_SUCCEED);
        } else {
            console.log(event.status);
            callback(cleverapps.Social.CODE_CANCELLED);
        }
    };
    var postData = {
        title: data.name,
        text: data.description,
        img_url: data.picture
    };

    cleverapps.platform.exitFullscreen(function () {
        mailru.common.stream.post(postData);
    });
};

cleverapps.MyMailRuSocial.prototype.listFriends = function (callback) {
    mailru.common.friends.getAppUsers(function (list) {
        var friends = [];
        for (var i = 0; i < list.length; i++) {
            friends.push(cleverapps.MyMailRuSocial.formatUserData(list[i]));
        }
        callback(cleverapps.Social.CODE_SUCCEED, friends);
    }, true);
};

cleverapps.MyMailRuSocial.prototype.listInvitableFriends = function (callback) {
    mailru.common.friends.getExtended(function (list) {
        var friends = [];
        for (var i = 0; i < list.length; i++) {
            if (!list[i].app_installed) {
                friends.push(cleverapps.MyMailRuSocial.formatUserData(list[i]));
            }
        }
        callback(cleverapps.Social.CODE_SUCCEED, friends);
    });
};

cleverapps.MyMailRuSocial.formatUserData = function (user) {
    return {
        id: user.uid,
        name: user.first_name + " " + user.last_name,
        first_name: user.first_name,
        picture: {
            data: {
                url: user.pic_128
            }
        }
    };
};

cleverapps.MyMailRuSocial.prototype.aboutUser = function (userId, callback, onFailure) {
    var isSingle = !Array.isArray(userId);

    mailru.common.users.getInfo(function (data) {
        if (data.error) {
            if (onFailure) {
                onFailure(cleverapps.Social.CODE_FAILED, data.error);
            }
            return;
        }

        if (!data || typeof data.map !== "function") {
            cleverapps.throwAsync("MailRU aboutUser response - " + JSON.stringify(data));
        }

        var users = data.map(function (userData) {
            return cleverapps.MyMailRuSocial.formatUserData(userData);
        });
        if (isSingle) {
            callback(users[0]);
        } else {
            callback(users);
        }
    }, userId);
};

cleverapps.MyMailRuSocial.prototype.aboutMe = function (callback) {
    // console.log('about me: ' + this.getUserID())
    this.aboutUser(this.getUserID(), callback);
};

cleverapps.FakeMailRU = {
    events: {
        listen: function () {}
    },

    app: {
        payments: {
            showDialog: function () {}
        },

        friends: {
            invite: function () {},
            request: function () {}
        },

        utils: {
            setHeight: function () {},
            hash: {
                read: function () {}
            }
        },
    
        events: {
        },
    
        init: function () {}
    },

    common: {
        stream: {
            post: function () {}
        },
        
        friends: {
            getAppUsers: function () {},
            getExtended: function () {}
        },
        
        users: {
            getInfo: function () {}
        },
        
        events: {
        }
    },
    
    loader: {
        require: function () {}
    },

    utils: {
        sign: function () {},
        apiOverJSONP: function () {}
    }
};