/**
 * Created by vladislav on 9/6/2022
 */

var MobileVk = function () {
    VKPlatform.call(this, "mobile_vk");
};

MobileVk.prototype = Object.create(VKPlatform.prototype);
MobileVk.prototype.constructor = MobileVk;

MobileVk.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};

MobileVk.prototype.canCreateShortcut = function () {
    return false;
};