/**
 * Created by Andrey Popov on 20.04.2021
 */

var AdsSound = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();
        this.setAnchorPoint2();

        properties = properties || {};

        if (properties.isPreview) {
            var icon = cleverapps.UI.createSprite(bundles.editor_controls.frames.sound_icon);
            this.setContentSize(cleverapps.styles.AdsSound);
            icon.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(icon);
        }

        var playSound = function () {
            var soundUrl = bundles[properties.sound.bundle].urls[properties.sound.sound];
            if (properties.isDisk === "true") {
                cleverapps.audio.insertDisk(soundUrl);
            } else {
                this.audio = cleverapps.audio.playSound(soundUrl, {
                    loop: properties.loop === "true"
                });
            }
        }.bind(this);

        if (properties.sound && !properties.isPreview) {
            if (properties.delay) {
                playSound();
            } else if (properties.startOnEvent && properties.startOnEvent !== Wysiwyg.NOT_SELECTED) {
                eventBus.on(properties.startOnEvent, playSound, this);
            }

            if (properties.stopOnEvent && properties.stopOnEvent !== Wysiwyg.NOT_SELECTED) {
                eventBus.on(properties.stopOnEvent, function () {
                    if (properties.isDisk === "true") {
                        if (properties.fadeOut === "true") {
                            cleverapps.audio.fadeOut(2);
                        } else {
                            cleverapps.audio.eject();
                        }
                    } else {
                        cleverapps.audio.stopSound(this.audio);
                    }
                }.bind(this), this);
            }
        }
    }
});

cleverapps.styles.AdsSound = {
    width: 68,
    height: 60
};

AdsSound.PROPERTIES = [{
    name: "sound", type: "sound", bundles: ["ads_background_music", "ads_sfx_common"], bubbleUp: true
}, {
    name: "startOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(cleverapps.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}, {
    name: "stopOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(cleverapps.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}, {
    name: "fadeOut", type: "string", items: ["true", "false"], value: "false"
}, {
    name: "isDisk", type: "string", items: ["true", "false"], value: "false"
}, {
    name: "loop", type: "string", items: ["true", "false"], value: "false"
}];