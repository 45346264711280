/**
 * Created by Aleksandr on 21.12.2022
 */

var OrangeryTab = cc.Node.extend({
    ctor: function (orangery, tab, minWidth) {
        this._super();

        this.tab = tab;
        this.units = {};
        this.orangery = orangery;

        var styles = cleverapps.styles.OrangeryTab;

        var maxLineLength = 0;
        tab.rows.forEach(function (row) {
            maxLineLength = Math.max(maxLineLength, row.length);
        });
        var width = styles.grid.maxWidth;
        if (maxLineLength <= styles.minAmount) {
            width = styles.grid.minWidth;
        } else if (maxLineLength <= styles.maxAmount) {
            width = maxLineLength * 80;
        }
        width = Math.max(width, minWidth);
        var height = tab.rows.length * 80;
        height = Math.min(Math.max(styles.grid.minHeight, height), styles.grid.maxHeight);

        var grid = this.createGrid();

        var gridWrapper = new cc.Node();
        gridWrapper.setContentSize(Math.max(width, grid.width), Math.max(height, grid.height));
        gridWrapper.addChild(grid);
        grid.setPositionRound({ x: { align: "left" }, y: { align: "top" } });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(gridWrapper, {
            direction: cleverapps.UI.ScrollView.DIR_BOTH,
            scrollBarEnabled: false,
            outOfBoundaryScale: false
        });
        scroll.setContentSize2(gridWrapper.width, height);
        scroll.setPositionRound(scroll.width / 2, scroll.height / 2);
        scroll.scrollToDefault();

        this.setAnchorPoint2();
        this.setContentSize2(scroll.width, scroll.height);

        if (this.selectedItem) {
            var point = scroll.targetToPoint(this.selectedItem);
            scroll.scrollToPoint(scroll.normalizePoint({
                x: 0,
                y: point.y
            }));

            this.selectedItem.select(true);
        }

        this.addChild(scroll);
    },

    createGrid: function () {
        var lines = this.tab.rows.map(function (row, y) {
            var items = row.map(function (item, x) {
                var view = new OrangeryItem(this.orangery, item, this.tab.id, (x + y) % 2);

                if (this.orangery.selected === item) {
                    this.selectedItem = view;
                }

                return view;
            }.bind(this));

            return new cleverapps.Layout(items, {
                direction: cleverapps.UI.HORIZONTAL
            });
        }.bind(this));

        return new cleverapps.Layout(lines, {
            direction: cleverapps.UI.VERTICAL,
            align: cleverapps.UI.ALIGN_START
        });
    }
});

cleverapps.styles.OrangeryTab = {
    padding: {
        x: 5,
        y: 15
    },

    grid: {
        minWidth: 640,
        maxWidth: 970,
        minHeight: 320,
        maxHeight: 540
    },

    minAmount: 8,
    maxAmount: 12
};