/**
 * Created by mac on 10/6/20
 */

var AdminLevelMiniature = {
    generate: function (adminLevel) {
        var bg = AdminLevelMiniature.default();

        var Generator = AdminLevelMiniature[cleverapps.config.name] || AdminLevelMiniature[cleverapps.config.type];
        if (Generator) {
            try {
                var miniature = Generator(adminLevel);
                cleverapps.UI.fitToBox(miniature, cleverapps.styles.AdminLevelMiniature);
                bg.addChild(miniature);
                miniature.setPositionRound(cleverapps.styles.AdminLevelMiniature);
            } catch (e) {
                console.log("Error in level miniature " + cleverapps.humanReadableNumber(adminLevel.episodeNo, adminLevel.levelNo), e);
            }
        }

        return bg;
    },

    battlefield: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var battlefieldView = new BattlefieldView();
        for (var x = 0; x < Battlefield.GRID.x / 2; x++) {
            for (var y = 0; y < Battlefield.GRID.y; y++) {
                battlefieldView.cells[x][y].setVisible(false);
                var squad = game.battlefield.findSquad(x, y);
                if (squad) {
                    squad.remove();
                }
            }
        }
        battlefieldView.scale = 1;

        cc.eventManager.removeListener(battlefieldView.setupDragListener);

        var wrap = new cc.Node();
        wrap.setContentSize2(
            battlefieldView.width / 2,
            battlefieldView.height + 2 * cleverapps.styles.BattlefieldView.additionalHeight
        );
        wrap.addChild(battlefieldView);
        battlefieldView.setPositionRound(-wrap.width, 0);

        return wrap;
    },

    merge: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var map = new Map2d(adminLevel.content.map, {
            units: game.level.units,
            tiles: game.level.tiles,
            visibleBox: adminLevel.content.visibleBox,
            terrains: adminLevel.content.terrains,
            waterBorder: 0
        });

        map.layers = [
            // map.layers[Map2d.LAYER_WATER],
            [[]],
            [[]],
            // [[]],
            map.layers[Map2d.LAYER_GROUND],
            [[]],
            [[]]
        ];

        game.map = map;

        var view = new Map2dView(map);

        var wrap = new cc.Node();
        wrap.setContentSize2(view.width, view.height);
        wrap.setAnchorPoint2();

        view.setPositionRound({ align: "center" }, { align: "center" });

        wrap.addChild(view);

        view.setVisibleRect(cc.rect(0, 0, view.width, view.height));

        return wrap;
    },

    default: function () {
        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_miniature_bg);
        bg.setContentSize2(cleverapps.styles.AdminLevelMiniature.bg);
        return bg;
    },

    solitaire: function (adminLevel) {
        new FakeGame(adminLevel);

        var table = new CardTable();
        var screen = Game.currentGame.level.content.screens[0];
        table.setCards(screen.cards, TileTable.ListMagicCards(screen), screen.maxValue);

        var wrap = new cc.Node();
        wrap.setContentSize2(table.rect.width * resolutionScale, table.rect.height * resolutionScale);

        var view = new CardTableView(table);
        table.flipNormalize();
        table.showCards(function () {}, true);

        wrap.addChild(view);

        var viewRect = view.calcRect();

        var center = cc.p(wrap.width / 2, wrap.height / 2);

        var offset = cc.pNeg(cc.rectGetCenter(viewRect));
        var position = cc.pAdd(center, offset);
        view.setPositionRound(position);

        return wrap;
    },

    tile3: function (adminLevel) {
        new FakeGame(adminLevel);

        var table = new TileTable();
        var screen = Game.currentGame.level.content.screens[0];
        table.setCards(screen.cards, TileTable.ListMagicCards(screen), screen.maxValue);

        var wrap = new cc.Node();
        wrap.setContentSize2(table.rect.width * resolutionScale, table.rect.height * resolutionScale);

        var view = new CardTableView(table);
        table.flipNormalize();
        table.showCards(function () {}, true);

        wrap.addChild(view);

        var viewRect = view.calcRect();

        var center = cc.p(wrap.width / 2, wrap.height / 2);

        var offset = cc.pNeg(cc.rectGetCenter(viewRect));
        var position = cc.pAdd(center, offset);

        view.setPositionRound(position);
        cleverapps.UI.fitToBox(wrap, cleverapps.styles.AdminLevelMiniature);

        return wrap;
    },

    scramble: function (adminLevel) {
        var text = cleverapps.UI.generateOnlyText(adminLevel.hint, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        text.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_TOP);
        text.setDimensions(cleverapps.styles.AdminLevelMiniature.width, cleverapps.styles.AdminLevelMiniature.height / 2);
        return text;
    },

    differences: function (adminLevel) {
        var placeholder = new cc.Node();
        placeholder.setContentSize2(cleverapps.styles.AdminLevelMiniature);
        placeholder.setAnchorPoint2();

        var loading = new cc.Sprite(bundles.admin.frames.loading_png);
        placeholder.addChild(loading);
        loading.setPositionRound(placeholder.width / 2, placeholder.height / 2);

        var game = new FakeGame(adminLevel);
        var name = game.level.getImageBundle();
        if (bundles[name]) {
            cleverapps.bundleLoader.loadBundle(name, {
                onSuccess: function () {
                    var view = new cc.Sprite(bundles[name].urls.a);
                    var w = view.width;
                    var h = view.height;
                    var frame = new cc.Sprite(bundles[name].urls.a, cc.rect((w - h) / 2, 0, h, h));

                    cleverapps.UI.fitToBox(frame, cleverapps.styles.AdminLevelMiniature);
                    placeholder.addChild(frame);
                    frame.setPositionRound(placeholder.width / 2, placeholder.height / 2);

                    loading.removeFromParent();
                }
            });
        }

        return placeholder;
    },

    board: function (adminLevel) {
        var words = adminLevel.words.map(function (word, id) {
            return (id + 1) + ". " + word;
        }).join("\n");

        return cleverapps.UI.generateOnlyText(words, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
    },

    bundles: {
        match3: function () {
            var result = [];

            if (typeof Editor !== "undefined") {
                result = result.concat(Editor.BUNDLES.filter(function (bundle) {
                    return bundles[bundle];
                }));
            }

            return result;
        }
    },

    match3: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var view = AdminLevelMiniature._match3(game, adminLevel);

        view.addCleaner(function () {
            if (Game.currentGame === game) {
                delete Game.currentGame;
            }
        });

        return view;
    },

    _match3: function (game, adminLevel) {
        var field = game.field;
        adminLevel.gameField = field;

        var view = new cc.Node();
        view.setAnchorPoint2(0.5, 0.5);

        var fieldView = new cc.Node();
        fieldView.setAnchorPoint(0.5, 0.5);
        view.addChild(fieldView);

        var floorView = new FloorView();
        floorView.setLocalZOrder(-1);
        view.addChild(floorView);

        field.onTileAdded = function (tile) {
            var ViewClass = tile.getViewClass();
            var tileView = new ViewClass(tile);
            floorView.addChild(tileView);
            tileView.scale = 1;
        };

        field.onCellAdded = function (cell) {
            var ViewClass = cell.getViewClass();
            var cellView = new ViewClass(cell);
            fieldView.addChild(cellView);
            cellView.scale = 1;
        };

        field.onBackgroundAdded = floorView.addBackground.bind(floorView);

        field.load(adminLevel);

        cleverapps.UI.wrap(floorView);
        cleverapps.UI.wrap(fieldView);

        var size = fieldView.getContentSize();
        view.setContentSize(size);
        floorView.setPositionRound(size.width / 2, size.height / 2);
        fieldView.setPositionRound(size.width / 2, size.height / 2);

        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                if (field.cells[i][j]) {
                    field.cells[i][j].onAfterExplodeListener = function () {};
                }
                if (field.floor[i][j]) {
                    field.floor[i][j].onAfterExplodeListener = function () {};
                }
            }
        }

        return view;
    }
};

cleverapps.styles.AdminLevelMiniature = {
    width: 125,
    height: 125,

    x: { align: "center", dx: 0 },
    y: { align: "center", dy: 0 },

    bg: {
        width: 135,
        height: 135
    }
};