/**
 * Created by mac on 9/30/20
 */

var AdminEpisode = function (options) {
    cleverapps.EventEmitter.call(this);

    Object.assign(this, options);
};

AdminEpisode.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminEpisode.prototype.constructor = AdminEpisode;

AdminEpisode.getAddMoreEpisode = function () {
    return new AdminEpisode({
        extra: true,
        episodeNo: "#addMoreEpisode"
    });
};

AdminEpisode.create = function (index, name) {
    var episode = new AdminEpisode({
        index: index
    });
    episode.setEpisodeNo(name);
    return episode;
};

AdminEpisode.prototype.setEpisodeNo = function (name) {
    var episodeNo = parseInt(name);
    Object.assign(this, {
        name: name,
        episodeNo: name,
        start: episodeNo * Episode.LEVELS_PER_EPISODE,
        end: (episodeNo + 1) * Episode.LEVELS_PER_EPISODE - 1
    });
    this.onUpdate();
};

AdminEpisode.prototype.isAddMoreEpisode = function () {
    return this.extra !== undefined;
};

AdminEpisode.prototype.isNumeric = function () {
    var code = parseInt(this.episodeNo);
    return code === this.episodeNo;
};

AdminEpisode.prototype.isCurrent = function () {
    return this.episodeNo === cleverapps.user.episode;
};

AdminEpisode.prototype.onClick = function (f) {
    if (this.isAddMoreEpisode()) {
        cleverapps.administrator.adminEpisodes.createNew(f);
    } else {
        cleverapps.administrator.adminEpisodes.setActive(this, f);
    }
};

AdminEpisode.prototype.setDebug = function (debug) {
    if (this.debug !== debug) {
        this.debug = debug;
        this.trigger("change:debug");
    }
};

AdminEpisode.prototype.inProgress = function () {
    return AdminEpisode.InProgress(this.episodeNo);
};

AdminEpisode.prototype.getBundle = function () {
    return bundles[Episode.BundleId(this.episodeNo)];
};

AdminEpisode.InProgress = function (episodeNo, language) {
    if (!episodeNo || /[^0-9]/.test(episodeNo)) {
        return;
    }
    var bundleName = Episode.BundleId(episodeNo, language);
    return bundles[bundleName].episode.levelsAmount < 15;
};

AdminEpisode.prototype.select = function () {
    this.selected = true;
    this.trigger("select");
};

AdminEpisode.prototype.unselect = function () {
    this.selected = false;
    this.trigger("unselect");
};

AdminEpisode.prototype.hover = function () {
    if (this.isAddMoreEpisode()) {
        return;
    }
    
    cleverapps.administrator.adminEpisodes.hover(this);
};

AdminEpisode.prototype.unhover = function () {
    cleverapps.administrator.adminEpisodes.unhover(this);
};

AdminEpisode.prototype.onUpdate = function () {
    this.trigger("update");
};

AdminEpisode.prototype.onUpdateCurrent = function () {
    this.trigger("updateCurrent");
};

AdminEpisode.DEBUG_DISABLED = 1;
