/**
 * Created by vladislav on 9/6/2022
 */

var Yandex = function () {
    Platform.call(this, "yandex");
};

Yandex.prototype = Object.create(Platform.prototype);
Yandex.prototype.constructor = Yandex;

Yandex.prototype.getLocalStoragePreffix = function () {
    return "_yandex";
};

Yandex.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};

Yandex.prototype.pushSender = function () {
    return cleverapps.YandexPushesMap[cleverapps.config.name] ? cleverapps.LocalPushes.SocialSender : undefined;
};

Yandex.prototype.canReview = function () {
    return cleverapps.social.canReviewGame;
};

Yandex.prototype.requestReview = function () {
    cleverapps.social.ysdk.feedback.requestReview().then(function (res) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.SOCIAL_REVIEW + res.feedbackSent);
    });
};

Yandex.prototype.needWindowForLogin = function () {
    return true;
};

Yandex.prototype.isFullscreenAvailable = function () {
    return false;
};

Yandex.prototype.showBannerAd = function (callback) {
    cleverapps.social.ysdk.adv.showBannerAdv()
        .then(function (data) {
            console.log("showBannerAdv", data);

            if (!data || !data.stickyAdvIsShowing) {
                callback(data || true);
                return;
            }

            callback();
        })
        .catch(function (e) {
            console.error("showBannerAdv", e);

            callback(e || true);
        });
};

Yandex.prototype.hideBannerAd = function (callback) {
    cleverapps.social.ysdk.adv.hideBannerAdv()
        .then(function () {
            console.log("hideBannerAdv success");

            callback();
        })
        .catch(function (e) {
            console.error("hideBannerAd", e);

            callback(e || true);
        });
};

Yandex.prototype.getBannerAdStatus = function (callback) {
    cleverapps.social.ysdk.adv.getBannerAdvStatus()
        .then(function (data) {
            console.log("getBannerAdStatus", data);

            if (!data || !data.stickyAdvIsShowing) {
                callback(data || true);
                return;
            }

            callback();
        })
        .catch(function (e) {
            console.error("getBannerAdvStatus", e);

            callback(e || true);
        });
};