/**
 * Created by Andrey Popov on 1/27/21.
 */

var FontPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;

    if (initialValue && initialValue.general === undefined) {
        initialValue = {
            general: initialValue
        };
    }

    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;
    var defaultFontValue = {
        size: 40,
        color: new cc.Color(),
        stroke: {
            size: 0,
            color: new cc.Color()
        },
        shadow: {
            blur: 0,
            direction: {
                width: 0,
                height: 0
            },
            color: new cc.Color()
        }
    };
    var defaultValues = {
        general: defaultFontValue
    };
    defaultValues[resolution] = {};
    this.value = cleverapps.override(defaultValues, initialValue);

    this.propertyEditors = [{
        name: property.name,
        fullOnly: true,
        isBlock: true
    }, {
        name: "size",
        allowOverride: true,
        getters: [function () {
            return this.value.general.size;
        }.bind(this), function () {
            return this.value[resolution].size;
        }.bind(this)],
        setters: [function (value) {
            this.value.general.size = value;
        }.bind(this), function (value) {
            if (value) {
                this.value[resolution].size = value;
            } else {
                delete this.value[resolution].size;
            }
        }.bind(this)]
    }, {
        name: "color",
        type: "color",
        getter: function () {
            return this.value.general.color;
        }.bind(this),
        setter: function (value) {
            this.value.general.color = value;
            return this.value.general.color;
        }.bind(this)
    }, {
        name: "Stroke width",
        fullOnly: true,
        getter: function () {
            return this.value.general.stroke ? this.value.general.stroke.size : 0;
        }.bind(this),
        setter: function (value) {
            this.value.general.stroke.size = value;
        }.bind(this)
    }, {
        name: "Stroke color",
        fullOnly: true,
        type: "color",
        getter: function () {
            return this.value.general.stroke ? this.value.general.stroke.color : new cc.Color();
        }.bind(this),
        setter: function (value) {
            this.value.general.stroke.color = value;
            return this.value.general.stroke.color;
        }.bind(this)
    }, {
        name: "Shadow",
        fullOnly: true,
        getters: [function () {
            return this.value.general.shadow ? this.value.general.shadow.blur : 0;
        }.bind(this), function () {
            return this.value.general.shadow ? this.value.general.shadow.direction.width : 0;
        }.bind(this), function () {
            return this.value.general.shadow ? this.value.general.shadow.direction.height : 0;
        }.bind(this)],
        setters: [function (value) {
            this.value.general.shadow.blur = value;
        }.bind(this), function (value) {
            this.value.general.shadow.direction.width = value;
        }.bind(this), function (value) {
            this.value.general.shadow.direction.height = value;
        }.bind(this)],
        subNames: ["blur", "w", "h"]
    }, {
        name: "Shadow color",
        fullOnly: true,
        type: "color",
        getter: function () {
            return this.value.general.shadow ? this.value.general.shadow.color : new cc.Color();
        }.bind(this),
        setter: function (value) {
            this.value.general.shadow.color = value;
            return this.value.general.shadow.color;
        }.bind(this)
    }, {
        name: "",
        fullOnly: true,
        isBlock: true
    }].filter(Inspector.FilterForFullVersion).map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

FontPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    var value = sender.getString();
    if (sender instanceof cc.EditBox) {
        value = parseFloat(value);
    }
    sender.customSetter(value);
    this.onChangeValueCallback(this.property.name, this.value);

    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};