/**
 * Created by spepa on 24.02.2020.
 */

var AdminFBProducts = function () {
    this.batchesMap = [];
    this.productsToUpdate = [];

    this.onChangeStatus = function () {};
};

AdminFBProducts.prototype.setUpdateData = function (products, langs) {
    this.productsToUpdate = products;
    this.langsToUpdate = langs;
};

AdminFBProducts.prototype.processUpdate = function () {
    this.productsToUpdate = [];
    this.langsToUpdate = [];

    cleverapps.meta.display({
        focus: 'AdminFBProductsWindow',
        actions: [
            function (f) {
                new AdminFBProductsWindow(this);
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this),

            function (f) {
                if (this.productsToUpdate.length && this.langsToUpdate.length) {
                   this.makeRequest();
                }
                f();
            }.bind(this)
        ]
    });
};


AdminFBProducts.prototype.makeRequest = function () {
    this.onChangeStatus();

    var onError = function (err) {
        cleverapps.notification.create("Failed fetching fb products data");
        console.log(err);
        this.onChangeStatus();
    }.bind(this);

    cleverapps.RestClient.get("/admin/fbproducts/" + this.productsToUpdate + '/' + this.langsToUpdate, {}, this.updateProducts.bind(this), onError);
};

AdminFBProducts.prototype.updateProducts = function (data) {
    var batches = this.createBatches(data);

    batches.forEach(function (batch, ind) {
        FB.api('', 'post', {
            batch: batch
        }, function (res) {
            if (res.error) {
                console.log('Error updating batch ' + ind, res.error);
                console.log(this.batchesMap[ind]);
            } else {
                this.parseBatchResponse(res, ind);
            }

            if (ind === batches.length - 1) {
                cleverapps.notification.create("Finished fb products cache update");
                this.onChangeStatus();
            }
        }.bind(this));
    }, this);
};

AdminFBProducts.prototype.parseBatchResponse = function (batch, batchInd) {
    var batchMap = this.batchesMap[batchInd];

    batch.forEach(function (item, ind) {
        var body = JSON.parse(item.body);

        if (item.code !== 200) {
            console.log('Error updating ' + batchMap[ind], body.error);
        } else {
            if (body.error || !body.description) {
                var comment = body.error;
                if (!comment) {
                    comment = body.type === "website" ? body.title : 'empty description';
                }
                console.log('Error updating ' + batchMap[ind], comment);
            } else if (!body.image) {
                console.log('No image for ' + batchMap[ind] + ', possible wrong size (200x200 required)');
            }
        }
    });
};

AdminFBProducts.prototype.createBatches = function (productsData) {
    this.batchesMap = [];
    var batches = [], batch = [];

    var writeMap = function (name) {
        this.batchesMap[batches.length] || (this.batchesMap[batches.length] = []);
        this.batchesMap[batches.length].push(name);
    }.bind(this);

    var gather = function (data, staging) {
        for (var lang in data) {
            for (var product in data[lang]) {
                var name = (staging ? 'staging ' : 'production ') + lang + '/' + product;
                var link = data[lang][product];
                writeMap(name);

                batch.push({
                    method: 'post',
                    relative_url: '/',
                    body: "scrape=true&id=" + encodeURIComponent(link)
                });

                if (batch.length === 50) {
                    batches.push(batch);
                    batch = [];
                }
            }
        }
    };

    gather(productsData.prod);
    gather(productsData.stand, true);
    if (batch.length) batches.push(batch);

    return batches;
};