/**
 * Created by spepa on 25.05.2023
 */

var TestPlatform = function () {
    Platform.call(this, "test");
};

TestPlatform.prototype = Object.create(Platform.prototype);
TestPlatform.prototype.constructor = TestPlatform;

TestPlatform.prototype.getLocalStoragePreffix = function () {
    return "_test";
};

TestPlatform.prototype.getCurrentTournamentId = function (callback) {
    callback(1, true);
};

TestPlatform.prototype.reportScore = function (score, callback) {
    console.log("Report Score", score);
    cleverapps.notification.create("Score: " + score);
    callback();
};

TestPlatform.prototype.followOfficialPage = function () {
    var action = function () {
        new ToolConfirmWindow(function () {
            cc.sys.openURL(cleverapps.platform.getHelpUrl());
        }, "Follow official page");
    };

    if (cleverapps.meta.isFocused()) {
        action();
    } else {
        cleverapps.meta.display({
            focus: "followOfficialPage",
            action: function (f) {
                action();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    }
};

TestPlatform.prototype.showOfficialPage = function () {
    this.followOfficialPage();
};