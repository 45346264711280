/**
 * Created by decipaliz on 14.03.2023
 */

var AdminAccessWindow = Window.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.AdminAccessWindow;
        this._super({
            title: "Admin Access",
            content: this.createContent(),
            button: {
                text: "login",
                onPressed: function () {
                    window.localStorage.setItem("adminAccess_username", this.usernameBox.getString().toLowerCase().trim());
                    window.localStorage.setItem("adminAccess", cleverapps.hashCode(this.passwordBox.getString().toLowerCase().trim()));
                    window.location.reload();
                }.bind(this)
            },
            styles: cleverapps.config.ui === "riddles" ? styles.window.riddles : styles.window
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.AdminAccessWindow;

        var usernameLabel = cleverapps.UI.generateOnlyText("username:", cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_TEXT);
        var usernameBox = this.usernameBox = new cc.EditBox(cc.size(styles.usernameBox.width, styles.usernameBox.height), new cc.Scale9Sprite(bundles.windows.frames.editbox_bg_png), true);
        usernameBox.setInputMode(cc.EDITBOX_INPUT_MODE_SINGLELINE);
        usernameBox.setMaxLength(15);
        usernameBox.stayOnTop(true);
        usernameBox.setTouchEnabled();
        usernameBox.setFontColor(cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_BOX_TEXT.color);
        usernameBox.setFont(cleverapps.UI.getFontName(), cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_BOX_TEXT.size);
        var usernameLayout = new cleverapps.Layout([usernameLabel, usernameBox], { direction: cleverapps.UI.HORIZONTAL, margin: styles.usernameBox.margin });

        var passwordLabel = cleverapps.UI.generateOnlyText("password:", cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_TEXT);
        var passwordBox = this.passwordBox = new cc.EditBox(cc.size(styles.passwordBox.width, styles.passwordBox.height), new cc.Scale9Sprite(bundles.windows.frames.editbox_bg_png), true);
        passwordBox.setInputMode(cc.EDITBOX_INPUT_MODE_SINGLELINE);
        passwordBox.setInputFlag(cc.EDITBOX_INPUT_FLAG_PASSWORD);
        passwordBox.setMaxLength(15);
        passwordBox.stayOnTop(true);
        passwordBox.setTouchEnabled();
        passwordBox.setFontColor(cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_BOX_TEXT.color);
        passwordBox.setFont(cleverapps.UI.getFontName(), cleverapps.styles.FONTS.ADMIN_ACCESS_WINDOW_BOX_TEXT.size);
        var passwordLayout = new cleverapps.Layout([passwordLabel, passwordBox], { direction: cleverapps.UI.HORIZONTAL, margin: styles.passwordBox.margin });

        return new cleverapps.Layout([usernameLayout, passwordLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_ACCESS_WINDOW_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.BLACK
    },
    ADMIN_ACCESS_WINDOW_BOX_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.AdminAccessWindow = {
    margin: 50,
    usernameBox: {
        width: 300,
        height: 50,
        x: { align: "center" },
        y: { align: "center" },
        margin: 10
    },
    passwordBox: {
        width: 300,
        height: 50,
        x: { align: "center" },
        y: { align: "center" },
        margin: 10
    },
    window: {
        riddles: {
            padding: {
                top: 200,
                bottom: 80
            }
        },
        padding: {
            top: 100,
            bottom: 80
        }
    }
};
