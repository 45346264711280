/**
 * Created by r4zi4l on 29.07.2022
 */

cleverapps.unitsDemoMode = function () {
    var params = cleverapps.getRequestParameters();
    if (params.unitsDemo === undefined || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.config.demoMode = true;

    cleverapps.DataLoader.resetClientSession = function () {
        cleverapps.DataLoader.alerted = true;
        cleverapps.DataLoader.enabled = false;
        cleverapps.DataLoader.corrupted = false;
    };

    AdminEpisodes.prototype.reset = function () {

    };

    Merge.prototype.loadStaticUnits = function () {
        cleverapps.unitsDemoMode.addUnits(this.map);
    };

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        cleverapps.config.adminMode = true;

        cleverapps.flags.norest = true;
        cleverapps.flags.nologin = true;

        if (returnClassOnly) {
            return cleverapps.meta.getMainScene();
        }

        cleverapps.unitsDemoMode.gotoUnitsDemoScene(f);
    };
};

cleverapps.unitsDemoMode.gotoUnitsDemoScene = function (f) {
    var params = cleverapps.getRequestParameters();

    var pageId = params.unitsDemo;
    if (pageId === "0") {
        pageId = "main";
    }
    var page = cleverapps.travelBook.getPageById(pageId) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);

    var editorEpisodeNo = "editor";
    var editorEpisode = new Episode(editorEpisodeNo, 0);
    var bundleId = editorEpisode.bundleId();

    var demoEpisodeNo = "demo_" + params.unitsDemo;
    var demoBundleId = bundleId.replace(editorEpisodeNo, demoEpisodeNo);

    var pageLevel = page.getCurrentLevel();

    var bundle = bundles[demoBundleId] = cleverapps.clone(bundles[bundleId], true);
    bundle.episode.episodeNo = demoEpisodeNo;
    bundle.episode.levels[0].units = pageLevel.units;
    bundle.episode.levels[0].tiles = pageLevel.tiles;
    bundle.episode.levels[0].expedition = pageLevel.expedition;

    var demoEpisode = new Episode(demoEpisodeNo, 0);
    demoEpisode.getCurrentLevel().play(f);
};

cleverapps.unitsDemoMode.addUnits = function (map) {
    var getGrounds = function (y1) {
        var ground = [];
        for (var x = 0; x < map.getWidth(); x++) {
            if (map.isGround(x, y1)) {
                ground.push(cc.p(x, y1));
            }
        }
        return ground;
    };

    var rows = [];
    for (var rowInd = 0; rowInd < map.getHeight(); rowInd++) {
        var grounds = getGrounds(rowInd);
        if (grounds.length) {
            rows.push(grounds);
        }
    }

    console.trace();
    var groups = cleverapps.unitsDemoMode.getUnitsGroups(map);
    var groupInd = 0;
    var relatedUnits = [];

    var loadNextGroup = function () {
        var nextGroup = groups[groupInd++];
        relatedUnits = relatedUnits.concat(nextGroup.units.slice());
    };

    var isSameType = function () {
        var prevGroup = groups[groupInd - 1];
        var nextGroup = groups[groupInd];
        return nextGroup && nextGroup.type === prevGroup.type;
    };

    var spawnUnits = function (units, cells, cellsUsedX) {
        var start = Math.floor((cells.length - cellsUsedX) / 2);
        cells = cells.slice(start, start + cellsUsedX);

        units.forEach(function (unit) {
            unit.factory(cells[0].x, cells[0].y);
            cells.splice(0, unit.cellsUsedX);
        });
    };

    for (rowInd = 0; rowInd < rows.length;) {
        while (groupInd < groups.length && (relatedUnits.length === 0 || isSameType())) {
            loadNextGroup();
        }

        if (!relatedUnits.length) {
            return;
        }

        var row = rows[rowInd];
        var inRowUnits = [];

        var paddingCells = 4;
        var occupiedX = 0, occupiedY = 0;
        while ((row.length - paddingCells) > occupiedX && relatedUnits.length > 0) {
            inRowUnits.push(relatedUnits.shift());
            occupiedX += inRowUnits.at(-1).cellsUsedX;
            occupiedY = Math.max(occupiedY, inRowUnits.at(-1).cellsUsedY);
        }
        spawnUnits(inRowUnits, row, occupiedX);

        rowInd += occupiedY + (relatedUnits.length > 0 ? 0 : 1);
    }

    var remainingGroups = groups.slice(groupInd);
    if (remainingGroups.length) {
        console.error("Remaining groups:", remainingGroups);
        cleverapps.throwAsync("Unable to place all groups in unitsDemo");
    }
};

cleverapps.unitsDemoMode.getUnitsGroups = function (map) {
    var groups = [];

    Families.codes.forEach(function (code) {
        if (["unknown", "multiCellBody", "placeholder", "specialoffer", "initialize", "decorator", "landmarkspot"].includes(code)) {
            return;
        }

        if (!Game.currentGame.level.families[code]) {
            return;
        }

        if (!bundles["unit_" + code]) {
            console.error("No bundle for " + code + "!");
            return;
        }

        console.log("Has " + code);

        var family = Families[code];
        var group = {
            code: code,
            type: family.type,
            layer: Map2d.LAYER_UNITS,
            units: []
        };

        var stages = family.units.length - 1;
        if (family.units[0].mission) {
            stages = 0;
        }

        cleverapps.rangeArray(0, stages).forEach(function (stage) {
            var unit = new Unit({
                code: code,
                stage: stage
            });
            unit.chooser = true;

            if (!UnitView.UnitIconFrame(unit) && !UnitView.UnitIconJson(unit)) {
                console.log("No frame for " + code + (stage + 1));
                return;
            }

            if (Families[code].units[stage].deleted) {
                return;
            }

            group.units.push({
                cellsUsedX: Families[code].units[stage].multicell ? 3 : 1,
                cellsUsedY: Families[code].units[stage].multicell ? 2 : 1,
                factory: function (x, y) {
                    unit.setPosition(x, y);
                    map.add(Map2d.LAYER_UNITS, x, y, unit);
                    return unit;
                }
            });
        });

        groups.push(group);
    });

    return groups;
};
