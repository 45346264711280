/**
 * Created by slava on 4/5/17.
 */

cleverapps.SocialManager = {
    NoSocialPlatforms: [Pliega, Rustore, Huzcom, IOSCh, Playable, GDCom, Xiaomi, PWA],

    create: function () {
        if (cleverapps.platform.oneOf(cleverapps.SocialManager.NoSocialPlatforms)) {
            return new cleverapps.NoSocial();
        }

        if (cc.sys.isNative && [cc.sys.OS_ANDROID, cc.sys.OS_IOS].includes(cc.sys.os) && !(cleverapps.platform instanceof GPG)) {
            if (cleverapps.AndroidSocial.isAvailable() || cleverapps.AppleSocial.isAvailable()) {
                return new cleverapps.SelectSocial();
            }
            return cleverapps.SelectSocial.selectMobileFB();
        }

        var socials = TestSocial.isAppropriate() ? {
            default: TestSocial
        } : {
            instant: cleverapps.InstantSocial,
            mobile_ok: cleverapps.OKSocial,
            web_ok: cleverapps.OKSocial,
            mobile_mm: cleverapps.MobileMyMailRuSocial,
            web_mm: cleverapps.MyMailRuSocial,
            mobile_vk: cleverapps.MobileVkSocial,
            web_vk: cleverapps.VKSocial,
            sp_mbga: cleverapps.SPMobageSocial,
            mbga: cleverapps.MobageSocial,
            draugiem: cleverapps.DraugiemSocial,
            fotostrana: cleverapps.FotoStranaSocial,
            cleverapps: cleverapps.XsollaSocial,
            yandex: cleverapps.YandexSocial,
            macos: cleverapps.AppleSocial,
            gpg: cleverapps.AndroidSocial,
            mygames: cleverapps.MygamesSocial,
            microsoft: cleverapps.MicrosoftFacebook,
            msstart: cleverapps.MSStartSocial,
            plng: cleverapps.PlingaSocial,
            kongregate: cleverapps.KongregateSocial,
            default: cleverapps.FacebookCanvasSocial
        };

        var Constuctor = socials[cleverapps.platform.source] || socials.default;

        return new Constuctor();
    },

    onFailedLogin: function () {
        cleverapps.notification.create("SocialManager.FailedLogging");
    },

    onLogin: function () {
        if (levels.user.haveTmpId() || levels.user.haveDeviceId()) {
            var oldTmpId = levels.user.id;

            var newId = cleverapps.social.getUserID();
            cleverapps.RestClient.post("/users/off/" + oldTmpId, { newId: newId }, function (response) {
                console.log("Success marked off: " + oldTmpId + ", newId: " + newId + ", response " + JSON.stringify(response));
            }, function () {
                console.log("Failed marking off: " + oldTmpId + ", newId: " + newId);
            });
            levels.user.setId(newId);
            levels.user.setLoggedIn(Date.now());

            cleverapps.synchronizer.reset();

            cleverapps.info.onChangeUserId();

            if (cleverapps.userClan) {
                cleverapps.userClan.changeUserId(oldTmpId, newId);
                cleverapps.userClan.load();
            }
        } else if (levels.user.id !== cleverapps.social.getUserID()) {
            console.log("second login! old - " + levels.user.id + ", new - " + cleverapps.social.getUserID());

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.SECOND_LOGIN);

            if (!cc.sys.isNative && !cleverapps.platform.oneOf(Yandex, MSStart)) {
                cleverapps.DataLoader.cleanAll();
                window.location.reload();
                return;
            }

            levels.user.setId(cleverapps.social.getUserID());
            cleverapps.synchronizer.reset();
        }

        cleverapps.DataLoader.processSaveQueue();
        cleverapps.social.deleteAllRequests();

        cleverapps.synchronizer.syncWhenReady();

        cleverapps.whenAllInitialized(this.onLoginRefreshes.bind(this));
    },

    onLoginRefreshes: function () {
        if (!cleverapps.platform.oneOf(MobileVk, VKPlatform, GDCom, Plinga)) {
            cleverapps.notification.create("SocialManager.Connected");
        }

        // console.log('About me');
        cleverapps.social.aboutMe(this.onLoadUserInfo.bind(this));
        // console.log('Done about me');

        cleverapps.user.calcIdfa();

        cleverapps.friends.reload();
        cleverapps.invitalbleFriends.reload();
        cleverapps.rewardedAdsManager.configureActiveAds();
    },

    onLoadUserInfo: function (user) {
        // console.log(JSON.stringify(user));
        cleverapps.friends.updatePlayerInfo(user);

        if (user.currency && user.currency.user_currency) {
            var userCurrency = user.currency.user_currency;

            if (Product.CURRENCY_SIGNS[userCurrency]) {
                Product.CURRENCY = Product.CURRENCY_SIGNS[userCurrency];
                Product.CURRENCY_CODE = userCurrency;
            }
        }

        if (user.first_name && !cleverapps.flags.nologin) {
            setTimeout(function () {
                var image;
                var player = cleverapps.friends.getPlayer();
                if (player.avatar && player.avatar !== FriendsCache.DEFAULT_PLAYER_INFO().avatar) {
                    image = new cleverapps.UI.Avatar(player);
                    if (!image.width || !image.height) {
                        image = undefined;
                    }
                }
                cleverapps.notification.create("SocialManager.Welcome", {
                    toReplace: {
                        userName: user.first_name
                    },
                    image: image
                });
            }, 1000);
        }

        if (user.name) {
            var name = user.name.substr(0, 50);
            cleverapps.info.setKeyValue("name", name);
        }

        if (user.picture && user.picture.data && user.picture.data.url) {
            cleverapps.info.setKeyValue("avatar", user.picture.data.url);
        }

        if (cleverapps.periodicBonus) {
            cleverapps.periodicBonus.refreshItems();
        }
    }
};
