/**
 * Created by razial on 03.12.2020
 */

var StickersCollectionWindow = Window.extend({
    onWindowLoaded: function (mission) {
        var styles = cleverapps.styles.StickersCollectionWindow;

        this.game = new StickersCollection(mission);

        this.generateContent();

        var options = {
            title: "StickersCollectionWindow.Title",
            name: "stickerscollectionwindow",
            content: this.content,
            styles: cleverapps.styles.StickersCollectionWindow.window,
            help: this.onClickHelp.bind(this),
            openSound: bundles.main.urls.stickers_collection_effect
        };

        if (styles.button) {
            options.button = {
                width: styles.button.width,
                text: "OK",
                onPressed: this.close.bind(this)
            };
        }

        this._super(options);

        if (mission.isRunning()) {
            var timeLeft = mission.getTimeLeft();
            if (timeLeft > 0) {
                this.timer = new cleverapps.CountDown(timeLeft, {
                    onFinish: this.close.bind(this)
                });
            }
        }

        if (this.game.isFinished()) {
            this.game.finish({ silent: true });
        }

        mission.needShowStartWindow = false;
    },

    onExit: function () {
        this._super();

        if (this.timer) {
            this.timer.stop();
        }
    },

    onClose: function () {
        this.game.checkIfComplete();
    },

    onClickHelp: function () {
        new GuideWindow(MissionRulesOptionsBuilder.build(this.game.mission));
    },

    generateContent: function () {
        var styles = cleverapps.styles.StickersCollectionWindow;

        var book = this.createBook();

        var text = cleverapps.UI.generateOnlyText("StickersCollectionWindow.text", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.book.width, 0);

        var currentValue = Math.min(this.game.mission.result, StickersCollection.GEMS);
        var goalValue = StickersCollection.GEMS;

        this.prize = new PrizeBarComponent({
            currentValue: currentValue,
            goalValue: goalValue,
            reward: RewardsConfig.StickersCollection,
            onCollect: this.createListener(this.close.bind(this))
        });

        this.content = new cleverapps.Layout([book, text, this.prize], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
        this.content.setLocalZOrder(2);
    },

    createBook: function () {
        var styles = cleverapps.styles.StickersCollectionWindow;

        var bookAnimation = new cleverapps.Spine(bundles.stickers_collection.jsons.book, 1);
        bookAnimation.setAnimation(0, "animation", false);
        bookAnimation.addAnimation(0, "idle", true);
        bookAnimation.setPositionRound({ align: "center" }, { align: "center" });

        var bookNode = this.bookNode = new cc.Node();
        bookNode.setAnchorPoint2();
        bookNode.setContentSize2(styles.book);
        bookNode.addChild(bookAnimation);

        if (bundles.stickers_collection.jsons.light) {
            var light = new cleverapps.Spine(bundles.stickers_collection.jsons.light, 1);
            light.setAnimation(0, "animation", false);
            light.setPositionRound({ align: "center" }, { align: "center" });
            bookNode.addChild(light);
        }

        this.game.open();

        return bookNode;
    },

    listBundles: function () {
        return ["stickers_collection"];
    }
});

cleverapps.styles.StickersCollectionWindow = {
    margin: 65,

    book: {
        width: 600,
        height: 400
    },

    button: {
        width: 160
    },

    padding: {
        top: 25,
        bottom: 30
    }
};
