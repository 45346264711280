/**
 * Created by Andrey Popov on 15.06.2021.
 */

var ScriptView = cleverapps.Layout.extend({
    ctor: function (scriptFileName, width) {
        this.scriptFileName = scriptFileName;

        var styles = cleverapps.styles.ScriptView;

        var editAreas = [{
            caption: "Script name",
            name: "scriptName"
        }, {
            caption: "Parameters",
            name: "parameters"
        }, {
            caption: "Source code in JS",
            name: "text"
        }].map(function (editArea) {
            var areaCaption = cleverapps.UI.generateOnlyText(editArea.caption, cleverapps.styles.FONTS.AREA_CAPTION_TEXT);

            var areaBg = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component);
            areaBg.setContentSize(width - styles.textPadding, styles[editArea.name].height);

            var editBox = this[editArea.name] = new cc.EditBox(cc.size(areaBg.width - styles.textPadding, styles[editArea.name].height));
            editBox.setDelegate(this);
            editBox.stayOnTop(true);
            editBox.setTouchEnabled();
            editBox.setMaxLength(10000);
            editBox.setPositionRound(areaBg.width / 2, areaBg.height / 2);
            areaBg.addChild(editBox);

            return new cleverapps.Layout([areaCaption, areaBg], {
                direction: cleverapps.UI.VERTICAL
            });
        }.bind(this));

        this.showScriptInfo();

        var gitButtons = this.gitButtons = new GitButtons("cleverapps/res/wysiwyg/scripts");
        var gitButtonsView = new GitButtonsView(gitButtons);
        gitButtons.checkStatus();
        gitButtons.on("statusChanged", this.handleGitStatusChanged.bind(this));
        gitButtonsView.setPositionRound(styles.buttons);

        this._super(editAreas.reverse().concat(gitButtonsView), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        this.saveChanges = cleverapps.accumulate(300, this.saveChanges.bind(this));
    },

    showScriptInfo: function () {
        var scriptInfo = cleverapps.wysiwyg.assets.scripts.filter(function (script) {
            return script.fileName === this.scriptFileName;
        }.bind(this))[0];

        if (!scriptInfo) {
            return;
        }

        var flag = !(cleverapps.meta.isFocused() && cleverapps.meta.focus === "GitInfoWindow");
        this.scriptName.stayOnTop(flag);
        this.parameters.stayOnTop(flag);
        this.text.stayOnTop(flag);

        this.scriptName.setString(scriptInfo.name);
        this.parameters.setString(scriptInfo.parameters);
        this.text.setString(scriptInfo.exec);

        this.scriptName.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.scriptName.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
        this.parameters.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.parameters.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
        this.text.setFontColor(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.color);
        this.text.setFontSize(cleverapps.styles.FONTS.SCRIPT_BODY_TEXT.size);
    },

    handleGitStatusChanged: function () {
        cleverapps.wysiwyg.assets.load(this.showScriptInfo.bind(this));
    },

    editBoxTextChanged: function () {
        this.saveChanges();
    },

    editBoxEditingDidEnd: function () {
        this.saveChanges();
        this.gitButtons.checkStatus();
    },

    saveChanges: function () {
        cleverapps.wysiwyg.assets.saveScript(this.scriptFileName, this.scriptName.getString(), this.text.getString(), this.parameters.getString());
    }
});

cleverapps.styles.ScriptView = {
    margin: 20,

    buttons: {
        x: { align: "center" },
        y: { align: "center" }
    },

    textPadding: 20,

    scriptName: {
        height: 30
    },

    parameters: {
        height: 160
    },

    text: {
        height: 600
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCRIPT_BODY_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    AREA_CAPTION_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});