/**
 * Created by andrey on 19.02.18.
 */

var PackIcon = function () {
    SideBarIcon.call(this, {
        lottery: true
    });
};

PackIcon.prototype = Object.create(SideBarIcon.prototype);
PackIcon.prototype.constructor = PackIcon;

PackIcon.prototype.resetState = function () {
    this.available = cleverapps.packManager.isAvailable();

    if (this.available) {
        var stage = cleverapps.packManager.get().stage;
        var animations = [bundles.sidebar.jsons.pack_icon_json, bundles.sidebar.jsons.pack1_icon_json, bundles.sidebar.jsons.pack2_icon_json];
        if (cleverapps.config.name === "mergecraft" && stage > 0) {
            var skin = "0";
        }
        this.setAnimation(animations[stage], skin);
    }
};

PackIcon.prototype.onPressed = function () {
    var controls = ["MenuBarGoldItem", "MenuBarLivesItem", "MenuBarCoinsItem", "MenuBarWandsItem", "MenuBarWorkersItem"];
    cleverapps.meta.display({
        focus: "PackWindow",
        control: controls,
        action: function (f) {
            new PackWindow();
            cleverapps.meta.onceNoWindowsListener = f;
        }
    });
};
