/**
 * Created by Andrey Popov on 20.01.2021.
 */

var WysiwygPreviewView = WysiwygWindowBase.extend({
    ctor: function (wysiwygPreview, options) {
        this._super("Preview", options.width, options.height);

        this.wysiwygPreview = wysiwygPreview;

        var canvas = this.canvas = new cc.Node();
        canvas.setAnchorPoint2();
        this.addChild(canvas, -1);

        this.wysiwygPreview.on("clearComponents", this.clearComponents.bind(this), this);
        this.wysiwygPreview.on("showLoading", function () {
            this.toggleLoading(true);
        }.bind(this), this);
        this.wysiwygPreview.on("hideLoading", function () {
            this.toggleLoading(false);
        }.bind(this), this);
        this.wysiwygPreview.on("showComponents", this.showComponents.bind(this), this);
        this.wysiwygPreview.on("showDirectory", this.showDirectory.bind(this), this);
        this.wysiwygPreview.on("showScript", this.showScript.bind(this), this);
        this.wysiwygPreview.on("updateComponent", this.updateComponent.bind(this), this);
        this.wysiwygPreview.on("selectComponent", this.selectComponent.bind(this), this);

        this.shadow = new ShadowLayer();
        this.shadow.setOpacity(0);
        this.addChild(this.shadow);
        this.loading = new cc.Sprite(bundles.wysiwyg.frames.loading);
        this.loading.runAction(new cc.RepeatForever(new cc.RotateBy(5, 360)));
        this.loading.setPositionRound(this.width / 2, this.height / 2);
        this.toggleLoading(false);
        this.addChild(this.loading);

        var eye = new cc.Sprite(bundles.editor_controls.frames.component_item);
        eye.setPositionRound(cleverapps.styles.WysiwygPreviewView.eye);
        cleverapps.UI.applyHover(eye);
        cleverapps.UI.onClick(eye, function () {
            wysiwygPreview.toggleState();
            eye.setSpriteFrame(wysiwygPreview.enabled ? bundles.editor_controls.frames.component_item : bundles.wysiwyg.frames.clip_item);
        });
        this.addChild(eye);
    },

    clearComponents: function () {
        if (this.directoryView) {
            this.directoryView.removeFromParent();
            this.directoryView = undefined;
        }
        if (this.scriptView) {
            this.scriptView.removeFromParent();
            this.scriptView = undefined;
        }
        if (this.featuresSelector) {
            this.featuresSelector.removeFromParent();
            this.featuresSelector = undefined;
        }
        this.canvas.removeAllChildren();
    },

    showDirectory: function (directoryItems) {
        this.createScroll(new DirectoryView(directoryItems));
        this.directoryView = this.scroll;
        this.scroll.scrollToPercent(100);
    },

    showScript: function (scriptFileName) {
        this.toggleLoading(false);
        this.changeCursor(false);
        this.createScroll(new ScriptView(scriptFileName, this.width));
        this.scriptView = this.scroll;
    },

    showFeaturesSelector: function () {
        var styles = cleverapps.styles.WysiwygPreviewView.featuresSelector;
        var selectedClip = cleverapps.wysiwyg.hierarchy.getSelectedClip();

        var features = [new SelectorView(
            selectedClip ? selectedClip.properties.resolutions.filter(function (item) {
                return Wysiwyg.VIDEO_SIZES[item];
            }) : [],
            this.wysiwygPreview.selectedResolution,
            this.wysiwygPreview.selectResolution.bind(this.wysiwygPreview)
        )];

        if (selectedClip && selectedClip.children.length !== 1) {
            features.unshift(new PreviewSliderView(this.wysiwygPreview.wysiwyg.previewSlider));
        }

        this.featuresLayout = new cleverapps.Layout(features, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this.featuresSelector = new cleverapps.UI.ScrollView(this.featuresLayout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            outOfBoundaryScale: 0
        });
        this.featuresSelector.setContentSize2(this.width - styles.offset, styles.height);
        this.featuresSelector.setPositionRound(styles);
        this.featuresSelector.scrollToDefault();
        this.addChild(this.featuresSelector);
    },

    showComponents: function (clips, callback) {
        var styles = cleverapps.styles.WysiwygPreviewView;

        if (this.clipScenes) {
            this.clipScenes.forEach(function (clipScene) {
                clipScene.destroy();
                clipScene.removeFromParent();
            });
        }

        var size = this.wysiwygPreview.canvasSize;
        var scaleX = Math.min((this.width - styles.padding.x) / size.width, (this.width - styles.padding.x) / this.width);
        var scaleY = Math.min((this.height - styles.padding.y) / size.height, (this.height - styles.padding.y) / this.height);
        var scale = Math.min(scaleX, scaleY);

        this.canvas.setContentSize2(size);
        this.canvas.setScale(scale);
        this.canvas.setPositionRound(styles);

        var clipRows = [];
        var rowIndex = -1;
        this.clipScenes = [];
        // eslint-disable-next-line no-nested-ternary
        var clipsInRow = clips.length % 3 === 0 ? 3 : clips.length === 4 ? 2 : 4;
        var requiredWidth = 0;
        var requiredHeight = 0;

        var gameComponents = 0;

        callback = cleverapps.wait(clips.length, callback || function () {});

        clips.forEach(function (clip, index) {
            if (index % clipsInRow === 0) {
                rowIndex++;
                clipRows.push([]);
            }
            var clipScene = new ClipScene(clip, {
                size: this.canvas.getContentSize(),
                preview: true,
                onInit: callback
            });

            if (clipScene.snapshotComponent) {
                gameComponents++;
            }

            this.clipScenes.push(clipScene);
            clipRows[rowIndex].push(clipScene);

            if (rowIndex === 0) {
                requiredWidth += clipScene.width;
            }
            if (index % clipsInRow === 0) {
                requiredHeight += clipScene.height;
            }
        }.bind(this));

        if (gameComponents > 1) {
            this.clipScenes.forEach(function (clipScene) {
                if (clipScene.snapshotComponent) {
                    clipScene.snapshotComponent.skipPreviewInit = true;
                }
            });
        }

        var clipsLayout = new cleverapps.Layout(clipRows.map(function (row) {
            return new cleverapps.Layout(row, { direction: cleverapps.UI.HORIZONTAL });
        }), { direction: cleverapps.UI.VERTICAL });
        clipsLayout.setScale(Math.min(Math.max(this.canvas.width, this.width) / requiredWidth, Math.max(this.canvas.height, this.height) / requiredHeight));
        clipsLayout.setPositionRound(this.canvas.width / 2, this.canvas.height / 2);

        this.canvas.addChild(clipsLayout);

        this.changeCursor(false);

        this.showFeaturesSelector();
    },

    toggleLoading: function (visible) {
        var styles = cleverapps.styles.WysiwygPreviewView;
        this.loading.setVisible(visible);
        this.changeCursor(visible);

        if (visible) {
            if (!this.savedLocalZorder) {
                this.savedLocalZorder = this.getLocalZOrder();
                this.setLocalZOrder(1000);
            }
            this.shadow.stopAllActions();
            this.shadow.visible = true;

            this.shadow.setContentSize2(this.width - styles.padding.x, this.height - styles.padding.y);
            this.shadow.setPositionRound(styles.padding.x / 2, 0);
            this.shadow.runAction(new cc.FadeTo(0.2, 100));
        } else {
            if (this.savedLocalZorder) {
                this.setLocalZOrder(this.savedLocalZorder);
                delete this.savedLocalZorder;
            }
            this.shadow.stopAllActions();

            this.shadow.runAction(new cc.Sequence(
                new cc.FadeTo(0.2, 0),
                new cc.CallFunc(function () {
                    this.shadow.visible = false;
                }.bind(this))
            ));
        }
    },

    updateComponent: function (item) {
        this.clipScenes.forEach(function (clipScene) {
            clipScene.redrawComponent(item);
        });
    },

    selectComponent: function (item) {
        this.clipScenes.forEach(function (clipScene) {
            clipScene.selectComponent(item);
        });
    },

    changeCursor: function (waiting) {
        if (!cc.sys.isNative && "mouse" in cc.sys.capabilities) {
            cc._canvas.style.cursor = waiting ? "wait" : "default"; 
        }
    }
});

cleverapps.styles.WysiwygPreviewView = {
    padding: {
        x: 20,
        y: 40
    },

    eye: {
        x: { align: "left", dx: 10 },
        y: { align: "top", dy: -40 }
    },

    featuresSelector: {
        x: { align: "left", dx: 150 },
        y: { align: "top", dy: 20 },

        offset: 170,
        height: 50,
        margin: 15,
        padding: {
            left: 20,
            right: 20
        }
    },

    x: { align: "center" },
    y: { align: "center", dy: -16 }
};