/**
 * Created by slava on 4/21/17.
 */

cleverapps.InstantSocial = function () {
    console.log("Choosing Instant-facebook");

    cleverapps.Social.call(this);

    SelectFriendWindow.MAX_SELECTED = 1;
};

cleverapps.InstantSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.InstantSocial.prototype.constructor = cleverapps.InstantSocial;

cleverapps.InstantSocial.prototype._update = function (options, callback) {
    this.getBase64Image(Share.getData("default").picture, function (err, image) {
        if (err) {
            console.log(err);
            callback(cleverapps.Social.CODE_FAILED);
        } else {
            callback(cleverapps.Social.CODE_SUCCEED);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.INSTANT.REQUEST);
            FBInstant.updateAsync({
                action: "CUSTOM",
                image: image,
                text: {
                    default: options.message
                },
                template: options.template,
                strategy: "IMMEDIATE",
                notification: "PUSH"
            });
        }
    });
};

cleverapps.InstantSocial.prototype.isLoggedIn = function () {
    return Boolean(FBInstant.player.getID());
};

cleverapps.InstantSocial.prototype.getUserID = function () {
    return FBInstant.player.getID();
};

cleverapps.InstantSocial.prototype.request = function (options, callback) {
    FBInstant.context.createAsync(options.to[0]).then(function () {
        this._update({
            template: "request",
            message: options.message
        }, callback);
    }.bind(this)).catch(function (error) {
        console.log("send request to " + options.to[0] + " failed", error);

        if (["SAME_CONTEXT", "INVALID_PARAM"].indexOf(error.code) !== -1) {
            callback(cleverapps.Social.CODE_SUCCEED);
            return;
        }

        callback(cleverapps.Social.CODE_FAILED);
    });
};

cleverapps.InstantSocial.prototype.inviteFriends = function (data, callback) {
    this.getBase64Image(Share.getData("default").picture, function (err, image) {
        if (err) {
            console.log(err);
            callback(cleverapps.Social.CODE_FAILED);
        } else {
            FBInstant.inviteAsync({
                image: image,
                text: data.message,
                cta: data.title
            }).then(function () {
                callback(cleverapps.Social.CODE_SUCCEED);
            }).catch(function () {
                callback(cleverapps.Social.CODE_FAILED);
            });
        }
    });
};

cleverapps.InstantSocial.prototype.shareDialog = function (data, callback) {
    this.getBase64Image(data.picture, function (err, image) {
        if (err) {
            console.log(err);
            callback(cleverapps.Social.CODE_FAILED);
        } else {
            cleverapps.countPendingRequests(FBInstant.shareAsync({
                image: image,
                text: data.description,
                cta: data.title,
                shareDestination: ["NEWSFEED"],
                switchContext: false
            })).then(function () {
                callback(cleverapps.Social.CODE_SUCCEED);
            }).catch(function () {
                callback(cleverapps.Social.CODE_FAILED);
            });
        }
    });
};

cleverapps.InstantSocial.prototype.waitWindowCallback = function (callback) {
    var waitWindow = undefined;

    if (!cleverapps.meta.isFocused()) {
        cleverapps.meta.display({
            focus: "waitWindowCallback",
            action: function (f) {
                waitWindow = new WaitWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    } else {
        waitWindow = new WaitWindow();
    }

    return function () {
        if (waitWindow) {
            waitWindow.close();
        }
        callback.apply(this, arguments);
    };
};

cleverapps.InstantSocial.prototype.drawBase64 = function (image) {
    var canvas = document.createElement("canvas");
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    canvas.getContext("2d").drawImage(image, 0, 0);
    return canvas.toDataURL("image/jpg");
};

cleverapps.InstantSocial.prototype.getBase64Image = function (image, callback) {
    var onFinish = this.waitWindowCallback(callback);

    cc.loader.load(image, function (err, images) {
        var loadedImage = (images && Array.isArray(images) && images.length > 0) ? images[0] : undefined;

        if (!err && loadedImage === undefined) {
            err = cleverapps.Social.CODE_FAILED;
        }

        onFinish(err, !err ? this.drawBase64(loadedImage.getHtmlElementObj()) : undefined);
    }.bind(this));
};

cleverapps.InstantSocial.prototype.listFriends = function (callback) {
    FBInstant.player.getConnectedPlayersAsync()
        .then(function (players) {
            var list = players.map(function (player) {
                return {
                    id: player.getID(),
                    first_name: player.getName(),
                    picture: {
                        data: {
                            url: player.getPhoto()
                        }
                    }
                };
            });

            callback(cleverapps.Social.CODE_SUCCEED, list);
        })
        .catch(function () {
            callback(cleverapps.Social.CODE_FAILED);
        });
};

cleverapps.InstantSocial.prototype.listInvitableFriends = function (callback) {
    callback(cleverapps.Social.CODE_SUCCEED, []);
};

cleverapps.InstantSocial.prototype.aboutMe = function (callback) {
    callback({
        currency: {
            user_currency: "USD"
        },
        name: FBInstant.player.getName(),
        first_name: FBInstant.player.getName(),
        picture: {
            data: {
                url: FBInstant.player.getPhoto()
            }
        }
    });
};

cleverapps.InstantSocial.prototype.getAccessToken = function () {
    return "instant";
};
