/**
 * Created by slava on 4/21/17.
 */

cleverapps.MobileMyMailRuPayments = function () {
    cleverapps.MyMailRuPayments.call(this);
};

cleverapps.MobileMyMailRuPayments.prototype = Object.create(cleverapps.MyMailRuPayments.prototype);
cleverapps.MobileMyMailRuPayments.prototype.constructor = cleverapps.MobileMyMailRuPayments;

cleverapps.MobileMyMailRuPayments.prototype.init = function (callback) {
    callback();
};

cleverapps.MobileMyMailRuPayments.prototype.onPurchaseResult = function () {
    cleverapps.RestPayments.prototype.onPurchaseResult.apply(this, arguments);

    this.closeWidgetWindow();
    this.stopWidgetChecker();
};

cleverapps.MobileMyMailRuPayments.prototype._purchaseOpen = function (product) {
    this.setCancelTimeout(cleverapps.parseInterval("30 minutes"));

    var request = cleverapps.social.getRequestParameters();
    var params = {
        appid: cleverapps.config.mm.appId,
        session_key: request.session_key,
        service_id: product.id.mymailru,
        service_name: product.title,
        mailiki_price: parseInt(product.loadedPrice),
        mob: 1
    };

    var query = [];
    for (var key in params) {
        if (key === "service_name") {
            var param = key + "=" + params[key].split("").map(function (char) {
                if (char.match(/[а-я]/i)) {
                    return char;
                }
                return encodeURIComponent(char);
            }).join("");
            query.push(param);
        } else {
            query.push(key + "=" + encodeURIComponent(params[key]));
        }
    }

    this.openWidgetWindow("http://m.my.mail.ru/cgi-bin/app/paymentm?" + query.join("&"));
    this.startWidgetChecker();
};

cleverapps.MobileMyMailRuPayments.prototype.closeWidgetWindow = function () {
    if (this.widgetWindow) {
        this.widgetWindow.close();
        delete this.widgetWindow;
    }
};

cleverapps.MobileMyMailRuPayments.prototype.openWidgetWindow = function (query) {
    this.closeWidgetWindow();

    this.widgetWindow = window.open(query);
};

cleverapps.MobileMyMailRuPayments.prototype.stopWidgetChecker = function () {
    clearInterval(this.widgetChecker);
    delete this.widgetChecker;
};

cleverapps.MobileMyMailRuPayments.prototype.startWidgetChecker = function () {
    this.stopWidgetChecker();

    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !this.purchaseHandler) {
        return;
    }

    var onWidgetProcessed = function () {
        this.closeWidgetWindow();
        this.stopWidgetChecker();

        if (this.purchaseProduct) {
            this.startPurchaseChecker(this.purchaseProduct, {
                timeouts: cleverapps.arrayFill(15, "1 seconds")
            });
            this.setCancelTimeout(15000);
        }
    }.bind(this);

    this.widgetChecker = setInterval(function () {
        try {
            var widgetWindow = this.widgetWindow;
            if (widgetWindow && widgetWindow.location
                && (!widgetWindow.location.href || widgetWindow.location.href.indexOf("vid=") >= 0)) {
                onWidgetProcessed();
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }.bind(this), 500);
};

cleverapps.MobileMyMailRuPayments.isAppropriate = function () {
    return cleverapps.platform instanceof MobileMyMailRu;
};