/**
 * Created by slava on 4/21/17.
 */

cleverapps.FacebookCanvasSocial = function () {
    console.log("Choosing html5-facebook");

    cleverapps.BaseFB.call(this);
};

cleverapps.FacebookCanvasSocial.prototype = Object.create(cleverapps.BaseFB.prototype);
cleverapps.FacebookCanvasSocial.prototype.constructor = cleverapps.FacebookCanvasSocial;

cleverapps.FacebookCanvasSocial.prototype.init = function (callback) {
    var timeout;
    var fbInitInterval;

    var callCallback = cleverapps.once(function () {
        setTimeout(callback, 500);

        if (fbInitInterval !== undefined) {
            clearInterval(fbInitInterval);
            fbInitInterval = undefined;
        }

        if (timeout !== undefined) {
            clearTimeout(timeout);
            timeout = undefined;
        }

        cleverapps.social.deleteAllRequests();
    });

    var onFailure = function (reason) {
        if (typeof plugin !== "undefined"
            && plugin.FacebookAgent
            && plugin.FacebookAgent.getInstance()
            && plugin.FacebookAgent.getInstance()._isLoggedIn !== undefined) {
            callCallback();
            return;
        }

        if (typeof plugin === "undefined") {
            plugin = {};
        }
        plugin.FacebookAgent = cleverapps.FakeFacebookAgent;
        callCallback();

        if (reason === "timeout") {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_TIMEOUT);
        } else {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FAKEFB_BY_SCRIPT_ERROR);
        }
    };

    var onSuccess = function () {
        timeout = setTimeout(function () {
            onFailure("timeout");
        }, 10000);
    };

    // console.log('LOADING SDK', cleverapps.loadSdk);
    var path = "//connect.facebook.net/en_US/sdk.js";
    cleverapps.loadSdk(path, { onSuccess: onSuccess, onFailure: onFailure });

    window.fbAsyncInit = function () {
        if (plugin.FacebookAgent) {
            return;
        }

        cleverapps.config.plugin = {
            facebook: {
                status: true,
                version: "v3.3",
                appId: cleverapps.config.facebook.appId
            }
        };

        cleverapps.StartFacebook();

        fbInitInterval = setInterval(function () {
            if (plugin.FacebookAgent && plugin.FacebookAgent.getInstance() && plugin.FacebookAgent.getInstance()._isLoggedIn !== undefined) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FACEBOOK_INITED);
                callCallback();
            }
        }, 50);
    };
};

cleverapps.FacebookCanvasSocial.prototype.isLoggedIn = function () {
    return this.initialized && plugin.FacebookAgent.getInstance().isLoggedIn();
};

cleverapps.FacebookCanvasSocial.prototype.getUserID = function () {
    return plugin.FacebookAgent.getInstance().getUserID();
};

cleverapps.FacebookCanvasSocial.prototype.getAccessToken = function () {
    return plugin.FacebookAgent.getInstance().getAccessToken();
};

cleverapps.FacebookCanvasSocial.prototype._login = function (callback) {
    if (!this.isInitialized()) {
        cleverapps.notification.create("SocialManager.Initialization");
        onFailure && onFailure();
        return;
    }

    cleverapps.platform.exitFullscreen(function () {
        plugin.FacebookAgent.getInstance().login(cleverapps.BaseFB.PERMISSIONS, callback);
    });
};

cleverapps.FacebookCanvasSocial.prototype.login = function (onSuccess, onFailure) {
    this._login(function (code) {
        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.SocialManager.onLogin();
            onSuccess();
        } else {
            cleverapps.SocialManager.onFailedLogin();
            onFailure && onFailure();
        }
    });
};

cleverapps.FacebookCanvasSocial.prototype.inviteFriends = function (options, callback) {
    this.request(options, callback);
};

cleverapps.FacebookCanvasSocial.prototype.request = function (options, callback) {
    delete options.element;

    if (typeof options.to === "object") {
        options.to = options.to.join(",");
    }

    cleverapps.platform.exitFullscreen(function () {
        plugin.FacebookAgent.getInstance().appRequest(options, callback);
    });
};

cleverapps.FacebookCanvasSocial.prototype.shareDialog = function (data, callback) {
    var link = this.getShareDialogLink(data);

    console.log("share link", link);

    var shareData = {
        dialog: "feedDialog",
        link: link,
        caption: Messages.get(cleverapps.config.title)
    };

    cleverapps.platform.exitFullscreen(function () {
        plugin.FacebookAgent.getInstance().dialog(shareData, callback);
    });
};

cleverapps.FacebookCanvasSocial.prototype.api = function (apiMethod, httpMethod, params, callback) {
    params = params || {};
    if (typeof params === "function") {
        callback = params;
        params = {};
    }

    if (!this.isLoggedIn()) {
        console.log("Not logged in!");
        if (callback) {
            callback(cleverapps.Social.CODE_CANCELLED);
        }
        return;
    }

    if (httpMethod === undefined) {
        httpMethod = plugin.FacebookAgent.HttpMethod.GET;
        callback = function () {
        };
    } else if (typeof httpMethod === "function") {
        callback = httpMethod;
        httpMethod = plugin.FacebookAgent.HttpMethod.GET;
    }
    try {
        plugin.FacebookAgent.getInstance().api(apiMethod, httpMethod, params, callback);
    } catch (e) {
        console.log(e);
        if (callback) {
            callback(cleverapps.Social.CODE_CANCELLED);
        }
    }
};

cleverapps.FakeFacebookAgent = {
    getInstance: function () {
        return this;
    },

    isLoggedIn: function () {
        return false;
    },

    logEvent: function () {
    },

    login: function (permissions, callback) {
        callback(cleverapps.Social.CODE_FAILED);
    },

    getAccessToken: function () {
    },

    dialog: function (info, callback) {
        callback(cleverapps.Social.CODE_FAILED);
    },

    appRequest: function (info, callback) {
        callback(cleverapps.Social.CODE_FAILED);
    },

    api: function (path, httpmethod, params, callback) {
        if (typeof params === "function") {
            callback = params;
            params = {};
        }
        callback(cleverapps.Social.CODE_FAILED);
    },

    HttpMethod: {
        GET: "get"
    }
};
