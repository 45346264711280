/**
 * Created by Aleksandr on 16.06.2023
 */

cleverapps.LocalPushes.MSStartSender = {
    sendPush: function (info, timeout) {
        if (timeout > cleverapps.parseInterval("7 days")) {
            return;
        }

        var data = cleverapps.LocalPushes.GetPushData(info);

        var title = data.title;
        var message = data.text;
        var id = info.id % 16;
        var image = undefined;
        var payload = undefined;
        var callToAction = undefined;

        if (!message) {
            cleverapps.throwAsync("empty msstart push - " + info.code);
            return;
        }

        console.log("Sending push - " + id, {
            title: title,
            message: message,
            id: id,
            image: image,
            payload: payload,
            timeout: Math.round(timeout / 1000),
            callToAction: callToAction
        });

        $msstart.scheduleNotificationAsync(
            title,
            message,
            id,
            image,
            payload,
            Math.round(timeout / 1000),
            callToAction
        ).then(function (response) {
            console.log("MSStartSender - " + response);
        }).catch(function (error) {
            console.log("MSStartSender error - " + JSON.stringify(error));
        });
    },

    cancelPush: function () {}
};