/**
 * Created by iamso on 23.09.2021
 */

if ((cleverapps.config.source || cleverapps.config.requestParamSource) === "microsoft") {
    cc.sys.isNative = true;
    cc.sys.isMobile = false;

    cc.sys.os = cc.sys.OS_WINDOWS;

    var MicrosoftHandler = cleverapps.async(function (data) {
        data = decodeWebViewData(data);

        data = JSON.parse(data);

        var method = data.method;

        switch (data.handler) {
            case "payments":
                cleverapps.payments[method](data);
                break;
            case "platform":
                cleverapps.platform[method](data);
                break;
            case "social":
                cleverapps.social[method](data);
                break;
            default:
                var stringData = JSON.stringify(data);
                console.log(stringData);
                break;
        }
    });

    var MicrosoftNotifier = function (data) {
        window.chrome.webview.postMessage(data);
    };

    // var closeApplication = function () {
    //     MicrosoftNotifier(JSON.stringify({
    //         handler: 'app',
    //         method: 'close'
    //     }));
    // };

    cleverapps.whenAllInitialized(function () {
        MicrosoftNotifier(JSON.stringify({
            handler: "app",
            method: "initialize",
            title: Messages.get(cleverapps.config.title)
        }));
    });

    var AdjustPlugin = AdjustPluginMS;
}