/**
 * Created by vladislav on 31.08.2022
 */

cleverapps.FakeVkBridge = {
    callbacks: [],

    send: function (method) {
        this.callbacks.forEach(function (callback) {
            callback({
                detail: {
                    type: method + "Failed"
                }
            });
        });

        return Promise.resolve({});
    },

    subscribe: function (callback) {
        this.callbacks.push(callback);
    },

    unsubscribe: function (callback) {
        var index = this.callbacks.indexOf(callback);

        if (index !== -1) {
            this.callbacks.splice(index, 1);
        }
    },

    isWebView: function () {
        return false;
    }
};
