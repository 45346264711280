/**
 * Created by mac on 10/6/20
 */

var AdminLevelMaxiature = {
    generate: function (adminLevel, options) {
        options = options || {};
        var Generator = AdminLevelMaxiature[cleverapps.config.name] || AdminLevelMaxiature[cleverapps.config.type];
        if (adminLevel !== undefined && Generator) {
            try {
                cleverapps.environment.levelNo = adminLevel.levelNo;
                cleverapps.environment.episodeNo = adminLevel.episodeNo;
                return Generator(adminLevel, options);
            } catch (e) {
                console.log("Error in level maxiature " + cleverapps.humanReadableNumber(adminLevel.episodeNo, adminLevel.levelNo), e);
            }
        }

        return AdminLevelMaxiature.default();
    },

    bundles: {
        match3: function () {
            return ["enemies"];
        }
    },

    default: function () {
        return new cc.Sprite(bundles.admin.frames.loading_png);
    },

    merge: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var page = cleverapps.travelBook.getPageById(game.level.expedition) || cleverapps.travelBook.getPageById("main");
        cleverapps.travelBook.setCurrentPage(page);

        var map = game.map = new Map2d(adminLevel.content.map, {
            units: game.level.units,
            tiles: game.level.tiles,
            visibleBox: adminLevel.content.visibleBox,
            terrains: adminLevel.content.terrains,
            waterBorder: 0
        });

        Merge.InsertDefaults(map, adminLevel.content.field);

        var SIZE = map.getWidth() + map.getHeight();
        var totalWidth = SIZE * cleverapps.styles.Map2dView.cell.width / 2;
        var totalHeight = SIZE * cleverapps.styles.Map2dView.cell.height / 2;

        var context = {
            level: new Episode(adminLevel.episodeNo, adminLevel.levelNo).getLevel()
        };
        var bundles = GameScene.prototype.listBundles.call(context);

        var preview = new AdminLevelPreview(bundles, function (preview) {
            var view = new Map2dView(map);
            view.setPositionRound({ align: "center" }, { align: "center" });
            view.setScale(Math.min(preview.width / view.width, preview.height / view.height));
            preview.addChild(view);
            view.removeFromParent();
            preview.addChild(view);
            view.setVisibleRect(cc.rect(0, 0, view.width, view.height));
        });
        preview.setContentSize(totalWidth, totalHeight);

        return preview;
    },

    solitaire: function (adminLevel) {
        var toMiniature = function (screen) {
            var nextScreenLevel = AdminLevel.createFromData({}, adminLevel.getContent());
            nextScreenLevel.screens = [screen];
            return AdminLevelMiniature.solitaire(nextScreenLevel);
        };

        var subscreens = adminLevel.screens.map(toMiniature);
        var small = [];

        var select = function (newSelected) {
            if (newSelected === selected) {
                return;
            }

            small.forEach(function (tileWithCaption, index) {
                var sprite = new cc.Sprite(index === newSelected ? bundles.admin.frames.lvl_selected_bg : bundles.admin.frames.lvl_bg);
                tileWithCaption.tile.setSpriteFrame(sprite.getSpriteFrame());
            });

            var newScreen = toMiniature(adminLevel.screens[newSelected]);
            cleverapps.UI.fitToBox(newScreen, mainScreenBox);

            newScreen.setPositionRound(currentScreen.getPosition());
            currentScreen.parent.addChild(newScreen);
            currentScreen.removeFromParent();
            currentScreen = newScreen;

            selected = newSelected;
        };

        if (adminLevel.screens.length > 1) {
            var tileStyles = cleverapps.styles.AdminLevelMaxiature.tiles;
            for (var i = 0; i < adminLevel.screens.length; i++) {
                subscreens[i].scale = 0.25;

                var tileImg = i === 0 ? bundles.admin.frames.lvl_selected_bg : bundles.admin.frames.lvl_bg;
                var tile = cleverapps.UI.createScale9Sprite(tileImg, cleverapps.UI.Scale9Rect.TwoPixelXY);
                tile.setContentSize2(tileStyles);

                var text = cleverapps.UI.generateImageText(i + 1, cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);
                text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

                cleverapps.UI.fitToBox(subscreens[i], tileStyles.fitTo);
                tile.addChild(subscreens[i]);

                var tileWithCaption = new cleverapps.Layout([tile, text], {
                    direction: cleverapps.UI.VERTICAL,
                    margin: tileStyles.margins.vertical
                });
                tileWithCaption.tile = tile;

                small.push(tileWithCaption);

                cleverapps.UI.onClick(tileWithCaption, select.bind(this, i));
                cleverapps.UI.applyHover(tileWithCaption);
            }
        }

        var subscreensLayout = new cleverapps.Layout(small, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminLevelMaxiature.tiles.margins.horizontal
        });

        var currentScreen = toMiniature(adminLevel.screens[0]);
        var selected = 0;
        var mainScreenBox = {
            width: currentScreen.width * currentScreen.scaleX,
            height: currentScreen.height * currentScreen.scaleY
        };

        return new cleverapps.Layout([currentScreen, subscreensLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminLevelMaxiature.tiles.margins.vertical
        });
    },

    scramble: function (adminLevel) {
        var words = adminLevel.words;

        var game = new FakeGame(adminLevel);
        var styling = GameSceneBase.ChooseStyling(game.level);

        var mapWord = function (word, index) {
            var layout = new cleverapps.Layout(word.split("").map(function (symbol) {
                var bg = new cc.Sprite(styling.bgImage);
                var char = CharView.Create(symbol, { noBg: true });
                char.setPositionRound(bg.width / 2, bg.height / 2);
                bg.addChild(char);
                return bg;
            }), {
                direction: cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.AdminLevelMaxiature.scramble.margin.x
            });

            layout.setLocalZOrder(-index);

            return layout;
        };

        var box = new cc.Node();
        if (adminLevel.hint) {
            var hint = cleverapps.UI.generateImageText(adminLevel.hint, cleverapps.styles.FONTS.MAXIATURE_LABEL);
            box.addChild(hint);
            box.setContentSize2(hint.width, hint.height * 1.5);
            box.setAnchorPoint2();
            hint.setPositionRound({ align: "center" }, { align: "top" });
        }

        var layout = new cleverapps.Layout([box].concat(words.map(mapWord)), {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminLevelMaxiature.scramble.margin.y
        });

        return layout;
    },

    differences: function (adminLevel, options) {
        var placeholder = new cc.Node();
        placeholder.setContentSize2(cleverapps.styles.AdminLevelMaxiature.bg);
        placeholder.setAnchorPoint2();

        var loading = new cc.Sprite(bundles.admin.frames.loading_png);
        placeholder.addChild(loading);
        loading.setPositionRound(placeholder.width / 2, placeholder.height / 2);

        var game = new FakeGame(adminLevel);
        var name = game.level.getImageBundle();
        if (bundles[name]) {
            cleverapps.bundleLoader.loadBundle(name, {
                onSuccess: function () {
                    var images = [new cc.Sprite(bundles[name].urls.a), new cc.Sprite(bundles[name].urls.b)];

                    adminLevel.differences.forEach(function (area, id) {
                        images.forEach(function (image) {
                            var frame = cleverapps.UI.createScale9Sprite(bundles.admin.frames.area_selection, cleverapps.UI.Scale9Rect.TwoPixelXY);
                            image.addChild(frame);

                            var frameInfo = PhotoViews.FrameSizeAndPos(new DifferenceArea(area));

                            frame.setContentSize(frameInfo);
                            frame.setPositionRound(frameInfo);

                            var bg = new cc.Sprite(bundles.admin.frames.number_bg);
                            bg.setPositionRound(frame.x - frame.width / 2, frame.y + frame.height / 2);
                            $(bg).styles(cleverapps.styles.AdminLevelMaxiature.label);
                            frame.parent.addChild(bg);

                            var label = cleverapps.UI.generateImageText((id + 1) + "", cleverapps.styles.FONTS.MAXIATURE_LABEL);
                            label.setPositionRound(bg.width / 2, bg.height / 2);
                            bg.addChild(label);
                        });
                    });

                    var layout = new cleverapps.Layout(images, {
                        direction: cleverapps.UI.VERTICAL,
                        reversed: true,
                        margin: cleverapps.styles.AdminLevelMaxiature.tiles.margins.vertical
                    });

                    if (!options.enlarge) {
                        cleverapps.UI.fitToBox(layout, cleverapps.styles.AdminLevelMaxiature.bg);
                    }
                    placeholder.addChild(layout);
                    layout.setPositionRound(placeholder.width / 2, placeholder.height / 2);

                    loading.removeFromParent();
                }
            });
        }

        return placeholder;
    },

    battlefield: function (adminLevel) {
        return AdminLevelMiniature.battlefield(adminLevel);
    },

    board: function (adminLevel) {
        return AdminLevelMiniature.board(adminLevel);
        // var game = new FakeGame(adminLevel);
        // var boardView = new cleverapps.BoardView(game.board, cleverapps.scenes.getRunningScene(this));
        //
        // return boardView;
    },

    match3: function (adminLevel) {
        var styles = cleverapps.styles.AdminLevelMaxiature;

        var view = AdminLevelMiniature.match3(adminLevel);

        var controller = new GoalsController(Game.currentGame, view);
        var goalsView = controller.goalsView;
        goalsView.setPositionRound(view.width / 2, view.height + goalsView.height);

        goalsView.rotation = 0;
        cleverapps.values(controller.viewByType).forEach(function (goalView) {
            goalView.rotation = 0;
        });

        cleverapps.UI.wrap(view);

        var movesView = new MovesView(Game.currentGame, goalsView);
        view.addChild(movesView);
        movesView.setPositionRound(styles.match3.movesView.offsetX, view.height + styles.match3.movesView.offsetY);

        return view;
    },

    tile3: function (adminLevel) {
        return AdminLevelMiniature.tile3(adminLevel);
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MAXIATURE_LABEL: {
        name: "formal",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.styles.AdminLevelMaxiature = {
    bg: {
        width: 500,
        height: 670
    },

    scramble: {
        offset: {
            x: { align: "left", dx: 100 },
            y: { align: "top", dy: -100 }
        },
        margin: {
            x: -10,
            y: -30
        }
    },

    tiles: {
        width: 160,
        height: 210,
        fitTo: {
            width: 150,
            height: 200
        },
        margins: {
            vertical: 10,
            horizontal: 26
        }
    },

    label: {
        offsetX: 10,
        offsetY: -10
    },

    match3: {
        movesView: {
            offsetY: -80,
            offsetX: 50
        }
    }
};