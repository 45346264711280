/**
 * Created by Andrey Popov on 2/08/21.
 */

var SoundPropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        bundle: "",
        sound: ""
    }, initialValue);

    var urlsWithBundles = {};
    urlsWithBundles[Wysiwyg.NOT_SELECTED] = true;

    this.property.bundles.forEach(function (bundleString) {
        Object.keys(bundles[bundleString].urls).forEach(function (url) {
            var file = bundles[bundleString].urls[url];
            if (file.indexOf(".png") !== -1 || file.indexOf(".json") !== -1) {
                return;
            }

            urlsWithBundles[url] = bundleString;
        });
    });

    this.propertyEditors = [{
        name: property.name,
        items: Object.keys(urlsWithBundles).concat([SoundPropertyEditor.WITHOUT_MUSIC]),
        bundles: this.property.bundles,
        getter: function () {
            return this.value.sound;
        }.bind(this),
        setter: function (value) {
            this.value = {};
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.sound = Wysiwyg.NOT_SELECTED;
            } else if (value === SoundPropertyEditor.WITHOUT_MUSIC) {
                this.value.sound = SoundPropertyEditor.WITHOUT_MUSIC;
            } else {
                this.value = {
                    sound: value,
                    bundle: urlsWithBundles[value]
                };
            }
        }.bind(this),
        getPreview: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                return;
            }

            var icon = new cc.Sprite(bundles.editor_controls.frames.sound_icon);

            cleverapps.UI.onClick(icon, function () {
                cleverapps.audio.unmute();

                cleverapps.audio.eject();
                cc.audioEngine.stopAllEffects();

                cleverapps.audio.playSound(bundles[urlsWithBundles[value]].urls[value]);
            });
            cleverapps.UI.applyHover(icon);

            return icon;
        }
    }].map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

SoundPropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
};

SoundPropertyEditor.WITHOUT_MUSIC = "without_music";