/**
 * Created by andrey on 23.04.18.
 */

cleverapps.DailyTasks = function () {
    this.onChangeStateListener = function () {};
    this.onTaskSwappedOut = function () {};

    this.tasks = [];
    this.whenStart = -1;

    if (!this.isAvailable()) {
        this.save();
    } else {
        this.load();
    }

    cleverapps.whenAllInitialized(this.update.bind(this));
    cleverapps.user.on("incLevel", this.update.bind(this));

    cleverapps.eventBus.on("gameEvent", this.onGameEvent.bind(this));
    cleverapps.eventBus.on("taskEvent", this.onTaskEvent.bind(this));
};

cleverapps.DailyTasks.prototype.getInfo = function () {
    return {
        whenStart: this.whenStart,
        tasks: this.tasks.map(function (task) {
            return task.getSaveInfo();
        })
    };
};

cleverapps.DailyTasks.prototype.load = function (serverData) {
    if (!this.isAvailable()) {
        this.tasks = [];
        this.whenStart = -1;
        return;
    }

    var data = serverData || cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.DAILY_TASKS) || {};
    this.tasks = [];
    this.whenStart = data.whenStart !== undefined ? data.whenStart : -1;

    if (this.whenStart > this.getStartTime()) {
        this.whenStart = this.getStartTime();
    }

    if (data.tasks && data.tasks.length === cleverapps.DailyTasks.ACTIVE_TASKS && data.tasks.every(function (taskData) {
        return DailyTasksConfig[taskData.type];
    })) {
        this.tasks = data.tasks.map(function (taskData) {
            if (taskData.data.color && cleverapps.DailyTasks.CELL_COLORS.indexOf(taskData.data.color) === -1) {
                taskData.data.color = cleverapps.Random.choose(cleverapps.DailyTasks.CELL_COLORS);
            }
            if (taskData.data.cardValue && cleverapps.DailyTasks.CARD_VALUES.indexOf(taskData.data.cardValue) === -1) {
                taskData.data.cardValue = cleverapps.Random.choose(cleverapps.DailyTasks.CARD_VALUES);
            }
            return cleverapps.DailyTask.Create(taskData);
        });
    } else {
        this.whenStart = -1;
    }
};

cleverapps.DailyTasks.prototype.save = function (updateServer) {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.DAILY_TASKS, this.getInfo());

    if (updateServer !== false) {
        cleverapps.synchronizer.addUpdateTask("dailytasks");
    }
};

cleverapps.DailyTasks.prototype.collect = function (task) {
    if (task.collected) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DAILY_TASK_REWARD + this.getTaskSlot(task));
    cleverapps.playSession.inc(cleverapps.EVENTS.SESSION_DAILY_TASK_REWARD);

    task.collect();
    cleverapps.social.sendActivity(cleverapps.Social.ACTIVITY.DAILY_TASK, {
        userName: cleverapps.friends.getPlayer().name
    });
};

cleverapps.DailyTasks.prototype.collectLastTask = function () {
    var lastTask = cleverapps.dailyTasks.getCompleteAllTask();
    if (lastTask.progress === lastTask.goal && !lastTask.collected && cleverapps.dailyTasks.getNotCollectedTasks().length === 1) {
        lastTask.givePrize();
    }
};

cleverapps.DailyTasks.prototype.generateTasks = function () {
    var chosen = [];
    var slotsData = this.prepareTaskBySlots();

    for (var slot in slotsData) {
        var candidates = Object.keys(slotsData[slot]);
        candidates = candidates.filter(function (type) {
            return chosen.indexOf(type) === -1;
        });
        chosen.push(cleverapps.Random.choose(candidates));
    }

    return chosen.map(function (type, index) {
        var taskData = slotsData[index][type];
        return new cleverapps.DailyTask(+type, taskData.data[index], taskData.data);
    });
};

cleverapps.DailyTasks.prototype.prepareTaskBySlots = function () {
    var slots = {};
    for (var i = 0; i < cleverapps.DailyTasks.ACTIVE_TASKS; i++) {
        slots[i] = {};
    }

    var configs = cleverapps.clone(DailyTasksConfig, true);
    var types = Object.keys(configs);

    types.forEach(function (type) {
        var config = configs[type];
        if (config.filter && !config.filter()) {
            return;
        }
        config.data = config.dynamic();

        var possibleSlots = Object.keys(config.data);
        possibleSlots.forEach(function (slot) {
            if (slots[slot]) {
                slots[slot][type] = config;
            }
        });
    });

    return slots;
};

cleverapps.DailyTasks.prototype.getStartTime = function () {
    var date = new Date();
    var timezone = date.getTimezoneOffset() * 1000 * 60;
    return date.getTime() - (date.getTime() - timezone) % cleverapps.DailyTasks.INTERVAL;
};

cleverapps.DailyTasks.prototype.startNewTasks = function () {
    this.tasks = this.generateTasks();
    this.whenStart = this.getStartTime();
    this.save();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DAILY_TASKS_START);
};

cleverapps.DailyTasks.prototype.getNotCollectedTasks = function () {
    return this.tasks.filter(function (task) {
        return task.isFinished() && !task.collected;
    });
};

cleverapps.DailyTasks.prototype.isAllTasksFinished = function () {
    return cleverapps.dailyTasks.getActiveTasks().length === 0;
};

cleverapps.DailyTasks.prototype.getActiveTasks = function () {
    return this.tasks.filter(function (task) {
        return !task.isFinished();
    });
};

cleverapps.DailyTasks.prototype.getFinishedTasks = function () {
    return this.tasks.filter(function (task) {
        return task.isFinished();
    });
};

cleverapps.DailyTasks.prototype.getTaskByType = function (type) {
    var task;
    this.getActiveTasks().forEach(function (activeTask) {
        if (activeTask.type === type) {
            task = activeTask;
        }
    });
    return task;
};

cleverapps.DailyTasks.prototype.swapOutTask = function (taskToSwap) {
    var targetSlot = this.getTaskSlot(taskToSwap);
    var taskBySlots = this.prepareTaskBySlots();
    var slotData = taskBySlots[targetSlot];

    var currentTasks = this.tasks.map(function (task) {
        return task.type;
    });

    if (!slotData) {
        var tasks = this.tasks.map(function (task) {
            return task.type;
        });
        var slots = Object.keys(taskBySlots).map(function (slot) {
            return Object.keys(taskBySlots[slot]);
        });
        throw "undefined data for task '" + (taskToSwap && taskToSwap.type) + "', slot '" + targetSlot + "', tasks '" + JSON.stringify(tasks) + "', slots '" + JSON.stringify(slots) + "'";
    }

    var candidates = Object.keys(slotData).filter(function (type) {
        return currentTasks.indexOf(+type) === -1;
    });

    var chosen = cleverapps.Random.choose(candidates);
    if (chosen !== undefined) {
        var newTask = new cleverapps.DailyTask(+chosen, slotData[chosen].data[targetSlot], slotData[chosen].data);
        var ind = this.tasks.indexOf(taskToSwap);
        this.tasks.splice(ind, 1, newTask);
        this.onTaskSwappedOut(targetSlot);
        this.save();
    }
};

cleverapps.DailyTasks.prototype.addProgress = function (type, amount, options) {
    var task = this.getTaskByType(type);
    if (task) {
        task.addProgress(amount, options);
    }
};

cleverapps.DailyTasks.prototype.setProgress = function (type, amount, options) {
    var task = this.getTaskByType(type);
    if (task) {
        task.setProgress(amount, options);
    }
};

cleverapps.DailyTasks.prototype.getTimeLeft = function () {
    var duration = cleverapps.DailyTasks.INTERVAL;
    return Math.max(0, this.whenStart + duration - Date.now());
};

cleverapps.DailyTasks.prototype.isAvailable = function (options) {
    return levels.user.checkAvailable(cleverapps.Availables.DAILY_TASKS, options);
};

cleverapps.DailyTasks.prototype.update = function () {
    clearTimeout(this.finishTimeout);

    if (!this.isAvailable()) {
        return;
    }

    if (this.getTimeLeft() === 0) {
        this.startNewTasks();
    }

    this.finishTimeout = new cleverapps.LongTimeout(this.update.bind(this), this.getTimeLeft());

    this.onChangeStateListener();
};

cleverapps.DailyTasks.prototype.getTasks = function () {
    return this.tasks;
};

cleverapps.DailyTasks.prototype.isRunning = function () {
    return this.tasks.length > 0;
};

cleverapps.DailyTasks.prototype.processDiscoverWord = function (word) {
    cleverapps.unique(word.split("")).forEach(function (letter) {
        cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.GUESS_WORDS_WITH_LETTER, 1, { letter: letter });
    });

    if (word.length >= 7) {
        cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.GUESS_LONG_WORDS, 1);
    }
};

cleverapps.DailyTasks.prototype.processOpenCard = function (playedCard) {
    if (cleverapps.config.type === "solitaire") {
        this.addProgress(cleverapps.DailyTasks.COLLECT_CARDS_BY_VALUE, 1, {
            cardValue: playedCard.getRank()
        });

        this.addProgress(cleverapps.DailyTasks.COLLECT_CARDS_BY_SUIT, 1, {
            cardSuit: playedCard.getSuit()
        });
    }
};

cleverapps.DailyTasks.prototype.getTaskSlot = function (task) {
    var slot = this.tasks.indexOf(task);
    return slot === -1 ? undefined : slot;
};

cleverapps.DailyTasks.prototype.getCompleteAllTask = function () {
    return this.getTasks().filter(function (task) {
        return task.type === cleverapps.DailyTasks.COMPLETE_ALL;
    })[0];
};

cleverapps.DailyTasks.prototype.taskWasFinished = function (task) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DAILY_TASK_FINISH_AMOUNT + this.getFinishedTasks().length);
    cleverapps.playSession.inc(cleverapps.EVENTS.SESSION_DAILY_TASK_FINISH);

    if (task.type !== cleverapps.DailyTasks.COMPLETE_ALL) {
        this.addProgress(cleverapps.DailyTasks.COMPLETE_ALL);
    }

    this.preparePush();
    this.onChangeStateListener();
};

cleverapps.DailyTasks.prototype.preparePush = function () {
    if (this.getFinishedTasks().length < 2) {
        return;
    }

    var timeout;
    if (this.getActiveTasks().length > 0 && this.getTimeLeft() > cleverapps.DailyTasks.PUSH_UNTIL_RESET_TIME) {
        timeout = this.getTimeLeft() - cleverapps.DailyTasks.PUSH_UNTIL_RESET_TIME;
    }

    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.DAILY_TASKS_UNCOMPLETED, timeout);
};

cleverapps.DailyTasks.prototype.onTaskEvent = function (type, amount, options) {
    options = typeof amount === "object" ? amount : options;
    amount = typeof amount !== "number" ? 1 : amount;
    cleverapps.dailyTasks.addProgress(type, amount, options);
};

cleverapps.DailyTasks.prototype.onGameEvent = function (type, options) {
    if (cleverapps.config.type === "merge") {
        switch (type) {
            case Merge.MINE:
                var data = options.affected.getData();
                if (data.source && data.mineable) {
                    cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.DESTROY_ENT);
                }
                break;
            case Merge.COMPLETE_MINING:
                if (options.task.energy) {
                    cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.USE_WORKER);
                }
                break;
            case Merge.BUILD:
                cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.USE_WORKER);
                cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.BUILD);
                break;
            case Merge.MERGE:
                cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.MERGE, 1, { mergeAmount: options.affected.length });

                if (options.affected.some(function (unit) {
                    return unit.code === "crystal" && unit.isLast();
                })) {
                    cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.USE_RAINBOW);
                }
                break;
            case Merge.HARVEST:
                if (options.affected.getType() === "fruit") {
                    cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.HARVEST_FRUITS);
                }
                break;
            case Merge.PRIZE_HARVESTED:
                if (options.affected.getData().chest === true) {
                    cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.OPEN_CHEST);
                }
                break;
            case Merge.SPAWN:
                var spawnCheck = function (spawned, filter) {
                    if (Array.isArray(options.affected)) {
                        var mergeSource = options.affected.filter(filter);
                        if (mergeSource.length > 0) {
                            return spawned.stage > mergeSource[0].stage;
                        }
                    }
                };

                if (options.unit.getType() === "fruit" && options.unit.stage < 4) {
                    if (!options.affected || (!Array.isArray(options.affected) && ["chest", "fruit", "source"].indexOf(options.affected.getType()) !== -1)) {
                        cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.GROW_PLANT);
                    }
                } else if (options.unit.getType() === "source") {
                    if (spawnCheck(options.unit, function (unit) {
                        return unit.getType() === "source";
                    })) {
                        cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.CREATE_SOURCE);
                    }
                } else if (options.unit.code === "wands") {
                    if (spawnCheck(options.unit, function (unit) {
                        return unit.code === "wands";
                    })) {
                        cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.MERGE_SOLDIERS);
                    }
                }

                break;
            case Merge.OPENFOG:
                cleverapps.dailyTasks.addProgress(cleverapps.DailyTasks.OPEN_FOG);

                break;
        }
    }
};

cleverapps.DailyTasks.INTERVAL = cleverapps.parseInterval(cleverapps.config.debugMode ? "10 minutes" : "1 day");
cleverapps.DailyTasks.PUSH_UNTIL_RESET_TIME = cleverapps.parseInterval("5 hours");
