/**
 * Created by slava on 20/2/18
 */

var TabMenu = cc.Node.extend({
    ctor: function (table, options) {
        this._super();

        this.table = table;

        this.buttonByPage = {};

        var buttons = Object.keys(this.table).map(function (page, index) {
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: page,
                width: cleverapps.styles.EditorTabMenu.buttons.width,
                onClicked: function () {
                    this.setActive(page);
                }.bind(this)
            });
            button.text.setColor(cc.color(101, 147, 216, 255));
            this.addChild(button);

            this.buttonByPage[page] = button;
            if (index === 0) {
                button.button_png = new cc.Sprite(bundles.editor.frames.editor_tabs_button_on);
                button.setBright(true);
            }

            return button;
        }, this);

        var width = cleverapps.UI.arrangeWithMargins(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            y: cleverapps.styles.EditorTabMenu.buttons.y,
            margin: cleverapps.styles.EditorTabMenu.buttons.margin
        });

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(width, buttons[0].height);

        for (var page in this.table) {
            this.addChild(this.table[page]);
            if (!this.table[page].alignment) {
                this.table[page].setPositionRound(this.width / 2, this.height / 2);
            }
            this.table[page].setVisible(false);
        }

        this.active = undefined;
        this.setActive(Object.keys(this.table)[0]);

        if (options && options.background) {
            var bg = new cc.Scale9Sprite(options.background);
            bg.setContentSize(cleverapps.styles.EditorTabMenu.bg);
            bg.setPositionRound(cleverapps.styles.EditorTabMenu.bg);
            bg.setAnchorPoint2();
            this.addChild(bg, -1);
        }
    },

    getCurrentView: function () {
        if (!this.active) {
            return undefined;
        }

        return this.table[this.active];
    },

    setActive: function (page) {
        if (this.active) {
            this.getCurrentView().setVisible(false);
            this.buttonByPage[this.active].button_png = new cc.Sprite(bundles.editor.frames.editor_tabs_button_off);
            this.buttonByPage[this.active].setBright(false);
        }

        this.active = page;
        this.getCurrentView().setVisible(true);

        this.buttonByPage[page].button_png = new cc.Sprite(bundles.editor.frames.editor_tabs_button_on);
        this.buttonByPage[this.active].setBright(true);
    }
});

cleverapps.styles.EditorTabMenu = {
    buttons: {
        width: 230,
        y: 200,
        margin: 10
    },
    bg: {
        width: 526,
        height: 740,
        x: { align: "center" },
        y: { align: "center", dy: -160 }
    }
};