/**
 * Created by Andrey Popov on 1/22/21.
 */

var HierarchyItemView = cc.Node.extend({
    ctor: function (hierarchyItem) {
        this._super();

        this.hierarchyItem = hierarchyItem;

        var styles = cleverapps.styles.HierarchyItemView;
        var width = styles.width - styles.levelIndent * this.hierarchyItem.getLevel();

        this.setAnchorPoint2();
        this.setContentSize2(width, styles.height);

        var icon = this.icon = new cc.Sprite();
        icon.setAnchorPoint2();
        icon.setPositionRound(styles.icon);
        this.addChild(icon);
        this.updateIcon();

        var hasTypeIcon = hierarchyItem.assetName && bundles.wysiwyg.frames["item_" + hierarchyItem.assetName.toLowerCase()] || hierarchyItem.type === HierarchyItem.TYPE.SCRIPT;
        if (hasTypeIcon) {
            hasTypeIcon = true;
            var typeIcon = new cc.Sprite(bundles.wysiwyg.frames["item_" + (hierarchyItem.type === HierarchyItem.TYPE.SCRIPT ? "adsscript" : hierarchyItem.assetName.toLowerCase())]);
            typeIcon.setAnchorPoint2();
            typeIcon.setPositionRound(styles.typeIcon);
            cleverapps.UI.fitToBox(typeIcon, styles.typeIcon);
            this.addChild(typeIcon);
        }

        var text = this.text = cleverapps.UI.generateOnlyText(this.hierarchyItem.getCaption(), cleverapps.styles.FONTS.HIERARCHY_ITEM_TEXT);
        text.fitTo(width - styles.icon.width, styles.height);

        this.underline = cleverapps.UI.drawUnderline(text, hasTypeIcon ? styles.lineWithType : styles.line);
        this.underline.setVisible(false);
        this.addChild(this.underline);
        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        text.setDimensions(width - styles.icon.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        text.setAnchorPoint2();
        text.setPositionRound(hasTypeIcon ? styles.textWithType : styles.text);
        this.addChild(text);

        this.setSelected(hierarchyItem.isSelected());

        cleverapps.UI.onClick(this, this.hierarchyItem.onClick.bind(this.hierarchyItem), {
            filter: function () {
                return !cleverapps.wysiwyg.hierarchy.selectionBlocked;
            }
        });
        cleverapps.UI.onClick(this.icon, this.hierarchyItem.onIconClick.bind(this.hierarchyItem), {
            filter: function () {
                return !cleverapps.wysiwyg.hierarchy.selectionBlocked;
            }
        });
    },

    setSelected: function (selected) {
        this.text.setFontFillColor(selected ? cleverapps.styles.COLORS.BLACK : cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY);
        this.text._setUpdateTextureDirty();
    },

    onMouseOver: function () {
        if (this.underline && this.underline.visible === false && !cleverapps.wysiwyg.hierarchy.selectionBlocked) {
            this.underline.setVisible(true);
            this.underline.setScale(0);
            this.underline.runAction(new cc.ScaleTo(0.3, 1));
        }
    },

    onMouseOut: function () {
        if (this.underline && this.underline.visible === true) {
            this.underline.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 0),
                new cc.CallFunc(function () {
                    this.underline.setVisible(false);
                }.bind(this))
            ));
        }
    },

    updateIcon: function () {
        var image;
        var rotation = 0;
        switch (this.hierarchyItem.type) {
            case HierarchyItem.TYPE.CLIP:
                image = bundles.wysiwyg.frames.scenario_item;
                rotation = this.hierarchyItem.properties.visible === true ? 90 : 0;
                break;
            case HierarchyItem.TYPE.DIRECTORY:
                image = bundles.wysiwyg.frames.directory_item;
                break;
            case HierarchyItem.TYPE.SCRIPT:
                image = bundles.wysiwyg.frames.item_adsscript;
                this.icon.setVisible(false);
                break;
            case HierarchyItem.TYPE.SCENE:
                image = bundles.wysiwyg.frames.clip_item;
                break;
            case HierarchyItem.TYPE.COMPONENT:
                image = this.hierarchyItem.properties.visible === false
                    ? bundles.editor_controls.frames.component_item_hidden
                    : bundles.editor_controls.frames.component_item;
                break;
        }

        this.icon.setSpriteFrame(image);
        this.icon.setRotation(rotation);
        cleverapps.UI.fitToBox(this.icon, cleverapps.styles.HierarchyItemView.icon);
    }
});

cleverapps.styles.HierarchyItemView = {
    width: 380,
    height: 32,

    icon: {
        x: { align: "left", dx: 4 },
        y: { align: "center", dy: 3 },
        width: 28,
        height: 28
    },

    typeIcon: {
        x: { align: "left", dx: 14 },
        y: { align: "center", dy: 2 },
        width: 32,
        height: 32
    },

    text: {
        x: { align: "right", dx: -8 },
        y: { align: "center", dy: 1 }
    },

    textWithType: {
        x: { align: "right", dx: 20 },
        y: { align: "center", dy: 1 }
    },

    line: {
        x: { align: "left", dx: 18 },
        y: { align: "bottom" },
        height: 1
    },

    lineWithType: {
        x: { align: "left", dx: 50 },
        y: { align: "bottom", dy: -1 },
        height: 1
    },

    levelIndent: 40
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HIERARCHY_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    SELECTED_HIERARCHY_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});
