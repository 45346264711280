/**
 * Created by andrey on 11.07.18.
 */

cleverapps.Adversting = {
    idfa: undefined,

    getIdfa: function () {
        if (!this.isEmptyIdfa(this.idfa)) {
            if (cc.sys.isNative) {
                console.log("idfa - " + this.idfa);
            }
            return this.idfa;
        }

        var idfa;
        if (cc.sys.isNative && cc.sys.os === cc.sys.OS_ANDROID) {
            if (typeof AdverstingPlugin !== "undefined" && typeof AdverstingPlugin.getIdfa === "function") {
                idfa = AdverstingPlugin.getIdfa();
                console.log("android idfa - " + idfa);
            }
        } else if (cc.sys.isNative && [cc.sys.OS_IOS, cc.sys.OS_OSX].indexOf(cc.sys.os) !== -1) {
            idfa = cleverapps.webViewData.advertisingId;
            console.log("ios idfa - " + idfa);
        } else if (cleverapps.isLocalhost()) {
            idfa = "localhostIDFA";
        }
        this.idfa = idfa;
        return idfa;
    },

    isEmptyIdfa: function (idfa) {
        return !idfa || [cc.sys.OS_IOS, cc.sys.OS_OSX].indexOf(cc.sys.os) !== -1 && idfa.indexOf("0000") === 0;
    },

    postbackUnity: function (idfa) {
        if (!cleverapps.platform.oneOf(Amazon, AndroidPlatform)) {
            return;
        }

        var gameId = cleverapps.config.android.unityGameId;
        if (cleverapps.platform.oneOf(IOS)) {
            gameId = cleverapps.config.ios.unityGameId;
        }

        var url = "https://postback.unityads.unity3d.com/games/" + gameId + "/install?advertisingTrackingId=" + idfa;
        cleverapps.RestClient.get(url, {}, function () {
        });
    },

    getInstallReferrer: function () {
        if (cc.sys.isNative && cc.sys.os === cc.sys.OS_ANDROID
            && typeof AdverstingPlugin !== "undefined" && typeof AdverstingPlugin.getInstallReferrer === "function") {
            AdverstingPlugin.getInstallReferrer();
            console.log("AdverstingPlugin.getInstallReferrer called");
        }
    },

    onInstallReferrerReceived: function (referrerUrl) {
        if (typeof decodeWebViewData !== "undefined") {
            referrerUrl = decodeWebViewData(referrerUrl);
        }
        console.log("onInstallReferrerReceived " + referrerUrl);

        if (referrerUrl.indexOf("&") !== -1) {
            referrerUrl.split("&").forEach(function (paramWithValue) {
                if (paramWithValue.indexOf("utm_campaign") !== -1) {
                    var channel = paramWithValue.split("=")[1];

                    console.log("channel extracted " + channel);
                    cleverapps.user.setChannel(channel);
                } else if (paramWithValue.indexOf("utm_content") !== -1) {
                    // https://developers.facebook.com/docs/app-ads/install-referrer
                    var content = paramWithValue.split("=")[1];

                    console.log("Encrytped Referrer Data " + content);

                    if (cleverapps.config.name === "mergecraft") {
                        cleverapps.RestClient.post("/users/decodereferrer", { content: content }, function (response) {
                            if (response.channel) {
                                cleverapps.user.setChannel(response.channel);
                            } else {
                                console.log("Failed decode referrer: " + content);
                            }
                        });
                    }
                }
            });
        }
    },

    logAppTrackingStatus: function (rawValue) {
        console.log("logAppTrackingStatus " + (typeof rawValue) + " - " + rawValue);

        var status = "notdetermined";

        switch (rawValue) {
            case 1:
                status = "restricted";
                break;
            case 2:
                status = "denied";
                break;
            case 3:
                status = "authorized";
                break;
        }

        console.log("logAppTrackingStatus " + status);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.APP_TRACKING + status);
    }
};
