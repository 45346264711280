/**
 * Created by spepa on 05.04.2023
 */

var InstantTournament = function () {
    this.id = "";
    this.score = 0;

    this.load();
};

InstantTournament.prototype.load = function () {
    var data = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.INSTANT_TOURNAMENT);
    if (data) {
        this.id = data.id;
        this.score = data.score;
    }

    cleverapps.platform.whenInitialized("instantTournament", function () {
        cleverapps.platform.getCurrentTournamentId(function (id) {
            if (id !== this.id) {
                this.id = "";
            }
        }.bind(this));
    }.bind(this));
};

InstantTournament.prototype.save = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.INSTANT_TOURNAMENT, {
        id: this.id,
        score: this.score
    });
};

InstantTournament.prototype.isInTournament = function () {
    return Boolean(this.id);
};

InstantTournament.prototype.updateScore = function (game) {
    var score = game.score.getPoints();

    cleverapps.platform.getCurrentTournamentId(function (id, skipCheck) {
        if (this.id === id && this.score >= score && !skipCheck) {
            return;
        }

        cleverapps.platform.reportScore(score, function (id) {
            if (id) {
                this.id = id;
                this.score = score;
                this.save();
            }
        }.bind(this));
    }.bind(this));
};