/**
 * Created by andrey on 22.07.2020
 */

cleverapps.AppleSocial = function () {
    console.log("Choosing Apple Social");

    cleverapps.Social.call(this);
};

cleverapps.AppleSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.AppleSocial.prototype.constructor = cleverapps.AppleSocial;

cleverapps.AppleSocial.prototype.onTokenValidateFailure = function () {
    this.id = "";
    this.token = "";

    this.save();
};

cleverapps.AppleSocial.prototype.updateNameFromServer = function (callback) {
    var onSuccess = function (json) {
        var name = json.name || "";
        console.log("AppleSocial.updateNameFromServer success - " + name);
        this.name = name;
        this.save();

        callback();
    }.bind(this);

    var onFailure = function (response) {
        console.log("AppleSocial.updateNameFromServer failure - " + response);

        callback();
    };

    console.log("AppleSocial.updateNameFromServer");
    cleverapps.RestClient.get("/info/name/" + this.getUserID(), {}, onSuccess, onFailure);
};

cleverapps.AppleSocial.prototype.prepareData = function (data, callback) {
    data = decodeWebViewData(data);
    data = JSON.parse(data);

    console.log("AppleSocial.prepareData - " + JSON.stringify(data));

    this.id = data.id || "";
    this.token = data.token || "";
    if (data.name) {
        this.name = data.name;
    }

    this.save();

    if (this.id && !this.name) {
        this.updateNameFromServer(callback);
    } else {
        callback();
    }
};

cleverapps.AppleSocial.prototype.onInit = function (data) {
    var callback = function () {
        if (this.onInitCallback) {
            console.log("AppleSocial.onInit");
            this.onInitCallback();
            delete this.onInitCallback;
        }
    }.bind(this);

    this.prepareData(data, callback);
};

cleverapps.AppleSocial.prototype.save = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.APPLE_SOCIAL, {
        id: this.id,
        token: this.token,
        name: this.name
    });
};

cleverapps.AppleSocial.prototype.init = function (callback) {
    var data = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.APPLE_SOCIAL);

    this.onInitCallback = callback;

    webkitMessage("appleSocial", {
        action: "init",
        data: data
    });

    console.log("AppleSocial.init - " + JSON.stringify(data));
};

cleverapps.AppleSocial.prototype.isLoggedIn = function () {
    return this.getUserID() && this.getAccessToken();
};

cleverapps.AppleSocial.prototype.getUserID = function () {
    if (this.id) {
        return "apple_" + this.id;
    }
};

cleverapps.AppleSocial.isAppleUserId = function (id) {
    return id && id.indexOf("apple_") === 0;
};

cleverapps.AppleSocial.prototype.getAccessToken = function () {
    return this.token;
};

cleverapps.AppleSocial.prototype.onLogin = function (code, data) {
    var callback = function () {
        if (this.onLoginListener) {
            console.log("AppleSocial.onLogin - " + code);
            this.onLoginListener(code);
            delete this.onLoginListener;
        }
    }.bind(this);

    this.prepareData(data, callback);
};

cleverapps.AppleSocial.prototype.login = function (onSuccess, onFailure) {
    this.onLoginListener = function (code) {
        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.SocialManager.onLogin();
            onSuccess();
        } else {
            cleverapps.SocialManager.onFailedLogin();
            onFailure && onFailure();
        }
    };

    webkitMessage("appleSocial", {
        action: "login"
    });

    console.log("AppleSocial.login");
};

cleverapps.AppleSocial.prototype.aboutMe = function (callback) {
    var data = {
        id: this.getUserID(),
        first_name: this.name,
        name: this.name
    };

    console.log("AppleSocial.aboutMe - " + JSON.stringify(data));

    callback(data);
};

cleverapps.AppleSocial.prototype.getCode = function () {
    return cleverapps.AppleSocial.CODE;
};

cleverapps.AppleSocial.isAvailable = function () {
    return cc.sys.isNative && cleverapps.webViewData && cleverapps.webViewData.appleSocialAvailable && cleverapps.platform.oneOf(IOS, MacOS);
};

cleverapps.AppleSocial.CODE = "applesocial";
