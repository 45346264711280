/**
 * Created by slava on 24.03.17.
 */

var TutorialFinishWindow = Window.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.TutorialFinishWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);

        var h = styles.paddingBottom;
        content.setContentSize(styles.width, styles.height ? styles.height : styles.paddingBottom);

        var text = cleverapps.UI.generateTTFText("TutorialFinishWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT, {
            gameTitle: Messages.get(cleverapps.config.title)
        });
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        var width = content.width + 50;
        text.setDimensions(cc.size(width, 0));
        text.setPosition(content.width / 2 + styles.text.x, content.height + text.height / 2 + styles.text.y);
        content.addChild(text);
        h += text.height + styles.paddingTop;

        if (styles.character) {
            var character = new cc.Sprite(styles.character.image);
            character.setPosition(content.width / 2  + styles.character.x, styles.character.y);
            content.addChild(character);
            h += character.height;
        }

        if (styles.height)
            h = styles.height;

        content.setContentSize(content.width, h);

        this._super({
            content: content,
            title: "TutorialFinishWindow.title",
            name: 'tutorialfinishwindow',
            button: {
                text: "OK"
            },
            closeButton: false
        });
    }
});

cleverapps.styles.TutorialFinishWindow = {
    width: 550,
    paddingBottom: 50,
    paddingTop: 20,

    text: {
        x: 0,
        y: 0
    }
};