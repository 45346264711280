/**
 * Created by slava on 25/12/19
 */

var Metha = function () {
    this.enabled = true;
    this.locked = false;
    this.focus = "initialize";
    this.focusHistory = [];
    this.controls = [];
    this.eventNodes = [];

    // for cleverapps.windows callback
    this.onceNoWindowsListener = undefined;

    this.controlsVisible = true;
    this.lastControlsVisible = {};
    this.onControlsListeners = {};

    this.pendingListeners = [];
};

Metha.prototype.registerControl = function (name, listener) {
    if (typeof listener === "object") {
        var obj = listener;
        listener = obj.createListener(obj.toggle.bind(obj));
    }

    if (cleverapps.gameModes.noControls) {
        setTimeout(function () {
            listener(false);
        }, 0);
        return;
    }

    this.onControlsListeners[name] = listener;

    listener(false, true);
    this.lastControlsVisible[name] = false;

    if (this.controlsVisible || this.controls.indexOf(name) !== -1) {
        listener(true);
        this.lastControlsVisible[name] = true;
    }
};

Metha.prototype.removeControl = function (name) {
    delete this.onControlsListeners[name];
    delete this.lastControlsVisible[name];
};

Metha.prototype.updateControlsVisibility = function () {
    for (var key in this.onControlsListeners) {
        var visible = this.controlsVisible || this.controls.indexOf(key) !== -1;

        if (this.lastControlsVisible[key] !== visible) {
            this.onControlsListeners[key](visible);
            this.lastControlsVisible[key] = visible;
        }
    }
};

Metha.prototype.setControlsVisible = function (visible) {
    this.controlsVisible = visible;

    this.clearUpdateTimeout();
    this.updateTimeout = setTimeout(this.updateControlsVisibility.bind(this), 100);
};

Metha.prototype.clearUpdateTimeout = function () {
    if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
    }
};

Metha.prototype.debugMessage = function (message) {
    if (cleverapps.config.debugMode && !cc.sys.isNative) {
        throw message;
    } else {
        cleverapps.throwAsync(message);
    }
};

Metha.prototype.displayWhenFreeFocus = function (options) {
    if (!this.isFocused()) {
        this.display(options);
    } else {
        this.whenFreeFocus(this.display.bind(this, options));
    }
};

Metha.prototype.whenFreeFocus = function (listener) {
    if (!this.isFocused()) {
        listener();
    } else {
        this.pendingListeners.push(listener);
    }
};

Metha.prototype.distract = function (options) {
    if (!this.isFocused()) {
        this.debugMessage("Distract without focus! " + options.focus);
    }

    if (this.focus === options.focus) {
        this.debugMessage("Distract same focus!" + options.focus);
        return;
    }

    this.focus = false;

    this.display(options);
};

Metha.prototype.display = function (options) {
    if (this.isFocused()) {
        return;
    }

    if (options.filter && !options.filter()) {
        return;
    }

    var focus = options.focus;

    this.grabFocus(focus, options);

    var actions = options.actions || [options.action];

    this.compound(this.freeFocus.bind(this, focus), actions);
};

Metha.prototype.compound = function (f, actions, index, focus) {
    index = index || 0;
    focus = focus || this.focus;

    if (this.focus !== focus) {
        return;
    }

    if (this.locked) {
        this.onceUnlocked = this.compound.bind(this, f, actions, index, focus);
        return;
    }

    if (actions.length === index) {
        f();
        return;
    }

    this.setEventNodes([]);

    actions[index](cleverapps.once(this.compound.bind(this, f, actions, index + 1, focus)));
};

Metha.prototype.lock = function () {
    if (this.locked) {
        this.debugMessage("metha already locked");
    }
    this.locked = true;
};

Metha.prototype.unlock = function () {
    if (!this.locked) {
        this.debugMessage("metha was not locked");
    }
    this.locked = false;

    if (this.onceUnlocked) {
        var callback = this.onceUnlocked;
        this.onceUnlocked = undefined;
        callback();
    }
};

Metha.prototype.isLocked = function () {
    return this.locked;
};

Metha.prototype.showControlsWhileFocused = function (controls, silent) {
    cleverapps.toArray(controls).forEach(function (control) {
        if (this.isFocused() && this.controls.indexOf(control) < 0) {
            this.controls.push(control);
            if (this.lastControlsVisible[control] !== true) {
                if (this.onControlsListeners[control]) {
                    this.onControlsListeners[control](true, silent);
                }
                this.lastControlsVisible[control] = true;
            }
        } else if (silent && this.onControlsListeners[control]) {
            this.onControlsListeners[control](true, silent);
        }
    }, this);
};

Metha.prototype.isControlEnabled = function (control) {
    return (!this.isFocused() && !cleverapps.gameModes.noControls) || cleverapps.intersect(this.controls, control).length > 0;
};

Metha.prototype.hideAllControls = function () {
    var controls = this.controls;
    this.controls = [];

    if (this.isFocused()) {
        controls.forEach(function (control) {
            if (this.lastControlsVisible[control] !== false) {
                if (this.onControlsListeners[control]) {
                    this.onControlsListeners[control](false);
                }
                this.lastControlsVisible[control] = false;
            }
        }, this);
    }
};

Metha.prototype.hideControlsWhileFocused = function (controls) {
    if (!Array.isArray(controls)) {
        controls = [controls];
    }

    controls.forEach(function (control) {
        if (this.isFocused() && this.controls.indexOf(control) >= 0) {
            this.controls.splice(this.controls.indexOf(control), 1);
            if (this.lastControlsVisible[control] !== false) {
                if (this.onControlsListeners[control]) {
                    this.onControlsListeners[control](false);
                }
                this.lastControlsVisible[control] = false;
            }
        }
    }, this);
};

Metha.prototype.trigger = function () {
    if (this.isFocused()) {
        return;
    }

    while (this.pendingListeners.length > 0) {
        var first = this.pendingListeners.shift();
        first();
        if (this.isFocused()) {
            return;
        }
    }

    this.onFocusLostListenerPlacements();
};

Metha.prototype.disableInput = function (disabled) {
    this.inputDisabled = disabled;
};

Metha.prototype.checkEventNode = function (node) {
    if (node.alwaysOn) {
        return true;
    }

    if (this.inputDisabled) {
        return false;
    }

    var scene = cleverapps.scenes.getRunningScene(node);

    if (!this.isFocused()) {
        return true;
    }

    var eventNodes = this.eventNodes;

    if (scene.toolMenu) {
        eventNodes = eventNodes.concat(scene.toolMenu, scene.toolMenu.opener);
    }

    if (scene.snapshotBar) {
        eventNodes = eventNodes.concat(scene.snapshotBar);
    }

    if (!eventNodes.length) {
        return false;
    }

    if (eventNodes[0] === scene) {
        return true;
    }

    while (node) {
        if (eventNodes.indexOf(node) !== -1) {
            return true;
        }
        node = node.parent;
    }
    return false;
};

Metha.prototype.setEventNodes = function (eventNodes) {
    this.eventNodes = eventNodes;
};

Metha.prototype.grabFocus = function (name, options) {
    if (this.focus !== false) {
        this.debugMessage("Want to grab focus: " + name + " focus is already given: " + this.focus);
    }

    console.log("Grab focus: " + name);
    cleverapps.eventBus.trigger("changeFocus");

    this.focusHistory.push(name);
    this.focusHistory = this.focusHistory.slice(-10);

    if (this.triggerTimeout) {
        clearTimeout(this.triggerTimeout);
        this.triggerTimeout = undefined;
    }

    this.focus = name;
    this.controls = cleverapps.toArray(options && options.control || []);

    this.setControlsVisible(false);

    cleverapps.restoreProgress.update();
};

Metha.prototype.freeFocus = function (name) {
    if (this.focus === false) {
        this.debugMessage("Want to free focus: " + name + " focus is already free!");
    }

    if (this.focus !== name) {
        console.log("Wrong focus clearing: " + this.focus + " > " + name);
        return;
    }

    console.log("Focus freed: " + this.focus);
    cleverapps.eventBus.trigger("changeFocus");
    this.focus = false;

    this.setControlsVisible(true);

    cleverapps.restoreProgress.update();

    cleverapps.bundleLoader.clearUnusedResources();

    this.triggerTimeout = setTimeout(this.trigger.bind(this), 0);
};

Metha.prototype.isFocused = function () {
    return this.focus !== false;
};

Metha.prototype.getType = function () {
    if (cleverapps.config.source === "playable") {
        return Metha.NONE;
    }

    switch (cleverapps.config.meta) {
        case "farm":
            return Metha.FARM;
        case "homefix":
            return Metha.HOMEFIX;
        case "shortmeta":
            return Metha.SHORTMETA;
        case "simple":
            return Metha.SIMPLE;
        case "none":
            return Metha.NONE;
        case "fishdom":
            return Metha.FISHDOM;
        case "loop":
            return Metha.LOOP;
    }

    return Metha.HOSE;
};

Metha.prototype.getMainScene = function () {
    if (cleverapps.config.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Metha.FARM:
            return FarmScene;

        case Metha.HOMEFIX:
            return HomefixScene;

        case Metha.FISHDOM:
            return FishdomScene;
            
        case Metha.SHORTMETA:
            return ShortMetaScene;

        case Metha.SIMPLE:
            return SimpleMainScene;

        case Metha.LOOP:
        case Metha.NONE:
            return GameScene;

        default:
            return HoseScene;
    }
};

Metha.prototype.getRumble = function () {
    return this.getMainObject().knockoutGame.rumble;
};

Metha.prototype.stopRumble = function () {
    return this.getMainObject().knockoutGame.stopRumble();
};

Metha.prototype.getMainObject = function () {
    switch (this.getType()) {
        case Metha.FARM:
            return cleverapps.farm;

        case Metha.FISHDOM:
            return cleverapps.fishdom;

        case Metha.HOMEFIX:
            return cleverapps.home;

        case Metha.SHORTMETA:
            return cleverapps.shortmeta;

        case Metha.SIMPLE:
            return cleverapps.simple;

        case Metha.HOSE:
            return cleverapps.hose;

        case Metha.LOOP:
            return cleverapps.loop;

        case Metha.NONE:
            return NoMetha;
    }
};

Metha.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        new LivesShopWindow();
        cleverapps.meta.onceNoWindowsListener = f;
        return;
    }

    var mainObject = this.getMainObject();

    if (this.getType() === Metha.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : mainObject.findBuildingForActiveItem() || cleverapps.Random.mathChoose(mainObject.listAvailableProvidesBuildings());

        level = MethaHelper.getCurrentLevel(building);
    } else {
        level = level || MethaHelper.getCurrentLevel();
    }

    if (this.getType() === Metha.HOSE) {
        cleverapps.Plot.isPassedAllEpisodes = levels.user.isPassedAll();
    }

    if (mainObject.knockoutGame) {
        mainObject.knockoutGame.wantsToPlay(f, level);
    } else {
        MethaHelper.start(f, level);
    }
};

Metha.prototype.wantsToReplay = function (f, level) {
    MethaHelper.start(f, level);
};

Metha.HOSE = 0;
Metha.FARM = 1;
Metha.HOMEFIX = 2;
Metha.SHORTMETA = 3;
Metha.SIMPLE = 4;
Metha.NONE = 5;
Metha.FISHDOM = 6;
Metha.LOOP = 7;
