/**
 * Created by vladislav on 9/6/2022
 */

var VKPlatform = function (source) {
    Platform.call(this, source || "web_vk");
};

VKPlatform.prototype = Object.create(Platform.prototype);
VKPlatform.prototype.constructor = VKPlatform;

VKPlatform.prototype.getLocalStoragePreffix = function () {
    return "_VK";
};

VKPlatform.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};

VKPlatform.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};

VKPlatform.prototype.canCreateShortcut = function () {
    return true;
};

VKPlatform.prototype.createShortcut = function () {
    cleverapps.social.api("VKWebAppAddToFavorites");
};

VKPlatform.prototype.calcChannel = function () {
    return Platform.prototype.calcChannel.call(this, { param: "hash" })
        || Platform.prototype.calcChannel.call(this, { param: "referrer" }) || "";
};

VKPlatform.prototype.showBannerAd = function (callback) {
    if (!this.bannerAdListener) {
        this.bannerAdListener = function (event) {
            event = event && event.detail;

            if (!event) {
                return;
            }

            if (event.type === "VKWebAppBannerAdUpdated" && event.data.result === true) {
                cleverapps.bannerAd.onPaidEvent();
            }
        };

        vkBridge.subscribe(this.bannerAdListener);
    }

    vkBridge.send("VKWebAppShowBannerAd", {
        layout_type: "resize",
        can_close: false
    })
        .then(function (data) {
            if (!data || !data.result) {
                callback(data || true);
                return;
            }

            callback();

            cleverapps.bannerAd.onPaidEvent();
        })
        .catch(function (e) {
            callback(e || true);
        });
};

VKPlatform.prototype.hideBannerAd = function (callback) {
    vkBridge.send("VKWebAppHideBannerAd")
        .then(function (data) {
            if (!data || !data.result) {
                callback(data || true);
                return;
            }

            callback();

            if (this.bannerAdListener) {
                vkBridge.unsubscribe(this.bannerAdListener);
                this.bannerAdListener = undefined;
            }
        })
        .catch(function (e) {
            callback(e || true);
        });
};