/**
 * Created by mac on 4/21/20
 */

var EditorBase = function () {
    this.loading = true;
    this.history = new UndoHistory();
    this.copyPaste = new CopyPaste();

    this.controls = [];
    this.addBasicControls();

    Editor.currentEditor = this;
};

EditorBase.prototype.addBasicControls = function () {
    this.controls = [
        {
            icon: bundles.editor.frames.editor_play_icon,
            hint: "Play",
            action: function () {
                cleverapps.meta.display({
                    focus: "EditorPlayLevel",
                    actions: [function (f) {
                        setTimeout(f, 500);
                    }, function (f) {
                        LevelManager.getInstance().playLevel(f);
                    }]
                });
            }
        },

        {
            icon: bundles.editor.frames.editor_clear_icon,
            hint: "Clear level",
            action: function () {
                if (confirm("Clear level?")) {
                    Editor.currentEditor.clear();
                }
            }
        },

        {
            icon: bundles.editor.frames.editor_undo_icon,
            hint: "undo",
            comboKeys: [cc.KEY.ctrl, cc.KEY.z],
            action: function () {
                Editor.currentEditor.history.undo();
            }
        },

        {
            icon: bundles.editor.frames.editor_redo_icon,
            comboKeys: [cc.KEY.ctrl, cc.KEY.shift, cc.KEY.z],
            hint: "redo",
            action: function () {
                Editor.currentEditor.history.redo();
            }
        },

        {
            icon: bundles.editor.frames.editor_copy_icon,
            hint: "copy",
            comboKeys: [cc.KEY.ctrl, cc.KEY.c],
            action: function () {
                Editor.currentEditor.copyPaste.copy();
            }
        },

        {
            icon: bundles.editor.frames.editor_paste_icon,
            hint: "paste",
            comboKeys: [cc.KEY.ctrl, cc.KEY.v],
            action: function () {
                Editor.currentEditor.copyPaste.paste();
            }
        }
    ];
};

EditorBase.prototype._save = cleverapps.accumulate(100, function () {
    if (this.loading || !this.prepareEdit()) {
        return;
    }

    var instance = LevelManager.getInstance();
    var level = instance.level;

    instance.update(this.getSaveData());
    instance.save(function () {
        if (!level.logEntry) {
            level.logEntry = cleverapps.administrator.log.createEntry({
                type: AdminLog.EDIT_LEVEL,
                episodeNo: instance.level.episodeNo,
                levelNo: instance.level.levelNo
            });
        } else {
            level.logEntry.updateData();
        }
    });
});

EditorBase.prototype.save = function () {
    if (this.loading || !this.prepareEdit()) {
        return;
    }

    this._save();
};

EditorBase.prototype.getSaveData = function () {
    var data = {
        hard: this.hard
    };
    if (this.creationTime) {
        data.creationTime = this.creationTime;
    }
    if (this.comments) {
        data.comments = this.comments;
    }
    return data;
};

EditorBase.prototype.load = function () {
    var level = LevelManager.getInstance().level;

    if (LevelManager.getInstance().version === undefined) {
        LevelManager.getInstance().setVersion(LevelManager.getInstance().getMaxVersion());
    }

    this.addHardMode(!!level.content.hard);

    if (level.content.creationTime) {
        this.creationTime = level.content.creationTime;
    }

    if (level.content.comments) {
        this.comments = level.content.comments;
    }

    this._load(level.content);

    this.loading = false;
};

EditorBase.prototype.prepareEdit = function () {
    return this.loading || LevelManager.getInstance().prepareEdit();
};

EditorBase.prototype.addHardMode = function (state) {
    this.hard = state;
};

EditorBase.prototype.clear = function () {
    this.save();
};