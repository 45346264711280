/**
 * Created by vladislav on 9/6/2022
 */

var Rustore = function () {
    AndroidBase.call(this, "rustore");
};

Rustore.prototype = Object.create(AndroidBase.prototype);
Rustore.prototype.constructor = Rustore;

Rustore.prototype.getLocalStoragePreffix = function () {
    return "_nosocial";
};

Rustore.prototype.getSuggestedLanguage = function () {
    return [cc.sys.LANGUAGE_RUSSIAN];
};