/**
 * Created by razial on 01.08.2023
 */

var BannerAd = function () {
    this.state = BannerAd.STATE_HIDDEN;

    cleverapps.whenAllInitialized(function () {
        this.show();
    }.bind(this));

    cleverapps.timeouts.setInterval(this.check.bind(this), BannerAd.CHECK_INTERVAL);
};

BannerAd.prototype.canShow = function () {
    if (!cleverapps.platform.isInitialized()) {
        return false;
    }

    var payerLevel = cleverapps.paymentsHistory.classify();
    if (payerLevel !== cleverapps.PaymentsHistory.BRACKET_NONE) {
        return false;
    }

    if (cc.sys.isMobile && Resolution.getDeviceOrientation() !== cc.ORIENTATION_PORTRAIT) {
        return false;
    }

    if (cleverapps.platform.oneOf(Instant)) {
        return cleverapps.config.instant.bannerId && (cc.sys.isInApp || ["riddles", "fairy"].indexOf(cleverapps.config.name) !== -1);
    }

    if (cleverapps.platform.oneOf(Yandex)) {
        return cleverapps.social.isInitialized();
    }

    if (cleverapps.platform.oneOf(VKPlatform)) {
        return cleverapps.social.isInitialized();
    }

    if (cleverapps.platform.oneOf(OKPlatform)) {
        return true;
    }

    if (cleverapps.platform.oneOf(GDCom)) {
        return cleverapps.config.debugMode || ["riddles", "fairy"].indexOf(cleverapps.config.name) !== -1;
    }

    return false;
};

BannerAd.prototype.show = function () {
    if (this.processing || this.state !== BannerAd.STATE_HIDDEN) {
        return;
    }

    if (!this.canShow()) {
        return;
    }

    this.processing = true;

    console.log("showBannerAd loading");
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.LOADING);

    cleverapps.platform.showBannerAd(function (error) {
        this.processing = false;

        if (error) {
            console.log("showBannerAd error", error);
            return;
        }

        this.state = BannerAd.STATE_SHOWN;

        console.log("showBannerAd success");
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.SHOWN);

        cleverapps.playSession.set(cleverapps.EVENTS.STATS.BANNER.DAU, true);

        this.addPaidEventInterval();

        this.check();
    }.bind(this));
};

BannerAd.prototype.hide = function () {
    if (this.processing || this.state !== BannerAd.STATE_SHOWN) {
        return;
    }

    this.processing = true;

    console.log("hideBannerAd loading");

    cleverapps.platform.hideBannerAd(function (error) {
        this.processing = false;

        if (error) {
            console.log("hideBannerAd error", error);
            return;
        }

        this.state = BannerAd.STATE_HIDDEN;

        console.log("hideBannerAd success");
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.HIDDEN);

        this.removePaidEventInterval();
    }.bind(this));
};

BannerAd.prototype.addPaidEventInterval = function () {
    this.removePaidEventInterval();

    if (this.isPaidEventsByInterval()) {
        this.paidEventInterval = cleverapps.timeouts.setInterval(this.onPaidEvent.bind(this), BannerAd.PAID_EVENT_INTERVAL);
    }
};

BannerAd.prototype.removePaidEventInterval = function () {
    if (this.paidEventInterval) {
        cleverapps.timeouts.clearInterval(this.paidEventInterval);
        delete this.paidEventInterval;
    }
};

BannerAd.prototype.check = function () {
    if (this.processing) {
        return;
    }

    if (this.state === BannerAd.STATE_SHOWN && !this.canShow()) {
        this.hide();
        return;
    }

    if (this.state === BannerAd.STATE_HIDDEN && this.canShow()) {
        this.show();
        return;
    }

    if (this.state === BannerAd.STATE_SHOWN) {
        this.processing = true;

        cleverapps.platform.getBannerAdStatus(function (error) {
            this.processing = false;

            if (this.state === BannerAd.STATE_SHOWN && error) {
                this.hide();
            }
        }.bind(this));
    }
};

BannerAd.prototype.onResize = cleverapps.accumulate(300, function () {
    this.check();
});

BannerAd.prototype.getECPM = function () {
    if (cleverapps.platform.oneOf(VKPlatform)) {
        return cleverapps.platform.oneOf(MobileVk) && cleverapps.MobileVkSocial.getPlatfrom() === "html5_mobile" ? BannerAd.eCPM.VK_APP : BannerAd.eCPM.VK;
    }

    if (cleverapps.platform.oneOf(OKPlatform)) {
        return cleverapps.platform.oneOf(MobileOK) ? BannerAd.eCPM.MOBILE_OK : BannerAd.eCPM.WEB_OK;
    }

    if (cleverapps.platform.oneOf(Instant)) {
        return BannerAd.eCPM.Instant;
    }

    if (cleverapps.platform.oneOf(Yandex)) {
        return BannerAd.eCPM.Yandex;
    }
};

BannerAd.prototype.isPaidEventsByInterval = function () {
    return cleverapps.platform.oneOf(Instant, Yandex);
};

BannerAd.prototype.onPaidEvent = function () {
    var impressionCost = this.getECPM() / 1000;
    if (!impressionCost) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.TYPE + "banner", {
        value: impressionCost
    });
};

BannerAd.STATE_HIDDEN = "hidden";
BannerAd.STATE_SHOWN = "shown";

BannerAd.PAID_EVENT_INTERVAL = cleverapps.parseInterval("1 minute");
BannerAd.CHECK_INTERVAL = cleverapps.parseInterval("1 minute");

BannerAd.eCPM = {
    // https://apiok.ru/apps/features/banner_ads
    WEB_OK: 20 / cleverapps.EXCHANGE_RATES.RUB,
    MOBILE_OK: 30 / cleverapps.EXCHANGE_RATES.RUB,

    // https://dev.vk.com/ru/apps-offer-ad
    VK: 25 / cleverapps.EXCHANGE_RATES.RUB,
    VK_APP: 30 / cleverapps.EXCHANGE_RATES.RUB,

    Instant: 7.57,
    Yandex: 30 / cleverapps.EXCHANGE_RATES.RUB
};