/**
 * Created by andrey on 24.05.17.
 */
var ToolFolder = function (listItems) {
    this.listItems = listItems;
};

ToolFolder.create = function (listItems) {
    return new ToolFolder(listItems);
};

var displayToolPrompt = function (callback, text, defaultVal) {
    if (cc.sys.isNative || cleverapps.platform.oneOf(Instant)) {
        cleverapps.meta.display({
            focus: "Prompt",
            action: function (f) {
                new PromptWindow({ callback: callback, text: text, defaultVal: defaultVal });
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    } else {
        var value = window.prompt(text, defaultVal);
        if (value !== null && value !== undefined) {
            callback(value);
        }
    }
};

var displayToolWindow = function (func) {
    return function () {
        if (cleverapps.meta.isFocused()) {
            func();
        } else {
            cleverapps.meta.display({
                focus: "ToolWindow",
                action: function (f) {
                    func();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        }
    };
};

var splitEntriesInParts = function f(items, actionFunc) {
    console.log("split");
    var res = {};
    var batch = {};

    items.forEach(function (item, ind) {
        console.log(item, ind);
        batch[item.name] = actionFunc.bind(undefined, item, ind);

        if (Object.keys(batch).length === 30) {
            res["part" + Object.keys(res).length] = batch;
            batch = {};
        }
    });

    if (Object.keys(batch).length) {
        res["part" + Object.keys(res).length] = batch;
    }

    console.log(res);

    return res;
};

// eslint-disable-next-line no-unused-vars
var dialogues = function () {
    var showDialogue = function (steps, options) {
        options = options || {};

        cleverapps.meta.display({
            focus: "ToolDialogue",
            action: function (f) {
                var dialogue = new Dialogue(steps, {
                    showUp: true,
                    autoClose: true,
                    autoScroll: false,
                    bundles: [options.bundle]
                });

                dialogue.on("afterClose", f);
                new DialogueView(dialogue);
            }
        });
    };

    var mapToMenu = function (menu) {
        var res = {};

        Object.keys(menu).forEach(function (title) {
            res[title] = function () {
                var item = menu[title];
                if (item.options) {
                    showDialogue(item.steps, item.options);
                } else {
                    showDialogue(item);
                }
            };
        });

        return res;
    };

    var listPersons = function () {
        var res = {};

        var allPersons = {};
        Object.keys(cleverapps.config.persons).forEach(function (group) {
            Object.keys(cleverapps.config.persons[group]).forEach(function (person) {
                allPersons[person] = cleverapps.config.persons[group][person];
            });
        });
        for (var role in allPersons) {
            var personName = allPersons[role];

            res[role + ":" + personName] = {
                steps: [
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: role,
                        person2: {
                            role: role,
                            orientation: "right"
                        }
                    },
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: {
                            role: role,
                            orientation: "right"
                        },
                        person2: role
                    },
                    {
                        text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                        person: role,
                        person2: {
                            role: role,
                            orientation: "right"
                        }
                    }
                ],
                options: {}
            };
        }

        return res;
    };

    var listMinimals = function () {
        var minimals = {};

        Object.keys(cleverapps.config.personsMinimal).forEach(function (role) {
            var personName = cleverapps.config.personsMinimal[role];

            var showMinimal = function () {
                var dialogue = new MinimalDialogue({
                    text: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
                    person: role,
                    delay: 0.5
                });

                dialogue.addCleaner(function () {
                    cleverapps.bundleLoader.deleteBundle("dialogues_minimal");
                });

                cleverapps.UI.onClick(dialogue, function () {
                    dialogue.remove();
                });

                cleverapps.scenes.getRunningScene().scene.addChild(dialogue);
                dialogue.display();
            };

            minimals[role + ":" + personName] = function () {
                cleverapps.bundleLoader.loadBundle("dialogues_minimal", {
                    onSuccess: showMinimal
                });
            };
        });

        return minimals;
    };

    var listPlot = function () {
        var steps = {};
        for (var article in cleverapps.styles.dialogues) {
            var step = cleverapps.styles.dialogues[article].dialogue;
            steps[article] = step;
        }

        return steps;
    };

    var res = {
        persons: mapToMenu(listPersons()),
        minimals: listMinimals(),
        plot: mapToMenu(listPlot())
    };

    if (cleverapps.styles.comicses) {
        res.comics = (function () {
            var comicses = {};
            Object.keys(cleverapps.styles.comicses).forEach(function (key) {
                comicses[key] = function () {
                    cleverapps.meta.display({
                        focus: "ToolComics",
                        actions: [
                            function (f) {
                                ComicsScene.showComics(f, cleverapps.styles.comicses[key]);
                            },
                            function (f) {
                                cleverapps.travelBook.gotoMainScene(f);
                            }]
                    });
                };
            });

            return comicses;
        }());
    }

    return res;
};

cleverapps.Tool = {
    snapshots: ToolFolder.create(function () {
        var res = {
            resetDate: function () {
                cleverapps.snapshots.extractData(function (snapshot) {
                    var delta = DateShift.getDelta();

                    var expandTime = cleverapps.expandTime;
                    cleverapps.expandTime = function (time, options) {
                        return expandTime.call(this, time, options) - delta;
                    };

                    if (cleverapps.config.type === "merge") {
                        var expandSeconds = UnitPacker.expandSeconds;
                        UnitPacker.expandSeconds = function (time, key) {
                            return expandSeconds.call(this, time) - (key === "limit" ? 0 : delta);
                        };
                    }

                    snapshot = JSON.parse(JSON.stringify(snapshot));

                    delete snapshot.date;

                    var processDate = function (obj) {
                        for (var name in obj) {
                            if (typeof obj[name] === "number") {
                                if (obj[name] > cleverapps.ZERO_DATE && ["forces", "fogs"].some(function (str) {
                                    return name.indexOf(str) !== 0;
                                })) {
                                    obj[name] -= delta;
                                }
                            } else if (typeof obj[name] === "object") {
                                processDate(obj[name]);
                            }
                        }
                    };
                    processDate(snapshot);

                    cleverapps.synchronizer.overrideProgress(snapshot);

                    cleverapps.meta.whenFreeFocus(function () {
                        cleverapps.expandTime = expandTime;
                        if (cleverapps.config.type === "merge") {
                            UnitPacker.expandSeconds = expandSeconds;
                        }
                    });
                });
            }
        };

        Object.keys(bundles.snapshots.jsons).sort(function (a, b) {
            var aParts = a.split("_");
            var bParts = b.split("_");
            for (var i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                var aInt = parseInt(aParts[i]);
                var bInt = parseInt(bParts[i]);

                if (isNaN(aInt) && isNaN(bInt)) {
                    return a.level - b.level;
                }
                if (!isNaN(aInt) && isNaN(bInt)) {
                    return 1;
                }
                if (isNaN(aInt) && !isNaN(bInt)) {
                    return -1;
                }
                if (!isNaN(aInt) && !isNaN(bInt)) {
                    return aInt - bInt;
                }
            }
            return 0;
        }).forEach(function (name) {
            res[name] = function () {
                var snapshot = cc.loader.getRes(bundles.snapshots.jsons[name]);
                cleverapps.synchronizer.overrideProgress(snapshot);
            };
        });

        if (cleverapps.config.importMode) {
            return Object.assign({
                download: function () {
                    cleverapps.snapshots.exportToJson(cleverapps.loadedSnapshot);
                },
                createScene: function () {
                    cleverapps.RestClient.post(
                        "/wysiwyg/createfromtemplate",
                        {
                            snapshot: window.location.pathname.substring(1),
                            actions: cleverapps.loadedSnapshot.actions,
                            flags: cleverapps.loadedSnapshot.flags
                        },
                        function (clipId) {
                            cleverapps.flags.norest = true;
                            window.location = projects[cleverapps.config.name].stand.staticUrl + "#wysiwyg=true&directory=wysiwyg/tmp&clipId=" + clipId;
                        },
                        function () {

                        },
                        {
                            ignoreNoRest: true
                        }
                    );
                }
            }, res);
        }

        return Object.assign({
            import: function () {
                cleverapps.snapshots.importFromUrl(function (snapshot) {
                    if (!snapshot.abtest) {
                        cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.ABTEST);
                        cleverapps.abTest.updateGroups(snapshot.users.id);
                        snapshot.abtest = cleverapps.abTest.groups;
                    }

                    cleverapps.synchronizer.overrideProgress(snapshot);
                });
            },

            importJson: function () {
                var input = document.createElement("input");
                input.type = "file";

                input.onchange = function (event) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        cleverapps.synchronizer.overrideProgress(JSON.parse(event.target.result.toString()));
                    };
                    reader.readAsText(event.target.files[0]);
                };

                input.click();
            },

            download: function () {
                cleverapps.snapshots.importFromUrl(function (snapshot) {
                    cleverapps.snapshots.exportToJson(snapshot);
                });
            }
        }, res);
    }),

    wysiwyg: function () {
        if (!cleverapps.wysiwyg && typeof Wysiwyg !== "undefined") {
            cleverapps.wysiwyg = new Wysiwyg();
        }
        cleverapps.toolModel.hide();

        var openWysiwyg = function (f) {
            cleverapps.config.wysiwygMode = true;
            cleverapps.setUrlHash({ "wysiwyg": true });
            cleverapps.scenes.replaceScene(new WysiwygScene(cleverapps.wysiwyg), f);
        };

        if (cleverapps.meta.isFocused()) {
            cleverapps.meta.distract({
                focus: "wysiwyg",
                action: openWysiwyg
            });
        } else {
            cleverapps.meta.display({
                focus: "wysiwyg",
                action: openWysiwyg
            });
        }
    },

    "Meta homefix": (cleverapps.config.meta === "homefix") ? {
        fromStart: function () {
            var home = cleverapps.home;
            if (!home) {
                return;
            }

            HomeTool.set(home.firstFurnitureId(), 0, false, function () {
                cleverapps.refreshScene();
            });
        },

        gotoFurniture: function () {
            var home = cleverapps.home;
            if (!home) {
                return [];
            }

            var actionFunc = function (building) {
                HomeTool.set(building.id, 0, false, function () {
                    cleverapps.refreshScene();
                });
            };

            return splitEntriesInParts(home.readConfig().items, actionFunc);
        },

        nextFurniture: function () {
            var home = cleverapps.home;

            if (home.isCompleted()) {
                return;
            }

            HomeTool.set(home.getNextId(), 0, true, function () {
                cleverapps.refreshScene();
            });
        },

        nextStage: function () {
            var home = cleverapps.home;

            if (home.isCompleted()) {
                return;
            }

            var nextProgress = HomeTool.calcNextProgress(home.getMaxFurniture());
            HomeTool.set(nextProgress.furnitureId, nextProgress.stage, true, function () {
                cleverapps.refreshScene();
            });
        },

        passAll: function () {
            var home = cleverapps.home;
            HomeTool.set(home.furniture[home.furniture.length - 1].id, 0, false, function () {
                cleverapps.refreshScene();
            });
        },

        lastLevel: function () {
            var home = cleverapps.home;
            HomeTool.set(home.furniture[home.furniture.length - 1].id, 0, true, function () {
                cleverapps.refreshScene();
            });
        },

        add20Stars: function () {
            cleverapps.home.earnStars(20);
        }
    } : undefined,

    Navigation: (cleverapps.config.type !== "merge") ? {
        goto_episode: function () {
            cleverapps.Tool.Navigation.goto_level("goto_episode");
        },

        goto_level: function (type) {
            var callback = function (level) {
                if (type === "goto_episode" && parseInt(level) >= 0) {
                    level = parseInt(level) * 15 + 1;
                }
                if (level) {
                    level = parseInt(level) - 1;

                    levels.user.level = level % Episode.LEVELS_PER_EPISODE;
                    levels.user.episode = Math.floor(level / Episode.LEVELS_PER_EPISODE);
                    levels.user.progress = 0;

                    var total = cleverapps.episodes.getAmountEpisodes();
                    if (levels.user.episode >= total) {
                        levels.user.episode = total;
                        levels.user.level = 0;
                        levels.user.progress = level - levels.user.getHumanReadableNumber() + 1;
                    }

                    cleverapps.user.save();
                    cleverapps.DataLoader.processSaveQueue();

                    if (cleverapps.hose) {
                        cleverapps.hose.reset();
                        cleverapps.hose.setCurrentEpisode(levels.user.episode);
                    }

                    if (cleverapps.playButton) {
                        cleverapps.playButton.update();
                    }

                    if (cleverapps.isKnockoutGame()) {
                        cleverapps.meta.getMainObject().knockoutGame.setLevel(level);
                        cleverapps.meta.getMainObject().save();
                    }

                    if (typeof match3 !== "undefined" && match3 && match3.heroes) {
                        match3.heroes.checkAvailableToOpen();
                    }

                    cleverapps.sideBar.refreshAllIcons();
                    cleverapps.refreshScene();
                }
            };

            displayToolPrompt(callback, "Level", 15);
        }
    } : undefined,

    "Meta hose": (cleverapps.config.meta === "hose") ? {
        pass_level: function () {
            if (levels.user.isPassedAll()) {
                return;
            }

            var episode = cleverapps.hose.episodes[cleverapps.environment.episodeNo];
            var level = episode.getCurrentLevel();
            if (level) {
                levels.user.incLevel();
            }

            cleverapps.refreshScene();
        },

        pass_episode: function () {
            if (levels.user.isPassedAll()) {
                return;
            }

            var episode = cleverapps.hose.episodes[cleverapps.environment.episodeNo];
            while (episode.getCurrentLevel() && episode.getCurrentLevel().levelNo < episode.levelsPerEpisode) {
                levels.user.incLevel();
            }
            cleverapps.refreshScene();
        },

        pass_all: function () {
            var getCurLvl = function (episode) {
                var curLvl = episode.getCurrentLevel();
                if (cleverapps.meta.getType() !== Metha.HOSE) {
                    if (episode.episodeNo === cleverapps.user.episode) {
                        curLvl = episode.levels[cleverapps.user.level];
                    } else {
                        return undefined;
                    }
                }
                return curLvl;
            };

            cleverapps.meta.display({
                focus: "ToolPassAll",
                action: function () {
                    while (!levels.user.isPassedAll()) {
                        var episode = cleverapps.hose.getCurrentEpisode();

                        while (getCurLvl(episode) && getCurLvl(episode).levelNo < episode.levelsPerEpisode) {
                            levels.user.incLevel();
                        }

                        cleverapps.hose.nextEpisode();
                    }

                    cleverapps.refreshScene();
                }
            });
        }
    } : undefined,

    "Meta farm": (cleverapps.config.meta === "farm") ? {
        gotoBuilding: function () {
            var farm = cleverapps.farm;
            if (!farm) {
                return [];
            }

            var actionFunc = function (building, ind) {
                Farm.Reset();
                var newData = Farm.GetBuildingProgress(ind, 0);
                farm.load(newData);
                farm.save();
                cleverapps.refreshScene();
            };

            return splitEntriesInParts(farm.readConfig().items, actionFunc);
        },

        nextBuilding: function () {
            var farm = cleverapps.farm;

            if (farm.isCompleted()) {
                return;
            }

            FarmTool.set(farm.currentBuildingId + 1, 0, true);

            cleverapps.meta.display({
                focus: "ToolMoveNext",
                action: function (f) {
                    farm.moveNext(f);
                }
            });
        },

        nextQuest: function () {
            var farm = cleverapps.farm;

            if (farm.isCompleted()) {
                return;
            }

            var nextProgress = FarmTool.calcNextProgress(farm.getBuilding());
            FarmTool.set(nextProgress.buildingId, nextProgress.questId, true);

            cleverapps.meta.display({
                focus: "ToolMoveNext",
                action: function (f) {
                    farm.moveNext(f);
                }
            });
        },

        passAll: function () {
            var farm = cleverapps.farm;

            FarmTool.set(farm.buildings.length, 0);

            cleverapps.refreshScene();
        },

        listQuestItems: ToolFolder.create(function () {
            var farm = cleverapps.farm;

            if (!farm || !farm.getBuilding() || !farm.getBuilding().getQuest()) {
                return;
            }

            var res = {};
            var quest = farm.getBuilding().getQuest();
            var keys = farm.keys;
            quest.items.forEach(function (item) {
                res[item.name] = function () {
                    var currentAmount = keys[item.name] || 0;
                    var amount = parseInt(window.prompt(item.name + " " + currentAmount + "/" + item.amount, currentAmount));
                    if (!isNaN(amount)) {
                        keys[item.name] = amount;
                        farm.save();
                    }
                };
            });
            return res;
        }),

        setItems: function () {
            var farm = cleverapps.farm;
            var input = window.prompt("Enter required items \n e.g. coke=2,mentos=5");
            if (input) {
                input.replace(/ /g, "").split(",").forEach(function (item) {
                    var index = item.indexOf("=");
                    var key = item.substr(0, index);
                    var val = parseInt(item.substr(index + 1));
                    farm.keys[key] = val;
                    farm.save();
                });
            }
        },

        addCurrentQuestItems: function (isLastLevel) {
            var farm = cleverapps.farm;
            var quest = farm.getBuilding().getQuest();
            if (quest) {
                var keys = farm.keys;
                quest.items.forEach(function (item, index) {
                    if (!keys[item.name]) {
                        keys[item.name] = 0;
                    }

                    if (keys[item.name] < item.amount) {
                        keys[item.name] = item.amount;
                    }
                    if (isLastLevel === true && index === 0) {
                        keys[item.name]--;
                    }
                });
            }
            farm.save();
        },

        addAllItems: function () {
            var keys = {};
            cleverapps.farm.buildings.forEach(function (building) {
                if (keys[building.provides]) {
                    return;
                }
                keys[building.provides] = true;
                for (var i = 0; i < 1000; i++) {
                    cleverapps.farm.keys[building.provides] = 1000;
                }
            });

            cleverapps.farm.save();
        },

        lastLevel: function () {
            var farm = cleverapps.farm;

            FarmTool.set(farm.buildings.length, 0, true);

            cleverapps.refreshScene();
        },

        inspectByBuildings: function () {
            var actionFunc = function (building) {
                var provides = [];

                for (var level = 1; level <= 100; level++) {
                    var levelProvide = [0, 0, 0];

                    if (building.id === 0 && level === 1) {
                        levelProvide = [3, 0, 0];
                    } else {
                        cleverapps.Random.seed(building.id * 10000 + level);
                        for (var i = 0; i < 3; i++) {
                            var t = cleverapps.Random.nextDouble();
                            if (t < cleverapps.farm.probabilities[0]) {
                                levelProvide[0]++;
                            } else if (t < cleverapps.farm.probabilities[1]) {
                                levelProvide[1]++;
                            } else {
                                levelProvide[2]++;
                            }
                        }
                    }
                    provides.push(levelProvide);
                }
                console.log(building.name, provides);
            };

            return splitEntriesInParts(cleverapps.farm.buildings, actionFunc);
        },

        inspect: function () {
            var data = [];
            var fakeKeys = {};
            var fakeBuildingLevels = {};
            var skippedItems = [];

            var farm = cleverapps.farm;
            if (!farm) {
                return;
            }

            var totalLlvs = 0;

            farm.buildings.forEach(function (building) {
                var ind = data.push({}) - 1;
                data[ind][building.name] = {};
                fakeBuildingLevels[building.name] === undefined && (fakeBuildingLevels[building.name] = 0);

                building.quests.forEach(function (quest) {
                    var levelsOnQuest = 0;
                    var totalQuestProvides = {};
                    data[ind][building.name][Messages.get(quest.title)] = {
                        perItems: {}
                    };

                    quest.items.forEach(function (item) {
                        if (fakeKeys[item.name] === undefined) {
                            fakeKeys[item.name] = 0;
                        }
                        var totalItemProvides = {};
                        var levelsOnItem = 0;

                        while (fakeKeys[item.name] < item.amount) {
                            levelsOnItem++;
                            levelsOnQuest++;
                            var targetBuilding = farm.findProperBuilding(item);
                            fakeBuildingLevels[targetBuilding.name] === undefined && (fakeBuildingLevels[targetBuilding.name] = 0);
                            fakeBuildingLevels[targetBuilding.name]++;

                            var itemName = targetBuilding.provides;
                            fakeKeys[itemName] === undefined ? fakeKeys[itemName] = 1 : fakeKeys[itemName]++;
                            totalItemProvides[itemName] === undefined ? totalItemProvides[itemName] = 1 : totalItemProvides[itemName]++;
                            totalQuestProvides[itemName] === undefined ? totalQuestProvides[itemName] = 1 : totalQuestProvides[itemName]++;
                        }

                        if (!levelsOnItem) {
                            skippedItems.push(item.name + " - " + Messages.get(quest.title) + " (" + building.name + ")");
                        }

                        fakeKeys[item.name] -= item.amount;
                        data[ind][building.name][Messages.get(quest.title)].perItems[item.name] = {
                            earnedOnItem: cleverapps.clone(totalItemProvides),
                            levelsOnItem: levelsOnItem
                        };
                    });

                    data[ind][building.name][Messages.get(quest.title)].earnedOnQuest = cleverapps.clone(totalQuestProvides);
                    data[ind][building.name][Messages.get(quest.title)].levelsOnQuest = levelsOnQuest;
                    totalLlvs += levelsOnQuest;
                    data[ind][building.name][Messages.get(quest.title)].total = cleverapps.clone(fakeKeys);
                });
            });

            data.push({
                total: cleverapps.clone(fakeKeys),
                skippedItems: skippedItems,
                totalLvls: totalLlvs
            });

            data.forEach(function (item) {
                console.log(item);
            });

            console.log("Print clock to get json data");
            clock = JSON.stringify(data);
        }
    } : undefined,

    "Meta simple": (cleverapps.config.meta === "simple") ? {
        reset: function () {
            cleverapps.simple.stars = 0;
            cleverapps.simple.current = 0;

            cleverapps.simple.save();

            cleverapps.refreshScene();
        },

        moveNext: function () {
            cleverapps.user.incLevel();

            cleverapps.simple.earnStars(cleverapps.simple.getRequiredStars() - cleverapps.simple.stars - 3, true);
            cleverapps.simple.earnStars(3);

            cleverapps.meta.onFocusLostListenerPlacements();
        },

        moveTo: ToolFolder.create(function () {
            console.log("moveto");
            var entries = [];

            var moveTo = function (item, index) {
                cleverapps.simple.current = index;
                cleverapps.simple.stars = 0;
                cleverapps.simple.save();
                cleverapps.refreshScene();
            };

            for (var i = 0; i < cleverapps.simple.bgsAmount; i++) {
                entries.push({
                    name: Messages.has("background_name_" + i) ? Messages.get("background_name_" + i) : "UNNAMED_" + i
                });
            }

            return splitEntriesInParts(entries, moveTo);
        }),

        passAll: function () {
            cleverapps.simple.current = cleverapps.simple.bgsAmount - 1;
            cleverapps.simple.save();

            cleverapps.refreshScene();
        },

        add1: function () {
            cleverapps.simple.earnStars(1, false);
            cleverapps.simple.updateProgress();
        },

        add10: function () {
            cleverapps.simple.earnStars(10, false);
            cleverapps.simple.updateProgress();
        }
    } : undefined,

    UI: {
        dialogues: ToolFolder.create(dialogues),

        windowPersons: ToolFolder.create(function () {
            var res = {};

            var allPersons = {};
            Object.keys(cleverapps.config.persons).forEach(function (group) {
                Object.keys(cleverapps.config.persons[group]).forEach(function (person) {
                    allPersons[person] = {};
                    allPersons[person].name = cleverapps.config.persons[group][person];
                    allPersons[person].group = group;
                });
            });
            Object.keys(allPersons).forEach(function (role) {
                var personName = allPersons[role];

                var bundleName = "persons_" + allPersons[role].group;

                if (!bundles[bundleName]) {
                    bundleName = role;
                }

                res[role + ":" + personName] = function () {
                    cleverapps.meta.display({
                        focus: "ToolWindowPersons",
                        action: function (f) {
                            console.log(role, bundleName);
                            cleverapps.bundleLoader.loadBundle(bundleName, {
                                onSuccess: function () {
                                    var window = new SettingsWindow();

                                    var persons = new WindowPersons({
                                        role: role,
                                        emotion: "idle"
                                    }, window);
                                    window.persons = persons;
                                    window.persons.positionPersons();
                                    window.persons.showUp();

                                    var leftPerson = persons.leftPerson;

                                    var animations = Object.keys(leftPerson.data.animations);

                                    var buttons = animations.map(function (animation) {
                                        return new cleverapps.UI.Button({
                                            text: animation,
                                            width: 250,
                                            height: 70,
                                            onClicked: function () {
                                                leftPerson.setAnimation(0, animation, true);
                                                leftPerson.setCompleteListener(function () {});
                                            }
                                        });
                                    });

                                    var layout = new cleverapps.Layout(buttons, {
                                        margin: 10,
                                        direction: cleverapps.UI.VERTICAL
                                    });
                                    cleverapps.UI.fitToBox(layout, {
                                        height: 700
                                    });
                                    window.addChild(layout);
                                    layout.setPositionRound({ x: { align: "left", dx: 50 }, y: { align: "top", dy: -50 } });

                                    cleverapps.meta.onceNoWindowsListener = function () {
                                        cleverapps.bundleLoader.deleteBundle(bundleName);
                                        f();
                                    };
                                }
                            });
                        }
                    });
                };
            });

            return res;
        }),

        skins: ToolFolder.create(function () {
            var res = {};
            Object.keys(cleverapps.SkinManager.SKINS).forEach(function (skin) {
                res[skin] = function () {
                    cleverapps.eventManager.events = cleverapps.eventManager.events.filter(function (event) {
                        return !event.features.some(function (feature) {
                            return cleverapps.SkinManager.SKINS[feature];
                        });
                    });
                    if (skin !== "default") {
                        cleverapps.eventManager.setCustomFeature(skin, cleverapps.parseInterval("1 day"));
                    } else {
                        cleverapps.eventManager.setCurrentFeatures();
                    }
                };
            });
            return res;
        }),

        windows: {
            restartWindow: displayToolWindow(function () {
                new RestartWindow();
            }),

            canUpgradeHeroWindow: displayToolWindow(function () {
                new CanUpgradeHeroWindow();
            }),

            tellWindow: displayToolWindow(function () {
                new TellWindow({
                    text: "TreasureSearchWindow.Help.Text",
                    title: "TreasureSearchWindow.Help.Title"
                });
            }),

            selectFriendWindow: displayToolWindow(function () {
                new SelectFriendWindow("askLives", function () {

                });
            }),

            newLeague: displayToolWindow(function () {
                new NewLeagueWindow();
            }),

            rateWindow: displayToolWindow(function () {
                new RateWindow();
            }),

            victoryWindow: displayToolWindow(function () {
                if (!Game.currentGame) {
                    return;
                }

                new VictoryWindow(Game.currentGame, cleverapps.scenes.getRunningScene().boatswain);
            }),

            miniGameWindow: displayToolWindow(function () {
                cleverapps.periodicBonus.unlock();
                var reward = cleverapps.clone(cleverapps.periodicBonus.getReward(), true);
                new MiniGameWindow(reward);
            }),

            startEpisodeWindow: displayToolWindow(function () {
                new StartEpisodeWindow(cleverapps.hose.getCurrentEpisode());
            }),

            finishEpisodeWindow: (cleverapps.config.meta === "hose") ? displayToolWindow(function () {
                new FinishEpisodeWindow(cleverapps.user.episode);
            }) : undefined,

            finisherWindow: displayToolWindow(function () {
                new FinisherWindow();
            }),

            fbConnectWindow: displayToolWindow(function () {
                new FbConnectWindow({
                    errorMessage: "FbConnectWindow.TryAgain",
                    onSuccess: function () {},
                    onFailure: function () {}
                });
            }),

            restoreProgress: displayToolWindow(function () {
                new FbConnectWindow({
                    restoreProgress: true,
                    onSuccess: function () {},
                    onFailure: function () {}
                });
            }),

            loseWindow: displayToolWindow(function () {
                new LoseWindow(Game.currentGame);
            }),

            tacticalExtendWindow: displayToolWindow(function () {
                new TacticalExtendWindow();
            }),

            waitWindow: displayToolWindow(function () {
                new WaitWindow();
            }),

            crossPromoWindow: displayToolWindow(function () {
                var promotion = cleverapps.crossPromo.getFirstAvailable();

                if (!promotion) {
                    cleverapps.notification.create("No promo available for " + cleverapps.platform.source);
                } else {
                    new CrossPromoWindow(promotion);
                }
            }),

            reloadWindow: displayToolWindow(function () {
                new ReloadWindow();
            }),

            tutorialFinishWindow: function () {
                new TutorialFinishWindow();
            },

            newVersionWindow: displayToolWindow(function () {
                new NewVersionWindow();
            }),

            helpByFiveAttemptsWindow: displayToolWindow(function () {
                new HelpByFiveAttemptsWindow();
            }),

            packWindow: displayToolWindow(function () {
                new PackWindow();
            }),

            packWindow1: displayToolWindow(function () {
                cleverapps.packManager.used = 0;
                new PackWindow();
            }),

            packWindow2: displayToolWindow(function () {
                cleverapps.packManager.used = 1;
                new PackWindow();
            }),

            packWindow3: displayToolWindow(function () {
                cleverapps.packManager.used = 2;
                new PackWindow();
            }),

            unlimitedLivesFinish: displayToolWindow(function () {
                new ExtendUnlimitedLivesWindow();
            }),

            allDone: displayToolWindow(function () {
                new AllDoneWindow();
            }),

            addBombMovesWindow: displayToolWindow(function () {
                new AddBombMovesWindow(100, "a", function () {}, function () {}, function () {});
            }),

            prolongationWindow: displayToolWindow(function () {
                new ProlongationWindow({
                    onGiveUp: function () {
                    }
                });
            }),

            confirmExitWindow: displayToolWindow(function () {
                new ConfirmExitWindow();
            }),

            vipRewardedWindow: displayToolWindow(function () {
                new VIPRewardedWindow(function () {}, function () {});
            }),

            passLastChance: displayToolWindow(function () {
                new PassLastChanceWindow(cleverapps.missionManager.findLocalPass());
            }),

            dailyLevelWindow: displayToolWindow(function () {
                new DailyLevelWindow();
            }),

            emailWindow: displayToolWindow(function () {
                new EmailWindow();
            })
        },

        rewardWindows: {
            StarChestWindow: displayToolWindow(function () {
                new StarChestWindow();
            }),

            DailyTaskRewardWindow: displayToolWindow(function () {
                cleverapps.dailyTasks.whenStart = -1;
                cleverapps.dailyTasks.processResults();

                var tasks = cleverapps.dailyTasks.getActiveTasks();
                var task = tasks[Math.floor(Math.random() * tasks.length)];
                if (task) {
                    RewardWindow.createDailyTaskWindow(task);
                }
            }),

            MiniGameRewardWindow: displayToolWindow(function () {
                RewardWindow.createMiniGameWindow(cleverapps.clone(cleverapps.periodicBonus.getReward(), true));
            }),

            RewardWindow: displayToolWindow(function () {
                var reward = {
                    hard: 100
                };

                if (cleverapps.lives) {
                    reward.unlimitedLives = "1 day";
                }

                if (cleverapps.config.type === "merge") {
                    delete reward.unlimitedLives;
                    reward.wands = 10;
                    reward.soft = 100;
                    reward.unit = [{
                        code: "king",
                        stage: 0,
                        amount: 1
                    }, {
                        code: "magicplant",
                        stage: 5,
                        amount: 1
                    }];
                }

                var boosters = cleverapps.boosters.listBoostersBefore();
                if (boosters.length > 0) {
                    reward.boosters = {};
                    for (var i = 0; i < boosters.length; i++) {
                        reward.boosters[boosters[i].id] = 1;
                    }
                }

                new RewardWindow(reward, {
                    shareId: "reward"
                });
            }),

            HeroLevelRewardWindow: displayToolWindow(function () {
                new RewardWindow({
                    herolevels: [
                        "a3",
                        "b5"
                    ]
                });
            }),

            ExpeditionRewardWindow: displayToolWindow(function () {
                RewardWindow.createMissionWindow({
                    soft: 600,
                    unit: { code: "crystal", stage: 3, amount: 5 }
                }, Missions[Mission.TYPE_DRAGONIA_EXPEDITION]);
            })
        }
    },

    ADS: ToolFolder.create(function () {
        var res = {
            cacheRewarded: function () {
                cleverapps.rewardedAdsManager.cacheNextAd(RewardedAdsManager.REWARDED);
            },

            playRewarded: function () {
                cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.BANK, function () {
                    console.log("REWARDED!");
                });
            },

            playInterstitial: function () {
                cleverapps.meta.display({
                    focus: "ToolPlayInterstitial",
                    action: function (f) {
                        cleverapps.rewardedAdsManager.showInterstitial(f);
                    }
                });
            },

            fake_rewarded_off: function () {
                VideoAds.prototype.isAvailable = function () {
                    return false;
                };
                VKAds.prototype.isAvailable = function () {
                    return false;
                };
            },

            disableAds: function () {
                cleverapps.rewardedAdsManager.isAvailableOld = cleverapps.rewardedAdsManager.isAvailable;
                cleverapps.rewardedAdsManager.isAvailable = function () {
                    return false;
                };
            },

            enableAds: function () {
                cleverapps.rewardedAdsManager.isAvailable = cleverapps.rewardedAdsManager.isAvailableOld;
            }
        };

        if (cleverapps.platform.oneOf(AndroidPlatform)) {
            ["AdMobAds", "YandexAppAds"].forEach(function (pluginName) {
                var PluginClass = window[pluginName];
                var currentPluginClass = cleverapps.rewardedAdsManager.plugin && cleverapps.rewardedAdsManager.plugin.constructor;
                if (PluginClass === currentPluginClass) {
                    return;
                }

                res[pluginName] = function () {
                    cleverapps.rewardedAdsManager.createPlugin = function () {
                        cleverapps.rewardedAdsManager.plugin = new PluginClass();
                    };
                    cleverapps.rewardedAdsManager.init();

                    cleverapps.toolModel.refresh("ADS");
                };
            });
        }

        return res;
    }),

    Debug: {
        resolution: ToolFolder.create(function () {
            var sorted = Object.keys(resolutions).sort(function (a, b) {
                return resolutions[a].scale - resolutions[b].scale;
            });

            var res = {};

            var currName = cleverapps.settings.calcResolution();

            sorted.forEach(function (name) {
                if (name !== currName) {
                    res[name] = function () {
                        cleverapps.settings.setManualResolution(name);
                    };
                } else {
                    res[name + " [CURRENT]"] = function () {};
                }
            });

            return res;
        }),

        AtlasAnalyzer: function () {
            cleverapps.atlasAnalyzer = new AtlasAnalyzer();
            AtlasAnalyzerScene.open(cleverapps.atlasAnalyzer);
        },

        restoreCheck: ToolFolder.create(function () {
            var res = {};

            res[cleverapps.Tool.restoreCheck ? "disable" : "enable"] = function () {
                cleverapps.Tool.restoreCheck = !cleverapps.Tool.restoreCheck;
                cleverapps.toolModel.refresh("restoreCheck");
            };

            return res;
        }),

        browserLanguage: function () {
            alert("System: " + cc.sys.language + " navigator: " + navigator.language);
        },

        clearLocalStorage: function () {
            var iosWebView = cc.sys.OS_IOS === cc.sys.os && cc.sys.isNative;
            if (iosWebView) {
                webkitMessage("localStorage", {
                    action: "clear"
                });
            } else if (cc.sys.OS_ANDROID === cc.sys.os && cc.sys.isNative) {
                cc.sys.localStorage.clear();
            }

            cleverapps.DataLoader.cleanAll();
            cleverapps.DataLoader.enabled = false;
            cleverapps.DataLoader.resetClientSession();

            cleverapps.meta.display({
                name: "waitReload",
                action: function () {
                    new WaitWindow();
                }
            });
        },

        printAllLetters: function () {
            var letters = {};
            cleverapps.values(Messages.dictionary).forEach(function (message) {
                // console.log(message);
                if (!Array.isArray(message)) {
                    message = [message];
                }
                message.forEach(function (m) {
                    m.split("").forEach(function (ch) {
                        letters[ch.toLowerCase()] = true;
                    });
                });
            });

            letters = Object.keys(letters).sort().join("");
            console.log(letters.length);
            console.log(letters);
        },

        reset: function () {
            cleverapps.Tool.Debug.clearLocalStorage();
            cleverapps.snapshots.reset();

            var iosWebView = cc.sys.OS_IOS === cc.sys.os && cc.sys.isNative;
            setTimeout(function () {
                window.location.reload();
            }, iosWebView ? 500 : 40000);

            cleverapps.meta.display({
                name: "waitReload",
                action: function () {
                    new WaitWindow();
                }
            });

            if (cleverapps.Tool.resetShare) {
                cleverapps.Tool.resetShare();
            }

            levels.user.level = 0;
            levels.user.episode = 0;
            levels.user.initializeNewPlayerGold();
            levels.user.progressCompare = 0;
            levels.user.progress = 0;
            levels.user.save(true);
            var id = levels.user.id;

            if (cleverapps.farm) {
                cleverapps.farm.reset();
            }

            if (cleverapps.home) {
                cleverapps.home.reset();
            }

            if (cleverapps.simple) {
                cleverapps.simple.reset();
            }

            if (cleverapps.shortmeta) {
                cleverapps.shortmeta.reset();
            }

            var callback2 = cleverapps.once(function () {
                cleverapps.settings = new cleverapps.Settings(true);

                if (cleverapps.periodicBonus) {
                    cleverapps.periodicBonus = new cleverapps.MiniGame(true);
                    cleverapps.periodicBonus.save(true);
                }

                levels.user = new levels.User(true);
                levels.user.id = id;
                cleverapps.user = levels.user;

                if (cleverapps.boosters) {
                    cleverapps.boosters = new cleverapps.Boosters(true);
                    cleverapps.boosters.save(true);
                }

                if (cleverapps.bonusRoundChest) {
                    cleverapps.bonusRoundChest = new cleverapps.BonusRoundChest(true);
                    cleverapps.bonusRoundChest.save(true);
                }

                if (cleverapps.subscription && cleverapps.subscription.isActive()) {
                    cleverapps.subscription.active = false;
                    cleverapps.subscription.save();
                }

                if (cleverapps.crossPromo) {
                    cleverapps.crossPromo.reset();
                }

                if (cleverapps.starChest) {
                    cleverapps.starChest = new cleverapps.StarChest(true);
                    cleverapps.starChest.save(true);
                }

                if (cleverapps.packManager) {
                    cleverapps.packManager = new PackManager(true);
                    cleverapps.packManager.save(true);
                }

                if (Game.currentGame && Game.currentGame.storeSave) {
                    Game.currentGame.storeSave = function () {};
                }

                cleverapps.forces.reset();
                cleverapps.GameSaver && cleverapps.GameSaver.reset();
                cleverapps.missionManager.reset();

                cleverapps.friends.reset();

                if (cleverapps.hose) {
                    cleverapps.hose.reset();
                    cleverapps.hose.setCurrentEpisode(levels.user.episode);
                }

                if (cleverapps.serverFlags) {
                    cleverapps.serverFlags.reset();
                }

                if (cleverapps.unlimitedLives) {
                    cleverapps.unlimitedLives.reset();
                }

                if (cleverapps.lives) {
                    cleverapps.lives.reset();
                }

                if (cleverapps.growthFund) {
                    cleverapps.Tool.growthFund.reset();
                }

                cleverapps.unitsLibrary && cleverapps.unitsLibrary.reset();
                typeof Quests !== "undefined" && Quests.reset();
                typeof Workers !== "undefined" && Workers.reset();
                typeof Harvested !== "undefined" && Harvested.reset();
                typeof Fogs !== "undefined" && Fogs.reset();
                typeof Pocket !== "undefined" && Pocket.reset();

                if (cleverapps.unitSavers) {
                    cleverapps.unitSavers.reset();
                }

                cleverapps.social.logout();

                cleverapps.synchronizer._syncOut.onTasksEmptyListener = function () {
                    window.location.reload();
                };
            });

            var callback1 = cleverapps.once(function () {
                levels.reset();
                cleverapps.synchronizer.onceSyncCompletedListener = callback2;
            });

            if (cleverapps.isLocalhost() && !cleverapps.synchronizer.synced) {
                levels.reset();
                callback2();

                window.location.reload();
            } else {
                cleverapps.synchronizer._syncOut.getAccumulateTimeout = function () {
                    return 1;
                };
                cleverapps.synchronizer._syncOut.onTasksEmptyListener = callback1;
                cleverapps.synchronizer._syncOut._sendUpdateInner();
            }
        },

        adminAccess: function () {
            cleverapps.meta.display({
                focus: "AdminAccessWindow",
                action: function (f) {
                    new AdminAccessWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        },

        forces: {
            shownForces: ToolFolder.create(function () {
                var ids = Object.keys(Forces).filter(function (key) {
                    return typeof Forces[key] === "object" && cleverapps.forces.isShown(Forces[key].id);
                });

                var res = {};
                ids.forEach(function (id) {
                    res["X__" + id] = function () {
                        cleverapps.forces.clearForce(Forces[id].id);

                        cleverapps.toolModel.refresh();
                    };
                });

                return res;
            }),

            markAllShown: function () {
                if (cleverapps.forces.isRunningForce()) {
                    cleverapps.forces.closeRunningForce();
                }

                Object.keys(Forces).forEach(function (key) {
                    if (typeof Forces[key] === "object" && Forces[key].id !== undefined) {
                        cleverapps.forces.saveForce(Forces[key].id);
                    }
                });
            },

            clearAll: function () {
                Object.keys(Forces).forEach(function (key) {
                    if (typeof Forces[key] === "object" && Forces[key].id !== undefined) {
                        cleverapps.forces.clearForce(Forces[key].id);
                    }
                });
            }
        },

        resetWindows: {
            packWindow: function () {
                cleverapps.packManager.whenBuy = 0;
                cleverapps.packManager.whenPopup = 0;
                cleverapps.packManager.save();
            },
            crossPromoWindow: function () {
                cleverapps.crossPromo.nextTime = Date.now();
                cleverapps.crossPromo.createdTime = 0;
                cleverapps.crossPromo.save();
            },
            resetPlacements: function () {
                cleverapps.placements.resetAllPlacements();
            }
        },

        resetDailyLevel: function () {
            cleverapps.dailyLevel.reset();
            cleverapps.refreshScene();
        },

        resetBonusRound: function () {
            cleverapps.bonusRoundChest.unlock();
        },

        throwError: function () {
            throw "Tool Error";
        },

        instantApi: ToolFolder.create(function () {
            if (!cleverapps.platform.oneOf(Instant)) {
                return;
            }

            var checkApi = function (api) {
                var apis = FBInstant.getSupportedAPIs();
                console.log("Searching " + api, apis);
                cleverapps.notification.create(api + " - " + apis.includes(api));
            };

            return {
                purchaseAsync: checkApi.bind(this, "payments.purchaseAsync"),
                purchaseSubscriptionAsync: checkApi.bind(this, "payments.purchaseSubscriptionAsync"),
                getRewardedVideoAsync: checkApi.bind(this, "getRewardedVideoAsync"),
                getInterstitialAdAsync: checkApi.bind(this, "getInterstitialAdAsync")
            };
        }),

        screenshots: function () {
            cleverapps.debugStats.setEnabled(false);

            if (!cc.sys.isNative) {
                var body = window.document.body;
                var containers = body.getElementsByClassName("container");
                for (var i = 0; i < containers.length; i++) {
                    console.log(containers.item(i));
                    body.removeChild(containers.item(i));
                }

                var expand = body.getElementsByClassName("expand");
                for (i = 0; i < expand.length; i++) {
                    console.log(expand.item(i));
                    body.removeChild(expand.item(i));
                }

                var commits = window.document.getElementById("commits");
                if (commits) {
                    commits.parentNode.removeChild(commits);
                }
            }

            cleverapps.refreshScene();
        },

        toggleStats: function () {
            cleverapps.debugStats.toggle();
        },

        diffMaps: ToolFolder.create(function () {
            if (cleverapps.config.name !== "differences") {
                return;
            }

            var openLink = function (ep, lvl) {
                var url = "http://differences.labsystech.ru/cleverapps/private/differences/diffmaps/" + ep + "/" + lvl + ".png";
                var win = window.open(url, "_blank");
                win.focus();
            };

            var res = {};

            for (var ep = 0; ep < cleverapps.episodes.getAmountEpisodes(); ep++) {
                var links = {};
                for (var lvl = 0; lvl < 15; lvl++) {
                    links[lvl] = openLink.bind(undefined, ep, lvl);
                }
                res[ep] = links;
            }

            return res;
        }),

        pushes: ToolFolder.create(function () {
            var res = {};
            Object.keys(cleverapps.LocalPushes.TYPES).forEach(function (type) {
                res[type] = function () {
                    var timeout = cleverapps.parseInterval("13 seconds");
                    if (cc.sys.os === cc.sys.OS_IOS && cc.sys.isNative) {
                        timeout = cleverapps.parseInterval("1 minute");
                    }
                    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES[type], timeout);
                };
            });

            return res;
        }),

        debugSlots: function () {
            cleverapps.Spine.debugSlots = true;
            cleverapps.refreshScene();
        },

        debugBones: function () {
            cleverapps.Spine.debugBones = true;
            cleverapps.refreshScene();
        },

        browserName: function () {
            cleverapps.notification.create(new UAParser().getBrowser().name);
        },

        browserType: function () {
            cleverapps.notification.create(cc.sys.browserType);
        },

        osName: function () {
            if (cleverapps.platform instanceof MobileOK) {
                cleverapps.notification.create(FAPI.Util.getRequestParameters().mob_platform);

                return;
            }

            cleverapps.notification.create(cc.sys.os);
        },

        islandsInfo: function () {
            cleverapps.flags.islandsInfoEnabled = true;
            cleverapps.refreshScene();
        },

        debugFont: function () {
            var createFont = function (size) {
                return {
                    name: "default",
                    fnt: true,
                    size: size,
                    color: cleverapps.styles.COLORS.WHITE,
                    stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
                };
            };

            var testString = "x0123456789/2+3-4";

            var createText = function (size) {
                return cleverapps.UI.generateOnlyText(testString, createFont(size));
            };

            var createImageText = function (size) {
                return cleverapps.UI.generateImageText(testString, createFont(size));
            };

            var sizes = [65, 60, 55, 50, 45, 40, 35, 30, 25, 20];

            var pairs = sizes.map(function (size) {
                var text = createText(size);
                var imageText = createImageText(size);
                return new cleverapps.Layout([imageText, text], {
                    direction: cleverapps.UI.HORIZONTAL,
                    margin: 20
                });
            });

            var layout = new cleverapps.Layout(pairs, {
                direction: cleverapps.UI.VERTICAL,
                margin: 20
            });

            var scene = cleverapps.scenes.getRunningScene().scene;
            scene.addChild(layout);
            layout.setPositionRound(scene.width / 2, scene.height - layout.height / 2 - 100);
        },

        debugButtons: function () {
            var createLayoutForSize = function (size) {
                var items = Object.keys(TextWithIcon.ICONS).filter(function (key) {
                    var icon = TextWithIcon.ICONS[key][0];
                    return bundles[icon.bundle].frames[icon.name];
                }).map(function (key) {
                    return new cleverapps.UI.Button({
                        text: key + "Test text",
                        width: size.width,
                        height: size.height
                    });
                });

                return new cleverapps.Layout(items, {
                    margin: 5,
                    direction: cleverapps.UI.VERTICAL
                });
            };

            var sizes = [
                {
                    width: 140,
                    height: 60
                },
                {
                    width: 170,
                    height: 70
                },
                {
                    width: 210,
                    height: 80
                },
                {
                    width: 250,
                    height: 90
                }
            ];

            var layouts = sizes.map(function (size) {
                return createLayoutForSize(size);
            });

            var layout = new cleverapps.Layout(layouts, {
                margin: 10,
                direction: cleverapps.UI.HORIZONTAL
            });

            var scene = cleverapps.scenes.getRunningScene();

            scene.addChild(layout);
            layout.setLocalZOrder(100);
            layout.setPositionRound(scene.width / 2, scene.height / 2);
        },

        printProductsForCrm: function () {
            var packs = {};
            for (var i = 0; i < RewardsConfig.Packs.length; i++) {
                var packCode = "starterPack0";
                var packName = "Starter pack";
                if (i === 1) {
                    packCode = "starterPack";
                    packName = "Super pack";
                } else if (i === 2) {
                    packCode = "starterPack2";
                    packName = "Mega pack";
                }

                packs[packCode] = {
                    name: packName
                };
                Object.assign(packs[packCode], RewardsConfig.Packs[i]);
            }

            Object.keys(cleverapps.config.products).filter(function (productCode) {
                if (cleverapps.config.type === "merge" && ["gold500", "gold1800", "gold3800", "gold6000", "gold25000"].indexOf(productCode) !== -1) {
                    return true;
                }

                if (!cleverapps.lives && productCode.indexOf("ives") !== -1) {
                    return false;
                }

                return packs[productCode] === undefined && (productCode.indexOf("Pack") !== -1 || cleverapps.config.products[productCode].restoreReward !== undefined);
            }).forEach(function (productCode) {
                var product = cleverapps.config.products[productCode];
                var title = product.title.replace("", "");
                if (!Messages.has(title)) {
                    return;
                }

                packs[productCode] = {
                    name: Messages.get(title)
                };
                Object.assign(packs[productCode], product.restoreReward || product.reward);
            });

            cleverapps.values(packs).forEach(function (pack) {
                if (pack.boosters) {
                    Object.keys(pack.boosters).forEach(function (boosterCode) {
                        pack["booster" + boosterCode] = pack.boosters[boosterCode];
                    });
                    pack.boosters = undefined;
                }

                if (pack.worker) {
                    pack.worker = cleverapps.parseInterval(pack.worker) / 3600000;
                }

                if (pack.unlimitedLives) {
                    pack.unlimitedLives = cleverapps.parseInterval(pack.unlimitedLives) / 3600000;
                }

                if (pack.energy) {
                    pack.lives = pack.energy;
                    pack.energy = undefined;
                }
            });

            console.log(JSON.stringify(packs, undefined, 4));
        },

        toggleBannerPadding: function () {
            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
                return;
            }

            var toggleAction = function (value) {
                Resolution.setContainerPadding({
                    bottom: value
                });
            };

            cleverapps.debugSlider.create({
                action: toggleAction,
                minValue: 0,
                maxValue: 300,
                step: 1,
                baseValue: Resolution.getContainerPadding().bottom,
                caption: "dpr: " + window.devicePixelRatio
            });
        },

        toggleTimeScale: function () {
            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
            } else {
                cleverapps.debugSlider.createTimeScaleSlider();
            }
        },

        toggleZoomScale: function () {
            if (cleverapps.config.type !== "merge") {
                return;
            }

            if (cleverapps.debugSlider.isActive()) {
                cleverapps.debugSlider.remove();
            } else {
                var toggleAction = function (value) {
                    var scene = cleverapps.scenes.getRunningScene();
                    if (!scene || !scene.map || !scene.map.scroll || cleverapps.meta.isFocused()) {
                        return;
                    }
                    if (scene.animateZoom) {
                        scene.animateZoom(0, value);
                    }
                };

                cleverapps.debugSlider.create({
                    action: toggleAction,
                    minValue: 0.3,
                    maxValue: 4,
                    step: 0.1,
                    baseValue: 1,
                    silent: 1
                });
            }
        },

        memo: function () {
            return Shortcuts.GetMemo();
        },

        toggleVideoMonetization: function () {
            if (!RewardedAdsManager.IsEnabled()) {
                return;
            }

            cleverapps.flags.videoAdsMainMonetization = !cleverapps.flags.videoAdsMainMonetization;
            cleverapps.flags.update();
        },

        videoProducts: ToolFolder.create(function () {
            var shopWindow = cleverapps.windows.list[0];
            if (!shopWindow || !(shopWindow instanceof ShopWindow)) {
                return {};
            }

            var tiles = shopWindow.tiles;

            var res = {
                reset: function () {
                    tiles.forEach(function (tile) {
                        cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.PRODUCT_VIDEO_PROGRESS + tile.tileModel.product.itemId, 0);
                        tile.tileModel.updateModelState();
                    });
                }
            };

            tiles.forEach(function (tile) {
                if (tile.tileModel.product.videoProduct) {
                    res[tile.tileModel.product.itemId] = function () {
                        var amount = parseInt(window.prompt("Enter amount", tile.tileModel.product.videoPrice - tile.tileModel.currentVideoProgress() - 1));
                        if (!isNaN(amount)) {
                            var i = 0;
                            while (i < amount) {
                                tile.tileModel.incVideoProduct(function () {});
                                i++;
                            }
                        }
                    };
                }
            });

            return res;
        }),

        payments: ToolFolder.create(function () {
            var res = {};

            var oldLoadedPrices = {};
            for (var id in cleverapps.config.products) {
                oldLoadedPrices[id] = cleverapps.config.products[id].loadedPrice;
            }

            var oldCalcLoadedPrice;
            ["yandex", "ok", "reset"].forEach(function (name) {
                res[name] = function () {
                    if (!oldCalcLoadedPrice) {
                        oldCalcLoadedPrice = cleverapps.payments.calcLoadedPrice;
                    }

                    if (name === "yandex") {
                        cleverapps.payments.calcLoadedPrice = cleverapps.YandexPayments.prototype.calcLoadedPrice;
                    }

                    if (name === "ok") {
                        cleverapps.payments.calcLoadedPrice = cleverapps.OKPayments.prototype.calcLoadedPrice;
                    }

                    for (var i in cleverapps.config.products) {
                        var product = cleverapps.config.products[i];

                        if (name === "reset") {
                            product.loadedPrice = oldLoadedPrices[id];
                        } else {
                            product.loadedPrice = cleverapps.payments.calcLoadedPrice(product.price);
                        }
                    }
                };
            });

            res.resetEmail = function () {
                cleverapps.info.setKeyValue("email", "");
            };

            if (RewardedAdsManager.IsEnabled()) {
                res.toggleAvailable = function () {
                    var isAvailable = cleverapps.payments.isAvailable();
                    cleverapps.flags.videoAdsMainMonetization = false;
                    cleverapps.payments.isAvailable = function () {
                        return !isAvailable;
                    };
                    cleverapps.flags.update();
                };
            }

            if (cleverapps.platform.oneOf(AndroidPlatform)) {
                var PaymentsClasses = {
                    GooglePlay: cleverapps.WebViewPayments,
                    YooKassa: YooKassaPayments
                };

                Object.keys(PaymentsClasses).forEach(function (key) {
                    var PaymentsClass = PaymentsClasses[key];

                    if (cleverapps.payments.constructor === PaymentsClass) {
                        return;
                    }

                    res[key] = function () {
                        cleverapps.PaymentsManager.init = function () {
                            return new PaymentsClass();
                        };
                        cleverapps.PaymentsManager.switch();

                        cleverapps.toolModel.refresh("payments");
                    };
                });
            }

            return res;
        }),

        abTests: ToolFolder.create(function () {
            var res = {};

            Object.keys(cleverapps.ABTest.Tests).forEach(function (id) {
                var test = cleverapps.ABTest.Tests[id];

                if (!cleverapps.user.checkAvailable(test.available)) {
                    return;
                }

                var group = cleverapps.abTest.getGroup(test);
                var name = "";
                for (var prop in cleverapps.ABTest) {
                    if (cleverapps.ABTest[prop] === group) {
                        name = test.id + ":" + prop;
                        break;
                    }
                }
                res[name] = function () {
                    group = cleverapps.abTest.getGroup(test);
                    var groups = test.groups;
                    var index = groups.indexOf(group);
                    var nextGroup = groups[(index + 1) % groups.length];
                    cleverapps.abTest.forceGroup(
                        test,
                        nextGroup
                    );

                    cleverapps.toolModel.refresh();
                };
            }, this);

            return res;
        }),

        adLimits: ToolFolder.create(function () {
            var res = {};

            var map = {};

            for (var type1 in AdsLimits.TYPES) {
                map[AdsLimits.TYPES[type1]] = type1;
            }

            Object.keys(AdsLimits.LIMITS).forEach(function (type) {
                var limit = cleverapps.adsLimits.getLimit(type);

                var name = map[type] + "-" + cleverapps.adsLimits.getLimitLeftCount(type);

                if (cleverapps.user.checkAvailable(limit.available)) {
                    res[name] = function () {
                        if (cleverapps.adsLimits.state(type) === AdsLimits.STATE_READY) {
                            cleverapps.adsLimits.watch(type);

                            cleverapps.toolModel.refresh();
                        }
                    };
                }
            });

            return res;
        }),

        clans: {
            addFakePlayers: function () {
                return ClansTool.addFakePlayers();
            },

            sendMessage: function () {
                return ClansTool.sendMessage();
            },

            sendRequest: function () {
                return ClansTool.sendRequest();
            },

            fillHelp: function () {
                return ClansTool.fillHelp();
            }
        },

        toggleDebugMinimal: function () {
            cleverapps.flags.debugMinimalDialogue = !cleverapps.flags.debugMinimalDialogue;

            cleverapps.notification.create((cleverapps.flags.debugMinimalDialogue ? "Enabled" : "Disabled") + " debug minimal");
        },

        sceneSize: function () {
            var size = cleverapps.UI.getSceneSize();
            cleverapps.notification.create("width: " + size.width + " height: " + size.height);
        },

        adsPlugin: function () {
            var pluginName = "unknown";
            var plugin = cleverapps.rewardedAdsManager.plugin;
            if (plugin instanceof GDComAds) {
                pluginName = "GDComAds";
            } else if (plugin instanceof PlingaAds) {
                pluginName = "PlingaAds";
            } else if (plugin instanceof IOSAds) {
                pluginName = "IOSAds";
            } else if (plugin instanceof AdMobAds) {
                pluginName = "AdMobAds";
            } else if (plugin instanceof OKAds) {
                pluginName = "OKAds";
            } else if (plugin instanceof VKAppAds) {
                pluginName = "VKAppAds";
            } else if (plugin instanceof VKAds) {
                pluginName = "VKAds";
            } else if (plugin instanceof InstantAds) {
                pluginName = "InstantAds";
            } else if (plugin instanceof GoogleWebAds) {
                pluginName = "GoogleWebAds";
            } else if (plugin instanceof YandexAds) {
                pluginName = "YandexAds";
            } else if (plugin instanceof VideoAds) {
                pluginName = "VideoAds";
            }
            cleverapps.notification.create(pluginName);
        },

        source: function () {
            cleverapps.notification.create(PlatformManager.getSource());
        },

        throwTestError: function () {
            cleverapps.throwAsync("test error");
        },

        throwTestErrorOnLoad: function () {
            cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.THROWERROR, true);
            setTimeout(window.location.reload.bind(window.location), 1000);
        }
    },

    SOCIAL: {
        share: ToolFolder.create(function () {
            var windows = {};

            if (cleverapps.config.type === "merge") {
                windows.achievement = function () {
                    var window = new FinishQuestWindow(Game.currentGame.quests.quests[0]);
                    window.needsShare = function () {
                        return true;
                    };
                };

                windows.bonus_world = function () {
                    RewardWindow.createMissionWindow({
                        hard: 100
                    }, Missions[Mission.TYPE_DRAGONIA_EXPEDITION]);
                };

                windows.reward = function () {
                    new RewardWindow({ worker: Workers.ADS_BONUS_WORKER_PERIOD }, { shareId: "reward" });
                };
            }

            if (cleverapps.config.type === "match3") {
                var scene = cleverapps.scenes.getRunningScene();
                windows.victory = function () {
                    new VictoryWindow(scene.game, scene.boatswain);
                };
            }

            windows.tournament = function () {
                if (cleverapps.isKnockoutGame()) {
                    new KnockoutVictoryWindow();
                } else {
                    new RewardWindow({ hard: 100 }, { shareId: "tournament" });
                }
            };

            var res = {};
            Object.keys(windows).forEach(function (shareId) {
                res[shareId] = function () {
                    Share.isAvailable = function () {
                        return true;
                    };

                    cleverapps.meta.display({
                        focus: "ToolShare",
                        action: function (f) {
                            windows[shareId]();
                            cleverapps.meta.onceNoWindowsListener = f;
                        }
                    });
                };
            });

            return res;
        }),

        inviteFriends: function () {
            cleverapps.platform.inviteFriends(function (code, response) {
                cleverapps.notification.create("code: " + code + "\n" + JSON.stringify(response));
                console.log(code);
                console.log(response);
            });
        },

        listFriends: function () {
            cleverapps.social.listFriends(function (code, list) {
                list.forEach(function (friend) {
                    console.log(JSON.stringify(friend));
                });
            });
        },

        aboutMe: function () {
            cleverapps.social.aboutMe(function (code, response) {
                console.log("CODE");
                console.log(code);
                console.log(JSON.stringify(response));
            });
        },

        testSchedule: function () {
            console.log("sendPush: " + cleverapps.LocalPushes.TIMEOUT_THRESHOLD * 1.1);

            cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.LIVES, cleverapps.LocalPushes.TIMEOUT_THRESHOLD * 1.1);
        },

        testCancel: function () {
            console.log("cancelPush");

            cleverapps.localPushes.cancelPush(cleverapps.LocalPushes.TYPES.LIVES);
        },

        createShortcut: function () {
            cleverapps.platform.createShortcut && cleverapps.platform.createShortcut(function (code, data) {
                cleverapps.notification.create(code + JSON.stringify(data));
            });
        }
    },

    gold: ToolFolder.create(function () {
        var data = {
            add1000: function () {
                cleverapps.user.earnHard(cleverapps.EVENTS.EARN.OTHER, 1000);
            },
            reset: function () {
                if (cleverapps.config.type === "merge") {
                    var harvested = Game.currentGame.harvested;
                    for (var code in harvested.items) {
                        var value = harvested.get(code);
                        harvested.take(code, -value);
                    }
                }

                cleverapps.user.gold = 0;
                cleverapps.user.onChangeGold();
            }
        };

        if (cleverapps.config.type === "merge" || cleverapps.config.type === "match3") {
            data.addExp10 = function () {
                cleverapps.exp.setExp(cleverapps.exp.getExp() + 10);
            };

            data.addExp100 = function () {
                cleverapps.exp.setExp(cleverapps.exp.getExp() + 100);
            };

            data.addExp1000 = function () {
                cleverapps.exp.setExp(cleverapps.exp.getExp() + 1000);
            };
        }

        if (cleverapps.config.type === "merge") {
            ["rubies", "coins", "wands"].forEach(function (key) {
                data[key + "1000"] = function () {
                    Game.currentGame.addReward(key, 1000);
                };
            });

            data.resetWands = function () {
                Game.currentGame.setWands(0);
            };

            data.resetLives = function () {
                if (cleverapps.unlimitedLives) {
                    cleverapps.unlimitedLives.reset();
                }
                cleverapps.lives.setAmount(0);
            };
        }

        if (cleverapps.config.soft) {
            data.addSoft1000 = function () {
                cleverapps.user.setSoft(cleverapps.user.soft + 1000);
            };

            data.resetSoft = function () {
                cleverapps.user.setSoft(0);
            };
        }

        return data;
    }),

    boosters: ToolFolder.create(function () {
        var boosters = cleverapps.boosters.listAll();

        var res = {};

        var addAmount = function (id, amount) {
            cleverapps.boosters.add(id, amount);
        };

        var boostersTitle = {};
        boostersTitle[cleverapps.Boosters.TYPE_BRUSH] = "Brush";
        boostersTitle[cleverapps.Boosters.TYPE_DISCOVER] = "Discover";
        boostersTitle[cleverapps.Boosters.TYPE_MAGNET] = "Magnet";
        boostersTitle[cleverapps.Boosters.TYPE_DICE] = "Dice";
        boostersTitle[cleverapps.Boosters.TYPE_JOKERS] = "Two jokers";
        boostersTitle[cleverapps.Boosters.TYPE_HIGHLIGHT] = "Flashlight";
        boostersTitle[cleverapps.Boosters.TYPE_EYE] = "Glasses";
        boostersTitle[cleverapps.Boosters.TYPE_MAGNIFIER] = "Magnifier";

        cleverapps.boosters.listAll().forEach(function (booster) {
            res[(boostersTitle[booster.id] || "Booster title") + " (id:" + booster.id + ")"] = addAmount.bind(null, booster.id, 1);
        });

        res.reset = function () {
            boosters.forEach(function (booster) {
                cleverapps.boosters.setAmount(booster.id, 0);
            });
            cleverapps.boosters.save();
        };

        return res;
    }),

    lives: {
        setFull: function () {
            cleverapps.lives.setAmount(cleverapps.lives.getMaxLives());
        },

        add1: function () {
            cleverapps.lives.setAmount(cleverapps.lives.amount + 1);
        },

        addUnlimitedLives: function () {
            var callback = function (time) {
                cleverapps.unlimitedLives.buy(time);
                cleverapps.lives.onBuyUnlimitedLivesListener();
            };

            displayToolPrompt(callback, "Time", "5 seconds");
        },

        extendUnlimitedLivesWindow: displayToolWindow(function () {
            new ExtendUnlimitedLivesWindow();
        }),

        reset: function () {
            if (cleverapps.unlimitedLives) {
                cleverapps.unlimitedLives.reset();
            }
            cleverapps.lives.setAmount(0);
        },

        rewarded_ads_available: function () {
            cleverapps.rewardedAdsManager.isAvailable = function () {
                return true;
            };
        }
    },

    start_pack: function () {
        cleverapps.packManager.whenStart = Date.now();
        cleverapps.packManager.whenPopup = -1;
        cleverapps.packManager.used = 0;
        cleverapps.packManager.save();
    },

    Shortcuts: ToolFolder.create(Shortcuts.GetMenu),

    piggyBank: ToolFolder.create(function () {
        if (!cleverapps.piggyBank) {
            return;
        }
        var resetWindow = function () {
            if (cleverapps.windows.list.some(function (window) {
                return window instanceof PiggyBankWindow;
            })) {
                cleverapps.windows.closeBottomWindows();
                cleverapps.windows.currentWindow().close();
                new PiggyBankWindow();
            }
        };

        var res = {
            on: function () {
                PiggyBank.AVAILABLE = {};
                cleverapps.piggyBank.when = Date.now() - cleverapps.parseInterval(PiggyBank.INTERMISSION);
                cleverapps.piggyBank.isActive();
                cleverapps.sideBar.resetByClassName(PiggyBankIcon);
            },

            off: function () {
                cleverapps.piggyBank.when -= cleverapps.parseInterval(PiggyBank.LIFETIME);
                resetWindow();
                cleverapps.sideBar.resetByClassName(PiggyBankIcon);
            },

            add100: function () {
                cleverapps.piggyBank.add(100);
                resetWindow();
            }
        };

        PiggyBank.PRODUCTS.forEach(function (product, index) {
            res[product] = function () {
                cleverapps.piggyBank.level = index;
                cleverapps.piggyBank.active = true;
                cleverapps.piggyBank.amount = cleverapps.piggyBank.getBaseAmount();
                cleverapps.piggyBank.save();
                resetWindow();
            };
        });

        return res;
    }),

    dailyTasks: ToolFolder.create(function () {
        return !cleverapps.dailyTasks ? undefined : {
            regenerate: function () {
                this.dailyTasksIteration = this.dailyTasksIteration || 0;
                this.dailyTasksIteration++;

                cleverapps.dailyTasks.startNewTasks();
                cleverapps.dailyTasks.onChangeStateListener();

                if (cleverapps.windows.list.length > 0
                && cleverapps.windows.list[cleverapps.windows.list.length - 1] instanceof DailyTasksWindow) {
                    var window = cleverapps.windows.list[cleverapps.windows.list.length - 1];
                    window.close();
                }

                cleverapps.meta.display({
                    focus: "DailyTasksWindow",
                    action: function (f) {
                        new DailyTasksWindow();
                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            },

            choose: ToolFolder.create(function () {
                var menu = {};

                for (var type in DailyTasksConfig) {
                    var config = cleverapps.clone(DailyTasksConfig[type], true);

                    if (config.filter && !config.filter()) {
                        continue;
                    }

                    var name = Object.keys(cleverapps.DailyTasks).find(function (name) {
                        return String(cleverapps.DailyTasks[name]) === String(type);
                    });

                    menu[name] = function (type, config) {
                        config.data = config.dynamic();

                        var possibleSlots = Object.keys(config.data);
                        for (var i = 0; i < possibleSlots.length; ++i) {
                            var slot = possibleSlots[i];
                            if (slot < cleverapps.DailyTasks.ACTIVE_TASKS) {
                                cleverapps.dailyTasks.tasks[slot] = new cleverapps.DailyTask(+type, config.data[slot], config.data);
                                cleverapps.dailyTasks.save();
                                break;
                            }
                        }
                    }.bind(this, type, config);
                }

                return menu;
            }),

            finish: function () {
                var tasks = cleverapps.dailyTasks.getActiveTasks();
                if (tasks.length > 0) {
                    var task = tasks[0];
                    cleverapps.eventBus.trigger("taskEvent", task.type, task.goal, task.data);
                }
            },

            add_1: function () {
                var tasks = cleverapps.dailyTasks.getActiveTasks();
                if (tasks.length > 0) {
                    var task = tasks[0];
                    cleverapps.eventBus.trigger("taskEvent", task.type, 1, task.data);
                }
            }
        };
    }),

    growthFund: {
        setRewardsLvl: function (lvl) {
            if (!cleverapps.growthFund) {
                return;
            }

            var callback = function (lvl) {
                if (isNaN(lvl)) {
                    return;
                }
                lvl = Math.max(0, Math.min(lvl, cleverapps.user.level));

                var received = cleverapps.growthFund.isBought() ? 0 : -1;
                cleverapps.growthFund.levels.forEach(function (level) {
                    if (level.levelNo <= lvl) {
                        received = level.levelNo;
                    }
                });

                cleverapps.growthFund.received = received;
                cleverapps.growthFund.calcLevelsState();
                cleverapps.growthFund.trigger("updateState");
                cleverapps.growthFund.save();

                var win = cleverapps.windows.currentWindow();
                if (win && win instanceof GrowthFundWindow) {
                    win.close();
                }
            };

            if (lvl === undefined) {
                displayToolPrompt(callback, "Level", 1);
            } else {
                callback(lvl);
            }
        },

        reset: function () {
            if (!cleverapps.growthFund) {
                return;
            }
            cleverapps.growthFund.received = -1;
            cleverapps.growthFund.calcLevelsState();
            cleverapps.growthFund.trigger("updateState");
            cleverapps.growthFund.save();
        }
    },

    subscriptions: ToolFolder.create(function () {
        var res = {};
        if (!Subscription.IsAvailable()) {
            return res;
        }

        var getServerInfo = function (callback) {
            return function () {
                var params = {
                    source: Subscription.calcSource(),
                    tokens: cleverapps.subscription.tokens
                };
                cleverapps.RestClient.get("/subscriptions/getinfo/" + levels.user.id, params, function (subscription) {
                    subscription = subscription || { data: cleverapps.subscription.getInfo() };
                    console.log("subscription - " + JSON.stringify(subscription));
                    callback(subscription);
                });
            };
        };

        res.serverInfo = getServerInfo(function (subscription) {
            displayToolWindow(function () {
                new ToolSubscriptionWindow(subscription);
            })();
        });

        if (cleverapps.isLocalhost() || cleverapps.platform.oneOf(CleverApps) && cleverapps.config.debugMode) {
            res.cancel = function () {
                if (cleverapps.subscription.isActive()) {
                    cleverapps.subscription.active = false;
                    cleverapps.subscription.save();
                    cleverapps.subscription.trigger("update");
                }
            };
        } else if (cleverapps.platform.oneOf(FacebookCanvas, TestPlatform)) {
            res.changeToWeek = getServerInfo(function (subscription) {
                if (!subscription.id) {
                    return;
                }

                plugin.FacebookAgent.getInstance().canvas.pay({
                    action: "change_subscription",
                    subscription_id: subscription.id,
                    product: Product.Create("subsWeek").id.facebook
                }, function () {
                    console.log("changeToWeek", arguments);
                });
            });
            res.changeToMonth = getServerInfo(function (subscription) {
                if (!subscription.id) {
                    return;
                }

                plugin.FacebookAgent.getInstance().canvas.pay({
                    action: "change_subscription",
                    subscription_id: subscription.id,
                    product: Product.Create("subsMonth").id.facebook
                }, function () {
                    console.log("changeToMonth", arguments);
                });
            });
            res.reactivate = getServerInfo(function (subscription) {
                if (!subscription.id) {
                    return;
                }

                plugin.FacebookAgent.getInstance().canvas.pay({
                    action: "reactivate_subscription",
                    subscription_id: subscription.id
                }, function () {
                    console.log("reactivate", arguments);
                });
            });

            res.cancel = getServerInfo(function (subscription) {
                if (!subscription.id) {
                    return;
                }

                plugin.FacebookAgent.getInstance().canvas.pay({
                    action: "cancel_subscription",
                    subscription_id: subscription.id
                }, function () {
                    console.log("cancel", arguments);
                });
            });
        }
        res.setRewardTime = function () {
            var interval = window.prompt("Enter time", "5 seconds");
            if (!interval) {
                return;
            }
            var time = cleverapps.parseInterval(interval);
            cleverapps.subscription.rewardTime = Date.now() - cleverapps.parseInterval(Subscription.REWARD_INTERVAL) + time;
            cleverapps.subscription.save();
        };
        return res;
    }),

    missions: ToolFolder.create(function () {
        return MissionsTool.getToolItems(this);
    }),

    paymentsHistory: {
        add500: function () {
            cleverapps.paymentsHistory.addPayment(Product.Create("gold500"));
        },
        add1800: function () {
            cleverapps.paymentsHistory.addPayment(Product.Create("gold1800"));
        },
        add6000: function () {
            cleverapps.paymentsHistory.addPayment(Product.Create("gold6000"));
        },
        add25000: function () {
            cleverapps.paymentsHistory.addPayment(Product.Create("gold25000"));
        },
        resetPaymentsHistory: function () {
            cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.PAYMENTS_HISTORY);
            cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.PACK);
            cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.PIGGY_BANK);
            cleverapps.paymentsHistory.ids = [];
            cleverapps.paymentsHistory.time = [];
            cleverapps.paymentsHistory.price = [];
            cleverapps.subscription.reset();
        },
        checkClassify: function () {
            var postfix = cleverapps.paymentsHistory.isVIP() ? " (VIP)" : " (no VIP)";
            var groupNames = Object.keys(cleverapps.PaymentsHistory);
            var group = cleverapps.paymentsHistory.classify();
            var name;
            for (var i = 0; i < groupNames.length; i++) {
                if (cleverapps.PaymentsHistory[groupNames[i]] === group) {
                    name = groupNames[i];
                    break;
                }
            }
            console.log(cleverapps.paymentsHistory.getInfo());
            cleverapps.notification.create("Payer classification = " + name + postfix);
        },
        checkVIPStatus: function () {
            var postfix = cleverapps.paymentsHistory.isVIP() ? " (VIP)" : " (no VIP)";

            var groupNames = Object.keys(cleverapps.PaymentsHistory).filter(function (key) {
                return key.startsWith("BRACKET_");
            });

            var status = cleverapps.paymentsHistory.classify();
            var name;
            for (var i = 0; i < groupNames.length; i++) {
                if (cleverapps.PaymentsHistory[groupNames[i]] === status) {
                    name = groupNames[i];
                    break;
                }
            }

            cleverapps.notification.create("Payer vip status = " + name + postfix);
        },

        vipRewardedWindow: displayToolWindow(function () {
            new VIPRewardedWindow(function () {}, function () {});
        })
    },

    audio: {
        currentSceneDelay: function () {
            var scene = cleverapps.scenes.getRunningScene();
            var styles = scene.getAudioStyles();
            if (styles) {
                console.log("### Current scene delay: " + styles.delay);
            } else {
                console.log("### No audio styles for scene");
            }
        },

        setSceneDelay: function () {
            var delay = parseFloat(window.prompt("Delay", 1));
            if (isNaN(delay)) {
                console.log("### Wrong value");
                return;
            }

            var scene = cleverapps.scenes.getRunningScene();
            var styles = scene.getAudioStyles();
            if (!styles) {
                console.log("#### No audio styles for current scene");
                return;
            }

            if (scene instanceof GameSceneBase) {
                cleverapps.styles.GameScene.sound.delay = delay;
                console.log("#### GameSceneBase set to " + delay);
            } else if (scene instanceof cleverapps.FixedWidthScene) {
                cleverapps.styles.FixedWidthScene.sound.delay = delay;
                console.log("#### FixedWidthScene set to " + delay);
            }
        }
    },

    events: ToolFolder.create(function () {
        var res = {};
        var constructEventName = function (eventData, isActive) {
            var event = new cleverapps.Event(eventData);
            var name = event.features.join(", ");
            var networks = eventData.networks || (eventData.trueData && eventData.trueData.networks);
            if (networks) {
                var networksPrefix = "[" + networks[0];
                networksPrefix += networks[1] ? ", " + networks[1] : "";
                networksPrefix += networks[2] ? "..." : "";
                networksPrefix += "] ";
                name = networksPrefix + name;
            }
            name = isActive ? ToolMenuItem.ACTIVE_ITEM + name + " (" + new Date(eventData.finish).toLocaleDateString("ru", {
                day: "numeric", month: "numeric", hour: "numeric", minute: "numeric"
            }) + ")" : name;
            return name;
        };

        var noDublicateEvents = [];
        cleverapps.eventManager.events.forEach(function (eventData) {
            var event = new cleverapps.Event(eventData);

            noDublicateEvents = noDublicateEvents.filter(function (filteredEventData) {
                var fileteredEvent = new cleverapps.Event(filteredEventData);
                return JSON.stringify(event.features.slice(0).sort()) !== JSON.stringify(fileteredEvent.features.slice(0).sort());
            });
            noDublicateEvents.push(eventData);
        });

        noDublicateEvents.forEach(function (eventData) {
            var event = new cleverapps.Event(eventData);
            var isApplicable = cleverapps.eventManager.isEventApplicable(event);

            res[constructEventName(eventData, isApplicable)] = function () {
                eventData.toolApplicable = !isApplicable;

                if (eventData.toolApplicable) {
                    eventData.trueData = {
                        start: eventData.start,
                        finish: eventData.finish,
                        networks: eventData.networks
                    };
                    var date = new Date();
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    date.setSeconds(date.getSeconds() - 1);
                    eventData.start = date.toISOString();
                    date.setSeconds(date.getSeconds() + 60 * 60);
                    eventData.finish = date.toISOString();
                    delete eventData.networks;
                } else if (eventData.trueData) {
                    eventData.start = eventData.trueData.start;
                    eventData.finish = eventData.trueData.finish;
                    eventData.networks = eventData.trueData.networks;
                    delete eventData.trueData;
                }

                cleverapps.refreshScene();
            };
        });
        return res;
    }),

    friendRequests: {
        create: function () {
            levels.friendRequests.listAvailableTypes().forEach(function (type) {
                var from = levels.FriendRequest.SYSTEM_ID;
                var friend = cleverapps.friends.listFriends()[0];
                if (friend && friend.id) {
                    from = friend.id;
                }

                var request = {
                    id: "__" + Math.floor(Math.random() * 1000000000000000),
                    from: from,
                    type: type
                };

                levels.friendRequests.notProcessed.push(levels.FriendRequest.createFriendRequest(request));
                levels.friendRequests.save();
                levels.friendRequests.onNewListener();
            });
        },
        clear: function () {
            levels.friendRequests.notProcessed = [];
            levels.friendRequests.save();
        }
    },

    cups: {
        daily: {
            addStars: function (cup) {
                cup = cup || cleverapps.dailyCup;

                var amount = parseInt(window.prompt("Enter stars", "10"));
                var reward = new Reward("cup", {
                    type: cup.type,
                    amount: amount
                });
                reward.receiveReward();
            },

            addPlayers: function (cup) {
                cup = cup || cleverapps.dailyCup;
                cleverapps.RestClient.get("/dailycup/addDebugPlayers/" + cup.type + "/" + cleverapps.user.id, {}, function () {
                    setTimeout(function () {
                        cup.reload();
                    }, 500);
                });
            }
        },

        weekly: {
            addStars: function () {
                cleverapps.Tool.cups.daily.addStars(cleverapps.weeklyCup);
            },

            addPlayers: function () {
                cleverapps.Tool.cups.daily.addPlayers(cleverapps.weeklyCup);
            }
        },

        clan: {
            addStars: function () {
                cleverapps.Tool.cups.daily.addStars(cleverapps.clanCup);
            },

            addPlayers: function () {
                cleverapps.Tool.cups.daily.addPlayers(cleverapps.clanCup);
            }
        }
    },

    bonusWorld: {
        gotoLevel: function () {
            var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD);
            if (mission) {
                var levelNo = parseInt(window.prompt("level number", 15)) - 1;
                if (!isNaN(levelNo)) {
                    var receivedRewards = mission.details.receivedRewards || {};

                    for (var i = 0; i < RewardsConfig.BonusWorld.length; i++) {
                        if (RewardsConfig.BonusWorld[i].level >= levelNo) {
                            delete receivedRewards[RewardsConfig.BonusWorld[i].level];
                        }
                    }

                    var details = mission.details;
                    details.receivedRewards = receivedRewards;

                    mission.update(levelNo - mission.result, details);

                    cleverapps.refreshScene();
                }
            }
        }
    },

    slotMachine: ToolFolder.create(function () {
        return {
            setPayline: function () {
                var codes = {
                    c: "coin",
                    cs: "coins",
                    b1: "booster_1",
                    b2: "booster_2",
                    b3: "booster_3"
                };

                var msg = "Enter 3 elements, separated by comma";
                for (var code in codes) {
                    msg = msg + "\n " + code + ": " + codes[code];
                }

                SlotMachine.PREDEFINED_PAYLINE = window.prompt(msg, "c,c,c")
                    .replace(/ /g, "")
                    .split(",")
                    .map(function (code2) {
                        return codes[code2];
                    });
            },

            resetPayline: function () {
                delete SlotMachine.PREDEFINED_PAYLINE;
            },

            addTicket: function () {
                var reward = new Reward("slotMachineTicket", 1);
                reward.receiveReward();
                reward.onAnimationFinished();
            },

            resetTickets: function () {
                var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_SLOT_MACHINE);

                var reward = new Reward("slotMachineTicket", -mission.logic.getTickets());
                reward.receiveReward();
                reward.onAnimationFinished();
            },

            resetForces: function () {
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE.id);
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE_ICON.id);
                cleverapps.forces.clearForce(Forces.SLOT_MACHINE_HELP.id);
            }
        };
    }),

    flyingAd: ToolFolder.create(function () {
        var res = {};
        Object.keys(FlyingAd.TYPES).forEach(function (type) {
            if (cleverapps.config.name !== "differences" && FlyingAd.TYPES[type] === FlyingAd.TYPES.TYPE_DISCOVER) {
                return;
            }

            res[type] = function () {
                cleverapps.flyingAd.setDelay(0);
                FlyingAd.DEBUG_TYPE = FlyingAd.TYPES[type];
                cleverapps.flyingAd.update();
            };
        });

        res.reset = function () {
            delete FlyingAd.DEBUG_TYPE;
            cleverapps.flyingAd.update();
        };

        return res;
    }),

    personWindows: {
        ConfirmSpeedUpWindow: displayToolWindow(function () {
            new ConfirmSpeedUpWindow({
                timeLeft: cleverapps.parseInterval("1 minute"),
                totalDuration: cleverapps.parseInterval("1 minute"),
                eventName: cleverapps.EVENTS.SPENT.ORDER,
                callback: function () {}
            });
        }),

        SpeedUpWindow: displayToolWindow(function () {
            new SpeedUpWindow({
                getTimeLeft: function () {
                    return cleverapps.parseInterval("1 minute");
                },
                getFullJobTime: function () {
                    return cleverapps.parseInterval("1 minute");
                },
                isSpeedUpBonusActive: function () {
                    return false;
                }
            });
        }),

        LevelUpWindow: displayToolWindow(function () {
            new LevelUpWindow();
        }),

        StartQuestWindow: displayToolWindow(function () {
            new StartQuestWindow(Game.currentGame.quests.quests.slice(0, 2));
        }),

        FinishQuestWindow: displayToolWindow(function () {
            new FinishQuestWindow(Game.currentGame.quests.quests[0]);
        })
    },

    game: {
        modes: ToolFolder.create(function () {
            var res = {
            };

            cleverapps.gameModes.listModes().forEach(function (flagName) {
                res[(cleverapps.gameModes[flagName] ? ToolMenuItem.ACTIVE_ITEM : "") + flagName] = function () {
                    cleverapps.gameModes.toggle(flagName);
                    cleverapps.toolModel.refresh();
                };
            });

            return res;
        })
    }
};