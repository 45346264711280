/**
 * Created by vladislav on 9/6/2022
 */

var MSStart = function () {
    Platform.call(this, "msstart");
};

MSStart.prototype = Object.create(Platform.prototype);
MSStart.prototype.constructor = MSStart;

MSStart.prototype.getLocalStoragePreffix = function () {
    return "_msstart";
};

MSStart.prototype.getCurrentTournamentId = function (callback) {
    callback(1);
};

MSStart.prototype.reportScore = function (score, callback) {
    if (!cleverapps.social.isLoggedIn()) {
        callback();
        return;
    }

    $msstart.submitGameResultsAsync(score).then(function (result) {
        callback(result);
    });
};

MSStart.prototype.pushSender = function () {
    // return cleverapps.LocalPushes.MSStartSender;
};

MSStart.prototype.isFullscreenAvailable = function () {
    return false;
};
