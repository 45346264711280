/**
 * Created by Andrey Popov on 07.07.2021.
 */
var PlayableAdsScene = cleverapps.FixedWidthScene.extend({
    ctor: function () {
        this.isWide = true;

        this._super();

        cleverapps.flags.noTransitionAnimation = true;
        cleverapps.config.wysiwygMode = true;
        cleverapps.config.nologin = true;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_WYSIWYG);

        cleverapps.audio.mute();

        if (cleverapps.config.name === "mergecraft") {
            Unit.MINIMAL_SELL_LEVEL = 1000;
        }

        if (!cleverapps.wysiwyg) {
            cleverapps.wysiwyg = new Wysiwyg("wysiwyg/playable");
        } else {
            cleverapps.wysiwyg.directory = "wysiwyg/playable";
            cleverapps.wysiwyg.playable = true;
        }

        cleverapps.UI.generateImageText = cleverapps.UI.generateOnlyText;
        if (typeof match3 !== "undefined") {
            cleverapps.styles.FONTS.GOAL_TEXT.stroke = cleverapps.styles.DECORATORS.BLACK_TEXT_STROKE;
        }

        var update = function (object) {
            Object.keys(object).forEach(function (propName) {
                if (propName === "position") {
                    if (object.position.x.dx) {
                        object.position.x.dx = Math.round(object.position.x.dx * resolutionScale);
                    }
                    if (object.position.y.dy) {
                        object.position.y.dy = Math.round(object.position.y.dy * resolutionScale);
                    }
                } else if (["height", "width", "contentWidth", "contentHeight", "scrollX", "scrollY"].indexOf(propName) !== -1) {
                    object[propName] = Math.round(object[propName] * resolutionScale);
                }
            });
        };

        var scenario = cc.loader.getRes(bundles.ads_playable.jsons.scenario_json);
        scenario.forEach(function (item) {
            update(item.properties);
        });

        cleverapps.wysiwyg.hierarchy.loadInt([scenario], scenario[0].id);

        var clipConfig = cleverapps.wysiwyg.hierarchy.getSelectedClip();

        cleverapps.wysiwyg.prepareScenes(clipConfig, function (scenes) {
            cleverapps.wysiwyg.runClipVariations({
                path: "",
                playableAds: true,
                preview: true,
                scenes: scenes
            });
        });
    },

    _closeAction: function () {
        // do nothing
    },

    addMenuContainer: function () {
    },

    listBundles: function () {
        var bundles = ["wysiwyg", "game"];
        if (cleverapps.config.source === "playable") {
            bundles.push("ads_playable", "main");
        }
        return bundles;
    }
});

PlayableAdsScene.overrideMethodsToCaptureBundles = function (urls) {
    var spriteFrames = [];
    var spineJsons = [];
    var sounds = [];

    var formatBundleName = function (name) {
        name = name || "";

        if (name[0] === "@") {
            return name.split("#")[2];
        } 
        var nameToReturn = undefined;
        Object.keys(bundles).forEach(function (bundleName) {
            Object.keys(bundles[bundleName].frames).forEach(function (frame) {
                if (name === bundles[bundleName].frames[frame]) {
                    nameToReturn = frame;
                }
            });

            Object.keys(bundles[bundleName].jsons).forEach(function (json) {
                if (name === bundles[bundleName].jsons[json]) {
                    nameToReturn = json;
                }
            });

            Object.keys(bundles[bundleName].urls).forEach(function (url) {
                if (name === bundles[bundleName].urls[url]) {
                    nameToReturn = url;
                }
            });
        });

        if (nameToReturn) {
            return nameToReturn;
        }

        return encodeURIComponent(name);
    };

    cc.Sprite.prototype.ctor = cleverapps.extendFunc(cc.Sprite.prototype.ctor, function (frame) {
        if (typeof frame !== "object" && frame && spriteFrames.indexOf(frame) === -1) {
            spriteFrames.push(frame);
            var processed = processVirtualImage(frame);
            var data = unpackedMap[processed[0] === "#" ? processed : "#" + processed];
            if (data) {
                urls[formatBundleName(frame)] = data;
            } else {
                var imageUrl = frame;
                if (imageUrl.indexOf("ads_backgrounds_") !== -1) {
                    imageUrl = "cleverapps/res/bundles/ads/background/" + frame.split("ads_backgrounds_")[1].split("/")[0] + frame.substring(frame.length - 4);
                }
                urls[formatBundleName(frame)] = imageUrl;
            }
        }

        this._super.apply(this, arguments);
    });

    cc.Scale9Sprite.prototype.ctor = cleverapps.extendFunc(cc.Scale9Sprite.prototype.ctor, function (frame) {
        if (frame && spriteFrames.indexOf(frame) === -1) {
            spriteFrames.push(frame);
            var processed = processVirtualImage(frame);
            urls[formatBundleName(frame)] = unpackedMap[processed[0] === "#" ? processed : "#" + processed];
        }

        this._super.apply(this, arguments);
    });

    cleverapps.Spine.prototype.ctor = cleverapps.extendFunc(cleverapps.Spine.prototype.ctor, function (json) {
        if (json && spineJsons.indexOf(json) === -1) {
            spineJsons.push(json);
            var processed = processVirtualJson(json);
            urls[formatBundleName(json)] = unpackedMap[processed];
        }
        this._super.apply(this, arguments);
    });

    cleverapps.Audio.prototype.playSound = cleverapps.extendFunc(cleverapps.Audio.prototype.playSound, function (sound) {
        if (sound && sounds.indexOf(sound) === -1) {
            sounds.push(sound);
            var processed = processVirtualUrl(sound).replace(cleverapps.config.staticUrl, "");
            urls[formatBundleName(sound)] = unpackedMap[processed];
        }
        this._super.apply(this, arguments);
    });

    cleverapps.Audio.prototype.insertDisk = cleverapps.extendFunc(cleverapps.Audio.prototype.insertDisk, function (sound) {
        if (sound && sounds.indexOf(sound) === -1) {
            sounds.push(sound);
            var processed = processVirtualUrl(sound).replace(cleverapps.config.staticUrl, "");
            urls[formatBundleName(sound)] = unpackedMap[processed];
        }
        this._super.apply(this, arguments);
    });

    if (typeof match3 !== "undefined") {
        ["heroes", "game"].forEach(function (bundle) {
            Object.keys(bundles[bundle].jsons).filter(function (spine) {
                return ["plist", "atlas"].indexOf(spine) === -1 && bundles[bundle].jsons[spine];
            }).forEach(function (spine) {
                new cleverapps.Spine(bundles[bundle].jsons[spine]);
            });
        });

        Object.keys(bundles.coef.frames).forEach(function (frame) {
            new cc.Sprite(bundles.coef.frames[frame]);
        });
    }
};
