/**
 * Created by slava on 18/8/17
 */

cleverapps.WideMode = function () {
    this.refresh();
};

cleverapps.WideMode.prototype.resizeFont = function (font) {
    var coef = 1;
    if (this.mode === cleverapps.WideMode.VERTICAL) {
        coef = 25 / 40;
    } else if (this.mode === cleverapps.WideMode.SQUARE) {
        coef = 34 / 40;
    }

    return {
        name: font.name,
        size: coef * font.size,
        color: font.color,
        stroke: font.stroke
    };
};

cleverapps.WideMode.prototype.refresh = function () {
    this.mode = cleverapps.WideMode.VERTICAL;

    var winSize = cleverapps.UI.getWinSize();
    if (winSize.width > cleverapps.styles.SQUARE_MODE_WIDTH) {
        this.mode = cleverapps.WideMode.SQUARE;
    }

    if (winSize.width > cleverapps.styles.HORIZONTAL_MODE_WIDTH) {
        this.mode = cleverapps.WideMode.HORIZONTAL;
    }
};

cleverapps.WideMode.VERTICAL = 0;
cleverapps.WideMode.SQUARE = 1;
cleverapps.WideMode.HORIZONTAL = 2;