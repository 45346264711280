/**
 * Created by Andrey Popov on 20.01.2021.
 */

var ClipScene = cc.Node.extend({
    ctor: function (clipSceneConfig, options) {
        this._super();

        this.options = options;

        this.clipSceneConfig = this.movingNode = clipSceneConfig;
        this.setAnchorPoint2();
        this.setContentSize(options.size);

        this.content = this;

        this.eventBus = new cleverapps.EventEmitter();

        this.componentViews = {};

        var overrides = this.clipSceneConfig.components.filter(function (component) {
            return AdsAdHoc === component.ctor();
        });
        var snapshotComponent = this.clipSceneConfig.components.filter(function (component) {
            return component.properties.isGame;
        })[0];
        var otherComponents = this.clipSceneConfig.components.filter(function (component) {
            return !component.properties.isGame && overrides.indexOf(component) === -1;
        });

        var afterInit = function () {
            this.addBackground();
            this.createComponents(otherComponents, this.content);

            if (!this.options.preview) {
                this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_STARTED, this.options.size);
            } else {
                this.eventBus.trigger(Wysiwyg.EVENTS.PREVIEW, this.options.size);
            }

            if (this.options.onInit) {
                this.options.onInit();
            }
        }.bind(this);

        this.createComponents(overrides, this.content);
        if (snapshotComponent) {
            this.createComponents([snapshotComponent], this.content);
            this.snapshotComponent.init(afterInit);
        } else {
            afterInit();
        }

        if (options.preview) {
            var border = cleverapps.UI.createScale9Sprite(bundles.editor_controls.frames.component_frame);
            border.setAnchorPoint2();
            border.setContentSize(this.getContentSize());
            border.setPositionRound(this.width / 2, this.height / 2);
            this.content.addChild(border);
        }

        if (options.onComplete) {
            this.eventBus.on(Wysiwyg.EVENTS.SCENE_COMPLETED, options.onComplete, this);
        }

        if (!this.snapshotComponent && clipSceneConfig.properties.duration) {
            setTimeout(options.onComplete, clipSceneConfig.properties.duration);
        }
    },

    createComponents: function (components, parent) {
        components.forEach(function (component) {
            if (component.properties.showOnEvent && component.properties.showOnEvent !== Wysiwyg.NOT_SELECTED && !this.options.preview) {
                this.eventBus.on(component.properties.showOnEvent, function () {
                    setTimeout(this.addNestedComponent.bind(this, component, parent), component.properties.delay);
                }.bind(this));
            } else if (component.properties.delay && !this.options.preview) {
                setTimeout(this.addNestedComponent.bind(this, component, parent), component.properties.delay);
            } else {
                this.addNestedComponent(component, parent);
            }
        }.bind(this));
    },

    addNestedComponent: function (component, parent) {
        component.properties.isPreview = this.options.preview;
        var componentView = new SceneComponentView(component, this.eventBus, parent, this.getContentSize());
        if (component.properties.isGame) {
            this.snapshotComponent = componentView.componentInstance;
        }

        this.componentViews[component.id] = componentView;
        this.createComponents(component.components, componentView);
        parent.addChild(componentView);

        component.scripts.forEach(function (script) {
            if (component.properties.isPreview && !script.activeOnPreview) {
                return;
            }
            ClipScene.executeComponentScript(script, componentView.componentInstance, this.eventBus);
        }.bind(this));

        if (parent.componentInstance) {
            parent.componentInstance.nestedComponents.push(componentView.componentInstance);
        }

        return componentView;
    },

    addBackground: function () {
        var bg = Wysiwyg.getValueForResolution(this.clipSceneConfig.properties.bg);
        if (bg && bg.bundle && bundles[bg.bundle] && (bundles[bg.bundle].urls[bg.image] || bundles[bg.bundle].frames[bg.image])) {
            var bgSprite = cleverapps.UI.createSprite(bundles[bg.bundle].urls[bg.image] || bundles[bg.bundle].frames[bg.image]);
            var adjustBgSprite = function () {
                bgSprite.setScale(Math.max(this.options.size.width / bgSprite.width, this.options.size.height / bgSprite.height, 1));
                var w = this.options.size.width / bgSprite.scale;
                var h = this.options.size.height / bgSprite.scale;
                bgSprite.setTextureRect(cc.rect(bgSprite.width / 2 - w / 2, bgSprite.height / 2 - h / 2, w, h));
            }.bind(this);

            if (bgSprite.width === 0 || bgSprite.height === 0) {
                bgSprite.addLoadedEventListener(function () {
                    adjustBgSprite();
                });
            } else {
                adjustBgSprite();
            }

            bgSprite.setAnchorPoint2();
            if (this.clipSceneConfig.properties.bgPosition) {
                bgSprite.setPositionRound(Wysiwyg.getValueForResolution(this.clipSceneConfig.properties.bgPosition));
            } else {
                bgSprite.setPositionRound(this.width / 2, this.height / 2);
            }
            bgSprite.setTag("clipBg");
            this.content.addChild(bgSprite, -1);
        }
    },

    redrawComponent: function (item) {
        if (!this.componentViews[item.id]) {
            return;
        }

        var parent = this.componentViews[item.id].getParent();
        this.componentViews[item.id].removeFromParent();

        var searchComponent = function (componentToSearch, componentId) {
            var searchResult = componentToSearch.components.filter(function (component) {
                return component.id === componentId;
            });

            if (searchResult.length > 0) {
                return searchResult[0];
            }
            for (var i = 0; i < componentToSearch.components.length; i++) {
                searchResult = searchComponent(componentToSearch.components[i], componentId);
                if (searchResult) {
                    return searchResult;
                }
            }

            return undefined;
        };

        var component = searchComponent(this.clipSceneConfig, item.id);
        var componentView = this.addNestedComponent(component, parent);

        if (component.properties.isGame) {
            this.snapshotComponent.init(function () {});
        }
        componentView.updateItemTools(true);
    },

    selectComponent: function (item) {
        Object.keys(this.componentViews).forEach(function (key) {
            var componentId = parseInt(key);
            if (this.componentViews[componentId]) {
                this.componentViews[componentId].updateItemTools(componentId === item.id);
            }
        }.bind(this));
    },

    destroy: function () {
        if (cleverapps.meta.isFocused()) {
            cleverapps.meta.freeFocus(cleverapps.meta.focus);
        }

        if (this.snapshotComponent) {
            this.snapshotComponent.cleanup();
            this.snapshotComponent = undefined;
        }

        Object.keys(this.componentViews).forEach(function (key) {
            this.componentViews[key].removeFromParent();
        }.bind(this));
        this.componentViews = {};

        this.eventBus.purge();
    }
});

ClipScene.executeComponentScript = function (script, component, eventBus) {
    var parameters = {};
    script.scriptParameters.split("\n").forEach(function (parameter) {
        var parameterName = parameter.split(":")[0];
        parameters[parameterName] = script[parameterName];
    });
    // eslint-disable-next-line no-new-func
    var scriptFunction = Function("component", "parameters", "eventBus", script.scriptBody);
    scriptFunction(component, parameters, eventBus);
};

cleverapps.styles.ClipScene = {
    scroll: {
        padding: 5
    }
};