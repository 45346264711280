/**
 * Created by Andrey Popov on 20.04.2021
 */

var AdsAdHoc = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint2();

        properties = properties || {};

        // sprite
        if (properties.sprite && properties.toSprite) {
            this.replaceImage(properties.sprite, properties.toSprite);
        }

        var i, prop;
        for (i = 1; i <= 5; i++) {
            prop = properties["lineStage" + i];
            if (prop && prop.bundle && prop.image) {
                Object.keys(bundles).filter(function (bundleName) {
                    return bundleName.indexOf("units_") !== -1;
                }).forEach(function (bundleName) {
                    this.replaceImage({ bundle: bundleName, resource: "unit_coins" + i }, prop);
                }.bind(this));

                UnitView.ANCHORS.coins["stage" + i] = {
                    x: 0.5,
                    y: 0.5
                };
            }
        }

        for (i = 0; i <= 5; i++) {
            prop = properties["tileSkin" + i];
            if (prop && prop.bundle && prop.image) {
                this.replaceImage({ bundle: "card", resource: "skin_" + i }, prop);
            }
        }

        // spine

        if (properties.spine && properties.toSpine && properties.spine.resource && properties.spine.resource.length > 0
            && properties.toSpine.json && properties.toSpine.json.length > 0) {
            bundles[properties.spine.bundle].jsons[properties.spine.resource] = bundles[properties.toSpine.bundle].jsons[properties.toSpine.json];
        }

        // sound

        if (properties.music) {
            if (properties.music === SoundPropertyEditor.WITHOUT_MUSIC) {
                cleverapps.styles.GameScene.STYLING.regular.forEach(function (style) {
                    style.music = undefined;
                });
            } else {
                Object.keys(bundles).filter(function (bundleName) {
                    return bundles[bundleName].urls.background_music_game;
                }).forEach(function (bundleName) {
                    if (!bundles[properties.music.bundle]) {
                        return;
                    }
                    bundles[bundleName].urls.background_music_game = bundles[properties.music.bundle].urls[properties.music.sound];
                });
                cleverapps.styles.GameScene.STYLING.regular.forEach(function (style) {
                    style.music = bundles.game_music.urls.background_music_game;
                });
            }
        }

        if (properties.mergeSound) {
            Object.keys(bundles.merge.urls).filter(function (soundName) {
                return soundName.startsWith("merge_effect_");
            }).forEach(function (soundName) {
                bundles.merge.urls[soundName] = bundles[properties.mergeSound.bundle].urls[properties.mergeSound.sound];
            });
        }
        if (properties.spawnSound) {
            bundles.merge.urls.spawn_start_effect = bundles[properties.spawnSound.bundle].urls[properties.spawnSound.sound];
        }
        if (properties.collectionSound) {
            bundles.game.urls.collection_effect = bundles[properties.collectionSound.bundle].urls[properties.collectionSound.sound];
        }
        if (properties.tileSound) {
            bundles.game.urls.play_tile_effect = bundles[properties.tileSound.bundle].urls[properties.tileSound.sound];
        }
        if (properties.blackoutSound) {
            bundles.game.urls.blackout_effect = bundles[properties.blackoutSound.bundle].urls[properties.blackoutSound.sound];
        }

        if (properties.sound && properties.toSound && properties.sound.resource && properties.sound.resource.length > 0
            && properties.toSound.sound && properties.toSound.sound.length > 0) {
            bundles[properties.sound.bundle].urls[properties.sound.resource] = bundles[properties.toSound.bundle].urls[properties.toSound.sound];
        }
    },
    
    replaceImage: function (from, to) {
        if (!from.resource || from.resource.length === 0 || !to.image || to.image.length === 0) {
            return;
        }

        if (from.bundle === to.bundle && from.resource === to.image) {
            return;
        }

        bundles[from.bundle].frames[from.resource + "_original"] = bundles[from.bundle].frames[from.resource];
        bundles[from.bundle].frames[from.resource] = bundles[to.bundle].frames[to.image] || bundles[to.bundle].urls[to.image];
    }
});

cleverapps.styles.AdsAdHoc = {
    width: 68,
    height: 60
};

AdsAdHoc.PROPERTIES = [{
    name: "music", type: "sound", bundles: ["ads_background_music", "ads_sfx_common"]
}];

if (cleverapps.config.type === "merge") {
    for (var i = 1; i <= 5; i++) {
        AdsAdHoc.PROPERTIES.push({
            name: "lineStage" + i,
            type: "image",
            bundles: ["ads_frames", "screenshots", "ads_sprites", "ads_units"]
        });
    }

    AdsAdHoc.PROPERTIES = AdsAdHoc.PROPERTIES.concat([{
        name: "mergeSound", type: "sound", bundles: ["ads_sfx_common"]
    }, {
        name: "spawnSound", type: "sound", bundles: ["ads_sfx_common"]
    }]);
}

if (cleverapps.config.type === "tile3") {
    for (var j = 0; j <= 5; j++) {
        AdsAdHoc.PROPERTIES.push({
            name: "tileSkin" + j,
            type: "image",
            bundles: ["ads_frames", "screenshots", "ads_sprites"]
        });
    }

    AdsAdHoc.PROPERTIES = AdsAdHoc.PROPERTIES.concat([{
        name: "collectionSound", type: "sound", bundles: ["ads_sfx_common"]
    }, {
        name: "tileSound", type: "sound", bundles: ["ads_sfx_common"]
    }, {
        name: "blackoutSound", type: "sound", bundles: ["ads_sfx_common"]
    }]);
}

AdsAdHoc.PROPERTIES = AdsAdHoc.PROPERTIES.concat([{
    name: "sprite",
    type: "resource"
}, {
    name: "toSprite",
    type: "image",
    bundles: ["ads_frames", "screenshots", "ads_sprites", "ads_units"]
}, {
    name: "spine",
    type: "resource"
}, {
    name: "toSpine",
    type: "spine",
    bundles: ["ads_spines", "ads_spines2", "ads_spines3", "dialogues", "ads_map2d", "ads_units"],
    value: {
        skin: "default",
        animation: "animation",
        looped: false
    }
}, {
    name: "sound",
    type: "resource"
}, {
    name: "toSound",
    type: "sound",
    bundles: ["ads_background_music", "ads_sfx_common"]
}]);