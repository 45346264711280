/**
 * Created by Andrey Popov on 22.04.20
 */

var Prolongation = function () {
    this.counter = 0;

    this.onAddMoves = function () {};
};

Prolongation.prototype.getFreeOffer = function (type) {
    var price = 0;
    var moves = (type === Prolongation.TYPES.MOVES) && 5;

    return {
        free: true,
        moves: moves,
        text: "FREE",
        force: this.getFreeProlongationForce(),
        onClick: function () {
            this.addMoves(moves, {
                price: price,
                delay: cleverapps.config.type === "solitaire" ? 1 : undefined
            });

            cleverapps.forces.closeRunningForce();
        }.bind(this)
    };
};

Prolongation.prototype.getMoves = function () {
    return this.isCustomWhaleFirstStep() ? 10 : 5;
};

Prolongation.prototype.getCurrencyOffer = function (type) {
    var moves = (type === Prolongation.TYPES.MOVES) && this.getMoves();
    var price = this.getPrice();

    var icon = "$$";
    var spend = levels.user.spendHard.bind(levels.user);

    if (cleverapps.config.soft) {
        icon = "@@";
        spend = levels.user.spendSoft.bind(levels.user);
    }

    return {
        moves: moves,
        text: Messages.get("PlayOn") + " " + icon + price,
        priceText: icon + price,
        onClick: function () {
            if (spend(cleverapps.EVENTS.SPENT.CONTINUE, price)) {
                this.addMoves(moves, {
                    price: price
                });
                this.escalateToUpperLevel();

                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HINT_USED, { value: price });
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MOVES_BOUGHT + "_" + price);
            }
        }.bind(this)
    };
};

Prolongation.prototype.getAdsOffer = function () {
    var moves = cleverapps.config.type === "tile3" ? 3 : 5;

    return {
        moves: moves,
        text: Messages.get("PlayOn") + " ##",
        priceText: "## Watch",
        onClick: function () {
            cleverapps.rewardedAdsManager.loadAndPlay({
                type: RewardedAdsManager.REWARDED,
                adLimit: AdsLimits.TYPES.PROLONGATION,
                callback: function () {
                    this.addMoves(moves);
                    this.escalateToUpperLevel();
                    cleverapps.adsLimits.watch(AdsLimits.TYPES.PROLONGATION);
                }.bind(this)
            });
        }.bind(this)
    };
};

Prolongation.prototype.hasOffers = function () {
    return Prolongation.PRICES[this.counter] !== undefined;
};

Prolongation.prototype.getOffers = function (type, noFree) {
    if (this.isFree() && !noFree) {
        return [this.getFreeOffer(type)];
    }

    var offers = [];

    if (this.isAdAvailable()) {
        offers.push(this.getAdsOffer());
    } else {
        offers.push(this.getCurrencyOffer(type));
    }

    return offers;
};

Prolongation.prototype.isAdAvailable = function () {
    return cleverapps.meta.getType() === Metha.LOOP || (this.counter === 0
        && cleverapps.adsLimits.state(AdsLimits.TYPES.PROLONGATION) === AdsLimits.STATE_READY
        && cleverapps.rewardedAdsManager.isAvailable(RewardedAdsManager.REWARDED));
};

Prolongation.prototype.isFree = function () {
    var force = this.getFreeProlongationForce().id;
    return levels.user.getFloatLevel() < 1.4 && !cleverapps.forces.isShown(force);
};

Prolongation.prototype.getFreeProlongationForce = function () {
    return cleverapps.config.type === "solitaire" ? Forces.FREE_ADD_MOVES_FORCE : Forces.FREE_PROLONGATION_FORCE;
};

Prolongation.prototype.isCustomWhaleFirstStep = function () {
    return this.counter === 0 && (levels.user.gold > 500
        || cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_WHALE
        || cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_BIGWHALE);
};

Prolongation.prototype.getPrice = function () {
    if (this.isCustomWhaleFirstStep()) {
        return 250;
    }
    
    return Prolongation.PRICES[this.counter];
};

Prolongation.prototype.escalateToUpperLevel = function () {
    this.counter++;

    if (this.counter >= Prolongation.PRICES.length && cleverapps.meta.getType() !== Metha.LOOP) {
        this.counter = Prolongation.PRICES.length - 1;
    }
};

Prolongation.prototype.getAlertMessage = function () {
    var game = Game.currentGame;
    var mission = game.secondaryMission;

    var lantern = Lantern.Get();
    var percent = game.getPercentOfCompletion();
    var goldCollected = game.rewards[GameBase.REWARD_HARD];

    var type = undefined;
    if (game.rewards[GameBase.REWARD_BOOSTERS]) {
        type = Prolongation.GIVE_UP_TYPES.BOOSTER;
    } else if (lantern && lantern.isActive(game.level) && lantern.getCurrentStreak() > 0) {
        type = Prolongation.GIVE_UP_TYPES.LANTERN;
    } else if (mission && mission.isRunning() && game.rewards.clover > 0) {
        type = Prolongation.GIVE_UP_TYPES.MISSION;
    } else if (goldCollected && goldCollected > game.basicReward) {
        type = Prolongation.GIVE_UP_TYPES.GOLD_COLLECTED;
    } else if (percent >= 0.70) {
        type = Prolongation.GIVE_UP_TYPES.NEARLY;
    }

    if (type === undefined) {
        return;
    }

    var message = [Messages.get("GiveUpAlert.text1")];

    if (type === Prolongation.GIVE_UP_TYPES.NEARLY) {
        message.push(Messages.get("AddMovesWindow.nearlyDoneGiveUp"));
    }

    if (cleverapps.ListBreaks().length > 0) {
        message.push(Messages.get("GiveUpAlert.text2"));
    }

    return message.join("\n");
};

Prolongation.prototype.getText = function (type) {
    if (type === Prolongation.TYPES.BOMB) {
        return Messages.get("PostponeBombWindow.addMoves", {
            moves: BombCard.MOVES_TO_ADD
        });
    }

    if (type === Prolongation.TYPES.FLAGS) {
        return Messages.get("RemoveFlagsWindow.text");
    }

    return Messages.get("ProlongationWindow.addMoves");
};

Prolongation.prototype.addMoves = function (moves, options) {
    options = options || {};
    options.delay = 700;

    this.onAddMoves(moves, options);

    if (Game.currentGame) {
        if (Game.currentGame.wildcardBooster) {
            Game.currentGame.wildcardBooster.stopHint();
        }

        if (moves) {
            Game.currentGame.setMoves(moves, options);
        }
    }
};

Prolongation.GIVE_UP_TYPES = {
    BOOSTER: 0,
    LANTERN: 1,
    MISSION: 2,
    GOLD_COLLECTED: 3,
    NEARLY: 4
};

Prolongation.PRICES = [
    100,
    300,
    500,
    700,
    1000
];

if (cleverapps.config.type === "tile3") {
    Prolongation.PRICES = [
        295,
        295,
        295,
        295,
        295
    ];
}

Prolongation.TYPES = {};
Prolongation.TYPES.MOVES = 1;
Prolongation.TYPES.BOMB = 2;
Prolongation.TYPES.FLAGS = 3;
