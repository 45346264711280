/**
 * Created by andrey on 11.11.19.
 */

cleverapps.FirebaseManager = {
    run: function () {
        if (cc.sys.isNative && cc.sys.os === cc.sys.OS_IOS) {
            return cleverapps.WebViewIosFirebase;
        } if (cleverapps.flags.nofirebase) {
            return cleverapps.FakeFirebase;
        } if (cc.sys.isNative && typeof FirebasePlugin !== "undefined") {
            return cleverapps.WebViewAndroidFirebase;
        } if (cleverapps.platform.oneOf(CleverApps, MacOS) && cleverapps.config.webFirebase) {
            return cleverapps.WebFirebase;
        } if (cc.sys.isNative) {
            console.log("No firebase plugin found!");
        }
        return cleverapps.FakeFirebase;
    }
};

cleverapps.WebFirebase = {
    init: function () {
        cleverapps.loadSdk("//www.gstatic.com/firebasejs/8.9.0/firebase-app.js", {
            onSuccess: function () {
                cleverapps.loadSdk("//www.gstatic.com/firebasejs/8.9.0/firebase-analytics.js", {
                    onSuccess: function () {
                        firebase.initializeApp(cleverapps.config.webFirebase);
                        firebase.analytics();
                    }
                });
            }
        });
    },

    logEvent: function (name, data) {
        if (typeof firebase !== "undefined" && typeof firebase.analytics === "function") {
            firebase.analytics().logEvent(name, data);
        }
    },

    setScreenName: function () {
    }
};

cleverapps.WebViewAndroidFirebase = {
    batch: [],

    sendBatch: cleverapps.accumulate(1000, function () {
        var self = cleverapps.WebViewAndroidFirebase;
        if (self.batch.length > 0) {
            if (FirebasePlugin.sendBatch) {
                FirebasePlugin.sendBatch(JSON.stringify(self.batch));
            }
            self.batch = [];
        }
    }),

    init: function () {
        this.batch.push({
            method: "setUserID",
            id: cleverapps.user.id
        });
        this.sendBatch();
    },

    logEvent: function (name, data) {
        this.batch.push({
            method: "logEvent",
            name: name,
            data: JSON.stringify(data)
        });
        this.sendBatch();
    },

    setScreenName: function (screen, scene) {
        this.batch.push({
            method: "setScreenName",
            screen: screen,
            scene: scene
        });
        this.sendBatch();
    }
};

cleverapps.WebViewIosFirebase = {
    init: function () {
        webkitMessage("firebase", {
            action: "setUserID",
            id: cleverapps.user.id
        });
    },

    logEvent: function (name, data) {
        webkitMessage("firebase", {
            action: "logEvent",
            name: name,
            data: JSON.stringify(data)
        });
    },

    setScreenName: function (screen, scene) {
        webkitMessage("firebase", {
            action: "setScreenName",
            screen: screen,
            scene: scene
        });
    }
};

cleverapps.FakeFirebase = {
    init: function () {},
    logEvent: function () {},
    setScreenName: function () {}
};
