/**
 * Created by denis on 31 july 2020
 */

var BoardPlayer = function (level, options) {
    GamePlayer.call(this, level, options);

    if (Game.currentGame) {
        this.keypadView = Game.currentGame.keypad.getAdsKeypadView ? Game.currentGame.keypad.getAdsKeypadView() : $(KeypadView).get();
        this.finger.setPositionRound(0, 0);

        this.finger.relaxPosition = { y: -this.finger.finger.height };
    }
};

BoardPlayer.prototype = Object.create(GamePlayer.prototype);
BoardPlayer.prototype.constructor = BoardPlayer;

BoardPlayer.prototype.playAction = function (action, f, duration) {
    if (action.type === "word") {
        this.openWordAction(action, f);
    } else if (action.type === "extrawindow") {
        var cookierJarView = $(CookieJarView).get();
        this.clickUIElement(cookierJarView, duration, f);
        setTimeout(function () {
            cleverapps.meta.display({
                focus: "ExtraWindow",
                action: function (f) {
                    new ExtraWindow(action.extra);
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        }, 1500);
    } else if (action.type === "furnitureSelect") {
        cleverapps.home.getFurnitureById(action.id).onClick();
        f();
    }
};

BoardPlayer.prototype.openWordAction = function (action, f) {
    var word;
    if (action.letters) {
        word = action.letters.map(function (data) {
            if (cleverapps.config.subtype === "stacks") {
                return Game.currentGame.keypad.field[data.x][data.y].symbol;
            } 
            return Game.currentGame.keypad.letters[data.index].symbol;
        }).join("");
    } else {
        var words = Game.currentGame.board.getUndiscoveredWords();
        words = cleverapps.Random.shuffle(words.slice());
        words = words.filter(function (word) {
            return Game.currentGame.keypad.findWordIndexes(word);
        });
        word = words[0];
    }

    if (word) {
        this.openWord(word, Game.currentGame.board.getUndiscoveredWords().length === 1, f);
    } else {
        f();
    }
};

BoardPlayer.prototype.openWord = function (word, isLastWord, callback) {
    var letters = Game.currentGame.keypad.findWordIndexes(word);

    if (!letters) {
        callback();
        return;
    }

    letters.forEach(function (letter, i) {
        var touchPosition = letter.onLocateView().getPosition();
        var targetPosition = cleverapps.scenes.getRunningScene(this.keypadView).convertToNodeSpace(this.keypadView.convertToWorldSpace(touchPosition));

        if (i === 0 && !this.finger.isShown) {
            this.finger.fadeIn(targetPosition);
            this.finger.isShown = true;
        }

        this.finger.moveTo(targetPosition);

        if (i === 0) {
            this.finger.press();
            this.finger.addAction(function (f) {
                Game.currentGame.keypad.swipe();
                f();
            });
            this.finger.delay(0.2);
        }

        this.finger.addAction(function (f) {
            Game.currentGame.keypad.touchBegan(letter);
            this.keypadView.pathNode.clear();
            f();
        }.bind(this));
    }.bind(this));

    this.finger.unPress();
    this.finger.addAction(function (f) {
        Game.currentGame.keypad.touchEnded();
        f();
    });
    this.finger.delay(0.2);

    if (isLastWord) {
        this.fingerRelax(1.0, callback);
    } else {
        this.finger.runOnce(callback);
    }
};
