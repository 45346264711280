/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorldLevelView = cc.Node.extend({
    ctor: function (level, parent) {
        this._super();

        this.level = level;
        this.styles = cleverapps.styles.BonusWorldLevelView[this.level.bonusWorld.type];

        this.setAnchorPoint2();
        this.setContentSize2(this.styles);
        this.setLocalZOrder(100 - this.level.levelNo);

        this.createBg();
        this.createText();
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            this.createIndicator();
        }

        parent.addChild(this);
        this.setPositionRound(this.styles.levels[this.level.levelNo]);

        this.onChangeType();

        cleverapps.aims.registerTarget("targetLevel" + this.level.levelNo, this);

        this.level.on("changeType", this.createListener(this.animateChangeType.bind(this)));
    },

    createTooltip: function () {
        cleverapps.tooltipManager.create(this, {
            text: this.level.type === BonusWorldLevel.TYPE_NOT_PASSED ? "BonusWorldNotPassedLevelTooltip" : "BonusWorldPassedLevelTooltip",
            position: this.level.levelNo >= 3 ? cleverapps.styles.UI.Tooltip.LOCATION.below : cleverapps.styles.UI.Tooltip.LOCATION.above
        });
    },

    createFinger: function () {
        this.finger = new FingerView({ control: "bonus_world_finger" });

        this.getParent().addChild(this.finger);
        this.finger.setPositionRound(this.styles.levels[this.level.levelNo]);

        this.finger.standardLoop({
            delay: 5,
            delayOnBegin: true
        });
    },

    createBg: function () {
        var json = this.level.bonusWorld.bundle.jsons.level_json;
        var scaleX = 1.0;
        if (this.styles.levels[this.level.levelNo].scaleX) {
            json = this.level.bonusWorld.bundle.jsons.level_2_json;
            scaleX = this.styles.levels[this.level.levelNo].scaleX;
        }
        this.bg = new cleverapps.Spine(json);
        this.addChild(this.bg);
        this.bg.setPositionRound(this.width / 2, this.height / 2);
        if (this.styles.skins) {
            this.bg.setSkin("skin_" + this.level.levelNo % this.styles.skins);
        }
        this.bg.setScaleX(scaleX);

        var animation = "current";
        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            animation = "passed";
        }
        if (this.level.type === BonusWorldLevel.TYPE_NOT_PASSED) {
            animation = "not_passed";
        }
        this.bg.setAnimation(0, animation, true);
    },

    createIndicator: function () {
        if (!this.styles.indicator) {
            return;
        }

        var indicator = this.indicator = new cleverapps.Spine(this.level.bonusWorld.bundle.jsons.level_indicator);
        this.addChild(indicator);
        indicator.setPositionRound(this.width / 2, this.height / 2);
        indicator.setAnimation(0, this.getIndicatorAnimation("idle"), true);
    },

    createText: function () {
        if (this.styles.skinNumbers) {
            if (this.level.type !== BonusWorldLevel.TYPE_PASSED) {
                this.bg.setSkin("" + this.level.levelNo);
            }
        } else {
            this.text = cleverapps.UI.generateImageText(this.level.levelNo + 1, cleverapps.styles.FONTS.BONUS_WORLD_LEVEL_TEXT);
            this.addChild(this.text);
            this.text.setPositionRound(this.styles.text);
        }
    },

    onChangeType: function () {
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }
        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }
        cleverapps.tooltipManager.remove(this);
        if (this.finger) {
            this.finger.removeFromParent();
        }

        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            this.createTooltip();
        }
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            this.hoverHandler = cleverapps.UI.applyHover(this);
            this.clickHandler = cleverapps.UI.onClick(this, this.onClick.bind(this));
            this.createFinger();
        }
        if (this.level.type === BonusWorldLevel.TYPE_NOT_PASSED) {
            this.createTooltip();
        }
    },

    animateChangeType: function () {
        if (this.styles.indicator) {
            this.animateIndicatorChange();
        } else {
            if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
                this.bg.setAnimationAndIdleAfter("np-c", "current");
            }

            if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
                this.bg.setAnimationAndIdleAfter("c-p", "passed");

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(1.0),
                    new cc.PlaySound(this.level.bonusWorld.bundle.urls.pass_level_effect)
                ));
            }
        }

        this.onChangeType();
    },

    animateIndicatorChange: function () {
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            var prevLevel = cleverapps.aims.getTarget("targetLevel" + (this.level.levelNo - 1));
            var endPos = cc.p(this.width / 2, this.height / 2);

            var indicator = this.indicator = prevLevel.indicator;
            delete prevLevel.indicator;
            indicator.replaceParentSamePlace(this);
            indicator.setAnimation(0, this.getIndicatorAnimation("animation"), false);
            cleverapps.audio.playSound(this.level.bonusWorld.bundle.urls.jump_sfx);

            this.bg.setAnimation(0, "np-c", false);

            indicator.runAction(new cc.Sequence(
                new cc.JumpTo(this.styles.delay, endPos, this.styles.jumpHeight, 1),
                new cc.CallFunc(function () {
                    indicator.setAnimation(0, this.getIndicatorAnimation("idle"), true);
                    this.bg.setAnimation(0, "current", true);
                }.bind(this))
            ));
        }

        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            this.bg.setAnimationAndIdleAfter("c-p", "passed");

            if (this.level.isLastLevelOnEpisode()) {
                var target = cleverapps.aims.getTarget("finalPrizeTarget" + this.level.bonusWorld.type);
                this.indicator.replaceParentSamePlace(target);
                this.indicator.setAnimationAndIdleAfter("animation", "idle_static");

                endPos = cc.p(target.width / 2 + this.styles.finalPrizePos.x, target.height / 2 + this.styles.finalPrizePos.y);
                this.indicator.runAction(new cc.JumpTo(this.styles.delay, endPos, this.styles.jumpHeight, 1));
                cleverapps.audio.playSound(this.level.bonusWorld.bundle.urls.jump_sfx);
            }
        }
    },

    getIndicatorAnimation: function (name) {
        return name + (this.styles.levels[this.level.levelNo].invertIndicator ? "_invert" : "");
    },

    onClick: function () {
        this.level.onClick();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUS_WORLD_LEVEL_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.BonusWorldLevelView = {};
cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_VALENTINES] = {
    width: 130,
    height: 100,
    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    levels: [
        {
            x: { align: "center", dx: -84 },
            y: { align: "center", dy: -550 }
        },
        {
            x: { align: "center", dx: 53 },
            y: { align: "center", dy: -600 },
            scaleX: 1
        },
        {
            x: { align: "center", dx: 220 },
            y: { align: "center", dy: -550 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: -350 }
        },
        {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: -307 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: -190 },
            y: { align: "center", dy: -300 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: -245 },
            y: { align: "center", dy: -210 }
        },
        {
            x: { align: "center", dx: -205 },
            y: { align: "center", dy: -90 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: 60 },
            y: { align: "center", dy: -47 },
            scaleX: 1
        },
        {
            x: { align: "center", dx: 210 },
            y: { align: "center", dy: -10 }
        }, {
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 110 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: 30 },
            y: { align: "center", dy: 135 }
        }, {
            x: { align: "center", dx: -130 },
            y: { align: "center", dy: 159 },
            scaleX: -1
        },
        {
            x: { align: "center", dx: -190 },
            y: { align: "center", dy: 280 },
            scaleX: 1
        },
        {
            x: { align: "center", dx: -80 },
            y: { align: "center", dy: 370 },
            scaleX: 1
        }
    ]
};
cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_EASTER] = {
    width: 130,
    height: 160,
    skins: 3,
    text: {
        x: { align: "center" },
        y: { align: "center", dy: 10 }
    },

    levels: [
        {
            x: { align: "center", dx: -149 },
            y: { align: "center", dy: -490 }
        },
        {
            x: { align: "center", dx: 12 },
            y: { align: "center", dy: -515 }
        },
        {
            x: { align: "center", dx: 159 },
            y: { align: "center", dy: -470 }
        },
        {
            x: { align: "center", dx: 97 },
            y: { align: "center", dy: -314 }
        },
        {
            x: { align: "center", dx: -48 },
            y: { align: "center", dy: -320 }
        },
        {
            x: { align: "center", dx: -194 },
            y: { align: "center", dy: -305 }
        },
        {
            x: { align: "center", dx: -256 },
            y: { align: "center", dy: -198 }
        },
        {
            x: { align: "center", dx: -253 },
            y: { align: "center", dy: -68 }
        },
        {
            x: { align: "center", dx: -113 },
            y: { align: "center", dy: 10 }
        },
        {
            x: { align: "center", dx: 54 },
            y: { align: "center", dy: -19 }
        },
        {
            x: { align: "center", dx: 190 },
            y: { align: "center", dy: 7 }
        },
        {
            x: { align: "center", dx: 99 },
            y: { align: "center", dy: 102 }
        },
        {
            x: { align: "center", dx: -29 },
            y: { align: "center", dy: 149 }
        },
        {
            x: { align: "center", dx: -148 },
            y: { align: "center", dy: 241 }
        },
        {
            x: { align: "center", dx: -125 },
            y: { align: "center", dy: 363 }
        }
    ]
};

cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
    width: 75,
    height: 105,
    skinNumbers: true,
    indicator: true,
    jumpHeight: 60,
    delay: 0.99,

    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    finalPrizePos: {
        x: -10,
        y: 50
    },

    levels: [
        {
            x: { align: "center", dx: -224 },
            y: { align: "center", dy: -551 }
        },
        {
            x: { align: "center", dx: -115 },
            y: { align: "center", dy: -445 }
        },
        {
            x: { align: "center", dx: -41 },
            y: { align: "center", dy: -347 }
        },
        {
            x: { align: "center", dx: 89 },
            y: { align: "center", dy: -298 }
        },
        {
            x: { align: "center", dx: 211 },
            y: { align: "center", dy: -252 }
        },
        {
            x: { align: "center", dx: 273 },
            y: { align: "center", dy: -175 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: 179 },
            y: { align: "center", dy: -119 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: 43 },
            y: { align: "center", dy: -81 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: -95 },
            y: { align: "center", dy: -67 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: -225 },
            y: { align: "center", dy: -31 }
        },
        {
            x: { align: "center", dx: -132 },
            y: { align: "center", dy: 47 }
        },
        {
            x: { align: "center", dx: -31 },
            y: { align: "center", dy: 104 }
        },
        {
            x: { align: "center", dx: 74 },
            y: { align: "center", dy: 140 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: 17 },
            y: { align: "center", dy: 206 }
        },
        {
            invertIndicator: true,
            x: { align: "center", dx: -74 },
            y: { align: "center", dy: 255 }
        }
    ]
};

if (cleverapps.config.name === "riddles") {
    cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
        width: 85,
        height: 85,
        skinNumbers: true,
        indicator: true,
        jumpHeight: 50,
        delay: 0.4,

        finalPrizePos: {
            x: 0,
            y: 0
        },

        text: {
            x: { align: "center" },
            y: { align: "center" }
        },

        levels: [
            {
                x: { align: "center", dx: 38 },
                y: { align: "center", dy: -523 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -56 },
                y: { align: "center", dy: -488 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -138 },
                y: { align: "center", dy: -437 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -211 },
                y: { align: "center", dy: -363 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -262 },
                y: { align: "center", dy: -275 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -198 },
                y: { align: "center", dy: -193 }
            },
            {
                x: { align: "center", dx: -87 },
                y: { align: "center", dy: -170 }
            },
            {
                x: { align: "center", dx: 35 },
                y: { align: "center", dy: -150 }
            },
            {
                x: { align: "center", dx: 268 },
                y: { align: "center", dy: -70 }
            },
            {
                x: { align: "center", dx: 220 },
                y: { align: "center", dy: 14 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: 141 },
                y: { align: "center", dy: 84 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: 73 },
                y: { align: "center", dy: 164 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -36 },
                y: { align: "center", dy: 201 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -115 },
                y: { align: "center", dy: 259 },
                invertIndicator: true
            },
            {
                x: { align: "center", dx: -174 },
                y: { align: "center", dy: 337 },
                invertIndicator: true
            }
        ]
    };
}

if (cleverapps.config.name === "scramble") {
    cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
        skins: 2,
        width: 80,
        height: 70,

        text: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 2 }
        },

        levels: [
            {
                x: { align: "center", dx: -50 },
                y: { align: "center", dy: -455 }
            },
            {
                x: { align: "center", dx: 41 },
                y: { align: "center", dy: -389 }
            },
            {
                x: { align: "center", dx: 145 },
                y: { align: "center", dy: -345 }
            },
            {
                x: { align: "center", dx: 58 },
                y: { align: "center", dy: -245 }
            },
            {
                x: { align: "center", dx: -75 },
                y: { align: "center", dy: -230 }
            },
            {
                x: { align: "center", dx: -176 },
                y: { align: "center", dy: -177 }
            },
            {
                x: { align: "center", dx: -154 },
                y: { align: "center", dy: -82 }
            },
            {
                x: { align: "center", dx: -32 },
                y: { align: "center", dy: -63 }
            },
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: 29 }
            },
            {
                x: { align: "center", dx: 59 },
                y: { align: "center", dy: 80 }
            },

            {
                x: { align: "center", dx: -68 },
                y: { align: "center", dy: 92 }
            },
            {
                x: { align: "center", dx: -164 },
                y: { align: "center", dy: 163 }
            },
            {
                x: { align: "center", dx: -46 },
                y: { align: "center", dy: 202 }
            },
            {
                x: { align: "center", dx: 70 },
                y: { align: "center", dy: 247 }
            },
            {
                x: { align: "center", dx: 186 },
                y: { align: "center", dy: 283 }
            }
        ]
    };
}