/**
 * Created by vladislav on 9/6/2022
 */

var Amazon = function () {
    AndroidBase.call(this, "amazon");
};

Amazon.prototype = Object.create(AndroidBase.prototype);
Amazon.prototype.constructor = Amazon;

Amazon.prototype.canReview = function () {
    return false;
};

Amazon.prototype._callNative = function (options) {
    console.log("amazon is here");
    if (options.method === "payments.connect") {
        PurchasesPlugin.connect();
    } else {
        console.log(options.method, PurchasesPlugin[options.method]);
    }
};