/**
 * Created by Andrey Popov on 6/19/23.
 */

var ResourcePropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    this.value = cleverapps.override({
        bundle: "",
        resource: ""
    }, initialValue);

    property.resourceType = property.resourceType || property.name;

    var itemsGetter = undefined;
    if (property.resourceType === "sprite") {
        itemsGetter = function (bundleName) {
            if (!bundleName || bundleName.length === 0) {
                return [];
            }
            return Object.keys(bundles[bundleName].frames);
        };
    } else if (property.resourceType === "spine") {
        itemsGetter = function (bundleName) {
            if (!bundleName || bundleName.length === 0) {
                return [];
            }
            return Object.keys(bundles[bundleName].jsons).filter(function (spine) {
                return ["atlas", "plist", "texture_info_debug_json"].indexOf(spine) === -1;
            });
        };
    } else if (property.resourceType === "sound") {
        itemsGetter = function (bundleName) {
            if (!bundleName || bundleName.length === 0) {
                return [];
            }
            return Object.keys(bundles[bundleName].urls).filter(function (sound) {
                var url = bundles[bundleName].urls[sound];
                return typeof url === "string" && url.indexOf(".png") === -1 && url.indexOf(".json") === -1;
            });
        };
    }

    var availableBundles = Object.keys(bundles).filter(function (bundleName) {
        return bundleName.indexOf("crosspromo_") === -1 && bundleName.indexOf("episode_") === -1 && bundleName.indexOf("language_") === -1
            && !bundles[bundleName].debug && !bundles[bundleName].virtual && itemsGetter(bundleName).length > 0;
    });

    var bundleEditor = {
        name: "bundle",
        items: availableBundles,
        getter: function () {
            return this.value.bundle.toString();
        }.bind(this),
        setter: function (value) {
            this.value.bundle = value;
            this.value.resource = "";
            cleverapps.bundleLoader.loadBundle(value, {
                onSuccess: function () {
                    this.onChangeValueCallback(this.property.name, this.value);
                    cleverapps.wysiwyg.inspector.showItemProperties();
                }.bind(this)
            });
        }.bind(this)
    };

    var resourceEditor = {
        name: "resource",
        items: itemsGetter(this.value.bundle),
        bundles: this.value.bundle && this.value.bundle.length > 0 ? [this.value.bundle] : [],
        getter: function () {
            return this.value.resource;
        }.bind(this),
        setter: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.resource = undefined;
            } else {
                this.value.resource = value;
            }
        }.bind(this),
        getPreview: function (resource) {
            if (!this.value.bundle || this.value.bundle.length === 0) {
                return;
            }

            if (property.resourceType === "sprite" && resource !== Wysiwyg.NOT_SELECTED && bundles[this.value.bundle].frames[resource]) {
                return new cc.Sprite(bundles[this.value.bundle].frames[resource]);
            }

            if (property.resourceType === "sound" && resource !== Wysiwyg.NOT_SELECTED && bundles[this.value.bundle].urls[resource]) {
                var icon = new cc.Sprite(bundles.editor_controls.frames.sound_icon);
                cleverapps.UI.onClick(icon, function () {
                    cleverapps.audio.unmute();
                    cleverapps.audio.eject();
                    cc.audioEngine.stopAllEffects();

                    cleverapps.audio.playSound(bundles[this.value.bundle].urls[resource]);
                }.bind(this));
                cleverapps.UI.applyHover(icon);

                return icon;
            }

            if (property.resourceType === "spine" && resource !== Wysiwyg.NOT_SELECTED && bundles[this.value.bundle].jsons[resource]) {
                var loadedSpine = cc.loader.getRes(bundles[this.value.bundle].jsons[resource]);
                if (!loadedSpine.animations) {
                    return;
                }
                var animations = Object.keys(loadedSpine.animations);

                var spine = new cleverapps.Spine(bundles[this.value.bundle].jsons[resource]);
                spine.setAnimation(0, animations[animations.length - 1], true);

                return spine;
            }
        }.bind(this)
    };

    this.propertyEditors = [
        {
            name: (property.resourceType === property.name ? "Any " : "Game ") + property.resourceType,
            isBlock: true
        },
        bundleEditor,
        resourceEditor
    ];

    if (property.resourceType === "spine") {
        var animations = [];
        var skins = [];
        if (this.value.bundle && this.value.resource) {
            var loadedSpine = cc.loader.getRes(bundles[this.value.bundle].jsons[this.value.resource]);
            if (loadedSpine.animations) {
                animations = Object.keys((loadedSpine.animations));
            }
            if (loadedSpine.skins) {
                skins = Object.keys((loadedSpine.skins));
            }
        }

        this.propertyEditors.push({
            name: "skin",
            items: skins,
            getter: function () {
                return this.value.skin;
            }.bind(this),
            setter: function (value) {
                this.value.skin = value;
            }.bind(this)
        });

        this.propertyEditors.push({
            name: "animation",
            items: animations,
            getter: function () {
                return this.value.animation;
            }.bind(this),
            setter: function (value) {
                this.value.animation = value;
            }.bind(this)
        });

        this.propertyEditors.push({
            name: "idle",
            items: [Wysiwyg.NOT_SELECTED].concat(animations),
            getter: function () {
                return this.value.idle;
            }.bind(this),
            setter: function (value) {
                this.value.idle = value;
            }.bind(this)
        });
    }

    this.propertyEditors = this.propertyEditors.map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

ResourcePropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    cleverapps.wysiwyg.inspector.showItemProperties();
};