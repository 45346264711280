/**
 * Created by spepa on 15.03.2023
 */

var LoopStatsComponent = cc.Node.extend({
    ctor: function () {
        this._super();
        var results = this.getResults(Game.currentGame);

        this.setAnchorPoint2();
        this.createContent(results);
    },

    getResults: function (game) {
        return {
            score: game.score.getPoints(),
            time: game.timer.getInGameTime(),
            moves: game.moves
        };
    },

    simpleEntry: function (name, value) {
        if (name.indexOf("time") !== -1) {
            value = cleverapps.intervalToString(value * 1000, false);
        }
        return cleverapps.UI.generateOnlyText(Messages.get(name) + ": " + value, cleverapps.styles.FONTS.WHITE_TEXT);
    },

    createContent: function (results) {
        var styles = cleverapps.styles.LoopStatsComponent;
        var stats = cleverapps.loop.getInfo();

        var views;
        var items = [];
        items.push(this.simpleEntry("score", results.score));
        items.push(this.simpleEntry("time", results.time));
        items.push(this.simpleEntry("moves", results.moves));
        items.push(this.simpleEntry("highscore", stats.highscore));
        items.push(this.simpleEntry("besttime", stats.besttime));
        items.push(this.simpleEntry("bestmoves", stats.bestmoves));

        views = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        if (styles.background) {
            var background = new cc.Scale9Sprite(bundles.game.frames.loop_stats_bg);
            background.setPositionRound(views.width / 2, views.height / 2);
            background.setContentSize2(views.getContentSize());
            this.addChild(background);
        }

        views.setPositionRound(views.width / 2, views.height / 2);
        this.addChild(views);

        this.setContentSize2(views.getContentSize());
    },

    beforeWinClose: function () {
        this.setCascadeOpacityEnabledRecursively(true);
        this.runAction(new cc.FadeOut(0.1));
    }
});

cleverapps.styles.LoopStatsComponent = {
    margin: 20,
    padding: {
        x: 50,
        y: 80
    },
    background: true
};