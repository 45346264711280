/**
 * Created by andrey on 29.12.2022
 */

cleverapps.IOSPayments = function () {
    PurchasesPlugin = cleverapps.IOSPurchasesPlugin;

    cleverapps.WebViewPayments.call(this);

    // cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, cleverapps.throttle(1000, this.checkReceipt.bind(this)));
};

cleverapps.IOSPayments.prototype = Object.create(cleverapps.WebViewPayments.prototype);
cleverapps.IOSPayments.prototype.constructor = cleverapps.IOSPayments;

cleverapps.IOSPayments.prototype.refreshReceipt = function () {
    PurchasesPlugin.refreshReceipt();
};

cleverapps.IOSPayments.prototype.onRefreshReceipt = function (refreshedReceipt) {
    this.refreshedReceipt = decodeWebViewData(refreshedReceipt);
    console.log("IOSPayments.onRefreshReceipt - " + this.refreshedReceipt);
    this.refreshedReceipt = JSON.parse(this.refreshedReceipt);

    this.checkReceipt();
};

cleverapps.IOSPayments.prototype.checkReceipt = function () {
    if (!this.refreshedReceipt) {
        return;
    }

    var data = {
        userid: cleverapps.user.id,
        platform: "apple",
        receipt: this.refreshedReceipt.receipt
    };

    cleverapps.RestClient.post("/purchasevalidator/checkreceipt/", data);
};

cleverapps.IOSPayments.prototype.onRestoreSubscriptionsResult = function (code, products, error) {
    products = decodeWebViewData(products);
    error = decodeWebViewData(error);

    console.log("WebViewPayments.onRestoreSubscriptionsResult code - " + code);

    if (products) {
        console.log("IOSPayments.onRestoreSubscriptionsResult products - " + products.length, products);
        products = JSON.parse(products);
    }

    if (error) {
        console.log("IOSPayments.onRestoreSubscriptionsResult error - " + error);
    }

    var callback = this.restoreSubscriptionsCallback;
    delete this.restoreSubscriptionsCallback;

    if (code === "success") {
        callback && callback(cleverapps.Social.CODE_SUCCEED);
        this.addSubscriptionTokens(products, true);
    } else {
        callback && callback(cleverapps.Social.CODE_FAILED);
    }
};

cleverapps.IOSPayments.prototype.restoreSubscriptions = function (callback) {
    callback = callback || function () {};

    if (!PurchasesPlugin.restore || !this.initialized) {
        callback(false);
        return;
    }

    this.restoreSubscriptionsCallback = cleverapps.once(callback);

    PurchasesPlugin.restoreSubscriptions();
};

cleverapps.IOSPayments.isAppropriate = function () {
    return cc.sys.isNative && [cc.sys.OS_OSX, cc.sys.OS_IOS].includes(cc.sys.os);
};

cleverapps.IOSPurchasesPlugin = {
    connect: function () {
        console.log("IOSPurchasesPlugin connect");
        webkitMessage("purchase", {
            action: "connect"
        });
    },

    refresh: function (type) {
        console.log("IOSPurchasesPlugin refresh");
        webkitMessage("purchase", {
            action: "refresh",
            type: type
        });
    },

    hasProductByName: function () {
        // store product names here to check?
        return true;
    },

    purchase: function (name) {
        webkitMessage("purchase", {
            action: "purchase",
            productName: name
        });
    },

    restoreSubscriptions: function () {
        webkitMessage("purchase", {
            action: "restoreSubscriptions"
        });
    },

    restore: function () {
        webkitMessage("purchase", {
            action: "restore"
        });
    },

    refreshReceipt: function () {
        webkitMessage("purchase", {
            action: "refreshReceipt"
        });
    },

    consumePurchase: function (orderId) {
        webkitMessage("purchase", {
            action: "consumePurchase",
            orderId: orderId
        });
    },

    refreshSubscriptionsTokens: function () {
        webkitMessage("purchase", {
            action: "refreshSubscriptionsTokens"
        });
    }
};
