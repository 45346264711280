/**
 * Created by mac on 2/28/23
 */

var Jam = function (level, options) {
    GameBase.call(this, level, options);

    this.jamPuzzle = new JamPuzzle(level.content.jam, level.content.exit);

    // this.jamPuzzle.solve(this.jamPuzzle.state);

    this.cars = [];
    var state = this.jamPuzzle.state;
    state.h.forEach(function(cars, row) {
        // console.log("h ", row, cars)
        cars.forEach(function(car, carIndex) {
            this.cars.push(new Car({
                y: row,
                x: car,
                width: this.jamPuzzle.lhorizontal[row][carIndex],
                height: 1
            }));
        }, this);
    }, this);
    state.v.forEach(function(cars, column) {
        cars.forEach(function(car, carIndex) {
            this.cars.push(new Car({
                y: car,
                x: column,
                width: 1,
                height: this.jamPuzzle.lvertical[column][carIndex]
            }));
        }, this);
    }, this);

    this.goal = this.goalCar();
};

var Game = Jam;

Jam.prototype = Object.create(GameBase.prototype);
Jam.constructor = Jam;

Jam.prototype.handleClick = function (x, y) {
    console.log(x, y)
    var figure = undefined;
    var minDist = Infinity;

    this.cars.forEach(function(car) {
        var distance = Math.abs(car.distanceTo(x, y));
        if (distance < minDist) {
            minDist = distance;
            figure = car;
        }
    });

    console.log(figure, minDist)

    if (figure !== undefined) {
        figure.moveTo(x, y);
    }
};

Jam.prototype.goalCar = function () {
    if (this.jamPuzzle.isRightSideExit()) {
        var row = this.jamPuzzle.exit.y;

        // console.log("row", row)

        var last = this.cars.filter(function(car) {
            // console.log("car", car)
            return car.y === row && car.isHorizontal();
        });

        return last[last.length - 1];
    }
};

Jam.prototype.hint = function () {

};
