/**
 * Created by iamso on 11.03.2022
 */

var RestoreProgressButton = cleverapps.UI.Button.extend({
    ctor: function (scene) {
        var styles = cleverapps.styles.RestoreProgressButton;
        var size = styles.size ? styles.size[cleverapps.wideMode.mode] : styles;

        var content = cleverapps.UI.generateOnlyText("RestoreProgress", cleverapps.styles.FONTS.BUTTON_TEXT);
        content.setDimensions(size.width, 0);
        content.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        content.fitTo(undefined, size.height);

        this._super({
            width: size.width,
            height: size.height,
            content: content,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            onClicked: function () {
                cleverapps.restoreProgress.restore();
            }
        });

        this.alwaysOn = true;

        this.scene = scene;
        this.scene.addChild(this);

        this.updatePosition();
        this.setCascadeOpacityEnabledRecursively(true);
        this.setOpacity(0);
        this.setVisible(false);
        this.setLocalZOrder(52); // higher than scene film effect

        cleverapps.restoreProgress.onShow = this.createListener(this.onShow.bind(this));
        cleverapps.restoreProgress.onHide = this.createListener(this.onHide.bind(this));
    },

    updatePosition: function () {
        var styles = cleverapps.styles.RestoreProgressButton;
        var position = styles[cleverapps.environment.getScene()] || styles.default;

        this.setPositionRound(position);
    },

    onShow: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.FadeIn(0.3)
        ));
    },

    onHide: function () {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeOut(0.3),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.RestoreProgressButton = {
    width: 300,
    height: 110,

    default: [{
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    }, {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    }, {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    }]
};