/**
 * Created by Andrey Popov on 9/9/22.
 */

var SnapshotBarView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.SnapshotBarView;

        var content = this.createContent();

        this.addChild(content);
        content.setPositionRound(styles.position);
        this.setPositionRound(styles.position);

        cleverapps.snapshotBar.onStartRecording = this.onStartRecording.bind(this);
        cleverapps.snapshotBar.onStopRecording = this.onStopRecording.bind(this);

        cleverapps.snapshotBar.onShowLabel = this.showLabel.bind(this);
        cleverapps.snapshotBar.onHideLabel = this.hideLabel.bind(this);
        cleverapps.snapshotBar.onShowButtons = this.showButtons.bind(this);
        cleverapps.snapshotBar.onHideButtons = this.hideButtons.bind(this);

        this.setVisible(cleverapps.config.debugMode);
    },

    updateSize: function () {
        this.setPositionRound(cleverapps.styles.SnapshotBarView.position);
    },

    createContent: function () {
        var styles = cleverapps.styles.SnapshotBarView;

        this.label = new TextWithIcon(cleverapps.snapshotBar.label, {
            icons: {
                "!!": bundles.dev_buttons.frames.label_rec,
                "**": bundles.dev_buttons.frames.label_playback
            }
        });
        this.label.setVisible(false);

        this.buttons = this.createButtons();
        this.stopCaptureActionsButton.setPositionRound(this.captureActionsButton.getPosition());

        var layout = new cleverapps.Layout([this.label, this.buttons], {
            direction: cleverapps.UI.HORIZONTAL
        });
        this.label.setPositionRound(styles.label.position);
        return layout;
    },

    createButtons: function () {
        var styles = cleverapps.styles.SnapshotBarView;

        this.makeSnapshotButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.dev_buttons.frames.snapshot),
            width: styles.buttons.width,
            height: styles.buttons.height,
            hint: "Make Snapshot\n(Alt + S)",
            onClicked: function () {
                cleverapps.snapshotBar.photo();
            }
        });

        this.captureActionsButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.dev_buttons.frames.snapshot_rec),
            width: styles.buttons.width,
            height: styles.buttons.height,
            hint: "Record Actions\n(Alt + R)",
            onClicked: function () {
                cleverapps.snapshotBar.toggleRecord();
            }
        });

        this.stopCaptureActionsButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.dev_buttons.frames.snapshot_stop),
            width: styles.buttons.width,
            height: styles.buttons.height,
            hint: "Stop Record\n(Alt + R)",
            onClicked: function () {
                cleverapps.snapshotBar.toggleRecord();
            }
        });
        this.stopCaptureActionsButton.setVisible(false);
        this.stopCaptureActionsButton.getDebugId = function () {
            return undefined;
        };

        this.createSceneButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.dev_buttons.frames.snapshots_create_clip),
            width: styles.buttons.width,
            height: styles.buttons.height,
            hint: "Create clip",
            onClicked: function () {
                cleverapps.snapshotBar.createScene();
            }
        });

        var buttons = [this.stopCaptureActionsButton, this.makeSnapshotButton, this.captureActionsButton];

        if (cleverapps.config.importMode) {
            buttons.push(this.createSceneButton);
        }

        return new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    showButtons: function () {
        this.buttons.setVisible(true);
    },

    hideButtons: function () {
        this.buttons.setVisible(false);
    },

    showLabel: function () {
        this.label.setString(cleverapps.snapshotBar.label);
        this.label.setVisible(true);
    },

    hideLabel: function () {
        this.label.setVisible(false);
    },

    onStopRecording: function () {
        this.makeSnapshotButton.enable();
        this.stopCaptureActionsButton.setVisible(false);
        this.captureActionsButton.setVisible(true);
    },

    onStartRecording: function () {
        this.makeSnapshotButton.disable();
        this.stopCaptureActionsButton.setVisible(true);
        this.captureActionsButton.setVisible(false);
    }
});

cleverapps.styles.SnapshotBarView = {
    position: {
        x: { align: "right" },
        y: { align: "bottom" }
    },

    buttons: {
        width: 70,
        height: 70
    },

    label: {
        position: {
            x: { align: "right", dx: -100 },
            y: { align: "bottom" }
        }
    },

    margin: 10
};