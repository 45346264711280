/**
 * Created by Andrey Popov on 1/24/21.
 */

var WysiwygPreview = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.componentsByIds = {};

    this.wysiwyg.hierarchy.on("itemPropertyChanged", this.itemPropertyChanged.bind(this));

    this.enabled = true;
};

WysiwygPreview.prototype = Object.create(cleverapps.EventEmitter.prototype);
WysiwygPreview.prototype.constructor = WysiwygPreview;

WysiwygPreview.prototype.registerComponent = function (component) {
    this.componentsByIds[component.id] = component;
};

WysiwygPreview.prototype.clear = function () {
    this.componentsByIds = {};
};

WysiwygPreview.prototype.showComponents = function (selectedItem, forceUpdate) {
    if (!selectedItem) {
        return;
    }

    if (selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
        this.clear();
        if (this.enabled) {
            this.trigger("showDirectory", this.wysiwyg.hierarchy.items.filter(function (item) {
                return item.type === HierarchyItem.TYPE.CLIP;
            }));
        }
        this.selectedResolution = undefined;
        this.shownScene = undefined;
        this.wysiwyg.inspector.showItemProperties();
    } else {
        var selectedClip = this.wysiwyg.hierarchy.getSelectedClip();
        var props = selectedClip.properties;

        if (!this.selectedResolution || (props.resolutions && props.resolutions.indexOf(this.selectedResolution) === -1)) {
            this.selectedResolution = (props.resolutions && props.resolutions.length > 0) ? props.resolutions[0] : props.resolution;
        }

        this.canvasSize = Wysiwyg.VIDEO_SIZES[this.selectedResolution] || Wysiwyg.VIDEO_SIZES.portrait_1080x1920;

        if (this.wysiwyg.playable) {
            this.canvasSize = cleverapps.scenes.getRunningScene().getContentSize();
        }

        this.trigger("showLoading");
        this.wysiwyg.hierarchy.blockSelection(true);

        this.wysiwyg.prepareScenes(selectedItem, function (scenes) {
            this.wysiwyg.hierarchy.blockSelection(false);
            this.trigger("hideLoading");

            if (selectedItem.type === HierarchyItem.TYPE.SCRIPT) {
                this.clear();
                this.selectedResolution = undefined;
                this.shownScene = undefined;
                this.trigger("showScript", selectedItem.properties.scriptFileName);
            } else if (this.enabled) {
                if (forceUpdate) {
                    this.forceUpdate = true;
                }

                if (scenes.length === 1 && this.shownScene === scenes[0].id && !this.forceUpdate) {
                    this.selectComponent(selectedItem);
                } else {
                    this.clear();
                    this.trigger("showLoading");
                    this.wysiwyg.hierarchy.blockSelection(true);
                    
                    this.trigger("showComponents", scenes, function () {
                        this.selectComponent(selectedItem);
                        this.trigger("hideLoading");
                        this.wysiwyg.hierarchy.blockSelection(false);
                    }.bind(this));

                    this.shownScene = scenes.length === 1 ? scenes[0].id : undefined;
                }
                this.forceUpdate = false;
            } else {
                this.clear();
            }
        }.bind(this));
    }
};

WysiwygPreview.prototype.itemPropertyChanged = function (component) {
    if (component.type === HierarchyItem.TYPE.COMPONENT) {
        if (component.assetName === "AdsGame" || component.assetName === "AdsMap2d") {
            if (this.forceUpdate) {
                this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem, true);
            }
        } else {
            this.trigger("updateComponent", component);
        }
    } else {
        this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem);
    }
};

WysiwygPreview.prototype.selectComponent = function (component) {
    this.trigger("selectComponent", component);
};

WysiwygPreview.prototype.clear = function () {
    this.trigger("clearComponents");
};

WysiwygPreview.prototype.toggleState = function () {
    this.enabled = !this.enabled;
    this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem, true);
};

WysiwygPreview.prototype.selectResolution = function (selectedResolution) {
    this.selectedResolution = selectedResolution;

    this.showComponents(cleverapps.wysiwyg.hierarchy.selectedItem, true);
    cleverapps.wysiwyg.inspector.showItemProperties();
};
