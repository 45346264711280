/**
 * Created by vladislav on 18/05/20
 */

cleverapps.SkinManager.SKINS.memorial = {
    bundle: "memorial",
    localization: {
        heroes: {
            "Missions.TulpanTournament.Title": "Memorial.Missions.TulpanTournament.Title",
            "Missions.TulpanTournament.Rules1": "Memorial.Missions.TulpanTournament.Rules1",
            "Missions.TulpanTournament.AnimationMainTextWin": "Memorial.Missions.TulpanTournament.AnimationMainTextWin",
            "Missions.TulpanTournament.AnimationMainTextLose": "Memorial.Missions.TulpanTournament.AnimationMainTextLose",
            "Missions.TulpanTournament.AnimationMainTextInfo0": "Memorial.Missions.TulpanTournament.AnimationMainTextInfo0",
            "Missions.TulpanTournament.AnimationMainTextInfo1": "Memorial.Missions.TulpanTournament.AnimationMainTextInfo1",
            "AddMovesWindow.mission.TulpanTournament": "Memorial.AddMovesWindow.mission.TulpanTournament",
            "AddMovesWindow.missionGiveUp.TulpanTournament": "Memorial.AddMovesWindow.missionGiveUp.TulpanTournament"
        },
        wordsoup: {
            "Missions.LetterTournament.Title": "Memorial.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Memorial.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Memorial.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Memorial.MissionRulesWindow.LetterTournament.text3"
        },
        crocword: {
            "Missions.LetterTournament.Title": "Memorial.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Memorial.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Memorial.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Memorial.MissionRulesWindow.LetterTournament.text3",
            "WardrobeDescText": "Memorial.WardrobeDescText",
            "WardrobeRulesText": "Memorial.WardrobeRulesText",
            "WardrobePrizeRewardTitle": "Memorial.WardrobePrizeRewardTitle",
            "WardrobeDialogue.stage0.text0": "Memorial.WardrobeDialogue.stage0.text0",
            "WardrobeDialogue.stage1.text0": "Memorial.WardrobeDialogue.stage1.text0",
            "WardrobeDialogue.stage2.text0": "Memorial.WardrobeDialogue.stage2.text0",
            "WardrobeDialogue.stage3.text0": "Memorial.WardrobeDialogue.stage3.text0",
            "WardrobeDialogue.stage4.text0": "Memorial.WardrobeDialogue.stage4.text0",
            "WardrobeDialogue.stage5.text0": "Memorial.WardrobeDialogue.stage5.text0",
            "WardrobeDialogue.stage6.text0": "Memorial.WardrobeDialogue.stage6.text0",
            "WardrobeDialogue.stage7.text0": "Memorial.WardrobeDialogue.stage7.text0",
            "WardrobeDialogue.stage8.text0": "Memorial.WardrobeDialogue.stage8.text0",
            "WardrobeDialogue.stage9.text0": "Memorial.WardrobeDialogue.stage9.text0",
            "WardrobeDialogue.stage10.text0": "Memorial.WardrobeDialogue.stage10.text0",
            "WardrobeDialogue.stage11.text0": "Memorial.WardrobeDialogue.stage11.text0"
        },

        scramble: {
            "Missions.LetterTournament.Title": "Memorial.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Memorial.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Memorial.MissionRulesWindow.LetterTournament.text3"
        },

        riddles: {
            "Missions.TulpanTournament.Title": "Memorial.Missions.TulpanTournament.Title",
            "Missions.TulpanTournament.Rules1": "Memorial.Missions.TulpanTournament.Rules1",
            "Missions.TulpanTournament.AnimationMainTextWin": "Memorial.Missions.TulpanTournament.AnimationMainTextWin",
            "Missions.TulpanTournament.AnimationMainTextLose": "Memorial.Missions.TulpanTournament.AnimationMainTextLose",
            "Missions.TulpanTournament.AnimationMainTextInfo0": "Memorial.Missions.TulpanTournament.AnimationMainTextInfo0",
            "Missions.TulpanTournament.AnimationMainTextInfo1": "Memorial.Missions.TulpanTournament.AnimationMainTextInfo1",
            "AddMovesWindow.mission.TulpanTournament": "Memorial.AddMovesWindow.mission.TulpanTournament",
            "AddMovesWindow.missionGiveUp.TulpanTournament": "Memorial.AddMovesWindow.missionGiveUp.TulpanTournament"
        }
    },
    slots: {
        localization: function (code) {
            var localization = cleverapps.SkinManager.SKINS.easter.localization;
            return localization && localization[cleverapps.config.name] && localization[cleverapps.config.name][code];
        },
        spine: function (options) {
            var spines = {};
            if (["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.tulpan_tournament.jsons.json] = bundles.memorial.jsons.json;
                spines[bundles.sidebar.jsons.tournament_tulpan_icon_json] = bundles.memorial.jsons.icon_json;
            }

            if (["crocword", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.person_hero.jsons.hero_json] = bundles.memorial.jsons.hero_json;
            }

            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.sidebar.jsons.mission_letter_icon_json] = bundles.sidebar.jsons.memorial_letter_json;
            }

            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.memorial.jsons.mission_window_json;
            }

            return spines[options.json] ? spines[options.json] : options.json;
        },
        toolbarDecors: function () {
            if (["heroes", "riddles", "wordsoup", "crocword"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.SceneDecors.toolbarViewMemorial;
            }
        },
        windowDecors: function (options) {
            if (["heroes", "riddles", "wordsoup", "crocword", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                var decors = options.decors && cleverapps.clone(options.decors, true) || {};

                decors.memorial_decor_top = true;
                decors.memorial_decor_bottom_left = true;
                decors.memorial_decor_bottom_right = true;

                if (options.window instanceof LeadersWindow) {
                    decors.memorial_decor_bottom_right = false;
                }
                if (options.window instanceof SubscriptionWindow && ["crocword", "wordsoup"].indexOf(cleverapps.config.name) !== -1) {
                    decors.memorial_decor_top = false;
                    decors.memorial_decor_bottom_left = false;
                    decors.memorial_decor_bottom_right = false;
                }
                if (options.window instanceof WardrobeWindow) {
                    decors.memorial_decor_top = false;
                }
                if (options.window instanceof WardrobeRulesWindow) {
                    decors.memorial_decor_top = false;
                }
                if (options.window instanceof WardrobeReceiveWindow) {
                    decors.memorial_decor_top = false;
                }
                decors.leaves_right = false;
                decors.gears = false;

                if (cleverapps.config.name === "scramble") {
                    if (options.window instanceof LeadersWindow) {
                        decors.memorial_decor_bottom_left = false;
                    }

                    if (options.window instanceof SubscriptionWindow) {
                        decors.memorial_decor_bottom_left = false;
                    }

                    if (options.window instanceof VictoryWindow) {
                        decors.memorial_decor_top = false;
                        decors.memorial_decor_bottom_left = false;
                    }

                    if (options.window instanceof TreasureSearchWindow) {
                        decors.memorial_decor_bottom_left = false;
                    }

                    if (options.window instanceof TellWindow) {
                        decors.memorial_decor_bottom_left = false;
                    }
                }

                if (cleverapps.config.name === "wordsoup" && options.window instanceof HomeTasksWindow) {
                    decors.memorial_decor_bottom_left = false;
                    decors.memorial_decor_bottom_right = false;
                }

                return decors;
            }
            return options.decors;
        },
        menuBarItem: function (options) {
            if (options.name === "GoldItem") {
                return cleverapps.styles.SceneDecors.menuBarItemMemorial;
            }
        },
        sceneControlButton: function (options) {
            if (options.target.options.logic === MenuControlButton) {
                return cleverapps.styles.SceneDecors.sceneControlButtonMemorial;
            }
        },
        progressViewDecor: function () {
            return cleverapps.styles.SceneDecors.progressViewMemorial;
        },
        missionIcon: function (options) {
            if (["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_BURN_NEARBY) {
                return bundles.memorial.frames.mission_icon_png;
            }

            if (["wordsoup", "crocword", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.memorial.frames.mission_icon_png;
            }
        },
        missionStar: function (options) {
            if (["wordsoup", "crocword", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.memorial.frames["mission_star" + options.type + "_png"];
            }
        },
        missionRulesWindow: function (options) {
            if (["wordsoup", "crocword", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.memorial.jsons.letter_rules_json;
            }
        },
        missionWindowDecors: function () {
            return cleverapps.styles.SceneDecors.missionWindowMemorial;
        },
        removeMissions: function () {
            if (["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                return [Mission.TYPE_COLLECT_MARK, Mission.TYPE_COMBO];
            }
        },
        wardrobeWindowBanner: function () {
            return bundles.memorial.frames.wardrobe_window_banner;
        },
        wardrobeBundle: function () {
            return "memorial";
        }
    }
};
