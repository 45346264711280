/**
 * Created by Denis "dalorveen" Kuzin on 11 september 2020
 */

var Match3Player = function (level, options) {
    GamePlayer.call(this, level, options);
    this.fieldView = this.scene.fieldView;
    if (this.scene && this.fieldView) {
        this.finger.relaxPosition = this.scene.convertToNodeSpace(this.fieldView.convertToWorldSpace({
            x: this.fieldView.width / 2,
            y: 0
        }));
    }
    this.finger.relaxEasing = cc.easeExponentialOut();

    this.actionsDone = 0;
    if (Game.currentGame) {
        Game.currentGame.counter.registerStage(10000, function () {
            if (this.pendingAction) {
                this.pendingAction();
                this.pendingAction = undefined;
            }

            if (this.pendingAfterLastAction) {
                this.pendingAfterLastAction();
                this.pendingAfterLastAction = undefined;
            }
        }.bind(this));
    }
};  

Match3Player.prototype = Object.create(GamePlayer.prototype);
Match3Player.prototype.constructor = Match3Player;

Match3Player.prototype.playAction = function (action, finishActionCallback) {
    if (Game.currentGame && Game.currentGame.counter.isActive()) {
        this.pendingAction = this.playAction.bind(this, action, finishActionCallback);
        return;
    }

    var progressFilter = function (a) {
        return a.type && ["delay"].indexOf(a.type) === -1;
    };

    if (this.options.progressChanged && progressFilter(action)) {
        var callback = function () {
            finishActionCallback();
            this.options.progressChanged(++this.actionsDone / this.actions.filter(progressFilter).length);
        }.bind(this);
    } else {
        callback = finishActionCallback;
    }

    if (!this.finger.isShown) {
        var startPositionOffset = cleverapps.styles.ScenarioMatch3Player.startPositionOffset;
        this.finger.fadeIn(
            cc.p(this.finger.relaxPosition.x, startPositionOffset.y),
            { x: this.finger.relaxPosition.x, y: startPositionOffset.y }
        );
        this.finger.isShown = true;
        this.finger.runOnce(this.playAction.bind(this, action, callback));
        return;
    }

    if (action.start && action.finish) {
        this.swapTwoTiles({
            start: action.start,
            dir: { row: action.finish.y - action.start.y, col: action.finish.x - action.start.x }
        }, callback);
    } else if (action.type === "delay") {
        this.finger.delay(action.delay);
        this.finger.runOnce(callback);
    } else if (action.type === "autoMove") {
        var move = this.findMove();
        this.swapTwoTiles(move, callback);
    } else {
        callback();
    }
};

Match3Player.prototype.swapTwoTiles = function (move, callback) {
    var fingerActions = [
        function (targetPosition) {
            this.finger.moveTo(targetPosition);

            this.finger.delay(0.1);

            this.finger.press();

            this.finger.addAction(function (f) {
                this.fieldView.onTouchBegan(new cc.Touch(targetPosition.x, targetPosition.y, 0));
                f();
            }.bind(this));
        }.bind(this),

        function (targetPosition) {
            this.finger.moveTo(targetPosition);

            this.finger.addAction(function (f) {
                this.fieldView.onTouchMoved(new cc.Touch(targetPosition.x, targetPosition.y, 0));
                f();
            }.bind(this));

            this.finger.unPress();

            this.finger.addAction(function (f) {
                this.fieldView.onTouchEnded(new cc.Touch(targetPosition.x, targetPosition.y, 0));
                f();
            }.bind(this));
        }.bind(this)
    ];

    fingerActions.forEach(function (action, i) {
        var dx = 0;
        var dy = 0;

        if (i !== 0) {
            dx = move.dir.col;
            dy = move.dir.row;
        }

        var targetPosition = this.cellPosition(move.start.x + dx, move.start.y + dy);

        action(targetPosition);
    }, this);

    this.fingerRelax(1.0, callback);
};

Match3Player.prototype.cellPosition = function (col, row) {
    return this.finger.convertToNodeSpace(this.fieldView.convertToWorldSpace(BaseCellView.alignInTheGrid(col, row)));
};

Match3Player.prototype.findMove = function () {
    var allValidMoves = Game.currentGame.field.findValidMove(true);

    var movesForGoal = [];
    allValidMoves.forEach(function (move) {
        for (var id in Game.currentGame.goals.elements) {
            var goal = Game.currentGame.goals.elements[id];
            if (goal.done) {
                continue;
            }

            var cellToSwap = Game.currentGame.field.cells[move.start.y + move.dir.row][move.start.x + move.dir.col];
            Game.currentGame.field.swap(move.start, cellToSwap, true);
            var shape = Game.currentGame.field.findAllShapes(move.start)[0];
            if (!shape) {
                Game.currentGame.field.swap(move.start, cellToSwap, true);
                continue;
            }

            var goalsAmount = 0;
            var boomGoalsAmount = 0;
            shape.cells.forEach(function (cell) {
                var floor = Game.currentGame.field.floor[cell.y][cell.x];
                if (floor && floor.getGoalId && goal.type === floor.getGoalId()) {
                    goalsAmount++;
                }

                if (cell.getGoalId && goal.type === cell.getGoalId()) {
                    goalsAmount++;
                }

                var eaters = Game.currentGame.EATERS;
                if (eaters) {
                    eaters.forEach(function (eater) {
                        if (cell.getColor && eater.getAcceptedColors()[0] === cell.getColor()) {
                            goalsAmount++;
                        }
                    });
                }

                cell.forNeighbor(function (neighboringCell) {
                    var isIntersect = shape.cells.some(function (cell) {
                        return cell.x === neighboringCell.x && cell.y === neighboringCell.y;
                    });

                    if (!isIntersect && neighboringCell.getGoalId && goal.type === neighboringCell.getGoalId()) {
                        boomGoalsAmount++;
                    }
                });
            });
            if (goalsAmount > 0 || boomGoalsAmount > 0) {
                movesForGoal.push({
                    move: move,
                    shape: shape,
                    goalsAmount: goalsAmount,
                    boomGoalsAmount: boomGoalsAmount
                });
            }

            Game.currentGame.field.swap(move.start, cellToSwap, true);
        }
    });

    var bestMove = movesForGoal.sort(function (a, b) {
        var left = b.goalsAmount * 10 + b.boomGoalsAmount;
        var right = a.goalsAmount * 10 + a.boomGoalsAmount;
        return left - right;
    }).map(function (item) {
        return item.move;
    })[0];

    if (bestMove) {
        // console.log('*** Best move for goal ', bestMove);
    } else {
        var maxLength = 0;
        allValidMoves.forEach(function (move) {
            var cellToSwap = Game.currentGame.field.cells[move.start.y + move.dir.row][move.start.x + move.dir.col];
            Game.currentGame.field.swap(move.start, cellToSwap, true);
            var shape = Game.currentGame.field.findAllShapes(move.start)[0];

            if (shape && shape.cells.length > maxLength) {
                maxLength = shape.cells.length;
                bestMove = move;
            }

            Game.currentGame.field.swap(move.start, cellToSwap, true);
        });

        // console.log('*** No moves found for goals. Best move is ', bestMove);
    }

    return bestMove || allValidMoves[0];
};

Match3Player.prototype.afterLastAction = function (f) {
    if (Game.currentGame && Game.currentGame.counter.isActive()) {
        this.pendingAfterLastAction = f.bind(this);
        return;
    }

    f();
};

cleverapps.styles.ScenarioMatch3Player = {
    startPositionOffset: {
        y: -100
    }
};