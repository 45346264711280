/**
 * Created by mac on 2019-08-25
 */

var BaseWindow = cc.Node.extend({
    listBundles: function () {
        return [];
    },

    ctor: function (options) {
        this._super();

        var args = arguments;
        var option = options || {};

        var parent = cleverapps.scenes.getRunningScene();
        if (!option.alwaysOnTop && !option.parentScene && !parent.windowsContainer) {
            if (cleverapps.scenes.windowsParent) {
                parent = cleverapps.scenes.windowsParent;
            }
            parent = parent.scene || parent;
        }
        parent.addChild(this);

        this.adjustBaseWindow();
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER);

        cleverapps.windows.add(this);

        this.bundles = this.listBundles.apply(this, arguments) || [];
        if (cleverapps.skins.getBundles()) {
            this.bundles = this.bundles.concat(cleverapps.skins.getBundles());
        }
        var personsBundles = this.getPersonBundles();
        if (personsBundles) {
            this.bundles = this.bundles.concat(personsBundles);
        }
        this.bundles.push("windows");
        this.bundles = cleverapps.unique(this.bundles);
        this.bundles = this.bundles.filter(function (name) {
            return bundles[name];
        });
        if (this.bundles.length === 0) {
            this.runWindow(args);
            return;
        }

        var onSuccess = this.createListener(function () {
            this.runWindow(args);
        }.bind(this));

        var onFailure = this.createListener(function () {
            this.deleteSelf();

            new RestartWindow();
        }.bind(this));

        cleverapps.meta.clearUpdateTimeout();
        this.load.apply(this, [onSuccess, onFailure].concat(arguments));
    },

    getPerson: function () {
        return undefined;
    },

    getPersonBundles: function () {
        var personBundles = [];
        var person = this.getPerson();
        if (person) {
            var left, right;

            if (person.left || person.right) {
                left = person.left;
                right = person.right;
            } else {
                left = person;
            }

            [left, right].filter(Boolean).forEach(function (personRole) {
                var personObject = PersonsLibrary.getPerson(personRole.role || personRole);
                if (personObject && personObject.bundle) {
                    personBundles.push(personObject.bundle);
                }
            });
        }

        return personBundles;
    },

    load: function (onSuccess, onFailure) {
        cleverapps.bundleLoader.loadBundles(this.bundles, { onSuccess: onSuccess, onFailure: onFailure });
    },

    isReadyToResize: function () {
        return this.initializedSuccess && !this.closed;
    },

    updateSize: function () {
        this.adjustBaseWindow();
    },

    updatePosition: function () {

    },

    adjustBaseWindow: function () {
        var scene = cleverapps.scenes.getRunningScene(this);
        var scale = this.parent.getScale();

        if (cleverapps.scenes.windowsParent && this.getParent().getParent() === cleverapps.scenes.windowsParent) {
            scale = Math.min(scene.width / cleverapps.scenes.windowsParent.width, scene.height / cleverapps.scenes.windowsParent.height);
        }

        var w = scene.width;
        var h = scene.height;

        this.setScale(1 / scale);
        this.setContentSize(w, h);
        this.setPositionRound((this.parent.width * scale - w) / 2, (this.parent.height * scale - h) / 2);
    },

    isCurrent: function () {
        return cleverapps.windows.currentWindow() === this;
    },

    runWindow: function (args) {
        this.adjustBaseWindow();

        this.onWindowLoaded.apply(this, args);

        this.initializedSuccess = true;

        if (this.displayDelay) {
            this.hideSelf();
            this.runAction(new cc.Sequence(
                new cc.DelayTime(this.displayDelay / 1000),
                new cc.CallFunc(function () {
                    this.displaySelf();
                }.bind(this))
            ));
        } else {
            this.displaySelf();
        }
    },

    hideSelf: function () {
        if (cleverapps.windows.currentWindow() === this) {
            this.setVisible(false);

            if (this.styles.shadow) {
                cleverapps.windows.onHideShadow();
            }

            this.onHide();
        }
    },

    displaySelf: function () {
        if (this.initializedSuccess && cleverapps.windows.currentWindow() === this) {
            this.adjustWindow();
            this.updatePosition();

            cleverapps.windows.onHideLoading();
            cleverapps.meta.updateControlsVisibility();

            this.setVisible(true);
            cleverapps.meta.setEventNodes([this]);

            if (this.styles.shadow) {
                cleverapps.windows.onShowShadow();
            } else {
                cleverapps.windows.onHideShadow();
            }

            if (cleverapps.environment) {
                cleverapps.environment.onOpenWindow();
            }

            this.popUpAnimation();
            this.onShow();
        }
    },

    deleteSelf: function () {
        cleverapps.windows.delete(this);
        this.removeFromParent();
    },

    stop: function () {
        cc.eventManager.removeListeners(this, true);
    },

    beforeCloseAnimation: function (callback) {
        callback();
    },

    close: function () {
        if (this.closed === true) {
            cleverapps.throwAsync("Closing already closed window: " + this.name);
        }

        if (!this.initializedSuccess || this.closed) {
            return;
        }

        if (this.closeButton instanceof BandButton) {
            this.closeButton.hide();
        }

        if (this.shareCheckBox && !this.withBg) {
            this.shareCheckBox.hide();
        }

        if (this.tapToContinue) {
            this.tapToContinue.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.homeButton) {
            this.homeButton.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.windowFooter) {
            this.windowFooter.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.windowTitle) {
            this.windowTitle.hide();
        }

        if (this.customComponent && this.customComponent.hide) {
            this.customComponent.hide();
        }

        cleverapps.bundleLoader.deleteBundles(this.bundles);

        cleverapps.windows.remove(this);
    },

    onClose: function () {

    }
});

BaseWindow.WINDOWS_ZORDER = 22;
