/**
 * Created by Andrey Popov on 20.01.2021.
 */
var Wysiwyg = function (directory) {
    this.assets = new Assets(this);
    this.hierarchy = new Hierarchy(this);
    this.wysiwygPreview = new WysiwygPreview(this);
    this.previewSlider = new PreviewSlider(this);
    this.inspector = new Inspector(this);
    this.wysiwygMenubar = new WysiwygMenubar(this);

    this.directory = directory || Wysiwyg.DEFAULT_DIRECTORY;

    this.wysiwygMenubar.gitButtons = new GitButtons("cleverapps/res/" + cleverapps.config.name + "/" + this.directory);
    this.wysiwygMenubar.gitButtons.checkStatus();

    this.hasChanges = false;
    this.wysiwygMenubar.gitButtons.on("statusChanged", this.handleGitStatusChanged.bind(this));

    if (cleverapps.config.source !== "playable") {
        cleverapps.setUrlHash({
            directory: this.directory
        });
    }

    this.playable = this.directory.indexOf("playable") !== -1;
};

Wysiwyg.prototype.handleGitStatusChanged = function (status) {
    if (status === GitButtons.CAN_PUSH) {
        this.hasChanges = true;
    }

    if (status === GitButtons.NO_CHANGES && this.hasChanges) {
        this.hasChanges = false;
        var selectedItem = this.hierarchy.selectedItem;
        if (selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
            this.hierarchy.loadDirectory(selectedItem.id);
        } else {
            this.hierarchy.loadClip(this.hierarchy.getSelectedClip().id);
        }
    }
};

Wysiwyg.prototype.addComponent = function (component, parentId, position) {
    var parentItem = this.hierarchy.items.filter(function (item) {
        return item.id === parentId;
    })[0];

    if (!parentItem || parentItem.type === HierarchyItem.TYPE.CLIP) {
        return;
    }

    var initialProperties = {
        position: position,
        scale: 1,
        rotation: 0,
        zOrder: 0,
        caption: component.preview.caption,
        visible: true
    };

    if (window[component.name] && window[component.name].PROPERTIES) {
        window[component.name].PROPERTIES.forEach(function (property) {
            if (typeof property.value === "function") {
                initialProperties[property.name] = property.value();
            } else if (property.value !== undefined) {
                initialProperties[property.name] = property.value;
            }
        });
    }

    var sceneComponent = new SceneComponent({ ctor: component.ctor, properties: initialProperties });
    this.wysiwygPreview.registerComponent(sceneComponent);

    var hierarchyItem = new HierarchyItem({
        type: HierarchyItem.TYPE.COMPONENT,
        parentItem: parentItem,
        id: sceneComponent.id,
        assetName: component.name,
        properties: initialProperties
    });
    this.hierarchy.addItem(hierarchyItem);
};

Wysiwyg.prototype.addScript = function (script, parentId) {
    var parentItem = this.hierarchy.items.filter(function (item) {
        return item.id === parentId;
    })[0];

    if (!parentItem || parentItem.type === HierarchyItem.TYPE.CLIP) {
        return;
    }

    var initialProperties = {
        visible: true,
        caption: script.name,
        scriptFileName: script.fileName
    };

    var hierarchyItem = HierarchyItem.createScript(script.fileName, parentItem, initialProperties);
    this.hierarchy.addItem(hierarchyItem);
};

Wysiwyg.prototype.previewVideo = function () {
    var params = cleverapps.getRequestParameters(location.hash);
    var url = window.location.origin + window.location.pathname + "#directory=" + params.directory
        + "&clipId=" + params.clipId + "&lang=" + cleverapps.settings.language + "&res=" + this.wysiwygPreview.selectedResolution;
    setTimeout(window.open.bind(window, url, "_blank"), 0);
};

Wysiwyg.prototype.generateVideo = function (clipConfig, callback) {
    this.prepareScenes(clipConfig, function (scenes) {
        this.runClipVariations({
            clipConfig: clipConfig,
            path: this.directory.replace(Wysiwyg.DEFAULT_DIRECTORY, "promo/ads") + "/" + clipConfig.properties.caption.replace(" ", "_") + "/",
            name: "video_%{lang}",
            scenes: scenes
        }, callback);
    }.bind(this));
};

Wysiwyg.prototype.capturePlayableAds = function (clipConfig, saveResult) {
    var scene = cleverapps.scenes.getRunningScene().scene || cleverapps.scenes.getRunningScene();
    scene.removeAllChildren();

    var urls = {};
    PlayableAdsScene.overrideMethodsToCaptureBundles(urls);

    this.prepareScenes(clipConfig, function (scenes) {
        this.runClipVariations({
            path: "",
            playableAds: true,
            scenes: scenes
        }, function () {
            if (saveResult) {
                cleverapps.RestClient.post("/wysiwyg/playable/savebundle", {
                    directory: cleverapps.wysiwyg.directory,
                    name: clipConfig.id,
                    content: urls,
                    hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(clipConfig)))
                });
            }
            cleverapps.styles.SCENE_MIN_WIDTH = 600;
            cleverapps.styles.SCENE_MAX_WIDTH = 2990;
            Resolution.setupDesignResolution();
            WysiwygScene.open(cleverapps.wysiwyg);
        }.bind(this));
    }.bind(this));
};

Wysiwyg.prototype.buildPlayable = function (clipConfig) {
    if (cleverapps.isLocalhost() || !cleverapps.config.debugMode) {
        alert("Available only for staging");
        return;
    }

    cleverapps.RestClient.post("/wysiwyg/playable/build", {
        name: clipConfig.id,
        project: cleverapps.config.name,
        force: this.forceBuildZip || 0,
        hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(clipConfig)))
    }, function (msg) {
        this.updateClipStatus();
        cleverapps.notification.create("BuildZip completed. " + msg);
    }.bind(this), function (msg) {
        var response = msg && msg.response && msg.response ? msg.response : "";
        if (response.indexOf("Time-out") !== -1) {
            setTimeout(function () {
                this.updateClipStatus(function (status) {
                    if (status === Wysiwyg.PLAYABLE_STATUSES.READY) {
                        cleverapps.notification.create("BuildZip completed. Ok");
                    } else {
                        cleverapps.RestClient.post("/wysiwyg/playable/cleanupbuild");
                        cleverapps.notification.create("Try to build later...");
                    }
                });
            }.bind(this), 50000);
        } else if (response.indexOf("another build in progress") !== -1) {
            cleverapps.Notification("BuildZip creation error: " + response);
        } else {
            this.updateClipStatus();
            console.log(msg);
            cleverapps.RestClient.post("/wysiwyg/playable/cleanupbuild");
            cleverapps.notification.create("BuildZip creation error. Try to build later...");
        }
    }.bind(this), {
        timeout: 100000
    });
};

Wysiwyg.prototype.prepareScenes = function (selectedItem, callback) {
    var scenes = [];
    var bundlesToLoad = [];
    var urlsToLoad = [];
    var containsGame = false;

    var getNestedComponents = function (hierarchyItem) {
        var componentResources = this.listComponentResources(hierarchyItem.properties, hierarchyItem.assetName);
        bundlesToLoad = bundlesToLoad.concat(componentResources.bundlesToLoad);
        urlsToLoad = urlsToLoad.concat(componentResources.urlsToLoad);
        return hierarchyItem.children.filter(function (item) {
            return item.type !== HierarchyItem.TYPE.SCRIPT;
        }).map(function (item) {
            if (item.properties && item.properties.isGame) {
                containsGame = true;
            }
            var itemResources = this.listComponentResources(item.properties, item.assetName);
            bundlesToLoad = bundlesToLoad.concat(itemResources.bundlesToLoad);
            urlsToLoad = urlsToLoad.concat(itemResources.urlsToLoad);
            var component = this.wysiwygPreview.componentsByIds[item.id];
            component.components = getNestedComponents(item);
            var validScriptFileNames = this.assets.scripts.map(function (script) {
                return script.fileName;
            });
            component.scripts = item.children.filter(function (item) {
                return item.type === HierarchyItem.TYPE.SCRIPT
                    && (item.properties.scriptContent || validScriptFileNames.indexOf(item.properties.scriptFileName) !== -1);
            }).map(function (item) {
                var result = cleverapps.clone(item.properties);
                var scriptInfo = item.properties.scriptContent ? this.assets.createScriptData(item.properties.scriptContent)
                    : this.assets.scripts.filter(function (script) {
                        return script.fileName === item.properties.scriptFileName;
                    })[0];
                result.scriptParameters = scriptInfo.parameters;
                result.scriptBody = scriptInfo.exec;
                return result;
            }.bind(this));
            return component;
        }.bind(this));
    }.bind(this);

    switch (selectedItem.type) {
        case HierarchyItem.TYPE.CLIP:
            for (var propertyName in selectedItem.properties) {
                var property = selectedItem.properties[propertyName];
                if (property && property.bundle) {
                    if (property.sound) {
                        urlsToLoad.push(bundles[property.bundle].urls[property.sound]);
                    } else {
                        bundlesToLoad.push(property.bundle);
                    }
                }
            }

            selectedItem.children.forEach(function (scene) {
                scenes.push({
                    id: scene.id,
                    properties: scene.properties,
                    components: getNestedComponents(scene)
                });
            });
            break;
        case HierarchyItem.TYPE.SCENE:
            scenes.push({
                id: selectedItem.id,
                properties: selectedItem.properties,
                components: getNestedComponents(selectedItem)
            });
            break;
        case HierarchyItem.TYPE.COMPONENT:
            var scene = selectedItem.parentItem;
            while (scene.type !== HierarchyItem.TYPE.SCENE) {
                scene = scene.parentItem;
            }
            scenes.push({
                id: scene.id,
                properties: scene.properties,
                components: getNestedComponents(scene)
            });
            break;
    }

    var afterLoadBundles = function () {
        cc.loader.load(cleverapps.unique(urlsToLoad), function () {
            callback(scenes);
        });
    };

    cleverapps.bundleLoader.loadBundles(cleverapps.unique(bundlesToLoad), {
        onSuccess: function () {
            if (containsGame) {
                this.loadExtraBundles(afterLoadBundles);
            } else {
                cc.loader.load(urlsToLoad, afterLoadBundles);
            }
        }.bind(this)
    });
};

Wysiwyg.prototype.loadExtraBundles = function (callback) {
    if (bundles["extra_" + cleverapps.settings.language]) {
        cleverapps.bundleLoader.loadBundles(["extra_" + cleverapps.settings.language], {
            onSuccess: callback
        });
    } else {
        callback();
    }
};

Wysiwyg.prototype.listComponentResources = function (item, assetName) {
    if (!item || typeof item !== "object") {
        return [];
    }

    var bundlesToLoad = [];
    var urlsToLoad = [];
    if (assetName && window[assetName] && window[assetName].PROPERTIES) {
        window[assetName].PROPERTIES.filter(function (assetProperty) {
            return item[assetProperty.name] && item[assetProperty.name].bundle && bundles[item[assetProperty.name].bundle];
        }).forEach(function (assetProperty) {
            var bundleName = item[assetProperty.name].bundle;

            if (item[assetProperty.name].sound) {
                urlsToLoad.push(bundles[bundleName].urls[item[assetProperty.name].sound]);
            } else if (item[assetProperty.name].image && bundles[bundleName].urls[item[assetProperty.name].image]) {
                urlsToLoad.push(bundles[bundleName].urls[item[assetProperty.name].image]);
            } else {
                bundlesToLoad.push(bundleName);
            }
        });
    }

    if (assetName && window[assetName]) {
        if (window[assetName].BUNDLES) {
            window[assetName].BUNDLES.forEach(function (bundleName) {
                bundlesToLoad.push(bundleName);
            });
        }
    }

    return {
        bundlesToLoad: bundlesToLoad,
        urlsToLoad: urlsToLoad.filter(Boolean)
    };
};

Wysiwyg.prototype.runClipVariations = function (options, callback) {
    var player = new ActionPlayer();

    var props = options.clipConfig.properties;

    var sizes = {};
    Object.keys(Wysiwyg.VIDEO_SIZES).filter(function (resolutionName) {
        if (props.resolutions) {
            return props.resolutions.indexOf(resolutionName) !== -1;
        }
        return resolutionName === props.resolution;
    }).forEach(function (resolutionName) {
        sizes[resolutionName] = Wysiwyg.VIDEO_SIZES[resolutionName];
    });

    var languages = props.languages;
    if (options.download) {
        languages = [cleverapps.settings.language];
        sizes = {};
        sizes[this.wysiwygPreview.selectedResolution] = Wysiwyg.VIDEO_SIZES[this.wysiwygPreview.selectedResolution];
    }

    var screenshotMode = props.frameCapture && !props.frameCapture.video;
    var audioBundle = props.music && bundles[props.music.bundle];
    var audio = audioBundle ? audioBundle.urls[props.music.sound] : undefined;

    var savedFiles = [];
    var processed = 0;
    var total = languages.length * Object.keys(sizes).length;

    languages.forEach(function (language) {
        Object.keys(sizes).forEach(function (resName) {
            player.add(function (f) {
                var clipSceneOptions = {
                    generate: true,
                    clipConfig: options.clipConfig,
                    scenes: options.scenes,
                    path: options.path,
                    resolutionName: resName,
                    language: language
                };

                var path = options.path;
                var name = options.name;

                var resolution = sizes[resName];
                var postfix = resolution.width + "x" + resolution.height;

                path = path.replace("%{name}", cleverapps.config.name).replace("%{lang}", language);
                name = name.replace("%{name}", cleverapps.config.name).replace("%{lang}", language);
                if (name.indexOf("%{resolution}") >= 0) {
                    name = name.replace("%{resolution}", postfix);
                } else {
                    name += "_" + postfix;
                }

                clipSceneOptions.onFinish = function () {
                    if (screenshotMode) {
                        f();
                    } else {
                        processed++;
                        var saveOptions = {
                            processed: processed,
                            total: total
                        };
                        cleverapps.Recorder.save(path, name, saveOptions, function (files) {
                            savedFiles.push(files);
                            f();
                        });
                    }
                };
                ActiveClipScene.open(clipSceneOptions);
            });
        });
    });

    if (screenshotMode) {
        player.add(function (f) {
            cleverapps.Recorder.terminate();
            f();
        });
    } else {
        player.add(function (f) {
            var ffmpeg = {
                video: savedFiles,
                audio: audio,
                fadeOutOff: props.fadeOutOff,
                fadeDuration: 1.5,
                frameRate: props.frameRate || 60,
                transition: {
                    duration: 1.0,
                    default: "circleopen",
                    sequence: []
                }
            };
            cleverapps.Recorder.runTransform(ffmpeg);
            f();
        });
    }

    player.add(function (f) {
        f();
        callback && callback();
    });

    player.play();
};

Wysiwyg.prototype.updateClipStatus = function (callback) {
    if (!this.hierarchy.selectedItem || this.hierarchy.selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
        return;
    }

    if (this.playable) {
        cleverapps.RestClient.post("/wysiwyg/playable/status", {
            name: this.hierarchy.getSelectedClip().id,
            directory: cleverapps.wysiwyg.directory,
            hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(this.hierarchy.getSelectedClip())))
        }, function (data) {
            var statusText = "";

            if (data.status === Wysiwyg.PLAYABLE_STATUSES.NO_BUNDLE) {
                statusText = "Bundle not found or dirty";
            } else if (data.status === Wysiwyg.PLAYABLE_STATUSES.NO_ZIP) {
                statusText = "Playable zip not found";
            } if (data.status === Wysiwyg.PLAYABLE_STATUSES.READY) {
                statusText = data.url;
            }

            this.wysiwygMenubar.updateClipStatus(data.status, statusText);

            if (callback) {
                callback(data.status, statusText);
            }
        }.bind(this));
    } else {
        cleverapps.taskManager.taskStatus(
            cleverapps.wysiwyg.directory + "/" + this.hierarchy.selectedItem.id,
            function (result) {
                this.wysiwygMenubar.updateClipStatus(result ? result.status : TaskManager.STATUS.NO_INFO, result ? result.info : "");
            }.bind(this)
        );
    }
};

Wysiwyg.LANGUAGES = ["en", "ru", "de", "es", "fr", "it", "ja", "nl", "pt", "ar", "ko", "tr", "zh", "pl", "lv"];

Wysiwyg.EVENTS = {
    GAME_STARTED: "gameStarted",
    GAME_FINISHED: "gameFinished",
    PROGRESS_CHANGED: "progressChanged",
    SCENE_COMPLETED: "sceneCompleted",
    SCENE_STARTED: "sceneStarted",
    PUZZLE_SHOW_UP: "puzzleShowUp",
    PUZZLE_STARTED: "puzzleStarted",
    PUZZLE_FINISHED: "puzzleFinished",
    PREVIEW: "preview"
};

if (cleverapps.config.type === "merge") {
    Object.assign(Wysiwyg.EVENTS, {
        LIVES_FEAST_SIDEBAR_ICON_CLICKED: "livesFeastSideBarIconClicked",
        SOFT_FEAST_SIDEBAR_ICON_CLICKED: "softFeastSideBarIconClicked",
        OPEN_FOG: "openFog",
        HIGHLIGHT_MERGE: "highlightMerge",
        OPENING_CHEST: "openingChest",
        HERO_CREATED: "heroCreated",
        UNIT_MERGED: "unitMerged",
        HARVEST: "harvest",
        MINE: "mine"
    });
}

if (cleverapps.config.type === "match3") {
    Object.assign(Wysiwyg.EVENTS, {
        INC_GOAL: "incGoal"
    });
}

Wysiwyg.PLAYABLE_STATUSES = {
    NO_BUNDLE: "noBundle",
    NO_ZIP: "noZip",
    READY: "ready"
};

Wysiwyg.DEFAULT_DIRECTORY = "wysiwyg";
Wysiwyg.NOT_SELECTED = "not selected";
