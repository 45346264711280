/**
 * Created by Ivan on 10.07.2023
 */

cleverapps.styles.FingersAnchors = {
    finger_png: {
        anchorX: 0.14,
        anchorY: 0.93
    },
    finger_merge_png: {
        anchorX: 0.15,
        anchorY: 0.94
    },
    finger_halloween_png: {
        anchorX: 0.17,
        anchorY: 0.93
    },
    finger_halloween2_png: {
        anchorX: 0.09,
        anchorY: 0.92
    },
    hand_halloween_small: {
        anchorX: 0.06,
        anchorY: 0.93
    },
    finger_with_pen_png: {
        anchorX: 0.02,
        anchorY: 0.96
    },
    finger_xmas_png: {
        anchorX: 0.1,
        anchorY: 0.96
    },
    finger_candy_cane: {
        anchorX: 0.23,
        anchorY: 0.95
    },
    finger_riddles_hand: {
        anchorX: 0.33,
        anchorY: 0.9
    },
    finger_heroes_hand: {
        anchorX: 0.21,
        anchorY: 0.9
    },
    finger_adventure_hand: {
        anchorX: 0.33,
        anchorY: 0.9
    },
    finger_cartoon_png: {
        anchorX: 0.32,
        anchorY: 0.9
    },
    finger_realistic_png: {
        anchorX: 0.21,
        anchorY: 0.93
    },
    archer_hand: {
        anchorX: 0.11,
        anchorY: 0.95
    },
    armor_hand: {
        anchorX: 0.11,
        anchorY: 0.96
    },
    indigenous_hand: {
        anchorX: 0.13,
        anchorY: 0.9
    },
    blue_hand: {
        anchorX: 0.1,
        anchorY: 0.97
    },
    leather_hand: {
        anchorX: 0.11,
        anchorY: 0.94
    },
    mage_hand: {
        anchorX: 0.1,
        anchorY: 0.96
    },
    yellow_hand: {
        anchorX: 0.1,
        anchorY: 0.96
    },
    big_hand: {
        anchorX: 0.07,
        anchorY: 0.95
    },
    cat_paw: {
        anchorX: 0.12,
        anchorY: 0.9
    },
    writing_hand: {
        anchorX: 0.05,
        anchorY: 0.97
    },
    dracula_hand: {
        anchorX: 0.1,
        anchorY: 0.95
    },
    dracula_thumb_hand: {
        anchorX: 0.09,
        anchorY: 0.97
    },
    warlock_finger: {
        anchorX: 0.1,
        anchorY: 0.96
    },
    king_hand: {
        anchorX: 0.15,
        anchorY: 0.96
    }
};