/**
 * Created by vladislav on 9/7/2022
 */

var FacebookCanvas = function () {
    Platform.call(this, "facebook");
};

FacebookCanvas.prototype = Object.create(Platform.prototype);
FacebookCanvas.prototype.constructor = FacebookCanvas;

FacebookCanvas.prototype.pushSender = function () {
    return cleverapps.LocalPushes.SocialSender;
};
