/**
 * Created by andrey on 02.08.2022.
 */

var BackToAdminOrEditorAction = function () {
    if (cleverapps.config.editorMode) {
        cleverapps.setUrlHash({ playLevel: undefined });

        this.level.edit();
        return true;
    }

    if (cleverapps.config.adminMode) {
        cleverapps.setUrlHash({ playLevel: undefined });

        AdministratorScene.open();
        return true;
    }
    return false;
};