/**
 * Created by razial on 03.12.2020
 */

var StickersCollection = function (mission) {
    MissionGame.call(this, mission);
};

StickersCollection.prototype = Object.create(MissionGame.prototype);
StickersCollection.prototype.constructor = StickersCollection;

StickersCollection.prototype.open = function () {
    var delta = this.mission.currency;
    this.mission.updateCurrency(-delta);
    this.mission.update(delta);

    if (this.isFinished()) {
        this.finish();
    }
};

StickersCollection.prototype.checkIfComplete = function () {
    if (this.isFinished() && this.mission.isRunning()) {
        this.mission.complete();
    }
};

StickersCollection.prototype.isFinished = function () {
    return this.mission.result >= StickersCollection.GEMS;
};

StickersCollection.prototype.getReward = function () {
    return this.mission.getPrize();
};

StickersCollection.GEMS = 25;

StickersCollection.GEMS_BY_PLACE = {
    1: 5,
    2: 3,
    3: 1
};

var StickersCollectionLogic = function (mission) {
    this.mission = mission;
};

StickersCollectionLogic.prototype.isMinigameReadyToFinish = function () {
    return this.mission.result + this.mission.currency >= StickersCollection.GEMS;
};

StickersCollectionLogic.prototype.isFinished = function () {
    return this.mission.result >= StickersCollection.GEMS;
};

StickersCollectionLogic.prototype.processEvent = function (options) {
    this.mission.updateCurrency(options.amount);
};