/**
 * Created by andrey on 26.04.2021.
 */

cleverapps.NativeFbEventsLogger = function() {
    this.batch = [];
};

cleverapps.NativeFbEventsLogger.prototype.logEvent = function (name, params) {
    if (cleverapps.flags.nofacebookevents) {
        return;
    }

    this.batch.push({
        name: name,
        params: params
    });
    this.logEventsBatch();
};

cleverapps.NativeFbEventsLogger.prototype.logEventsBatch = cleverapps.accumulate(1000, function() {
    if (cleverapps.flags.nofacebookevents) {
        return;
    }

    if (cc.sys.os === cc.sys.OS_ANDROID) {
        FacebookPlugin.logEventsBatch(JSON.stringify(this.batch));
    } else if (cc.sys.os === cc.sys.OS_IOS) {
        webkitMessage("facebook",{
            action: 'logEventsBatch',
            events: this.batch
        });
    }
    this.batch = [];
});