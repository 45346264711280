/**
 * Created by slava on 23.03.17.
 */

cleverapps.FixedWidthScene = cc.Scene.extend({
    listBundles: function () {
        return [];
    },

    listBaseBundles: function () {
        cleverapps.eventManager.setCurrentFeatures();
        cleverapps.skins.update(this);

        var result = ["main"];

        if (cleverapps.config.debugMode) {
            result.push("dev_resources");
            result.push("orangery");
        }
        if (cleverapps.skins.getBundles()) {
            result = result.concat(cleverapps.skins.getBundles());
        }

        return result;
    },

    ctor: function (options) {
        this._super();

        this.level = options && options.level;

        if (cleverapps.config.debugMode) {
            this.unsavedChanges = false;
            this.clearChanges();
        }
    },

    addChanges: function () {
        if (!cleverapps.config.debugMode) {
            return;
        }

        this.unsavedChanges = true;
        addEventListener("beforeunload", cleverapps.FixedWidthScene.unsavedChangesHandler);
    },

    clearChanges: function () {
        this.unsavedChanges = false;
        removeEventListener("beforeunload", cleverapps.FixedWidthScene.unsavedChangesHandler);
    },

    closeAction: function () {
        if (cleverapps.meta.isFocused()) {
            return;
        }

        if (this.unsavedChanges) {
            if (confirm("There are some unsaved changes, are you sure to exit?")) {
                this.clearChanges();
            } else {
                return;
            }
        }

        this._closeAction();
    },

    _closeAction: function () {
        cleverapps.meta.display({
            focus: "closeScene",
            action: function (f) {
                cleverapps.travelBook.gotoMainScene(f);
            }
        });
    },

    prepareBundles: function () {
        cleverapps.wideMode.refresh();

        this.bundles = this.listBundles().concat(this.listBaseBundles());
        this.bundles = cleverapps.unique(this.bundles.filter(function (name) {
            return name && bundles[name];
        }));
    },

    load: function (f) {
        var callback = cleverapps.wait(2, f);

        if (this.level) {
            this.level.load(callback);
        } else {
            callback();
        }

        var toLoad = cleverapps.substract(this.bundles, this.prevSceneBundles);

        cleverapps.bundleLoader.loadBundles(toLoad, {
            waitNoSounds: true,
            onSuccess: callback,
            onFailure: function () {
                var options = {
                    focus: "loadSceneBundlesFailure",
                    action: function () {
                        new RestartWindow();
                    }
                };

                if (cleverapps.meta.isFocused()) {
                    cleverapps.meta.distract(options);
                } else {
                    cleverapps.meta.display(options);
                }
            }
        });
    },

    setLoadingFinished: function () {
        this.isAllLoaded = true;
    },

    unload: function (nextScene) {
        if (this.level) {
            this.level.unload();
        }

        nextScene.prevSceneBundles = this.bundles;

        var toDelete = cleverapps.substract(this.bundles, nextScene.bundles);

        cleverapps.bundleLoader.deleteBundles(toDelete);
        cleverapps.bundleLoader.clearUnusedResources();
        cc.pool.drainAllPools();
    },

    canTransitionFast: function () {
        if (!this.bundles) {
            return false;
        }

        if (cleverapps.bundleLoader.isLoaded(this.bundles)) {
            return true;
        }

        if (cc.sys.isNative && this.bundles.every(function (name) {
            return !bundles[name].external;
        })) {
            return true;
        }

        return false;
    },

    onSceneLoaded: function (sceneName) {
        cleverapps.wideMode.refresh();

        cleverapps.environment.setScene(sceneName);

        var winSize = cleverapps.UI.getWinSize();
        this.setContentSize(winSize);

        this.scene = new cc.Node();
        this.scene.setPositionRound(this.width / 2, this.height / 2);
        this.scene.setAnchorPoint(0.5, 0.5);
        this.scene.setContentSize2(cleverapps.UI.getSceneSize());
        this.addChild(this.scene);

        this.movingNode = new cc.Node();
        this.movingNode.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 3);
        this.scene.addChild(this.movingNode);

        var shadow = new ShadowView();
        this.scene.addChild(shadow, BaseWindow.WINDOWS_ZORDER - 3);
        shadow.loading.replaceParentSamePlace(this.scene, { keepScale: true });
        shadow.updatePosition();

        this.backgroundStyles = this.getBackgroundStyles();
        this.setBackground();

        this.addTreasureBag();

        this.addMenuContainer();
        this.addSideBar();
        this.addToolbar();
        this.addSnapshotBar();

        cleverapps.windows.reset();

        var styles = this.getAudioStyles();

        if (cleverapps.config.source !== "playable") {
            this.startOrRestartMusic(styles);
        }

        if (cleverapps.flyingAd) {
            cleverapps.flyingAd.reset();
        }

        if (cleverapps.restoreProgress.isAvailable()) {
            this.restoreProgress = new RestoreProgressButton(this);
        }

        cc.director.setClearColor(cleverapps.styles.FixedWidthScene.clearColor || cc.color.BLACK);
    },

    startOrRestartMusic: function (styles) {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.delay || 0),
            new cc.CallFunc(function () {
                cleverapps.audio.insertDisk(styles.res);
                cleverapps.audio.fadeIn(styles.fadeIn || 0);
            })
        ));
    },

    getAudioStyles: function () {
        return cleverapps.styles.FixedWidthScene.sound;
    },

    displayIntro: function (silent) {
        cleverapps.meta.display({
            focus: "Scene.intro",
            control: this.introControls(),
            action: function (f) {
                this.playIntro(f, silent);
            }.bind(this)
        });
    },

    introControls: function () {

    },

    playIntro: function (f) {
        f();
    },

    addMenuContainer: function () {
        if (this.upMenuContainer) {
            this.upMenuContainer.removeFromParent();
            delete this.upMenuContainer;
        }

        this.upMenuContainer = new UpMenuContainer();
        this.scene.addChild(this.upMenuContainer);

        this.upMenuContainer.makeHideable();
    },

    addSideBar: function () {
        if (this.sideBar) {
            this.sideBar.removeFromParent();
            delete this.sideBar;
        }
        if (cleverapps.environment.hasScene([cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_EDITOR])) {
            var sideBarView = this.sideBar = new SideBarView();
            this.scene.addChild(sideBarView);
        }
    },

    addToolbar: function () {
        if (cleverapps.environment.isMainScene()) {
            var toolbarView = this.downToolBar = new ToolbarView();
            this.scene.addChild(toolbarView);
            toolbarView.setLocalZOrder(1);
        }
    },

    addTreasureBag: function () {
        if (this.treasureBag) {
            this.treasureBag.removeFromParent();
            this.treasureBag = undefined;
        }

        this.treasureBag = new TreasureBagIcon();
        this.treasureBag.setLocalZOrder(TreasureBagIcon.ZORDER);
        this.scene.addChild(this.treasureBag);

        this.treasureBag.updatePosition();
        this.treasureBag.hide(true);

        cleverapps.treasureBag.hide();
    },

    addSnapshotBar: function () {
        if ((cleverapps.config.debugMode && !cleverapps.config.wysiwygMode)
            && (cleverapps.environment.isGameScene() || cleverapps.environment.isMainScene())) {
            this.snapshotBar = new SnapshotBarView();
            this.snapshotBar.setLocalZOrder(1000);
            this.scene.addChild(this.snapshotBar);
        }
    },

    getBackgroundStyles: function () {
        return cleverapps.skins.getSlot("sceneBg") || cleverapps.styles.FixedWidthScene.background;
    },

    setBackground: function () {
        var options = this.backgroundStyles;

        if (!options || cleverapps.gameModes.noBg) {
            return;
        }

        if (options.patternId) {
            var winSize = cleverapps.UI.getWinSize();
            winSize.height += 2;

            this.background = new cc.Node();
            this.background.setAnchorPoint(0.5, 0.5);
            this.background.setContentSize(winSize);

            var pattern = cleverapps.UI.createPatternSprite(bundles[options.bundle].urls[options.patternId], winSize);
            this.background.addChild(pattern);
        } else if (options.horizontalPattern) {
            var layoutWidth = 0;
            var layoutItems = [];

            do {
                var bgSprite = new cc.Sprite(options.horizontalPattern);
                layoutItems.push(bgSprite);
                layoutWidth += bgSprite.width;
            } while (layoutWidth < this.scene.width || layoutItems.length < 2);

            this.background = new cleverapps.Layout(layoutItems, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: 0
            });
        } else if (options.scale9) {
            this.background = cleverapps.UI.createScale9Sprite(options.scale9, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else if (options.movableAnimation) {
            this.background = new cleverapps.Spine(options.movableAnimation);
            this.background.setAnimation(0, "animation", true);
        } else if (options.backgroundId) {
            this.background = new cc.Sprite(bundles[options.bundle].urls[options.backgroundId]);
        } else {
            return;
        }

        if (options.animation) {
            var animation = this.background.animation = new cleverapps.Spine(options.animation);
            animation.setAnimation(0, "animation", true);
            if (options.skin) {
                animation.setSkin(options.skin);
            }
            this.background.addChild(animation);
        }

        this.background.transitionBundles = cleverapps.toArray(options.bundle);

        this.updateBackgroundScale();
        this.updateBackgroundPosition();

        this.background.setLocalZOrder(-10);
        this.scene.addChild(this.background);
    },

    isReadyToResize: function () {
        return this.isAllLoaded;
    },

    updateSize: function () {
        this.scene.setContentSize2(cleverapps.UI.getSceneSize());
        this.updateBackgroundScale();
        this.updateFilmLayers();
    },

    updatePosition: function () {
        this.scene.setPositionRound(this.width / 2, this.height / 2);
        this.updateBackgroundPosition();
    },

    getBackgroundScaleCoef: function () {
        return 1;
    },

    updateBackgroundPosition: function () {
        if (this.background && !(this.backgroundStyles && this.backgroundStyles.movableAnimation)) {
            if (this.backgroundStyles && this.backgroundStyles.position) {
                this.background.setPositionRound(this.backgroundStyles.position);
                this.background.originalPosition = this.backgroundStyles.position;
            } else {
                this.background.setPosition(this.scene.width / 2, this.scene.height / 2);
            }
        }
    },

    updateBackgroundScale: function () {
        if (this.background && !(this.backgroundStyles && this.backgroundStyles.movableAnimation)) {
            var style = this.backgroundStyles;
            var scaleMargin = this.bgScaleMargin || 1;

            if (this.background instanceof cc.Scale9Sprite) {
                var size = cleverapps.UI.getWinSize();
                size.width = size.width * scaleMargin / this.scene.scale + 2;
                size.height = size.height * scaleMargin / this.scene.scale + 2;
                this.background.setContentSize2(size.width, size.height);
            } else if (typeof style.scale === "number") {
                this.background.setScale(style.scale);
            } else {
                var backgroundSize = style.size || this.background.getContentSize();
                var scale = Math.max(this.width / backgroundSize.width * scaleMargin / this.scene.scale, this.height / backgroundSize.height * scaleMargin / this.scene.scale) * this.getBackgroundScaleCoef();
                this.background.setScale(scale);
                if (cleverapps.platform.oneOf(Instant)) {
                    var realSizeHeight = backgroundSize.height / scaleMargin * scale;
                    if (realSizeHeight > this.height) {
                        this.background.y = this.height / 2 + (realSizeHeight - this.height) / 3;
                    }
                }
                this.background.stopAllActions();
            }

            if (this.background.animation) {
                this.background.animation.setPositionRound(this.background.width / 2, this.background.height / 2);
            }
        }
    },

    onTransitionAnimationDidStart: function () {
        cleverapps.audio.fadeOut(0.1);
    },

    createTransition: function () {
        if (!this.background || !this.background.transitionBundles) {
            return;
        }

        var transition = this.background;
        this.background = undefined;

        transition.replaceParentSamePlace(this, {
            keepScale: true
        });
        transition.setLocalZOrder(1000);
        return transition;
    },

    getPreferredBundles: function () {
        var bundles = this._super();
        bundles = cleverapps.substract(bundles, ["dev_resources"]);
        return bundles;
    }
});

cleverapps.FixedWidthScene.unsavedChangesHandler = function (event) {
    if (cleverapps.scenes.getRunningScene().unsavedChanges) {
        event.preventDefault();
        event.returnValue = "Please save changes";
        return false;
    }

    return true;
};

cleverapps.FixedWidthScene.prototype.updateFilmLayers = function () {
    if (this.upperFilmLayer) {
        this.upperFilmLayer.width = this.width + 2;
        this.upperFilmLayer.setPositionRound(-1, this.height - this.upperFilmLayer.height + 2);
    }

    if (this.downFilmLayer) {
        this.downFilmLayer.width = this.width + 2;
        this.downFilmLayer.setPositionRound(-1, -1);
    }
};

cleverapps.FixedWidthScene.prototype.createFilmEffect = function (options) {
    options = options || {};
    options.layerSizeKoef = options.layerSizeKoef !== undefined ? options.layerSizeKoef : 0.1;
    options.layerTimeout = options.layerTimeout || 0.3;
    options.onComplete = options.onComplete || function () {};

    this.scene.stopAllActions();
    var targetPosition = this.scene.getPosition();

    options.scale = options.scale || 1.3;
    var createLayer = function () {
        var layer = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        layer.setAnchorPoint(0, 0);
        layer.setContentSize2(this.scene.width, this.scene.height * options.layerSizeKoef + (options.layerSizeKoef ? 3 : 0));
        layer.setColor(cc.color.BLACK);
        return layer;
    }.bind(this);
    this.upperFilmLayer = createLayer();
    this.downFilmLayer = createLayer();

    if (options.moveBy) {
        if (options.moveBy.x) {
            targetPosition.x += options.moveBy.x;
        }

        if (options.moveBy.y) {
            var mh;
            if (options.moveBy.y < 0) {
                mh = this.upperFilmLayer.height + this.scene.height * (options.scale - 1) / 2;
                if (options.moveBy.y < -mh) {
                    options.moveBy.y = -mh;
                }
            } else {
                mh = this.downFilmLayer.height + this.scene.height * (options.scale - 1) / 2;
                if (options.moveBy.y > mh) {
                    options.moveBy.y = mh;
                }
            }
            targetPosition.y += options.moveBy.y;
        }
    }

    this.downFilmLayer.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 1);
    this.addChild(this.downFilmLayer);

    this.upperFilmLayer.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 1);
    this.addChild(this.upperFilmLayer);

    this.updateFilmLayers();

    if (!options.silent) {
        options.scaleTimeout = options.scaleTimeout || 1;
        this.upperFilmLayer.setOpacity(0);
        this.downFilmLayer.setOpacity(0);
        this.scene.runAction(new cc.Sequence(
            new cc.DelayTime(options.layerTimeout),
            new cc.Spawn(
                new cc.MoveTo(options.scaleTimeout, targetPosition),
                new cc.ScaleTo(options.scaleTimeout, options.scale)
            ),
            new cc.CallFunc(options.onComplete)
        ));
        this.upperFilmLayer.runAction(new cc.FadeTo(options.layerTimeout, 255));
        this.downFilmLayer.runAction(new cc.FadeTo(options.layerTimeout, 255));
    } else {
        this.scene.setScale(options.scale);
        this.scene.setPositionRound(targetPosition);
        options.onComplete();
    }

    if (!options.noControls) {
        cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function () {
                return true;
            },

            onTouchEnded: function () {
                if (options.onClick) {
                    options.onClick();
                }
                return true;
            }
        }, this.upperFilmLayer);

        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseScroll: function (event) {
                if (event && event.stopPropagation) {
                    event.stopPropagation();
                }
                return true;
            },
            onMouseMove: function (event) {
                if (event && event.stopPropagation) {
                    event.stopPropagation();
                }
                return true;
            }
        }, this.upperFilmLayer);
    }
};

cleverapps.FixedWidthScene.prototype.removeFilmEffect = function (options) {
    options = options || {};
    options.scaleTimeout = options.scaleTimeout === undefined ? 1 : options.scaleTimeout;
    options.layerTimeout = options.layerTimeout || 0.3;

    this.scene.stopAllActions();
    this.stopAllActions();

    this.scene.runAction(new cc.Spawn(
        new cc.MoveTo(options.scaleTimeout, cc.p(this.width / 2, this.height / 2)),
        new cc.ScaleTo(options.scaleTimeout, 1)
    ));
    this.runAction(new cc.Sequence(new cc.DelayTime(options.scaleTimeout), new cc.CallFunc(function () {
        this.scene.setScale(1);
        this.scene.setPositionRound(cc.p(this.width / 2, this.height / 2));
        if (options.onComplete) {
            options.onComplete();
        }
    }.bind(this))));

    var hideAnimation;
    if (options.scaleLayerSameTime) {
        options.layerTimeout = options.scaleTimeout;
        options.scaleTimeout = 0;
    }
    if (this.upperFilmLayer) {
        this.upperFilmLayer.stopAllActions();
        if (options.layerSlide) {
            hideAnimation = new cc.MoveBy(options.layerTimeout, { x: 0, y: this.upperFilmLayer.height });
        } else {
            hideAnimation = new cc.FadeTo(options.layerTimeout, 0);
        }
        this.upperFilmLayer.runAction(new cc.Sequence(new cc.DelayTime(options.scaleTimeout), hideAnimation, new cc.RemoveSelf()));
        this.upperFilmLayer = undefined;
    }
    if (this.downFilmLayer) {
        this.downFilmLayer.stopAllActions();
        if (options.layerSlide) {
            hideAnimation = new cc.MoveBy(options.layerTimeout, { x: 0, y: -this.downFilmLayer.height });
        } else {
            hideAnimation = new cc.FadeTo(options.layerTimeout, 0);
        }
        this.downFilmLayer.runAction(new cc.Sequence(new cc.DelayTime(options.scaleTimeout), hideAnimation, new cc.RemoveSelf()));
        this.downFilmLayer = undefined;
    }
};

cleverapps.styles.FixedWidthScene = {
    sound: {
        res: bundles.main.urls.background_music_map,
        delay: 0.5
    }
};
