/**
 * Created by slava on 3/28/17.
 */

cleverapps.Settings = function () {
    cleverapps.EventEmitter.call(this);

    this.onChangeSoundListener = function () {
    };

    this.onChangeMusicListener = function () {
    };

    this.load();
};

cleverapps.Settings.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Settings.prototype.constructor = cleverapps.Settings;

cleverapps.Settings.castLanguage = function (lang) {
    if (lang === cc.sys.LANGUAGE_DUTCH) {
        return "nl";
    }
    return lang;
};

cleverapps.Settings.getDefaultLanguage = function () {
    var languages = cleverapps.config.languages;
    if (languages.length === 1) {
        return languages[0];
    }

    var userLang = cleverapps.Settings.castLanguage(cleverapps.social.getSuggestedLanguage());
    if (languages.indexOf(userLang) !== -1) {
        return userLang;
    }

    userLang = cleverapps.Settings.castLanguage(cc.sys.language);
    if (languages.indexOf(userLang) === -1 || userLang === cc.sys.LANGUAGE_ENGLISH) {
        var defaultLanguages = cleverapps.platform.getSuggestedLanguage();
        for (var i = 0; i < defaultLanguages.length; i++) {
            var defaultLanguage = defaultLanguages[i];
            if (languages.indexOf(defaultLanguage) !== -1) {
                return defaultLanguage;
            }
        }
        return cc.sys.LANGUAGE_ENGLISH;
    }

    return userLang;
};

cleverapps.Settings.prototype.getSaveInfo = function () {
    return {
        sound: this.sound,
        music: this.music,
        language: this.language
    };
};

cleverapps.Settings.prototype.save = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.SETTINGS, this.getSaveInfo());
    cleverapps.synchronizer.addUpdateTask("settings");
};

cleverapps.Settings.prototype.loadData = function (info) {
    if (info === undefined) {
        this.sound = true;
        this.music = true;
        this.language = cleverapps.Settings.getDefaultLanguage();
        this.save();
    } else {
        this.sound = info.sound;
        this.music = info.music;
        this.language = info.language;
        this.manualResolution = info.manualResolution;
        if (this.manualResolution) {
            this.resolution = this.manualResolution;
        }
    }

    if (this.language === undefined) {
        this.language = cleverapps.Settings.getDefaultLanguage();
    }
};

cleverapps.Settings.prototype.load = function () {
    var info = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.SETTINGS);
    this.loadData(info);
};

cleverapps.Settings.prototype.toggleSound = function () {
    this.sound = !this.sound;

    this.save();
    this.onChangeSoundListener();
};

cleverapps.Settings.prototype.toggleMusic = function () {
    this.music = !this.music;
    cleverapps.audio.onChangeMusic();

    this.save();
    this.onChangeMusicListener();
};

cleverapps.Settings.prototype.setLanguage = function (language) {
    this.language = language;
    this.save();
};

cleverapps.Settings.prototype.updateDependents = function () {
    if (this.language === cc.sys.LANGUAGE_RUSSIAN) {
        if (!cleverapps.dailyLevel.currentEvent) {
            cleverapps.dailyLevel.load();
        }
    }

    if (cleverapps.config.type === "board") {
        cleverapps.episodes.reset();
        cleverapps.hose && cleverapps.hose.reset();
    }
};

cleverapps.Settings.calcHeight = function () {
    var height = cc.view.getFrameSize().height;

    if (window.screen && window.screen.height) {
        height = Math.max(height, window.screen.height);
    }

    if (cleverapps.platform instanceof Mobage) {
        height = cleverapps.MobageSocial.HEIGHT;
    }

    return height;
};

cleverapps.Settings.prototype.calcResolution = function () {
    // console.log("calcResolution", this.resolution, cleverapps.config.webpSupport, new Error().stack);
    if (this.resolution !== undefined && resolutions[this.resolution]) {
        return this.resolution;
    }

    this.resolution = cleverapps.Settings.calcResolution();

    return this.resolution;
};

cleverapps.Settings.calcResolution = function () {
    var devicePixelRatio = Math.min(2, window.devicePixelRatio || 1);

    var height = cleverapps.Settings.calcHeight();
    var pixelSizeHeight = height * devicePixelRatio;

    var names = Object.keys(resolutions);
    var webpNames = names.filter(function (name) {
        return resolutions[name].webp;
    });

    if (webpNames.length > 0) {
        if (cleverapps.config.webpSupport) {
            names = webpNames;
        } else if (webpNames.length < names.length) {
            names = cleverapps.substract(names, webpNames);
        }
    }

    names = names.sort(function (a, b) {
        return resolutions[a].scale - resolutions[b].scale;
    });

    var calcResValue = function (resolution) {
        var h = resolution.scale * cleverapps.styles.SCENE_HEIGHT;
        return h >= pixelSizeHeight ? (h - pixelSizeHeight) : ((pixelSizeHeight - h) * 100);
    };

    var bestId = 0;
    var bestDiff = calcResValue(resolutions[names[bestId]]);

    for (var i = 1; i < names.length; i++) {
        var diff = calcResValue(resolutions[names[i]]);

        if (diff < bestDiff) {
            bestDiff = diff;
            bestId = i;
        }
    }

    return names[bestId];
};

cleverapps.Settings.prototype.setManualResolution = function (manualResolution) {
    this.manualResolution = manualResolution;
    var info = this.getSaveInfo();
    info.manualResolution = this.manualResolution;
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.SETTINGS, info);
    cleverapps.DataLoader.processSaveQueue();

    window.location.reload();
};
