/**
 * Created by iamso on 21.11.2020.
 */

var AdminDetailControls = cleverapps.Layout.extend({
    ctor: function () {
        this.createActionButtons();
        this.createNavigationButtons();

        this._super([this.navigationButtons, this.actionButtons], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminDetailControls.rowMargin
        });
    },

    createButton: function (data) {
        var styles = cleverapps.styles.AdminDetailControls;

        var icon = new cc.Sprite(data.icon);
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: icon,
            width: styles.width,
            height: styles.height,
            onClicked: data.action,
            hint: data.hint
        });
        button.icon = icon;
        return button;
    },

    createActionButtons: function () {
        var buttons = this.createDetailsButtons();

        buttons.push(this.createButton({
            icon: bundles.admin.frames.play_icon,
            hint: "Play level",
            action: function () {
                cleverapps.meta.display({
                    focus: "AdminPlayLevel",
                    action: function (f) {
                        LevelManager.getInstance().playLevel(f);
                    }
                });
            }
        }));

        if (EditorScene.HasEditor()) {
            buttons.push(this.createButton({
                icon: bundles.admin.frames.edit_icon,
                hint: "Edit level",
                action: function () {
                    cleverapps.administrator.adminLevels.getActive().editLevel();
                }
            }));
        }

        if (["differences"].indexOf(cleverapps.config.name) === -1) {
            buttons.push(this.createButton({
                icon: bundles.admin.frames.delete_icon,
                hint: "Delete level",
                action: function () {
                    var active = cleverapps.administrator.adminLevels.getActive();
                    if (!active && active.isAddMoreLevel()) {
                        return;
                    }

                    cleverapps.meta.display({
                        focus: "deleteLevel",
                        action: function (f) {
                            if (confirm("Delete level " + active.getHumanNumber() + "?")) {
                                cleverapps.administrator.adminLevels.removeActive(f);
                            } else {
                                f();
                            }
                        }
                    });
                }
            }));
        }

        buttons.push(this.createButton({
            icon: bundles.admin.frames.clear_icon,
            hint: "Reset saved game",
            action: function () {
                var level = LevelManager.getInstance().level;
                cleverapps.GameSaver.removeSave(cleverapps.GameSaver.getStoreSlot(level.episodeNo, level.levelNo));
            }
        }));

        this.actionButtons = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminDetailControls.colMargin
        });
    },

    createNavigationButtons: function () {
        var buttons = ["left_double", "left", "right", "right_double"].map(function (key, index) {
            return this.createButton({
                icon: bundles.admin.frames[key],
                hint: ["Previous episode", "Previous level", "Next level", "Next episode"][index],
                action: function () {
                    cleverapps.meta.display({
                        focus: "AdminNavigation",
                        action: AdminNavigation[key]
                    });
                }
            });
        }.bind(this));

        this.navigationButtons = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminDetailControls.colMargin
        });
    },

    createDetailsButtons: function () {
        var makeCurrentButton = this.makeCurrentButton = this.createButton({
            icon: bundles.admin.frames.current_icon,
            hint: "Make current",
            action: function () {
                cleverapps.meta.display({
                    focus: "MakeCurrent",
                    action: function (f) {
                        var active = cleverapps.administrator.adminLevels.getActive();
                        if (!cleverapps.isNumber(active.episodeNo)) {
                            cleverapps.notification.create("Can set current only number episode");
                            f();
                            return;
                        }

                        cleverapps.administrator.adminLevels.makeCurrent(active);
                        cleverapps.administrator.adminLevels.update();
                        cleverapps.administrator.adminEpisodes.updateCurrent();
                        makeCurrentButton.updateIcon();
                        f();
                    }
                });
            }
        });
        makeCurrentButton.updateIcon = function () {
            var episode = cleverapps.administrator.adminEpisodes.getActive();
            if (cleverapps.isNumber(episode.episodeNo)) {
                makeCurrentButton.enable();
            } else {
                makeCurrentButton.disable();
            }

            if (cleverapps.administrator.adminLevels.getActive().isCurrent()) {
                makeCurrentButton.icon.setColor(new cc.Color(36, 255, 46, 255));
            } else {
                makeCurrentButton.icon.setColor(new cc.Color(255, 255, 255, 255));
            }

            cleverapps.UI.convertToGrayScale(makeCurrentButton.icon, !makeCurrentButton.disabled);
        };
        var hardButton = this.createButton({
            icon: bundles.admin.frames.hard_icon,
            hint: "Toggle hard",
            action: function () {
                cleverapps.administrator.adminLevels.getActive().toggleHard();
            }
        });
        hardButton.updateIcon = function () {
            var level = cleverapps.administrator.adminLevels.getActive();
            cleverapps.UI.convertToGrayScale(hardButton.icon, level.isHard());
        };
        this.hardButton = hardButton;

        var buttons = [makeCurrentButton, hardButton];

        if (cleverapps.config.subtype === "stacks") {
            var trickyButton = this.createButton({
                icon: bundles.admin.frames.tricky_level_mark,
                hint: "Toggle tricky",
                action: function () {
                    cleverapps.administrator.adminLevels.getActive().toggleTricky();
                }
            });
            trickyButton.updateIcon = function () {
                var level = cleverapps.administrator.adminLevels.getActive();
                cleverapps.UI.convertToGrayScale(trickyButton.icon, level.isTricky());
            };
            this.trickyButton = trickyButton;

            buttons.push(this.trickyButton);
        }

        return buttons;
    },

    reset: function (adminLevel) {
        if (adminLevel === undefined) {
            this.actionButtons.setVisible(false);
            return;
        }

        this.actionButtons.setVisible(true);

        this.hardButton.updateIcon();
        adminLevel.on("change:hard", function () {
            this.hardButton.updateIcon();
        }.bind(this), this);

        if (this.trickyButton) {
            this.trickyButton.updateIcon();
            adminLevel.on("change:tricky", function () {
                this.trickyButton.updateIcon();
            }.bind(this), this);
        }

        this.makeCurrentButton.updateIcon();
    }
});

cleverapps.styles.AdminDetailControls = {
    height: 60,
    width: 60,
    colMargin: 5,
    rowMargin: 10
};