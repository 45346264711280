/**
 * Created by spepa on 10/06/20
 */

cleverapps.SkinManager.SKINS.halloween = {
    bundle: "halloween",
    localization: {
        heroes: {
        },
        scramble: {
            "Missions.LetterTournament.Title": "Halloween.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Halloween.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Halloween.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Halloween.MissionRulesWindow.LetterTournament.text3"
        },
        wordsoup: {
        },
        tripeaks: {
            "CloversTooltip": "Halloween.CloversTooltip",
            "Missions.LetterTournament.Title": "Halloween.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Halloween.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Halloween.MissionRulesWindow.LetterTournament.text3",
            "AddMovesWindow.missionGiveUp.LetterTournament": "Halloween.AddMovesWindow.missionGiveUp.LetterTournament",
            "AddMovesWindow.mission.LetterTournament": "Halloween.AddMovesWindow.mission.LetterTournament"
        },
        differences: {
        },
        riddles: {
        }
    },
    slots: {
        localization: function (code) {
            var localization = cleverapps.SkinManager.SKINS.easter.localization;
            return localization && localization[cleverapps.config.name] && localization[cleverapps.config.name][code];
        },
        windowDecors: function (options) {
            var decors = {};

            if (["wordsoup"].indexOf(cleverapps.config.name) !== -1 && [VictoryWindow].indexOf(options.window.constructor) !== -1) {
                decors.web_bottom = true;
            } else {
                decors.web_top = true;
                decors.web_bottom = true;
                decors.pumpkin_animation = true;
            }

            if (["wordsoup"].indexOf(cleverapps.config.name) !== -1 && [ExtraWindow].indexOf(options.window.constructor) !== -1) {
                decors.web_top = false;
            }

            if ([MiniGameWindow].indexOf(options.window.constructor) !== -1) {
                decors.web_top = false;
                decors.web_bottom = false;
            }

            if (["olympics", "differences"].indexOf(cleverapps.config.name) !== -1 && [KnockoutWindow].indexOf(options.window.constructor) !== -1) {
                decors.pumpkin_animation = false;
            }

            if ([PiggyBankWindow, GuideWindow, DailyTasksWindow, LeadersWindow].indexOf(options.window.constructor) !== -1) {
                decors.forest = true;
                decors.roots_top_left = true;
                decors.roots_top_right = true;
                decors.owls_animation = true;
                decors.pumpkin_animation = false;
                decors.web_top = false;
            }

            if ([GuideWindow].indexOf(options.window.constructor) !== -1 && cleverapps.wideMode.mode !== cleverapps.WideMode.VERTICAL) {
                decors.forest = false;
                decors.owls_animation = false;
            }

            if ([RestartWindow, LevelStartWindow, LoseWindow, SettingsWindow].indexOf(options.window.constructor) !== -1) {
                decors.pumpkins = true;
                decors.candle_animation = true;
                decors.pumpkin_animation = false;
                decors.web_top = false;
            }

            if (typeof QuestWindow !== "undefined" && options.window.constructor === QuestWindow) {
                decors.pumpkin_animation = false;
            }

            if (typeof BuildingStartWindow !== "undefined" && options.window.constructor === BuildingStartWindow) {
                decors.web_top = false;
            }

            if ([RulesWindow].indexOf(options.window.constructor) !== -1) {
                decors.pumpkin_animation = false;
            }

            if (cleverapps.config.name === "differences") {
                if ([MissionWindow].indexOf(options.window.constructor) !== -1) {
                    decors.pumpkin_animation = false;
                }
            }

            if (cleverapps.config.name === "tripeaks") {
                if ([MissionWindow].indexOf(options.window.constructor) !== -1) {
                    decors.web_top = false;
                }

                if ([VictoryWindow].indexOf(options.window.constructor) !== -1) {
                    decors.web_top = false;
                    decors.pumpkin_animation = false;
                }

                if (options.window instanceof RulesWindow) {
                    decors.pumpkin_animation = false;
                }
            }

            if (cleverapps.config.name === "scramble") {
                if ([BackgroundsWindow].indexOf(options.window.constructor) !== -1) {
                    decors.web_bottom = false;
                }

                if (options.window instanceof RulesWindow) {
                    decors.pumpkin_animation = false;
                }

                if ([DailyCupLeadersWindow, CryptexWindow, BackgroundsWindow, VictoryWindow].indexOf(options.window.constructor) !== -1) {
                    decors.pumpkin_animation = false;
                }

                if ([DailyTasksWindow, LevelStartWindow, SettingsWindow].indexOf(options.window.constructor) !== -1) {
                    decors.pumpkin_animation = true;
                }
            }

            return decors;
        },
        missionIcon: function (options) {
            if (["scramble", "tripeaks"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.halloween.frames.mission_letter_png;
            }
        },
        missionStar: function (options) {
            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.halloween.frames["mission_star_" + options.type + "_png"];
            }

            if (["tripeaks"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.halloween.frames.mission_letter_png;
            }
        },
        missionRulesWindow: function (options) {
            if (["tripeaks", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.halloween.jsons.letter_rules_json;
            }
        },
        spine: function (options) {
            var spines = {};
            if (["scramble", "tripeaks"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.halloween.jsons.halloween_mission_window_json;
                spines[bundles.sidebar.jsons.mission_letter_icon_json] = bundles.sidebar.jsons.halloween_letter_json;
            }

            return spines[options.json];
        },
        cardMarkSpine: function (options) {
            if (["tripeaks"].indexOf(cleverapps.config.name) !== -1 && options.type === "mission") {
                return bundles.halloween.jsons.halloween_marks_json;
            } 
            return options.json;
        }

    }
};
