/**
 * Created by mac on 1/27/21
 */

var Loop = function () {
    this.data = {
        games: 0,
        wins: 0,
        highscore: 0,
        besttime: 0,
        bestmoves: 0
    };

    this.load();
};

Loop.prototype.isMemorableGame = function (score) {
    return !cleverapps.gameModes.alwaysNoMemorable && this.data.games > 1 && score >= Math.min(200, this.data.highscore * 0.5);
};

Loop.prototype.isSuccessfullGame = function (score) {
    return score > this.data.highscore;
};

Loop.prototype.gameStarted = function () {
    this.data.games++;
    this.save();
};

Loop.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        this.data.wins++;
    }

    var score = game.score.getPoints();
    if (score > this.data.highscore) {
        this.data.highscore = score;
    }

    var time = game.timer.getInGameTime();
    if (cleverapps.config.type === "klondike") {
        if (time < this.data.besttime && outcome === GameBase.OUTCOME_VICTORY) {
            this.data.besttime = time;
        }
    } else if (time > this.data.besttime) {
        this.data.besttime = time;
    }

    if (cleverapps.config.type === "klondike") {
        if (game.moves < this.data.bestmoves && outcome === GameBase.OUTCOME_VICTORY) {
            this.data.bestmoves = game.moves;
        }
    } else if (game.moves > this.data.bestmoves) {
        this.data.bestmoves = game.moves;
    }

    this.save();
};

Loop.prototype.getInfo = function () {
    return this.data;
};

Loop.prototype.isFirstLoopPass = function () {
    return this.data.highscore === 0;
};

Loop.prototype.updateInfo = function (serverData) {
    this.load(serverData);
    this.save(true);
};

Loop.prototype.load = function (data) {
    data = data || cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.LOOP);
    if (data) {
        this.data = data;
    }
};

Loop.prototype.save = function (fromServer) {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.LOOP, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};
