/**
 * Created by spepa on 20.12.2022
 */

var PeriodicSaleWindow = Window.extend({
    onWindowLoaded: function (mission) {
        this.logic = mission.logic;

        this._super({
            title: this.logic.isBonusSale() ? false : "PeriodicSaleWindow.line1",
            name: "PeriodicSaleWindow",
            noBackground: true,
            content: this.createContent(),
            openSound: this.logic.isBonusSale() ? bundles.periodic_sale.urls.bonussale_showup_effect : bundles.periodic_sale.urls.showup_effect
        });

        this.updateState(true);
        if (this.logic.isBonusSale()) {
            this.addChild(new Fireworks(), -1);
        }

        cleverapps.meta.hideAllControls();
        cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem", "MenuBarLivesItem"]);
    },

    getPerson: function () {
        var sale = cleverapps.missionManager.findByType(Mission.TYPE_PERIODIC_SALE);
        if (sale.logic.isBonusSale()) {
            return "bonussale_person";
        }
        return "warlock";
    },

    createContent: function () {
        var styles = cleverapps.styles.PeriodicSaleWindow;

        var lotsNode = new cleverapps.Layout(this.createLots(), {
            direction: this.logic.isBonusSale() ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL,
            margin: this.logic.isBonusSale() ? styles.lotsMargin.x : styles.lotsMargin.y,
            padding: styles.lotsPadding
        });

        var items = [lotsNode, this.createFooter()].filter(Boolean);
        this.content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL
        });

        this.createBadge();
        this.createBg();
        this.createCustomTitle();
        this.createTimer();
        return this.content;
    },

    createBg: function () {
        if (this.logic.isBonusSale()) {
            var bg = new cleverapps.Spine(bundles.periodic_sale.jsons.bonussale_bg);
            bg.setAnimation(0, "animation", true);
            bg.addChild(this.content);
            this.content.setPositionRound(cleverapps.styles.PeriodicSaleWindow.content);
            this.content = bg;
        }
    },

    createCustomTitle: function () {
        if (!this.logic.isBonusSale()) {
            return;
        }

        var styles = cleverapps.styles.PeriodicSaleWindow.content.title;
        var title = cleverapps.UI.generateOnlyText("PeriodicSaleWindow.title", cleverapps.styles.FONTS.BONUSBG_TITLE);
        title.fitTo(styles.width);

        var wrapper = new cc.Node();
        wrapper.setContentSize2(styles.width, styles.height);
        wrapper.setPositionRound(styles);
        wrapper.addChild(title);
        title.setPositionRound(wrapper.width / 2, wrapper.height / 2);
        this.content.addChild(wrapper);
    },

    createTimer: function () {
        if (!this.logic.isBonusSale()) {
            return;
        }

        var styles = cleverapps.styles.PeriodicSaleWindow.content.timer;

        var timeLeft = this.logic.mission.getTimeLeft();
        if (timeLeft > 0) {
            timeLeft = new cleverapps.CountDown(timeLeft);
        }

        var countdown = new cleverapps.CountDownView(timeLeft, {
            font: cleverapps.styles.FONTS.BONUS_SALE_TIMER_TEXT
        });

        countdown.setPositionRound(styles);
        this.content.addChild(countdown);
    },

    createLots: function () {
        var LotClass = this.logic.isBonusSale() ? BonusSaleLot : PeriodicSaleLot;
        return this.logic.getLots().map(function (lot, ind) {
            var view = new LotClass(lot, this.logic);
            view.setLocalZOrder(-ind);
            return view;
        }.bind(this));
    },

    createBadge: function () {
        if (this.logic.isBonusSale()) {
            return;
        }

        var badge = new TileBadge({
            rotation: 20,
            text: "-75%",
            largeFont: true
        });
        badge.setPositionRound(cleverapps.styles.PeriodicSaleWindow.badge);
        this.content.addChild(badge);
    },

    onClose: function () {
        if (this.logic.isAllSold()) {
            cleverapps.missionManager.remove(this.logic.mission);
            if (!this.logic.isBonusSale()) {
                new RewardWindow(RewardsConfig.PeriodicSale);
            }
        }
    },

    onShow: function () {
        this._super();
        this.updateState();
    },

    updateState: function (silent) {
        if (!this.bar) {
            return;
        }

        var lots = this.logic.getLots();
        var sold = this.logic.countSoldLots();
        var progress = Math.round(sold / lots.length * 100);

        var curText = sold + "/" + lots.length;
        if (curText !== this.bar.barText.getString()) {
            if (silent) {
                this.bar.updateBarText(curText);
                this.bar.setPercentage(progress);
                return;
            }

            var callback = function () {
                if (this.logic.isAllSold()) {
                    this.close();
                }
            }.bind(this);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.4),
                new cc.CallFunc(function () {
                    this.bar.updateBarText(curText);
                    this.bar.animatedChange(progress, { callback: callback });
                }.bind(this))
            ));
        }
    },

    createFooter: function () {
        if (this.logic.isBonusSale()) {
            return;
        }

        var styles = cleverapps.styles.PeriodicSaleWindow.footer;
        var txt = cleverapps.UI.generateTTFText("PeriodicSaleWindow.footer2", cleverapps.styles.FONTS.PERIODIC_FOOTER);
        txt.setDimensions(styles.text.width, 0);
        txt.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var bar = this.bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_red_dailytask,
            background: bundles.progress_bar.frames.bg_dailytask,
            barText: {
                text: "",
                font: cleverapps.styles.FONTS.DAILY_TASK_PROGRESS_FONT
            }
        });
        bar.setLength(styles.bar.width);

        var prize = new cc.Sprite(bundles.prize.frames.big_prize_png);
        cleverapps.UI.fitToBox(prize, styles.bar.prize);
        prize.setPositionRound(styles.bar.prize);
        bar.addChild(prize);

        cleverapps.tooltipManager.create(prize, {
            rewards: RewardsConfig.PeriodicSale,
            text: Messages.get("PeriodicSaleWindow.tooltip"),
            position: cleverapps.styles.UI.Tooltip.LOCATION.above
        });

        return new cleverapps.Layout([bar, txt], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    listBundles: function () {
        return ["periodic_sale"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUSBG_TITLE: {
        size: 60,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    PERIODIC_LOT_AMOUNT: {
        name: "default",
        size: 65,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PERIODIC_LOT_WAS: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PERIODIC_FOOTER: {
        name: "default",
        size: 37,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    VKDOG_DIALOGUE: {
        name: "nostroke",
        size: 29,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BONUS_SALE_TIMER_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.PeriodicSaleWindow = {
    lotsMargin: {
        y: 65,
        x: 50
    },

    lotsPadding: {
        top: 40,
        bottom: 45
    },

    content: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },

        title: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -8 },
            width: 440,
            height: 70
        },

        timer: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -122 }
        }
    },

    badge: {
        x: { align: "right", dx: 45 },
        y: { align: "top", dy: 40 }
    },

    footer: {
        margin: 20,

        padding: {
            top: 20
        },

        bar: {
            width: 500,
            prize: {
                width: 120,
                height: 110,
                maxScale: 1.3,
                x: { align: "right", dx: 15 },
                y: { align: "center", dy: 10 }
            }
        },

        text: {
            width: 1100
        }
    },

    vkDog: {
        minWidth: 330,
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: 100 },

        text: {
            width: 240,
            height: 205,
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: 120 }
        }
    }
};