/**
 * Created by Andrey Popov on 3/16/21.
 */

var DialogueView = Window.extend({
    onWindowLoaded: function (dialogue, options) {
        this.options = options || {};
        this.options.button = this.options.button || {};

        this.dialogue = dialogue;
        var styles = cleverapps.styles.DialogueView;

        this._super({
            name: "DialogueViewWindow",
            closeButton: false,
            closeByBackButton: false,
            noBackground: true,
            minHeight: styles.height,
            styles: cleverapps.overrideStyles(styles.Window, this.options.window, true),
            noScaleAdjustment: true,
            openSound: bundles.dialogues.urls.dialogue_effect
        });

        this.setVisible(false);
        this.generateContent();
        this.updateSize();
        this.updatePosition();

        dialogue.on("showUp", this.showUp.bind(this), this);
        dialogue.on("close", this.close.bind(this), this);

        dialogue.on("addPerson", this.addPerson.bind(this), this);
        dialogue.on("hidePerson", this.hidePerson.bind(this), this);
        dialogue.on("updateActive", this.updateActive.bind(this), this);
        dialogue.on("updatePersonTitle", this.updatePersonTitle.bind(this), this);
        dialogue.on("changeText", this.changeText.bind(this), this);
        dialogue.on("updateButton", this.updateButton.bind(this), this);
        dialogue.on("finishTyping", this.byLetterAnimationFinish.bind(this), this);

        dialogue.run();
    },

    updateSize: function () {
        var styles = cleverapps.styles.DialogueView;
        this.contentWrap.setContentSize(cleverapps.UI.getSceneSize());
        this.content.setContentSize2(this.contentWrap.width - 2 * styles.widthPadding, styles.height);
    },

    updatePosition: function () {
        var styles = cleverapps.styles.DialogueView;
        var pos = styles.position;
        pos = cc.p(cleverapps.clone(pos.x), cleverapps.clone(pos.y));
        pos.y.dy -= cleverapps.UI.getSceneOffsetY();
        this.contentWrap.setPositionRound(pos);
        this.content.setPositionRound(styles.widthPadding, styles.offsetY);

        [this.leftPersonView, this.rightPersonView].forEach(function (person) {
            if (!person) {
                return;
            }
            var y = styles.persons.aboveСontent ? this.content.y + this.content.height : 0;
            if (styles.persons.aboveСontent) {
                var dy = cleverapps.styles.WindowPersons.position.dy[person.role] || 0;
                y += dy;
            }
            if (person.getOrientation() === "left") {
                var leftX = person.calculateCoordinate(styles.persons.left.x, "x", this.contentWrap.width);
                person.setPositionRound(Math.min(leftX, this.content.width / 2 - styles.persons.padding / 2 - person.width / 2), y);
            } else {
                var rightX = person.calculateCoordinate(styles.persons.right.x, "x", this.contentWrap.width);
                person.setPositionRound(Math.max(rightX, this.content.width / 2 + styles.persons.padding / 2 + person.width / 2), y);
            }
        }.bind(this));
    },

    generateContent: function () {
        var content = this.content = cleverapps.UI.createScale9Sprite(bundles.dialogues.frames.bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        content.setAnchorPoint2(0, 0);
        content.setCascadeOpacityEnabled(true);

        var contentWrap = this.contentWrap = new cc.Node();
        contentWrap.setAnchorPoint(0, 0.5);
        contentWrap.addChild(content);

        cleverapps.UI.onClick(contentWrap, function () {
            this.dialogue.screenClicked();
            return true;
        }.bind(this), {
            ignoreScale: true
        });
        this.contentWrap.setContentSize(cleverapps.UI.getSceneSize());
        this.addChild(contentWrap);
    },

    showUp: function (silent, onShow) {
        var animationStyle = cleverapps.styles.DialogueView.showAnimation;
        if (silent) {
            this.setVisible(true);
            onShow();
            return;
        }

        if (animationStyle === "fromBelow") {
            this.showUpFromBelow(onShow);
        } else {
            this.showUpFromSides(onShow);
        }
    },

    showUpFromSides: function (onShow) {
        var baseContentSize = this.content.getContentSize();
        this.content.setContentSize(baseContentSize.width * 0.6, baseContentSize.height);
        this.content.setOpacity(0);
        if (this.textBgArrow) {
            this.textBgArrow.setVisible(false);
        }

        [this.leftPersonView, this.rightPersonView].forEach(function (person) {
            if (!person) {
                return;
            }
            var basePosition = person.getPosition();
            var startPosition;
            if (person.getOrientation() === "left") {
                startPosition = cc.p(-person.width / 2, basePosition.y);
            } else {
                startPosition = cc.p(this.contentWrap.width + person.width / 2, basePosition.y);
            }
            var delay = person.isActive ? 0 : 0.25;
            person.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    person.setPosition(startPosition);
                }),
                new cc.DelayTime(delay),
                new cc.MoveTo(0.25, basePosition).easing(cc.easeBackOut())
            ));
        }.bind(this));
        this.setVisible(true);

        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.25),
            new cc.Spawn(
                new cc.FadeTo(0.25, 255),
                new Scale9SpriteResizeTo(0.25, baseContentSize)
            ),
            new cc.CallFunc(function () {
                onShow();
                this.updateSize();
                this.updatePosition();
                if (this.textBgArrow) {
                    this.textBgArrow.setVisible(true);
                }
            }.bind(this))
        ));
    },

    showUpFromBelow: function (onShow) {
        var styles = cleverapps.styles.DialogueView;
        var basePosition = this.content.getPosition();
        var startPosition = cc.p(basePosition.x, -this.content.height);
        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.delay || 0),
            new cc.CallFunc(function () {
                this.content.setPositionRound(startPosition);
                this.setVisible(true);
            }.bind(this)),
            new cc.MoveTo(0.2, basePosition.x, basePosition.y + styles.showUp.offset),
            new cc.MoveTo(0.1, basePosition),
            new cc.CallFunc(function () {
                onShow();
                this.updatePosition();
            }.bind(this))
        ));
        [this.leftPersonView, this.rightPersonView].forEach(function (person) {
            if (!person) {
                return;
            }
            var dy = styles.persons.dy[person.role] || 0;
            var basePersonPosition = person.getPosition();
            person.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    person.setPosition(basePersonPosition.x, startPosition.y + this.content.height + dy);
                }.bind(this)),
                new cc.DelayTime(this.options.delay || 0),
                new cc.MoveTo(0.2, cc.p(basePersonPosition.x, basePersonPosition.y + styles.showUp.offset)),
                new cc.MoveTo(0.1, basePersonPosition.x, basePersonPosition.y)
            ));
        }.bind(this));
    },

    addPerson: function (person) {
        var role = Person.ParseOptions(person).role;
        if (!PersonsLibrary.getPerson(role)) {
            cleverapps.throwAsync("Person is undefined - " + role);
            return;
        }

        var personView = new Person(person);
        personView.setAnchorPoint2(0.5, 0);
        personView.setActive(person.active);

        this.contentWrap.addChild(personView);

        if (personView.getOrientation() === "left") {
            this.leftPersonView = personView;
        } else {
            this.rightPersonView = personView;
        }
        this.updatePosition();
    },

    updateActive: function (orientation) {
        var isLeft = orientation === "left";

        if (this.leftPersonView) {
            this.leftPersonView.setActive(isLeft);
            this.leftPersonView.setSpeaking(isLeft);
        }

        if (this.rightPersonView) {
            this.rightPersonView.setActive(!isLeft);
            this.rightPersonView.setSpeaking(!isLeft);
        }
    },

    updatePersonTitle: function (activePersonInfo) {
        var styles = cleverapps.styles.DialogueView;

        if (!this.personTitle) {
            this.personTitle = new DialoguePersonTitleView();
            this.content.addChild(this.personTitle, 1);
        }

        if (!activePersonInfo) {
            this.personTitle.hide();
            if (this.textBgArrow) {
                this.textBgArrow.setVisible(false);
            }
            return;
        }

        var isLeft = activePersonInfo.orientation === "left";

        var titleText = Messages.has("Dialogue.person." + activePersonInfo.role) && Messages.get("Dialogue.person." + activePersonInfo.role);
        if (titleText) {
            this.personTitle.update(titleText, isLeft ? this.leftPersonView : this.rightPersonView);
        } else {
            this.personTitle.hide();
        }

        var arrowStyle = styles.text.arrow;
        if (arrowStyle) {
            if (!this.textBgArrow) {
                this.textBgArrow = new cc.Sprite(bundles.dialogues.frames.arrow_png);
                this.content.addChild(this.textBgArrow);
            }

            this.textBgArrow.setVisible(true);
            this.textBgArrow.setScaleX(isLeft ? 1 : -1);
            this.textBgArrow.updatePosition = function () {
                if (arrowStyle.offsetX === "relative") {
                    var personX;
                    if (isLeft) {
                        personX = this.content.convertToNodeSpace(this.leftPersonView.parent.convertToWorldSpace(this.leftPersonView.getPosition())).x;
                        this.textBgArrow.setPositionRound(personX + this.leftPersonView.width / 2 + this.textBgArrow.width / 2, arrowStyle.y);
                    } else {
                        personX = this.content.convertToNodeSpace(this.rightPersonView.parent.convertToWorldSpace(this.rightPersonView.getPosition())).x;
                        this.textBgArrow.setPositionRound(personX - this.rightPersonView.width / 2 - this.textBgArrow.width / 2, arrowStyle.y);
                    }
                } else {
                    this.textBgArrow.setPositionRound(isLeft ? arrowStyle.offsetX : this.content.width - arrowStyle.offsetX, arrowStyle.y);
                }
            }.bind(this);
            this.textBgArrow.updatePosition();
        }
    },

    hidePerson: function (position) {
        if (position !== "right" && this.leftPersonView) {
            this.leftPersonView.removeFromParent();
            delete this.leftPersonView;
        }

        if (position === "right" && this.rightPersonView) {
            this.rightPersonView.removeFromParent();
            delete this.rightPersonView;
        }
    },

    changeText: function (text, isWallAtRight) {
        var styles = cleverapps.styles.DialogueView;

        if (this.text) {
            this.text.removeFromParent();
        }

        this.text = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT);
        this.text.initTextSize = this.text.getFontSize();
        this.content.addChild(this.text, 1);
        this.text.updatePosition = function () {
            this.text.setHorizontalAlignment(styles.text.alignment);
            this.text.setAnchorPoint2(0.5, 1.0);
            var textStyles = styles.text;
            var rightX = this.content.width - textStyles.padding.fromWallX;
            var leftX = textStyles.padding.x;
            if (isWallAtRight) {
                rightX = this.content.width - textStyles.padding.x;
                leftX = textStyles.padding.fromWallX;
            }
            var topY = this.content.height - textStyles.padding.y;
            var bottomY = (this.button && this.button.visible ? this.button.y + this.button.height / 2 : 0) + textStyles.padding.y;

            var textScale = 1;
            var textWidth = rightX - leftX;
            var textHeight = topY - bottomY;

            if (textWidth > textStyles.optimization.width) {
                // text rendering optimization
                textScale = 1.5;
                textWidth /= textScale;
                textHeight /= textScale;
            }
            this.text.setFontSize(Math.round(this.text.initTextSize / textScale));

            this.text.setDimensions(textWidth, 0);
            this.text.fitTo(undefined, textHeight);
            this.text.setScale(textScale);
            this.text.setPositionRound((leftX + rightX) / 2, topY);
            if (this.text._byLetterAnimationRunning) {
                this.text._byLetterAnimationDone();
                this.text.setString(text);
            }
        }.bind(this);
        this.text.updatePosition();
        this.text.byLetterAnimation({ callback: this.dialogue.finishTyping.bind(this.dialogue) });
    },

    updateButton: function (visible, isLast) {
        var styles = cleverapps.styles.DialogueView;

        visible = !this.options.button.noButton && visible;
        if (!this.button && visible) {
            this.button = new cleverapps.UI.Button({
                width: styles.button.width,
                height: styles.button.height,
                type: cleverapps.styles.UI.Button.Images.small_button_green,
                text: this.options.button.text || "Next",
                onClicked: function () {
                    this.dialogue.buttonClicked();
                }.bind(this)
            });
            this.button.updatePosition = function () {
                this.button.setPositionRound(this.content.width - (this.button.width / 2 + styles.button.offset.x), this.button.height / 2 + styles.button.offset.y);
            }.bind(this);
            this.button.updatePosition();
            this.content.addChild(this.button, 1);
        }

        if (this.button) {
            this.button.visible = visible;
            if (isLast) {
                this.button.text.setString(Messages.get("OK"));
            }
        }
    },

    close: function (silent) {
        this.byLetterAnimationFinish();
        this.content.stopAllActions();
        var onClose = function () {
            this.dialogue.trigger("beforeClose");
            Window.prototype.close.apply(this, arguments);
            this.dialogue.finishClose();
        }.bind(this);
        if (silent) {
            onClose();
            return;
        }
        var animationStyle = cleverapps.styles.DialogueView.showAnimation;
        if (animationStyle === "fromBelow") {
            this.closeToBelow(onClose);
        } else {
            this.closeToSides(onClose);
        }
    },

    closeToSides: function (onClose) {
        if (this.text) {
            this.text.removeFromParent();
        }
        if (this.textBgArrow) {
            this.textBgArrow.setVisible(false);
        }
        [this.leftPersonView, this.rightPersonView].forEach(function (person) {
            if (!person) {
                return;
            }
            var basePosition = person.getPosition();
            var startPosition;
            if (person.getOrientation() === "left") {
                startPosition = cc.p(-person.width / 2, basePosition.y);
            } else {
                startPosition = cc.p(this.contentWrap.width + person.width / 2, basePosition.y);
            }

            var delay = person.isActive ? 0.25 : 0;
            person.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.MoveTo(0.25, startPosition)
            ));
        }.bind(this));

        this.content.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.FadeTo(0.5, 0),
                new Scale9SpriteResizeTo(0.5, this.content.width * 0.6, this.content.height)
            ),
            new cc.CallFunc(onClose)
        ));
    },

    closeToBelow: function (onClose) {
        var styles = cleverapps.styles.DialogueView;
        var startPosition = cc.p(this.content.x, -this.content.height);
        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveTo(0.15, cc.p(this.content.x, this.content.y + styles.showUp.offset)),
            new cc.MoveTo(0.15, startPosition),
            new cc.CallFunc(onClose),
            new cc.RemoveSelf()
        ));
        [this.leftPersonView, this.rightPersonView].forEach(function (person) {
            if (!person) {
                return;
            }
            var dy = styles.persons.dy[person.role] || 0;
            person.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.MoveTo(0.15, cc.p(person.x, person.y + styles.showUp.offset)),
                new cc.MoveTo(0.15, person.x, startPosition.y + this.content.height + dy)
            ));
        }.bind(this));
    },

    byLetterAnimationFinish: function (skipped) {
        if (this.text) {
            this.text.byLetterAnimationFinish();
        }

        [this.leftPersonView, this.rightPersonView].forEach(function (personView) {
            if (personView) {
                personView.setSpeaking(false, skipped);
            }
        });
    },

    listBundles: function (dialogue) {
        return dialogue.bundlesToLoad.slice();
    }
});

cleverapps.styles.DialogueView = {
    position: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 0 }
    },
    height: 280,
    widthPadding: 0,
    offsetY: 20,
    showAnimation: "fromBelow", // "fromSides"

    showUp: {
        offset: 75
    },

    button: {
        width: 160,
        height: 60,

        offset: { x: 30, y: 30 }
    },

    text: {
        alignment: cc.TEXT_ALIGNMENT_LEFT,
        arrow: {
            y: { align: "top", dy: 23 },
            offsetX: "relative"
        },

        padding: {
            fromWallX: 30,
            x: 30,
            y: 30
        },

        optimization: {
            width: 1000
        }
    },

    persons: {
        aboveСontent: true,
        dy: {},
        left: {
            x: { align: "left", dx: 150 }
        },
        right: {
            x: { align: "right", dx: -150 }
        },

        padding: 100
    },

    Window: {
        windowShowUpAnimation: false
    }
};
