/**
 * Created by Andrey Popov on 12/1/20.
 */

var ChatMessage = function () {
};

ChatMessage.fromData = function (messageData) {
    var message = new ChatMessage();

    message.id = messageData.id;
    message.userId = messageData.userId;
    message.postDate = messageData.postDate;
    message.message = messageData.message;
    message.isResponse = messageData.isResponse;
    message.readByUser = messageData.readByUser;
    message.tag = messageData.tag;
    message.status = messageData.status;
    message.dialogueId = messageData.dialogueId;

    message.type = ChatMessage.MESSAGE_TYPE.CHAT;
    return message;
};

ChatMessage.createServiceMessage = function (text, id) {
    var message = new ChatMessage();
    message.id = id || "service_" + text;
    message.message = text;
    message.type = ChatMessage.MESSAGE_TYPE.SERVICE;
    return message;
};

ChatMessage.createSeparator = function (id) {
    var message = new ChatMessage();
    message.id = "separator_" + id;
    message.type = ChatMessage.MESSAGE_TYPE.SEPARATOR;
    return message;
};

ChatMessage.createTemporaryMessage = function (text) {
    var message = new ChatMessage();
    message.id = "tmp_" + text;
    message.message = text;
    message.type = ChatMessage.MESSAGE_TYPE.TEMPORARY;
    message.postDate = new Date().toString();
    return message;
};

ChatMessage.createCategoryButtons = function () {
    var message = new ChatMessage();
    message.id = "category_buttons";
    message.type = ChatMessage.MESSAGE_TYPE.CATEGORIES;
    return message;
};

ChatMessage.prototype.hasGift = function () {
    return this.tag === "gift" || this.tag === "takengift";
};

ChatMessage.prototype.isGiftTaken = function () {
    return this.tag === "takengift";
};

ChatMessage.prototype.isTurningOnConsoleStream = function () {
    return this.tag === "stream_on";
};

ChatMessage.prototype.isTurningOffConsoleStream = function () {
    return this.tag === "stream_off";
};

ChatMessage.prototype.isFromActiveOrUnreadDialogue = function () {
    if (cleverapps.chat.activeDialogueId === this.dialogueId) {
        return true;
    }

    return cleverapps.chat.wasUnreadMessages && this.dialogueId === cleverapps.chat.getLastMessage().dialogueId;
};

ChatMessage.prototype.isApplicable = function () {
    this.parseGift();

    if (this.metha) {
        if (this.metha.farm) {
            var buildingId = parseInt(this.metha.farm.building);
            var building = cleverapps.farm.buildings[buildingId];
            var questId = parseInt(this.metha.farm.quest);

            if (!building || questId >= building.quests.length) {
                return false;
            }
        } else if (this.metha.simple) {
            return parseInt(this.metha.simple.current) < cleverapps.simple.bgsAmount;
        }
    }

    if (this.harvested) {
        return Object.keys(this.harvested).filter(function (code) {
            return cleverapps.unitsLibrary.listAvailableByType("fruit").indexOf(code) !== -1;
        }).length > 0;
    }

    if (this.presents && this.presents.expeditionType) {
        var mission = cleverapps.missionManager.findByType(parseInt(this.presents.expeditionType));
        return mission && mission.isRunning();
    } if (this.presents && this.presents.expedition) {
        var page = cleverapps.travelBook.getPageById(this.presents.expedition);
        return page && page.isPermanent() && page.isActive();
    }

    return true;
};

ChatMessage.prototype.parseGift = function () {
    if (this.parsed) {
        return;
    }
    this.parsed = true;

    var gifts = JSON.parse(this.message);

    if (gifts.presents) {
        var reward = this.presents = {};
        gifts.presents.forEach(function (present) {
            if (typeof present !== "object" || present.type === undefined || present.amount === undefined) {
                return;
            }

            var addExpeditionDataToReward = function () {
                if (gifts.expedition && gifts.expeditionType) {
                    reward.expedition = gifts.expedition;
                    reward.expeditionType = gifts.expeditionType;
                } else if (gifts.expedition) {
                    TravelBook.PAGES.forEach(function (page) {
                        if (gifts.expedition === page.id && page.permanent) {
                            reward.expedition = page.id;
                        }
                    });
                }
            };

            var name = present.type;
            if (["hard", "gold", "unlimitedLives", "stars", "exp", "wardrobe", "lives", "lives2", "soft", "coins", "wands", "worker", "unit", "growthFund"].indexOf(name) === -1
                && name.indexOf("booster") !== 0 && name.indexOf("hl") !== 0) {
                return;
            }
            var amount = present.amount;
            if (name.indexOf("booster") === 0) {
                if (reward.boosters === undefined) {
                    reward.boosters = [];
                }
                reward.boosters[parseInt(name.replace("booster", ""))] = amount;
            } else if (name === "unlimitedLives") {
                reward.unlimitedLives = amount + " hour";
            } else if (name === "worker") {
                reward.worker = amount + " hour";
                if (gifts.expedition) {
                    addExpeditionDataToReward();
                }
            } else if (name.indexOf("hl") !== -1) {
                if (!reward.herolevels) {
                    reward.herolevels = [];
                }
                reward.herolevels.push([name.replace("hl", ""), present.amount]);
            } else if (name === "unit") {
                reward.unit = {
                    code: present.code,
                    stage: present.stage,
                    amount: amount || 1
                };

                addExpeditionDataToReward();
            } else if (name === "lives2") {
                reward.lives = amount;

                if (gifts.expedition && gifts.expeditionType) {
                    if (cleverapps.missionManager.findRunningMission(gifts.expeditionType)) {
                        reward.expedition = gifts.expedition;
                        reward.expeditionType = gifts.expeditionType;
                    }
                } else {
                    TravelBook.PAGES.forEach(function (page) {
                        if (page.energy && page.missionType && cleverapps.missionManager.findRunningMission(page.missionType)) {
                            reward.expedition = page.id;
                            reward.expeditionType = page.missionType;
                        }
                    });
                }
            } else if (["exp", "wands"].indexOf(name) !== -1 && gifts.expedition) {
                reward[name] = amount;
                addExpeditionDataToReward();
            } else {
                if (name === "coins") {
                    name = "soft";
                }
                if (name === "gold") {
                    name = "hard";
                }
                reward[name] = amount;
            }
        });
    } else if (gifts.progress) {
        this.progress = gifts.progress;
    } else if (gifts.restoreProgressId) {
        this.restoreProgressId = gifts.restoreProgressId;
    } else if (gifts.metha) {
        this.metha = gifts.metha;
    } else if (gifts.harvested) {
        this.harvested = gifts.harvested;
    }
};

ChatMessage.MESSAGE_TYPE = {
    CHAT: 0,
    SERVICE: 1,
    TEMPORARY: 2,
    SEPARATOR: 3,
    CATEGORIES: 4
};