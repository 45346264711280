/**
 * Created by Andrey Popov on 05.07.2021
 */

var VKAppAds = function () {
    VKAds.call(this);

    RewardedAdsManager.ACTIVE_ADS = [RewardedAdsManager.REWARDED];
};

VKAppAds.prototype = Object.create(VKAds.prototype);
VKAppAds.prototype.constructor = VKAppAds;

VKAppAds.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("vkappads", callback);
};

VKAppAds.isAppropriate = function () {
    return cleverapps.platform instanceof MobileVk && (cleverapps.MobileVkSocial.getPlatfrom() !== "html5_mobile");
};

VKAppAds.prototype._cache = function (name, callback) {
    vkBridge.send("VKWebAppGetAds", {}).then(function (event) {
        if (event.type === "promo") {
            console.log("Loaded vk ad: " + name);
            callback(AdsPlugin.CODE_SUCCEED, true);
        } else {
            console.log("Failed loading ad (type not promo)");
            callback(AdsPlugin.CODE_FAILED);
        }
    }).catch(function (error) {
        console.log(error);
        callback(AdsPlugin.CODE_FAILED);
    });
};

VKAds.prototype.getECPM = function () {
    return RewardedAdsManager.eCPM.VK_APP;
};