/**
 * Created by vtbel on 03.11.2020.
 */

var GitInfoWindow = Window.extend({
    onWindowLoaded: function (options) {
        this.options = options;
        this._super({
            noBackground: true,
            closeButton: false,
            name: "GitInfoWindow",
            content: this.getContent(),
            buttons: this.getButtons()
        });

        if (!cleverapps.environment.isEditorScene()) {
            $(cc.EditBox).hide();
        }
    },

    getButtons: function () {
        var styles = cleverapps.styles.GitInfoWindow.button;
        return [
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: this.options.action === "push" ? "push all" : "revert all",
                width: styles.width,
                height: styles.height,
                onClicked: function () {
                    this.options.callback();
                    this.close();
                }.bind(this)
            }),
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: "cancel",
                width: styles.width,
                height: styles.height,
                onClicked: this.close.bind(this)
            })
        ];
    },

    generateFileLine: function (file) {
        var styles = cleverapps.styles.GitInfoWindow;
        var fileName = cleverapps.UI.generateTTFText(file, cleverapps.styles.FONTS.GIT_INFO_TEXT);
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            text: this.options.action === "push" ? "push" : "revert",
            onClicked: function () {
                this.options.callback(file);
                this.close();
            }.bind(this),
            width: styles.lineButton.width,
            height: styles.lineButton.height
        });

        var maxFilenameWidth = styles.scroll.width - button.width - styles.lineMargin;
        fileName.fitTo(maxFilenameWidth);

        var line = new cleverapps.Layout([fileName, button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.scroll.width - fileName.width - button.width
        });

        return line;
    },

    getContent: function () {
        var styles = cleverapps.styles.GitInfoWindow;

        var fileNodes = [], i;
        if (this.options.status === "need push") {
            var text = cleverapps.UI.generateTTFText("Push already commited changes!", cleverapps.styles.FONTS.GIT_INFO_TEXT);
            text.setDimensions(styles.scroll.width, 0);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            fileNodes.push(text);
        } else {
            if (this.options.status.newFiles.length > 0) {
                fileNodes.push(cleverapps.UI.generateTTFText("New files:", cleverapps.styles.FONTS.GIT_INFO_TEXT));
                for (i = 0; i < this.options.status.newFiles.length; i++) {
                    fileNodes.push(this.generateFileLine(this.options.status.newFiles[i]));
                }
            }
            if (this.options.status.changedFiles.length > 0) {
                fileNodes.push(cleverapps.UI.generateTTFText("Changed files:", cleverapps.styles.FONTS.GIT_INFO_TEXT));
                for (i = 0; i < this.options.status.changedFiles.length; i++) {
                    fileNodes.push(this.generateFileLine(this.options.status.changedFiles[i]));
                }
            }
        }

        var files = new cleverapps.Layout(fileNodes, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        var scrollHeight = styles.scroll.height;
        if (files.height < scrollHeight) {
            scrollHeight = files.height;
        }

        var scroll = cleverapps.UI.createScale9Sprite(bundles.git.frames.scroll_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        scroll.setContentSize2(styles.scroll.width + 2 * styles.scroll.bgPadding, scrollHeight + 2 * styles.scroll.bgPadding);

        var scrollContainer = new cleverapps.UI.ScrollView(files);
        scrollContainer.setContentSize2(styles.scroll.width, scrollHeight);
        scrollContainer.setPositionRound(scroll.width / 2, scroll.height / 2);
        scroll.addChild(scrollContainer);

        var content = cleverapps.UI.createScale9Sprite(bundles.git.frames.scroll_content_bg);
        content.setContentSize2(styles.width, scroll.height + styles.padding * 2);

        scroll.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(scroll);

        var closeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.git.frames.delete_button,
                button_on_png: bundles.git.frames.delete_button
            },
            onClicked: this.close.bind(this)
        });
        closeButton.setPositionRound(styles.closeButton);
        content.addChild(closeButton);

        var title = cleverapps.UI.generateOnlyText(this.options.action === "push" ? "Push local changes?" : "Revert local changes?", cleverapps.styles.FONTS.GIT_INFO_TITLE_TEXT);
        content.addChild(title);
        title.setPositionRound(styles.title);

        return content;
    },

    onClose: function () {
        if (!cleverapps.environment.isEditorScene()) {
            $(cc.EditBox).show();
        }
    }
});

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    GIT_TEXT_COLOR: new cc.Color(89, 144, 221, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GIT_INFO_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.GIT_TEXT_COLOR
    },
    GIT_INFO_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.GIT_TEXT_COLOR
    }
});

cleverapps.styles.GitInfoWindow = {
    button: {
        width: 150,
        height: 70
    },

    scroll: {
        width: 450,
        height: 500,
        bgPadding: 10
    },

    closeButton: {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    },

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 60 }
    },

    width: 600,
    padding: 150,
    margin: 20,
    lineMargin: 20,
    lineButton: {
        width: 100,
        height: 44
    }
};