/**
 * Created by andrey on 21.05.2020
 */

var BoosterBeforeView = cc.Scale9Sprite.extend({
    ctor: function (windowBooster, level) {
        this._super(bundles.boosters_before.frames.booster_before_bg_png);

        this.windowBooster = windowBooster;
        this.level = level;

        var styles = cleverapps.styles.BoosterBeforeView;

        this.setContentSize2(styles);

        this.createIcon();

        this.createText();

        this.createAdsBubble();

        this.createTextBg();

        if (this.windowBooster.isLantern()) {
            var bonus = new BoosterBeforeBonusView(this.windowBooster);
            bonus.setPositionRound(styles.bonus);
            this.addChild(bonus);
            bonus.lanternAnimation();
        }

        if (!this.windowBooster.isDisabled() && !this.windowBooster.isLantern()) {
            this.amount = new BoosterBeforeAmountView(this.windowBooster);
            this.amount.setPositionRound(styles.amount);
            this.addChild(this.amount);
        }

        this.createPrice();

        this.windowBooster.on("update", this.createListener(this.update.bind(this)), this);
        this.windowBooster.on("showForce", this.createListener(this.showForce.bind(this)), this);
        this.windowBooster.on("changeAmount", this.createListener(this.update.bind(this)), this);

        if (!this.windowBooster.isDisabled() && !this.windowBooster.isLantern()) {
            cleverapps.UI.applyHover(this);
            cleverapps.UI.onClick(this, this.onClick.bind(this));
        }

        this.update();
    },

    showUp: function (delay) {
        this.setVisible(false);
        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.boosters_before.urls.appear_sfx, { throttle: 0 });
                    this.setScale(0.5);
                    this.setVisible(true);
                }.bind(this)),
                new cc.ScaleTo(0.4, 1, 1).easing(cc.easeBackOut(2))
            )
        );

        this.adBubbleView.setScale(0);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.1),
            new cc.CallFunc(function () {
                this.adBubbleView.setScale(1);

                if (this.windowBooster.adBubble.isActive()) {
                    this.adBubbleView.show();
                }
            }.bind(this))
        ));
    },

    createAdsBubble: function () {
        var styles = cleverapps.styles.BoosterBeforeView;

        this.adBubbleView = new AdBubbleView(this.windowBooster.adBubble);
        this.addChild(this.adBubbleView);
        this.adBubbleView.setPositionRound(styles.adBubble);
    },

    createPrice: function () {
        var styles = cleverapps.styles.BoosterBeforeView.price;

        var text = new TextWithIcon("$$" + this.windowBooster.getPrice(), {
            font: cleverapps.styles.FONTS.WHITE_TEXT
        });

        this.price = new cc.Scale9Sprite(bundles.boosters_before.frames.booster_before_price_bg_png);
        this.price.setContentSize2(text.width + 2 * styles.bg.padding.x, text.height + 2 * styles.bg.padding.y);
        this.price.addChild(text);
        text.setPositionRound(this.price.width / 2, this.price.height / 2 + styles.text.dy);

        this.price.setPositionRound(styles);
        this.addChild(this.price);
    },

    createTextBg: function () {
        var styles = cleverapps.styles.BoosterBeforeView;

        var textBgImage = bundles.boosters_before.frames.booster_before_level_bg;

        this.textAvailableBg = cleverapps.UI.createScale9Sprite(textBgImage, styles.textAvailable.bg.rect);
        this.addChild(this.textAvailableBg);
        this.textAvailableBg.setContentSize2(styles.textAvailable.bg);
        this.textAvailableBg.setPositionRound(styles.textAvailable.bg);
    },

    createText: function () {
        var styles = cleverapps.styles.BoosterBeforeView;

        this.textAvailable = cleverapps.UI.generateOnlyText(Messages.get("UnlockOnLevel", {
            level: this.windowBooster.getLevelAvailable()
        }), cleverapps.styles.FONTS.BOOSTERS_BEFORE_UNLOCK || cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        this.addChild(this.textAvailable, 1);
        this.textAvailable.setPositionRound(styles.textAvailable);
        this.textAvailable.fitTo(styles.textAvailable.width);
    },

    createIcon: function () {
        var styles = cleverapps.styles.BoosterBeforeView;

        var dy = styles.icon[this.windowBooster.id] ? styles.icon[this.windowBooster.id].dy : styles.icon.dy;
        this.icon = new cc.Sprite(this.windowBooster.getIcon());
        this.icon.setPositionRound(this.width / 2, this.height / 2 + dy);
        this.addChild(this.icon);
    },

    onClick: function () {
        this.windowBooster.onClick();

        this.icon.stopAllActions();
        this.icon.setScale(0.95);
        this.icon.runAction(
            new cc.ScaleTo(0.15, 1).easing(cc.easeBackInOut())
        );

        var sound = this.windowBooster.isSelected() ? bundles.main.urls.booster_before_cancel_effect
            : bundles.main.urls.booster_before_selection_effect;
        cleverapps.audio.playSound(sound);
    },

    updateBackground: function () {
        var bgImage = bundles.boosters_before.frames.booster_before_bg_png;
        if (this.windowBooster.isDisabled()) {
            bgImage = bundles.boosters_before.frames.booster_before_bg_off_png;
        } else if (this.windowBooster.isSelected() || this.windowBooster.isLantern()) {
            bgImage = bundles.boosters_before.frames.booster_before_bg_on_png;
        }
        this.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame(bgImage));
    },

    updateIcon: function () {
        this.icon.setSpriteFrame(this.windowBooster.getIcon());

        if (this.windowBooster.isUpcoming()) {
            this.icon.setOpacity(150);
        } else {
            this.icon.setOpacity(255);
        }
    },

    updateText: function () {
        this.textAvailable.setVisible(this.windowBooster.isUpcoming());
        if (this.textAvailableBg) {
            this.textAvailableBg.setVisible(this.windowBooster.isUpcoming());
        }
    },

    update: function () {
        this.updateBackground();

        this.updateIcon();

        this.updateText();

        if (this.amount) {
            this.amount.update();
        }

        this.price.setVisible(this.windowBooster.isActive() && this.windowBooster.getAmount() <= 0);

        if (this.windowBooster.isSelected() && Messages.has("BoosterBeforeUse_" + this.windowBooster.booster.id)) {
            cleverapps.notification.create("BoosterBeforeUse_" + this.windowBooster.booster.id, {
                image: this.windowBooster.booster.icon
            });
        }
    },

    showForce: function (f) {
        var force = this.windowBooster.getForce();
        if (cleverapps.forces.isAvailable(this, force)) {
            cleverapps.forces.create(this, force);
            cleverapps.forces.onceForceClosed = f;

            this.update();
        } else {
            f();
        }
    },

    onExit: function () {
        this.windowBooster.onExit();
    }
});

cleverapps.styles.BoosterBeforeView = {
    width: 186,
    height: 186,

    adBubble: {
        x: { align: "right", dx: 39 },
        y: { align: "bottom", dy: -40 }
    },

    bonus: {
        x: { align: "right", dx: 18 },
        y: { align: "bottom", dy: -18 }
    },

    amount: {
        x: { align: "right", dx: 18 },
        y: { align: "bottom", dy: -18 }
    },

    price: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 5 },

        bg: {
            padding: {
                x: 20,
                y: 8
            }
        },

        text: {
            dy: 6
        }
    },

    textAvailable: {
        x: { align: "center" },
        y: { align: "bottom", dy: 8 },
        width: 170,

        bg: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -5 },
            width: 190,
            height: 55
        }
    },

    icon: {
        dy: 0
    }
};