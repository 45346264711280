/**
 * Created by vladislav on 08.08.2022
 */

var WindowPersons = function (persons, window) {
    this.window = window;

    this.visible = true;

    var left, right;

    if (persons.left || persons.right) {
        left = persons.left;
        right = persons.right;
    } else {
        left = persons;
    }

    if (left) {
        this.leftPerson = this.createPerson(left);
    }

    if (right) {
        if (typeof right === "string") {
            right = {
                role: right
            };
        }
        right.orientation = "right";

        this.rightPerson = this.createPerson(right);
    }
};

WindowPersons.prototype.update = function () {
    var isVisible = this.positionPersons();

    this.toggleVisible(isVisible);
};

WindowPersons.prototype.positionPersons = function () {
    var styles = cleverapps.styles.WindowPersons;

    var positions = [styles.position];

    var leftPersonOffset = this.leftPerson && this.leftPerson.width * 0.3;
    var rightPersonOffset = this.rightPerson && this.rightPerson.width * 0.3;

    var secondPosition = {
        left: {
            x: { align: "left", dx: -leftPersonOffset },
            y: { align: "bottom" }
        },

        right: {
            x: { align: "right", dx: rightPersonOffset },
            y: { align: "bottom" }
        }
    };
    positions.push(secondPosition);

    for (var i = 0; i < positions.length; i++) {
        var position = positions[i];

        [this.leftPerson, this.rightPerson].forEach(function (person) {
            if (!person) {
                return;
            }
            var dy = styles.position.dy[person.role] || 0;
            var personPos = person.getOrientation() === "left" ? position.left : position.right;
            personPos.y.dy = dy;
            person.setPositionRound(personPos);
            person.originalPosition = person.getPosition();
        }, this);

        if (!this.checkOverlap()) {
            return true;
        }
    }

    return false;
};

WindowPersons.prototype.checkOverlap = function () {
    return [this.leftPerson, this.rightPerson].some(function (person) {
        if (!person) {
            return false;
        }

        var box = person.getGlobalBoundingBox();

        if (this.window.window) {
            var rect = cc.rectIntersection(box, this.window.window.getGlobalBoundingBox());
            if (rect.width > 0 && rect.height > 0) {
                return true;
            }
        }

        if (this.window.buttons && !this.window.withBg) {
            rect = cc.rectIntersection(box, this.window.buttons.getGlobalBoundingBox());
            if (rect.width > 0 && rect.height > 0) {
                return true;
            }
        }

        if (this.window.tapToContinue && !this.window.withBg) {
            rect = cc.rectIntersection(box, this.window.tapToContinue.getGlobalBoundingBox());
            if (rect.width > 0 && rect.height > 0) {
                return true;
            }
        }

        return false;
    }, this);
};

WindowPersons.prototype.createPerson = function (options) {
    var person = new Person(options);
    person.setVisible(this.visible);
    person.setAnchorPoint2(0.5, 0);
    this.window.addChild(person);

    return person;
};

WindowPersons.prototype.showUp = function () {
    [this.leftPerson, this.rightPerson].forEach(function (person) {
        if (person) {
            person.show();
        }
    }, this);
};

WindowPersons.prototype.toggleVisible = function (visible) {
    if (this.visible === visible) {
        return;
    }

    this.visible = visible;

    [this.leftPerson, this.rightPerson].forEach(function (person) {
        if (person) {
            person.setVisible(visible);
        }
    });
};

WindowPersons.prototype.setLeftPerson = function (person) {
    person = Person.ParseOptions(person);

    if (this.leftPerson && this.leftPerson.getRole() === person.role) {
        return;
    }

    if (this.leftPerson) {
        this.leftPerson.hide();
    }

    this.leftPerson = this.createPerson(person);

    this.positionPersons();

    this.leftPerson.show();
};

WindowPersons.prototype.setRightPerson = function (person) {
    person = Person.ParseOptions(person);
    person.orientation = "right";

    if (this.rightPerson && this.rightPerson.getRole() === person.role) {
        return;
    }

    if (this.rightPerson) {
        this.rightPerson.hide();
    }

    this.rightPerson = this.createPerson(person);
    this.rightPerson.show();
};

cleverapps.styles.WindowPersons = {
    position: {
        left: {
            x: { align: "left", dx: 50 },
            y: { align: "bottom" }
        },

        right: {
            x: { align: "right", dx: -50 },
            y: { align: "bottom" }
        },
        dy: {}
    }
};
