/**
 * Created by slava on 23.03.17.
 */

var PagingScrollView = cc.Node.extend({ 
    ctor: function (options) {
        this._super();

        options = options || {};
        this.currentPage = options.beginPage ? options.beginPage : 0;
        this.amountPages = options.amountPages ? options.amountPages : 0;
        var styles = this.styles = options.styles;
        this.direction = styles.direction !== undefined ? styles.direction : PagingScrollView.DIRECTIONS.Horizontal;
        this.autoScroll = !styles.noAutoScroll;
        this.visiblePages = styles.visiblePages;
        this.prevPageShowed = styles.prevEpisodeShowed;
        this.firstOffset = 0;

        this.pageHeight = options.pageHeight;
        this.pageWidth = options.pageWidth;
        this.middlePage = false;

        cleverapps.UI.onDrag(this, {
            onDragStart: this.onTouchBegan.bind(this),
            followPointer: this.onTouchMoved.bind(this),
            onDragEnd: this.onTouchEnded.bind(this)
        });

        if (!options.noWheelScroll) {
            cc.eventManager.addListener({
                event: cc.EventListener.MOUSE,
                onMouseScroll: function (r) {
                    if (!this.isMoving && cleverapps.meta.checkEventNode(this)) {
                        var d = r.getScrollY();
                        if (d !== 0) {
                            if (this.autoScroll) {
                                if (d > 0) {
                                    this.onNextPageListener();
                                } else {
                                    this.onPrevPageListener();
                                }
                            } else {
                                if (d > 0) {
                                    d = cleverapps.styles.PagingScrollView.wheelScrollSpeed;
                                } else {
                                    d = -cleverapps.styles.PagingScrollView.wheelScrollSpeed;
                                }

                                this.basePosition = this.container.getPosition();
                                var l = this.getScrollLength();
                                if (this.direction === PagingScrollView.DIRECTIONS.Horizontal) {
                                    d = Math.max(this.basePosition.x - this.firstOffset, Math.min(l.x + this.basePosition.x, d));
                                    this.deltaMove([-d, 0]);
                                } else {
                                    d = Math.max(this.basePosition.y - this.firstOffset, Math.min(l.y + this.basePosition.y, d));
                                    this.deltaMove([0, -d]);
                                }
                            }
                        }
                    }
                }.bind(this)
            }, this);
        }

        this.onNextPageListener = function () {
        };
        this.onPrevPageListener = function () {
        };
        this.onScrolledToCurrentPage = function () {
        };

        this.container = new cc.Node();
        this.addChild(this.container);
        this.scheduleUpdate();
    },

    update: function (dt) {
        this._super(dt);

        if (this.visiblePages > 1) {
            this.calcCurrentPage(true);
        }
    },

    calcFirstOffset: function () {
        this.firstOffset = 0;

        if (this.styles.firstOffset) {
            this.firstOffset = this.styles.firstOffset;
        } else if (this.visiblePages > 1) {
            if (this.isHorizontal()) {
                this.firstOffset = this.pageWidth / 2;
            } else {
                this.firstOffset = this.pageHeight / 2;
            }
        }

        if (this.isVertical()) {
            var offsetY = cleverapps.styles.PagingScrollView.offsetEpisodes ? -cleverapps.UI.getSceneOffsetY() : 0;
            var scale = PagesView.calcScale();
            offsetY += (scale * this.pageHeight - this.pageHeight) / 2 / scale;
            this.firstOffset += offsetY;
        }
    },

    getScrollLength: function () {
        var l = { x: 0, y: 0 };
        if (this.direction === PagingScrollView.DIRECTIONS.Horizontal) {
            l.x = (this.amountPages - this.visiblePages) * this.pageWidth;
        } else {
            l.y = (this.amountPages - this.visiblePages) * this.pageHeight + cleverapps.styles.PagingScrollView.comingSoonExtraLength[cleverapps.wideMode.mode];
            l.y -= cleverapps.UI.getSceneOffsetY() * 2;
        }
        return l;
    },

    onTouchBegan: function (touch) {
        if (cleverapps.meta.isFocused()) {
            return false;
        }

        this.touchBeganId = touch.getID();

        if (this.isMoving) {
            if (this.autoScroll) {
                return false;
            } 
            this.container.stopAllActions();
            this.calcCurrentPage();
        }
        var point = this.convertTouchToNodeSpace(touch);
        this.horizontalLookDirection = undefined;
        this.basePoint = point;
        this.prevPoint = point;
        this.prevPointTime = 0;
        this.prevPointCalcTimes = 0;
        this.deltaPoint = 0;
        this.basePosition = this.container.getPosition();
        this.isMoving = false;
        return cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);
    },

    deltaMove: function (d, silent) {
        var l = this.getScrollLength();
        if (this.isHorizontal()) {
            if (this.basePosition.x + d[0] > this.firstOffset + 1.e-6 || this.basePosition.x + d[0] + 1.e-6 < -l.x) {
                this.isMoving = false;
                return;
            }
        } else if (this.basePosition.y + d[1] > this.firstOffset + 1.e-6 || this.basePosition.y + d[1] + 1.e-6 < -l.y) {
            this.isMoving = false;
            return;
        }

        var treshhold = silent ? PagingScrollView.MOVE_TRESHOLD : 0;
        if (Math.abs(d[0]) > treshhold * this.pageWidth || Math.abs(d[1]) > treshhold * this.pageHeight) {
            this.isMoving = true;
            this.showPages(d);
        }

        if (this.isMoving) {
            cleverapps.audio.playSound(bundles.main.urls.pagingscroll);

            if (silent) {
                this.container.setPosition(this.basePosition.x + d[0], this.basePosition.y + d[1]);
                if (!this.autoScroll) {
                    this.calcCurrentPage();
                }
            } else {
                this.showPages(d);
                this.container.runAction(new cc.Sequence(
                    new cc.MoveTo(0.3, { x: this.basePosition.x + d[0], y: this.basePosition.y + d[1] }),
                    new cc.CallFunc(function () {
                        this.isMoving = false;
                        this.calcCurrentPage();
                    }.bind(this))
                ));
            }
        }
    },

    onTouchMoved: function (touch) {
        if (this.touchBeganId !== touch.getID()) {
            return false;
        }

        var point = this.convertTouchToNodeSpace(touch);
        var d = [point.x - this.basePoint.x, point.y - this.basePoint.y];

        var maxWidth = this.styles.maxWidth || cleverapps.styles.SCENE_MAX_WIDTH;
        if (!this.autoScroll && this.isVertical() && this.width < maxWidth && !cleverapps.styles.horizontalScrollDisabled) {
            if (this.horizontalLookDirection === undefined && (Math.abs(d[0]) > PagingScrollView.MOVE_TRESHOLD * this.pageWidth || Math.abs(d[1]) > PagingScrollView.MOVE_TRESHOLD * this.pageHeight)) {
                if (Math.abs(d[0]) > 2 * Math.abs(d[1])) {
                    this.horizontalLookDirection = true;
                } else {
                    this.horizontalLookDirection = false;
                }
            }

            if (this.horizontalLookDirection) {
                var dx = d[0];
                dx = Math.min(dx, (maxWidth - this.width) / 2 - this.basePosition.x);
                dx = Math.max(dx, -(maxWidth - this.width) / 2 - this.basePosition.x);
                this.container.setPosition(this.basePosition.x + dx, this.basePosition.y);
                return;
            }
        }

        if (this.isHorizontal()) {
            d[1] = 0;
        } else {
            d[0] = 0;
        }

        if (!this.autoScroll) {
            this.calcDeltaPoint(point);
        }
        this.deltaMove(d, true);
    },

    onEnter: function () {
        this._super();
        this.showCurrentPageOnly();
    },

    onExitTransitionDidStart: function () {
        this._super();
        this.showCurrentPageOnly();
    },

    showPages: function (d) {
        var pages = {};
        for (var i = 0; i < this.visiblePages; i++) {
            pages[this.currentPage + i] = true;
        }
        if (this.prevPageShowed) {
            pages[this.currentPage - 1] = true;
        }

        var nextPage = this.currentPage + ((d[0] < 0 || d[1] < 0) ? this.visiblePages : -1);
        pages[nextPage] = true;

        if (typeof this.middlePage === "number") {
            if (pages[this.middlePage]) {
                pages[this.middlePage + 1] = true;
            }
            pages[this.middlePage] = true;
        } else {
            var prevPage = this.currentPage + ((d[0] < 0 || d[1] < 0) ? -1 : this.visiblePages);
            pages[prevPage] = true;
        }

        this.getPages().forEach(function (child) {
            child.setVisible(pages[child.pageNo]);
        });
    },

    getPages: function () {
        return this.container.getChildren().filter(function (child) {
            return child.pageNo !== undefined;
        });
    },

    getVisiblePages: function () {
        return this.container.getChildren().filter(function (child) {
            return child.pageNo !== undefined && child.visible;
        });
    },

    showCurrentPageOnly: function () {
        this.getPages().forEach(function (child) {
            child.setVisible(this.currentPage + (this.prevPageShowed ? -1 : 0) <= child.pageNo && child.pageNo < this.currentPage + this.visiblePages || this.middlePage === child.pageNo);
        }.bind(this));
    },

    isHorizontal: function () {
        return this.direction === PagingScrollView.DIRECTIONS.Horizontal;
    },

    isVertical: function () {
        return !this.isHorizontal();
    },

    getCurrentPagePosition: function (currentPage) {
        if (currentPage === undefined) {
            currentPage = this.currentPage;
        }
        var pos = cc.p(
            this.isHorizontal() ? -currentPage * this.pageWidth : 0,
            this.isVertical() ? -currentPage * this.pageHeight : 0
        );

        if (this.visiblePages > 1) {
            pos = cc.p(
                pos.x + (this.isHorizontal() ? this.firstOffset : 0),
                pos.y + (this.isVertical() ? this.firstOffset : 0)
            );
        }

        var l = this.getScrollLength();
        if (this.isHorizontal()) {
            pos.x = Math.min(this.firstOffset, Math.max(pos.x, -l.x));
        } else {
            pos.y = Math.min(this.firstOffset, Math.max(pos.y, -l.y));
        }

        return pos;
    },

    calcCurrentPage: function (inUpdate) {
        var currentPosition = this.container.getPosition();
        var size = this.pageWidth;
        if (this.isHorizontal()) {
            currentPosition = currentPosition.x;
        } else {
            currentPosition = currentPosition.y;
            size = this.pageHeight;
        }

        if (currentPosition > 0) {
            currentPosition = 0;
        }

        var oldPage = this.currentPage;

        this.currentPage = Math.round(-currentPosition / size);

        if (inUpdate && oldPage === this.currentPage) {
            return;
        }

        this.middlePage = this.currentPage + this.visiblePages;
        this.showCurrentPageOnly();

        if (oldPage < this.currentPage) {
            this.onNextPageListener();
        } else if (oldPage > this.currentPage) {
            this.onPrevPageListener();
        }
    },

    scrollToPositionInPage: function (position, silent) {
        var pos = this.getCurrentPagePosition(), d;

        if (this.direction === PagingScrollView.DIRECTIONS.Horizontal) {
            d = this.width / 2 + position.x;
            if (d < 0 && this.currentPage === 0) {
                d = 0;
            }
            pos.x = Math.max(this.container.x - d, -this.getScrollLength().x);
        } else {
            d = this.height / 2 + position.y - this.height * 0.3;
            if (d < 0 && this.currentPage === 0) {
                d = 0;
            }
            pos.y = Math.max(this.container.y - d, -this.getScrollLength().y);
        }

        this.scrollToPosition(pos, silent);
    },

    scrollToPosition: function (pos, silent, scrollTimeout) {
        if (silent) {
            this.container.setPosition(pos);
            this.calcCurrentPage();
        } else {
            this.container.stopAllActions();
            scrollTimeout = scrollTimeout || 1;
            this.showPages([pos.x - this.container.x, pos.y - this.container.y]);
            this.isMoving = true;
            this.container.runAction(new cc.Sequence(
                new cc.DelayTime(1),
                new cc.MoveTo(scrollTimeout, pos),
                new cc.CallFunc(function () {
                    this.calcCurrentPage();
                    this.isMoving = false;
                }.bind(this))
            ));
        }
    },

    scrollToCurrentPage: function (silent, pageChanged, isSlow, currentPage) {
        var pos = this.getCurrentPagePosition(currentPage);

        if (silent) {
            this.container.stopAllActions();
            this.isMoving = false;
            this.container.setPosition(pos);
            if (this.autoScroll) {
                this.showCurrentPageOnly();
            } else {
                this.calcCurrentPage();
            }
            this.onScrolledToCurrentPage();
        } else {
            if (pageChanged) {
                cleverapps.audio.playSound(bundles.main.urls.window_effect);

                if (cleverapps.config.name === "riddles") {
                    pos = cc.p(
                        pos.x + (this.isHorizontal() ? this.pageWidth * 0.167 : 0),
                        pos.y + (this.isVertical() ? this.pageHeight * 0.167 : 0)
                    );

                    if (currentPage === this.amountPages - 1) {
                        pos.y = -this.getScrollLength().y;
                    }
                }
            }
            this.container.stopAllActions();
            var scrollTimeout = 0.25;
            if (isSlow) {
                scrollTimeout = cleverapps.styles.PagingScrollView.slowScroll.timeout;
            }
            this.showPages([this.container.x - pos.x, pos.y - this.container.y]);
            this.isMoving = true;
            this.container.runAction(new cc.Sequence(
                new cc.MoveTo(scrollTimeout, pos),
                new cc.CallFunc(function () {
                    if (this.autoScroll) {
                        this.showCurrentPageOnly();
                    } else {
                        this.calcCurrentPage();
                    }
                    this.onScrolledToCurrentPage();
                    this.isMoving = false;
                }.bind(this))
            ));
        }
    },

    calcDeltaPoint: function (point) {
        var dt = Date.now();
        if (this.prevPointTime === 0) {
            this.prevPointTime = dt;
            this.prevPoint = point;
            return;
        }

        if (this.prevPointTime < dt - PagingScrollView.SCROLL_SPEED_PERIODS[this.prevPointCalcTimes]) {
            if (this.prevPointTime < dt - 200) {
                this.prevPoint = point;
            }
            this.deltaPoint = this.isHorizontal() ? point.x - this.prevPoint.x : point.y - this.prevPoint.y;
            this.deltaPoint = this.deltaPoint / (dt - this.prevPointTime) * cleverapps.styles.PagingScrollView.touchScrollSpeedLevel;

            this.prevPointTime = dt;
            if (this.prevPointCalcTimes < PagingScrollView.SCROLL_SPEED_PERIODS.length - 1) {
                this.prevPointCalcTimes++;
            }
            this.prevPoint = point;
        }
    },

    onTouchEnded: function (touch) {
        if (this.touchBeganId !== touch.getID()) {
            return false;
        }

        this.isMoving = false;
        if (this.autoScroll) {
            var oldPos = this.getCurrentPagePosition();
            var currentPosition = this.container.getPosition();
            if (Math.abs(oldPos.x - currentPosition.x) > PagingScrollView.SWIPE_TRESHOLD * this.pageWidth
                || Math.abs(oldPos.y - currentPosition.y) > PagingScrollView.SWIPE_TRESHOLD * this.pageHeight) {
                if (currentPosition.x < oldPos.x || currentPosition.y < oldPos.y) {
                    this.onNextPageListener();
                } else {
                    this.onPrevPageListener();
                }
            } else {
                this.scrollToCurrentPage(false);
            }
        } else {
            if (this.horizontalLookDirection) {
                this.isMoving = true;
                this.container.runAction(new cc.Sequence(
                    new cc.MoveTo(0.5, { x: 0, y: this.basePosition.y }).easing(cc.easeOut(3)),
                    new cc.CallFunc(function () {
                        this.isMoving = false;
                    }.bind(this))
                ));
                return;
            }

            this.calcCurrentPage();
            this.calcDeltaPoint(this.convertTouchToNodeSpace(touch));
            this.deltaPoint = Math.max(Math.min(Math.round(this.deltaPoint), 5), -5);
            this.showPages([this.deltaPoint, this.deltaPoint]);
            this.isMoving = true;
            var pos = this.container.getPosition();
            var timeout = Math.max(0.5, Math.sqrt(Math.abs(this.deltaPoint)) / 2);

            var l = this.getScrollLength();
            if (this.direction === PagingScrollView.DIRECTIONS.Horizontal) {
                pos.x = Math.min(this.firstOffset, Math.max(pos.x + this.deltaPoint * cleverapps.styles.PagingScrollView.scrollInertia, -l.x));
                pos.y = 0;
            } else {
                pos.x = 0;
                pos.y = Math.min(this.firstOffset, Math.max(pos.y + this.deltaPoint * cleverapps.styles.PagingScrollView.scrollInertia, -l.y));
            }

            this.container.runAction(new cc.Sequence(
                new cc.MoveTo(timeout, pos).easing(cc.easeOut(2)),
                new cc.CallFunc(function () {
                    this.calcCurrentPage();
                    this.isMoving = false;
                }.bind(this))
            ));
        }
    },

    addPage: function (view) {
        this.container.addChild(view);
        if (view.onShow) {
            view.onShow();
        }

        if (!this.isMoving) {
            this.showCurrentPageOnly();
        }
    }
});

cleverapps.styles.PagingScrollView = {
    touchScrollSpeedLevel: 2,
    wheelScrollSpeed: 700,
    scrollInertia: 200,
    comingSoonExtraLength: [0, 0, 0],
    slowScroll: {
        timeout: 0.8
    }
};

PagingScrollView.DIRECTIONS = {
    Horizontal: 0,
    Vertical: 1
};

PagingScrollView.MOVE_TRESHOLD = 0.01;
PagingScrollView.SWIPE_TRESHOLD = 0.05;
PagingScrollView.SCROLL_SPEED_PERIODS = [25, 50, 75, 100];