/**
 * Created by iamso on 23.11.2020.
 */

var AdminLogView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.log = cleverapps.administrator.log;
        this.views = [];

        this.setAnchorPoint2();
        this.setContentSize2(cleverapps.styles.AdminLogView);

        this.createContent();
        this.addFrame();
        this.addModeToggle();

        this.log.on("addLogEntry", this.addEntry.bind(this), this);
        this.log.loadLastEntries();

        cleverapps.UI.applyHover(this, {
            onMouseOver: function () {},
            onMouseOut: function () {}
        });
    },

    addEntry: function (entry) {
        var view = this.createEntry(entry);
        view.x = this.width / 2;

        this.content.addChild(view);
        this.updateContent();
    },

    updateContent: function () {
        this.content.reshape();
        this.scroll.updateInnerContent();
        if (!this.getInteractiveStates().mouseover) {
            this.scroll.scrollToPercent(100);
        }
    },

    addFrame: function () {
        var frame = cleverapps.UI.createScale9Sprite(bundles.admin.frames.log_bg);
        frame.setContentSize(this.getContentSize());
        this.addChild(frame, 1);
        frame.setPositionRound(this.width / 2, this.height / 2);
    },

    addModeToggle: function () {
        var modeToggle = cleverapps.UI.generateOnlyText(this.log.targetEpisode !== undefined ? "EPISODE" : "ALL", cleverapps.styles.FONTS.ADMIN_TEXT_SELECTED);
        this.addChild(modeToggle, 1);
        modeToggle.setPositionRound(cleverapps.styles.AdminLogView.mode);

        cleverapps.UI.onClick(modeToggle, function () {
            cleverapps.administrator.log.toggleMode();
            modeToggle.setString(this.log.targetEpisode !== undefined ? "EPISODE" : "ALL");
            modeToggle.setPositionRound(cleverapps.styles.AdminLogView.mode);
        }.bind(this));
        cleverapps.UI.applyHover(modeToggle);
    },

    createContent: function () {
        var views = this.log.entries.map(function (entry) {
            return this.createEntry(entry);
        }, this);

        var content = this.content = new cleverapps.Layout(views, {
            direction: cleverapps.UI.VERTICAL,
            reversed: true
        });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(content);
        scroll.setContentSize(this.getContentSize());
        scroll.setBarPadding(cleverapps.styles.AdminLogView.scroll);
        this.addChild(scroll, -1);
        scroll.setPositionRound(this.width / 2, this.height / 2);
        scroll.scrollToPercent(100);
    },

    createEntry: function (entry) {
        var view = new AdminLogEntryView(entry, this);
        view.x = this.width / 2;
        this.views.push(view);
        return view;
    }
});

cleverapps.styles.AdminLogView = {
    width: 780,
    height: 150,

    scroll: {
        cornerPadding: 10,
        sidePadding: 10
    },

    mode: {
        x: { align: "right", dx: -5 },
        y: { align: "bottom", dy: -22 }
    }
};