/**
 * Created by spepa on 23.08.2022
 */

cleverapps.MygamesPayments = function () {
    cleverapps.RestPayments.call(this);
    this.currency = (new URLSearchParams(window.location.search)).get("ui_currency") || "RUB";

    for (var id in cleverapps.config.products) {
        var product = cleverapps.config.products[id];
        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedId = id;
    }
};

cleverapps.MygamesPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
cleverapps.MygamesPayments.prototype.constructor = cleverapps.MygamesPayments;

cleverapps.MygamesPayments.prototype.calcLoadedPrice = function (price) {
    var rate = cleverapps.EXCHANGE_RATES[this.currency];
    if (this.currency === "RUB") {
        price = Math.floor(price * cleverapps.RUB_DISCOUNTED_RATE);
    } else if (rate) {
        price = Math.ceil(price * rate);
    }

    if (cleverapps.MygamesPayments.TESTERS.indexOf(cleverapps.user.id) !== -1) {
        price = Math.ceil(price / 1000);
    }

    var symbol = Product.CURRENCY_SIGNS[this.currency] || this.currency;
    return price + " " + symbol;
};

cleverapps.MygamesPayments.prototype._purchaseOpen = function (product, callback) {
    var data = {
        uid: cleverapps.user.id,
        currency: this.currency,
        item_id: product.key,
        amount: parseFloat(product.loadedPrice),
        additional_param: "",
        description: product.description
    };

    cleverapps.RestClient.post("/mygamespayments", data, function (res) {
        if (res.error) {
            console.log(res.error);
            callback(cleverapps.Social.CODE_FAILED);
        } else {
            window.electronApi.showPayment(res.url);
        }
    });
};

cleverapps.MygamesPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.MygamesPayments.isAppropriate = function () {
    return cleverapps.platform instanceof Mygames;
};

cleverapps.MygamesPayments.TESTERS = [
    "223914816",
    "224977482",
    // "224974534",
    "224977211",
    "224977565"
];