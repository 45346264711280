/**
 * Created by mac on 4/7/20
 */

var Exclamation = function () {
    this.onShowListener = function () {};
    this.onHideListener = function () {};
};

Exclamation.prototype.show = function (message, type) {
    if (this.enabled) {
        this.onShowListener(message, type || Exclamation.Types.Normal);
    }
};

Exclamation.prototype.setGame = function (game) {
    this.game = game;

    this.game.on("stop", this.disable.bind(this));

    this.enabled = true;
};

Exclamation.prototype.disable = function () {
    if (this.enabled) {
        this.enabled = false;
        delete this.game;

        this.onHideListener();
    }
};

Exclamation.prototype.getCongratulations = function () {
    if (["heroes", "runes"].indexOf(cleverapps.config.name) !== -1) {
        return ["wow", "super", "yay", "yeah", "ohyeah", "oh"];
    } 
    return Messages.get("congratulations");
};

Exclamation.Types = {
    Normal: 100,
    Warning: 101,
    Congrats: 102
};