/**
 * Created by vladislav on 29.10.2021
 */

var SceneDebugger = function () {
    this.isTextsVisible = true;
    this.matchedNodes = [];
    this.hiddenNodes = [];

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.TOUCH_ONE_BY_ONE,
        swallowTouches: true,
        processTouch: this.processTouch.bind(this),
        onTouchBegan: this.onTouchBegan.bind(this)
    }), -100);

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.KEYBOARD,
        onKeyReleased: function (key) {
            if (key === cc.KEY.v) {
                this.toggleMatchedNodes();
            } else if (key === cc.KEY.Delete || key === cc.KEY.backspace) {
                this.removeSelectedNode();
            } else if (key === cc.KEY.u) {
                this.toggleUnitVisibility();
            }
        }.bind(this)
    }), 2);
};

SceneDebugger.prototype.toggleTexts = function () {
    if (this.isTextsVisible) {
        $(cc.LabelTTF).filter(function (node) {
            return cc.rectIntersectsRect(node.getBoundingBoxToWorld(), cleverapps.scenes.getRunningScene(node).getBoundingBox());
        }).hide();
    } else {
        $(cc.LabelTTF).show();
    }

    this.isTextsVisible = !this.isTextsVisible;
};

SceneDebugger.prototype.removeSelectedNode = function () {
    if (this.selectedNode && this.selectedNode.onDeletePressed) {
        this.selectedNode.onDeletePressed();
    } else if (this.selectedNode && this.selectedNode.removeFromParent) {
        this.selectedNode.removeFromParent();
        this.selectedNode = undefined;
    }
};

SceneDebugger.prototype.toggleMatchedNodes = function () {
    var visible;

    if (this.matchedNodes.length) {
        visible = !this.matchedNodes[0].visible;
        this.matchedNodes.forEach(function (node) {
            if (this.hiddenNodes[node.__instanceId]) {
                delete this.hiddenNodes[node.__instanceId];
            }
            node.setVisible(visible);
            if (!visible) {
                this.hiddenNodes[node.__instanceId] = node;
            }
        }, this);
    } else if (this.selectedNode) {
        visible = !this.selectedNode.visible;
        if (visible) {
            delete this.hiddenNodes[this.selectedNode.__instanceId];
        } else {
            this.hiddenNodes[this.selectedNode.__instanceId] = this.selectedNode;
        }
        this.selectedNode.setVisible(visible);
    } else if (Object.keys(this.hiddenNodes).length) {
        Object.keys(this.hiddenNodes).forEach(function (instanceId) {
            this.hiddenNodes[instanceId].setVisible(true);
        }, this);
        this.hiddenNodes = {};
    }
};

SceneDebugger.unitVisibility = true;

SceneDebugger.prototype.toggleUnitVisibility = function () {
    SceneDebugger.unitVisibility = !SceneDebugger.unitVisibility;

    var scene = cleverapps.scenes.getRunningScene();
    scene && this.visitUnitVisibility(scene);
};

SceneDebugger.prototype.visitUnitVisibility = function (node) {
    if (node instanceof UnitView) {
        node.visible = SceneDebugger.unitVisibility;
    } if (node instanceof GroundedView) {
        node.x = Math.abs(node.x) * (SceneDebugger.unitVisibility ? 1 : -1);
    }

    var children = node.getChildren();
    for (var i = 0; i < children.length; i++) {
        this.visitUnitVisibility(children[i]);
    }

    if (node.iterateTiles) {
        node.iterateTiles(function (tile) {
            if (tile instanceof cc.Node) {
                this.visitUnitVisibility(tile);
            }
        }.bind(this));
    }
};

SceneDebugger.prototype.processTouch = function (v) {
    if (!v || !v.visible) {
        return;
    }
    if (typeof EditorScene !== "undefined" && v instanceof EditorScene) {
        return;
    }

    if (v.hasTouch(this.touch) && !v.isExcluded && !this.excluded.some(function (nodeClass) {
        return v instanceof nodeClass;
    }) && cleverapps.meta.checkEventNode(v)) {
        this.matchedNode = v;
        if (this.preferred.some(function (filter) {
            return filter(v);
        })) {
            this.preferredNode = v;
        }
    }

    if (v.iterateVisibleTiles) {
        v.iterateVisibleTiles(function (tile) {
            if (!this.preferredNode && tile instanceof cc.Node) {
                this.processTouch(tile);
            }
        }.bind(this));
    }

    if (!v.children) {
        return;
    }

    for (var i = 0; i < v.children.length && !this.preferredNode; i++) {
        this.processTouch(v.children[i]);
    }
};

SceneDebugger.prototype.onTouchBegan = function (touch) {
    if (cleverapps.config.wysiwygMode) {
        return;
    }

    if (this.matchedNode && this.matchedNode.debugButton) {
        var button = this.matchedNode.debugButton;
        var location = button.convertTouchToNodeSpace(touch);
        if (cc.rectContainsPoint(cc.rect(0, 0, button.width, button.height), location)) {
            return;
        }
    }

    this.matchedNode = undefined;
    this.preferredNode = undefined;
    this.touch = touch;
    this.excluded = [SideBarView, cleverapps.UI.ScrollView, cleverapps.UI.ScrollBarContainer];
    if (typeof CenterHint !== "undefined") {
        this.excluded.push(CenterHint);
    }
    if (typeof KeypadView !== "undefined") {
        this.excluded.push(KeypadView);
    }
    if (typeof FireworksAnimation !== "undefined") {
        this.excluded.push(FireworksAnimation);
    }
    if (typeof MergeTutorialView !== "undefined") {
        this.excluded.push(MergeTutorialView);
    }

    this.preferred = [];
    if (typeof UnitView !== "undefined") {
        this.preferred.push(function (node) {
            return node instanceof UnitView; 
        });

        this.preferred.push(function (node) {
            return node.debugUnitImage;
        });
    }

    if (cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
        this.matchedNodes.forEach(function (node) {
            node.cleanupBorder();
        });
        this.matchedNodes = [];
        this.processTouch(cleverapps.scenes.getRunningScene());

        if (this.matchedNode) {
            this.matchedNode.debugParents();
            this.matchedNode.debugBorder({ showPosition: true, showAttributes: true });
            this.matchedNodes.push(this.matchedNode);
            return true;
        }
    }

    if (this.matchedNodes.length) {
        this.matchedNodes.forEach(function (node) {
            node.cleanupBorder();
        });
        this.matchedNodes = [];
    }
    return false;
};

SceneDebugger.prototype.createSpine = function (json) {
    var scene = cleverapps.scenes.getRunningScene();

    var spine = new cleverapps.Spine(json);
    spine.setPositionRound(scene.width / 2, scene.height / 2);
    scene.addChild(spine);

    spine.setVisible(true);
    spine.debugBorder();

    console.log("skins:", Object.keys(spine.data.skins));
    console.log("animations:", Object.keys(spine.data.animations));
};