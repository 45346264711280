/**
 * Created by vladislav on 9/6/2022
 */

var CleverApps = function () {
    Platform.call(this, "cleverapps");

    this.migrateFromXsolla();
};

CleverApps.prototype = Object.create(Platform.prototype);
CleverApps.prototype.constructor = CleverApps;

CleverApps.prototype.getLocalStoragePreffix = function () {
    return "_cleverapps";
};

CleverApps.prototype.needWindowForLogin = function () {
    return true;
};

CleverApps.prototype.migrateFromXsolla = function () {
    var userXsolla = cc.sys.localStorage.getItem(cleverapps.config.name + "_xsolla");
    if (!userXsolla) {
        return;
    }
    userXsolla = JSON.parse(userXsolla);

    var user = cc.sys.localStorage.getItem(cleverapps.config.name + this.getLocalStoragePreffix());
    user = user && JSON.parse(user);
    var userId = user && user.id;

    if (userId === userXsolla.id) {
        return;
    }
    
    var needMigrate = !userId || (userId.startsWith("__")
            && ((userXsolla.episode - user.episode) || (userXsolla.level - user.level) || (userXsolla.exp - user.exp)) > 0);

    cleverapps.whenAllInitialized(function () {
        var oldUserId = needMigrate ? userId : userXsolla.id;
        if (oldUserId) {
            cleverapps.userIdsHistory.add(oldUserId);
        }
    });

    if (!needMigrate) {
        return;
    }

    cc.sys.localStorage.setItem(cleverapps.config.name + this.getLocalStoragePreffix(), JSON.stringify(userXsolla));
    cc.sys.localStorage.setItem(cleverapps.config.name + this.getLocalStoragePreffix() + "_checksum", -1);
};