/**
 * Created by Ivan on 01.09.22
 */

var GoogleWebAds = function () {
    AdsPlugin.call(this, {
        cantLoadAndPlayInParallel: true
    });
    AdsPlugin.EXPIRATION_TIME = cleverapps.parseInterval("100 days");
};

GoogleWebAds.prototype = Object.create(AdsPlugin.prototype);
GoogleWebAds.prototype.constructor = GoogleWebAds;

GoogleWebAds.isAppropriate = function () {
    return cleverapps.platform.oneOf(CleverApps, Xiaomi);
};

GoogleWebAds.prototype.init = function (callback) {
    console.log("Initing GoogleWebAds");

    var attrs = {
        "data-ad-client": "ca-pub-9277187477340577",
        "data-ad-frequency-hint": "5s"
    };
    if (cleverapps.platform instanceof Xiaomi) {
        attrs["data-ad-client"] = "ca-pub-5038666584748769";
    }

    if (cleverapps.config.debugMode) {
        attrs["data-adbreak-test"] = "on";
    }

    cleverapps.loadSdk("//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
        attrs: attrs,
        crossorigin: true,

        onSuccess: function () {
            console.log("GoogleWebAds API loaded");
            window.adsbygoogle = window.adsbygoogle || [];

            this.adConfig({
                preloadAdBreaks: "on",
                sound: "on",
                onReady: function () {
                    console.log("GoogleWebAds ready");
                    callback();
                }
            });
        }.bind(this),
        onFailure: function () {
            console.log("Failed load adsbygoogle.js");
        }
    });
};

GoogleWebAds.prototype.adBreak = function (options) {
    window.adsbygoogle.push(options);
};

GoogleWebAds.prototype.adConfig = function (options) {
    window.adsbygoogle.push(options);
};

GoogleWebAds.prototype._cache = function (name, callback) {
    if (name === RewardedAdsManager.REWARDED) {
        var rewarded = false;

        this.adBreak({
            name: name,
            type: "reward",
            beforeAd: function () {
                cc.game.pause();
            },
            afterAd: function () {
                cc.game.resume();
            },
            beforeReward: function (showAdFn) {
                callback(AdsPlugin.CODE_SUCCEED, showAdFn);
            },
            adViewed: function () {
                rewarded = true;
            },
            adDismissed: function () {
                rewarded = false;
            },
            adBreakDone: function () {
                if (this._callback) {
                    this._callback(rewarded ? AdsPlugin.CODE_REWARD : undefined);
                    this._callback = undefined;
                } else {
                    callback();
                }
            }.bind(this)
        });
    }
};

GoogleWebAds.prototype._playAd = function (name, ad, callback) {
    if (typeof ad !== "function") {
        callback && callback();
        return;
    }
    
    if (name === RewardedAdsManager.REWARDED) {
        this._callback = callback;
        ad();
    }
};

GoogleWebAds.prototype.getECPM = function () {
    return RewardedAdsManager.eCPM.GoogleWeb;
};