/**
 * Created by mac on 3/19/23
 */

var CarView = cc.Scale9Sprite.extend({
    ctor: function (car) {
        this.car = car;

        this._super(bundles.jam.frames.cell_bg_red_png);

        this.setContentSize2(cleverapps.styles.JamScene.cell.width * car.width, cleverapps.styles.JamScene.cell.height * car.height);
        this.setPositionByCar(car);

        if (!car.isGoal()) {
            this.setColor(cleverapps.Random.choose(CarView.CellImages));
        }

        if (car.isHorizontal()) {
            this.setAnchorPoint2(0, 0);
        } else {
            this.setAnchorPoint(0, 1);
        }

        car.onMoveListener = this.createListener(this.moved.bind(this));
    },

    moved: function () {
        this.setPositionByCar();
    },

    setColor: function (colorFrame) {
        this.setSpriteFrame(new cc.Sprite(colorFrame).getSpriteFrame());
    },

    setPositionByCar: function () {
        this.setPositionRound(this.car.x * cleverapps.styles.JamScene.cell.width, this.car.visibleY() * cleverapps.styles.JamScene.cell.height);
    }
});

CarView.CellImages = [
    bundles.jam.frames.cell_bg_green_png,
    bundles.jam.frames.cell_bg_blue_png,
    bundles.jam.frames.cell_bg_yellow_png,
    bundles.jam.frames.cell_bg_purple_png
];