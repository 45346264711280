/**
 * Created by Andrey Popov on 08.10.2021.
 */
var AtlasAnalyzerScene = cleverapps.FixedWidthScene.extend({
    ctor: function (atlasAnalyzer, options) {
        this.isWide = true;
        this._super();

        this.atlasAnalyzer = atlasAnalyzer;
        this.options = options;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_ATLAS_ANALYZER);

        var options = this.options;

        cleverapps.Synchronizer.prototype.showClientSessionExpiredWindow = function () {};

        var size = cleverapps.UI.getWinSize(true);

        var sceneViewHeight = size.height - cleverapps.styles.AnalyzerMenubarView.height - 2 * cleverapps.styles.AtlasAnalyzerScene.margin;
        var sceneViewWidth = size.width - cleverapps.styles.BundlesExplorerView.width - cleverapps.styles.ImageSpyView.width;

        var bundlesExplorerView = new BundlesExplorerView(this.atlasAnalyzer.bundlesExplorer, { height: sceneViewHeight });
        var textureImageView = new TextureImageView(this.atlasAnalyzer.textureImage, {
            height: sceneViewHeight,
            width: sceneViewWidth
        });
        var imageSpyView = new ImageSpyView(this.atlasAnalyzer.imageSpy, { height: sceneViewHeight });

        bundlesExplorerView.setLocalZOrder(2);
        textureImageView.setLocalZOrder(1);
        imageSpyView.setLocalZOrder(2);
        var horizontalLayout = new cleverapps.Layout([bundlesExplorerView, textureImageView, imageSpyView], {
            direction: cleverapps.UI.HORIZONTAL
        });

        var menubarView = new AnalyzerMenubarView(this.atlasAnalyzer.analyzerMenubar);
        menubarView.setLocalZOrder(1);

        var verticalLayout = new cleverapps.Layout([menubarView, horizontalLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AtlasAnalyzerScene.margin
        });

        verticalLayout.setAnchorPoint2(0, 0);
        verticalLayout.setPositionRound(0, 0);
        this.scene.addChild(verticalLayout);

        this.atlasAnalyzer.showUp(options.tab || "Main", options.bundleName, options.frame);

        cleverapps.debugStats.setEnabled(false);
    },

    _closeAction: function () {
        cleverapps.debugStats.setEnabled(true);

        cleverapps.setUrlHash({
            atlasAnalyzer: undefined, tab: undefined, bundleName: undefined, frame: undefined 
        });
        cleverapps.FixedWidthScene.prototype._closeAction.call(this);
    },

    setBackground: function () {
        if (this.background) {
            this.background.removeFromParent();
        }
        var winSize = cleverapps.UI.getWinSize(true);
        var background = this.background = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        background.setColor(new cc.Color(255, 255, 255, 255));
        background.setContentSize2(winSize.width, winSize.height);
        background.setAnchorPoint(0, 0);
        background.setPositionRound(0, -cleverapps.UI.getSceneOffsetY());
        background.setLocalZOrder(-10);
        this.updateBackgroundScale();
        this.scene.addChild(background);
    },

    listBundles: function () {
        return ["wysiwyg"];
    }
});

AtlasAnalyzerScene.open = function (atlasAnalyzer, bundleName) {
    (cleverapps.meta.isFocused() ? cleverapps.meta.distract : cleverapps.meta.display).call(cleverapps.meta, {
        focus: "atlasAnalyzer",
        control: ["SceneControlButtonsView"],
        action: function (f) {
            cleverapps.scenes.replaceScene(new AtlasAnalyzerScene(atlasAnalyzer, {
                bundleName: bundleName
            }), f);
        }
    });
};

cleverapps.styles.AtlasAnalyzerScene = {
    margin: 4
};

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!params.atlasAnalyzer || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (returnClassOnly) {
            return AtlasAnalyzerScene;
        }

        if (!cleverapps.atlasAnalyzer) {
            cleverapps.atlasAnalyzer = new AtlasAnalyzer(params.tab);
        }
        var atlasAnalyzer = cleverapps.atlasAnalyzer;

        var scene = new AtlasAnalyzerScene(atlasAnalyzer, {
            tab: params.tab,
            bundleName: params.bundleName,
            frame: params.frame
        });
        cleverapps.scenes.replaceScene(scene, f);
    };
}());