/**
 * Created by Andrey Popov on 10.04.2020
 */

var MoreTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = MoreTile.styles = MoreTile.styles || cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.MoreTile, true);
        this._super(tileModel, styles, options);
    },

    createIcon: function () {
    },

    createTitle: function () {
        var title = cleverapps.UI.generateOnlyText('ShopWindow.showMore', cleverapps.styles.FONTS.WINDOW_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setDimensions(this.styles.text.width, 0);
        title.fitTo(undefined, this.styles.text.height);
        return title;
    },

    createButton: function () {
        return new TileButton(this.tileModel, {
            onClicked: this.options.onClicked,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            noIcons: true
        });
    }
});

cleverapps.styles.MoreTile = {
    width: 240,
    height: 350,
    scale: [1, 1, 1],

    content: {
        margin: 20
    },

    text: {
        width: 150,
        height: 200
    }
};
