/**
 * Created by vladislav on 29.10.2021
 */

var Shortcuts = function () {
    Shortcuts.List.forEach(function (shortcut) {
        var combo = shortcut.keys.map(function (key) {
            return cc.KEY[key];
        });

        cleverapps.keyboardController.bindKeys(shortcut.name, combo, shortcut.action);
    });
};

Shortcuts.GetMenu = function () {
    var toolItems = {};

    Shortcuts.List.forEach(function (shortcut) {
        var key = shortcut.keys.map(function (key) {
            return key.toUpperCase();
        }).join("+");
        toolItems[key] = shortcut.action;
    }, this);

    return toolItems;
};

Shortcuts.List = [
    {
        name: "debugDrawCalls",
        keys: ["ctrl", "q"],
        action: function () {
            cleverapps.drawCallsDebugger.debugDrawCalls();
        }
    },
    {
        name: "toggleTexts",
        keys: ["ctrl", "y"],
        action: function () {
            cleverapps.sceneDebugger.toggleTexts();
        }
    },
    {
        name: "captureSnapshot",
        keys: ["alt", "s"],
        action: function () {
            cleverapps.snapshots.createSnapshot();
        }
    },
    {
        name: "recordActions",
        keys: ["alt", "r"],
        action: function () {
            cleverapps.snapshotBar.toggleRecord();
        }
    },
    {
        name: "toggleDebugInfo",
        keys: ["d"],
        action: function () {
            cleverapps.flags.toggle("debugInfo");
        }
    }
];

Shortcuts.GetMemo = function () {
    var memo = {};

    Shortcuts.Memo.forEach(function (item) {
        memo[item.name + "(" + item.keys.map(function (key) {
            return key.toUpperCase();
        }).join("+") + ")"] = function () {};
    }, this);

    return memo;
};

Shortcuts.Memo = [
    {
        name: "debugDrawCalls",
        keys: ["ctrl", "q"]
    },
    {
        name: "toggleTexts",
        keys: ["ctrl", "y"]
    },
    {
        name: "toggleMatchedNodes",
        keys: ["v"]
    },
    {
        name: "removeSelectedNode",
        keys: ["Delete"]
    },
    {
        name: "selectNode",
        keys: ["ctrl", "lmb"]
    },
    {
        name: "selectMultipleNodes",
        keys: ["shift", "lmb"]
    },
    {
        name: "moveNode 1px [10px]",
        keys: ["arrows [shift]"]
    },
    {
        name: "scale",
        keys: ["s", "arrows"]
    },
    {
        name: "mirror",
        keys: ["m", "arrows"]
    },
    {
        name: "rotate",
        keys: ["r", "arrows"]
    },
    {
        name: "changeWidth",
        keys: ["w", "up/down"]
    },
    {
        name: "changeHeight",
        keys: ["h", "up/down"]
    },
    {
        name: "changeTTFDimensions",
        keys: ["d", "arrows"]
    },
    {
        name: "copyUnit",
        keys: ["c", "lmb"]
    },
    {
        name: "deleteUnit [all same units]",
        keys: ["rmb [shift]"]
    },
    {
        name: "toggle unitLock",
        keys: ["l", "lmb"]
    },
    {
        name: "capture snapshot",
        keys: ["alt", "s"]
    },
    {
        name: "record actions",
        keys: ["alt", "r"]
    },
    {
        name: "hide units",
        keys: ["u"]
    },
    {
        name: "debugInfo",
        keys: ["d"]
    }
];