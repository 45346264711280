/**
 * Created by Ivan on 04.07.2023
 */

var PreviewSliderView = cc.Node.extend({
    ctor: function (previewSlider) {
        this._super();

        this.previewSlider = previewSlider;

        this.previewSlider.on("changeScene", this.onChangeScene.bind(this), this);

        var styles = cleverapps.styles.PreviewSliderView.slider;

        var slider = this.slider = new Slider();
        this.slider.setContentSize2(styles);
        this.slider.setPositionRound(this.slider.width / 2, 0);

        this.addChild(slider);

        slider.percentChangeCallback = function (sender) {
            this.previewSlider.setScene(Math.round(sender.getPercent() * (this.previewSlider.countOfScenes - 1) / 100));
        }.bind(this);
        slider.incPercentCallback = function () {
            this.previewSlider.setScene(this.previewSlider.current + 1);
        }.bind(this);
        slider.decPercentCallback = function () {
            this.previewSlider.setScene(this.previewSlider.current - 1);
        }.bind(this);

        this.addLabels();
        this.setContentSize2(this.slider.width, this.slider.height);
    },

    addLabels: function () {
        var labels = this.previewSlider.getNamesOfScenes();
        labels.forEach(function (label, index) {
            var name = cleverapps.UI.generateOnlyText(label, cleverapps.styles.FONTS.SLIDER_ITEM_TEXT);
            var percent = index / (this.previewSlider.countOfScenes);
            name.setPositionRound(percent * this.slider.width, cleverapps.styles.PreviewSliderView.label.y);
            this.addChild(name);
        }.bind(this), this);
    },

    onChangeScene: function () {
        this.slider.setPercent(this.previewSlider.current * 100 / (this.previewSlider.countOfScenes));
    }
});

cleverapps.styles.PreviewSliderView = {
    slider: {
        width: 150,
        height: 10
    },
    label: {
        y: 0
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLIDER_ITEM_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});