/**
 * Created by spepa on 20.12.2022
 */

var PeriodicSaleLot = cleverapps.Layout.extend({
    ctor: function (lot, logic) {
        this.lot = lot;
        this.logic = logic;

        var items = this.createItems();
        var button = this.crateButton();

        this._super([items, button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.PeriodicSaleLot.buttonMargin
        });

        var styles = cleverapps.styles.PeriodicSaleLot;
        var bg = cleverapps.UI.createScale9Sprite(bundles.periodic_sale.frames.lotBg);
        bg.setContentSize(this.width + styles.lotBg.padding.x, this.height + styles.lotBg.padding.y);
        bg.setPositionRound(this.width / 2, this.height / 2 + styles.lotBg.offset.y);
        this.addChild(bg, -1);

        this.updateState();
    },

    updateState: function () {
        if (this.logic.isSoldLot(this.lot.id)) {
            this.itemsNode.setOpacity(160);
            this.button.label.setOpacity(160);
            this.button.setString(Messages.get("SoldOut"));
            this.button.disable();
        }
    },

    createItems: function () {
        var styles = cleverapps.styles.PeriodicSaleLot;

        var icons = [];
        var units = this.lot.reward.units;
        for (var i = 0; i < units.amount; i++) {
            var icon = UnitView.getUnitImage(units);
            cleverapps.UI.fitToBox(icon, styles.item);

            var iconNode = new cc.Node();
            iconNode.setAnchorPoint2();
            iconNode.setContentSize2(styles.item);
            iconNode.setLocalZOrder(units.amount - i);
            icon.setPositionRound(iconNode.width / 2, iconNode.height / 2);
            iconNode.addChild(icon);
            icons.push(iconNode);
        }
        var itemsNode = this.itemsNode = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.item.margin
        });
        itemsNode.setCascadeOpacityEnabledRecursively(true);

        var amount = cleverapps.UI.generateImageText("x" + units.amount, cleverapps.styles.FONTS.PERIODIC_LOT_AMOUNT);
        amount.setPositionRound(styles.amount);
        itemsNode.addChild(amount, 10);

        var bg = cleverapps.UI.createScale9Sprite(bundles.periodic_sale.frames.itemsBg);
        bg.setContentSize(itemsNode.width + styles.itemsBg.padding.x, itemsNode.height + styles.itemsBg.padding.y);
        bg.setPositionRound(itemsNode.width / 2, itemsNode.height / 2);
        itemsNode.addChild(bg, -1);

        return itemsNode;
    },

    crateButton: function () {
        var styles = cleverapps.styles.PeriodicSaleLot.button;

        var items = [];
        var button = this.button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            text: "$$" + this.lot.price,
            width: styles.width,
            height: styles.height,
            onClicked: this.onClick.bind(this)
        });
        items.push(button);

        var icon = new cc.Sprite(bundles.reward_icons.frames.gold_small_png);
        cleverapps.UI.fitToBox(icon, styles.icon);
        var oldPrice = cleverapps.UI.generateImageText(this.lot.oldPrice, cleverapps.styles.FONTS.PERIODIC_LOT_WAS);

        var label = button.label = new cleverapps.Layout([icon, oldPrice], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        label.setCascadeOpacityEnabled(true);
        label.setAnchorPoint(0.5, 0);
        label.setPositionRound(styles.label);
        button.addChild(label);

        var line = new cc.Scale9Sprite(bundles.digits.frames.underline_png);
        line.setContentSize(label.width + styles.label.linePadding, line.height);
        label.addChild(line);
        line.setRotation(-8);
        line.setPositionRound(label.width / 2, label.height / 2);
        line.setColor(cleverapps.styles.COLORS.COLOR_RED);

        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    onClick: function () {
        var lots = this.logic.getLots();
        var sold = this.logic.countSoldLots();

        var cellsRequired = this.lot.reward.units.amount;
        if (lots.length - sold === 1) {
            RewardsConfig.PeriodicSale.unit.forEach(function (unit) {
                cellsRequired += unit.amount;
            });
        }

        var notEnough = cellsRequired - Game.currentGame.map.countEmptySlots();
        if (notEnough > 0) {
            Game.currentGame.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
            return;
        }

        if (levels.user.spendHard(cleverapps.EVENTS.SPENT.PERIODIC_LOT + this.lot.id, this.lot.price)) {
            new RewardWindow(this.lot.reward);
            this.logic.lotBought(this.lot.id);
            this.updateState();
        }
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.styles.PeriodicSaleLot = {
    buttonMargin: 60,

    lotBg: {
        offset: { y: 0 },
        padding: {
            x: 90,
            y: 40
        }
    },

    itemsBg: {
        padding: {
            x: 40,
            y: 0
        }
    },

    item: {
        width: 150,
        height: 170,
        maxScale: 1.4,
        margin: -60
    },

    amount: {
        x: { align: "right", dx: 10 },
        y: { align: "bottom", dy: 0 }
    },

    button: {
        width: 240,
        height: 100,
        margin: 5,

        icon: {
            width: 65,
            height: 46
        },

        label: {
            linePadding: 6,
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 50 }
        },

        padding: {
            top: 40
        }
    }
};