/**
 * Created by vladislav on 08.10.2019
 */

var RewardsListComponent = cc.Node.extend({
    ctor: function (reward, options) {
        this._super();

        this.reward = reward instanceof RewardsList ? reward : new RewardsList(reward, options);
        this.options = options || {};

        this.createContent();
        if (!options.noShowControls) {
            cleverapps.meta.showControlsWhileFocused(this.reward.listControls());
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.RewardsListComponent;

        var targets = this.targets = [];

        this.reward.listRewards().forEach(function (reward) {
            if (reward.getAmount()) {
                targets.push(this.createOneReward(reward));
            }
        }, this);

        this.content = new cleverapps.WrapGridLayout(targets, {
            columns: this.options.columns || this.calcColumnsCount(targets.length),
            margin: {
                x: this.options.margin && this.options.margin.x !== undefined ? this.options.margin.x : styles.margin.x,
                y: this.options.margin && this.options.margin.y !== undefined ? this.options.margin.y : styles.margin.y
            }
        });

        this.addChild(this.content);
        this.setContentSize2(this.content);
        this.setAnchorPoint2();
        this.content.setAnchorPoint2();
        this.content.setPositionRound(this.width / 2, this.height / 2);
    },

    createOneReward: function (reward) {
        var styles = cleverapps.styles.RewardsListComponent;

        var icon;
        if (this.options.icons && this.options.icons[reward.type]) {
            icon = new cc.Sprite(this.options.icons[reward.type]);
        } else if (this.options.small) {
            icon = reward.getSmallIcon();
        } else {
            icon = reward.getIcon();
        }

        if (this.options.iconDimensions) {
            cleverapps.UI.fitToBox(icon, {
                width: this.options.iconDimensions.width,
                height: reward.type === "units" && this.options.iconWrap && this.options.iconWrap.height || this.options.iconDimensions.height,
                maxScale: this.options.iconDimensions.maxScale
            });
        }

        var prefix = this.options.noPrefix ? "" : "x";
        var text = cleverapps.UI.generateTTFText(reward.getText(prefix), this.options.font || cleverapps.styles.FONTS.REWARDS_LIST_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT);

        if (this.options.iconWrap) {
            var iconWrap = new cc.Node();
            iconWrap.setAnchorPoint2();
            iconWrap.setContentSize(this.options.iconWrap.width || icon.width * icon.scale, this.options.iconWrap.height || icon.height * icon.scale);

            iconWrap.addChild(icon);
            icon.setPositionRound(iconWrap.width / 2, iconWrap.height / 2);

            icon = iconWrap;
        }

        if (this.options.textWrap) {
            var textWrap = new cc.Node();
            textWrap.setAnchorPoint2();
            textWrap.setContentSize(this.options.textWrap);

            textWrap.addChild(text);
            text.setPositionRound(textWrap.width / 2, textWrap.height / 2);

            text = textWrap;
        }

        var target = new cleverapps.Layout([icon, text], {
            direction: this.options.textDirection !== undefined ? this.options.textDirection : cleverapps.UI.VERTICAL,
            margin: this.options.textMargin !== undefined ? this.options.textMargin : styles.textMargin
        });
        target.icon = icon;
        target.text = text;
        target.reward = reward;

        if (this.options.shine) {
            var animation = target.shine = new cleverapps.Spine(bundles.main.jsons.reward_shine_json);
            animation.setAnimationAndIdleAfter("open", "idle");
            animation.setPositionRound(styles.shine);
            animation.setLocalZOrder(-1);
            target.addChild(animation);
        }

        if (reward.type === "units") {
            target.getPreferredBundles = function () {
                return ["units"];
            };
        }

        return target;
    },

    showUp: function (delay) {
        delay = delay || 300;

        this.targets.forEach(function (target) {
            if (target.shine) {
                target.shine.setVisible(false);
            }

            target.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(delay / 1000),
                new cc.ScaleTo(0, 1.2),
                new cc.Show(),
                new cc.ScaleTo(0.18, 0.9).easing(cc.easeInOut(1.2)),
                new cc.PlaySound(bundles.main.urls.reward_icon_show_effect, { throttle: 0 }),
                new cc.CallFunc(function () {
                    if (target.shine) {
                        target.shine.setVisible(true);
                        target.shine.setAnimationAndIdleAfter("open", "idle");
                    }
                }),
                new cc.ScaleTo(0.12, 1).easing(cc.easeInOut(1.2))
            ));

            delay += 300;
        });

        return delay;
    },

    receiveRewards: function () {
        this.reward.receiveRewards();
    },

    receiveRewardsAnimation: function (callback) {
        var delay = 0;
        var duration = 0;
        var flyingUnderShadow = false;
        var doNotWait = false;

        cleverapps.audio.playSound(bundles.main.urls.win_reward_effect);

        this.targets.forEach(function (target, index) {
            if (!this.options.noFadeOut) {
                target.setCascadeOpacityEnabledRecursively(true);
                if (target.shine) {
                    target.shine.setCascadeOpacityEnabled(false);
                }

                target.runAction(new cc.Sequence(
                    new cc.DelayTime(delay / 1000),
                    new cc.FadeOut(0.3),
                    new cc.CallFunc(function () {
                        if (target.shine) {
                            target.shine.setAnimation(1, "close", false);
                        }
                    })
                ));
            }

            var flyingTarget = cleverapps.aims.getTarget(target.reward.getFlyingTarget());
            if (flyingTarget) {
                flyingUnderShadow = flyingUnderShadow || flyingTarget.aim.flyingUnderShadow;
                doNotWait = doNotWait || flyingTarget.aim.doNotWait;
            }

            target.icon.setLocalZOrder(this.targets.length - index);

            target.reward.collectRewardsAnimation(target.icon, {
                delay: delay + 200,
                beginScale: 1
            });

            duration = Math.max(duration, delay + 1300 + target.reward.getFlyingAmount() * 150);
            delay += 300;
        }.bind(this));

        if (callback) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay / 1000),
                new cc.CallFunc(function () {
                    var window = cleverapps.windows.currentWindow();
                    if (window && flyingUnderShadow) {
                        window.hideSelf();
                    }
                }),
                new cc.DelayTime(doNotWait ? 0 : (duration - delay) / 1000),
                new cc.CallFunc(callback)
            ));
        }
    },

    calcColumnsCount: function (rewardsCount) {
        if (rewardsCount <= 3) {
            return rewardsCount;
        }

        if (cleverapps.wideMode.mode === cleverapps.WideMode.HORIZONTAL) {
            return 4;
        }

        if (rewardsCount === 4 && cleverapps.wideMode.mode !== cleverapps.WideMode.HORIZONTAL) {
            return 2;
        }

        return 3;
    },

    getRewardsAmount: function () {
        return this.targets.length;
    }
});

cleverapps.styles.RewardsListComponent = {
    margin: {
        x: 30,
        y: 30
    },
    shine: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 40 }
    },
    textMargin: 10
};