/**
 * Created by Aleksandr on 29.09.2022.
 */

cleverapps.PlingaPayments = function () {
    cleverapps.RestPayments.call(this);

    for (var itemId in cleverapps.config.products) {
        var product = cleverapps.config.products[itemId];
        product.loadedId = itemId;
    }
};

cleverapps.PlingaPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
cleverapps.PlingaPayments.prototype.constructor = cleverapps.PlingaPayments;

cleverapps.PlingaPayments.prototype._purchaseOpen = function (product, callback) {
    cleverapps.platform.exitFullscreen(function () {
        plingaRpc.purchasePackage(product.itemId, function (data) {
            if (data.paymentStatus === "paid") {
                product.packageName = data.packageName;
                callback(cleverapps.Social.CODE_SUCCEED, product);
            } else {
                callback(cleverapps.Social.CODE_FAILED);
            }
        });
    });
};

cleverapps.PlingaPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.PlingaPayments.isAppropriate = function () {
    return cleverapps.platform instanceof Plinga;
};
