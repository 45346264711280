/**
 * Created by iamso on 24.09.2021
 */

cleverapps.MicrosoftPayments = function () {
    cleverapps.Payments.call(this);
    Product.WAIT_NO_MORE = 20000;

    MicrosoftProducts = MicrosoftProducts[cleverapps.config.name];

    this.storePlugin = cleverapps.MicrosoftStorePlugin;
};

cleverapps.MicrosoftPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.MicrosoftPayments.prototype.constructor = cleverapps.MicrosoftPayments;

cleverapps.MicrosoftPayments.prototype.init = function (callback) {
    this.onInitCallback = callback;

    this.storePlugin.init();

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.checkInitialized.bind(this));
    cleverapps.timeouts.setInterval(this.checkInitialized.bind(this), cleverapps.parseInterval("2 minutes"));
};

cleverapps.MicrosoftPayments.prototype.checkInitialized = function () {
    if (!this.initialized) {
        this.storePlugin.init();
    }
};

cleverapps.MicrosoftPayments.prototype._purchase = function (product, callback) {
    var microId = MicrosoftProducts[product.itemId];

    if (!microId) {
        console.log("MicrosoftPayments.open " + product.itemId + " microId not available");
        callback(cleverapps.Social.CODE_FAILED);
        return;
    }

    // this.storePlugin.purchase(MicrosoftProducts.testProduct);
    this.storePlugin.purchase(microId);
};

cleverapps.MicrosoftPayments.prototype.onPurchaseResult = function (data) {
    var product = Product.Create(data.productId) || {};
    var code, purchase;

    if (data.canceled) {
        console.log("MicrosoftPayments.onPurchaseCancelled", product.key);
        code = cleverapps.Social.CODE_CANCELLED;
    } else if (data.error) {
        console.log("MicrosoftPayments.onPurchaseFailure", product.key, data.error);
        code = cleverapps.Social.CODE_FAILED;
    } else {
        console.log("MicrosoftPayments.onPurchaseSuccess", product.key, data);
        code = cleverapps.Social.CODE_SUCCEED;
        purchase = product;
        purchase.trackingId = data.trackingId;

        purchase.loadedCurrencyCode = purchase.loadedCurrencyCode || "USD";
        purchase.loadedPriceAmount = parseFloat(product.loadedPrice.replace(",", ".").replace(/[^\d.]/g, "")) || product.price;
        purchase.transactionId = data.trackingId;
    }

    cleverapps.Payments.prototype.onPurchaseResult.call(this, code, purchase);
};

cleverapps.MicrosoftPayments.prototype.onInit = function (data) {
    console.log("MicrosoftPayments.onInit - " + JSON.stringify(data));

    if (data.error) {
        console.log("Error requesting microsoft products", data.error);
        return;
    }

    if (this.initialized) {
        console.log("Already initialized");
        return;
    }

    if (MicrosoftDevs.indexOf(cleverapps.platform.getDeviceId()) !== -1) {
        MicrosoftProducts.gold500 = MicrosoftProducts.testProduct;
    }

    var products = data.products;

    for (var ourProductId in MicrosoftProducts) {
        var microId = MicrosoftProducts[ourProductId];
        var ourProduct = cleverapps.config.products[ourProductId];
        var product = products[microId];

        if (ourProduct && product) {
            ourProduct.loadedPrice = product.price;
            ourProduct.loadedCurrencyCode = product.currencyCode;
            ourProduct.loadedId = microId;
            console.log("MicrosoftPayments product " + ourProductId + " " + microId + " " + ourProduct.loadedPrice);
        }
    }

    if (this.onInitCallback) {
        this.onInitCallback();
        delete this.onInitCallback;
    }
};

cleverapps.MicrosoftPayments.prototype.validate = function (purchase, onSuccess) {
    if (onSuccess) {
        onSuccess();
    }
};

cleverapps.MicrosoftPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.MicrosoftPayments.isAppropriate = function () {
    return cleverapps.platform instanceof Microsoft;
};

cleverapps.MicrosoftStorePlugin = {
    init: function () {
        MicrosoftNotifier(JSON.stringify({
            handler: "store",
            method: "init"
        }));
    },

    purchase: function (productId) {
        MicrosoftNotifier(JSON.stringify({
            handler: "store",
            method: "purchase",
            productId: productId
        }));
    }
};