/**
 * Created by Denis Kuzin on 17 july 2023
 */

var PointerView = cleverapps.Spine.extend({
    ctor: function (options) {
        this._super(bundles.pointer.jsons.arrow_json);

        this.options = options || {};
        this.target = this.options.target;

        this.definePosition();
    },

    definePosition: function () {
        var position = this.options.pointerPosition;

        var dx = 0;
        var dy = 0;

        if (position === undefined) {
            position = this.guessPosition();
        }
        switch (position) {
            case PointerView.POSITIONS.TOP:
                this.setAnchorPoint2(0, 0.5);
                this.setRotation(-90);
                dy = this.target.height / 2;
                break;
            case PointerView.POSITIONS.BOTTOM:
                this.setAnchorPoint2(0, 0.5);
                this.setRotation(90);
                dy = -this.target.height / 2;
                break;
            case PointerView.POSITIONS.LEFT:
                this.setAnchorPoint2(1, 0.5);
                this.setRotation(0);
                dx = -this.target.width / 2;
                break;
            case PointerView.POSITIONS.RIGHT:
                this.setAnchorPoint2(1, 0.5);
                this.setRotation(180);
                dx = this.target.width / 2;
                break;
        }
        this.setPositionRound(this.target.width / 2 + dx, this.target.height / 2 + dy);
        this.setAnimation(0, "animation", true);
    },

    guessPosition: function () {
        if (this.target instanceof MenuBarItemView) {
            return PointerView.POSITIONS.LEFT;
        }
        if (typeof CardView !== "undefined" && this.target instanceof CardView) {
            return PointerView.POSITIONS.TOP;
        }
        return PointerView.POSITIONS.BOTTOM;
    }
});

PointerView.POSITIONS = {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right"
};