/**
 * Created by Ivan on 02.08.2023
 */

var VideoNode = cc.Node.extend({
    ctor: function () {
        cleverapps.loadSdk("https://unpkg.com/videocontext@0.50.4/dist/videocontext.js", {
            onSuccess: function () {
                const canvas = document.createElement("canvas");
                canvas.id = "videoCanvas";
                canvas.style.position = "absolute";
                canvas.style.top = "0";
                canvas.style.left = "0";
                canvas.style.zIndex = "1";
                cc.game.container.appendChild(canvas);
                if (cleverapps.Recorder.recorder) {
                    cleverapps.Recorder.recorder.stop();
                    cleverapps.Recorder.canvas = canvas;
                    cleverapps.Recorder.start({});
                }
                var videoCtx = new VideoContext(canvas);
                var videoNode1 = videoCtx.video("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4");
                videoNode1.connect(videoCtx.destination);
                videoNode1.start(0);
                videoNode1.stop(10);
                if (cleverapps.Recorder.recorder) {
                    setTimeout(function () {
                        cleverapps.Recorder.recorder.stop();
                        cleverapps.Recorder.canvas = cc.game.canvas;
                        cleverapps.Recorder.start({});
                    }, 10000);
                }
                videoCtx.play();
            },
            onFailure: function () {
                console.log("VideoContext did not load!");
            }
        });
    }
});