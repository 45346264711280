/**
 * Created by Andrey Popov on 1/26/21.
 */

var ImagePropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;
    var resolution = cleverapps.wysiwyg.wysiwygPreview.selectedResolution;

    if (this.property.allowOverride) {
        this.value = cleverapps.override({
            general: {
                bundle: "",
                image: ""
            }
        }, initialValue);

        if (!this.value[resolution]) {
            this.value[resolution] = {};
        }
    } else {
        this.value = cleverapps.override({
            bundle: "",
            image: ""
        }, initialValue);
    }

    var bundlesList = this.property.bundles.map(function (bundleName) {
        if (bundles[bundleName] && bundles[bundleName + "_selectorpreview"]) {
            return bundleName + "_selectorpreview";
        }
        return bundleName;
    });

    var framesWithBundles = {};
    framesWithBundles[Wysiwyg.NOT_SELECTED] = true;

    bundlesList.forEach(function (bundleString) {
        if (bundleString.endsWith("*")) {
            Object.keys(bundles).filter(function (bundleName) {
                return bundleName.startsWith(bundleString.replace("*", ""));
            }).forEach(function (bundleName) {
                Object.keys(bundles[bundleName].frames).concat(Object.keys(bundles[bundleName].urls))
                    .forEach(function (frame) {
                        framesWithBundles[frame] = bundleName;
                    });
            });
        } else if (bundles[bundleString]) {
            Object.keys(bundles[bundleString].frames).forEach(function (frame) {
                framesWithBundles[frame] = bundleString;
            });
        }
    });

    this.propertyEditors = [{
        name: property.name,
        items: Object.keys(framesWithBundles),
        bundles: bundlesList,
        direction: cleverapps.UI.VERTICAL,
        allowOverride: property.allowOverride,
        getter: function () {
            return this.value.image;
        }.bind(this),
        setter: function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value = undefined;
            } else {
                this.value = {
                    image: value,
                    bundle: framesWithBundles[value].replace("_selectorpreview", "")
                };
            }
        }.bind(this),
        getPreview: function (frame) {
            if (frame === Wysiwyg.NOT_SELECTED) {
                return;
            }

            if (!cleverapps.bundleLoader.isLoaded(framesWithBundles[frame])) {
                return;
            }

            if (!bundles[framesWithBundles[frame]].frames[frame]) {
                return;
            }

            return new cc.Sprite(bundles[framesWithBundles[frame]].frames[frame]);
        }
    }];

    if (this.property.allowOverride) {
        delete this.propertyEditors[0].setter;
        delete this.propertyEditors[0].getter;
        this.propertyEditors[0].setters = [function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value.general = undefined;
            } else {
                this.value.general = {
                    image: value,
                    bundle: framesWithBundles[value].replace("_selectorpreview", "")
                };
            }
        }.bind(this), function (value) {
            if (value === Wysiwyg.NOT_SELECTED) {
                this.value[resolution] = undefined;
            } else {
                this.value[resolution] = {
                    image: value,
                    bundle: framesWithBundles[value].replace("_selectorpreview", "")
                };
            }
        }.bind(this)];

        this.propertyEditors[0].getters = [function () {
            return this.value.general.image;
        }.bind(this),
        function () {
            return this.value[resolution].image;
        }.bind(this)];
    }

    this.propertyEditors = this.propertyEditors.map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

ImagePropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    this.propertyEditors.forEach(function (propertyEditor) {
        propertyEditor.updateOverrides();
    });
};