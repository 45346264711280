/**
 * Created by Andrey Popov on 13.12.2022
 */

var AdsGame = cc.Scene.extend({
    ctor: function (properties, eventBus, parent, sceneSize) {
        this._super();

        this.properties = properties || {};
        this.eventBus = eventBus;
        this.generatesEvents = this.properties.generatesEvents || [];
        this.listensEvents = this.properties.listensEvents || [];

        var size = {
            width: properties.width || sceneSize.width,
            height: properties.height || sceneSize.height
        };

        this.setContentSize2(size);

        if (this.listensEvents.indexOf(Wysiwyg.EVENTS.SCENE_STARTED) !== -1) {
            eventBus.on(Wysiwyg.EVENTS.SCENE_STARTED, this.play.bind(this), this);
        }
    },

    init: function (f) {
        if (!this.properties.snapshot) {
            f();
            return;
        }

        if (this.properties.isPreview && this.skipPreviewInit) {
            var text = cleverapps.UI.generateOnlyText("Snapshot " + this.properties.snapshot + ". Can't show several snapshots at the same time.", cleverapps.styles.FONTS.WINDOW_TEXT);
            text.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(text);
            f();
            return;
        }

        if (!cleverapps.loadedSnapshot || (!this.properties.isPreview && cleverapps.loadedSnapshot.id !== this.properties.snapshot)) {
            cleverapps.snapshots.getSnapshot(this.properties.snapshot, function (snapshot) {
                cleverapps.loadedSnapshot = snapshot;
                if (snapshot) {
                    cleverapps.loadedSnapshot.id = this.properties.snapshot;
                }
                this.init(f);
            }.bind(this));
            return;
        }

        if (!this.episode && cleverapps.loadedSnapshot.scene) {
            var episode = new Episode(cleverapps.loadedSnapshot.scene.episodeNo, cleverapps.loadedSnapshot.scene.levelNo);
            episode.loadData(function () {
                this.episode = episode;
                this.init(f);
            }.bind(this));
            return;
        }

        if (Game.currentGame && Game.currentGame.stop) {
            Game.currentGame.stop();
            Game.currentGame = undefined;
        }

        if (cleverapps.loadedSnapshot.scene) {
            var levelNo = cleverapps.loadedSnapshot.scene.levelNo;

            cleverapps.environment.levelNo = levelNo;
            cleverapps.environment.episodeNo = cleverapps.loadedSnapshot.scene.episodeNo;

            var level = this.episode.getLevel(levelNo);
            var page = cleverapps.travelBook.getPageById(level.expedition) || cleverapps.travelBook.getPageById("main");
            cleverapps.travelBook.setCurrentPage(page);
        }

        cleverapps.snapshots.importData(cleverapps.loadedSnapshot);

        var flags = this.properties.flags;
        if (!flags && cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.flags) {
            flags = cleverapps.loadedSnapshot.flags;
        }
        flags = flags || [];
        Object.keys(cleverapps.gameModes).forEach(function (flagName) {
            cleverapps.gameModes[flagName] = flags.indexOf(flagName) !== -1;
        });

        var scene = this.virtualScene = level ? new GameScene({
            level: level
        }) : new (cleverapps.meta.getMainScene())();

        this.addChild(scene);
        cleverapps.styles.VIRTUAL_WIN_SIZE = {
            height: cleverapps.styles.SCENE_HEIGHT,
            width: this.width * cleverapps.styles.SCENE_HEIGHT / this.height
        };
        cleverapps.scenes.windowsParent = scene;
        cleverapps.wideMode.refresh();

        scene.prepareBundles();

        scene.load(function () {
            scene.onSceneLoaded();

            cleverapps.environment.setScene(cleverapps.Environment.SCENE_WYSIWYG);

            scene.scene.setScale(this.getContentSize().height / cleverapps.styles.SCENE_HEIGHT);
            scene.scene.setAnchorPoint2(0, 0);
            scene.scene.setPositionRound(0, 0);

            var oldEventNodes = cleverapps.meta.eventNodes;
            this.virtualScene.playIntro(function () {
                cleverapps.meta.setEventNodes(oldEventNodes);
            }, true);
            f();
        }.bind(this));
    },

    play: function () {
        var actions = this.properties.actions || cleverapps.loadedSnapshot.actions || [];

        var clipProps = cleverapps.wysiwyg.hierarchy.getSelectedClip().properties;

        this.player = GamePlayer.Create(undefined, {
            scene: this.virtualScene,
            finger: {
                image: clipProps.finger ? bundles[clipProps.finger.bundle].frames[clipProps.finger.image] : undefined,
                noCircle: this.properties.fingerNoCircle,
                name: clipProps.finger ? clipProps.finger.image : undefined
            },
            progressChanged: function (progress) {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.PROGRESS_CHANGED) !== -1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.PROGRESS_CHANGED, progress);
                }
            }.bind(this)
        });

        this.player.play({
            actions: actions,
            delay: this.properties.actionsDelay || 0,
            initialDelay: this.properties.playDelay || 0,
            onStartPlay: function () {
                if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.GAME_STARTED) !== -1) {
                    this.eventBus.trigger(Wysiwyg.EVENTS.GAME_STARTED);
                }
            }.bind(this)
        }, this.onGameFinished.bind(this));
    },

    onGameFinished: function () {
        if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.GAME_FINISHED) !== -1) {
            this.eventBus.trigger(Wysiwyg.EVENTS.GAME_FINISHED);
        }

        setTimeout(this.runFinishAnimation.bind(this), this.properties.finishAnimationDelay || 0);

        setTimeout(function () {
            if (this.generatesEvents.indexOf(Wysiwyg.EVENTS.SCENE_COMPLETED) !== -1) {
                this.eventBus.trigger(Wysiwyg.EVENTS.SCENE_COMPLETED);
            }
        }.bind(this), this.properties.clipCompletedDelay || 1200);
    },

    runFinishAnimation: function () {
        if (this.properties.finishAnimation === AdsGame.FINISH_ANIMATIONS.FADE_OUT) {
            this.getParent().getParent().children.forEach(function (child) {
                if (child.tag !== "clipBg") {
                    child.setCascadeOpacityEnabledRecursively(true);

                    child.runAction(new cc.Sequence(
                        new cc.FadeOut(1.2)
                    ));
                }
            });
        }
    },

    cleanup: function () {
        this._super();
        if (this.episode) {
            this.episode.destructor();
        }

        if (cleverapps.loadedSnapshot) {
            delete cleverapps.loadedSnapshot;
        }

        delete cleverapps.styles.VIRTUAL_WIN_SIZE;
        delete cleverapps.scenes.windowsParent;
    }
});

AdsGame.FINISH_ANIMATIONS = {};
AdsGame.FINISH_ANIMATIONS.FADE_OUT = "fade out";

AdsGame.PROPERTIES = [{
    name: "isGame", value: true
}, {
    name: "snapshot", type: "string", link: true, bubbleUp: true, dirtyOnChange: true
}, {
    name: "flags",
    type: "string",
    multiselect: true,
    items: function () {
        return Object.keys(cleverapps.gameModes); 
    },
    bubbleUp: true,
    dirtyOnChange: true
}, {
    name: "actions", type: "actions", bubbleUp: true
}, {
    name: "generatesEvents",
    type: "string",
    items: cleverapps.values(Wysiwyg.EVENTS),
    multiselect: true,
    value: [Wysiwyg.EVENTS.GAME_STARTED, Wysiwyg.EVENTS.GAME_FINISHED, Wysiwyg.EVENTS.PROGRESS_CHANGED, Wysiwyg.EVENTS.SCENE_COMPLETED]
}, {
    name: "listensEvents", type: "string", items: cleverapps.values(Wysiwyg.EVENTS), multiselect: true, value: [Wysiwyg.EVENTS.SCENE_STARTED]
}, {
    name: "playDelay", type: "number", value: 0
}, {
    name: "actionsDelay", type: "number", value: 0
}, {
    name: "clipCompletedDelay", type: "number", value: 1200
}, {
    name: "finishAnimation", type: "string", value: Wysiwyg.NOT_SELECTED, items: cleverapps.values(AdsGame.FINISH_ANIMATIONS).concat([Wysiwyg.NOT_SELECTED])
}, {
    name: "finishAnimationDelay", type: "number", value: 0
}, {
    name: "fingerNoCircle", type: "checkBox", value: false
}, {
    name: "position",
    type: "position",
    value: {
        x: { align: "center", dx: "-50%" },
        y: { align: "center", dy: "-50%" }
    }
}
];