/**
 * Created by iamso on 12.05.21
 */

var WorkerTask = function (type, params) {
    this.type = type;
    this.params = params;
    this.options = {};
    this.dbId = undefined;

    this.crateTypeValues();
};

WorkerTask.prototype.crateTypeValues = function () {
    switch (this.type) {
        case WorkerTask.TYPES.ADVIDEO:
            this.code = this.params.clip;
            this.options.project = cleverapps.config.name;
            break;
        default:
            this.code = "";
    }
};

WorkerTask.prototype.start = function (onCreated) {
    var query = cleverapps.RestClient.createQueryString({
        code: this.code,
        type: this.type
    });

    cleverapps.RestClient.post(
        TaskManager.GetRestFullPath("/tasks/create?" + query), 
        { options: this.options },
        function (res) {
            this.dbId = res.insertId;

            onCreated();
        }.bind(this),
        function (err) {
            console.log("Failed scheduling task " + this.code, err.errorMessage || err.error);
            this.failed();
        }.bind(this)
    );
};

WorkerTask.prototype.complete = function (result) {
    cleverapps.taskManager.removeTask(this);

    if (this.params.onComplete) {
        this.params.onComplete(result);
    }
};

WorkerTask.prototype.failed = function () {
    cleverapps.taskManager.removeTask(this);

    if (this.params.onFailed) {
        this.params.onFailed();
    }
};

WorkerTask.TYPES = {};
WorkerTask.TYPES.ADVIDEO = "advideo";
