/**
 * Created by vladislav on 02.18.21.
 */

var SpinePropertyEditor = function (property, initialValue, onChangeValueCallback) {
    this.property = property;
    this.onChangeValueCallback = onChangeValueCallback;

    if (initialValue && initialValue.bundle) {
        var loadedSpine = cc.loader.getRes(bundles[initialValue.bundle].jsons[initialValue.json]);
        if (!loadedSpine) {
            loadedSpine = cc.loader.getRes(bundles.main.jsons[initialValue.json]);
        }
    }

    if (loadedSpine) {
        var availableAnimations = Object.keys((loadedSpine.animations));
        var availableSkins = Object.keys((loadedSpine.skins));
    }

    this.value = cleverapps.override({
        bundle: "",
        json: "",
        skin: "",
        animation: "",
        idle: "",
        looped: false
    }, initialValue);

    var spinesWithBundles = {};
    spinesWithBundles[Wysiwyg.NOT_SELECTED] = true;

    property.bundles.forEach(function (bundleString) {
        Object.keys(bundles[bundleString].jsons).forEach(function (spine) {
            if (["atlas", "plist"].indexOf(spine) !== -1) {
                return;
            }
            spinesWithBundles[spine] = bundleString;
        });
    });

    var jsonEditor = {
        name: "json",
        items: Object.keys(spinesWithBundles),
        bundles: this.property.bundles,
        getter: function () {
            return this.value.json;
        }.bind(this),
        setter: function (value) {
            this.value.json = value === Wysiwyg.NOT_SELECTED ? undefined : value;
            this.value.bundle = value === Wysiwyg.NOT_SELECTED ? undefined : spinesWithBundles[value];
        }.bind(this),
        getPreview: function (spine) {
            if (spine === Wysiwyg.NOT_SELECTED) {
                return;
            }

            if (!bundles[spinesWithBundles[spine]].jsons[spine] || spine === "texture_info_debug_json") {
                return;
            }

            var loadedSpine = cc.loader.getRes(bundles[spinesWithBundles[spine]].jsons[spine]);
            if (!loadedSpine) {
                loadedSpine = cc.loader.getRes(bundles.main.jsons[spine]);
            }
            if (loadedSpine) {
                var node = new cc.Node();
                node.setContentSize2(100, 100);

                var animations = Object.keys(loadedSpine.animations);
                var animation = new cleverapps.Spine(bundles[spinesWithBundles[spine]].jsons[spine]);
                animation.setAnimation(0, animations[animations.length - 1], true);

                var contentSize = animation.getContentSize();
                var scale = Math.min(node.width / contentSize.width, node.height / contentSize.height);
                animation.setScale(scale);

                node.addChild(animation);
                animation.setPositionRound(node.width / 2, node.height / 2);
                return node;
            }
        }
    };

    var skinEditor = {
        name: "skin",
        fullOnly: true,
        items: availableSkins,
        getter: function () {
            return this.value.skin;
        }.bind(this),
        setter: function (value) {
            this.value.skin = value;
        }.bind(this)
    };

    var animationEditor = {
        name: "animation",
        items: availableAnimations,
        getter: function () {
            return this.value.animation;
        }.bind(this),
        setter: function (value) {
            this.value.animation = value;
        }.bind(this)
    };

    var idleEditor = {
        name: "idle",
        fullOnly: true,
        items: [" "].concat(availableAnimations),
        getter: function () {
            return this.value.idle;
        }.bind(this),
        setter: function (value) {
            this.value.idle = (value === " ") ? undefined : value;
        }.bind(this)
    };

    var loopEditor = {
        name: "looped",
        fullOnly: true,
        items: ["true", "false"],
        getter: function () {
            return this.value.looped.toString();
        }.bind(this),
        setter: function (value) {
            this.value.looped = value === "true";
        }.bind(this)
    };

    var editors = [];
    if (property.name === "toSpine") {
        jsonEditor.name = property.name;
        editors = [jsonEditor];
    } else {
        editors = [{
            name: "Spine",
            fullOnly: true,
            isBlock: true
        },
        jsonEditor,
        skinEditor,
        animationEditor,
        idleEditor,
        loopEditor, {
            name: "",
            fullOnly: true,
            isBlock: true
        }];
    }

    this.propertyEditors = editors.filter(Inspector.FilterForFullVersion).map(function (propertyDescription) {
        return new PropertyEditor(propertyDescription, this);
    }.bind(this));
};

SpinePropertyEditor.prototype.editBoxEditingDidEnd = function (sender) {
    sender.customSetter(sender.getString());
    this.onChangeValueCallback(this.property.name, this.value);
    cleverapps.wysiwyg.inspector.showItemProperties();
};