/**
 * Created by vladislav on 9/14/2022
 */

cleverapps.MicrosoftFacebook = function () {
    console.log("Choosing microsoft-facebook");
    cleverapps.Social.call(this);
};

cleverapps.MicrosoftFacebook.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.MicrosoftFacebook.prototype.constructor = cleverapps.MicrosoftFacebook;

cleverapps.MicrosoftFacebook.prototype.init = function (callback) {
    this.loadUser();
    callback();
};

cleverapps.MicrosoftFacebook.prototype.saveUser = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.MS_SOCIAL, {
        user: this.user
    });
};

cleverapps.MicrosoftFacebook.prototype.loadUser = function () {
    var data = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.MS_SOCIAL);
    if (data && data.user) {
        this.user = data.user;
    }
};

cleverapps.MicrosoftFacebook.prototype.login = function (onSuccess, onFailure) {
    var waitWindow = new WaitWindow();
    this.fbLoginCallback = function (code) {
        waitWindow.close();

        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.SocialManager.onLogin();
            this.saveUser();
            onSuccess();
        } else {
            cleverapps.SocialManager.onFailedLogin();
            onFailure && onFailure();
        }
    };
    cleverapps.MicrosoftPlugin.FBLogin();
};

cleverapps.MicrosoftFacebook.prototype.onFbLogin = function (data) {
    var authUrl = data && data.authUrl;

    var params = new URLSearchParams(authUrl.substring(authUrl.indexOf("#")));

    if (!params.has("access_token")) {
        this.fbLoginCallback(cleverapps.Social.CODE_FAILED);
        return;
    }
    this.accessToken = params.get("access_token");

    this.initPlayer(function (code) {
        this.fbLoginCallback(code);
    }.bind(this));
};

cleverapps.MicrosoftFacebook.prototype.initPlayer = function (callback) {
    var success = function (data) {
        if (data && data.id) {
            this.user = cleverapps.MicrosoftFacebook.formatUserData(data);
            callback(cleverapps.Social.CODE_SUCCEED);
        } else {
            callback(cleverapps.Social.CODE_FAILED);
        }
    }.bind(this);

    var fail = function () {
        callback(cleverapps.Social.CODE_FAILED);
    };

    this.api("me?fields=instant_game_player_id,first_name,name,picture", success, fail);
};

cleverapps.MicrosoftFacebook.prototype.api = function (path, onSuccess, onFail) {
    var host = "https://graph.fb.gg/";
    if (path.indexOf("?") === -1) {
        path += "?";
    }
    var url = host + path + "&access_token=" + this.accessToken;

    cleverapps.RestClient.get(url, {}, function (response) {
        onSuccess(response);
    }, function () {
        onFail && onFail();
    });
};

cleverapps.MicrosoftFacebook.prototype.aboutMe = function (callback) {
    if (this.user) {
        callback(this.user);
    }
};

cleverapps.MicrosoftFacebook.prototype.getAccessToken = function () {
    return "microsoft";
};

cleverapps.MicrosoftFacebook.prototype.isLoggedIn = function () {
    return this.user && this.user.id !== undefined;
};

cleverapps.MicrosoftFacebook.prototype.getUserID = function () {
    return this.user.id;
};

cleverapps.MicrosoftFacebook.formatUserData = function (data) {
    return {
        id: "" + data.instant_game_player_id,
        fbId: "" + data.id,
        name: data.name,
        first_name: data.first_name,
        picture: {
            data: {
                url: data.url
            }
        }
    };
};