/**
 * Created by denis on 31 july 2020
 */

var SolitairePlayer = function (level, options) {
    Tile3Player.call(this, level, options);

    Hand.JOKER_POSITION_IN_HAND = 0;

    this.cardTableView = $(CardTableView).get();
    this.handView = $(HandView).get();
    this.wildCardButton = $(WildCardButton).get();
    this.undoBlock = $(UndoBlock).get();
};

SolitairePlayer.prototype = Object.create(Tile3Player.prototype);
SolitairePlayer.prototype.constructor = SolitairePlayer;

SolitairePlayer.prototype.playAction = function (action, f) {
    this.beforePlayAction();

    if (action.type === "show_finger") {
        f();
    } else if (action.type === "play") {
        this.playTableCard(action, f);
    } else if (action.type === "hand") {
        if (this.lastAction && this.lastAction.type === "combo_reward") {
            setTimeout(this.playHandCard.bind(this, f), 1000);
        } else {
            this.playHandCard(f, this.lastAction);
        }
    } else if (action.type === "booster") {
        this.executeBooster(action.id, f);
    } else if (action.type === "combo_reward") {
        f();
    } else {
        this.playAnyAction(f);
    }

    this.lastAction = action;
};

SolitairePlayer.prototype.playTableCard = function (card, f) {
    var waitTime = 5000;
    var interval = 100;

    var nextTry = function () {
        var cardView = this.getOpenedCardView(card);

        if (!cardView && this.getCardView(card) && waitTime > 0) {
            waitTime -= interval;
            setTimeout(nextTry, interval);
            return;
        }

        var action = function () {
            this.game.table.cardClicked(cardView.card || cardView.decorator);
        }.bind(this);

        if (cardView) {
            this.fingerClickAction(cardView, action, { doNotFollowFinger: true, callback: f });
        } else {
            f();
        }
    }.bind(this);

    nextTry();
};

SolitairePlayer.prototype.getOpenedCardView = function (card) {
    return this.getCardView(card, true);
};

SolitairePlayer.prototype.getCardView = function (card, onlyOpened) {
    var tableCards = this.cardTableView.centerNode.getChildren();
    var views = tableCards.filter(function (view) {
        var found = view.card && view.card.value === card.value && (!onlyOpened || view.card.isOpen());

        if (card.x !== undefined && card.y !== undefined) {
            return found && card.x === view.card.x && card.y === view.card.y;
        }

        return found;
    });
    return views[0];
};

SolitairePlayer.prototype.beforePlayAction = function () {
    if (!this.comboReward) {
        this.comboReward = this.actions.filter(function (action) {
            return action.type === "combo_reward";
        }).map(function (action) {
            return action.value;
        });

        var getComboReward = function () {
            return this.comboReward;
        }.bind(this);

        if (!cleverapps.Combo.prototype.__giveReward) {
            cleverapps.Combo.prototype.__giveReward = cleverapps.Combo.prototype.giveReward;
        }
        cleverapps.Combo.prototype.giveReward = function (card) {
            card.value = getComboReward().shift();

            cleverapps.Combo.prototype.__giveReward.call(this, card);
        };
    }
};

SolitairePlayer.prototype.afterLastAction = function (f) {
    cleverapps.Combo.prototype.giveReward = cleverapps.Combo.prototype.__giveReward;
    cleverapps.Combo.prototype.__giveReward = undefined;

    f();
};

SolitairePlayer.prototype.playAnyAction = function (f) {
    var tableCards = Game.currentGame.table.listMissed();
    tableCards = cleverapps.Random.shuffle(tableCards);

    var hand = Game.currentGame.hand.stack;

    if (tableCards.length > 0) {
        this.playTableCard(tableCards[0], f);
    } else if (hand.length > 0) {
        this.playHandCard(this.playAnyAction.bind(this, f));
    } else {
        f();
    }
};

SolitairePlayer.prototype.playHandCard = function (f) {
    var target = this.handView.cardViews[this.handView.cardViews.length - 1];
    var action = function () {
        this.game.shiftCard();
    }.bind(this);
    this.fingerClickAction(target, action, { doNotFollowFinger: true, callback: f });
};

SolitairePlayer.prototype.executeBooster = function (boosterId, f) {
    var target;
    var action = function () {};

    if (boosterId === cleverapps.Boosters.TYPE_WILDCARD) {
        target = this.wildCardButton;
        action = function () {
            this.wildCardButton.onPressed();
        }.bind(this);
    } else if (boosterId === cleverapps.Boosters.TYPE_UNDO) {
        target = this.undoBlock.undoButton;
        action = function () {
            this.undoBlock.onClicked();
        }.bind(this);
    }

    if (!target) {
        f();
        return;
    }

    this.fingerClickAction(target, action, { doNotFollowFinger: true, callback: f });
};

SolitairePlayer.SETTINGS = {
    durations: {
        combo: 0.7,
        lockOpen: 2.2,
        extraCards: 1.0,
        fillHand: 0.8
    }
};