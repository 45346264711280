/**
* Created by mac on 2/28/23
*/

var JamScene = NoneMainGameScene.extend({
    onSceneLoaded: function () {
        this._super();

        var grid = new Grid(this.game);
        grid.setPositionRound(cleverapps.styles.JamScene.grid);
        this.addChild(grid);

        this.cars = this.game.cars.map(function(car) {
            var carView = new CarView(car);
            grid.addChild(carView);
        });
    }
});

GameScene = JamScene;

cleverapps.styles.JamScene = {
    grid: {
        x: {align: "center"},
        y: {align: "center", dy: 200}
    },

    cell: {
        width: 90,
        height: 90
    }
};
