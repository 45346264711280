/**
 * Created by mac on 12/7/20
 */

var Map2d = function (groundMap, options) {
    cleverapps.EventEmitter.call(this);

    options = options || {};

    this.unitsTexture = Map2d.getUnitsTexture(options);
    this.tilesTexture = Map2d.getTilesTexture(options);

    this.visibleBox = this.prepareVisibleBox(options.visibleBox || Map2d.VISIBLE_BOX);
    this.waterBorder = options.waterBorder !== undefined ? options.waterBorder : Map2d.WATER_BORDER;
    this.regions = options.regions || {};
    this.decorators = new Map2dDecorators(options.decorators || []);
    this.fences = new Map2dFences(this);
    this.terrains = (options.terrains || []).map(function (line) {
        return line.split("");
    });

    groundMap = groundMap.map(function (row) {
        if (typeof row === "string") {
            row = row.split("").map(cleverapps.castType);
        }
        return row;
    });

    this.width = groundMap[0].length;
    this.height = groundMap.length;

    var water = this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? cell : undefined;
    });

    var borders = this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? Map2d.TILE_BORDER : undefined;
    });

    var ground = this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? undefined : cell;
    });

    var units = this.map(groundMap, function () {
        return undefined;
    });

    var fog = this.map(groundMap, function () {
        return undefined;
    });

    this.layers = [water, borders, ground, units, fog];

    this.earthType = this.map(groundMap, function () {
        return undefined;
    });

    for (var region in this.regions) {
        var preferredEarthType = undefined;

        if (region.indexOf("match3") === 0) {
            preferredEarthType = "match3";
        }

        if (preferredEarthType) {
            var positions = this.regions[region].positions;
            for (var i = 0; i < positions.length; ++i) {
                var position = positions[i];
                this.earthType[position.y][position.x] = preferredEarthType;
            }
        }
    }

    this.onAdd = function () {};
    this.onAnimationsAdd = function () {};
    this.onMove = function () {};
    this.onFogOpen = function () {};
    this.onAddTarget = function () {};
    this.onDiscoverView = function () {};
    this.getScrollZoom = function () {};
    this.stopDragging = function () {};

    this.onUpdateVisibleRect = function () {};
    this.addCellTiles = function () {};
    this.removeCellTiles = function () {};

    this.pointer = {
        x: 0,
        y: 0
    };

    this.constructTerrains();
    this.constructGrowth();

    this.load();

    this.growFrameData = {};
    this.borderTypes = {};

    this.screenRect = cc.rect(0, 0, 1, 1);
    this.visibleRect = cc.rect(0, 0, 1, 1);
    this.initVisibleBoxRect();

    this.removeQueue = new Map2dRemoveQueue(this);
};

Map2d.prototype = Object.create(cleverapps.EventEmitter.prototype);
Map2d.prototype.constructor = Map2d;

Map2d.LATEST_MAP = undefined;

Map2d.TILE_WATER = 0;
Map2d.TILE_GREEN_LEVEL_1 = 1;
Map2d.TILE_GREEN_LEVEL_2 = 2;
Map2d.TILE_WATER_UNIT = 9;
Map2d.TILE_GROW = 10;
Map2d.TILE_BORDER = 11;

Map2d.LAYER_WATER = 0;
Map2d.LAYER_BORDERS = 1;
Map2d.LAYER_GROUND = 2;
Map2d.LAYER_UNITS = 3;
Map2d.LAYER_FOG = 4;

Map2d.LAYERS = [
    [Map2d.LAYER_WATER],
    [Map2d.LAYER_BORDERS],
    [Map2d.LAYER_GROUND],
    [Map2d.LAYER_UNITS, Map2d.LAYER_FOG]
];

var Iso = {
    SAME: {
        x: 0,
        y: 0
    },

    UP_LEFT: {
        y: -1,
        x: 0
    },

    UP_RIGHT: {
        y: 0,
        x: 1
    },

    UP_ABOVE: {
        y: -1,
        x: 1
    },

    DOWN_LEFT: {
        y: 0,
        x: -1
    },

    DOWN_RIGHT: {
        y: 1,
        x: 0
    },

    DOWN_BELOW: {
        y: 1,
        x: -1
    },

    HORIZONTAL_LEFT: {
        y: -1,
        x: -1
    },

    HORIZONTAL_RIGHT: {
        y: 1,
        x: 1
    }
};

var ISO_NEIGHBORS = [Iso.DOWN_LEFT, Iso.DOWN_RIGHT, Iso.UP_LEFT, Iso.UP_RIGHT];

Map2d.prototype.prepareVisibleBox = function (visibleBox) {
    if (Array.isArray(visibleBox)) {
        visibleBox = visibleBox.slice().reverse().find(function (visibleBox) {
            return cleverapps.user.checkAvailable(visibleBox.available)
                || cleverapps.config.editorMode || cleverapps.config.adminMode;
        });
    }

    if (!visibleBox.cutLeft && !visibleBox.cutRight) {
        visibleBox.cutRight = (1 - visibleBox.width) / 2;
        visibleBox.cutLeft = visibleBox.cutRight;
    }

    if (!visibleBox.cutTop && !visibleBox.cutBottom) {
        visibleBox.cutTop = (1 - visibleBox.height) / 2;
        visibleBox.cutBottom = visibleBox.cutTop;
    }

    return visibleBox;
};

Map2d.prototype.getView = function () {
    return this.onDiscoverView();
};

Map2d.prototype.stop = function () {
    this.stopped = true;

    this.listAvailableUnits().forEach(function (unit) {
        unit.destructor();
    });
    this.decorators.destructor();

    if (Map2d.LATEST_MAP === this) {
        Map2d.LATEST_MAP = undefined;
    }

    this.addCellTiles = function () {};
    this.removeCellTiles = function () {};

    this.trigger("stop");
};

Map2d.prototype.setPointer = function (x, y) {
    this.pointer.x = x;
    this.pointer.y = y;
};

Map2d.prototype.initVisibleBoxRect = function () {
    var size = this.width + this.height;
    var top = this.width - 1;
    var bottom = -this.height + 1;

    var minX = size / 2 - size * (0.5 - this.visibleBox.cutLeft) - this.waterBorder;
    var maxX = size / 2 + size * (0.5 - this.visibleBox.cutRight) + this.waterBorder;
    var minY = bottom + size * this.visibleBox.cutBottom - this.waterBorder;
    var maxY = top - size * this.visibleBox.cutTop + this.waterBorder;

    this.visibleBoxRect = cc.rect(
        Math.floor((minX + minY) / 2),
        Math.floor((minX - minY) / 2),
        Math.ceil((maxX - minX) / 2),
        Math.ceil((maxY - minY) / 2)
    );
};

Map2d.prototype.getVisibleBoxRect = function () {
    return this.visibleBoxRect;
};

Map2d.prototype.map = function (array2d, iterator) {
    return array2d.map(function (row) {
        return row.map(function (cell) {
            return iterator(cell);
        }, this);
    }, this);
};

Map2d.prototype.iterateLayerCells = function (layerId, iterator) {
    // if (layerId === Map2d.LAYER_WATER) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_BORDERS) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_GROUND) {
    //     return;
    // }

    // if (layerId === Map2d.LAYER_FOG) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_UNITS) {
    //     return;
    // }
    if (layerId === Map2d.LAYER_WATER && !cleverapps.config.editorMode) {
        this.iterateWaterCells(iterator);
        return;
    }

    this.layers[layerId].forEach(function (row, y) {
        row.forEach(function (object, x) {
            if (cc.isoRectContainsPoint(this.visibleBoxRect, x, y)) {
                iterator(x, y, object);
            }
        }, this);
    }, this);
};

Map2d.prototype.iterateWaterCells = function (iterator) {
    cc.iterateIsoRect(this.visibleBoxRect, function (x, y) {
        iterator(x, y, this.getValue(Map2d.LAYER_WATER, x, y));
    }.bind(this));
};

Map2d.prototype.onUpdateScroll = function () {
    if (this.stopped) {
        return;
    }

    this.setScrollCell(this.getScreenCenterCell() || this.scrollCell);
    this.setZoom(this.getScrollZoom() || this.zoom);
};

Map2d.prototype.setZoom = function (zoom) {
    if (this.zoom !== zoom) {
        this.zoom = zoom;

        this.save();
    }
};

Map2d.prototype.setScrollCell = function (scrollCell) {
    if (!cc.pointEqualToPoint(this.scrollCell, scrollCell)) {
        this.scrollCell = cc.p(scrollCell.x, scrollCell.y);

        this.save();
    }
};

Map2d.prototype.load = function () {
    var slot = Game.currentGame && Game.currentGame.slot || CustomSyncers.SLOT_MAIN;

    var stored = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.MAP2D + slot)
        || cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.SCROLL + slot) || {};

    this.scrollCell = stored.scrollCell;
    this.zoom = stored.zoom;
};

Map2d.prototype.save = function () {
    var slot = Game.currentGame && Game.currentGame.slot || CustomSyncers.SLOT_MAIN;

    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.MAP2D + slot, {
        zoom: this.zoom,
        scrollCell: this.scrollCell
    });
};

Map2d.prototype.getGround = function (dir) {
    return this.layers[Map2d.LAYER_GROUND][this.pointer.y + dir.y] && this.layers[Map2d.LAYER_GROUND][this.pointer.y + dir.y][this.pointer.x + dir.x];
};

Map2d.prototype.getTerrainCode = function (x, y) {
    if (x === undefined) {
        x = this.pointer.x;
    }
    if (y === undefined) {
        y = this.pointer.y;
    }
    return this.terrains[y] && this.terrains[y][x] || Map2d.TERRAIN_GREEN;
};

Map2d.prototype.setTerrain = function (x, y, value) {
    this.terrains[y][x] = value;
};

Map2d.prototype.getTerrainType = function (x, y) {
    if (typeof x === "object" && y === undefined) {
        y = this.pointer.y + x.y;
        x = this.pointer.x + x.x;
    }

    return {
        name: this.tilesTexture + "_" + Map2d.TERRAINS[this.getTerrainCode(x, y)],
        defaultTiles: this.tilesTexture + "_" + bundles[this.tilesTexture].defaultTerrain
    };
};

Map2d.prototype.getTerrainConfig = function (x, y) {
    var type = this.getTerrainType(x, y);
    return Map2d.TERRAIN_CONFIG[type.name] || Map2d.TERRAIN_CONFIG.default;
};

Map2d.prototype.getTerrainFrames = function (x, y) {
    var type = this.getTerrainType(x, y);
    return (Map2d.TERRAIN_BUNDLES[type.name] || bundles[type.defaultTiles]).frames;
};

Map2d.prototype.getTerrainFrameData = function (name, x, y) {
    var frames = this.getTerrainFrames(x, y);
    var config = this.getTerrainConfig(x, y);
    return { res: frames[name], options: config.frames[name] || {} };
};

Map2d.prototype.getValue = function (layerId, x, y) {
    if (layerId === Map2d.LAYER_WATER && (x < 0 || x >= this.getWidth() || y < 0 || y >= this.getHeight())
        && cc.isoRectContainsPoint(this.visibleBoxRect, x, y) && !cleverapps.config.editorMode) {
        return Map2d.TILE_WATER;
    }

    return this.layers[layerId][y] && this.layers[layerId][y][x];
};

Map2d.prototype.setValue = function (layer, dir, value) {
    var x = this.pointer.x + dir.x;
    var y = this.pointer.y + dir.y;
    this.trigger("removeValue", layer, x, y);
    this.layers[layer][y][x] = value;
    this.trigger("setValue", layer, x, y);
};

Map2d.prototype.add = function (layer, x, y, unit) {
    this.layers[layer][y][x] = unit;
};

Map2d.prototype.getFog = function (x, y) {
    return this.layers[Map2d.LAYER_FOG][y] && this.layers[Map2d.LAYER_FOG][y][x];
};

Map2d.prototype.getDecoratorUnit = function (x, y) {
    return this.layers[Map2d.LAYER_UNITS][y] && this.layers[Map2d.LAYER_UNITS][y][x] && this.layers[Map2d.LAYER_UNITS][y][x].code === "decorator";
};

Map2d.prototype.getEarthType = function (x, y) {
    return this.earthType[y] && this.earthType[y][x];
};

Map2d.prototype.remove = function (layer, x, y) {
    if (this.dragging && layer === Map2d.LAYER_UNITS && this.dragging.x === x && this.dragging.y === y) {
        cleverapps.throwAsync("Remove draggging unit");
    }
    if (layer === Map2d.LAYER_UNITS) {
        var unit = this.getUnit(x, y);
        if (unit) {
            unit.removeStack = "x - " + x + ", y - " + y + ", stack - " + new Error().stack;
            // console.log(unit.removeStack);
        }
    }
    this.layers[layer][y][x] = undefined;
};

Map2d.prototype.isWater = function (dir) {
    var value = this.getValue(Map2d.LAYER_WATER, this.pointer.x + dir.x, this.pointer.y + dir.y);
    return [Map2d.TILE_WATER, Map2d.TILE_WATER_UNIT].includes(value);
};

Map2d.prototype.isWaterUnit = function (x, y) {
    return this.layers[Map2d.LAYER_WATER][y] && this.layers[Map2d.LAYER_WATER][y][x] === Map2d.TILE_WATER_UNIT;
};

Map2d.prototype.isGround = function (x, y) {
    if (typeof x === "object") {
        if (x.direction) {
            y = this.pointer.y + x.y;
            x = this.pointer.x + x.x;
        }
    }
    return this.layers[Map2d.LAYER_GROUND][y] && [Map2d.TILE_GREEN_LEVEL_1, Map2d.TILE_GREEN_LEVEL_2].indexOf(this.layers[Map2d.LAYER_GROUND][y][x]) !== -1;
};

Map2d.prototype.getUnit = function (x, y) {
    return this.layers[Map2d.LAYER_UNITS][y] && this.layers[Map2d.LAYER_UNITS][y][x];
};

Map2d.prototype.getUnitWithHead = function (x, y) {
    var unit = this.getUnit(x, y);
    if (unit && unit.head) {
        return unit.head;
    }
    return unit;
};

Map2d.prototype.bfs = function (x, y, filter) {
    if (!filter(x, y)) {
        return [];
    }

    var dirs = ISO_NEIGHBORS;
    var area = [{ x: x, y: y }];

    var used = {};
    used[this.getPositionKey(x, y)] = true;

    var pointer = 0;
    while (pointer < area.length) {
        var p = area[pointer++];
        for (var i = 0; i < dirs.length; i++) {
            var tx = p.x + dirs[i].x;
            var ty = p.y + dirs[i].y;

            var key = this.getPositionKey(tx, ty);
            if (!used[key]) {
                used[key] = true;

                if (filter(tx, ty)) {
                    area.push({ x: tx, y: ty });
                }
            }
        }
    }

    return area;
};

Map2d.prototype.getPositionKey = function (x, y) {
    return x + y * this.width;
};

Map2d.prototype.compareMergeable = function (target, x, y) {
    var unit = this.getUnit(x, y);
    return unit && !this.getFog(x, y) && target.isMergeable(unit);
};

Map2d.prototype.compareEqual = function (target, x, y) {
    var unit = this.getUnit(x, y);
    return unit && !unit.isDragged() && !this.getFog(x, y) && Unit.Equals(target, unit);
};

Map2d.prototype.setScreenRect = function (screenRect) {
    this.screenRect = screenRect;
};

Map2d.prototype.setVisibleRect = function (visibleRect) {
    this.lastVisibleRectTime = Date.now();

    if (cc.rectEqualToRect(visibleRect, this.visibleRect)) {
        return;
    }

    var self = this;
    var oldRect = this.visibleRect;

    cc.iterateIsoRect(oldRect, function (x, y) {
        if (!cc.isoRectContainsPoint(visibleRect, x, y) && cc.isoRectContainsPoint(self.visibleBoxRect, x, y)) {
            self.removeQueue.add(x, y);
        }
    });

    cc.iterateIsoRect(visibleRect, function (x, y) {
        if (!cc.isoRectContainsPoint(oldRect, x, y) && cc.isoRectContainsPoint(self.visibleBoxRect, x, y)) {
            self.removeQueue.remove(x, y);
            self.addCellTiles(x, y);
        }
    });

    this.visibleRect = visibleRect;

    this.onUpdateVisibleRect();
};

Map2d.prototype.getVisibleRect = function () {
    return this.visibleRect;
};

Map2d.prototype.isVisibleCell = function (x, y) {
    return cc.isoRectContainsPoint(this.visibleRect, x, y);
};

Map2d.prototype.getScreenCenterCell = function () {
    return cc.isoRectGetCenter(this.screenRect);
};

Map2d.prototype.getMapCenterCell = function () {
    return cc.p(Math.round(this.width / 2), Math.round(this.height / 2));
};

Map2d.prototype.listAvailableGrounds = function () {
    var cells = [];

    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.isGround(x, y) && !this.getFog(x, y)) {
                cells.push(cc.p(x, y));
            }
        }
    }

    return cells;
};

Map2d.prototype.listAvailableUnits = function (filter) {
    var units = [];

    if (filter && typeof filter !== "function") {
        filter = Unit.IsApplicable.bind(Unit, filter);
    }

    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            var unit = this.getUnit(x, y);
            if (unit && (!filter || filter(unit))) {
                units.push(unit);
            }
        }
    }

    return units;
};

Map2d.prototype.listAvailableUnitsInRegion = function (region, targets) {
    var units = [];

    if (!this.regions[region]) {
        return units;
    }

    this.regions[region].positions.forEach(function (cell) {
        var unit = this.getUnit(cell.x, cell.y);
        if (unit && Unit.IsApplicable(targets, unit)) {
            units.push(unit);
        }
    }, this);

    return units;
};

Map2d.prototype.isEmpty = function (x, y) {
    return this.isGround(x, y) && !this.getUnit(x, y) && !this.getFog(x, y);
};

Map2d.prototype.countEmptySlots = function () {
    var amount = 0;
    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.isEmpty(x, y)) {
                amount++;
            }
        }
    }
    return amount;
};

Map2d.prototype.findEmptySlot = function (x, y, unit, options) {
    options = options || {};

    var index = ISO_NEIGHBORS;
    var data = unit && Families[unit.code].units[unit.stage];

    var checkResourceCell = function (rx, ry) {
        for (var i = 0; i < index.length; i++) {
            var otherUnit = this.getUnit(rx + index[i].x, ry + index[i].y);
            if (Unit.Equals(unit, otherUnit) && !this.getFog(rx + index[i].x, ry + index[i].y)) {
                return true;
            }
        }
    }.bind(this);

    if (!data || data.unmergeable || options.skipCheckResource) {
        checkResourceCell = function () {
            return true;
        };
    }

    cleverapps.Random.shuffle(index);

    var shape = Unit.GetShape(unit);
    var checkScreen = options.skipCheckScreen !== undefined ? !options.skipCheckScreen : true;
    var firstCell;
    var screenCell;
    var resourceCell;
    var radius = options.radius !== undefined ? options.radius : Map2d.RADIUS_BIG;
    var preferredEarthType = options.preferredEarthType;

    for (var d = 0; d < 100 && (checkScreen || !firstCell) && (d <= radius || !firstCell); d++) {
        var hasScreenCell = false;

        for (var dd = 0; dd <= d; dd++) {
            for (var j = 0; j < 2; j++) {
                var rd = dd;
                if (j > 0) {
                    if (dd === 0) {
                        continue;
                    }
                    rd = -dd;
                }
                for (var i = 0; i < index.length; i++) {
                    var nx = x + ((index[i].x === 0) ? rd : (index[i].x * d));
                    var ny = y + ((index[i].y === 0) ? rd : (index[i].y * d));

                    var cells = shape.map(function (part) {
                        return {
                            x: nx + part.x,
                            y: ny + part.y
                        };
                    });

                    var isScreenCell = false;

                    if (checkScreen) {
                        isScreenCell = cells.every(this.isScreenCell.bind(this));
                        hasScreenCell = hasScreenCell || isScreenCell;
                    }

                    var isResourceCell = cells.every(checkResourceCell);

                    if (cells.every(function (cell) {
                        return this.isEmpty(cell.x, cell.y) && this.getEarthType(cell.x, cell.y) === preferredEarthType;
                    }, this)) {
                        var cell = cc.p(nx, ny);
                        firstCell = firstCell || cell;
                        if (isScreenCell) {
                            screenCell = screenCell || cell;
                            if (isResourceCell) {
                                return cell;
                            }
                        }
                        if (isResourceCell) {
                            resourceCell = resourceCell || cell;
                        }
                    }
                }
            }
        }

        checkScreen = hasScreenCell;
    }

    return screenCell || resourceCell || firstCell;
};

Map2d.prototype.hasMergeable = function () {
    var mergeable = {};
    var units = this.listAvailableUnits();
    for (var i = 0; i < units.length; i++) {
        var unit = units[i];
        if (unit.isMergeable(unit)) {
            var code = unit.code;
            var stage = unit.stage;
            mergeable[code] = mergeable[code] || {};
            mergeable[code][stage] = mergeable[unit.code][stage] || [];
            mergeable[code][stage].push(unit);
            if (mergeable[code][stage].length > 2) {
                return unit;
            }
        }
    } 
};

Map2d.prototype.isScreenCell = function (x, y) {
    return cc.isoRectContainsPoint(this.screenRect, x, y);
};

Map2d.prototype.getCenterDistance = function (x, y) {
    var center = this.getScreenCenterCell();
    return center ? cc.pDistance(center, cc.p(x, y)) : 1e3;
};

Map2d.prototype.constructTerrains = function () {
    for (var y = 0; y < this.getHeight(); y++) {
        if (this.terrains.length <= y) {
            this.terrains.push([]);
        }
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.terrains[y].length <= x) {
                this.terrains[y].push(Map2d.TERRAIN_EMPTY);
            }
            if (this.terrains[y][x] === Map2d.TERRAIN_EMPTY && this.isGround(x, y)) {
                this.terrains[y][x] = Map2d.TERRAIN_GREEN;
            }
        }
    }
};

Map2d.prototype.moveUnitToRightAndHighlight = function (unit, window) {
    if (this.highlightInProgress) {
        this.onHighlightComplete = this.moveUnitToRightAndHighlight.bind(this, unit, window);
        return;
    }
    this.highlightInProgress = true;

    var targetZoom = 1.4;
    var padding = {
        x: cleverapps.UI.getSceneSize().width / 8 + (unit.findComponent(MultiCell) ? 0 : cleverapps.styles.Map2dView.cell.width / 2),
        y: (1 - targetZoom) * cleverapps.styles.Map2dView.cell.height
    };

    if (window.highlighter) {
        window.highlighter.unhighlight();
        delete window.highlighter;
    }

    this.getView().scroll.runAction(new cc.Sequence(
        new cc.Spawn(
            new cc.CellScrollAction(unit, { duration: 0.5, padding: padding }),
            new cc.ZoomAction(0.5, { zoom: targetZoom })
        ),
        new cc.CallFunc(function () {
            this.highlightInProgress = false;
            if (window.closed || Game.currentGame.map.getUnit(unit.x, unit.y) !== unit) {
                delete this.onHighlightComplete;
                return;
            }

            window.highlighter = new UnitHighlighter(unit, { spotlight: true });
            if (window.visible) {
                window.highlighter.replaceParentSamePlace(window, { keepScale: true });
            }

            if (this.onHighlightComplete) {
                this.onHighlightComplete();
                delete this.onHighlightComplete;
            }
        }.bind(this))
    ));
};

Map2d.prototype.constructGrowth = function (x, y) {
    if (x === undefined) {
        for (y = 0; y < this.getHeight(); y++) {
            for (x = 0; x < this.getWidth(); x++) {
                this.constructGrowth(x, y);
            }
        }
        return;
    }

    this.setPointer(x, y);
    if (this.getGround(Iso.SAME) === Map2d.TILE_GREEN_LEVEL_2) {
        if (this.getGround(Iso.DOWN_LEFT) === Map2d.TILE_GREEN_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_LEFT, Map2d.TILE_GROW);
        }
        if (this.getGround(Iso.DOWN_RIGHT) === Map2d.TILE_GREEN_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_RIGHT, Map2d.TILE_GROW);
        }
        if (this.getGround(Iso.DOWN_BELOW) === Map2d.TILE_GREEN_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_BELOW, Map2d.TILE_GROW);
        }
    }
};

Map2d.prototype.getWidth = function () {
    return this.width;
};

Map2d.prototype.getHeight = function () {
    return this.height;
};

Map2d.prototype.setDragging = function (unit) {
    this.dragging = unit;
    this.trigger("changeDragging", unit);
};

Map2d.prototype.showMergeBonus = function (unit) {
    this.trigger("showMergeBonus", unit);
};

Map2d.prototype.findPath = function (source, destination) {
    var steps = [{ x: source.x, y: source.y, vertical: true }];
    var visited = {};
    visited[source.x + source.y * this.getWidth()] = true;
    var pointer = 0;

    var getPath = function (step) {
        var path = [];
        while (step) {
            path.push(cc.p(step.x, step.y));
            step = step.prevStep;
        }
        return path.reverse();
    };

    while (pointer < steps.length) {
        var step = steps[pointer++];
        if (cc.pointEqualToPoint(step, destination)) {
            return getPath(step);
        }

        var dirs = step.vertical ? [Iso.DOWN_RIGHT, Iso.UP_LEFT, Iso.UP_RIGHT, Iso.DOWN_LEFT]
            : [Iso.UP_RIGHT, Iso.DOWN_LEFT, Iso.DOWN_RIGHT, Iso.UP_LEFT];
        dirs.forEach(function (dir) {
            var tx = step.x + dir.x;
            var ty = step.y + dir.y;
            if (this.isGround(tx, ty) && !visited[tx + ty * this.getWidth()] && this.getUnit(tx, ty) === undefined) {
                visited[tx + ty * this.getWidth()] = true;
                steps.push({
                    x: tx, y: ty, vertical: dir.x === 0, prevStep: step 
                });
            }
        }.bind(this));
    }

    return undefined;
};

Map2d.prototype.getGrowFrameData = function (x, y) {
    return this.growFrameData[y] && this.growFrameData[y][x];
};

Map2d.prototype.setGrowFrameData = function (x, y, data) {
    if (cleverapps.config.editorMode) {
        return;
    }

    if (!this.growFrameData[y]) {
        this.growFrameData[y] = {};
    }
    this.growFrameData[y][x] = data;
};

Map2d.prototype.getBorderType = function (x, y) {
    return this.borderTypes[y] && this.borderTypes[y][x];
};

Map2d.prototype.setBorderType = function (x, y, border) {
    if (cleverapps.config.editorMode) {
        return;
    }

    if (!this.borderTypes[y]) {
        this.borderTypes[y] = {};
    }
    this.borderTypes[y][x] = border;
};

Object.keys(Iso).forEach(function (key) {
    Iso[key].direction = true;
});

Map2d.GetPositionKey = function (x, y) {
    if (y === undefined) {
        y = x.y;
        x = x.x;
    }
    return x + "_" + y;
};

Map2d.getTilesTexture = function (level) {
    return "tiles_" + level.tiles;
};

Map2d.getUnitsTexture = function (level) {
    return "units_" + level.units;
};

Map2d.INITIAL_SCROLL_CELLS = {};

Map2d.RADIUS_SMALL = 3;
Map2d.RADIUS_BIG = 5;

Map2d.MAXSIZE = 80;

Map2d.VISIBLE_BOX = {
    width: 0.5,
    height: 0.5
};

Map2d.WATER_BORDER = 10;

Map2d.TERRAIN_EMPTY = ".";
Map2d.TERRAIN_GREEN = "g";

Map2d.TERRAINS = {
    g: "green",
    i: "ice",
    p: "purple",
    s: "sand",
    b: "blue",
    f: "fort"
};

Map2d.TERRAIN_BUNDLES = {};

Map2d.AVAILABLE_TILES = [];
Map2d.AVAILABLE_UNITS = [];

Object.keys(bundles).forEach(function (name) {
    if (name.match(/^tiles_[^_]+$/)) {
        Map2d.AVAILABLE_TILES.push(name);
    }

    if (name.match(/^units_[^_]+$/)) {
        Map2d.AVAILABLE_UNITS.push(name);
    }
});

Object.keys(Map2d.TERRAINS).forEach(function (code) {
    Map2d.AVAILABLE_TILES.forEach(function (tilesTexture) {
        var terrain = Map2d.TERRAINS[code];

        var bundle = bundles[tilesTexture + "_" + terrain];
        if (!bundle) {
            return;
        }

        Map2d.TERRAIN_BUNDLES[tilesTexture + "_" + terrain] = bundle;

        Object.keys(bundle.frames).forEach(function (frameId) {
            var baseId = frameId.replace("_" + terrain, "");

            if (bundle.frames[frameId] && !bundle.frames[baseId]) {
                bundle.frames[baseId] = bundle.frames[frameId];
            }
        });
    });
});
