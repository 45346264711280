/**
 * Created by slava on 4/21/17.
 */

cleverapps.TestPayments = function () {
    cleverapps.Payments.call(this);
};

cleverapps.TestPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.TestPayments.prototype.constructor = cleverapps.TestPayments;

cleverapps.TestPayments.prototype._purchase = function (product, callback) {
    setTimeout(function () {
        if (confirm(Messages.get("FakePayments.Buying") + " " + product.title + " for " + product.price)) {
            setTimeout(function () {
                callback(cleverapps.Social.CODE_SUCCEED);
            }, cleverapps.TestPayments.PROCESS_TIMEOUT);
        } else {
            setTimeout(function () {
                console.log(Messages.get("FakePayments.Cancelled"));
                callback(cleverapps.Social.CODE_CANCELLED);
            }, cleverapps.TestPayments.PROCESS_TIMEOUT);
        }
    }, 1000);
};

cleverapps.TestPayments.prototype.isAvailable = function () {
    return true;
};

cleverapps.TestPayments.prototype._restore = function () {
    // var productId = "addMoves";
    // this.restoreProduct = Product.Create(productId);
    // this.restorePurchase = Product.Create(productId);
    // this.restorePurchase.restore = true;
};

cleverapps.TestPayments.isAppropriate = function () {
    return TestSocial.isAppropriate();
};

cleverapps.TestPayments.prototype.validate = function (product, onSuccess) {
    if (onSuccess) {
        onSuccess();
    }
};

cleverapps.TestPayments.PROCESS_TIMEOUT = 0;