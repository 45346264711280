/**
 * Created by mac on 9/25/20
 */

var AdminLevels = function (administrator) {
    cleverapps.EventEmitter.call(this);

    this.levels = [];

    this.episodeStatistics = new EpisodeStatistics();
    this.versions = new LevelVersions();

    this.onReadyListeners = [];

    this.ready = false;

    this.episodeStatistics.whenReady(this.onStatisticsLoaded.bind(this));

    administrator.adminEpisodes.onUpdateHoverEpisodeListener = this.onUpdateEpisodesHover.bind(this);
};

AdminLevels.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLevels.prototype.constructor = AdminLevels;

AdminLevels.prototype.onUpdateEpisodesHover = function () {
    if (this.draggingLevel) {
        this.draggingLevel.setDragEpisode(cleverapps.administrator.adminEpisodes.hoverEpisode);
    }
};

AdminLevels.prototype.addLevel = function (adminLevel) {
    this.levels.splice(adminLevel.levelNo, 0, adminLevel);

    for (var i = 0; i < this.levels.length; i++) {
        if (!this.levels[i].isAddMoreLevel()) {
            this.levels[i].levelNo = i;
        }
    }

    this.trigger("addLevel", adminLevel);
};

AdminLevels.prototype.makeCurrent = function (adminLevel) {
    cleverapps.user.level = parseInt(adminLevel.levelNo);
    cleverapps.user.episode = parseInt(adminLevel.episodeNo);
    cleverapps.user.save();
};

AdminLevels.prototype.update = function () {
    this.levels.forEach(function (level) {
        level.onUpdate();
    });
};

AdminLevels.prototype.copyLevel = function (adminLevel) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;
    var levelNo = adminLevel.levelNo;

    var data = {
        language: cleverapps.settings.language
    };

    var onSuccess = function (response) {
        cleverapps.administrator.adminEpisodes.saveToStorage(episodeNo, response.episode);

        if (cleverapps.administrator.adminEpisodes.getActive() === adminEpisode) {
            this.loadData(function (episode) {
                this.addLevel(this.createLevel(episode, response.levelNo));
                this.episodeStatistics.reset(episode);
            }.bind(this));
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.COPY_LEVEL,
            episodeNo: episodeNo,
            levelNo: levelNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Copy level error");
    };

    cleverapps.RestClient.post("/admin/copylevel/" + episodeNo + "/" + adminLevel.levelNo, data, onSuccess, onError);
};

AdminLevels.prototype.copyLevelToClipboard = function (adminLevel) {
    var level = cleverapps.clone(adminLevel.getContent(), true);

    Object.assign(level, {
        clipboardInfo: {
            gameName: cleverapps.config.name
        }
    });

    cleverapps.copyToClipboard(JSON.stringify(level), function () {
        this.trigger("copyLevelToClipboard");

        cleverapps.notification.create("Copy level to clipboard");
    }.bind(this));
};

AdminLevels.prototype.levelFromClipboard = function (callback) {
    window.navigator.clipboard.readText().then(function (data) {
        var levelContent;
        try {
            levelContent = JSON.parse(data);
            if (!levelContent.clipboardInfo) {
                levelContent = undefined;
            }
            // eslint-disable-next-line no-empty
        } catch (e) {

        }
        callback(levelContent);
    }).catch(function () {
        callback();
    });
};

AdminLevels.prototype.pasteLevelFromClipboard = function (levelContent, callback) {
    if (["merge", "tile3"].indexOf(cleverapps.config.type) === -1) {
        return;
    }

    if (levelContent.clipboardInfo.gameName !== cleverapps.config.name) {
        if (levelContent.field) {
            levelContent.field.forEach(function (unit) {
                if (!Families[unit.code]) {
                    unit.code = "coins";
                    unit.stage = Families.coins.units.length - 1;
                } else if (Families[unit.code].units.length - 1 < unit.stage) {
                    unit.stage = Families[unit.code].units.length - 1;
                }
            });
        }

        if (levelContent.decorators) {
            levelContent.decorators = levelContent.decorators.filter(function (decorator) {
                return Map2dDecoratorView.DecoratorImageExists(decorator);
            });
        }
    }

    delete levelContent.clipboardInfo;

    var levelNo = this.levels.length - 1;

    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;

    var onSuccess = function (response) {
        cleverapps.administrator.adminEpisodes.saveToStorage(episodeNo, response.data);

        if (cleverapps.administrator.adminEpisodes.getActive() === adminEpisode) {
            this.loadData(function (episode) {
                this.addLevel(this.createLevel(episode, levelNo));
                this.episodeStatistics.reset(episode);
            }.bind(this));
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.PASTE_LEVEL,
            episodeNo: episodeNo,
            levelNo: levelNo
        });

        cleverapps.notification.create("Paste level from clipboard");

        callback();
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.error || "Paste level error");
        callback();
    };

    cleverapps.RestClient.post("/admin/pastelevel/" + episodeNo + "/" + levelNo, { levelContent: levelContent }, onSuccess, onError);
};

AdminLevels.prototype.moveLevel = function (options) {
    options = options || {};
    var data = {
        toEpisodeNo: options.toEpisodeNo,
        toLevelNo: options.toLevelNo,
        fromEpisodeNo: options.fromEpisodeNo,
        fromLevelNo: options.fromLevelNo,
        language: cleverapps.settings.language
    };

    var onSuccess = function (response) {
        cleverapps.administrator.adminEpisodes.saveToStorage(options.fromEpisodeNo, response.fromEpisode);
        if (response.toEpisode) {
            cleverapps.administrator.adminEpisodes.saveToStorage(options.toEpisodeNo, response.toEpisode);
        }

        if (response.toEpisode) {
            this.reset(options.callback);
        } else {
            options.callback();

            this.updateLevels();
            this.loadData(function (episode) {
                this.episodeStatistics.reset(episode);
            }.bind(this));
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.MOVE_LEVEL,
            toEpisodeNo: options.toEpisodeNo,
            toLevelNo: options.toLevelNo,
            fromEpisodeNo: options.fromEpisodeNo,
            fromLevelNo: options.fromLevelNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.errorMessage || "Move level error");
        this.reset(options.callback);
    }.bind(this);

    cleverapps.RestClient.post("/admin/movelevel/", data, onSuccess, onError);
};

AdminLevels.prototype.updateLevels = function () {
    this.levels.forEach(function (level) {
        level.onUpdate();
    });
};

AdminLevels.prototype.dragLevel = function (adminLevel, levelNo) {
    this.levels.splice(adminLevel.levelNo, 1);
    this.levels.splice(levelNo, 0, adminLevel);

    this.levels.forEach(function (level, levelNo) {
        level.setLevelNo(levelNo);
    });
};

AdminLevels.prototype.getStatistics = function () {

};

AdminLevels.prototype.createNew = function (blankContent, callback) {
    var levelNo = this.levels.length - 1;

    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeNo = adminEpisode.episodeNo;

    blankContent.levelNo = levelNo;
    blankContent.episodeNo = episodeNo;
    var data = {
        blankContent: blankContent
    };

    var onSuccess = function (response) {
        cleverapps.administrator.adminEpisodes.saveToStorage(episodeNo, response.data);

        if (cleverapps.administrator.adminEpisodes.getActive() === adminEpisode) {
            this.loadData(function (episode) {
                this.addLevel(this.createLevel(episode, levelNo));
                this.episodeStatistics.reset(episode);
            }.bind(this));
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.CREATE_LEVEL,
            episodeNo: episodeNo,
            levelNo: levelNo
        });

        callback();
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.error || "Create level error");
        callback();
    };

    cleverapps.RestClient.post("/admin/newlevel/" + episodeNo + "/" + levelNo, data, onSuccess, onError);
};

AdminLevels.prototype.removeActive = function (f) {
    var levelNo = this.active.levelNo;
    var episodeNo = this.active.episodeNo;

    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();

    var onSuccess = function (response) {
        cleverapps.administrator.adminEpisodes.saveToStorage(episodeNo, response.data);

        if (cleverapps.administrator.adminEpisodes.getActive() === adminEpisode) {
            this.reset(f);
        } else {
            f();
        }

        cleverapps.administrator.log.createEntry({
            type: AdminLog.DELETE_LEVEL,
            episodeNo: episodeNo,
            levelNo: levelNo
        });
    }.bind(this);

    var onError = function (response) {
        cleverapps.notification.create(response.error || "Delete level error");
        f();
    };

    cleverapps.RestClient.post("/admin/removelevel/" + episodeNo + "/" + levelNo, {}, onSuccess, onError);
};

AdminLevels.FIRST_LEVEL = 0;
AdminLevels.LAST_LEVEL = 1;

AdminLevels.prototype.onStatisticsLoaded = function (data) {
    this.whenReady(function () {
        data.forEach(function (levelData) {
            if (this.levels[levelData.levelNo]) {
                this.levels[levelData.levelNo].setDifficulty(levelData.difficulty);
            }
        }, this);
    }.bind(this));

    this.trigger("statisticsLoaded", data);
};

AdminLevels.prototype.reset = function (callback, suggestedLevel) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();

    if (typeof adminEpisode.episodeNo === "undefined") {
        return;
    }

    this.setActive(undefined);

    this.ready = false;

    this.loadData(function (episode) {
        this.updateData(episode);

        var selectedLevel = this.levels[0];
        if (suggestedLevel) {
            if (suggestedLevel === AdminLevels.LAST_LEVEL) {
                selectedLevel = this.levels[this.levels.length - 2];
            } else {
                selectedLevel = this.levels[suggestedLevel];
            }
        }

        if (selectedLevel && !selectedLevel.addMoreLevel) {
            this.setActive(selectedLevel);
        }

        if (callback) {
            callback();
        }

        this.runOnReadyListeners();

        this.ready = true;

        this.episodeStatistics.reset(episode);
        this.trigger("statisticsReset");
    }.bind(this));
};

AdminLevels.prototype.runOnReadyListeners = function () {
    var listeners = this.onReadyListeners;
    this.onReadyListeners = [];
    listeners.forEach(function (listener) {
        listener(this.data);
    }, this);
};

AdminLevels.prototype.whenReady = function (callback) {
    if (this.ready) {
        callback();
    } else {
        this.onReadyListeners.push(callback);
    }
};

AdminLevels.prototype.next = function (sign) {
    var pos = this.levels.indexOf(this.active) + sign;

    if (this.levels[pos] === undefined || this.levels[pos].isAddMoreLevel()) {
        return undefined;
    }

    return this.levels[pos];
};

AdminLevels.prototype.createLevel = function (episode, levelNo) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();

    var hashes = episode.getLevelHashes(levelNo);
    var version = episode.getMaxVersion(episode.getLevelVersions(levelNo));

    return AdminLevel.createFromData({
        levelNo: levelNo,
        episodeNo: adminEpisode.episodeNo,
        hashes: hashes,
        version: version
    }, episode.getLevels()[levelNo]);
};

AdminLevels.prototype.updateData = function (episode) {
    var levelsAndVersions = episode.getLevels();
    this.levels = [];
    for (var levelNo = 0; levelNo < 100 && levelsAndVersions[levelNo]; levelNo++) {
        this.levels.push(this.createLevel(episode, levelNo));
    }

    this.levels.push(AdminLevel.getAddMoreLevel());

    this.versions.reset();
    this.trigger("reset");
};

AdminLevels.prototype.getActive = function () {
    return this.active;
};

AdminLevels.prototype.setActive = function (level) {
    if (this.active !== level) {
        if (this.active !== undefined) {
            this.active.unselect();
        }

        this.active = level;

        if (cleverapps.config.adminMode) {
            cleverapps.setUrlHash({
                episode: this.active ? this.active.episodeNo : undefined,
                level: this.active ? this.active.levelNo : undefined
            });
        }

        if (level !== undefined) {
            level.select();
            this.trigger("chartUpdate", level);
            this.trigger("changeActive");
        }

        cleverapps.administrator.adminDetails.reset();
    }
};

AdminLevels.prototype.loadData = function (callback) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    if (!adminEpisode) {
        return;
    }

    var episode = new Episode(adminEpisode.episodeNo);
    episode.loadData(function () {
        if (adminEpisode !== cleverapps.administrator.adminEpisodes.getActive()) {
            return;
        }

        callback(episode);

        episode.destructor();
    });
};

AdminLevels.prototype.download = function (callback) {
    this.loadData(function (episode) {
        var data = JSON.stringify(episode.getContent(), undefined, 4);
        var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
        var fileName = Episode.BundleId(adminEpisode.episode.episodeNo) + ".json";

        this.trigger("downloadLevels", {
            fileName: fileName,
            data: data
        });
    }.bind(this));

    callback();
};

AdminLevels.prototype.upload = function (event, callback) {
    var files = event.target.files;
    var file = files[0];
    var end = file.name.indexOf(".json");
    if (end < 0) {
        cleverapps.notification.create("Error - File must be json format");
        callback();
        return;
    }

    var reader = new FileReader();

    reader.onload = function (event) {
        var content = event.target.result;

        cleverapps.administrator.adminEpisodes.upload(content, callback);
    };

    reader.readAsText(file);
};