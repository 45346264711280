/**
 * Created by andrey on 15.05.2023
 */

var YooKassaPayments = function () {
    cleverapps.RestPayments.call(this);

    for (var itemId in cleverapps.config.products) {
        var product = cleverapps.config.products[itemId];

        product.loadedPrice = this.calcLoadedPrice(product.price);
        product.loadedPriceAmount = parseInt(product.loadedPrice);
        product.loadedCurrencyCode = "RUB";
        product.loadedId = itemId;
    }

    cleverapps.playSession.set(cleverapps.EVENTS.STATS.YOOKASSA.DAU, true);
};

YooKassaPayments.prototype = Object.create(cleverapps.RestPayments.prototype);
YooKassaPayments.prototype.constructor = YooKassaPayments;

YooKassaPayments.prototype.calcLoadedPrice = function (price) {
    var loadedPrice = Math.floor(price * cleverapps.RUB_YOOKASSA_RATE) + " RUB";

    if (cleverapps.config.debugMode || cleverapps.WebViewPayments.DEBUG_IDFA.includes(cleverapps.user.idfa)) {
        loadedPrice = Math.max(1, Math.round(price / 3)) + " RUB";
    }

    return loadedPrice;
};

YooKassaPayments.prototype._purchase = function (product) {
    if (product.type === "subscription") {
        this.onPurchaseResult(cleverapps.Social.CODE_FAILED, product, "YooKassa subscriptions is not supported");
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YOOKASSA.OPEN);

    var idfa = cleverapps.user.idfa;
    var email = cleverapps.info.getValue("email");
    var merchantCustomerId = undefined;
    if (!cleverapps.user.haveTmpId() || idfa && !idfa.startsWith("0000")) {
        merchantCustomerId = cleverapps.user.id + "_" + idfa + "_" + email;
    }

    var purchase = {
        itemId: product.loadedId,
        price: product.loadedPriceAmount,
        loadedPriceAmount: product.loadedPriceAmount,
        email: email,
        merchantCustomerId: merchantCustomerId
    };

    var data = {
        userid: cleverapps.user.id,
        source: cleverapps.platform.source,
        purchase: purchase
    };

    console.log("YooKassaPayments.create", JSON.stringify(data));

    var onError = function (error) {
        this.onPurchaseResult(cleverapps.Social.CODE_FAILED, product, error);
    }.bind(this);

    cleverapps.RestClient.post("/yookassapayments/create", data, function (response) {
        console.log("YooKassaPayments.create response", JSON.stringify(response.error || response.payment || response));

        if (response.error && response.parameter === "receipt.customer.email") {
            cleverapps.notification.create("PurchaseCancelled.InvalidEmail");
            cleverapps.info.setKeyValue("email", "");
        }

        if (response.error || !response.payment) {
            onError(response.error);
            return;
        }

        response = response.payment;
        product.paymentId = response.paymentId;
        product.confirmation_type = response.confirmation && response.confirmation.type;
        product.confirmation_url = response.confirmation && response.confirmation.confirmation_url;
        product.confirmation_token = response.confirmation && response.confirmation.confirmation_token;

        console.log("YooKassaPayments.create Success: " + JSON.stringify(response));

        if (["waiting_for_capture", "succeeded"].includes(response.status)) {
            this.onPurchaseResult(cleverapps.Social.CODE_SUCCEED, product);
        } else if (["canceled"].includes(response.status)) {
            this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, product);
        } else if (response.status === "pending" && product.paymentId) {
            this.openWidget(product);
        } else {
            onError();
        }
    }.bind(this), onError);
};

YooKassaPayments.prototype.getFinishUrl = function (product) {
    var finishUrl = cleverapps.RestClient.getBase() + cleverapps.config.restApiURL
        + "/yookassapayments/finish/" + encodeURIComponent(cleverapps.user.id);

    var params = new URLSearchParams();
    if (cc.sys.isNative) {
        var appLink = "cleverapps://" + cleverapps.config.name;
        if (cleverapps.config.androidStaging) {
            appLink += "staging";
        }
        params.set("app_link", appLink);
    }

    if (product && product.paymentId) {
        params.set("paymentId", product.paymentId);
    }

    if (params.size > 0) {
        finishUrl += "?" + params.toString();
    }

    return finishUrl;
};

YooKassaPayments.prototype.getWidgetUrl = function (product) {
    var base = cc.sys.isNative ? cleverapps.config.staticUrl : location.href.substring(0, location.href.lastIndexOf("/") + 1);
    return base + "cleverapps/res/yookassa-widget.html"
        + "?confirmation_token=" + product.confirmation_token
        + "&payment_id=" + product.paymentId
        + "&finish_url=" + encodeURIComponent(this.getFinishUrl(product))
        + "&modal=false";
};

YooKassaPayments.prototype.openWidget = function (product) {
    var widgetUrl = this.getWidgetUrl(product);
    YooKassaPlugin.openWidget(widgetUrl);

    this.setNotifyActiveListener(this.onWidgetClosed.bind(this));
};

YooKassaPayments.prototype.onWidgetClosed = function () {
    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !this.purchaseHandler || !this.purchaseProduct) {
        return;
    }

    if (this.purchaseProduct) {
        this.startStatusChecker();
    }
};

YooKassaPayments.prototype.stopStatusChecker = function () {
    if (this.statusChecker) {
        this.statusChecker.stop();
        delete this.statusChecker;
    }
};

YooKassaPayments.prototype.startStatusChecker = function () {
    this.stopStatusChecker();

    var purchase = this.purchaseProduct;

    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !purchase || !purchase.paymentId) {
        return;
    }

    var waitPurchaseStateEndTime = Date.now() + YooKassaPayments.WAIT_PURCHASE_STATE_TIMEOUT;

    var check = function () {
        console.log("YooKassaPayments.statusCheck: " + purchase.paymentId);
        cleverapps.RestClient.get("/yookassapayments/get/" + purchase.paymentId, {}, function (response) {
            if (response.error || !response.payment) {
                console.log(response.error || "no payment");
                return;
            }

            var payment = response.payment;

            console.log("YooKassaPayments.statusCheck success: " + JSON.stringify(payment));

            if (["waiting_for_capture", "succeeded"].includes(payment.status)) {
                this.onPurchaseResult(cleverapps.Social.CODE_SUCCEED, purchase);
            } else if (["canceled"].includes(payment.status) || payment.purchaseState === "cancel") {
                this.onPurchaseResult(cleverapps.Social.CODE_CANCELLED, purchase);
            } else if (["initialize_error", "fail"].includes(payment.purchaseState)) {
                this.onPurchaseResult(cleverapps.Social.CODE_FAILED, purchase);
            } else if (Date.now() > waitPurchaseStateEndTime && !["finished", "success"].includes(payment.purchaseState)) {
                console.log("YooKassaPayments.statusCheck wait purchaseState timeout");
                this.onPurchaseResult(cleverapps.Social.CODE_FAILED, purchase);
            }
        }.bind(this));
    }.bind(this);

    this.statusChecker = this.startChecker({
        check: check,
        timeouts: cleverapps.arrayFill(5, "1 seconds").concat(cleverapps.RestPayments.TIMEOUTS)
    });
    this.setCancelTimeout(YooKassaPayments.WAIT_SUCCEEDED_TIMEOUT);
};

YooKassaPayments.prototype.onPurchaseResult = function () {
    cleverapps.RestPayments.prototype.onPurchaseResult.apply(this, arguments);

    this.stopStatusChecker();
};

YooKassaPayments.prototype.logPurchase = function (purchase, info) {
    console.log("YooKassaPayments.logPurchase - " + info.price);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YOOKASSA.PAID_COUNT);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.YOOKASSA.PAID_AMOUNT, {
        value: info.price
    });
};

YooKassaPayments.prototype.isAvailable = function () {
    return true;
};

YooKassaPayments.isAppropriate = function () {
    return cleverapps.platform.oneOf(AndroidPlatform)
        && cleverapps.country.isInitialized() && cleverapps.country.isRussia()
        && typeof YooKassaPlugin !== "undefined" && YooKassaPlugin.openWidget;
};

YooKassaPayments.WAIT_PURCHASE_STATE_TIMEOUT = cleverapps.parseInterval("2 seconds");
YooKassaPayments.WAIT_SUCCEEDED_TIMEOUT = cleverapps.parseInterval("60 seconds");
