/**
 * Created by vlad on 10.02.18.
 */

var PromptWindow = Window.extend({
    onWindowLoaded: function (options) {
        var content = this.getContent(options);

        this._super({
            name: "promptwindow",
            content: content,
            closeButton: true,
            button: {
                width: 150,
                text: "OK",
                onPressed: function () {
                    var level = this.editBox.getString();
                    options.callback(level);
                    this.close();
                }.bind(this)
            }
        });

        this.editBox.attachWithIME();
    },
    getContent: function (options) {
        var content = new cc.Node();

        var editBox = new ccui.TextField();
        var color = new cc.Color(255, 255, 255, 255);
        editBox.setTextColor(color);
        editBox.setPlaceHolderColor(color);
        this.editBox = editBox;

        content.addChild(editBox);

        editBox.setFontSize(60);
        editBox.setPlaceHolder("");
        if (options.defaultVal !== undefined) {
            editBox.setString(options.defaultVal);
        }

        var boxBg = cleverapps.UI.createScale9Sprite(bundles.tool.frames.tool_bg);
        boxBg.setContentSize2(400 * resolutionScale, 80 * resolutionScale);
        content.addChild(boxBg, -1);
        boxBg.setPositionRound(content.width / 2, content.height / 2);

        var label = cleverapps.UI.generateOnlyText(options.text || "", cleverapps.styles.FONTS.WINDOW_TEXT);

        return new cleverapps.Layout([label, content], {
            direction: cleverapps.UI.VERTICAL,
            margin: 50 * resolutionScale,
            padding: cc.multPadding(cc.padding(100, 150), resolutionScale)
        });
    }
});