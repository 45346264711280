/**
 * Created by mac on 1/30/20
 */

cleverapps.SkinManager.SKINS.xmas = {
    bundle: "xmas",
    localization: {
        tripeaks: {
            "Missions.LetterTournament.Title": "Xmas.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Xmas.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Xmas.MissionRulesWindow.LetterTournament.text3",
            "AddMovesWindow.missionGiveUp.LetterTournament": "Xmas.AddMovesWindow.missionGiveUp.LetterTournament",
            "AddMovesWindow.mission.LetterTournament": "Xmas.AddMovesWindow.mission.LetterTournament",
            "CloversTooltip": "Xmas.CloversTooltip"
        },

        wordsoup: {
            "Missions.LetterTournament.Title": "Xmas.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Xmas.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Xmas.MissionRulesWindow.LetterTournament.text3",
            "WardrobeDialogue.stage0.text0": "Xmas.WardrobeDialogue.stage0.text0",
            "WardrobeDialogue.stage1.text0": "Xmas.WardrobeDialogue.stage1.text0",
            "WardrobeDialogue.stage2.text0": "Xmas.WardrobeDialogue.stage2.text0",
            "WardrobeDialogue.stage3.text0": "Xmas.WardrobeDialogue.stage3.text0",
            "WardrobeDialogue.stage4.text0": "Xmas.WardrobeDialogue.stage4.text0",
            "WardrobeDialogue.stage5.text0": "Xmas.WardrobeDialogue.stage5.text0",
            "WardrobeDialogue.stage6.text0": "Xmas.WardrobeDialogue.stage6.text0",
            "WardrobeDialogue.stage7.text0": "Xmas.WardrobeDialogue.stage7.text0",
            "WardrobeDialogue.stage8.text0": "Xmas.WardrobeDialogue.stage8.text0",
            "WardrobeDialogue.stage9.text0": "Xmas.WardrobeDialogue.stage9.text0",
            "WardrobeDialogue.stage10.text0": "Xmas.WardrobeDialogue.stage10.text0",
            "WardrobeDialogue.stage11.text0": "Xmas.WardrobeDialogue.stage11.text0",
            "WardrobeDescText": "Xmas.WardrobeDescText",
            "WardrobeRulesText": "Xmas.WardrobeRulesText",
            "WardrobePrizeRewardTitle": "Xmas.WardrobePrizeRewardTitle",
            "Missions.LetterTournament.Rules1": "Xmas.Missions.LetterTournament.Rules1"
        },

        scramble: {
            "Missions.LetterTournament.Title": "Xmas.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Xmas.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Xmas.MissionRulesWindow.LetterTournament.text3"
        }
    },
    slots: {
        localization: function (code) {
            var localization = cleverapps.SkinManager.SKINS.easter.localization;
            return localization && localization[cleverapps.config.name] && localization[cleverapps.config.name][code];
        },
        windowDecors: function (options) {
            var decors = cleverapps.clone(options.decors || {}, true);

            decors.xmas_window_top = true;
            decors.xmas_window_title_right = true;
            decors.xmas_window_title_left = true;
            decors.xmas_window_right_top = true;
            decors.xmas_window_right_bottom = true;
            decors.xmas_window_left_bottom = true;
            decors.xmas_window_left_top = true;
            decors.xmas_window_alternative_title_right = true;
            decors.xmas_window_alternative_title_left = true;

            if (cleverapps.config.ui === "tropical") {
                decors.decor_title_right = false;
                decors.decor_title_left = false;
                decors.window_liana_top_left = false;
                decors.window_liana_top_right = false;
                decors.window_liana_left = false;
                decors.window_liana_right = false;
                decors.window_liana_bottom_left = false;
                decors.window_liana_bottom_right = false;
            }

            if (cleverapps.config.name === "mergecraft" && options.window instanceof OrdersWindow) {
                decors.xmas_window_right_top = false;
                decors.xmas_window_right_bottom = false;
                decors.xmas_window_left_bottom = false;
                decors.xmas_window_left_top = false;
            }

            if (cleverapps.config.name === "mergecraft" && (options.window instanceof BuildPassWindow || options.window instanceof SalePassWindow)) {
                decors.xmas_window_right_top = false;
                decors.xmas_window_left_top = false;

                decors.xmas_window_right_top_bgonly = true;
                decors.xmas_window_left_top_bgonly = true;
            }

            if (cleverapps.config.name === "fairy" && options.window instanceof UnitsLibraryWindow) {
                decors.xmas_window_left_bottom = false;
            }

            return decors;
        },
        hero: function (options) {
            if (bundles.xmas.jsons[options.color + "_hero_spine_json"]) {
                return bundles.xmas.jsons[options.color + "_hero_spine_json"];
            }
        },
        spine: function (options) {
            var spines = {};
            if (["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.heroes.jsons.A_hero_spine_json] = bundles.xmas.jsons.A_hero_spine_json;
                spines[bundles.heroes.jsons.B_hero_spine_json] = bundles.xmas.jsons.B_hero_spine_json;
                spines[bundles.heroes.jsons.C_hero_spine_json] = bundles.xmas.jsons.C_hero_spine_json;
                spines[bundles.heroes.jsons.D_hero_spine_json] = bundles.xmas.jsons.D_hero_spine_json;
                spines[bundles.heroes.jsons.E_hero_spine_json] = bundles.xmas.jsons.E_hero_spine_json;
                spines[bundles.heroes.jsons.W_hero_spine_json] = bundles.xmas.jsons.W_hero_spine_json;
            }
            if (["tripeaks"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.xmas.jsons.xmas_mission_window_json;
            }
            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.xmas.jsons.mission_window_json;
                cleverapps.styles.MissionWindow.heroTitleAnimation = cleverapps.styles.xmasNewStyles.heroTitleAnimation;
            }

            if (["wordsoup", "tripeaks", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.sidebar.jsons.mission_letter_icon_json] = bundles.sidebar.jsons.xmas_letter_json;
                spines[bundles.sidebar.jsons.wardrobe_icon_json] = bundles.xmas.jsons.wardrobe_sidebar_icon_json;
            }

            return spines[options.json] ? spines[options.json] : options.json;
        },
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.xmas_menubar;
        },
        menuBarLevelItem: function () {
            return cleverapps.styles.SceneDecors.xmas_menubar_level;
        },
        sidebarBg: function () {
            return bundles.sidebar.frames.xmas_sidebar_bg;
        },
        sidebarIcon: function () {
            return cleverapps.styles.SceneDecors.xmas_sidebar;
        },
        toolbarDecors: function () {
            return cleverapps.styles.SceneDecors.xmas_toolbar;
        },
        sceneControlButton: function () {
            return cleverapps.styles.SceneDecors.xmas_control_buttons;
        },
        progressViewDecor: function () {
            return cleverapps.styles.SceneDecors.progressViewDecor;
        },
        gameAudio: function () {
            if (cleverapps.config.name === "wordsoup") {
                return bundles.xmas.urls.background_music_game;
            }
        },
        sceneBg: function () {
            if (cleverapps.config.name === "wordsoup") {
                return {
                    bundle: "xmas",
                    patternId: "pattern_bg_main_png"
                };
            }
        },
        gameSceneBg: function () {
            if (cleverapps.config.name === "wordsoup") {
                return {
                    bundle: "xmas",
                    patternId: "pattern_bg_main_png"
                };
            }
        },
        missionIcon: function (options) {
            if (["wordsoup", "tripeaks", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.xmas.frames.mission_letter_png;
            }
        },
        missionStar: function (options) {
            if (["wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.xmas.frames["mission_star" + options.type + "_png"];
            }

            if (["tripeaks"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.xmas.frames.mission_letter_png;
            }
        },
        missionRulesWindow: function (options) {
            if (["wordsoup", "tripeaks", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.xmas.jsons.letter_rules_json;
            }
        },
        wardrobeWindowBanner: function () {
            return bundles.xmas.frames.window_banner;
        },
        cardMarkSpine: function (options) {
            if (["tripeaks"].indexOf(cleverapps.config.name) !== -1 && options.type === "mission") {
                return bundles.xmas.jsons.xmas_marks_json;
            }
            return options.json;
        },
        wardrobeBundle: function () {
            return "xmas";
        }
    }
};
