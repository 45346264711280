/**
 * Created by spepa on 10/19/20
 */

var AdminLevelAnnotation = cc.Node.extend({
    ctor: function (availableWidth) {
        var styles = cleverapps.styles.AdminLevelAnnotation;

        this._super();
        this.adminLevel = cleverapps.administrator.adminLevels.getActive();

        this.setContentSize2(availableWidth, styles.height);
        this.setAnchorPoint2();

        this.bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.tool_tip_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.bg.setContentSize(this.getContentSize());
        this.bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.bg, -1);

        this.createContent();
    },

    createContent: function () {
        var content = this.content = new cc.Node();

        var styles = cleverapps.styles.AdminLevelAnnotation;

        var text = this.text = new cc.EditBox(cc.size(this.width - styles.textPadding.x, this.height - styles.textPadding.y));
        text.setMaxLength(-1);
        text.setString(" ");
        text.setDelegate(this);
        text.stayOnTop(true);
        text.setTouchEnabled();

        text.setFontColor(new cc.Color(cleverapps.styles.FONTS.ADMIN_TOOLTIP_TEXT.color));
        text.setFontSize(cleverapps.styles.FONTS.ADMIN_TOOLTIP_TEXT.size);
        text.setFontName(cleverapps.UI.getFontName());

        text.baseTxt = this.adminLevel.comments || "";
        text.setString(text.baseTxt);

        this.content.addChild(text);

        var scroll = this.scroll = new cleverapps.UI.ScrollView(content, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE
        });
        scroll.setContentSize(this.width, this.height - styles.scroll.paddingY);
        scroll.setPositionRound(this.width / 2, this.height / 2);
        scroll.setBarPadding(styles.scroll.bar);
        this.addChild(scroll, -1);
    },

    editBoxEditingDidEnd: function () {
        if (!this.visible || this.adminLevel !== cleverapps.administrator.adminLevels.getActive()) {
            return;
        }

        var msg = this.text.getString().trim();
        if (this.text.baseTxt !== msg) {
            this.text.baseTxt = msg;
            this.adminLevel.saveComments(msg);
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_TOOLTIP_TEXT: {
        size: 17,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.styles.AdminLevelAnnotation = {
    height: 100,

    textPadding: {
        x: 40,
        y: 20
    },

    scroll: {
        paddingY: 10,
        bar: {
            cornerPadding: 10,
            sidePadding: 10
        }
    },

    text: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 0 }
    }
};
