/**
 * Created by Andrey Popov on 1/25/21.
 */

var SceneComponentView = cc.Node.extend({
    ctor: function (sceneComponent, eventBus, parent, sceneSize) {
        this._super();

        this.sceneComponent = sceneComponent;
        this.parentComponent = parent;

        var properties = cleverapps.clone(sceneComponent.properties, true);

        ["width", "height", "scale", "position"].forEach(function (propName) {
            if (properties[propName]) {
                properties[propName] = Wysiwyg.getValueForResolution(properties[propName]);
            }
        });

        this.setAnchorPoint2();

        if (properties.width) {
            properties.width = parent.width * parseFloat(properties.width) / 100;
        }
        if (properties.height) {
            properties.height = parent.height * parseFloat(properties.height) / 100;
        }
        var ComponentClass = sceneComponent.ctor();
        var componentInstance = this.componentInstance = new ComponentClass(properties, eventBus, parent, sceneSize);
        if (!componentInstance.disableAnchors) {
            this.setAnchorPoint2(properties.anchorX !== undefined ? properties.anchorX : 0.5, properties.anchorY !== undefined ? properties.anchorY : 0.5);
        }

        if (properties.position && properties.position.x) {
            properties.position.x.dx = parent.width * parseFloat(properties.position.x.dx) / 100;
        }
        if (properties.position && properties.position.y) {
            properties.position.y.dy = parent.height * parseFloat(properties.position.y.dy) / 100;
        }
        this.setPositionRound(properties.position);

        if (properties.height && !properties.width) {
            this.setContentSize(componentInstance.width, properties.height);
        } else if (!properties.height && properties.width) {
            this.setContentSize(properties.width, componentInstance.height);
        } else {
            this.setContentSize(componentInstance.getContentSize());
        }

        if (properties.scale) {
            componentInstance.setScale(properties.scale);
        }

        if (properties.rotation) {
            this.setRotation(properties.rotation);
        }

        if (properties.zOrder !== undefined) {
            this.setLocalZOrder(properties.zOrder);
        }

        if (properties.visible === false) {
            this.setVisible(false);
        }

        componentInstance.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(componentInstance);

        if (properties.isPreview) {
            cleverapps.UI.onClick(componentInstance, sceneComponent.handleClick.bind(sceneComponent), {
                ignoreScale: true
            });
        }

        componentInstance.nestedComponents = [];

        this.sceneComponent.onGetView = this.createListener(function () {
            return componentInstance;
        });
    },

    updateItemTools: function (visible) {
        if (this.componentInstance.disableTools) {
            return;
        }

        if (!this.tools) {
            var tools = this.tools = cleverapps.UI.createScale9Sprite(bundles.wysiwyg.frames.preview_frame);
            tools.setAnchorPoint2();
            tools.setLocalZOrder(10000);
            this.componentInstance.addChild(tools);

            var position = Wysiwyg.getValueForResolution(this.sceneComponent.properties.position);

            if (!this.componentInstance.disableHeightChange) {
                if (position.x.align !== "right") {
                    var changeWidthControl = this.changeWidthControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                    changeWidthControl.setRotation(-90);
                    changeWidthControl.setAnchorPoint2();
                    tools.addChild(changeWidthControl);
                }

                if (position.x.align !== "left") {
                    var changeWidthControlLeft = this.changeWidthControlLeft = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                    changeWidthControlLeft.setRotation(90);
                    changeWidthControlLeft.setAnchorPoint2();
                    tools.addChild(changeWidthControlLeft);
                }

                if (position.y.align !== "bottom") {
                    var changeHeightControl = this.changeHeightControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                    changeHeightControl.setAnchorPoint2();
                    tools.addChild(changeHeightControl);
                }

                if (position.y.align !== "top") {
                    var changeHeightControlTop = this.changeHeightControlTop = new cc.Sprite(bundles.editor_controls.frames.preview_control);
                    changeHeightControlTop.setRotation(180);
                    changeHeightControlTop.setAnchorPoint2();
                    tools.addChild(changeHeightControlTop);
                }
            }

            var changeSizeControl = this.changeScaleControl = new cc.Sprite(bundles.editor_controls.frames.preview_control);
            changeSizeControl.setRotation(-45);
            changeSizeControl.setAnchorPoint2();
            tools.addChild(changeSizeControl);

            if (this.componentInstance.customTools) {
                var customButtons = Object.keys(this.componentInstance.customTools).map(function (name) {
                    var customTool = this.componentInstance.customTools[name];
                    return new cleverapps.UI.Button({
                        text: name,
                        textVariant: "strict_black",
                        onClicked: customTool.action.bind(customTool)
                    });
                }, this);

                var customTools = this.customTools = new cleverapps.Layout(customButtons, {
                    direction: cleverapps.UI.VERTICAL,
                    reversed: true
                });
                customTools.setAnchorPoint2(0.5, 1);
                tools.addChild(customTools);
            }

            var startPosition;
            var parent = this.getParent();
            cleverapps.UI.onDrag(tools, {
                onDragStart: function () {
                    startPosition = this.getPosition();
                    return true;
                }.bind(this),
                onDragMove: function (touch) {
                    var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                    this.setPosition(
                        startPosition.x + displacement.x * this.componentInstance.scale,
                        startPosition.y + displacement.y * this.componentInstance.scale
                    );
                }.bind(this),
                onDragEnd: function (touch) {
                    var displacement = this.parent.convertTouchToNodeSpaceDisplacement(touch);
                    this.shiftPosition(
                        Math.round(displacement.x * this.componentInstance.scale),
                        Math.round(displacement.y * this.componentInstance.scale)
                    );
                }.bind(this)
            });

            var addChangeSizeListener = function (control, dimension, sign) {
                cleverapps.UI.onDrag(control, {
                    onDragStart: function () {
                        startPosition = this.getPosition();
                        return true;
                    }.bind(this),
                    onDragMove: function (touch) {
                        var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
                        var width, height;
                        this.tools.replaceParentSamePlace(parent);
                        this.tools.setVisible(false);

                        if (dimension === "width") {
                            var alignCoefX = position.x.align === "center" ? 2 : 1;
                            width = Math.round(this.componentInstance.width + sign * displacement.x * this.componentInstance.scale * alignCoefX);
                            this.sceneComponent.change("width", Wysiwyg.setValueForResolution(this.sceneComponent.properties.width, width));
                        } else if (dimension === "height") {
                            var alignCoefY = position.y.align === "center" ? 2 : 1;
                            height = Math.round(this.componentInstance.height + sign * displacement.y * this.componentInstance.scale * alignCoefY);
                            this.sceneComponent.change("height", Wysiwyg.setValueForResolution(this.sceneComponent.properties.height, height));
                        } else {
                            width = Math.round(this.componentInstance.width + sign * displacement.x * this.componentInstance.scale);
                            height = Math.round(this.componentInstance.height - sign * displacement.y * this.componentInstance.scale);
                            this.sceneComponent.change("width", Wysiwyg.setValueForResolution(this.sceneComponent.properties.width, width));
                            this.sceneComponent.change("height", Wysiwyg.setValueForResolution(this.sceneComponent.properties.height, height));
                        }
                    }.bind(this),
                    onDragEnd: function () {
                        this.tools.removeFromParent();
                    }.bind(this)
                });
            }.bind(this);

            changeWidthControl && addChangeSizeListener(changeWidthControl, "width", 1);
            changeWidthControlLeft && addChangeSizeListener(changeWidthControlLeft, "width", -1);
            changeHeightControl && addChangeSizeListener(changeHeightControl, "height", -1);
            changeHeightControlTop && addChangeSizeListener(changeHeightControlTop, "height", 1);
            changeSizeControl && addChangeSizeListener(changeSizeControl, "both", 2);
        }

        if (visible && !this.keyboardListener && "keyboard" in cc.sys.capabilities) {
            this.keyboardListener = cc.eventManager.addListener({
                event: cc.EventListener.KEYBOARD,
                onKeyPressed: function (key) {
                    var keys = {};
                    keys[cc.KEY.up] = { x: 0, y: 1 };
                    keys[cc.KEY.down] = { x: 0, y: -1 };
                    keys[cc.KEY.left] = { x: -1, y: 0 };
                    keys[cc.KEY.right] = { x: 1, y: 0 };

                    if (keys[key]) {
                        this.shiftPosition(keys[key].x, keys[key].y);
                    }
                }.bind(this)
            }, this);
        }

        if (!visible && this.keyboardListener) {
            cc.eventManager.removeListener(this.keyboardListener);
            delete this.keyboardListener;
        }

        var styles = cleverapps.styles.SceneComponentView;

        this.tools.setPositionRound(this.width / 2, this.height / 2);
        this.tools.setContentSize(this.getContentSize());
        this.tools.setVisible(visible);

        this.changeWidthControl && this.changeWidthControl.setPositionRound(styles.changeWidth);
        this.changeWidthControlLeft && this.changeWidthControlLeft.setPositionRound(styles.changeWidthLeft);
        this.changeHeightControl && this.changeHeightControl.setPositionRound(styles.changeHeight);
        this.changeHeightControlTop && this.changeHeightControlTop.setPositionRound(styles.changeHeightTop);
        this.changeScaleControl && this.changeScaleControl.setPositionRound(styles.changeScale);

        this.customTools && this.customTools.setPositionRound(styles.customTools);
    },

    shiftPosition: function (dx, dy) {
        var position = Wysiwyg.getValueForResolution(this.sceneComponent.properties.position);
        var newDx = position.x.dx;
        if (newDx) {
            newDx = this.parentComponent.width * parseFloat(newDx) / 100;
        }
        newDx += dx;

        var newDy = position.y.dy;
        if (newDy) {
            newDy = this.parentComponent.height * parseFloat(newDy) / 100;
        }
        newDy += dy;

        this.sceneComponent.change("position", Wysiwyg.setValueForResolution(this.sceneComponent.properties.position, {
            x: {
                align: position.x.align,
                dx: parseFloat((newDx * 100 / this.parentComponent.width).toFixed(2))
            },
            y: {
                align: position.y.align,
                dy: parseFloat((newDy * 100 / this.parentComponent.height).toFixed(2))
            }
        }));
    }
});

cleverapps.styles.SceneComponentView = {
    changeWidth: {
        x: { align: "right", dx: 25 },
        y: { align: "center" }
    },

    changeWidthLeft: {
        x: { align: "left", dx: -25 },
        y: { align: "center" }
    },

    changeHeight: {
        x: { align: "center" },
        y: { align: "bottom", dy: -25 }
    },

    changeHeightTop: {
        x: { align: "center" },
        y: { align: "top", dy: 25 }
    },

    changeScale: {
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: -15 }
    },

    customTools: {
        x: { align: "center" },
        y: -20
    }
};