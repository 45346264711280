/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorldScene = cleverapps.FixedWidthScene.extend({
    ctor: function (mission) {
        this.bonusWorld = mission.bonusWorld;

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_BONUS_WORLD);

        for (var levelNo = 0; levelNo < Episode.LEVELS_PER_EPISODE; levelNo++) {
            new BonusWorldLevelView(new BonusWorldLevel(this.bonusWorld, levelNo), this.scene);
        }

        this.scene.addChild(new BonusWorldRewardsView(this.bonusWorld));

        cleverapps.environment.episodeNo = this.bonusWorld.config.episode;

        cleverapps.playSession.set(cleverapps.EVENTS.BONUS_WORLD_DAU, true);
    },

    onExit: function () {
        this._super();

        this.bonusWorld.purge();
    },

    getBackgroundStyles: function () {
        var bundleName = this.bonusWorld.backgroundBundleId();
        return {
            bundle: bundleName,
            backgroundId: "background",
            animation: bundles[bundleName].jsons.background_spine
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles[this.bonusWorld.backgroundBundleId()].urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        var bundles = [BonusWorldConfig[this.bonusWorld.type].bundle];

        bundles.push(this.bonusWorld.backgroundBundleId());

        return bundles;
    }
});
