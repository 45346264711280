/**
 * Created by andrey on 06.06.2023.
 */

cleverapps.Country = function () {
    this.load();

    this.onInitializedListeners = {};

    if (cleverapps.platform.oneOf(AndroidPlatform)) {
        cleverapps.timeouts.setTimeout(this.setInitialized.bind(this, true), cleverapps.parseInterval("1 minute"));
    } else {
        this.setInitialized(true);
    }
};

cleverapps.Country.prototype.load = function () {
    var info = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.COUNTRY) || {};
    this.currency = info.currency;
};

cleverapps.Country.prototype.save = function () {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.COUNTRY, {
        currency: this.currency
    });
};

cleverapps.Country.prototype.setCurrency = function (currency) {
    if (this.currency !== currency) {
        this.currency = currency;
        this.save();
    }

    this.setInitialized(true);
};

cleverapps.Country.prototype.isRussia = function () {
    var byCurrency = !cleverapps.WebViewPayments.DEBUG_IDFA.includes(cleverapps.user.idfa);

    return this.currency && byCurrency ? cleverapps.Country.RUB === this.currency
        : cleverapps.settings.language === cc.sys.LANGUAGE_RUSSIAN;
};

cleverapps.Country.prototype.isRuCurrency = function () {
    return cleverapps.Country.RUB === this.currency;
};

cleverapps.Country.prototype.getCode = function () {
    return this.isRussia() ? "ru" : "other";
};

cleverapps.Country.prototype.setInitialized = function (initialized) {
    this.initialized = initialized;

    if (!this.initialized) {
        return;
    }

    for (var key in this.onInitializedListeners) {
        var listener = this.onInitializedListeners[key];
        listener();
    }

    this.onInitializedListeners = {
    };
};

cleverapps.Country.prototype.isInitialized = function () {
    return this.initialized;
};

cleverapps.Country.prototype.whenInitialized = function (name, callback) {
    if (this.isInitialized()) {
        callback();
    } else {
        this.onInitializedListeners[name] = callback;
    }
};

cleverapps.Country.RUB = "RUB";