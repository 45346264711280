/**
 * Created by denis on 15 june 2020
 */

var FingerViewEx = FingerView.extend({
    ctor: function (options) {
        options = options || {};

        this.noCircle = options.noCircle;

        var isAllowableScene = cleverapps.environment.isGameScene() || cleverapps.environment.isWysiwygScene();

        if (isAllowableScene && ["board", "match3"].indexOf(cleverapps.config.type) >= 0
            && cleverapps.config.name !== "scramble" && cleverapps.config.source !== "playable") {
            options.pathColor = cleverapps.styles.COLORS.PATH_COLOR;

            if (cleverapps.config.type === "match3") {
                if (cleverapps.bundleLoader.isLoaded("screenshots")) {
                    cleverapps.overrideStyles(cleverapps.styles.Path, {
                        image: bundles.screenshots.frames.line_png
                    });
                } else {
                    options.pathColor = undefined;
                }
            }
        }

        this._super(options);

        if (!options.image) {
            this.finger.setTexture();
            this.noCircle = true;
        }
        var anchorPoint = cleverapps.styles.FingersAnchors[options.name];
        if (anchorPoint) {
            var anchorX = anchorPoint.anchorX !== undefined ? anchorPoint.anchorX : 0.5;
            var anchorY = anchorPoint.anchorY !== undefined ? anchorPoint.anchorY : 0.5;
            this.finger.setAnchorPoint2(anchorX, anchorY);
        }

        if (cleverapps.getRequestParameters(location.hash).showFingerAnchor) {
            this.showAnchorPoint();
        }

        this.moveSpeed = options.moveSpeed || FingerViewEx.MOVE_SPEED[cleverapps.config.name] || FingerViewEx.MOVE_SPEED[cleverapps.config.type] || FingerViewEx.MOVE_SPEED.default;
    },

    getMoveDuration: function (position) {
        var duration = cc.pDistance(position, this.finger) / this.moveSpeed;
        duration = (duration + 0.3) / (duration + 1.3); // hyperbolic adjustment: minimal duration ~0.23 sec, maximal duration 1 sec.
        if (this.isRelaxed) {
            duration = 0.3;
        }
        return duration;
    },

    run: function (options) {
        this.currentAction = 0;
        this.runOptions = options || {};
        this.startCurrentAction();
    },

    moveTo: function (position, options) {
        this.addAction(function (callback) {
            options = options || {};

            var duration = options.duration || this.getMoveDuration(position);
            var action = new cc.MoveTo(duration, position);

            if (!this.isRelaxed && !options.noEasing) {
                if (cleverapps.config.name === "tripeaks") {
                    action.easing(cc.easeInOut(3));
                } else if (cleverapps.config.type === "merge") {
                    action.easing(cc.easeQuadraticActionInOut());
                } else if (cleverapps.config.type === "match3") {
                    action.easing(cc.easeExponentialInOut());
                } else if (cleverapps.config.type === "board") {
                    action.easing(cc.easeExponentialOut());
                }
            }

            this.finger.runAction(new cc.Sequence(
                action,
                new cc.CallFunc(function () {
                    if (this.pressed && this.pathNode) {
                        this.pathNode.push(position);
                    }
                    this.isRelaxed = false;
                    callback();
                }.bind(this))
            ));
        }.bind(this));
    },

    relax: function (delay) {
        this.addAction(function (callback) {
            var x = 0;
            var y = 0;

            if (this.relaxPosition) {
                x = typeof this.relaxPosition.x === "number" ? this.relaxPosition.x : this.finger.x;
                y = typeof this.relaxPosition.y === "number" ? this.relaxPosition.y : this.finger.y;
            }

            this.finger.runAction(new cc.Sequence(
                new cc.MoveTo(delay, cc.p(x, y)).easing(this.relaxEasing || cc.easeCircleActionOut()),
                new cc.CallFunc(function () {
                    this.isRelaxed = true;
                    callback();
                }.bind(this))
            ));
        }.bind(this));
    },

    moveZigzag: function (endPoint) {
        this.addAction(function (callback) {
            var currentPoint = this.finger.getPosition();

            var getMidpoint = function (start, end) {
                return cc.p(
                    (start.x + end.x) / 2.0,
                    (start.y + end.y) / 2.0
                );
            };

            var getPointLiesOnPerpendicularBisector = function (start, end, radius) {
                var midpoint = getMidpoint(start, end);

                var slope = -1.0 / ((end.y - start.y) / (end.x - start.x));

                return cc.p(
                    midpoint.x + Math.cos(Math.atan(slope)) * radius,
                    midpoint.y + Math.sin(Math.atan(slope)) * radius
                );
            };

            var distance = Math.sqrt(Math.pow(endPoint.x - currentPoint.x, 2) + Math.pow(endPoint.y - currentPoint.y, 2));
            var radius1 = distance * 0.618;
            var radius2 = -radius1 * 0.618;

            var m = getMidpoint(currentPoint, endPoint);
            var secondPoint1 = getPointLiesOnPerpendicularBisector(currentPoint, m, radius1);
            var secondPoint2 = getPointLiesOnPerpendicularBisector(m, endPoint, radius2);

            this.finger.runAction(new cc.Sequence(
                new cc.BezierTo(1.3, [currentPoint, secondPoint1, m]).easing(cc.easeSineIn()),
                new cc.BezierTo(0.8, [m, secondPoint2, endPoint]).easing(cc.easeQuinticActionOut()),

                new cc.CallFunc(function () {
                    callback();
                })
            ));
        }.bind(this));
    },

    dislodge: function (delay, deltaPos, callback) {
        this.addAction(function (f) {
            this.finger.runAction(new cc.Sequence(
                new cc.MoveBy(delay, deltaPos).easing(cc.easeBackOut(3)),
                new cc.CallFunc(function () {
                    callback && callback();
                    f();
                })
            ));
        }.bind(this));
    },

    press: function (options) {
        options = options || {};
        options.noCircle = this.noCircle;
        this._super(options);
    },

    turnInto: function (image) {
        if (this.lastImage === image) {
            return;
        }

        this.addAction(function (callback) {
            var scale = this.finger.getScale();
            this.finger.runAction(new cc.Sequence(
                new cc.ScaleTo(0.1, 0.5),
                new cc.CallFunc(function () {
                    if (!this.defaultSpriteFrame) {
                        this.defaultSpriteFrame = this.finger.getSpriteFrame();
                    }

                    if (!image) {
                        this.finger.setSpriteFrame(this.defaultSpriteFrame);
                    } else {
                        this.finger.setSpriteFrame(new cc.Sprite(bundles.ads_sprites.frames[image]).getSpriteFrame());
                    }
                    this.lastImage = image;
                }.bind(this)),
                new cc.ScaleTo(0.2, scale),
                new cc.CallFunc(function () {
                    callback();
                })
            ));
        }.bind(this));
    },

    showAnchorPoint: function () {
        var point = new cc.Sprite(bundles.wysiwyg.frames.pixel_png);
        point.setColor(cc.color(0, 255, 0, 0));
        point.setScale(15);
        this.finger.addChild(point);

        var pointX = this.finger.width * this.finger.anchorX * this.finger.scaleX;
        var pointY = this.finger.height * this.finger.anchorY * this.finger.scaleY;
        point.setPositionRound(pointX, pointY);
    }
});

FingerViewEx.MOVE_SPEED = {
    default: 750,
    board: 1200,
    match3: 900,
    merge: 400,
    tile3: 1200,

    tripeaks: 1000,
    scramble: 2200
};