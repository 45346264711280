/**
 * Created by Andrey Popov on 1/22/21.
 */

var Hierarchy = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.items = [];
    this.selectedItem = undefined;
    this.directories = [];
    this.readyDirectory = "ads";

    this.multiSelection = false;
    this.selectedItems = [];
};

Hierarchy.prototype = Object.create(cleverapps.EventEmitter.prototype);
Hierarchy.prototype.constructor = Hierarchy;

Hierarchy.prototype.createDirectory = function (fromRoot) {
    var newName = (prompt("enter name for the new directory:") || "new_directory");
    if (fromRoot) {
        newName = "wysiwyg/" + newName;
    } else {
        newName = this.wysiwyg.directory + "/" + newName;
    }

    cleverapps.RestClient.post(
        "/wysiwyg/createDirectory",
        {
            directory: newName
        },
        function () {
            this.loadDirectory(newName);
        }.bind(this)
    );
};

Hierarchy.prototype.loadDirectory = function (directory, callback, silent) {
    this.blockSelection(true);
    directory = directory || "wysiwyg/tmp";

    cleverapps.RestClient.get(
        "/wysiwyg/directories?directory=" + directory,
        {}, 
        function (data) {
            this.directories = data.directories;

            var directoryItem = HierarchyItem.createDirectory(directory);

            var items = [directoryItem].concat(data.content.map(function (clipData) {
                var hierarchyItem = new HierarchyItem({
                    type: HierarchyItem.TYPE.CLIP,
                    id: clipData.id,
                    properties: {
                        caption: clipData.caption,
                        mtime: clipData.mtime
                    }
                });
                hierarchyItem.parentItem = directoryItem;

                return hierarchyItem;
            }));

            this.setItems(items, silent);

            cleverapps.wysiwyg.directory = directory;
            cleverapps.setUrlHash({
                directory: directory,
                clipId: undefined
            });

            this.blockSelection(false);

            if (!silent) {
                this.selectItem(directoryItem);
            }

            callback && callback();
        }.bind(this),
        function (err) {
            console.log(err);
            this.blockSelection(false);
        }.bind(this)
    );
};

Hierarchy.prototype.loadClip = function (clipId, callback) {
    this.blockSelection(true);

    if (this.directories.length === 0) {
        this.loadDirectory(cleverapps.wysiwyg.directory, function () {
            this.loadClip(clipId, callback);
        }.bind(this), true);
        return;
    }

    cleverapps.RestClient.get(
        "/wysiwyg/clip?directory=" + cleverapps.wysiwyg.directory + "&clipid=" + clipId,
        {},
        function (clipData) {
            var itemsByIds = {};
            var directoryItem = HierarchyItem.createDirectory(cleverapps.wysiwyg.directory);

            var items = [directoryItem].concat(clipData.map(function (item) {
                var hierarchyItem = new HierarchyItem(item);
                itemsByIds[item.id] = hierarchyItem;
                return hierarchyItem;
            }));

            items.forEach(function (item) {
                if (item.parent) {
                    item.parentItem = itemsByIds[item.parent];
                    delete item.parent;
                }

                if (item.type === HierarchyItem.TYPE.COMPONENT) {
                    var assetComponent = this.wysiwyg.assets.components.filter(function (comp) {
                        return comp.name === item.assetName;
                    })[0];

                    if (assetComponent) {
                        var sceneComponent = new SceneComponent({
                            id: item.id,
                            ctor: assetComponent.ctor,
                            properties: item.properties
                        });
                        this.wysiwyg.wysiwygPreview.registerComponent(sceneComponent);
                    }
                }
            }.bind(this));

            this.setItems(items);
            cleverapps.setUrlHash({
                directory: cleverapps.wysiwyg.directory,
                clipId: clipId
            });

            this.blockSelection(false);
            if (items.length > 1) {
                this.selectItem(items[1]);
            }

            callback && callback();
        }.bind(this),
        function (err) {
            console.log(err);
            this.blockSelection(false);
        }.bind(this)
    );
};

Hierarchy.prototype.getClipContent = function (clip) {
    var content = [clip.toJSON()];
    var parents = [clip.id];
    this.items.map(function (item) {
        return item.toJSON();
    }).forEach(function (item) {
        if (parents.indexOf(item.parent) !== -1) {
            content.push(item);
            parents.push(item.id);
        }
    });

    return cleverapps.clone(content, true);
};

Hierarchy.prototype.save = function (clip, f) {
    if (clip.type === HierarchyItem.TYPE.DIRECTORY) {
        return;
    }

    if (resolutionScale !== 1) {
        alert("check resolutionScale! Current value: " + resolutionScale);
        return;
    }

    var data = {
        directory: this.wysiwyg.directory,
        name: clip.id,
        content: this.getClipContent(clip)
    };

    cleverapps.RestClient.post(
        "/wysiwyg/edit", 
        data, 
        function () {
            this.wysiwyg.wysiwygMenubar.gitButtons.checkStatus();
            f && f();
        }.bind(this),
        function (response) {
            console.log(response.errorMessage || "Failure edit ads");
        }
    );
};

Hierarchy.prototype.moveItemUpOrDown = function (direction) {
    if (!this.selectedItem || !this.selectedItem.parentItem) {
        return;
    }

    var siblings = this.selectedItem.parentItem.children.slice().sort(function (a, b) {
        return this.items.indexOf(a) - this.items.indexOf(b);
    }.bind(this));

    var indexAmongSiblings = siblings.indexOf(this.selectedItem);
    var siblingIndex = indexAmongSiblings + direction;
    var sibling = siblings[siblingIndex];

    if (sibling) {
        this.swapItems(this.selectedItem, sibling);
    }
};

Hierarchy.prototype.makeReady = function () {
    if (!this.selectedItem) {
        return;
    }
    var itemToMove = this.selectedItem;
    var newDirectory = this.readyDirectory ? "wysiwyg/" + this.readyDirectory : "wysiwyg/ads";

    cleverapps.RestClient.post(
        "/wysiwyg/remove",
        {
            directory: this.wysiwyg.directory,
            name: itemToMove.id
        },
        function () {
            this.wysiwyg.directory = newDirectory;
            cleverapps.setUrlHash({
                wysiwyg: true,
                directory: newDirectory,
                clipId: undefined
            });
            this.loadDirectory(newDirectory, function () {
                this.pasteItem(itemToMove);

                this.wysiwyg.wysiwygMenubar.gitButtons.checkStatus();
            }.bind(this));
        }.bind(this),
        function (response) {
            console.log(response.errorMessage || "Failure to make ready");
        }
    );
};

Hierarchy.prototype.swapItems = function (item1, item2) {
    var index1 = this.items.indexOf(item1);
    var index2 = this.items.indexOf(item2);

    if (index2 < index1) {
        var temp = index2;
        index2 = index1;
        index1 = temp;

        temp = item2;
        item2 = item1;
    }

    var itemsToMove = this.items.splice(index2, this.getCountOfChildren(item2) + 1);
    this.items.splice.apply(this.items, [index1, 0].concat(itemsToMove));

    this.save(this.getSelectedClip());

    this.trigger("rebuildItems");
};

Hierarchy.prototype.addItem = function (item, silent) {
    if (item.parentItem) {
        var indexToAddAfter = this.items.indexOf(item.parentItem) + this.getCountOfChildren(item.parentItem);
        this.items.splice(indexToAddAfter + 1, 0, item);
        item.parentItem.children.push(item);
    } else {
        this.items.push(item);
    }

    if (!silent) {
        this.trigger("rebuildItems");

        this.wysiwyg.wysiwygPreview.forceUpdate = true;
        this.selectItem(item);

        this.save(this.getSelectedClip());
    }
};

Hierarchy.prototype.setItems = function (items, silent) {
    this.items = [];
    items.forEach(function (item) {
        this.addItem(item, true);
    }.bind(this));

    if (!silent) {
        this.trigger("rebuildItems");
    }
};

Hierarchy.prototype.removeItem = function (item) {
    var clip = this.getItemClip(item);
    this.items.filter(function (child) {
        return child.parentItem === item;
    }).forEach(function (child) {
        this.removeItem(child);
    }.bind(this));

    if (item.parentItem) {
        var index = item.parentItem.children.indexOf(item);
        if (index > -1) {
            item.parentItem.children.splice(index, 1);
        }
    }

    var toDelete = this.items.indexOf(item);
    if (toDelete !== -1) {
        this.items.splice(toDelete, 1);
        this.trigger("rebuildItems");
    }

    this.wysiwyg.wysiwygPreview.forceUpdate = true;
    if (item.parentItem) {
        this.selectItem(item.parentItem);
    } else if (this.items.length > 0) {
        this.selectItem(this.items[0]);
    }

    if (HierarchyItem.TYPE.CLIP === item.type) {
        cleverapps.RestClient.post(
            "/wysiwyg/remove", 
            {
                directory: this.wysiwyg.directory,
                name: item.id
            }, 
            function () {
                cleverapps.setUrlHash({
                    clipId: undefined
                });
                this.wysiwyg.wysiwygMenubar.gitButtons.checkStatus();
            }.bind(this),
            function (response) {
                console.log(response.errorMessage || "Failure edit ads");
            }
        );
    } else {
        this.save(clip);
    }
};

Hierarchy.prototype.selectItem = function (item) {
    if (this.selectionBlocked) {
        console.log("skip selection due loading");
        return;
    }

    console.log("selectItem:", item);
    this.selectedItem = item;
    this.trigger("selectionChanged", item);

    if (this.multiSelection) {
        this.selectedItems.push(item);
        this.trigger("multipleSelectionChanged", this.selectedItems);
    } else {
        this.selectedItems = [];
    }

    if (cleverapps.config.source !== "playable") {
        cleverapps.setUrlHash({ clipId: this.getItemClip(item) ? this.getItemClip(item).id : undefined });
    }
};

Hierarchy.prototype.changeItemProperty = function (item, property, value) {
    console.log("PROPERTY VALUE CHANGED: " + property + " : " + JSON.stringify(item.properties[property]) + " -> " + JSON.stringify(value));

    item.properties[property] = cleverapps.clone(value, true);
    this.save(this.getItemClip(item));

    if (["caption", "visible", "spine", "image", "sound", "LocalizedText", "gameSpine", "gameImage"].indexOf(property) !== -1) {
        this.trigger("rebuildItems");
    }

    if (item.assetName === "AdsAdHoc") {
        this.items.filter(function (gameItem) {
            return gameItem.assetName === "AdsGame";
        }).forEach(function (gameItem) {
            this.wysiwyg.wysiwygPreview.forceUpdate = true;
            this.trigger("itemPropertyChanged", gameItem);
        }.bind(this));
    } else {
        this.trigger("itemPropertyChanged", item);
    }
};

Hierarchy.prototype.getCountOfChildren = function (item) {
    var result = 0;
    item.children.forEach(function (child) {
        result++;
        result += this.getCountOfChildren(child);
    }.bind(this));
    return result;
};

Hierarchy.prototype.getSelectedClip = function () {
    var selected = this.selectedItem;
    if (!selected) {
        return undefined;
    }
    while (selected.parentItem) {
        selected = selected.parentItem;
    }

    return selected.type === HierarchyItem.TYPE.CLIP ? selected : undefined;
};

Hierarchy.prototype.getItemClip = function (item) {
    while (item && item.type !== HierarchyItem.TYPE.CLIP) {
        item = item.parentItem;
    }
    return item;
};

Hierarchy.prototype.getChildrenByFilter = function (item, filter) {
    var result = [];
    if (filter(item)) {
        result.push(item);
    }

    item.children.forEach(function (child) {
        result = result.concat(this.getChildrenByFilter(child, filter));
    }.bind(this));
    return result;
};

Hierarchy.prototype.getNewClipIndex = function () {
    var clipIndex = 0;
    var nameNoneUnique = true;
    do {
        clipIndex++;
        nameNoneUnique = this.items.filter(function (itemToCheck) {
            return itemToCheck.id === "clip_" + clipIndex;
        }).length > 0;
    } while (nameNoneUnique);
    return clipIndex;
};

Hierarchy.prototype.pasteItem = function (itemToPaste) {
    var newClip = undefined;
    var pasteItemInt = function (item, parent) {
        var newItem = item.clone(parent);
        if (item.type === HierarchyItem.TYPE.CLIP) {
            newClip = newItem;
        }
        this.addItem(newItem, true);
        item.children.forEach(function (child) {
            pasteItemInt(child, newItem);
        });
    }.bind(this);

    if (this.selectedItems.length) {
        this.selectedItems.forEach(function (item) {
            pasteItemInt(itemToPaste, item);
        });
    } else {
        var target = this.selectedItem;
        if (itemToPaste.type === HierarchyItem.TYPE.COMPONENT && itemToPaste.id === target.id) {
            target = target.parentItem;
        }
        pasteItemInt(itemToPaste, target);
    }

    var selectionId = this.selectedItem.id;
    this.save(newClip || this.getSelectedClip(), function () {
        if (newClip) {
            this.loadDirectory(selectionId);
        } else {
            this.loadClip(this.getSelectedClip().id);
        }
    }.bind(this));
};

Hierarchy.prototype.toggleMultiSelection = function () {
    this.multiSelection = !this.multiSelection;
};

Hierarchy.prototype.blockSelection = function (block) {
    this.selectionBlocked = block;
    this.trigger("showLoading", block);
};