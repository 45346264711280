/**
 * Created by andrey on 15.10.19.
 */

cleverapps.WebViewPayments = function () {
    cleverapps.Payments.call(this);

    this.startTime = Date.now();

    for (var j in cleverapps.config.products) {
        var product = cleverapps.config.products[j];
        product.loadedId = [cc.sys.OS_OSX, cc.sys.OS_IOS].includes(cc.sys.os) ? product.id.ios : product.id.android;
    }
};

cleverapps.WebViewPayments.prototype = Object.create(cleverapps.Payments.prototype);
cleverapps.WebViewPayments.prototype.constructor = cleverapps.WebViewPayments;

cleverapps.WebViewPayments.prototype.init = function (callback) {
    this.eventShowListener = cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.checkInitialized.bind(this));
    this.checkInitializedInterval = cleverapps.timeouts.setInterval(this.checkInitialized.bind(this), cleverapps.parseInterval("2 minutes"));
    this.checkInitialized();

    this.onInitializedCallback = callback;
};

cleverapps.WebViewPayments.prototype.stop = function () {
    console.log("WebViewPayments.stop");

    if (this.eventShowListener) {
        cc.eventManager.removeListener(this.eventShowListener);
        delete this.eventShowListener;
    }

    if (this.checkInitializedInterval) {
        cleverapps.timeouts.clearInterval(this.checkInitializedInterval);
        delete this.checkInitializedInterval;
    }

    this.stopped = true;
};

cleverapps.WebViewPayments.prototype.checkInitialized = cleverapps.timeredThrottle(1000, function () {
    var initialized = this.connected && this.refreshed;
    if (initialized && this.onInitializedCallback) {
        this.onInitializedCallback();
        delete this.onInitializedCallback;
    }
    this.setInitialized(initialized);

    if (this.stopped) {
        return;
    }

    if (!this.connected) {
        this.connect();
    } else if (!this.refreshed) {
        this.refresh("consumable");
    } else if (!this.refreshedSubscriptions) {
        this.refresh("subscription");
    } else if (!this.refreshedSubscriptionsTokens) {
        this.refreshSubscriptionsTokens();
    } else if (this.refreshReceipt && !this.refreshedReceipt) {
        this.refreshReceipt();
    }
});

cleverapps.WebViewPayments.prototype.refreshSubscriptionsTokens = function () {
    if (typeof PurchasesPlugin.refreshSubscriptionsTokens === "undefined" || !cleverapps.subscription) {
        this.onRefreshSubscriptionsTokensResult("success", "[]");
        return;
    }

    PurchasesPlugin.refreshSubscriptionsTokens();
};

cleverapps.WebViewPayments.prototype.onRefreshSubscriptionsTokensResult = function (code, purchases, error) {
    purchases = decodeWebViewData(purchases);
    error = decodeWebViewData(error);

    console.log("WebViewPayments.onRefreshSubscriptionsTokensResult - " + code);

    if (error) {
        console.log("WebViewPayments.refreshSubscriptionsTokens error - " + error);
        return;
    }

    if (purchases && code === "success") {
        console.log("WebViewPayments.onRefreshSubscriptionsTokensResult purchases - " + purchases);
        purchases = JSON.parse(purchases);

        this.addSubscriptionTokens(purchases);
    }

    if (code === "success") {
        this.refreshedSubscriptionsTokens = true;
        this.checkInitialized();
    }
};

cleverapps.WebViewPayments.prototype.addSubscriptionTokens = function (products, restore) {
    if (!Array.isArray(products)) {
        products = [products];
    }

    if (!cleverapps.platform.oneOf(AndroidPlatform, IOS, MacOS) || !cleverapps.subscription) {
        return;
    }

    var tokens = products.map(function (product) {
        var ourProduct = Product.Create(product.name);
        if (ourProduct && ourProduct.type === "subscription") {
            var token = {
                name: product.name
            };

            if (cleverapps.platform.oneOf(AndroidPlatform)) {
                token.purchaseToken = product.purchaseToken || product.receipt.purchaseToken;
                token.packageName = product.packageName || product.receipt.packageName;
                token.productId = product.productId || product.receipt.productId;
            } else {
                token.purchaseToken = product.receipt;
                token.packageName = product.packageName;
                token.productId = product.productId;
            }

            return token;
        }
        return undefined;
    }).filter(function (token) {
        return token;
    });

    if (tokens.length > 0) {
        cleverapps.subscription.addPurchaseTokens(tokens, restore);
    }
};

cleverapps.WebViewPayments.prototype.onPurchaseResult = function (code, product, error) {
    product = decodeWebViewData(product);
    error = decodeWebViewData(error);

    console.log("WebViewPayments.onPurchaseResult code - " + code);

    var ourProduct;

    if (product) {
        product = JSON.parse(product);
        ourProduct = Product.Create(product.name);
        console.log("WebViewPayments.onPurchaseResult orderId - " + product.orderId);
        console.log("WebViewPayments.onPurchaseResult product - " + JSON.stringify(product));
    }

    if (error) {
        console.log("WebViewPayments.onPurchaseResult error - " + error);
    }

    switch (code) {
        case "success":
            this.addSubscriptionTokens(product);
            product.itemId = ourProduct && ourProduct.itemId;
            code = cleverapps.Social.CODE_SUCCEED;
            break;
        case "pending":
            code = Product.PENDING_PURCHASE;
            product.pending = true;
            this.validate(product);
            break;
        case "cancel":
            code = cleverapps.Social.CODE_CANCELLED;
            break;
        case "failure":
            code = cleverapps.Social.CODE_FAILED;
            break;
    }

    if (product) {
        product.paymentId = product.orderId || product.receipt.orderId;
        if (cleverapps.platform.oneOf(MacOS)) {
            product.transactionId = cleverapps.user.id + "_" + Date.now();
        }

        product.loadedPriceAmount = ourProduct ? ourProduct.price : 0;
        product.loadedCurrencyCode = "USD";

        if (product.price_amount_micros && product.price_currency_code) {
            product.loadedPriceAmount = Math.round(product.price_amount_micros / 1000) / 1000;
            product.loadedCurrencyCode = product.price_currency_code;
        } else if (ourProduct && ourProduct.loadedPriceAmount && ourProduct.loadedCurrencyCode) {
            product.loadedPriceAmount = ourProduct.loadedPriceAmount;
            product.loadedCurrencyCode = ourProduct.loadedCurrencyCode;
        }
    }

    cleverapps.Payments.prototype.onPurchaseResult.call(this, code, product, error);

    if (["item already owned"].includes(error)) {
        this.restoreThrottled();
    }
};

cleverapps.WebViewPayments.prototype.connect = function () {
    if (this.connectRunning || !PurchasesPlugin.connect || this.connected) {
        return;
    }
    this.connectRunning = true;
    this.connectTimeout = cleverapps.timeouts.setTimeout(function () {
        this.connectRunning = false;
    }.bind(this), 60000);

    if (cleverapps.platform.oneOf(AndroidPlatform)) {
        cleverapps.platform.callNative("payments.connect", {}, function (code, options) {
            this.onConnectResult(code === 0, options.error);
        }.bind(this));
    } else {
        PurchasesPlugin.connect();
    }
};

cleverapps.WebViewPayments.prototype.onConnectResult = function (connected, error) {
    this.connectRunning = false;
    cleverapps.timeouts.clearTimeout(this.connectTimeout);

    console.log("WebViewPayments.onConnectResult - " + connected);

    if (error) {
        console.log("WebViewPayments.onConnectResult error - " + decodeWebViewData(error));
    }

    this.connected = connected;
    this.checkInitialized();

    cleverapps.flags.videoAdsMainMonetization = false;
    cleverapps.flags.update();
};

cleverapps.WebViewPayments.prototype.refresh = function (type) {
    if (this.refreshRunning || !PurchasesPlugin.refresh
        || (type === "consumable" && this.refreshed || type === "subscription" && this.refreshedSubscriptions)) {
        return;
    }
    this.refreshRunning = true;
    this.refreshTimeout = cleverapps.timeouts.setTimeout(function () {
        this.refreshRunning = false;
    }.bind(this), 60000);

    PurchasesPlugin.refresh(type);
};

cleverapps.WebViewPayments.prototype.onRefreshResult = function (code, type, products, error, errorMessage) {
    this.refreshRunning = false;
    cleverapps.timeouts.clearTimeout(this.refreshTimeout);

    console.log("WebViewPayments.onRefreshResult code - " + code + " type - " + type);

    products = decodeWebViewData(products);
    error = decodeWebViewData(error);
    errorMessage = decodeWebViewData(errorMessage);

    if (error || code === "failure") {
        console.log("WebViewPayments.onRefreshResult error - " + error + " message - " + errorMessage);
        return;
    }

    if (products) {
        products = JSON.parse(products);
        console.log("WebViewPayments.onRefreshResult products - " + JSON.stringify(products));
    }

    var loadedCurrencyCode;

    for (var i = 0; i < products.length; i++) {
        var ourProduct = cleverapps.config.products[Product.SearchProductId(products[i].name)];
        var currency = products[i].price_currency_code;
        loadedCurrencyCode = loadedCurrencyCode || currency;

        if (ourProduct !== undefined) {
            ourProduct.loadedPrice = products[i].price;
            ourProduct.loadedCurrencyCode = currency;
            ourProduct.loadedPriceAmount = Math.round(products[i].price_amount_micros / 1000) / 1000;

            console.log("WebViewPayments product " + products[i].productId + " " + ourProduct.loadedPrice + " " + ourProduct.loadedCurrencyCode + " " + ourProduct.loadedPriceAmount);
        }
    }
    if (type === "consumable") {
        this.refreshed = true;
    } else if (type === "subscription") {
        this.refreshedSubscriptions = true;
    }

    if (loadedCurrencyCode && !this.loadedCurrencyCode) {
        this.loadedCurrencyCode = loadedCurrencyCode;
        cleverapps.country.setCurrency(loadedCurrencyCode);

        if (YooKassaPayments.isAppropriate()) {
            console.log("WebViewPayments switch to YooKassaPayments");
            cleverapps.PaymentsManager.switch();
            return;
        }
    }

    cleverapps.flags.videoAdsMainMonetization = false;
    cleverapps.flags.update();

    this.checkInitialized();
};

cleverapps.WebViewPayments.prototype._purchase = function (product, callback) {
    if (!this.initialized) {
        console.log("WebViewPayments.open not initialized error");
        callback(cleverapps.Social.CODE_FAILED);
        return;
    }

    if (!this.refreshed || product.type === "subscription" && !this.refreshedSubscriptions) {
        console.log("WebViewPayments.open not refreshed error");
        callback(cleverapps.Social.CODE_FAILED);
        return;
    }

    var name = ([cc.sys.OS_OSX, cc.sys.OS_IOS].indexOf(cc.sys.os) !== -1) ? product.id.ios : product.id.android;

    if (!PurchasesPlugin.hasProductByName(name)) {
        cleverapps.throwAsync("Product '" + name + "' not configured correctly (please check sdkbox_config.json)");
    }

    var ourProduct = Product.Create(name);
    if (ourProduct !== undefined && !ourProduct.loadedPrice) {
        cleverapps.throwAsync("Product '" + name + "' has not loaded price");
    }

    PurchasesPlugin.purchase(name);
};

cleverapps.WebViewPayments.prototype.isAvailable = function () {
    var isBlocked = cleverapps.platform.oneOf(AndroidPlatform) && ["RUB"].includes(this.loadedCurrencyCode)
        && !cleverapps.WebViewPayments.DEBUG_IDFA.includes(cleverapps.user.idfa);

    var result = this.connected && this.refreshed && !isBlocked;

    console.log("WebViewPayments.isAvailable - " + JSON.stringify({
        result: result,
        isBlocked: isBlocked,
        connected: this.connected,
        refreshed: this.refreshed
    }));

    return result;
};

cleverapps.WebViewPayments.prototype.validate = function (product, onSuccess, onFailure) {
    onSuccess = onSuccess || function () {
    };

    if (!cleverapps.platform.oneOf(AndroidPlatform, IOS, MacOS, GPG)) {
        onSuccess();
        return;
    }

    var data = {
        userid: cleverapps.user.id,
        restore: product.restore,
        itemId: product.itemId,
        pending: product.pending
    };

    if (cleverapps.platform.oneOf(IOS, MacOS)) {
        data.receipt = product.receipt;
        data.productId = product.productId;
        data.packageName = product.packageName;
        data.platform = "apple";
    } else {
        data.receipt = product.receipt.purchaseToken;
        data.packageName = product.receipt.packageName;
        data.productId = product.receipt.productId;
        data.platform = "google";
        data.purchaseState = product.purchaseState;
    }

    product.paymentId = product.paymentId || product.orderId;

    data.errorInJson = true;

    var ourProduct = Product.Create(product.name);
    data.type = ourProduct && ourProduct.type;

    // console.log("WebViewPayments.validate - " + JSON.stringify(data));
    cleverapps.RestClient.post("/purchasevalidator/validate/", data, function (response) {
        if (response.error) {
            console.log("WebViewPayments.validate Failure: " + response.error);
            onFailure && onFailure();
            return;
        }

        product.paymentId = product.paymentId
            || response.receipt && response.receipt.orderId
            || response.transaction_id || response.transactionId;
        console.log("WebViewPayments.validate Success: " + JSON.stringify(response));
        onSuccess && onSuccess();
    });
};

cleverapps.WebViewPayments.prototype.calcLoadedPrice = function (price) {
    var exchangeRate = cleverapps.EXCHANGE_RATES[this.loadedCurrencyCode];
    if (exchangeRate) {
        var sign = Product.CURRENCY_CODE === this.loadedCurrencyCode ? Product.CURRENCY : this.loadedCurrencyCode;
        return Math.ceil(price * exchangeRate) + ".00 " + sign;
    }
    return Product.CURRENCY + " " + price;
};

cleverapps.WebViewPayments.isAppropriate = function () {
    return cc.sys.isNative && typeof PurchasesPlugin !== "undefined";
};

cleverapps.WebViewPayments.prototype.onRestoreResult = function (json) {
    json = decodeWebViewData(json);
    console.log("WebViewPayments.onRestoreResult - " + json);

    if (!json) {
        return;
    }

    var purchase = JSON.parse(json);
    var ourProduct = Product.Create(purchase && purchase.name);

    var orderId = purchase && purchase.orderId;
    if (!purchase || !purchase.name || !orderId || !ourProduct) {
        return;
    }

    this.restorePurchase = purchase;
    this.restorePurchase.restore = true;
    this.restoreProduct = ourProduct;
};

cleverapps.WebViewPayments.prototype._restore = function () {
    if (PurchasesPlugin.restore === undefined || !cleverapps.platform.oneOf(AndroidPlatform, GPG, IOS, MacOS)) {
        return;
    }

    PurchasesPlugin.restore();
};

cleverapps.WebViewPayments.prototype.consumePurchase = function (purchase) {
    if (!PurchasesPlugin.consumePurchase || !purchase || !purchase.orderId) {
        return;
    }

    PurchasesPlugin.consumePurchase(purchase.orderId);
    this.setConsumed(purchase.orderId);
};

cleverapps.WebViewPayments.DEBUG_IDFA = [
    "45f0caa5-d1ab-4b03-8e09-a0b63b17c181", // andrey k m31s
    "839d10f8-0da0-40fa-a4e9-02885ff0973a", // andrey k s21
    "17ef0ac8-71fa-47d1-941b-70924e8a031e", // anna
    "217ac5d3-6b67-48e8-8fe8-0fff1108c808", // alex_read
    "75f21832-7a24-47cc-9e88-8c4f461fc06d", // dmitriy
    "aca92164-3f00-4115-84c3-2ab54ed7ba7b", // samsung tab a android 9
    "1572cd26-1b38-4b85-8346-258e171b388c", // spepa
    "b1ae8117-ff55-4159-b9ad-18e4d05a9da8", // michail
    "cb3ed72f-c604-4c51-84fa-2ffaa9acb849", // vlad
    "85c137ac-0a55-45cd-bd8a-b8be552beea8", // bogdan
    "d2d82613-36c4-4d5c-aef5-43e62e938a48", // artem
    "2105ba80-4607-4ae3-a3a8-866aa7fb63b9", // denis
    "24280e2d-6603-415e-a064-d794cbda6437" // anatoly
];