/**
 * Created by vladislav on 13.07.20.
 */

var TileShuffleBooster = function (id) {
    Booster.call(this, id);
};

TileShuffleBooster.prototype = Object.create(Booster.prototype);
TileShuffleBooster.prototype.constructor = TileShuffleBooster;

TileShuffleBooster.prototype.onExecute = function () {
    Game.currentGame.table.shuffle();
};
