/**
 * Created by slava on 23/3/20
 */

var BaseButtonBooster = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.booster = options.booster;

        this.styles = options.styles;

        this.content = this.getContent();
        this.onChangeAmount();

        var buttonOptions = {
            onClicked: this.onPressed.bind(this),
            content: this.content,
            spine: options.spine
        };

        cleverapps.overrideStyles(buttonOptions, this.getSize());

        this._super(buttonOptions);

        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);

        this.updateSize();
        this.updatePosition();

        if (options.hideDirection !== undefined) {
            cleverapps.UI.makeHideable(this, options.hideDirection);
        }

        this.createArrow();

        this.booster.on("changeAmount", this.createListener(this.onChangeAmount.bind(this)), this);

        this.booster.onToggleArrowListener = this.createListener(this.toggleArrow.bind(this));
        this.booster.onToggleTintListener = this.createListener(this.toggleTint.bind(this));
        this.booster.onForceListener = this.createListener(this.showForce.bind(this));
        this.booster.onExecuteListener = this.createListener(this.onExecute.bind(this));
        this.booster.onEnableListener = this.createListener(this.enable.bind(this));
        this.booster.onDisableListener = this.createListener(this.disable.bind(this));

        cleverapps.aims.registerTarget("boosters" + this.booster.id, this, {
            controls: this.getControlId(),
            flyingUnderShadow: true
        });
    },

    onChangeAmount: function () {
        if (this.booster.getAmount() > 0) {
            this.amount.setVisible(true);
            this.setAmount(this.booster.getAmount());
            this.price.setVisible(false);
        } else {
            this.price.setVisible(true);
            this.amount.setVisible(false);
        }
    },

    setAmount: function (amount) {
        this.amountText.setString("x" + amount);
    },

    updatePosition: function () {
        if (this.styles.position) {
            var y = this.styles.position.y[cleverapps.wideMode.mode];
            if (this.styles.useSceneDelta) {
                y = cleverapps.clone(y);
                y.dy -= cleverapps.UI.getSceneOffsetY();
            }
            this.setPositionRound(this.styles.position.x[cleverapps.wideMode.mode], y);
        }
        this.content.setPositionRound(this.width / 2, this.height / 2);

        this.price.setPositionRound(this.styles.content.layout);
        this.amount.setPositionRound(this.styles.content.layout);
        this.freeBlock && this.freeBlock.setPosition(this.getFreeBlockStyles().position);
    },

    updateSize: function () {
        this.baseScale = (cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL) ? this.styles.phone.scale : 1;
        this.setScale(this.baseScale);
        var size = this.getSize();
        this.resize(size.width, size.height);
    },

    getSize: function () {
        return {
            width: this.styles.width,
            height: this.styles.height
        };
    },

    toggleArrow: function (on) {
        if (on) {
            this.addChild(this.arrow);
        } else {
            this.arrow.removeFromParent();
        }

        if (this.icon instanceof cleverapps.Spine) {
            this.icon.setAnimation(0, on ? "animation" : "idle", true);
        }
    },

    toggleTint: function (on) {
        this.hintOn = on;
        this.runTintAnimation();
    },

    getForceActions: function () {
        return [
            function (f) {
                this.switchToFreePrice();
                setTimeout(f, 700);
            }.bind(this),
            function (f) {
                cleverapps.meta.showControlsWhileFocused(this.getControlId(), true);

                cleverapps.forces.create(this, this.booster.force);
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        ];
    },

    getControlId: function () {
        return "hint_" + this.booster.id; 
    },

    showForce: function () {
        if (cleverapps.forces.isAvailable(this, this.booster.force)) {
            var actions = this.getForceActions();
            this.baseZOrder = this.getLocalZOrder();
            this.setLocalZOrder(0);

            cleverapps.meta.display({
                focus: "ButtonBoosterForce",
                control: [this.getControlId(), "opencards"],
                actions: actions
            });

            return true;
        }
        return false;
    },

    createArrow: function () {
        this.arrow = new PointerView({
            target: this
        });
    },

    runTintAnimation: function () {
        if (this.tintRunning || !this.hintOn) {
            return;
        }

        this.tintRunning = true;
        var white = cleverapps.styles.COLORS.WHITE;
        var color = cleverapps.styles.COLORS.HINT_ALARM_COLOR || cleverapps.styles.COLORS.PLAYER_ALARM_COLOR;

        this.background.runAction(new cc.Sequence(
            new cc.TintTo(0.7, color.r, color.g, color.b),
            new cc.TintTo(0.7, white.r, white.g, white.b),
            new cc.CallFunc(function () {
                this.tintRunning = false;
                this.runTintAnimation();
            }.bind(this))
        ));
    },

    createIcon: function () {
        if (bundles.game.jsons.hint_icon_json) {
            var animation = new cleverapps.Spine(bundles.game.jsons.hint_icon_json);
            animation.setAnimation(0, "idle", true);
            return animation;
        }
        return new cc.Sprite(bundles.game.frames.hint_icon_png);
    },

    getLayoutOptions: function () {
        return {
            direction: this.styles.content.direction,
            margin: this.styles.content.margin
        };
    },

    getContent: function () {
        var content = new cc.Node();
        content.setAnchorPoint2();

        var options = this.getLayoutOptions();

        var priceIcon = this.priceIcon = this.createIcon();

        var priceText = this.priceText = this.getPriceText();
        priceText.setCascadeOpacityEnabled(true);
        var price = this.price = new cleverapps.Layout([priceIcon, priceText], options);
        price.setAnchorPoint(0.5, 0.5);

        var amountIcon = this.amountIcon = this.createIcon();
        var amountText = this.amountText = cleverapps.UI.generateImageText("x" + this.booster.getAmount(), cleverapps.styles.FONTS.AMOUNT_BOOSTER);
        var amount = this.amount = new cleverapps.Layout([amountIcon, amountText], options);
        amount.setAnchorPoint(0.5, 0.5);

        content.addChild(price);
        content.addChild(amount);

        return content;
    },

    getPriceText: function () {
        var price = this.booster.getPrice();

        var text = price.soft && "@@" + price.amount || "$$" + price.amount;

        return new TextWithIcon(text, {
            font: cleverapps.styles.FONTS.BUTTON_BOOSTER
        });
    },

    switchToFreePrice: function () {
        this.freeBlock = new cc.Node();
        this.freeBlock.setAnchorPoint2();
        this.freeBlock.setCascadeOpacityEnabled(true);
        var styles = this.getFreeBlockStyles();
        this.freeBlock.setPosition(styles.position);
        this.freeBlock.setOpacity(0);

        var free = cleverapps.UI.generateTTFText("FREE", cleverapps.styles.FONTS.BUTTON_BOOSTER);
        free.fitTo(styles.textWidth);
        this.freeBlock.addChild(free);

        if (styles.background) {
            var bg = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
            bg.setContentSize2(free.width + 2 * styles.background.padding.x, free.height + 2 * styles.background.padding.y);
            this.freeBlock.addChild(bg);
            bg.setPositionRound(styles.background.dx, styles.background.dy);
            bg.setLocalZOrder(-1);
        }
        this.freeBlock.setContentSize(this.content.getContentSize());
        this.content.addChild(this.freeBlock);
        var currentText = this.price.isVisible() ? this.priceText : this.amountText;
        currentText.runAction(new cc.FadeOut(0.3));
        this.freeBlock.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.FadeIn(0.3)
        ));
    },

    getFreeBlockStyles: function () {
        var styles = this.styles.content.freeBlock;
        var width = styles.textWidth;
        var pos = this.content.convertToNodeSpace(this.priceText.parent.convertToWorldSpace(this.priceText.getPosition()));
        pos.x += styles.dx;
        return {
            textWidth: width,
            position: pos,
            background: styles.textBg
        };
    },

    getCurrentText: function () {
        return this.booster.getAmount() > 0 ? this.amountText : this.priceText;
    },

    switchToRegularPrice: function () {
        this.freeBlock.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                this.getCurrentText().runAction(new cc.FadeOut(0));
            }.bind(this)),
            new cc.DelayTime(0.5),
            new cc.FadeOut(0.3),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                delete this.freeBlock;
            }.bind(this)),
            new cc.CallFunc(function () {
                this.getCurrentText().runAction(new cc.FadeIn(0.3));
            }.bind(this))
        ));
    },

    onPressed: function () {
        var isRunningForce = cleverapps.forces.isRunningForce(this.booster.force);
        if (isRunningForce) {
            this.switchToRegularPrice();

            if (this.baseZOrder) {
                this.setLocalZOrder(this.baseZOrder);
            }

            cleverapps.forces.closeRunningForce();
        }

        cleverapps.meta.display({
            focus: "ExecuteButtonBooster",
            action: function (f) {
                this.booster.onPressed(f, isRunningForce);
            }.bind(this)
        });
    },

    onExecute: function () {
        if (this.icon instanceof cleverapps.Spine && cleverapps.config.name === "crocword") {
            this.icon.setAnimationAndIdleAfter("hint", "idle");
        }

        cleverapps.audio.playSound(this.booster.executeEffect);
    },

    getDebugId: function () {
        return this.booster.id;
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BUTTON_BOOSTER: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    AMOUNT_BOOSTER: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.ButtonBooster = {
    width: 150,
    height: 130,
    zOrder: 0,

    useSceneDelta: true,

    position: {
        x: [{ align: "center", dx: 0 },
            { align: "right", dx: -10 },
            { align: "right", dx: -10 }
        ],
        y: [{ align: "bottom", dy: 200 },
            { align: "bottom", dy: 360 },
            { align: "bottom", dy: 360 }
        ]
    },

    phone: {
        scale: 0.8
    },

    content: {
        margin: 20,
        direction: cleverapps.UI.HORIZONTAL,
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        freeBlock: {
            textWidth: 120,
            dx: 0
        }

    }
};