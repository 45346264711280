/**
 * Created by vladislav on 23.08.2019
 */

var CoinsProductTile = BaseProductTile.extend({
    ctor: function (tileModel, options) {
        var styles = cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, cleverapps.styles.CoinsProductTile, true);

        this._super(tileModel, styles, options);
    },

    updateAvailability: function () {
        this._super();

        if (this.description) {
            this.description.setBackgroundImage(this.getDescriptionBg());
        }
    },

    getDescriptionBg: function () {
        var image = bundles.tile_shop.frames.description_bg || bundles.tile_shop.frames.shop_title;

        if (!this.tileModel.isAvailable()) {
            image = bundles.tile_shop.frames.description_bg_off || bundles.tile_shop.frames.shop_title_off;
        }

        return image;
    },

    createDescription: function () {
        var font =  cleverapps.styles.FONTS.TILE_SHOP_COINS_OFFER;
        return new TileRewardsBlock(this.tileModel, {
            bgImage: this.getDescriptionBg(),
            bgSize: this.styles.rewardsBlock,
            iconDimensions: this.styles.iconDimensions,
            font: font,
            paddingX: this.styles.description.paddingX
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PRODUCT_TILE_LIMIT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.CoinsProductTile = {
    title: false
};
