/**
 * Created by slava on 4/21/17.
 */

cleverapps.MobileVkPayments = function () {
    cleverapps.VKPayments.call(this);
};

cleverapps.MobileVkPayments.prototype = Object.create(cleverapps.VKPayments.prototype);
cleverapps.MobileVkPayments.prototype.constructor = cleverapps.MobileVkPayments;

cleverapps.MobileVkPayments.prototype.init = function (callback) {
    callback();
};

cleverapps.MobileVkPayments.prototype.isAvailable = function () {
    return cleverapps.MobileVkSocial.getPlatfrom() !== "html5_ios";
};

cleverapps.MobileVkPayments.isAppropriate = function () {
    return cleverapps.platform instanceof MobileVk;
};
