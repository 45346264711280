/**
 * Created by vladislav on 19.02.2021
 */

var AdsExclamations = cc.Node.extend({
    ctor: function (properties, eventBus) {
        this._super();

        this.properties = properties;

        this.setAnchorPoint2();

        this.isMuted = this.properties.isPreview;
        this.messageId = 0;
        this.messageNo = 0;

        this.setContentSize2(cleverapps.styles.AdsExclamations);

        if (properties.isPreview) {
            this.runAnimation(true);
        }

        eventBus.on(Wysiwyg.EVENTS.PROGRESS_CHANGED, this.incProgress.bind(this), this);
    },

    incProgress: function (progress) {
        if (progress === 1) {
            return;
        }

        if (this.messageNo % 2 === 0) {
            setTimeout(this.runAnimation.bind(this), 0);
        }

        this.messageNo++;
    },

    runAnimation: function (looped) {
        var animation = new cleverapps.Spine(bundles.ads_exclamations.jsons["exclamation_" + this.messageId]);
        this.addChild(animation);
        animation.setAnimation(0, "animation_" + cleverapps.settings.language, false);
        animation.setPositionRound(this.width / 2, this.height / 2);
        animation.setCompleteListenerRemove(function () {
            if (looped) {
                this.runAnimation(looped);
            }
        }.bind(this));

        if (!this.isMuted) {
            cleverapps.audio.playSound(bundles.ads_exclamations.urls["exclamation_effect_" + this.messageId]);
        }

        this.messageId++;
        if (!bundles.ads_exclamations.jsons["exclamation_" + this.messageId]) {
            this.messageId = 0;
        }
    }
});

cleverapps.styles.AdsExclamations = {
    width: 400,
    height: 100
};

AdsExclamations.BUNDLES = ["ads_exclamations"];