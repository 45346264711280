/**
 * Created by spepa on 23.08.2022
 */

cleverapps.MygamesSocial = function () {
    console.log("Choosing mygames social");

    cleverapps.Social.call(this);
};

cleverapps.MygamesSocial.prototype = Object.create(cleverapps.Social.prototype);
cleverapps.MygamesSocial.prototype.constructor = cleverapps.MygamesSocial;

cleverapps.MygamesSocial.prototype.init = function (callback) {
    var params = new URLSearchParams(window.location.search);

    var ourParams = new URLSearchParams();
    ourParams.set("uid", "" + params.get("sz_pers_id"));
    ourParams.set("otp", "" + params.get("sz_token"));

    cleverapps.RestClient.get("/mygames/auth?" + ourParams.toString(), {}, function (res) {
        if (res.error) {
            console.log("Auth error", res);
        } else {
            this.user = cleverapps.MygamesSocial.formatUserData(res);
            callback();
        }
    }.bind(this), function (err) {
        console.log("Auth error", err);
    });
};

cleverapps.MygamesSocial.prototype.isLoggedIn = function () {
    return this.user !== undefined;
};

cleverapps.MygamesSocial.prototype.getAccessToken = function () {
    return "mygames";
};

cleverapps.MygamesSocial.prototype.getUserID = function () {
    return this.user && this.user.id;
};

cleverapps.MygamesSocial.prototype.shareDialog = function (data, callback) {
    var postData = {
        uid: cleverapps.user.id,
        title: data.name,
        img: data.picture
    };

    cleverapps.RestClient.post("/mygames/share", postData, function (res) {
        if (res.status === "ok") {
            callback(cleverapps.Social.CODE_SUCCEED);
        } else {
            callback(cleverapps.Social.CODE_FAILED);
        }
    }, function () {
        callback(cleverapps.Social.CODE_FAILED);
    });
};

cleverapps.MygamesSocial.prototype.aboutMe = function (callback) {
    if (this.user) {
        callback(this.user);
    }
};

cleverapps.MygamesSocial.prototype.getSuggestedLanguage = function () {
    var locale = (new URLSearchParams(window.location.search).get("ui_locale"));
    switch (locale) {
        case "ru-RU":
            return cc.sys.LANGUAGE_RUSSIAN;
        case "en-US":
            return cc.sys.LANGUAGE_ENGLISH;
        case "de-DE":
            return cc.sys.LANGUAGE_DUTCH;
        case "es-ES":
            return cc.sys.LANGUAGE_SPANISH;
        case "fr-FR":
            return cc.sys.LANGUAGE_FRENCH;
        case "pl-PL":
            return cc.sys.LANGUAGE_POLISH;
        case "tr-TR":
            return cc.sys.LANGUAGE_TURKISH;
        case "ar-AA":
            return cc.sys.LANGUAGE_ARABIC;
    }
};

cleverapps.MygamesSocial.formatUserData = function (data) {
    return {
        id: "" + data.uid,
        name: data.nick,
        first_name: data.nick,
        slug: data.slug,
        picture: {
            data: {
                url: data.avatar
            }
        }
    };
};

cc.sys.LANGUAGE_TURKISH = "tr";