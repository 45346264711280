/**
 * Created by Andrey Popov on 10.11.2021
 */

var AnalyzerMenubarView = cc.Node.extend({
    ctor: function (analyzerMenubar) {
        this._super();

        this.analyzerMenubar = analyzerMenubar;

        var styles = cleverapps.styles.AnalyzerMenubarView;

        this.setContentSize2(cleverapps.UI.getWinSize(true).width, styles.height);
        this.setAnchorPoint2();

        this.analyzerMenubar.on("updateTool", this.updateTool.bind(this), this);
    },

    updateTool: function (caption, visible) {
    },

    showTools: function (tools) {
        var styles = cleverapps.styles.AnalyzerMenubarView;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ANALYZER_STATUS_TEXT: {
        size: 12,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.styles.AnalyzerMenubarView = {
    height: 74,

    button: {
        width: 60,
        height: 60,
        icon: {
            width: 40,
            height: 40
        }
    },

    leftButtons: {
        x: { align: "left", dx: 60 },
        y: { align: "center" }
    },

    margin: 10
};