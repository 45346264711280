/**
 * Created by vladislav on 19.05.20.
 */

var HomeTool = {
    setInt: function(furnitureId, stage, skipLast, callback) {
        var home = cleverapps.home;

        while (!home.isCompleted() && (home.currentFurnitureId !== furnitureId || home.getMaxFurniture().stage !== stage)) {
            var currentFurniture = home.getMaxFurniture();
            while (!currentFurniture.canBeUpgraded() && !levels.user.isPassedAll()) {
                var episode = new Episode(levels.user.episode);
                var level = episode.getLevel(levels.user.level);
                cleverapps.home.earnStars(cleverapps.home.amountLevelStars(level));
                levels.user.incLevel();
            }

            var nextProgress = this.calcNextProgress(currentFurniture);
            if (skipLast && nextProgress.furnitureId === furnitureId && nextProgress.stage === stage) {
                break;
            } else {
                this.moveNext();
            }
        }

        callback();
    },

    set: function (furnitureId, stage, skipLast, callback) {
        var home = cleverapps.home;
        this.reset();

        this.setInt(furnitureId, stage, skipLast, function() {
            home.save();
            home.tasks.reset();
            cleverapps.user.save();

            callback();
        });
    },

    calcNextProgress: function (furniture) {
        if (furniture.stage + 1 === furniture.stages.length) {
            return {
                furnitureId: furniture.id + 1,
                stage: 0
            };
        }

        return {
            furnitureId: furniture.id,
            stage: furniture.stage + 1
        }
    },

    moveNext: function () {
        var home = cleverapps.home;
        cleverapps.home.stars = 0;
        var furniture = home.getMaxFurniture();
        if (!furniture.nextStage()) {
            furniture.choices && (furniture.choice = 0);
            home.currentFurnitureId  = home.getNextId();
        }
    },

    reset: function () {
        var home = cleverapps.home;

        home.furniture.forEach(function (furniture) {
            furniture.stage = 0;
            furniture.choice = undefined;
        });

        home.stars = 0;
        home.currentFurnitureId = home.firstFurnitureId();

        cleverapps.user.level = 0;
        cleverapps.user.episode = 0;
    }
};