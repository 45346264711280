/**
 * Created by andrey on 15.05.2023
 */

var YooKassaWebPayments = function () {
    YooKassaPayments.call(this);
};

YooKassaWebPayments.prototype = Object.create(YooKassaPayments.prototype);
YooKassaWebPayments.prototype.constructor = YooKassaWebPayments;

YooKassaWebPayments.prototype.closeWidget = function () {
    if (this.widget) {
        this.widget.close();
        delete this.widget;
    }
};

YooKassaWebPayments.prototype.openWidget = function (product) {
    this.closeWidget();

    var widgetUrl = this.getWidgetUrl(product);
    this.widget = window.open(widgetUrl);

    this.startWidgetChecker();
};

YooKassaWebPayments.prototype.stopWidgetChecker = function () {
    clearInterval(this.widgetChecker);
    delete this.widgetChecker;
};

YooKassaWebPayments.prototype.startWidgetChecker = function () {
    this.stopWidgetChecker();

    if (cleverapps.config.debugMode && cleverapps.Tool.restoreCheck || !this.purchaseHandler) {
        return;
    }

    var onWidgetProcessed = function () {
        this.closeWidget();
        this.stopWidgetChecker();

        if (this.purchaseProduct) {
            this.startStatusChecker();
        }
    }.bind(this);

    this.widgetChecker = setInterval(function () {
        try {
            var widget = this.widget;
            if (widget && widget.location && !widget.location.href) {
                onWidgetProcessed();
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }.bind(this), 500);
};

YooKassaWebPayments.prototype.onPurchaseResult = function () {
    YooKassaPayments.prototype.onPurchaseResult.apply(this, arguments);

    this.stopWidgetChecker();
};

YooKassaWebPayments.isAppropriate = function () {
    return false;
    // return cleverapps.isLocalhost();
};
