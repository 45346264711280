/**
 * Created by Andrey Popov on 19.09.2022
 */

var RecordingOverrides = {
    merge: {
        merge: {
            in: function () {
                if (!Merge.prototype.__merge) {
                    Merge.prototype.__merge = Merge.prototype.merge;
                }
                Merge.prototype.merge = function (affected, unit, mergeInfoUnit, automerge) {
                    var units = [];
                    var dragCell = undefined;

                    units.push(cc.p(unit.x, unit.y));

                    if (unit.dragRawX) {
                        dragCell = cc.p(unit.dragRawX, unit.dragRawY);
                    }

                    if (affected[0] && affected[0].dragRawX) {
                        dragCell = cc.p(affected[0].dragRawX, affected[0].dragRawY);
                    }

                    affected.forEach(function (unit) {
                        units.push(cc.p(unit.x, unit.y));
                    });

                    cleverapps.actionsRecording.pushAction({
                        type: "merge",
                        units: units,
                        dragCell: dragCell
                    });
                    Merge.prototype.__merge.call(this, affected, unit, mergeInfoUnit, automerge);
                };
            },
            out: function () {
                if (Merge.prototype.__merge) {
                    Merge.prototype.merge = Merge.prototype.__merge;
                }
            }
        },
        move: {
            in: function () {
                var instantWorkerTarget;

                if (!Unit.prototype.__move) {
                    Unit.prototype.__move = Unit.prototype.move;
                }
                Unit.prototype.move = function (x, y) {
                    var endPos = { x: x, y: y };
                    if (instantWorkerTarget) {
                        endPos.x = instantWorkerTarget.x;
                        endPos.y = instantWorkerTarget.y;
                    }
                    cleverapps.actionsRecording.pushAction({
                        type: "move",
                        units: [{
                            x: this.x,
                            y: this.y
                        }, endPos]
                    });

                    instantWorkerTarget = undefined;

                    Unit.prototype.__move.call(this, x, y);
                };

                if (!InstantWorker.prototype.__buildInstantly) {
                    InstantWorker.prototype.__buildInstantly = InstantWorker.prototype.buildInstantly;
                }
                InstantWorker.prototype.buildInstantly = function (buildable) {
                    instantWorkerTarget = { x: buildable.unit.x, y: buildable.unit.y };

                    InstantWorker.prototype.__buildInstantly.call(this, buildable);
                };
            },
            out: function () {
                if (Unit.prototype.__move) {
                    Unit.prototype.move = Unit.prototype.__move;
                    Unit.prototype.__move = undefined;
                }

                if (InstantWorker.prototype.__buildInstantly) {
                    InstantWorker.prototype.buildInstantly = InstantWorker.prototype.__buildInstantly;
                    InstantWorker.prototype.__buildInstantly = undefined;
                }
            }
        },
        zoom: {
            in: function () {
                if (!cleverapps.UI.ZoomHandler.prototype.__setZoom) {
                    cleverapps.UI.ZoomHandler.prototype.__setZoom = cleverapps.UI.ZoomHandler.prototype.setZoom;
                }
                cleverapps.UI.ZoomHandler.prototype.setZoom = function (zoom, silent) {
                    var zoomRounded = Math.round(100 * zoom) / 100;
                    var steps = cleverapps.actionsRecording.steps;
                    if (steps.length > 0 && steps[steps.length - 1].type === "zoom_to") {
                        steps[steps.length - 1].zoom = zoomRounded;
                    } else if (steps.length > 1 && steps[steps.length - 2].type === "zoom_to") {
                        steps[steps.length - 2].zoom = zoomRounded;
                    } else if (!(cleverapps.windows.currentWindow() instanceof CustomersWindow)) {
                        cleverapps.actionsRecording.pushAction({
                            type: "zoom_to",
                            zoom: zoomRounded
                        });
                    }

                    cleverapps.notification.create("Zoom to: " + zoomRounded);

                    cleverapps.UI.ZoomHandler.prototype.__setZoom.call(this, zoom, silent);
                };
            },
            out: function () {
                if (cleverapps.UI.ZoomHandler.prototype.__setZoom) {
                    cleverapps.UI.ZoomHandler.prototype.setZoom = cleverapps.UI.ZoomHandler.prototype.__setZoom;
                    cleverapps.UI.ZoomHandler.prototype.__setZoom = undefined;
                }
            }
        },
        startQuest: {
            in: function () {
                if (!Quests.prototype.__select) {
                    Quests.prototype.__select = Quests.prototype.select;
                }
                Quests.prototype.select = function (quest) {
                    var steps = cleverapps.actionsRecording.steps;
                    if (steps.length === 0 || steps[steps.length - 1].type !== "startQuest") {
                        cleverapps.actionsRecording.pushAction({
                            type: "startQuest",
                            unit: {
                                code: quest.unit ? quest.unit.code : undefined,
                                stage: quest.unit ? quest.unit.stage : undefined
                            }
                        });
                    }

                    Quests.prototype.__select.call(this, quest);
                };
            },
            out: function () {
                if (Quests.prototype.__select) {
                    Quests.prototype.select = Quests.prototype.__select;
                    Quests.prototype.__select = undefined;
                }
            }
        }
    },

    board: {
        furnitureSelect: {
            in: function () {
                if (typeof Furniture === "undefined") {
                    return;
                }

                if (!Furniture.prototype.__onClick) {
                    Furniture.prototype.__onClick = Furniture.prototype.onClick;
                }

                Furniture.prototype.onClick = function () {
                    cleverapps.actionsRecording.pushAction({
                        type: "furnitureSelect",
                        id: this.id
                    });
                    Furniture.prototype.__onClick.call(this);
                };
            },
            out: function () {
                if (typeof Furniture === "undefined") {
                    return;
                }

                if (Furniture.prototype.__onClick) {
                    Furniture.prototype.onClick = Furniture.prototype.__onClick;
                }
            }
        },
        keypad: {
            in: function () {
                if (!Keypad.prototype.__push) {
                    Keypad.prototype.__push = Keypad.prototype.push;
                }
                if (!Keypad.prototype.__pop) {
                    Keypad.prototype.__pop = Keypad.prototype.pop;
                }
                if (!Keypad.prototype.__status) {
                    Keypad.prototype.__status = Keypad.prototype.status;
                }

                var letters = [];
                Keypad.prototype.push = function (letter) {
                    if (letter instanceof KeypadLetter) {
                        letters.push({ index: letter.index });
                    } else {
                        letters.push({ x: letter.x, y: letter.y });
                    }

                    Keypad.prototype.__push.call(this, letter);
                };

                Keypad.prototype.pop = function () {
                    letters.pop();

                    Keypad.prototype.__pop.call(this);
                };

                Keypad.prototype.status = function () {
                    cleverapps.actionsRecording.pushAction({
                        type: "word",
                        letters: letters
                    });

                    letters = [];

                    Keypad.prototype.__status.apply(this, arguments);
                };
            },
            out: function () {
                if (Keypad.prototype.__push) {
                    Keypad.prototype.push = Keypad.prototype.__push;
                }
                if (Keypad.prototype.__pop) {
                    Keypad.prototype.pop = Keypad.prototype.__pop;
                }
                if (Keypad.prototype.__status) {
                    Keypad.prototype.status = Keypad.prototype.__status;
                }
            }
        }
    },

    tile3: {
        playCard: {
            in: function () {
                if (!TileTable.prototype.__startPlayCard) {
                    TileTable.prototype.__startPlayCard = TileTable.prototype.startPlayCard;
                }
                TileTable.prototype.startPlayCard = function (card) {
                    cleverapps.actionsRecording.pushAction({
                        type: "play",
                        index: this.cards.indexOf(card)
                    });

                    TileTable.prototype.__startPlayCard.call(this, card);
                };
            },
            out: function () {
                if (TileTable.prototype.__startPlayCard) {
                    TileTable.prototype.startPlayCard = TileTable.prototype.__startPlayCard;
                }
            }
        }

    },

    blocks: {
        movePiece: {
            in: function () {
                if (!BlocksGame.prototype.__takeAndPlacePiece) {
                    BlocksGame.prototype.__takeAndPlacePiece = BlocksGame.prototype.takeAndPlacePiece;
                }
                BlocksGame.prototype.takeAndPlacePiece = function (index, x, y, isHover) {
                    if (!isHover) {
                        cleverapps.actionsRecording.pushAction({
                            type: "play",
                            piece: index,
                            x: x,
                            y: y
                        });
                    }

                    BlocksGame.prototype.__takeAndPlacePiece.call(this, index, x, y, isHover);
                };
            },
            out: function () {
                if (BlocksGame.prototype.__takeAndPlacePiece) {
                    BlocksGame.prototype.takeAndPlacePiece = BlocksGame.prototype.__takeAndPlacePiece;
                }
            }
        },

        newPieces: {
            in: function () {
                if (!Pieces.prototype.__showNewPieces) {
                    Pieces.prototype.__showNewPieces = Pieces.prototype.showNewPieces;
                }
                Pieces.prototype.showNewPieces = function () {
                    Pieces.prototype.__showNewPieces.call(this);

                    cleverapps.actionsRecording.pushAction({
                        type: "setPieces",
                        nextForms: cleverapps.clone(this.forms, true),
                        nextColors: cleverapps.clone(this.colors, true)
                    });
                };
            },
            out: function () {
                if (Pieces.prototype.__getNewPieces) {
                    Pieces.prototype.getNewPieces = Pieces.prototype.__getNewPieces;
                }
            }
        }

    },

    solitaire: {
        playCard: {
            in: function () {
                if (!CardTable.prototype.__startPlayCard) {
                    CardTable.prototype.__startPlayCard = CardTable.prototype.startPlayCard;
                }
                CardTable.prototype.startPlayCard = function (card) {
                    cleverapps.actionsRecording.pushAction({
                        type: "play",
                        value: card.value
                    });

                    CardTable.prototype.__startPlayCard.call(this, card);
                };

                if (!Hand.prototype.__cardClicked) {
                    Hand.prototype.__cardClicked = Hand.prototype.cardClicked;
                }
                Hand.prototype.cardClicked = function (card) {
                    cleverapps.actionsRecording.pushAction({
                        type: "hand"
                    });

                    Hand.prototype.__cardClicked.call(this, card);
                };

                if (!Booster.prototype.__onPressed) {
                    Booster.prototype.__onPressed = Booster.prototype.onPressed;
                }
                Booster.prototype.onPressed = function (f, isRunningForce) {
                    cleverapps.actionsRecording.pushAction({
                        type: "booster",
                        id: this.id
                    });

                    Booster.prototype.__onPressed.call(this, f, isRunningForce);
                };

                if (!cleverapps.Combo.prototype.__giveReward) {
                    cleverapps.Combo.prototype.__giveReward = cleverapps.Combo.prototype.giveReward;
                }
                cleverapps.Combo.prototype.giveReward = function (card) {
                    cleverapps.actionsRecording.pushAction({
                        type: "combo_reward",
                        value: card.value
                    });

                    cleverapps.Combo.prototype.__giveReward.call(this, card);
                };
            },
            out: function () {
                if (CardTable.prototype.__startPlayCard) {
                    CardTable.prototype.startPlayCard = CardTable.prototype.__startPlayCard;
                }

                if (Hand.prototype.__cardClicked) {
                    Hand.prototype.cardClicked = Hand.prototype.__cardClicked;
                }

                if (Booster.prototype.__onPressed) {
                    Booster.prototype.onPressed = Booster.prototype.__onPressed;
                }

                if (cleverapps.Combo.prototype.__giveReward) {
                    cleverapps.Combo.prototype.giveReward = cleverapps.Combo.prototype.__giveReward;
                }
            }
        }
        
    },

    match3: {
        move: {
            in: function () {
                if (!Field.prototype.__move) {
                    Field.prototype.__move = Field.prototype.move;
                }
                Field.prototype.move = function (start, finish) {
                    cleverapps.actionsRecording.pushAction({
                        start: { x: start.x, y: start.y },
                        finish: { x: finish.x, y: finish.y }
                    });

                    Field.prototype.__move.call(this, start, finish);
                };
            },
            out: function () {
                if (Field.prototype.__move) {
                    Field.prototype.move = Field.prototype.__move;
                }
            }
        },

        wrongMove: {
            in: function () {
                if (!Field.prototype.__wrongMove) {
                    Field.prototype.__wrongMove = Field.prototype.wrongMove;
                }
                Field.prototype.wrongMove = function (start, finish) {
                    cleverapps.actionsRecording.pushAction({
                        start: { x: start.x, y: start.y },
                        finish: { x: finish.x, y: finish.y }
                    });

                    Field.prototype.__wrongMove.call(this, start, finish);
                };
            },
            out: function () {
                if (Field.prototype.__wrongMove) {
                    Field.prototype.wrongMove = Field.prototype.__wrongMove;
                }
            }
        }

    },

    differences: {
        discover: {
            in: function () {
                if (!Differences.prototype.__discover) {
                    Differences.prototype.__discover = Differences.prototype.discover;
                }
                Differences.prototype.discover = function (area, image, point) {
                    cleverapps.actionsRecording.pushAction({
                        area: { x: area.x, y: area.y },
                        image: image
                    });

                    Differences.prototype.__discover.call(this, area, image, point);
                };
            },
            out: function () {
                if (Differences.prototype.__discover) {
                    Differences.prototype.discover = Differences.prototype.__discover;
                }
            }
        }
    }
};

RecordingOverrides.override = function () {
    if (!PointerHandler.prototype.__handleTouchMoved) {
        PointerHandler.prototype.__handleTouchMoved = PointerHandler.prototype.handleTouchMoved;
    }
    var startScrollTime;
    var pointerTouchCell;
    var centerCell;

    PointerHandler.prototype.handleTouchMoved = function (touch, event) {
        if (this.target.isMap && !startScrollTime) {
            startScrollTime = Date.now();
            pointerTouchCell = Game.currentGame.map.getView().getCellByTouch(touch);
            centerCell = Game.currentGame.map.getScreenCenterCell();
        }
        PointerHandler.prototype.__handleTouchMoved.call(this, touch, event);
    };

    if (!PointerHandler.prototype.__handleTouchEnded) {
        PointerHandler.prototype.__handleTouchEnded = PointerHandler.prototype.handleTouchEnded;
    }
    PointerHandler.prototype.handleTouchEnded = function (touch, event) {
        var newPosition = Game.currentGame.map.getScreenCenterCell();
        if (this.target.isMap && startScrollTime && (newPosition.x !== centerCell.x || newPosition.y !== centerCell.y)) {
            cleverapps.actionsRecording.steps.push({
                type: "scroll_to",
                duration: (Date.now() - startScrollTime) / 1000,
                cellPosition: newPosition,
                pointerTouchCell: pointerTouchCell
            });
            startScrollTime = undefined;
        }
        PointerHandler.prototype.__handleTouchEnded.call(this, touch, event);
    };

    if (!PointerHandler.prototype.__handleClick) {
        PointerHandler.prototype.__handleClick = PointerHandler.prototype.handleClick;
    }
    PointerHandler.prototype.handleClick = function (touch, event) {
        var counter = Game.currentGame && Game.currentGame.counter;
        if (counter && counter.isActive()) {
            return;
        }

        if (this.target.isMap) {
            var mapView = Game.currentGame.map.getView();
            cleverapps.actionsRecording.pushAction({
                type: "click_map",
                id: this.target.getDebugId(),
                cell: mapView.getCellByTouch(touch),
                cellOffset: mapView.getOffsetByTouch(touch)
            });
        } else if (this.target.getDebugId()) {
            cleverapps.actionsRecording.pushAction({
                type: "click_uielement",
                id: this.target.getDebugId(),
                location: this.target.convertTouchToNodeSpace(touch)
            });
        }
        PointerHandler.prototype.__handleClick.call(this, touch, event);
    };
    cleverapps.values(RecordingOverrides[cleverapps.config.type] || {}).forEach(function (override) {
        override.in();
    });
};

RecordingOverrides.restore = function () {
    if (PointerHandler.prototype.__handleClick) {
        PointerHandler.prototype.handleClick = PointerHandler.prototype.__handleClick;
    }
    if (PointerHandler.prototype.__handleTouchMoved) {
        PointerHandler.prototype.handleTouchMoved = PointerHandler.prototype.__handleTouchMoved;
    }
    if (PointerHandler.prototype.__handleTouchEnded) {
        PointerHandler.prototype.handleTouchEnded = PointerHandler.prototype.__handleTouchEnded;
    }
    cleverapps.values(RecordingOverrides[cleverapps.config.type] || {}).reverse().forEach(function (override) {
        override.out();
    });
};
