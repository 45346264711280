/**
 * Created by Andrey Popov on 20.01.2021
 */
var AdsFireworks = Fireworks.extend({
    ctor: function (properties) {
        if (cleverapps.config.type === "merge") {
            FireworksAnimation.Create = function () {
                return new cleverapps.Spine(bundles.ads_fireworks.jsons.fireworks_json);
            };
        }

        properties = properties || {};

        var sound = this.fireworksSound = properties.sound ? bundles[properties.sound.bundle].urls[properties.sound.sound] : undefined;
        this._super(sound);

        this.setAnchorPoint2();
    },

    hide: function () {
        this.setVisible(false);

        this.sound = undefined;
    },

    show: function () {
        this.setVisible(true);

        this.sound = this.fireworksSound;
    }
});

AdsFireworks.BUNDLES = ["ads_fireworks"];

AdsFireworks.PROPERTIES = [{
    name: "position", value: { x: { align: "center", dx: "-50%" }, y: { align: "center", dy: "-50%" } }
}, {
    name: "sound", type: "sound", bundles: ["ads_background_music", "ads_sfx_common"]
}, {
    name: "loop", type: "checkBox", value: true
}];
