/**
 * Created by vladislav on 02.18.21.
 */

var AdsSpine = cc.Node.extend({
    ctor: function (properties) {
        this._super();
        this.setAnchorPoint2();

        var component = this.spine = undefined;
        properties = properties || {};

        this.resource = undefined;
        if (properties.spine && properties.spine.bundle && bundles[properties.spine.bundle] && bundles[properties.spine.bundle].jsons[properties.spine.json]) {
            this.resource = {
                bundle: properties.spine.bundle,
                resource: properties.spine.json
            };
            this.resourceProperty = properties.spine;
        } else if (properties.gameSpine && properties.gameSpine.bundle && bundles[properties.gameSpine.bundle] && bundles[properties.gameSpine.bundle].jsons[properties.gameSpine.resource]) {
            this.resource = properties.gameSpine;
            this.resourceProperty = properties.gameSpine;
        }

        // TexturePacker --format spine --sheet packed/bathroom_2.png --trim-mode None --size-constraints POT --max-size 4096 --data packed/bathroom_2.atlas images --force-publish

        if (this.resource) {
            component = this.spine = new cleverapps.Spine(bundles[this.resource.bundle].jsons[this.resource.resource]);
            this.loadAnimation(properties);
        }

        if (component) {
            this.addChild(component);

            var scale = 1;
            var width = properties.width;
            var height = properties.height;

            if (width) {
                scale = width / component.width;
            }
            if (height) {
                scale = Math.min(scale, height / component.height);
            }
            component.setScale(scale);

            this.setContentSize((properties.contentWidth || component.getContentSize().width) * scale, (properties.contentHeight || component.height) * scale);
            component.setPositionRound(this.width / 2, this.height / 2);
            component.setScaleX(properties.flipX ? -1 : 1);
            component.setScaleY(properties.flipY ? -1 : 1);

            if (width && component.width) {
                var scaleX = width / component.width;
                component.setScaleX(properties.flipX ? -1 * scaleX : scaleX);
            }
            if (height && component.height) {
                var scaleY = height / component.height;
                component.setScaleY(properties.flipY ? -1 * scaleY : scaleY);
            }

            properties.rotation && component.setRotation(properties.rotation);
        } else {
            this.setContentSize(100, 100);
        }
    },

    loadAnimation: function (properties) {
        this.animationName = "animation";
        if (this.resourceProperty && this.resourceProperty.animation) {
            this.animationName = this.resourceProperty.animation;
        }

        var skinName = "default";
        if (this.resourceProperty && this.resourceProperty.skin) {
            skinName = this.resourceProperty.skin;
        }

        var loadedSpine = cc.loader.getRes(bundles[this.resource.bundle].jsons[this.resource.resource]);
        if (loadedSpine) {
            var availableSkins = Object.keys(loadedSpine.skins);

            if (!properties.isPreview && properties.skinFormat) {
                var name = properties.skinFormat.replace("%{lang}", cleverapps.settings.language);
                var index = availableSkins.indexOf(name);
                if (index !== -1) {
                    skinName = availableSkins[index];
                }
            }
        }

        if (skinName !== "default" && availableSkins.indexOf(skinName) !== -1) {
            this.spine.setSkin(skinName);
        }

        this.show(properties);
    },

    hide: function () {
        this.setVisible(false);
    },

    show: function (properties) {
        if (!this.spine) {
            return;
        }

        this.setVisible(true);

        if (properties.spine && properties.spine.idle) {
            this.spine.setAnimation(0, this.animationName, false);
            this.spine.addAnimation(0, properties.spine.idle, properties.spine.looped);
        } else if (this.animationName) {
            this.spine.setAnimation(0, this.animationName, properties.spine.looped);
        }
    }
});

AdsSpine.PROPERTIES = [{
    name: "spine",
    type: "spine",
    bundles: ["ads_spines", "ads_spines2", "ads_spines3", "dialogues", "ads_map2d", "ads_menu", "ads_menu_text", "ads_animated_bg", "ads_window"],
    value: {
        skin: "default",
        animation: "animation",
        looped: false
    },
    bubbleUp: true
}, {
    name: "gameSpine",
    resourceType: "spine",
    type: "resource"
}, {
    name: "flipX", type: "checkBox"
}, {
    name: "flipY", type: "checkBox"
}, {
    name: "contentWidth", type: "number"
}, {
    name: "contentHeight", type: "number"
}, {
    name: "skinFormat", type: "string" // , value: 'grass_%{lang}'
}, {
    name: "showOnEvent", type: "string", items: [Wysiwyg.NOT_SELECTED].concat(cleverapps.values(Wysiwyg.EVENTS)), value: Wysiwyg.NOT_SELECTED
}];