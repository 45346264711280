/**
 * Created by Andrey Popov on 11/17/21.
 */

var MissionsTool = {
    getLastRemoved: function () {
        var actions = {};
        var fn = function (type) {
            if (Missions[type].oncePerEvent) {
                for (var id in cleverapps.missionManager.lastRemoved) {
                    if (Missions[type].semaphore === Missions[id].semaphore) {
                        delete cleverapps.missionManager.lastRemoved[id];
                    }
                }
            } else {
                delete cleverapps.missionManager.lastRemoved[type];
            }
            cleverapps.missionManager.save();
            cleverapps.toolModel.refresh();
        };

        for (var type in cleverapps.missionManager.lastRemoved) {
            var name = Missions[type].name;
            actions[name] = fn.bind(undefined, type);
        }

        return actions;
    },

    getMissionMenu: function (mission) {
        var missionActions = {};

        var finish = function () {
            var timeRemain = 2 * 1000;

            if (mission.feature) {
                cleverapps.eventManager.setCustomFeature(mission.feature, 0);
            }

            if (mission.eventFinishTime) {
                mission.eventFinishTime = Date.now() + cleverapps.parseInterval("1 seconds");
            } else {
                mission.started = Date.now() - cleverapps.parseInterval(mission.duration) + timeRemain;
            }

            mission.onUpdateState();
            cleverapps.missionManager.save();
        };

        missionActions.finish = finish;

        if (mission.type === Mission.TYPE_LANTERN) {
            missionActions.setLevel = function () {
                var level = parseInt(window.prompt("Lantern level (0..." + Lantern.getMaxStreak() + "):"));
                var levelCorrect = false;
                if (!isNaN(level)) {
                    if (level >= 0 && level <= Lantern.getMaxStreak()) {
                        levelCorrect = true;
                        mission.logic.lantern.streak = mission.logic.lantern.savedStreak = level;
                        mission.logic.lantern.save();
                    }
                }
                if (!levelCorrect) {
                    alert("INCORRECT LEVEL");
                }
            };
        } else if (mission.type === Mission.TYPE_TREASURE_SEARCH) {
            missionActions.viewField = function () {
                var s = "";
                for (var row = 0; row < TreasureSearch.HEIGHT; row++) {
                    for (var col = 0; col < TreasureSearch.WIDTH; col++) {
                        if (mission.details.field[TreasureSearch.HEIGHT - row - 1][col] === TreasureSearch.ELEMENT_GEM) {
                            s += "*";
                        } else {
                            s += "-";
                        }
                    }
                    s += "\n";
                }
                alert("TreasureSearch unopened keys/flowers:\n" + s);
            };
            missionActions.add20Shovels = function () {
                mission.updateCurrency(20);
            };
        } else if (mission.type === Mission.TYPE_STICKERS_COLLECTION) {
            missionActions.add10Gems = function () {
                mission.updateCurrency(10);
            };
        } else if (mission.logic instanceof PassMissionLogic) {
            missionActions.addStars = function () {
                var amount = parseInt(prompt("enter stars amount"));
                if (amount) {
                    cleverapps.missionManager.dispatchEvent(mission.type, { amount: amount });
                }
            };

            missionActions.setLevel = function () {
                var menu = {};
                var setLevel = function (level) {
                    while (mission.logic.progress.level < level) {
                        mission.logic.addProgress(mission.logic.levels[mission.logic.progress.level].task.goal - mission.logic.progress.progress);
                    }
                };

                for (var i = 0, l = mission.view.LEVELS.length; i < l; ++i) {
                    menu["level " + (i + 1)] = setLevel.bind(null, i);
                }
                menu.finish = setLevel.bind(null, mission.view.LEVELS.length);
                return menu;
            };

            missionActions.dropAllRewards = function () {
                var toLastBit = function (rewards) {
                    return rewards === 0 ? 0 : (1 << (Number(rewards).toString(2).length - 1));
                };

                mission.logic.rewards = toLastBit(mission.logic.rewards);
                mission.logic.premiumRewards = toLastBit(mission.logic.premiumRewards);
                mission.logic.saveProgress();
            };

            missionActions.reset = function () {
                mission.logic.load({});
                mission.logic.saveProgress();
                cleverapps.sideBar.findMissionIcon(mission.type).resetState();
            };
        } else if (mission.competition) {
            missionActions.win_place = function () {
                var value = window.prompt("Enter the place (1-5): ");
                var place = parseInt(value);
                if (isNaN(place) || place < 1 || place > 5) {
                    return;
                }

                var competition = mission.runningCompetition;
                if (competition) {
                    var results = competition.results.filter(function (res) {
                        return !res.player;
                    }).sort(function (a, b) {
                        return b.amount - a.amount;
                    });

                    var topPoints = results[0].amount + 10;
                    mission.update(topPoints);
                    for (var j = 0; j < place - 1; j++) {
                        competition.updatePlayerResult(results[j].id, topPoints + (5 - j));
                    }
                    competition.stop();
                }
                finish(mission);
            };

            missionActions.win_1_native = function () {
                if (mission.getResults().length) {
                    mission.update(1000);
                }
                finish(mission);
            };

            missionActions.lose = function () {
                var updated = false;
                var results = mission.getResults();
                if (results.length) {
                    var ourPlace = 0;
                    for (var j = 0; j < results.length; j++) {
                        if (results[j].player) {
                            ourPlace = j;
                        }
                    }
                    if (ourPlace >= 5) {
                        mission.update(Math.max(1, mission.result) - mission.result);
                        updated = true;
                    } else {
                        mission.update(-1000);
                        updated = true;
                    }
                }
                if (updated) {
                    finish(mission);
                }
            };
        } else if (mission.logic instanceof ExpeditionMissionLogic) {
            missionActions.resetCompensation = function () {
                cleverapps.serverFlags.clearFlag(cleverapps.ServerFlags.EXPEDITION_COMPENSATION);
            };
        }

        missionActions.makeUnknown = function () {
            cleverapps.setUrlHash({ forgetMission: mission.type });
            window.location.reload();
        };

        return missionActions;
    },

    getToolItems: function () {
        var missionActions = {
            "clear finished": this.getLastRemoved()
        };

        var start = function (missionData) {
            var siblingMission = cleverapps.missionManager.findBySemaphore(missionData.semaphore);
            if (siblingMission) {
                cleverapps.missionManager.remove(siblingMission);
            }

            var feature = missionData.feature;
            if (feature && !cleverapps.eventManager.isActive(feature)) {
                cleverapps.eventManager.setCustomFeature(feature, cleverapps.parseInterval("1 day"));
            }

            cleverapps.missionManager.planMission(missionData.type);

            cleverapps.meta.whenFreeFocus(function () {
                cleverapps.placements.run(Placements.FREE_FOCUS, {
                    skipDelayFromStart: true
                });
            });
        };

        var missions = {};

        cleverapps.missionManager.getExistingTypes().forEach(function (missionType) {
            var parentType = Mission.GetParentType(missionType);

            if (parentType === undefined) {
                missions[missionType] = missions[missionType] || {};
            } else {
                missions[parentType] = missions[parentType] || {};
                missions[parentType][missionType] = missions[parentType][missionType] || {};
            }
        });

        missions = Object.keys(missions).reduce(function (result, missionType) {
            result.push(missionType);

            Object.keys(missions[missionType]).forEach(function (childType) {
                result.push(childType);
            });

            return result;
        }, []);

        missions.forEach(function (missionType) {
            var missionData = Missions[missionType];
            var parentMissionData = Missions[Mission.GetParentType(missionType)];

            var title = (parentMissionData && (parentMissionData.name || parentMissionData.id) + " " || "") + (missionData.name || missionData.id);

            var mission = cleverapps.missionManager.findByType(missionType);

            if (mission) {
                missionActions[ToolMenuItem.ACTIVE_ITEM + title] = this.getMissionMenu(mission);
            } else {
                missionActions[title] = start.bind(null, missionData);
            }
        }.bind(this));

        return missionActions;
    }
};

(function () {
    var baseInit = Missions.initialize;
    Missions.initialize = function () {
        baseInit.apply(undefined, arguments);

        var params = cleverapps.getRequestParameters(location.hash);
        if (params.forgetMission !== undefined) {
            cleverapps.setUrlHash({ forgetMission: undefined });
            if (Missions[params.forgetMission]) {
                var missionType = Mission.GetChildType(params.forgetMission);
                console.warn("Mission " + Missions[missionType].name + " was made unknown, reload to restore");
                delete Missions[missionType];
            }
        }
    };
}());