/**
 * Created by mac on 9/25/20
 */

var GitButtons = function (folder) {
    cleverapps.EventEmitter.call(this);
    this.status = GitButtons.NO_CHANGES;
    this.folder = folder;
};

GitButtons.prototype = Object.create(cleverapps.EventEmitter.prototype);
GitButtons.prototype.constructor = GitButtons;

GitButtons.prototype.setStatus = function (status) {
    if (this.status !== status) {
        this.status = status;
        this.trigger("statusChanged", this.status);

        if (this.status === GitButtons.NO_CHANGES) {
            cleverapps.scenes.getRunningScene().clearChanges();
        }
    }
};

GitButtons.prototype.revert = function () {
    if (this.status !== GitButtons.CAN_PUSH && this.status !== GitButtons.PUSH_ERROR) {
        return;
    }

    this.checkStatus(function () {
        if (this.status !== GitButtons.CAN_PUSH && this.status !== GitButtons.PUSH_ERROR) {
            return;
        }

        cleverapps.meta.display({
            focus: "GitInfoWindow",
            action: function (f) {
                new GitInfoWindow({
                    parentScene: true,
                    status: this.gitStatus,
                    action: "revert",
                    callback: function (file) {
                        var text = "Are you sure to revert all local changes?";
                        if (file !== undefined) {
                            text = "Are you sure to revert file: " + file + "?";
                        }
                        if (window.confirm(text)) {
                            this.setStatus(GitButtons.RUNNING_COMMAND);
                            cleverapps.RestClient.post("/gitcommands/revert", {
                                folder: this.folder,
                                status: this.gitStatus,
                                file: file
                            }, function (result) {
                                if (result === "ok") {
                                    this.setStatus(GitButtons.NO_CHANGES);
                                    this.checkStatus();

                                    cleverapps.administrator && !cleverapps.config.wysiwygMode && cleverapps.administrator.log.createEntry({
                                        type: AdminLog.GIT_REVERT,
                                        data: file || this.gitStatus
                                    });
                                } else {
                                    alert("Revert error: " + JSON.stringify(result));
                                    this.setStatus(GitButtons.REVERT_ERROR);
                                }
                            }.bind(this), function () {
                                this.setStatus(GitButtons.REVERT_ERROR);
                            }.bind(this), {
                                timeout: 30000
                            });
                        }
                    }.bind(this)
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
    }.bind(this), true);
};

GitButtons.prototype.push = function () {
    if (this.status !== GitButtons.NEED_PUSH && this.status !== GitButtons.CAN_PUSH) {
        return;
    }

    this.checkStatus(function () {
        if (this.status !== GitButtons.NEED_PUSH && this.status !== GitButtons.CAN_PUSH) {
            return;
        }

        cleverapps.meta.display({
            focus: "GitInfoWindow",
            action: function (f) {
                new GitInfoWindow({
                    parentScene: true,
                    status: this.gitStatus,
                    action: "push",
                    callback: function (file) {
                        var userComment = "";

                        if (this.gitStatus !== "need push") {
                            userComment = window.prompt("Enter commit comment:", "");
                        }

                        if (userComment == null) {
                            return;
                        }

                        var username = window.localStorage.getItem("adminAccess_username");
                        if (!username) {
                            alert("Please log in!");
                            return;
                        }

                        var comment = "user: " + username;
                        if (userComment !== "") {
                            comment = comment + "\ncomment: \"" + userComment + "\"";
                        }

                        this.setStatus(GitButtons.RUNNING_COMMAND);
                        cleverapps.RestClient.post("/gitcommands/push", {
                            folder: this.folder,
                            status: this.gitStatus,
                            file: file,
                            comment: comment
                        }, function (result) {
                            if (result === "ok") {
                                this.setStatus(GitButtons.NO_CHANGES);
                                this.checkStatus();

                                cleverapps.administrator && !cleverapps.config.wysiwygMode && cleverapps.administrator.log.createEntry({
                                    type: AdminLog.GIT_PUSH,
                                    data: file || this.gitStatus
                                });
                            } else {
                                alert("Push error: " + JSON.stringify(result));
                                this.setStatus(GitButtons.PUSH_ERROR);
                            }
                        }.bind(this), function () {
                            this.setStatus(GitButtons.PUSH_ERROR);
                        }.bind(this), {
                            timeout: 30000
                        });
                    }.bind(this)
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
    }.bind(this), true);
};

GitButtons.prototype.checkStatus = function (callback, blocking) {
    if (this.status === GitButtons.RUNNING_COMMAND) {
        return;
    }

    if (blocking) {
        this.setStatus(GitButtons.RUNNING_COMMAND);
    }
    callback = callback || function () {};

    cleverapps.RestClient.get("/gitcommands/status?folder=" + this.folder, {}, function (status) {
        this.gitStatus = status;
        if (this.gitStatus === "need push") {
            this.setStatus(GitButtons.NEED_PUSH);
        } else if (this.gitStatus.newFiles.length > 0 || this.gitStatus.changedFiles.length > 0) {
            this.setStatus(GitButtons.CAN_PUSH);
        } else {
            this.setStatus(GitButtons.NO_CHANGES);
        }
        callback();
    }.bind(this), function () {
        this.setStatus(GitButtons.STATUS_ERROR);
        callback();
    }.bind(this));
};

GitButtons.NO_CHANGES = 0;
GitButtons.CAN_PUSH = 1;
GitButtons.NEED_PUSH = 2;
GitButtons.RUNNING_COMMAND = 3;
GitButtons.PUSH_ERROR = 4;
GitButtons.REVERT_ERROR = 5;
GitButtons.STATUS_ERROR = 6;