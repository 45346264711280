/**
 * Created by slava on 02.02.17.
 */

cleverapps.Scenes = function () {
};

cleverapps.refreshScene = function () {
    var sceneChange = function (f) {
        var scene = cleverapps.scenes.getRunningScene();
        scene.refreshFromTool = true;

        if (scene instanceof MainScene) {
            cleverapps.travelBook.gotoMainScene(f);
        } else if (typeof EditorScene !== "undefined" && scene instanceof EditorScene) {
            EditorScene.open({ level: scene.level, callback: f });
        } else if (scene instanceof GameScene) {
            if (cleverapps.config.editorMode) {
                f();
                LevelManager.getInstance().setLevel({
                    levelNo: cleverapps.environment.levelNo,
                    episodeNo: cleverapps.environment.episodeNo
                });
                LevelManager.getInstance().editLevel();
            } else if (cleverapps.config.type === "merge") {
                cleverapps.travelBook.gotoMainScene(f);
            } else {
                cleverapps.meta.wantsToPlay(f);
            }
        } else if (typeof HeroesScene !== "undefined" && scene instanceof HeroesScene) {
            cleverapps.scenes.replaceScene(new HeroesScene(), f);
        } else if (scene instanceof DailyCupScene) {
            cleverapps.scenes.replaceScene(new DailyCupScene(), f);
        } else if (scene instanceof BonusWorldScene) {
            cleverapps.travelBook.gotoMainScene(function () {
                cleverapps.scenes.replaceScene(new BonusWorldScene(cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD)), f);
            });
        }
    };

    if (cleverapps.meta.isFocused()) {
        cleverapps.meta.distract({
            focus: "sceneRefresh",
            action: sceneChange
        });
    } else {
        cleverapps.meta.display({
            focus: "sceneRefresh",
            action: sceneChange
        });
    }
};

cleverapps.Scenes.prototype.getMovingNode = function (target) {
    for (; target; target = target.parent) {
        if (target.movingNode) {
            return target.movingNode;
        }
    }

    var current = cleverapps.scenes.getRunningScene(target);
    return cleverapps.config.wysiwygMode ? current : current.movingNode;
};

cleverapps.Scenes.prototype.getRunningScene = function (node) {
    if (node) {
        if (cleverapps.config.debugMode && !((node instanceof cc.Node) || typeof Map2dCellView !== "undefined" && (node instanceof Map2dCellView))) {
            cleverapps.throwAsync("incorrect usage of getRunningScene");
        }

        do {
            if (node instanceof cc.Scene) {
                return node;
            }
            node = node.parent;
        } while (node);
    }

    return cc.director.getRunningScene();
};

cleverapps.Scenes.prototype.replaceScene = function (scene, f) {
    if (!cleverapps.meta.isFocused()) {
        cleverapps.meta.debugMessage("Trying to replace scene without user focus! " + scene.sceneName);
    }

    if (Game.currentGame) {
        Game.currentGame.stop();
        Game.currentGame = undefined;
    }

    cleverapps.sideBar.clearListeners();

    f = f || function () {};

    var current = cleverapps.scenes.getRunningScene();
    cleverapps.notification.clear();
    if (FingerView.currentFinger) {
        FingerView.currentFinger.removeFromParent();
        FingerView.currentFinger = undefined;
    }

    if (scene.isWide && !this.sceneMaxWidthToRestore) {
        this.sceneMaxWidthToRestore = cleverapps.styles.SCENE_MAX_WIDTH;
        Resolution.setupDesignResolution({
            sceneMaxWidthOverride: 5000
        });
    }

    if (!scene.isWide && this.sceneMaxWidthToRestore) {
        Resolution.setupDesignResolution({
            sceneMaxWidthOverride: this.sceneMaxWidthToRestore
        });
        this.sceneMaxWidthToRestore = undefined;
        current.scene.width = current.width;
    }

    cleverapps.meta.compound(f, [
        function (f) {
            cleverapps.menuBar.clean();
            cleverapps.aims.clear();

            current.performRecursive(function (node) {
                cc.eventManager.removeListeners(node);
                node.stopAllActions();
                if (node instanceof cleverapps.Spine) {
                    node.setCompleteListener(function () {});
                }

                if (node !== current) {
                    if (node.updateSize) {
                        node.updateSize = function () {};
                    }

                    if (node.updatePosition) {
                        node.updatePosition = function () {};
                    }

                    if (node.hideHandler) {
                        node.hideHandler = undefined;
                    }
                }
            });
            f();
        },

        function (f) {
            if (scene.prepareBundles) {
                scene.prepareBundles();
            }

            f();
        },

        function (f) {
            this.createTransition(current, scene);

            if (current.onTransitionAnimationDidStart) {
                current.onTransitionAnimationDidStart();
            }

            if (this.transition && this.transition.closeCurtains) {
                this.transition.closeCurtains(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.transition) {
                this.transition.replaceParent(scene);
            }

            cc.director.runScene(scene);
            cc.director.setNextScene();

            f();
        }.bind(this),

        function (f) {
            if (current.unload) {
                current.unload(scene);
            }

            var q = [current];
            while (q.length > 0) {
                var t = q.pop();
                q = q.concat(t.children);

                if (t.iterateTiles) {
                    t.iterateTiles(function (tile) {
                        if (tile instanceof cc.Node) {
                            q.push(tile);
                        }
                    });
                }

                t.removeAllChildren();
            }

            current = undefined;

            f();
        },

        function (f) {
            var callback = cleverapps.wait(2, f);

            cleverapps.rewardedAdsManager.showPreroll(callback);

            if (scene.load) {
                scene.load(callback);
            } else {
                callback();
            }
        },

        function (f) {
            if (scene.onSceneLoaded) {
                scene.onSceneLoaded();
            }

            if (scene.setLoadingFinished) {
                scene.setLoadingFinished();
            }

            f();
        },

        function (f) {
            if (cleverapps.toolModel && typeof ToolView !== "undefined") {
                cleverapps.toolModel.reset();
                scene.toolMenu = new ToolView();
                scene.addChild(scene.toolMenu);
                scene.toolMenu.addOpener();
            }

            if (this.transition && this.transition.openCurtains) {
                this.transition.openCurtains(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            this.removeTransition();
            f();
        }.bind(this)
    ]);
};

cleverapps.Scenes.prototype.createTransition = function (current, scene) {
    this.removeTransition();

    if (cleverapps.flags.noTransitionAnimation) {
        return;
    }

    if (current.createTransition && scene.canTransitionFast && scene.canTransitionFast()) {
        this.transition = current.createTransition();
    }

    if (!this.transition) {
        this.transition = new TransitionAnimation();
        current.addChild(this.transition);
        this.transition.updateSize();
        this.transition.updatePosition();
    }

    if (this.transition.transitionBundles) {
        cleverapps.bundleLoader.loadBundles(this.transition.transitionBundles);
    }
};

cleverapps.Scenes.prototype.removeTransition = function () {
    if (this.transition) {
        this.transition.removeFromParent();

        if (this.transition.transitionBundles) {
            cleverapps.bundleLoader.deleteBundles(this.transition.transitionBundles);
        }

        this.transition = undefined;
    }
};

cleverapps.Scenes.prototype.reset = function () {
    this.removeTransition();
};

cleverapps.Scenes.prototype.sceneRefresh = function () {
    cleverapps.flags.reset();
    if (levels) {
        levels.reset();
        cleverapps.windows.reset();
        cleverapps.scenes.reset();
        if (cleverapps.isKnockoutGame()) {
            cleverapps.meta.getMainObject().knockoutGame.stopRumble();
        }

        cleverapps.meta.distract({
            focus: "sceneRefresh",
            action: function (f) {
                cleverapps.travelBook.gotoMainScene(f);
            }
        });
    }
};