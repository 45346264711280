/**
 * Created by vladislav on 30.12.2021
 */

cleverapps.Boosters.CONFIG = {};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_CELL] = {
    model: CellBooster,
    price: {
        hard: 100
    },
    icon_moving: bundles.game.frames.cellbooster_moving_png,
    icon: bundles.game.frames.cellbooster_png,
    icon_active: bundles.game.frames.cellbooster_active_png,
    available: {
        level: 1.6
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_LINE] = {
    model: LineBooster,
    price: {
        hard: 150
    },
    icon_moving: bundles.game.frames.linebooster_moving_png,
    icon: bundles.game.frames.linebooster_png,
    icon_active: bundles.game.frames.linebooster_active_png,
    available: {
        level: 2.53
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_COLOR] = {
    model: ColorBooster,
    price: {
        hard: 200
    },
    icon_moving: bundles.game.frames.colorbooster_moving_png,
    icon: bundles.game.frames.colorbooster_png,
    icon_active: bundles.game.frames.colorbooster_active_png,
    available: {
        level: 3.33
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HINT] = {
    model: HintBooster,
    price: {
        hard: (cleverapps.config.subtype === "stacks") ? 150 : 50
    },
    force: Forces.FREE_HINT_FORCE,
    available: {
        level: 0.07,
        olympics: {
            level: 0.25
        }
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_WILDCARD] = {
    model: WildcardBooster,
    force: Forces.FREE_WILDCARD_FORCE,
    price: {
        hard: 150
    },
    available: {
        level: 1.45
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_SHUFFLE] = {
    model: ShuffleBooster,
    force: Forces.FREE_SHUFFLE_FORCE,
    price: {
        hard: 100
    },
    available: {
        level: 0.25,
        projectName: ["scramble"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_BRUSH] = {
    model: BrushBooster,
    force: Forces.FREE_PAINT_FORCE,
    price: {
        hard: 200
    },
    available: {
        level: 0.8
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_DISCOVER] = {
    model: DiscoverBooster,
    force: Forces.FREE_HINT_FORCE,
    price: {
        hard: 50
    },
    available: {
        level: 0.8
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_COMBO] = {
    model: ComboBooster,
    price: {
        hard: 50
    },
    icon: bundles.reward_icons.frames.heroes_png,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.73
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MOVES] = {
    model: MovesBooster,
    price: {
        hard: 100
    },
    icon: bundles.reward_icons.frames.moves_png,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MULTICOLOR] = {
    model: MultiColorBooster,
    price: {
        hard: 150
    },
    icon: bundles.reward_icons.frames.multicolor_png,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MAGNET] = {
    model: MagnetBooster,
    price: {
        hard: 50
    },
    icon: bundles.reward_icons.frames.magnet_png,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.85
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_DICE] = {
    model: DiceBooster,
    price: {
        hard: 100
    },
    icon: bundles.reward_icons.frames.dice_png,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_JOKERS] = {
    model: TwoJokersBooster,
    price: {
        hard: 150
    },
    icon: bundles.reward_icons.frames.joker_png,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HIGHLIGHT] = {
    model: HighlightBooster,
    price: {
        hard: 50
    },
    icon: bundles.reward_icons.frames.highlight,
    force: Forces.FIRST_BOOSTERS_BEFORE,
    available: {
        level: 1.33
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_EYE] = {
    model: EyeBooster,
    price: {
        hard: 100
    },
    icon: bundles.reward_icons.frames.eye,
    force: Forces.SECOND_BOOSTERS_BEFORE,
    available: {
        level: 2.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_MAGNIFIER] = {
    model: MagnifierBooster,
    price: {
        hard: 150
    },
    icon: bundles.reward_icons.frames.hint,
    force: Forces.THIRD_BOOSTERS_BEFORE,
    available: {
        level: 2.93
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_TILE_SHUFFLE] = {
    model: TileShuffleBooster,
    force: Forces.FREE_SHUFFLE_FORCE,
    price: {
        soft: 40
    },
    executeEffect: bundles.game.urls.shuffle_booster_effect,
    available: {
        level: 0.91,
        projectName: ["zenmatch"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_UNDO] = {
    model: UndoBooster,
    force: Forces.FREE_UNDO_FORCE,
    price: {
        hard: 50,
        soft: 100
    },
    disableForceOnInactive: true,
    executeEffect: bundles.game.urls.undo_booster_effect,
    available: {
        level: 0.25
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_RETURN] = {
    model: ReturnBooster,
    force: Forces.FREE_RETURN_FORCE,
    disableForceOnInactive: true,
    price: {
        soft: 295
    },
    executeEffect: bundles.game.urls.return_booster_effect,
    available: {
        level: 1.17,
        projectName: ["zenmatch"]
    }
};

if (cleverapps.config.type === "klondike") {
    cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_UNDO].price = { hard: 0 };
    cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HINT].price = { hard: 0 };
}
