/**
 * Created by vladislav on 13.03.2020
 */

cleverapps.SkinManager.SKINS.easter = {
    bundle: "easter",
    localization: {
        heroes: {
            "Missions.TulpanTournament.Title": "Easter.Missions.TulpanTournament.Title",
            "Missions.TulpanTournament.Rules1": "Easter.Missions.TulpanTournament.Rules1",
            "Missions.TulpanTournament.AnimationMainTextWin": "Easter.Missions.TulpanTournament.AnimationMainTextWin",
            "Missions.TulpanTournament.AnimationMainTextLose": "Easter.Missions.TulpanTournament.AnimationMainTextLose",
            "Missions.TulpanTournament.AnimationMainTextInfo0": "Easter.Missions.TulpanTournament.AnimationMainTextInfo0",
            "Missions.TulpanTournament.AnimationMainTextInfo1": "Easter.Missions.TulpanTournament.AnimationMainTextInfo1",
            "AddMovesWindow.mission.TulpanTournament": "Easter.AddMovesWindow.mission.TulpanTournament",
            "AddMovesWindow.missionGiveUp.TulpanTournament": "Easter.AddMovesWindow.missionGiveUp.TulpanTournament"
        },
        riddles: {
            "Missions.RoseTournament.Title": "Easter.Missions.RoseTournament.Title",
            "Missions.RoseTournament.Rules1": "Easter.Missions.RoseTournament.Rules1",
            "Missions.RoseTournament.AnimationMainTextWin": "Easter.Missions.RoseTournament.AnimationMainTextWin",
            "Missions.RoseTournament.AnimationMainTextLose": "Easter.Missions.RoseTournament.AnimationMainTextLose",
            "Missions.RoseTournament.AnimationMainTextInfo0": "Easter.Missions.RoseTournament.AnimationMainTextInfo0",
            "Missions.RoseTournament.AnimationMainTextInfo1": "Easter.Missions.RoseTournament.AnimationMainTextInfo1",
            "AddMovesWindow.mission.TulpanTournament": "Easter.AddMovesWindow.mission.RoseTournament",
            "AddMovesWindow.missionGiveUp.RoseTournament": "Easter.AddMovesWindow.missionGiveUp.RoseTournament",
            "AddMovesWindow.mission.RoseTournament": "Easter.AddMovesWindow.mission.RoseTournament"
        },
        wordsoup: {
            "Missions.LetterTournament.Title": "Easter.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Easter.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Easter.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Easter.MissionRulesWindow.LetterTournament.text3"
        },

        scramble: {
            "Missions.LetterTournament.Title": "Easter.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Easter.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Easter.MissionRulesWindow.LetterTournament.text3"
        },
        crocword: {
            "Missions.LetterTournament.Title": "Easter.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Easter.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Easter.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Easter.MissionRulesWindow.LetterTournament.text3"
        }
    },

    urls: {
        reward_icons: {
            energy_small_png: bundles.reward_icons.frames.easter_energy_small_png
        }
    },

    slots: {
        localization: function (code) {
            var localization = cleverapps.SkinManager.SKINS.easter.localization;
            return localization && localization[cleverapps.config.name] && localization[cleverapps.config.name][code];
        },

        windowDecors: function (options) {
            var decors = options.decors && cleverapps.clone(options.decors, true) || {};
            if (cleverapps.config.name === "wordsoup") {
                if (!(options.window instanceof LeadersWindow)) {
                    decors.window_bottom_right_corner_2 = true;
                    decors.window_bottom_right_corner_1 = true;
                    decors.window_bottom_left_corner_2 = true;
                    decors.window_bottom_left_corner_1 = true;
                }
            }
            if (cleverapps.config.name === "riddles") {
                decors.window_bottom_right = true;
                decors.window_bottom_left = true;
            }
            if (cleverapps.config.name === "scramble") {
                decors.easter_decor_top = true;
                decors.easter_decor_bottom = true;
                if (options.window instanceof TellWindow) {
                    decors.easter_decor_bottom = false;
                }
                if (options.window instanceof MissionWindow) {
                    decors.easter_decor_top = false;
                }
                if (options.window instanceof ExtraWindow) {
                    decors.easter_decor_top = false;
                }
                if (options.window instanceof VictoryWindow) {
                    decors.easter_decor_top = false;
                }
            }
            return decors;
        },
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.menuBarItemEaster;
        },
        toolbarDecors: function () {
            return cleverapps.styles.SceneDecors.toolbarViewEaster;
        },
        progressViewDecor: function () {
            return cleverapps.styles.SceneDecors.progressViewDecorEaster;
        },
        sceneControlButton: function (options) {
            if (options.target.options.logic === MenuControlButton) {
                return cleverapps.styles.SceneDecors.sceneControlButtonEaster;
            }
        },
        missionWindowDecors: function () {
            return cleverapps.styles.SceneDecors.missionWindowEaster;
        },
        tulpanScale: function () {
            if (cleverapps.config.name === "heroes") {
                return 0.9;
            }
        },
        missionIcon: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.easter.frames.letter_mission_icon;
            }
            if (["heroes"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_BURN_NEARBY) {
                return bundles.easter.frames.tulpan_png;
            }
            if (["riddles"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_COLLECT_MARK) {
                return bundles.easter.frames.rose_png;
            }
        },
        missionStar: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.easter.frames["mission_star" + options.type + "_png"];
            }
        },
        missionMarkIcon: function () {
            return bundles.easter.frames["rose_" + (Math.random() < 0.5 ? 1 : 2)];
        },
        missionRulesWindow: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.easter.jsons.letter_rules_json;
            }
        },
        removeMissions: function () {
            if (cleverapps.config.name === "riddles") {
                return [Mission.TYPE_COMBO, Mission.TYPE_BURN_NEARBY];
            }
            if (cleverapps.config.name === "heroes") {
                return [Mission.TYPE_COLLECT_MARK, Mission.TYPE_COMBO];
            }
        },
        gameSceneBg: function () {
            if (cleverapps.config.name === "wordsoup") {
                return {
                    bundle: "easter",
                    patternId: "pattern_bg"
                };
            }
        },
        spine: function (options) {
            var spines = {};
            if (["riddles"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.rose_tournament.jsons.json] = bundles.easter.jsons.rose_tournament_json;
                spines[bundles.main.jsons.tournament_rose_icon_json] = bundles.easter.jsons.tournament_rose_icon_json;
            }
            if (["heroes"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.tulpan_tournament.jsons.json] = bundles.easter.jsons.tulpan_tournament_json;
                spines[bundles.main.jsons.tournament_tulpan_icon_json] = bundles.easter.jsons.tournament_tulpan_icon_json;
            }
            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.easter.jsons.mission_window_json;
            }

            if (cleverapps.config.type === "board") {
                spines[bundles.sidebar.jsons.mission_letter_icon_json] = bundles.sidebar.jsons.easter_letter_json;
            }

            return spines[options.json];
        }
    }
};
