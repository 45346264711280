/**
 * Created by andrey on 17.11.2022
 */

var features = {
    type_jam: {
        bundles: [
            "/cleverapps/topack/jambundles"
        ],
        jsModules: [
        ]
    },
    type_blocks: {
        bundles: [
            "/cleverapps/topack/blocksbundles"
        ],
        dictionaries: {
            type_json: { path: "localizations/blocks/${lang}.json", priority: 120 }
        },
        jsModules: [
            "blocks",
            "blockstool"
        ]
    },
    tilegame: {
        bundles: [
            "/cleverapps/topack/tilegamebundles"
        ],
        jsModules: [
            "tilegame",
            "tiletool"
        ]
    },
    type_tile3: {
        bundles: [
            "/cleverapps/topack/tile3/tile3bundles"
        ],
        dictionaries: {
            type_json: { path: "localizations/tile3/${lang}.json", priority: 140 }
        },
        jsModules: [
            "tile3"
        ]
    },

    common: {
        bundles: [
            "/cleverapps/topack/cleverappsbundles",
            "/cleverapps/topack/crosspromobundles",
            "/cleverapps/topack/cleverappsepisodes",
            "/cleverapps/topack/cleverappslanguages",
            "/cleverapps/topack/sharebundles"
        ],

        languages: ["en", "fr", "ru", "de", "es", "pt", "it", "nl", "ar", "ja", "ko", "tr", "zh", "pl", "lv"],

        dictionaries: {
            common_json: { path: "localizations/common_${lang}.json", priority: 0 },
            project_json: { path: "localization/${lang}.json", priority: 1000 },
            shares_json: { path: "localizations/shares/shares_${lang}.json", priority: 1100 }
        },

        jsModules: [
            "preboot",
            "boot",
            "cleverapps",
            "levels",
            "administrator",
            "basetool",
            "orangery",
            "taskmanager",
            "atlasanalyzer",
            "wysiwyg"
        ],

        leagues: [30, 60, 100, 200, 500, 1000, false]
    },

    lives: {
        bundles: [
            "/cleverapps/topack/livesbundles",
            "/cleverapps/topack/prolongationbundles"
        ],

        jsModules: [
            "lives",
            "prolongation"
        ]
    },

    energy: {
        bundles: ["/cleverapps/topack/energybundles"],

        jsModules: [
            "energy"
        ]
    },

    nosoft: {
        bundles: ["/cleverapps/topack/nosoftbundles"],
        dictionaries: {
            gold_json: { path: "localizations/gold/gold_${lang}.json", priority: 100 }
        }
        
    },

    match3_rpg: {
        bundles: ["/match3/topack/rpgbundles"],
        jsModules: [
            "match3_rpg"
        ]
    },

    map2d: {
        jsModules: [
            "map2d"
        ]
    },

    type_merge: {
        bundles: [
            "/cleverapps/topack/mergebundles",
            "/cleverapps/topack/mergedialoguebundles",
            "/cleverapps/topack/mergetilesbundles"
        ],
        dictionaries: {
            type_json: { path: "localizations/merge/${lang}.json", priority: 150 },
            quests_json: { path: "localization/quests/quests_${lang}.json", priority: 160 },
            unitStories_json: { path: "localization/unitstories/unitstories_${lang}.json", priority: 180 }
        },
        jsModules: [
            "merge",
            "mergetool",
            "mergewysiwyg"
        ]
    },

    type_match3: {
        bundles: [
            "/match3/topack/match3mainbundles",
            "/match3/topack/match3gamebundles",
            "/match3/topack/featuresbundles",
            "/match3/topack/editorbundle"
        ],
        dictionaries: {
            type_json: { path: "localizations/match3/match3_${lang}.json", priority: 200 },
            skin_json: { path: "localization/match3skin_${lang}.json", priority: 220 }
        },
        jsModules: [
            "match3",
            "match3_heroes",
            "match3tool"
        ]
    },

    match3_hose: {
        jsModules: [
            "match3_hose"
        ]
    },

    cryptex: {
        bundles: [
            "/cleverapps/topack/cryptexbundles"
        ],

        jsModules: [
            "cryptex"
        ]
    },

    type_board: {
        bundles: [
            "/cleverapps/topack/boardgamebundles",
            "/cleverapps/topack/boardgameadsbundles.js"
        ],

        languages: ["en", "fr", "ru", "de", "es", "it", "ja", "nl"],

        dictionaries: {
            type_json: { path: "localizations/boardgame/boardgame_${lang}.json", priority: 240 }
        },

        jsModules: [
            "boardgame",
            "boardgametool",
            "boardgameadministrator"
        ]
    },

    type_battlefield: {
        bundles: [
            "/cleverapps/topack/battlefieldbundles"
        ],

        dictionaries: {
            type_json: { path: "localizations/battlefield/battlefield_${lang}.json", priority: 260 }
        },

        jsModules: [
            "battlefield",
            "epicarttool"
        ]
    },

    type_solitaire: {
        bundles: [
            "/cleverapps/topack/solitairebundles"
        ],
        dictionaries: {
            type_json: { path: "localizations/solitaire/${lang}.json", priority: 280 }
        },
        jsModules: [
            "solitaire",
            "solitairetool"
        ]
    },

    type_klondike: {
        bundles: [
            "/cleverapps/topack/klondikebundles"
        ],
        dictionaries: {
            type_json: { path: "localizations/klondike/${lang}.json", priority: 300 }
        },
        jsModules: [
            "solitaire",
            "klondikegame"
        ]
    },

    type_differences: {
        jsModules: [
            "differences",
            "differencestool"
        ]
    },

    type_coinmaster: {
        bundles: ["/cleverapps/topack/coinsbundles"],
        jsModules: [
            "coins"
        ]
    },

    riddlesgamefeatures: {
        bundles: [
            "/match3/topack/riddlesfeaturebundles"
        ]
    },
    heroesgamefeatures: {
        bundles: [
            "/match3/topack/heroesfeaturebundles"
        ],
        jsModules: [
            "heroesgamefeatures"
        ]
    },

    expeditions: {
        jsModules: [
            "expeditions"
        ]
    },

    expedition_halloween: {
        bundles: ["/cleverapps/topack/expeditions/halloweenbundles"],
        debug: true,

        dictionaries: {
            halloween_json: { path: "localizations/expeditions/halloween/halloween_${lang}.json", priority: 320 },
            halloween_unitStories_json: { path: "localizations/expeditions/halloween/unitstories/unitstories_${lang}.json", priority: 340 }
        }
    },
    expedition_xmas: {
        bundles: ["/cleverapps/topack/expeditions/xmasbundles"],
        debug: true,

        dictionaries: {
            xmas_json: { path: "localizations/expeditions/xmas/xmas_${lang}.json", priority: 360 },
            xmas_quests_json: { path: "localizations/expeditions/xmas/quests/quests_${lang}.json", priority: 380 },
            xmas_unitStories_json: { path: "localizations/expeditions/xmas/unitstories/unitstories_${lang}.json", priority: 400 }
        }
    },
    expedition_dragonia: {
        bundles: [
            "/cleverapps/topack/expeditions/dragoniabundles"
        ],

        dictionaries: {
            dragonia_json: { path: "localizations/expeditions/dragonia/dragonia_${lang}.json", priority: 420 },
            dragonia_quests_json: { path: "localizations/expeditions/dragonia/quests/quests_${lang}.json", priority: 440 },
            dragonia_unitStories_json: { path: "localizations/expeditions/dragonia/unitstories/unitstories_${lang}.json", priority: 460 }
        }
    },
    expedition_dragonia2: {
        bundles: [
            "/cleverapps/topack/expeditions/dragoniabundles"
        ],

        dictionaries: {
            dragonia_json: { path: "localizations/expeditions/dragonia/dragonia_${lang}.json", priority: 480 },
            dragonia_quests_json: { path: "localizations/expeditions/dragonia/quests/quests_${lang}.json", priority: 500 },
            dragonia_unitStories_json: { path: "localizations/expeditions/dragonia/unitstories/unitstories_${lang}.json", priority: 520 }
        }
    },
    expedition_dragonia3: {
        debug: true,
        bundles: [
            "/cleverapps/topack/expeditions/dragoniabundles"
        ],

        dictionaries: {
            dragonia_json: { path: "localizations/expeditions/dragonia/dragonia_${lang}.json", priority: 480 },
            dragonia_quests_json: { path: "localizations/expeditions/dragonia/quests/quests_${lang}.json", priority: 500 },
            dragonia_unitStories_json: { path: "localizations/expeditions/dragonia/unitstories/unitstories_${lang}.json", priority: 520 }
        }
    },
    expedition_undersea: {
        bundles: [
            "/cleverapps/topack/expeditions/underseabundles"
        ],

        dictionaries: {
            undersea_json: { path: "localizations/expeditions/undersea/undersea_${lang}.json", priority: 540 }
        }
    },
    expedition_rapunzel: {
        bundles: [
            "/cleverapps/topack/expeditions/rapunzelbundles"
        ],

        dictionaries: {
            rapunzel_json: { path: "localizations/expeditions/rapunzel/rapunzel_${lang}.json", priority: 560 },
            rapunzel_quests_json: { path: "localizations/expeditions/rapunzel/quests/quests_${lang}.json", priority: 580 },
            rapunzel_unitStories_json: { path: "localizations/expeditions/rapunzel/unitstories/unitstories_${lang}.json", priority: 600 }
        }
    },
    expedition_rapunzel2: {
        bundles: [
            "/cleverapps/topack/expeditions/rapunzelbundles"
        ],

        dictionaries: {
            rapunzel_json: { path: "localizations/expeditions/rapunzel/rapunzel_${lang}.json", priority: 620 },
            rapunzel_quests_json: { path: "localizations/expeditions/rapunzel/quests/quests_${lang}.json", priority: 640 },
            rapunzel_unitStories_json: { path: "localizations/expeditions/rapunzel/unitstories/unitstories_${lang}.json", priority: 660 }
        }
    },
    expedition_undersea2: {
        bundles: [
            "/cleverapps/topack/expeditions/underseabundles"
        ],

        dictionaries: {
            undersea_json: { path: "localizations/expeditions/undersea/undersea_${lang}.json", priority: 680 }
        }
    },
    expedition_farm: {
        bundles: ["/cleverapps/topack/expeditions/farmbundles"],
        debug: true,

        dictionaries: {
            expedition_farm_json: { path: "localizations/expeditions/farm/farm_${lang}.json", priority: 700 }
        }
    },
    expedition_easter: {
        bundles: [
            "/cleverapps/topack/expeditions/easterbundles"
        ],
        debug: true,

        dictionaries: {
            easter_json: { path: "localizations/expeditions/easter/easter_${lang}.json", priority: 720 },
            easter_quests_json: { path: "localizations/expeditions/easter/quests/quests_${lang}.json", priority: 740 },
            easter_unitStories_json: { path: "localizations/expeditions/easter/unitstories/unitstories_${lang}.json", priority: 760 }
        }
    },
    expedition_collections: {
        bundles: [
            "/cleverapps/topack/expeditions/collectionsbundles"
        ],
        debug: true,

        dictionaries: {
            collections_json: { path: "localizations/expeditions/collections/collections_${lang}.json", priority: 780 },
            collections_quests_json: { path: "localizations/expeditions/collections/quests/quests_${lang}.json", priority: 800 },
            collections_unitStories_json: { path: "localizations/expeditions/collections/unitstories/unitstories_${lang}.json", priority: 820 }
        }
    },
    expedition_adventure: {
        bundles: [
            "/cleverapps/topack/expeditions/adventurebundles"
        ],
        debug: true,

        dictionaries: {
            adventure_json: { path: "localizations/expeditions/adventure/adventure_${lang}.json", priority: 830 }
        }
    },

    ui_wooden: {
        bundles: ["/cleverapps/topack/ui/woodenuibundles"],
        jsModules: [
            "woodenui"
        ],

        imgFonts: {
            default: "marvin-round",
            nostroke: "marvin-round-nostroke",
            formal: "formal",
            custom_digits: "custom/custom_digits"
        }
    },
    ui_riddles: {
        bundles: ["/cleverapps/topack/ui/riddlesuibundles"],
        jsModules: [
            "riddlesui"
        ],
        imgFonts: {
            default: "marvin-round",
            nostroke: "marvin-round-nostroke",
            formal: "formal",
            custom_digits: "custom/custom_digits"
        }
    },
    ui_tropical: {
        bundles: ["/cleverapps/topack/ui/tropicuibundles.js"],
        jsModules: [
            "tropicui"
        ],
        imgFonts: {
            default: "marvin-round",
            nostroke: "marvin-round-nostroke",
            formal: "formal",
            custom_digits: "custom/custom_digits"
        }
    },
    ui_heroes: {
        bundles: ["/cleverapps/topack/ui/heroesuibundles"],
        jsModules: [
            "heroesui"
        ],
        imgFonts: {
            default: "bryndanwrite",
            nostroke: "bryndanwrite-nostroke",
            formal: "formal",
            custom_digits: "custom/heroes_custom_digits"
        }
    },

    meta_simple: {
        bundles: ["/cleverapps/topack/simplebundle"],
        jsModules: [
            "simplemain"
        ]
    },
    meta_shortmeta: {
        bundles: ["/cleverapps/topack/shortmetabundles.js"],
        jsModules: [
            "shortmeta"
        ]
    },
    meta_homefix: {
        bundles: ["/cleverapps/topack/homefixbundle"],
        dictionaries: {
            meta_json: { path: "localizations/homefix/homefix_${lang}.json", priority: 840 }
        },
        jsModules: [
            "homefix"
        ]
    },
    meta_farm: {
        bundles: ["/cleverapps/topack/farmbundle"],
        dictionaries: {
            meta_json: { path: "localization/farm/farm_${lang}.json", priority: 860 }
        },
        jsModules: [
            "metha_farm"
        ]
    },
    meta_fishdom: {
        bundles: ["/cleverapps/topack/fishdombundles"],
        jsModules: [
            "fishdom"
        ]
    },
    meta_loop: {
        bundles: ["/cleverapps/topack/loopbundle", "/cleverapps/topack/prolongationbundles"],
        dictionaries: {
            meta_json: { path: "localizations/loop/loop_${lang}.json", priority: 880 }
        },
        jsModules: [
            "metha_loop",
            "prolongation"
        ]
    },

    weeklycup: {
        dictionaries: {
            weekly_cup_json: { path: "localizations/weeklycup/weeklycup_${lang}.json", priority: 20 }
        },
        jsModules: [
            "weeklycup"
        ]
    },
    meta_hose: {
        jsModules: [
            "metha_hose"
        ]
    },

    regime_knockout: {
        bundles: ["/cleverapps/topack/knockoutbundle"],
        dictionaries: {
            regime_knockout_json: { path: "localizations/regimeknockout/regimeknockout_${lang}.json", priority: 40 }
        },
        jsModules: [
            "knockout"
        ]
    },

    skin_xmas: {
        bundles: ["/cleverapps/topack/skins/xmasskinbundles.js"]
    },

    slotmachine: {
        dictionaries: {
            slot_machine_json: { path: "localizations/slotmachine/slotmachine_${lang}.json", priority: 60 }
        },
        jsModules: [
            "slotmachine"
        ]
    },

    minesweeper: {
        jsModules: [
            "minesweeper"
        ]
    },

    treasure_search: {
        bundles: ["/cleverapps/topack/treasuresearchbundle"],
        dictionaries: {
            treasure_search_json: { path: "localizations/treasuresearch/treasuresearch_${lang}.json", priority: 80 }
        },
        jsModules: [
            "treasuresearch"
        ]
    },

    clans: {
        bundles: ["/cleverapps/topack/clansbundles"],
        dictionaries: {
            clans_json: { path: "localizations/clans/clans_${lang}.json", priority: 100 }
        },
        jsModules: [
            "clans"
        ]
    },

    achievements: {
        bundles: ["/cleverapps/topack/achievementsbundles"],
        dictionaries: {
            achievements_json: "localizations/achievements/achievements_${lang}.json"
        },
        jsModules: [
            "achievements"
        ]
    },

    type_yatzy: {
        bundles: [
            "/cleverapps/topack/yatzybundles"
        ]
    }
};

if (typeof exports !== "undefined") {
    module.exports = features;
}
