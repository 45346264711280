/**
 * Created by slava on 19/2/18
 */

var IncDecBlock = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.options = options;

        options.range = options.range || [-Infinity, Infinity];
        options.rate = options.rate || 1;
        this.value = options.value || 0;
        this.auto = false;

        this.setCascadeOpacityEnabled(true);
        var number = cleverapps.UI.generateTTFText("", cleverapps.styles.FONTS.EDITOR_TEXT);
        if (options.color) {
            number.setColor(options.color);
        }
        this.addChild(number);
        this.number = number;

        if (options.onChange) {
            cleverapps.UI.onClick(number, function () {
                var newValue = window.prompt("", number.getString());
                if (newValue && cleverapps.isNumber(newValue) && newValue >= options.range[0] && newValue <= options.range[1]) {
                    this._setValue(parseInt(newValue));
                }
            }.bind(this));
        }

        var icons = IncDecBlock.ICONS[options.icons || "default"];
        this.buttons = icons.map(function (icon, index) {
            var button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                content: new cc.Sprite(icon),
                hint: options.hint,
                onClicked: (index === 0) ? this.dec.bind(this) : this.inc.bind(this)
            });

            var radius = options.radius || cleverapps.styles.EditorIncDecBlock.radius;
            button.setPosition((index === 0) ? -radius : radius, cleverapps.styles.EditorIncDecBlock.yOffset);
            this.addChild(button);

            if (options.buttonsScale) {
                button.setScale(options.buttonsScale);
                button.baseScale = options.buttonsScale;
            }

            return button;
        }, this);

        this.updateButtonStates();
        this.redraw();

        cleverapps.UI.wrap(this);
    },

    setRange: function (range) {
        this.options.range = range;
        this.updateButtonStates();
    },

    inc: function () {
        this._setValue(this.value + this.options.rate);
    },

    dec: function () {
        this._setValue(this.value - this.options.rate);
    },

    updateButtonStates: function () {
        this.options.range.forEach(function (value, index) {
            if (this.value === value) {
                this.buttons[index].disable();
            } else {
                this.buttons[index].enable();
            }
        }, this);
    },

    setAuto: function (auto) {
        if (this.auto !== auto) {
            this.auto = auto;
            this.redraw();
        }
    },

    _setValue: function (value, silent) {
        if (value < this.options.range[0]) {
            value = this.options.range[0];
        } else if (value > this.options.range[1]) {
            value = this.options.range[1];
        }

        if (value === this.value) {
            return;
        }

        var diff = value - this.value;
        this.value = value;

        this.updateButtonStates();

        if (!silent) {
            if (this.options.onDec && diff < 0) {
                this.options.onDec();
            }

            if (this.options.onInc && diff > 0) {
                this.options.onInc();
            }

            if (this.options.onChange) {
                this.options.onChange(value, diff);
            }
        }

        this.redraw();
    },

    redraw: function () {
        var font = this.auto ? cleverapps.styles.FONTS.INCDECBLOCK_AUTO_TEXT : cleverapps.styles.FONTS.EDITOR_TEXT;
        var str = this.auto ? "auto" : this.value;

        cleverapps.UI.ImageFont.intertypeSetString({
            textObj: this.number,
            string: str,
            font: font,
            recreateFunc: function () {
                var pos = this.number.getPosition();
                this.number.removeFromParent();
                this.number = cleverapps.UI.generateTTFText(str, font);
                this.addChild(this.number);
                this.number.setPositionRound(pos);
            }.bind(this)
        });
    }
});

IncDecBlock.ICONS = {
    default: [bundles.editor.frames.editor_minus_icon, bundles.editor.frames.editor_plus_icon],
    updown: [bundles.editor.frames.editor_arrow_down, bundles.editor.frames.editor_arrow_up],
    leftright: [bundles.editor.frames.editor_arrow_left, bundles.editor.frames.editor_arrow_right]
};

cleverapps.styles.EditorIncDecBlock = {
    radius: 70,
    yOffset: 5
};