/**
 * Created by andrey on 17.05.2023
 */

var YandexAppAds = function () {
    AdsPlugin.call(this, {
        cantLoadAndPlayInParallel: true
    });
};

YandexAppAds.prototype = Object.create(AdsPlugin.prototype);
YandexAppAds.prototype.constructor = YandexAppAds;

YandexAppAds.prototype.init = function (callback) {
    console.log("YandexAppAds.init");
    this.onInitCallback = callback;

    var adUnitId = cleverapps.config.android.yandexAds.rewarded;
    if (cleverapps.config.debugMode) {
        adUnitId = YandexAppAds.YANDEX_AD_UNIT_ID;
    }
    YandexAdsPlugin.init(adUnitId);
};

YandexAppAds.prototype.onInit = function () {
    console.log("YandexAppAds.onInit");
    if (this.onInitCallback) {
        this.onInitCallback();
        delete this.onInitCallback;
    }
};

YandexAppAds.prototype._cache = function (name, callback) {
    if (name !== RewardedAdsManager.REWARDED) {
        callback(AdsPlugin.CODE_FAILED);
        return;
    }

    console.log("YandexAppAds._cache");
    this._cacheCallback = callback;
    YandexAdsPlugin.cache(name);
};

YandexAppAds.prototype.onAdLoaded = function () {
    console.log("YandexAppAds.onAdLoaded");
    if (this._cacheCallback) {
        this._cacheCallback(AdsPlugin.CODE_SUCCEED, true);
        this._cacheCallback = undefined;
    }
};

YandexAppAds.prototype.onAdFailedToLoad = function () {
    console.log("YandexAppAds.onAdFailedToLoad");
    if (this._cacheCallback) {
        this._cacheCallback(AdsPlugin.CODE_FAILED);
        this._cacheCallback = undefined;
    }
};

YandexAppAds.prototype.onAdClosed = function () {
    console.log("YandexAppAds.onAdClosed");
    if (this.success) {
        this._playCallback(AdsPlugin.CODE_REWARD);
    } else {
        this._playCallback();
    }
};

YandexAppAds.prototype.onReward = function () {
    this.success = true;
};

YandexAppAds.prototype.onAdOpened = function () {
    console.log("YandexAppAds.onAdOpened");
};

YandexAppAds.prototype._playAd = function (name, ad, callback) {
    console.log("YandexAppAds._playAd");
    this.success = false;
    this._playCallback = callback;
    YandexAdsPlugin.show(name);
};

YandexAppAds.isAppropriate = function () {
    return typeof YandexAdsPlugin !== "undefined"
        && (cleverapps.platform.oneOf(Rustore) || cleverapps.platform.oneOf(AndroidPlatform) && cleverapps.country.isRussia())
        && cleverapps.config.android.yandexAds && cleverapps.config.android.yandexAds.rewarded
        && YandexAdsPlugin.init && YandexAdsPlugin.cache && YandexAdsPlugin.show;
};

YandexAppAds.prototype.getECPM = function () {
    return RewardedAdsManager.eCPM.YandexApp;
};

YandexAppAds.YANDEX_AD_UNIT_ID = "demo-rewarded-yandex";
YandexAppAds.MINTEGRAL_AD_UNIT_ID = "demo-rewarded-mintegral";
YandexAppAds.MYTARGET_AD_UNIT_ID = "demo-rewarded-mytarget";