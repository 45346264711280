/**
 * Created by slava on 23.03.17.
 */

var FingerView = cc.Node.extend({
    ctor: function (options) {
        options = options || {};
        this._super();

        this.setLocalZOrder(100);

        var styles = cleverapps.styles.FingerView;

        this.setAnchorPoint2();
        this.setScale(options.scaleX || 1, options.scaleY || 1);
        this.finger = new cc.Sprite(options.image ? options.image : bundles.finger.frames.tutorial_hand_png);
        this.finger.setAnchorPoint(styles.anchorX, styles.anchorY);
        this.finger.baseRotation = 0;
        this.moveSpeed = 500;
        this.actions = [];

        if (options.pathColor) {
            this.pathNode = new cleverapps.Path(cc.color(options.pathColor.r, options.pathColor.g, options.pathColor.b, FingerView.PATH_BASE_ALPHA));
            this.pathNode.lineWidth = FingerView.PATH_LINE_WIDTH;
            this.addChild(this.pathNode, -2);
            this.pathNode.runAction(new cc.RepeatForever(new cc.Sequence(
                new cc.DelayTime(0.02),
                new cc.CallFunc(this.drawPath.bind(this))
            )));
        }

        this.addChild(this.finger);

        this.finger.setOpacity(0);

        if (options.control) {
            cleverapps.meta.registerControl(options.control, this.createListener(function (visible) {
                this.stopAllActions();

                if (visible && options.showDelay) {
                    this.runAction(new cc.Sequence(
                        new cc.DelayTime(options.showDelay),
                        new cc.Show()
                    ));
                } else {
                    this.setVisible(visible);
                }
            }.bind(this)));
        }

        if (cleverapps.environment.isWysiwygScene() && styles.offset) {
            this.setAdditionalTransform({
                a: 1,
                b: 0,
                c: 0,
                d: 1,
                tx: styles.offset.x,
                ty: styles.offset.y
            });
        }
    },

    standardLoop: function (params) {
        params = params || {};

        if (params.delayOnBegin || params.delayOnBeginAndFinish) {
            this.delay(params.delay || 1);
        }
        this.fadeIn(params.fadeInPosition || { x: 0, y: 0 }, params.fadeInStartPosition);
        this.addPressLoops(params.loops || 2, params.loops === 1 ? 0.3 : 0.1);
        this.fadeOut();
        if (!params.delayOnBegin || params.delayOnBeginAndFinish) {
            this.delay(params.delay || 1);
        }

        if (params.runOnce) {
            this.runOnce(function () {
                this.removeFromParent();

                params.onIterationFinish && params.onIterationFinish();
            }.bind(this));
        } else {
            this.run({
                looped: true,
                onIterationFinish: params.onIterationFinish
            });
        }
    },

    addPressLoops: function (amount, pressDelay) {
        for (var i = 0; i < (amount || 1); i++) {
            this.delay(0.1);
            this.press();
            this.delay(pressDelay || 0.1);
            this.unPress();
        }
    },

    clearActions: function () {
        this.actions = [];
    },

    drawPath: function () {
        this.pathNode.draw(this.finger.getPosition());
    },

    addAction: function (action) {
        this.actions.push(action);
    },

    run: function (options) {
        if (FingerView.currentFinger) {
            FingerView.currentFinger.removeFromParent();
        }
        FingerView.currentFinger = this;
        addCleaner(this, function () {
            if (FingerView.currentFinger === this) {
                FingerView.currentFinger = undefined;
            }
        }.bind(this));
        this.currentAction = 0;
        this.runOptions = options || {};
        this.startCurrentAction();
    },

    runOnce: function (f) {
        this.run({
            looped: false,
            onFinish: function () {
                this.clearActions();
                f && f();
            }.bind(this)
        });
    },

    startCurrentAction: function () {
        if (this.actions.length === 0) {
            return;
        }

        if (this.currentAction === this.actions.length) {
            if (this.runOptions.looped) {
                this.currentAction = 0;
                if (this.runOptions.onIterationFinish) {
                    this.runOptions.onIterationFinish();
                }
            } else {
                if (this.runOptions.onFinish) {
                    this.runOptions.onFinish();
                }
                return;
            }
        }
        this.actions[this.currentAction](this.nextAction.bind(this));
    },

    nextAction: function () {
        this.currentAction++;
        this.startCurrentAction();
    },

    checkClippingNodeFitting: function (targetPos) {
        for (var clippingNode = this.parent; clippingNode; clippingNode = clippingNode.parent) {
            if (typeof Map2dView !== "undefined" && clippingNode instanceof Map2dView || clippingNode.map2dRepeater) {
                return;
            }
            if (clippingNode instanceof ccui.Layout || clippingNode instanceof cc.Scene) {
                break;
            }
        }

        if (clippingNode) {
            var clippingNodePos = clippingNode.convertToNodeSpace(this.convertToWorldSpace(targetPos));
            clippingNodePos.y -= this.finger.height * this.finger.anchorY;

            this.finger.baseRotation = clippingNodePos.y <= 0 ? -80 : 0;
            this.finger.setRotation(this.finger.baseRotation);
        }
    },

    fadeIn: function (position, startPosition) {
        var styles = cleverapps.styles.FingerView;

        startPosition = startPosition || cc.p(position.x, position.y + styles.offsetY);
        var targetPos = cc.p(position.x + styles.dx, position.y + styles.dy);

        this.addAction(function (callback) {
            this.finger.setOpacity(0);
            this.finger.setScale(1);
            this.finger.setRotation(this.finger.baseRotation + FingerView.FINGER_UNPRESSED_ANGLE);
            this.pressed = false;
            this.finger.setPosition(startPosition);
            this.finger.runAction(
                new cc.Sequence(
                    new cc.CallFunc(this.checkClippingNodeFitting.bind(this, targetPos)),
                    new cc.MoveTo(0.3, targetPos),
                    new cc.CallFunc(callback)
                )
            );
            this.finger.runAction(new cc.FadeIn(0.3));
        }.bind(this));
    },

    fadeOut: function () {
        this.addAction(function (callback) {
            this.finger.runAction(new cc.MoveTo(0.3, cc.p(this.finger.x, this.finger.y - cleverapps.styles.FingerView.offsetY)));
            this.finger.runAction(new cc.Sequence(new cc.FadeOut(0.3), new cc.CallFunc(callback)));
        }.bind(this));
    },

    delay: function (duration) {
        this.addAction(function (callback) {
            this.finger.runAction(new cc.Sequence(new cc.DelayTime(duration), new cc.CallFunc(callback)));
        }.bind(this));
    },

    moveTo: function (position, easing) {
        this.addAction(function (callback) {
            var duration = Math.sqrt((position.x - this.finger.x) * (position.x - this.finger.x) + (position.y - this.finger.y) * (position.y - this.finger.y)) / this.moveSpeed;
            easing = easing || cc.easeInOut(1);

            this.finger.runAction(new cc.Sequence(
                new cc.MoveTo(duration, position).easing(easing),
                new cc.CallFunc(function () {
                    if (this.pressed && this.pathNode) {
                        this.pathNode.push(position);
                    }
                    callback();
                }.bind(this))
            ));
        }.bind(this));
    },

    unPress: function () {
        this.addAction(function (callback) {
            this.pressed = false;
            this.finger.runAction(new cc.RotateTo(0.2, this.finger.baseRotation + FingerView.FINGER_UNPRESSED_ANGLE));
            if (this.pathNode) {
                this.pathNode.animateFadeOut(400);
            }
            this.finger.runAction(new cc.Sequence(new cc.ScaleTo(0.2, 1), new cc.CallFunc(callback)));
        }.bind(this));
    },

    press: function (options) {
        options = options || {};

        this.addAction(function (callback) {
            this.pressed = true;
            if (this.pathNode) {
                this.pathNode.reset();
                this.pathNode.push(this.finger.getPosition());
            }

            this.finger.runAction(new cc.RotateTo(0.2, this.finger.baseRotation + FingerView.FINGER_PRESSED_ANGLE));
            this.finger.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 0.7),
                new cc.CallFunc(callback)
            ));
        }.bind(this));

        if (!options.noCircle) {
            this.clickCircle();
        }
    },

    getFingerPosition: function () {
        return this.finger.getPosition();
    },

    clickCircle: function () {
        this.addAction(function (callback) {
            if (!this.isDisplayed()) {
                callback();
                return;
            }

            var fingerPosition = this.finger.getPosition();

            var clickCircle = new cc.Sprite(bundles.finger.frames.tutorial_click_png);
            clickCircle.setPosition(fingerPosition.x, fingerPosition.y);
            clickCircle.setScale(0);
            this.addChild(clickCircle, -1);

            clickCircle.runAction(new cc.Sequence(
                new cc.PlaySound(bundles.finger.urls.press_effect),
                new cc.DelayTime(0.2),
                new cc.FadeOut(0.3),
                new cc.RemoveSelf()
            ));
            clickCircle.runAction(new cc.ScaleTo(0.5, 1));
            callback();
        }.bind(this));
    },

    showPayload: function (payload) {
        payload.setVisible(false);
        payload.setLocalZOrder(-1);

        this.addAction(function (callback) {
            payload.replaceParentSamePlace(this.finger, {
                keepScale: true
            });
            payload.setVisible(true);
            payload.runAction(new cc.ScaleBy(0.2, 1.3));
            this.payload = payload;
            callback();
        }.bind(this));
    },

    hidePayload: function () {
        this.addAction(function (callback) {
            if (this.payload) {
                this.payload.removeFromParent();
                this.payload = undefined;
            }
            callback();
        }.bind(this));
    }
});

FingerView.BUNDLES = ["finger"];

FingerView.PATH_BASE_ALPHA = 255;
FingerView.PATH_SEGMENTS = 100;
FingerView.PATH_LINE_WIDTH = 15;
FingerView.FINGER_PRESSED_ANGLE = 0;
FingerView.FINGER_UNPRESSED_ANGLE = -7;

cleverapps.styles.FingerView = {
    anchorX: 0.1,
    anchorY: 0.9,

    dx: 0,
    dy: 0,

    offsetY: 5,

    offset: {
        x: 10,
        y: -10
    }
};
