/**
 * Created by andrey on 24.05.17.
 */

var Fireworks = cc.Node.extend({
    ctor: function (sound) {
        this._super();

        cleverapps.bundleLoader.loadBundle("fireworks", {
            onSuccess: function () {
                this.createFirework();
                cleverapps.audio.playSound(sound || bundles.fireworks.urls.fireworks_effect);
            }.bind(this)
        });

        this.setLocalZOrder(100);
    },

    createFirework: function () {
        var fireworks = FireworksAnimation.Create();
        var animations = fireworks.listAnimations().filter(function (animation) {
            return animation.indexOf("animation") === 0;
        });

        var sceneSize = cleverapps.UI.getSceneSize();
        var scale = ["heroes", "tropical"].indexOf(cleverapps.config.ui) !== -1 ? 1 : Math.min(1, sceneSize.width / fireworks.width);
        fireworks.setScale(scale);
        fireworks.setAnimation(0, animations[Math.floor(Math.random() * animations.length)], false);
        fireworks.setCompleteListener(function () {
            fireworks.runAction(new cc.CallFunc(function () {
                fireworks.removeFromParent();
                cc.pool.putInPool(fireworks);
            }));
        });
        fireworks.setPositionRound(sceneSize.width / 2, sceneSize.height / 2);
        this.addChild(fireworks);
    }
});

var FireworksAnimation = cleverapps.Spine.extend({
    ctor: function () {
        this._super(bundles.fireworks.jsons.fireworks_json);
    }
});

FireworksAnimation.Create = function () {
    return cc.pool.hasObject(FireworksAnimation) ? cc.pool.getFromPool(FireworksAnimation) : new FireworksAnimation();
};