/**
 * Created by iamso on 07.10.2021
 */

cleverapps.LocalPushes.MicrosoftSender = {
    sendPush: function (info, timeout) {
        var data = cleverapps.LocalPushes.GetPushData(info);

        var title = data.title;
        var message = data.text;

        var id = info.id;

        if (!message) {
            cleverapps.throwAsync("empty microsoft push - " + info.code);
            return;
        }

        console.log("Sending push - " + id, data);
        cleverapps.MicrosoftLocalPushesPlugin.send({
            title: title,
            message: message,
            timeout: timeout,
            id: id
        });
    },

    cancelPush: function (info) {
        console.log("Cancel push - " + info.code);
        cleverapps.MicrosoftLocalPushesPlugin.cancel({
            id: info.id
        });
    }
};

cleverapps.MicrosoftLocalPushesPlugin = {
    send: function (data) {
        MicrosoftNotifier(JSON.stringify({
            handler: "localPushes",
            method: "send",
            title: data.title,
            message: data.message,
            timeout: data.timeout,
            id: "" + data.id
        }));
    },

    cancel: function (data) {
        MicrosoftNotifier(JSON.stringify({
            handler: "localPushes",
            method: "cancel",
            id: "" + data.id
        }));
    }
};