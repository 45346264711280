/**
 * Created by mac on 9/16/21
 */

var VkPromo = function () {

};

VkPromo.prototype.isAvailable = function (start, finish) {
    return cleverapps.social.isInitialized()
        && (start === null || Date.now() > new Date(start).getTime()) && (finish === null || Date.now() < new Date(finish).getTime());
};

VkPromo.prototype.giveReward = function () {
    cleverapps.meta.displayWhenFreeFocus({
        focus: "VKPromoRewardWindow",
        action: function (f) {
            new RewardWindow(VkPromo.REWARD);
            cleverapps.meta.onceNoWindowsListener = f;
        }
    });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.VKPROMO_REWARD);
};

VkPromo.REWARD = { hard: 50 };

if (cleverapps.config.type === "merge") {
    VkPromo.REWARD = { soft: 50 };
} else if (cleverapps.config.type === "match3" && !cleverapps.config.rpg) {
    VkPromo.REWARD = { unlimitedLives: "12 hours", stars: 10 };
} else if (cleverapps.config.type === "match3") {
    VkPromo.REWARD = { unlimitedLives: "12 hours", hard: 20 };
} else if (cleverapps.config.subtype === "stacks") {
    VkPromo.REWARD = { hard: 100 };
}

VkPromo.prototype.process = function (promoID) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.VKPROMO_CHECK);
    cleverapps.social.api("apps.promoHasActiveGift", { promo_id: promoID }, function (code, response) {
        if (code !== cleverapps.Social.CODE_SUCCEED) {
            return;
        }

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.VKPROMO_CHECK);
        if (response === 1) {
            this.giveReward();

            cleverapps.social.api("apps.promoUseGift", { promo_id: promoID });
        }
    }.bind(this));
};