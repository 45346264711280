/**
 * Created by vladislav on 25.01.2021
 */

var BonusWorldRewardsView = cc.Node.extend({
    ctor: function (bonusWorld) {
        this._super();

        this.bonusWorld = bonusWorld;

        this.setAnchorPoint2();

        this.styles = cleverapps.styles.BonusWorldRewardsView[this.bonusWorld.type];

        this.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        this.rewards = RewardsConfig.BonusWorld.map(function (reward, index) {
            var isOpen = this.bonusWorld.isRewardReceived(reward);

            var container = new cc.Node();
            this.addChild(container);
            container.setAnchorPoint2();
            container.setContentSize2(this.styles.prize);
            container.setPositionRound(this.styles.prizes[index]);

            if (!isOpen) {
                cleverapps.tooltipManager.create(container, {
                    rewards: reward.reward,
                    text: "" + this.bonusWorld.config.localizationPrefix + ".BonusWorld.RewardTooltip",
                    position: index < 1 ? cleverapps.styles.UI.Tooltip.LOCATION.above : cleverapps.styles.UI.Tooltip.LOCATION.below
                });

                container.hoverHandler = cleverapps.UI.applyHover(container);
            }

            var animation = new cleverapps.Spine(this.bonusWorld.bundle.jsons.chest_json);
            container.addChild(animation);
            animation.setAnimation(0, isOpen ? "idle_open" : "idle", true);
            animation.setSkin("skin_" + index);
            animation.setPositionRound(container.width / 2, container.height / 2);
            container.animation = animation;

            if (index === RewardsConfig.BonusWorld.length - 1) {
                cleverapps.aims.registerTarget("finalPrizeTarget" + this.bonusWorld.type, container);
            }

            return container;
        }, this);

        this.bonusWorld.on("giveReward", this.giveReward.bind(this));
    },

    giveReward: function (reward, f) {
        var container = this.rewards[RewardsConfig.BonusWorld.indexOf(reward)];

        if (container.hoverHandler) {
            container.hoverHandler.remove();
            container.hoverHandler = undefined;
        }

        cleverapps.tooltipManager.remove(container);
        cleverapps.audio.playSound(this.bonusWorld.bundle.urls.prize_sfx);

        var animation = container.animation;
        animation.setAnimation(0, "open", false);
        animation.setCompleteListener(function () {
            animation.setAnimation(0, "idle_open", true);
            animation.setCompleteListener(function () {});

            f();
        });
    }
});

cleverapps.styles.BonusWorldRewardsView = {};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_VALENTINES] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: { align: "center", dx: 220 },
            y: { align: "center", dy: -430 }
        },
        {
            x: { align: "center", dx: -71 },
            y: { align: "center", dy: -60 }
        },
        {
            x: { align: "center", dx: 19 },
            y: { align: "center", dy: 441 }
        }
    ]
};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_EASTER] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: { align: "center", dx: 245 },
            y: { align: "center", dy: -349 }
        },
        {
            x: { align: "center", dx: -245 },
            y: { align: "center", dy: 85 }
        },
        {
            x: { align: "center", dx: 19 },
            y: { align: "center", dy: 476 }
        }
    ]
};

cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
    prize: {
        width: 150,
        height: 150
    },

    prizes: [
        {
            x: { align: "center", dx: 144 },
            y: { align: "center", dy: -422 }
        },
        {
            x: { align: "center", dx: 203 },
            y: { align: "center", dy: 33 }
        },
        {
            x: { align: "center", dx: 22 },
            y: { align: "center", dy: 422 }
        }
    ]
};

if (cleverapps.config.name === "riddles") {
    cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
        prize: {
            width: 90,
            height: 90
        },

        prizes: [
            {
                x: { align: "center", dx: -251 },
                y: { align: "center", dy: -467 }
            },
            {
                x: { align: "center", dx: 140 },
                y: { align: "center", dy: -91 }
            },
            {
                x: { align: "center", dx: -40 },
                y: { align: "center", dy: 373 }
            }
        ]
    };
}

if (cleverapps.config.name === "scramble") {
    cleverapps.styles.BonusWorldRewardsView[BonusWorld.TYPE_SUMMER] = {
        prize: {
            width: 110,
            height: 110
        },

        prizes: [
            {
                x: { align: "center", dx: 180 },
                y: { align: "center", dy: -241 }
            },
            {
                x: { align: "center", dx: 98 },
                y: { align: "center", dy: -41 }
            },
            {
                x: { align: "center", dx: -10 },
                y: { align: "center", dy: 384 }
            }
        ]
    };
}