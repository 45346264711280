/**
 * Created by Andrey Popov on 1/22/21.
 */

var Inspector = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    if (wysiwyg.hierarchy.selectedItem) {
        this.showItemProperties(this.wysiwyg.hierarchy.selectedItem);
    }

    this.wysiwyg.hierarchy.on("selectionChanged", this.showItemProperties.bind(this));
    this.wysiwyg.wysiwygPreview.on("showScript", this.showItemProperties.bind(this));

    this.collapsedGroups = ["Node", "AdsGame"];
};

Inspector.prototype = Object.create(cleverapps.EventEmitter.prototype);
Inspector.prototype.constructor = Inspector;

Inspector.prototype.showItemProperties = function (selectedItem) {
    var hierarchyItem = selectedItem || this.wysiwyg.hierarchy.selectedItem;
    if (!hierarchyItem.type) {
        return;
    }
    var propertyGroups = [];

    switch (hierarchyItem.type) {
        case HierarchyItem.TYPE.CLIP:
            if (this.wysiwyg.playable) {
                propertyGroups.push({
                    name: "Clip",
                    properties: [
                        { name: "caption", type: "string" },
                        { name: "resolution", type: "string", items: Object.keys(Wysiwyg.VIDEO_SIZES) }]
                });
            } else {
                propertyGroups.push({
                    name: "Clip",
                    properties: [
                        { name: "caption", type: "string" },
                        {
                            name: "languages", type: "string", items: Wysiwyg.LANGUAGES, multiselect: true 
                        },
                        {
                            name: "resolutions", type: "string", items: Object.keys(Wysiwyg.VIDEO_SIZES), multiselect: true 
                        },
                        { name: "frameCapture", type: "frameCapture", items: Object.keys(Wysiwyg.VIDEO_SIZES) },
                        { name: "frameRate", type: "number", value: 60 },
                        {
                            name: "finger", type: "image", bundles: ["ads_finger"], value: { image: "finger_png", bundle: "ads_finger" } 
                        },
                        {
                            name: "music", type: "sound", bundles: ["ads_background_music", "ads_sfx_common"]
                        },
                        {
                            name: "cutOff", type: "checkBox"
                        },
                        {
                            name: "fadeOutOff", type: "checkBox"
                        }]
                });
            }
            break;
        case HierarchyItem.TYPE.SCENE:
            propertyGroups.push({
                name: "Scene",
                properties: [
                    {
                        name: "bg", type: "image", bundles: ["ads_backgrounds_*"], allowOverride: true
                    },
                    { name: "bgPosition", type: "position" },
                    { name: "duration", type: "number" }]
            });

            if (hierarchyItem.parentItem.children[0] && hierarchyItem.parentItem.children[0].id !== hierarchyItem.id) {
                propertyGroups.push({
                    name: "Scene transition",
                    properties: [
                        {
                            name: "sceneTransition",
                            type: "string",
                            items: ["ProgressInOut", "ProgressOutIn", "ProgressRadialCCW", "ProgressRadialCW", "ProgressVertical",
                                "ProgressHorizontal", "CrossFade", "Fade", "FadeTR", "JumpZoom", "MoveInL", "PageTurn", "RotoZoom",
                                "SceneOriented", "ShrinkGrow", "SlideInL", "SlideInR", "TurnOffTiles", "SplitCols", "SlideInB", "SlideInT"].concat([Wysiwyg.NOT_SELECTED])
                        },
                        { name: "transitionTime", type: "number" },
                        { name: "transitionSound", type: "sound", bundles: ["ads_transition"] }
                    ]
                });
            }
            break;
        case HierarchyItem.TYPE.COMPONENT:
            var defaultItems = [
                { name: "position", type: "position" },
                {
                    name: "height", type: "number", value: 100, allowOverride: true
                },
                {
                    name: "width", type: "number", value: 100, allowOverride: true
                },
                { name: "scale", type: "number", allowOverride: true },
                { name: "rotation", type: "number" },
                { name: "delay", type: "number" },
                { name: "zOrder", type: "number" },
                { name: "anchorX", type: "number" },
                { name: "anchorY", type: "number" },
                { name: "useDuringCapture", type: "checkBox" }
            ];
            if (window[hierarchyItem.assetName] && window[hierarchyItem.assetName].PROPERTIES) {
                var propertiesToAdd = [];
                for (var propertyKey in window[hierarchyItem.assetName].PROPERTIES) {
                    var property = window[hierarchyItem.assetName].PROPERTIES[propertyKey];

                    var duplicate = false;
                    for (var i = 0; i < defaultItems.length; i++) {
                        if (property.name === defaultItems[i].name && property.type === defaultItems[i].type) {
                            Object.assign(defaultItems[i], property);
                            duplicate = true;
                            break;
                        }
                    }
                    if (!duplicate) {
                        propertiesToAdd.push(property);
                    }
                }
                propertyGroups.push({
                    name: "Defined in component",
                    properties: propertiesToAdd
                });
            }
            propertyGroups.push({
                name: "Node",
                properties: defaultItems
            });
            break;
        case HierarchyItem.TYPE.SCRIPT:
            var scriptInfo = this.wysiwyg.assets.scripts.filter(function (script) {
                return script.fileName === hierarchyItem.properties.scriptFileName;
            })[0];
            var scriptParameters = scriptInfo.parameters.split("\n").map(function (param) {
                var parts = param.split(" ");
                var res = {
                    name: parts[0] ? parts[0].replace(":", "").trim() : "",
                    type: parts[1] ? parts[1].trim() : ""
                };
                if (parts.length > 2 && parts[2].startsWith("[") && parts[2].endsWith("]")) {
                    var itemsPart = parts[2].replace("[", "").replace("]", "");
                    res.items = itemsPart.split(",").map(function (element) {
                        return element.trim();
                    });
                }

                if (parts.length >= 3 && parts[3] === "multiselect") {
                    res.multiselect = true;
                }
                return res;
            }).filter(function (param) {
                return Object.keys(InspectorView.EDITORS).indexOf(param.type) !== -1;
            });
            propertyGroups.push({
                name: "Script",
                properties: [{ name: "caption", type: "string" }].concat(scriptParameters)
            });
            break;
    }

    propertyGroups.forEach(function (propertyGroup) {
        propertyGroup.vch = this.propertyChanged.bind(this, hierarchyItem);
        propertyGroup.values = cleverapps.clone(hierarchyItem.properties, true) || {};
        propertyGroup.properties = propertyGroup.properties.filter(function (property) {
            return !property.disabled;
        });
    }.bind(this));

    if (hierarchyItem.type !== HierarchyItem.TYPE.DIRECTORY) {
        propertyGroups = propertyGroups.concat(this.getChildrenGroups(hierarchyItem));
    }

    this.trigger("fillProperties", propertyGroups);
};

Inspector.prototype.getChildrenGroups = function (hierarchyItem) {
    var result = [];
    hierarchyItem.children.forEach(function (childItem) {
        var properties = [];
        if (childItem.assetName && window[childItem.assetName] && window[childItem.assetName].PROPERTIES) {
            properties = window[childItem.assetName].PROPERTIES.filter(function (property) {
                return property.bubbleUp;
            });
        } else if (childItem.type === HierarchyItem.TYPE.CLIP && childItem.children.filter(function (clipChild) {
            return clipChild.properties && clipChild.properties.isGame;
        }).length === 0) {
            properties = [{ name: "duration", type: "number" }];
        }

        if (properties.length > 0) {
            result.push({
                name: childItem.assetName || childItem.id,
                vch: this.propertyChanged.bind(this, childItem),
                values: cleverapps.clone(childItem.properties, true) || {},
                properties: properties,
                hierarchyItem: childItem
            });
        }

        result = result.concat(this.getChildrenGroups(childItem));
    }.bind(this));

    return result;
};

Inspector.prototype.propertyChanged = function (hierarchyItem, property, value) {
    var propertyConfig;
    if (hierarchyItem.assetName) {
        propertyConfig = window[hierarchyItem.assetName].PROPERTIES.filter(function (prop) {
            return prop.name === property;
        })[0];
    }

    if (propertyConfig && propertyConfig.dirtyOnChange || ["resolutions", "bg"].indexOf(property) !== -1) {
        this.wysiwyg.wysiwygPreview.forceUpdate = true;
    }

    this.wysiwyg.hierarchy.changeItemProperty(hierarchyItem, property, value);
};

Inspector.prototype.onGroupIconClick = function (propertyGroupName) {
    var groupIndex = this.collapsedGroups.indexOf(propertyGroupName);
    if (groupIndex === -1) {
        this.collapsedGroups.push(propertyGroupName);
    } else {
        this.collapsedGroups.splice(groupIndex, 1);
    }

    this.showItemProperties();
};

Inspector.prototype.onGroupNameClick = function (item, propertyGroupName) {
    if (item) {
        this.wysiwyg.hierarchy.selectItem(item);
    } else {
        this.onGroupIconClick(propertyGroupName);
    }
};

Inspector.FilterForFullVersion = function (propertyDescription) {
    var showFullVersion = cleverapps.wysiwyg.hierarchy.selectedItem && cleverapps.wysiwyg.hierarchy.selectedItem.type === HierarchyItem.TYPE.COMPONENT;
    return showFullVersion || !propertyDescription.fullOnly;
};