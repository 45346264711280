/**
 * Created by Andrey Popov on 9/9/22.
 */

var ClipPreviewBarView = cc.Node.extend({
    ctor: function (clipPreviewBar) {
        this._super();

        this.clipPreviewBar = clipPreviewBar;

        this.clipPreviewBar.onShow = this.show.bind(this);
        this.clipPreviewBar.onHide = this.hide.bind(this);

        this.duration = 0;
        this.clipPreviewBar.onResetTimer = function () {
            this.duration = 0;
        }.bind(this);
        this.clipPreviewBar.onStopTimer = function () {
            this.duration = -1;
        }.bind(this);

        this.createContent();
    },

    createContent: function () {
        var content = this.content = this.createWindow(this.hide.bind(this));
        content.id = "ClipPreviewBarView";

        this.timerButton = this.createButton({
            icon: "",
            onClick: function () {
                this.showPopup(
                    [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
                    function (speed) {
                        return speed.toString();
                    },
                    cc.director.getScheduler().setTimeScale.bind(cc.director.getScheduler()),
                    function (speed) {
                        return speed === cc.director.getScheduler().getTimeScale();
                    }
                );
            }.bind(this),
            class: "tool-button-timer"
        });
        content.appendChild(this.timerButton);
        this.createTimer();

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.download_icon,
            onClick: this.clipPreviewBar.download.bind(this.clipPreviewBar)
        }));

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.replay_icon,
            onClick: this.clipPreviewBar.replay.bind(this.clipPreviewBar)
        }));

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.edit_icon,
            onClick: function () {
                this.clipPreviewBar.edit();
                this.hide();
            }.bind(this)
        }));

        var selectors = document.createElement("div");
        selectors.classList.add("tool-selectors");

        selectors.appendChild(this.createButton({
            text: Messages.getLocalized(this.clipPreviewBar.options.language),
            onClick: function () {
                this.showPopup(
                    this.clipPreviewBar.clipProps.languages,
                    Messages.getLocalized,
                    this.clipPreviewBar.changeLanguage.bind(this.clipPreviewBar),
                    function (lang) {
                        return lang === this.clipPreviewBar.options.language;
                    }.bind(this)
                );
            }.bind(this)
        }));

        selectors.appendChild(this.createButton({
            text: this.clipPreviewBar.options.resolutionName.split("_")[1],
            onClick: function () {
                this.showPopup(
                    this.clipPreviewBar.clipProps.resolutions,
                    function (res) {
                        return res.split("_")[1];
                    },
                    this.clipPreviewBar.changeResolution.bind(this.clipPreviewBar),
                    function (res) {
                        return Wysiwyg.VIDEO_SIZES[res] && res === this.clipPreviewBar.options.resolutionName;
                    }.bind(this)
                );
            }.bind(this)
        }));

        var oldBarView = document.getElementById(content.id);
        if (oldBarView) {
            oldBarView.parentNode.removeChild(oldBarView);
        }

        content.appendChild(selectors);
        document.body.appendChild(content);
    },

    createWindow: function (onClose) {
        var window = document.createElement("div");
        window.classList.add("tool-window");

        var close = document.createElement("span");
        close.classList.add("tool-window-close");
        close.onclick = onClose || this.hideShownWindow.bind(this);
        close.innerText = "\u2715";
        window.appendChild(close);

        return window;
    },

    hideShownWindow: function () {
        if (this.shownWindow) {
            if (this.shownWindow.parentNode) {
                this.shownWindow.parentNode.removeChild(this.shownWindow);
            }
            delete this.shownWindow;
        }
    },

    createButton: function (options) {
        var button = document.createElement("div");
        button.classList.add("tool-button");

        if (options.icon) {
            button.classList.add("tool-button-icon");
            var img = document.createElement("img");
            img.src = options.icon;
            button.appendChild(img);
        }
        if (options.text) {
            var textContent = document.createTextNode(options.text);
            if (options.isInactive) {
                button.classList.add("tool-button-disabled");
            } else {
                button.classList.add("tool-button-text");
            }
            button.appendChild(textContent);
        }

        if (!options.isInactive) {
            button.onclick = options.onClick;
        }
        if (options.class) {
            button.classList.add(options.class);
        }

        return button;
    },

    show: function () {
        this.content.style.display = "block";
    },

    hide: function () {
        this.content.style.display = "none";
    },

    createTimer: function () {
        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                if (this.duration === -1) {
                    return;
                }
                this.duration++;
                var timerValue = parseInt(this.duration);
                timerValue += "";
                timerValue = timerValue.substring(0, timerValue.length - 1) + "." + timerValue.substring(timerValue.length - 1);
                while (timerValue.length < 4) {
                    timerValue = "0" + timerValue;
                }
                this.timerButton.innerText = timerValue;
            }.bind(this))
        )));
    },

    showPopup: function (items, textFormatter, onClick, isInactive) {
        if (items.length === 0) {
            return;
        }

        this.hideShownWindow();

        var popup = this.shownWindow = this.createWindow();
        popup.classList.add("tool-window-popup");

        items.forEach(function (item) {
            var button = this.createButton({
                text: textFormatter(item),
                onClick: function () {
                    this.hideShownWindow();
                    onClick(item);
                }.bind(this),
                isInactive: isInactive(item)
            });
            popup.appendChild(button);
        }.bind(this));
        document.body.appendChild(popup);
    }
});