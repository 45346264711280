/**
 * Created by andrey on 09.02.2021.
 */

var Map2dDecorators = function (decorators) {
    this.decorators = [];
    this.smileDecorators = [];
    this.largeDecorators = [];

    if (decorators) {
        decorators.forEach(function (options) {
            if (!Map2dDecoratorView.DecoratorImageExists(options)) {
                console.log("No frame for decorator '" + options.code + "' at x: " + options.x + ", y: " + options.y);
                return;
            }

            var decorator = new Map2dDecorator(options);
            this.addDecorator(decorator.x, decorator.y, decorator);
        }, this);
    }

    if (!cleverapps.config.adminMode) {
        this.smileInterval = cleverapps.timeouts.setInterval(this.smile.bind(this), 400);
    }
};

Map2dDecorators.prototype.destructor = function () {
    cleverapps.timeouts.clearInterval(this.smileInterval);
};

Map2dDecorators.prototype.getLargeDecorators = function () {
    return this.largeDecorators;
};

Map2dDecorators.prototype.smile = function () {
    if (Math.random() < 0.4) {
        var decorators = this.smileDecorators.filter(function (decorator) {
            return decorator.canSmile();
        });

        var threshold = (5 + decorators.length) / (25 + decorators.length);
        if (decorators.length && Math.random() < threshold) {
            var decorator = decorators[Math.floor(Math.random() * decorators.length)];
            decorator.smile();
        }
    }
};

Map2dDecorators.prototype.addDecorator = function (x, y, decorator) {
    if (!this.decorators[y]) {
        this.decorators[y] = {};
    }
    if (!this.decorators[y][x]) {
        this.decorators[y][x] = [];
    }
    this.decorators[y][x].push(decorator);

    if (decorator.hasSmile()) {
        this.smileDecorators.push(decorator);
    }

    if (decorator.isLarge()) {
        this.largeDecorators.push(decorator);
    }
};

Map2dDecorators.prototype.removeDecorator = function (x, y, decorator) {
    if (this.decorators[y] && this.decorators[y][x]) {
        cc.arrayRemoveObject(this.decorators[y][x], decorator);
    }

    cc.arrayRemoveObject(this.smileDecorators, decorator);
    cc.arrayRemoveObject(this.largeDecorators, decorator);
};

Map2dDecorators.prototype.getDecorators = function (x, y) {
    return this.decorators[y] && this.decorators[y][x];
};

Map2dDecorators.prototype.hasView = function (layerId, x, y) {
    var decorators = this.getDecorators(x, y);
    return decorators && decorators.some(function (decorator) {
        return decorator.getLayerId() === layerId;
    });
};

Map2dDecorators.prototype.addDecoratorsViews = function (x, y) {
    var decorators = this.getDecorators(x, y);
    if (!decorators) {
        return;
    }

    for (var i = 0; i < decorators.length; i++) {
        var decorator = decorators[i];
        if (!decorator.getView()) {
            decorator.createView();
        }
    }
};

Map2dDecorators.prototype.removeDecoratorsViews = function (x, y) {
    var decorators = this.getDecorators(x, y);
    if (!decorators) {
        return;
    }

    var map2dView = Game.currentGame.map.getView();

    for (var i = 0; i < decorators.length; i++) {
        var view = decorators[i].getView();
        if (view && map2dView.canRemoveTile(view)) {
            map2dView.putTileInPool(view);
        }
    }
};