/**
 * Created by mac on 9/25/20
 */

var Administrator = function () {
    this.adminEpisodes = new AdminEpisodes(this);
    this.adminLevels = new AdminLevels(this);

    this.adminDetails = new AdminDetails();

    var levelsFolder = "cleverapps/res/" + cleverapps.config.name + "/levels";
    if (cleverapps.config.type === "match3") {
        levelsFolder = "match3/res/levels";
    }
    if (cleverapps.config.type === "battlefield") {
        levelsFolder = "res/" + cleverapps.config.name + "/levels";
    }
    this.adminGitButtons = new GitButtons(levelsFolder);

    this.simpleMethaDataProvider = new SimpleMethaDataProvider();
    this.log = new AdminLog();

    AdminLevelsChart.PrepareChart();

    if (AdminLanguage.IsAvailable()) {
        var adminLanguages = {};
        cleverapps.config.languages.forEach(function (language) {
            adminLanguages[language] = new AdminLanguage(this, language, cleverapps.settings.language === language);
        }.bind(this));
        this.adminLanguages = adminLanguages;
    }
};

Administrator.prototype.updateGooglesheets = function (callback) {
    cleverapps.RestClient.get("/googlesheetcommands/update", {}, function (result) {
        callback(result.result);
        this.adminGitButtons.checkStatus();
    }.bind(this), function () {
        callback("network error");
    }, 30000);
};

Administrator.prototype.setLanguage = function (language, callback) {
    var oldLanguage = cleverapps.settings.language;

    if (!this.adminLanguages || oldLanguage === language) {
        return;
    }

    cleverapps.meta.display({
        focus: "SetAdminLanguage",
        action: function (f) {
            this.adminLanguages[oldLanguage].unselect();
            this.adminLanguages[language].select();

            cleverapps.settings.setLanguage(language);

            this.adminEpisodes.reset(function () {
                f();
                callback && callback();
            });
        }.bind(this)
    });
};

Administrator.prototype.scrambleGotoRow = function (row) {
    if (!row) {
        return;
    }

    var gid = {
        en: 0,
        ru: 1497319862,
        fr: 2132465089,
        it: 1727839938,
        de: 1616900791,
        es: 797836458,
        nl: 737846155,
        ja: 957913327,
        pl: 858975559,
        ko: 47567512,
        pt: 1954520613
    };

    var url = "https://docs.google.com/spreadsheets/d/1e7g86FZWbm1oYqlg15ex8-TCyraMEoCVBEbyQKIr_XM/edit#gid=%{gid}&range=%{row}:%{row}";
    url = url.replace("%{gid}", gid[cleverapps.settings.language]);
    url = url.replaceAll("%{row}", row);

    var win = window.open(url, "_blank");
    win.focus();
};