/**
 * Created by Andrey Popov on 1/25/21.
 */

var SceneComponent = function (options) {
    Object.assign(this, options);

    if (!SceneComponent.ID) {
        SceneComponent.ID = 0;
    }
    if (options.id && options.id >= SceneComponent.ID) {
        SceneComponent.ID = options.id + 1;
    }

    this.id = options.id || SceneComponent.ID++;

    this.onGetView = function () {};
};

SceneComponent.prototype.handleClick = function () {
    var hierarchy = cleverapps.wysiwyg.hierarchy;
    hierarchy.selectItem(hierarchy.items.filter(function (item) {
        return item.id === this.id;
    }.bind(this))[0]);
};

SceneComponent.prototype.change = function (property, value) {
    cleverapps.wysiwyg.hierarchy.changeItemProperty(cleverapps.wysiwyg.hierarchy.selectedItem, property, value);
    cleverapps.wysiwyg.inspector.showItemProperties();
};