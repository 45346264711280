/**
 * Created by iamso on 24.09.2021
 */

var MicrosoftProducts = {
    mergecraft: {
        gold500: "9P02CM8TVC1C",
        testProduct: "9P0T1P4LN55H",
        gold1800: "9PNDQG96M004",
        gold3800: "9PB87VBQS740",
        gold6000: "9P228BSKP78Q",
        gold25000: "9P529VTFQWZX",
        starterPack0: "9NKDLJ18DHP2",
        starterPack: "9PDZRS5HGPR3",
        starterPack2: "9N1TLJGX49ZF",
        growthFund: "9NNFBQBR57SV",
        subsWeek: "9N4G27WTHCH1",
        subsMonth: "9P2Z1VZ1SM8T",
        subsWeekTest: "9N2DB2KMB81F",
        subsMonthTest: "9NRNP2V46XXQ",
        expeditionPack: "9NPDLB9XBJ81"
    },

    wondermerge: {
        gold500: "9P9XXF7P43SN",
        testProduct: "9P3BLP2SNQS9",
        gold1800: "9PG4HFMDB23S",
        gold3800: "9NBQX8RHXT26",
        gold6000: "9NRTMC4877ZH",
        gold25000: "9NT4KHQC5Q00",
        starterPack2: "9MWGZZ8TWVCR",
        starterPack0: "9PFQ0HSW1GNL",
        starterPack: "9PF13P2BKQ5Q",
        growthFund: "9N6SV05KL4G5",
        expeditionPack: "9NT36TG90VDW"
    },

    tripeaks: {
        testProduct: "9PM68L5FCK7N",
        unlimitedLives: "9NZMSZSZS1LW",
        gold500: "9P3P869D90ZN",
        gold1800: "9N98K4ZLL0Q6",
        gold3800: "9P8SC2QTMQ38",
        gold6000: "9PDVH7XCR1VS",
        gold25000: "9PHNKPMNT1Z7",
        addMoves: "9P2NZ6LS2Z39",
        addMoves1: "9P89SRSW3LP3",
        addMoves2: "9PLT2KZN0X69",
        addMoves3: "9PJD04C78CV2",
        addMoves5: "9N01HFSMBFN1",
        starterPack0: "9N43HCJ6MNS5",
        starterPack: "9NB03GK0NZV0",
        starterPack2: "9PFKGHXHLRZV",
        sweetPack: "9N2X362QH5MD",
        honeyPack: "9PMT7QBV33LJ",
        jamPack: "9NSF2FLPGBC7",
        tastyPack: "9PLSDW0LJZBC",
        epicPack: "9P9Q07PQ3SQT",
        piggybank500: "9P10WBJKKJLC",
        piggybank1800: "9NM7CJ9BL4V0",
        piggybank6000: "9NG74XLRM5KQ",
        piggybank25000: "9NM7DZD46161"
    },

    fairy: {
        testProduct: "9MTT0S8KMMXH",
        gold500: "9P8PVSFNH91G",
        gold1800: "9N9CQ2LQGDT0",
        gold3800: "9NNXVV7KVKLL",
        gold6000: "9PLFD3WD19CW",
        gold25000: "9N7VKLZBSBBQ",
        starterPack0: "9NZ8P9WZVQ8X",
        starterPack: "9PK1RHH7C5GW",
        starterPack2: "9P53T0ZN3MSB",
        growthFund: "9MTD3880J6GF",
        expeditionPack: "9PP9892F62Q0"
    },

    riddles: {
        addMoves1: "9NH0DR69PXSZ"
    },

    zenmatch: {
        testProduct: "9PGQ42CJ3TB6",
        gold500: "9PJ2J2BKJ89Z",
        gold1800: "9P24QFHJFDTB",
        gold3800: "9PCPVWF6JT9G",
        gold6000: "9N08GBFNQPRG",
        gold25000: "9N8JLLLQ88L7",
        starterPack0: "9NH4KKQCZ65T",
        starterPack: "9PGRZP03MWZQ",
        starterPack2: "9NNRKHBND1JT"
    }
};

if (cleverapps.config.debugMode) {
    MicrosoftProducts.mergecraft.testProduct = "9NMP9ZPCH4NQ";
    MicrosoftProducts.wondermerge.testProduct = "9N99RVS5C38G";
    MicrosoftProducts.tripeaks.testProduct = "9PNJ1BD5T6MG";
    MicrosoftProducts.zenmatch.testProduct = "9PN9145P3NF7";
}

MicrosoftDevs = [
    "MS_d1005317-92e5-4a83-11a9-d927877edd08", // spepa
    "MS_af64328e-3371-73c1-367c-92bb6d560ed5",
    "MS_3c940659-7a68-99e0-0ef2-0e4369a5372f",
    "MS_bafafe21-499a-26aa-44dc-f74662bff405",
    "MS_9c82c0be-a17d-a981-11b7-4a85ec0661a4", // Dmitriy
    "MS_089e6329-8b28-c5e4-7b60-1f56b5b4b126",
    "MS_34390e45-0500-febc-5829-27c92a3481b5", // Anna Keda
    "MS_428f0900-1986-514e-b40e-3c8b70235374", // Alexandrina Read
    "MS_459d1c26-a293-f56b-b56c-84f410e3830b", // Bogdan
    "MS_3ff4887f-2eaa-81d3-e99e-d6495ee64e85", // Artem Vinokurov
    "MS_13c53147-883c-b6e0-6c56-2aae159ed6d1" // Sergei Bogdanov
];
