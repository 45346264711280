/**
 * Created by andrey on 25.11.19.
 */

var AdMobAds = function () {
    AdsPlugin.call(this, {
        cantLoadAndPlayInParallel: true
    });

    this.onAdLoadedCallback = {};
    this.savedImpressionCost = undefined;
    this._playCallback = function () {};
};

AdMobAds.prototype = Object.create(AdsPlugin.prototype);
AdMobAds.prototype.constructor = AdMobAds;

AdMobAds.prototype.init = function (callback) {
    console.log("AdMobAds.init");
    this.onInitCallback = callback;
    AdMobPlugin.init();
};

AdMobAds.prototype.onInit = function () {
    console.log("AdMobAds.onInit");
    if (this.onInitCallback) {
        this.onInitCallback();
        delete this.onInitCallback;
    }
};

AdMobAds.prototype._cache = function (name, callback) {
    console.log("AdMobAds._cache");
    this.onAdLoadedCallback[name] = callback;
    AdMobPlugin.cache(name);
};

AdMobAds.prototype.onAdLoaded = function (name) {
    console.log("AdMobAds.onAdLoaded");
    if (this.onAdLoadedCallback[name]) {
        this.onAdLoadedCallback[name](AdsPlugin.CODE_SUCCEED, true);
        this.onAdLoadedCallback[name] = undefined;
    }
};

AdMobAds.prototype.onAdFailedToLoad = function (name) {
    console.log("AdMobAds.onAdFailedToLoad");
    if (this.onAdLoadedCallback[name]) {
        this.onAdLoadedCallback[name](AdsPlugin.CODE_FAILED);
        this.onAdLoadedCallback[name] = undefined;
    }
};

AdMobAds.prototype.onAdClosed = function (name) {
    console.log("AdMobAds.onAdClosed");
    if (name === RewardedAdsManager.REWARDED && this.success) {
        this._playCallback(AdsPlugin.CODE_REWARD);
    } else {
        this._playCallback();
    }
};

AdMobAds.prototype.onReward = function () {
    this.success = true;
};

AdMobAds.prototype.onAdLeftApplication = function () {
};

AdMobAds.prototype.onAdOpened = function () {
    console.log("AdMobAds.onAdOpened");
};

AdMobAds.prototype._playAd = function (name, ad, callback) {
    console.log("AdMobAds._playAd");
    this.success = false;
    this._playCallback = callback;
    AdMobPlugin.show(name);
};

AdMobAds.isAppropriate = function () {
    return typeof AdMobPlugin !== "undefined" && cleverapps.platform.oneOf(Amazon, AndroidPlatform)
        && AdMobPlugin.init && AdMobPlugin.cache && AdMobPlugin.show;
};

AdMobAds.prototype.onPaidEvent = function (impressionCost) {
    this.savedImpressionCost = impressionCost;
};

AdMobAds.prototype.getECPM = function () {
    return this.savedImpressionCost * 1000;
};
