/**
 * Created by slava on 24.03.17.
 */

var VictoryWindow = Window.extend({
    ctor: function (game, boatswain) {
        this.game = game;
        this.boatswain = boatswain;
        this.next = undefined;

        var rewards = new RewardsList(this.game.rewards, {
            event: cleverapps.EVENTS.EARN.LEVEL_REWARD
        });

        rewards.receiveRewards();

        rewards.filter(function (reward) {
            return [GameBase.REWARD_PRIMARY, "lantern"].indexOf(reward.type) === -1;
        });

        this.rewards = rewards;

        var extraPrizes = [];

        if (game.rewards[GameBase.REWARD_PRIMARY] !== undefined) {
            extraPrizes.push(new ExtraPrize("mission", {
                type: game.primaryMission.type,
                amount: game.rewards[GameBase.REWARD_PRIMARY]
            }));
        }

        if (this.game.rewards.lantern !== undefined) {
            extraPrizes.push(new ExtraPrize("lantern", this.game.rewards.lantern));
        }

        if (cleverapps.meta.getType() === Metha.SIMPLE && game.level.isRegular()) {
            extraPrizes.push(new ExtraPrize("simpleStar", cleverapps.simple.amountLevelStars(game.level)));
        }

        if (cleverapps.meta.getType() === Metha.HOMEFIX && game.level.isRegular()) {
            extraPrizes.push(new ExtraPrize("homeStar", cleverapps.home.amountLevelStars(game.level)));
        }

        this.extraPrizes = extraPrizes;

        this._super();
    },

    onWindowLoaded: function () {
        var styles = cleverapps.styles.VictoryWindow;

        this.rumble = cleverapps.isRumble() && cleverapps.meta.getRumble();
        if (this.rumble) {
            this.rumbleRound = this.rumble.getPrevRound();

            if (!this.rumbleRound) {
                cleverapps.throwAsync("can't find round for rumble [" + this.rumble.rounds.map(function (round) {
                    return round.players && round.players.join(",");
                }).join("],[") + "]");
            }
        }

        this._super({
            name: "victorywindow",
            title: this.getTitleText(),
            shareId: !this.rumble && !cleverapps.instantTournament.isInTournament() && "victory",
            styles: styles,
            content: this.createContent(),
            homeButton: !this.rumble && cleverapps.isLevels(),
            noBackground: true,
            tapToContinue: true
        });

        if (this.boatswain) {
            this.boatswain.setIntent(this.boatswain.canNext() ? Boatswain.NEXT_INTENT : Boatswain.RETURN_INTENT);
        }
    },

    onShow: function () {
        var styles = cleverapps.styles.VictoryWindow;

        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.soundDelay || 0),
            new cc.PlaySound(bundles.game.urls.win_effect),
            new cc.PlaySound(bundles.game.urls.win_effect_2)
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(2.0),
            new cc.CallFunc(function () {
                this.runAction(new cc.RepeatForever(
                    new cc.Sequence(
                        new cc.PlaySound(bundles.game.urls.victory_looped_effect),
                        new cc.DelayTime(4.0)
                    )
                ));
            }.bind(this))
        ));

        var delay = 0;
        delay = this.rewardsList.showUp(delay);
        delay -= 100;

        this.extraPrizesList.forEach(function (extraPrizeView) {
            extraPrizeView.showUp(delay);
            delay += 300;
        });

        this.runAction(new cc.Sequence(
            new cc.DelayTime((delay + 300) / 1000),
            new cc.CallFunc(this.addFireworks.bind(this))
        ));

        if (this.customComponent && this.customComponent.showUp) {
            delay += this.customComponent.showUp();
        }

        this._super(delay / 1000 + 0.5);
    },

    getPerson: function () {
        return this.game.outcome === GameBase.OUTCOME_VICTORY && {
            role: "hero",
            emotion: "happy"
        };
    },

    getTitleText: function () {
        if (this.rumble) {
            return this.rumbleRound && this.rumbleRound.isLast()
                ? "KnockoutWindow.Title.Final"
                : Messages.get("KnockoutWindow.Title.Round", { roundId: (this.rumbleRound && this.rumbleRound.id || 0) + 1 });
        }

        if (["riddles", "adventure"].includes(cleverapps.config.name)) {
            var text = (this.game.level.episode.isDailyCup() || this.game.level.episode.isRegular() && cleverapps.user.isPassedAll())
                ? "LevelCompleted" : "VictoryWindow.clear";
            return Messages.get(text, { levelNo: this.game.level.getHumanReadableNumber() });
        }

        if (this.game.outcome === GameBase.OUTCOME_LOST) {
            return "LoseWindow.title";
        }

        return "VictoryWindow.Victory";
    },

    createContent: function () {
        var styles = cleverapps.styles.VictoryWindow;

        var data = this.contentItems = [];

        this.customComponent = undefined;
        if (cleverapps.config.type === "battlefield") {
            this.customComponent = new BattleStatsComponent();
        } else if (cleverapps.config.type === "blocks") {
            this.customComponent = new WindowScoreView();
        } else if (cleverapps.meta.getType() === Metha.LOOP) {
            this.customComponent = new LoopStatsComponent();
        }

        if (this.customComponent) {
            data.push(this.customComponent);
        }

        data.push(this.createRewards());

        var extraPrizes = this.createExtraPrizes();
        if (extraPrizes) {
            data.push(extraPrizes);
        }

        if (this.rumbleRound) {
            data.push(new RoundResultsComponent(this.rumbleRound, { victory: true }));
            data.push(new RoundCountdownComponent(function () {
                if (!this.closed) {
                    this.close();
                }
            }.bind(this)));
        }

        return new cleverapps.Layout(data, {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding,
            margin: styles.margin
        });
    },

    createRewards: function () {
        var styles = cleverapps.styles.VictoryWindow.rewards;

        var columns = cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL ? 3 : 5;

        var rewardsList = this.rewardsList = new RewardsListComponent(this.rewards, {
            columns: columns,
            iconDimensions: styles.iconDimensions,
            iconWrap: styles.iconDimensions,
            textMargin: styles.textMargin,
            font: cleverapps.styles.FONTS.REWARDWINDOW_VALUE_TEXT,
            noShowControls: true
        });
        rewardsList.setLocalZOrder(2);
        return rewardsList;
    },

    createExtraPrizes: function () {
        var styles = cleverapps.styles.VictoryWindow.extra;

        var extraPrizes = this.extraPrizesList = this.extraPrizes.map(function (extraPrize) {
            return new ExtraPrizeView(extraPrize);
        });

        if (!extraPrizes.length) {
            return;
        }

        var extraPrizesList = new cleverapps.Layout(extraPrizes, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        extraPrizesList.setLocalZOrder(2);
        return extraPrizesList;
    },

    addFireworks: function () {
        if (cleverapps.isKnockoutGame()) {
            return;
        }

        if (this.game.outcome !== GameBase.OUTCOME_VICTORY) {
            return;
        }

        var scene = cleverapps.scenes.getRunningScene(this).scene;
        this.fireworks = new Fireworks();
        this.fireworks.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 2);
        scene.addChild(this.fireworks);
    },

    removeFireworks: function () {
        if (this.fireworks) {
            this.fireworks.removeFromParent();
            this.fireworks = undefined;
        }
    },

    beforeCloseAnimation: function (callback) {
        this.rewardsList.receiveRewardsAnimation();

        var rewardsCount = this.rewardsList.targets.length;
        var extraPrizesCount = this.extraPrizesList.length;
        var delay = rewardsCount * 300;

        this.extraPrizesList.forEach(function (extraPrizeView, index) {
            delay += 300;
            extraPrizeView.collectReward(delay, rewardsCount + extraPrizesCount - index);
        });

        delay += 100;

        this.contentItems.forEach(function (item) {
            item.beforeWinClose && item.beforeWinClose();
        });

        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay / 1000),
            new cc.CallFunc(function () {
                cleverapps.aims.whenAllHidden(function () {
                    this.removeFireworks();
                    callback();
                }.bind(this));
            }.bind(this))
        ));
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.styles.VictoryWindow = {
    margin: 40,
    padding: {
        bottom: 40
    },

    rewards: {
        textMargin: 0,

        iconDimensions: {
            width: 130,
            height: 130
        },

        margin: 30,

        padding: {
            x: 0,
            y: 0
        }
    },

    extra: {
        margin: 60
    }
};
