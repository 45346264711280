/**
 * Created by slava on 4/5/17.
 */

cleverapps.MobileFacebook = function () {
    if (this.constructor === cleverapps.MobileFacebook) {
        console.log("Choosing mobile-facebook (sdkbox)");
    }

    this.apiJobs = [];
    this.apiProcessing = false;

    cleverapps.BaseFB.call(this);
};

cleverapps.MobileFacebook.prototype = Object.create(cleverapps.BaseFB.prototype);
cleverapps.MobileFacebook.prototype.constructor = cleverapps.MobileFacebook;

cleverapps.MobileFacebook.prototype.onAuthDetails = function (authDetails) {
    console.log("MobileFacebook.onAuthDetails - " + authDetails);

    authDetails = JSON.parse(decodeWebViewData(authDetails));

    this.accessToken = authDetails.accessToken;
    this.userID = authDetails.userID;
    this.graphDomain = authDetails.graphDomain;
    this.instantUserID = authDetails.instantUserID;

    if (this.graphDomain === "gaming") {
        this.userID = this.instantUserID;

        if (authDetails.userID && authDetails.accessToken) {
            this.accessToken = "instant_" + authDetails.userID + "|" + authDetails.accessToken;
        }
    }
};

cleverapps.MobileFacebook.prototype.onInit = function (authDetails) {
    console.log("MobileFacebook.onInit");

    this.onAuthDetails(authDetails);

    if (this.onInitListener) {
        this.onInitListener();
        delete this.onInitListener;
    }
};

cleverapps.MobileFacebook.prototype.init = function (callback) {
    this.onInitListener = callback;

    FacebookPlugin.init && FacebookPlugin.init();

    this.listeners = {
    };

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        this.deleteAllRequests();
    }.bind(this));

    this.deleteAllRequests();
};

cleverapps.MobileFacebook.prototype.onShared = function (result) {
    if (cc.sys.os === cc.sys.OS_IOS) {
        if (result === 2) { // strange return code in iOS plugin
            result = cleverapps.Social.CODE_SUCCEED;
        }
    }

    if (result === cleverapps.Social.CODE_FAILED) {
        cleverapps.notification.create("ShareError");
    }

    if (this.listeners.onShared) {
        this.listeners.onShared(result);
        delete this.listeners.onShared;
    }
};

cleverapps.MobileFacebook.prototype.onLogin = function (code, authDetails) {
    console.log("MobileFacebook.onLogin");

    this.onAuthDetails(authDetails);

    if (this.listeners.onLogin) {
        this.listeners.onLogin(code, authDetails);
        delete this.listeners.onLogin;
    }
};

cleverapps.MobileFacebook.prototype.onApi = function (key, data) {
    // console.log("ON API " + key);
    // console.log(JSON.stringify(data), decodeWebViewData(data));

    if (this.listeners.onApi) {
        data = decodeWebViewData(data);
        this.listeners.onApi(cleverapps.Social.CODE_SUCCEED, JSON.parse(data));
        delete this.listeners.onApi;
    }

    this.apiProcessing = false;
    this.processApi();
};

cleverapps.MobileFacebook.prototype.onFetchFriends = function (ok, list) {
    // console.log("ON FETCH FRIENDS");
    // console.log("CODE: " + ok);
    // console.log(JSON.stringify(list));

    if (this.listeners.onFetchFriends) {
        if (ok) {
            list = decodeWebViewData(list);
            var decoded = JSON.parse(list);
            if (cc.sys.os === cc.sys.OS_IOS) {
                // console.log("IOS");
                // console.log(JSON.stringify(decoded.data));
                decoded = decoded.data;
            }
            this.listeners.onFetchFriends(cleverapps.Social.CODE_SUCCEED, decoded);
        } else {
            this.listeners.onFetchFriends(cleverapps.Social.CODE_FAILED, list);
        }
        delete this.listeners.onFetchFriends;
    }
};

cleverapps.MobileFacebook.prototype.onInviteFriendsWithInviteIdsResult = function (result, msg) {
    // console.log("ON onInviteFriendsWithInviteIdsResult");
    // console.log(JSON.stringify(arguments));

    if (this.listeners.onInviteFriendsWithInviteIdsResult) {
        this.listeners.onInviteFriendsWithInviteIdsResult(result, msg);
        delete this.listeners.onInviteFriendsWithInviteIdsResult;
    }
};

cleverapps.MobileFacebook.prototype.onRequestInvitableFriends = function (response) {
    // console.log("ON onRequestInvitableFriends");
    // console.log(JSON.stringify(response.paging));

    if (this.listeners.onRequestInvitableFriends) {
        var data = decodeWebViewData(response.data);
        this.listeners.onRequestInvitableFriends(data);
        delete this.listeners.onRequestInvitableFriends;
    }
};

cleverapps.MobileFacebook.prototype.onInviteFriendsResult = function (result, msg) {
    if (cleverapps.config.debugMode) {
        console.log("ON onInviteFriendsResult");
    }

    if (this.listeners.onInviteFriendsResult) {
        this.listeners.onInviteFriendsResult(result, msg);
        delete this.listeners.onInviteFriendsResult;
    }
};

cleverapps.MobileFacebook.prototype.isLoggedIn = function () {
    return this.accessToken && this.userID;
};

cleverapps.MobileFacebook.prototype.getUserID = function () {
    return this.userID;
};

cleverapps.MobileFacebook.prototype.getAccessToken = function () {
    return this.accessToken;
};

cleverapps.MobileFacebook.prototype.login = function (onSuccess, onFailure) {
    this.listeners.onLogin = function (code) {
        if (code === cleverapps.Social.CODE_SUCCEED) {
            cleverapps.SocialManager.onLogin();
            onSuccess();
        } else {
            cleverapps.SocialManager.onFailedLogin();
            onFailure && onFailure();
        }
    };
    FacebookPlugin.login && FacebookPlugin.login(cleverapps.BaseFB.PERMISSIONS);
};

cleverapps.MobileFacebook.prototype.request = function (options, callback) {
    if (callback) {
        callback(cleverapps.Social.CODE_SUCCEED);
    }
};

cleverapps.MobileFacebook.prototype.getShareDialogData = function (data) {
    return {
        type: "link",
        link: this.getShareDialogLink(data),
        title: data.name,
        text: data.description,
        image: data.picture
    };
};

cleverapps.MobileFacebook.prototype.shareDialog = function (data, callback) {
    this.listeners.onShared = callback;
    FacebookPlugin.dialog && FacebookPlugin.dialog(this.getShareDialogData(data));
};

cleverapps.MobileFacebook.prototype.api = function (apiMethod, httpMethod, params, callback) {
    params = params || {};
    if (typeof params === "function") {
        callback = params;
        params = {};
    }

    if (!this.isLoggedIn()) {
        console.log("Not logged in!");
        if (callback) {
            callback(cleverapps.Social.CODE_CANCELLED);
        }
        return;
    }

    if (httpMethod === undefined) {
        httpMethod = "GET";
        callback = function () {};
    } else if (typeof httpMethod === "function") {
        callback = httpMethod;
        httpMethod = "GET";
    }

    // console.log('Add api: ' + apiMethod, httpMethod, JSON.stringify(params));
    this.addApiJob(apiMethod, httpMethod, params, callback);
    this.processApi();
};

cleverapps.MobileFacebook.prototype.addApiJob = function (apiMethod, httpMethod, params, callback) {
    this.apiJobs.push({
        apiMethod: apiMethod, httpMethod: httpMethod, params: params, callback: callback 
    });
};

cleverapps.MobileFacebook.prototype.processApi = function () {
    if (this.apiProcessing || this.apiJobs.length === 0) {
        return;
    }

    this.apiProcessing = true;
    var job = this.apiJobs.shift();
    this.listeners.onApi = job.callback;

    // console.log('ProcessApi: ' + job.apiMethod, job.httpMethod, JSON.stringify(job.params));
    FacebookPlugin.api && FacebookPlugin.api(job.apiMethod, job.httpMethod, job.params, "api");
};

cleverapps.MobileFacebook.prototype.getCode = function () {
    return "facebook";
};

cleverapps.MobileFacebook.APPINVITES_SDKBOX_ERROR = "Unrecognized 'com.facebook.platform.protocol.PROTOCOL_ACTION' extra: 'com.facebook.platform.action.request.APPINVITES_DIALOG'.";