/**
 * Created by slava on 10/8/18
 */

var GameSceneBase = cleverapps.FixedWidthScene.extend({
    ctor: function (options) {
        this.options = options || {};

        this._super(options);

        this.styling = this.level ? GameSceneBase.ChooseStyling(this.level) : {};
    },

    onSceneLoaded: function (sceneName) {
        cleverapps.environment.levelNo = this.level.levelNo;
        cleverapps.environment.episodeNo = this.level.episodeNo;
        cleverapps.environment.setScene(sceneName || cleverapps.Environment.SCENE_GAME);

        var level = this.level;
        var game = this.game = new Game(level, this.options.gameOptions);

        this._super(sceneName || cleverapps.Environment.SCENE_GAME);

        if (levels.cookieJar && levels.cookieJar.shouldBeVisible()) {
            levels.cookieJar.setVisible();
        }

        if (this.game.competition && cleverapps.isKnockoutGame()) {
            this.playersView = new KnockoutPlayersView(this.game.competition, cleverapps.meta.getRumble().getCurrentRound());
            this.scene.addChild(this.playersView);
        }

        if (game.combo && !cleverapps.gameModes.noControls) {
            var comboView = new ComboBarView(game.combo);
            this.scene.addChild(comboView);
        }

        if (cleverapps.config.type === "match3") {
            this.infoPanel = new InfoPanel();
            this.scene.addChild(this.infoPanel);

            this.controlsPanel = new ControlsPanel();
            this.scene.addChild(this.controlsPanel);
        }

        game.on("outcome", this.createListener(this.onChangeOutcome.bind(this)), this);
        this.initSceneBg();

        this.boatswain = new Boatswain(level);

        cc.pool.drainAllPools();
        this.game.initPool();

        if (cleverapps.lives && cleverapps.lives.amount > cleverapps.lives.getMaxLives()) {
            this.game.moreThenMaxRegenLife = true;
        }
        if (cleverapps.config.type !== "merge") {
            this.takeLife(true);
        }
        this.customize();

        if (this.game.levelWithCoins) {
            this.scene.addChild(new RewardGoldView());
        }

        if (this.game.secondaryMission) {
            this.cloversView = new CloversView();
            if (this.cloversView.withinInfoPanel) {
                this.infoPanel.addChild(this.cloversView);
            } else {
                this.scene.addChild(this.cloversView);
            }
        }

        if (this.level.isRegular() || this.level.episode.isBonusWorldLevel()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_START, {
                hash: this.level.hash
            });
        }

        if (this.game.tournament) {
            this.playersView = new TournamentPlayersView(this.game.competition);
            this.scene.addChild(this.playersView);
        }

        cleverapps.gameMessage.onShowMessage = this.createListener(this.onGameMessage.bind(this));
        cleverapps.exclamation.setGame(this.game);

        var exclamationView = this.exclamationView = ExclamationViewBase.Create();
        this.scene.addChild(exclamationView, 20);
        exclamationView.setPositionRound(cleverapps.styles.GameScene.exclamation);

        if (cleverapps.flyingAd) {
            this.scene.addChild(new FlyingAdView());
        }

        this.game.on("stop", this.onStop.bind(this), this);
    },

    onGameMessage: function (message, options) {
        var messageView = new GameMessageView(message, options);
        this.addChild(messageView);
    },

    onStop: function () {
    },

    calcHash: function () {
        return RecursiveHasher(this.level.content, ["episodeNo", "levelNo", "version", "hard", "paint", "extra", "gold", "bundles"]);
    },

    introControls: function () {
        return "panel_info";
    },

    initSceneBg: function () {
        if (!this.background) {
            return;
        }

        var scale = cleverapps.styles.GameScene.startAnimation.scale;
        this.bgScaleMargin = scale;
        var scaleX = this.background.getScaleX() * scale;
        var scaleY = this.background.getScaleY() * scale;
        this.background.setScale(scaleX, scaleY);
        this.scene.setScale(1 / scale);
    },

    playIntro: function (f, silent) {
        this.game.playIntro(f, silent);
    },

    _closeAction: function () {
        if (BackToAdminOrEditorAction.call(this) || !this.game) {
            return;
        }

        cleverapps.meta.display({
            focus: "GameSceneCloseAction",
            action: function (f) {
                this.game.leave(f);
            }.bind(this)
        });
    },

    customize: function () {

    },

    takeLife: function (silent) {
        if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
            if (this.level.episode.isDailyCup() && !cleverapps.dailyCup.withLives()) {
                return;
            }
            cleverapps.lives.take(silent);
        }
    },

    giveLife: function () {
        if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
            if (this.level.episode.isDailyCup() && !cleverapps.dailyCup.withLives()) {
                return;
            }

            if (this.game.moreThenMaxRegenLife || cleverapps.lives.amount < cleverapps.lives.getMaxLives()) {
                cleverapps.lives.give();
            }
        }
    },

    onChangeOutcome: function () {
        cleverapps.audio.fadeOut();
        this.level.isPassed = (this.game.outcome === GameBase.OUTCOME_VICTORY);

        BeforeWinAction.call(this);

        if (this.level.isCurrentLevel()) {
            if (cleverapps.isKnockoutGame()) {
                if (cleverapps.meta.getRumble().outcome === Rumble.OUTCOME_WIN) {
                    cleverapps.user.incLevel();
                }
            } else if (this.game.outcome === GameBase.OUTCOME_VICTORY) {
                cleverapps.user.incLevel();
            }
        }

        this.wantPlayChangeOutcome = true;
        this.playChangeOutcome();
    },

    playChangeOutcome: function () {
        var outcome = this.game.outcome;
        var controls = cleverapps.toArray(this.introControls());
        if (this.level.episode.isBonusRound() && outcome === GameBase.OUTCOME_LOST) {
            controls.push("MenuBarBonusRoundTimerItem");
        }
        if (outcome === GameBase.OUTCOME_VICTORY && cleverapps.config.type === "solitaire") {
            controls.push("opencards");
        }

        cleverapps.meta.display({
            focus: "ChangeOutcome",
            control: controls,

            filter: function () {
                return this.wantPlayChangeOutcome;
            }.bind(this),

            actions: [
                this.game.animateBeforeWin.bind(this.game),
                VictoryMessageAction,
                WinAnimationAction,

                function (f) {
                    this.wantPlayChangeOutcome = false;
                    this.game.stop();
                    f();
                }.bind(this),

                SaveFinalResultsAction.bind(this),
                this.game.animateZoom.bind(this.game),

                function (f) {
                    if (outcome !== GameBase.OUTCOME_VICTORY || cleverapps.user.isPassedAll()) {
                        f();
                        return;
                    }

                    var nextEpisodeNo = cleverapps.user.episode;
                    var episode = new Episode(nextEpisodeNo);
                    episode.loadData(function () {
                        episode.destructor();
                    });

                    f();
                },

                function (f) {
                    this.boatswain.prepareNext();
                    f();
                }.bind(this),

                InterstitialAction.bind(this),
                ShowOutcomeWindow.bind(this),
                UpdateScoreAction.bind(this),

                RateWindowAction.bind(this),
                NewLeagueWindowAction.bind(this),
                MinigameFinishAction.bind(this),

                PurgeOldGameAction.bind(this),

                BoatswainAction.bind(this)
            ]
        });
    },

    getBackgroundStyles: function () {
        var options = cleverapps.skins.getSlot("gameSceneBg") || this.styling;

        var urls = bundles[options.bundle].urls;
        options.backgroundId = urls[options.backgroundId] ? options.backgroundId : "background";
        options.animation = bundles[options.bundle].jsons.background_spine || options.animation;

        return options;
    },

    getAudioStyles: function () {
        return {
            res: cleverapps.skins.getSlot("gameAudio") || this.styling.music,
            fadeIn: cleverapps.styles.GameScene.sound.fadeIn,
            delay: cleverapps.config.wysiwygMode ? 0 : cleverapps.styles.GameScene.sound.delay
        };
    },

    updateSize: function () {
        this._super();

        this.exclamationView && this.exclamationView.setPositionRound(cleverapps.styles.GameScene.exclamation);
    },

    listBundles: function () {
        var level = this.level;
        var styling = this.styling;

        var gameBundles = ["game"];

        if (cleverapps.wideMode.mode === cleverapps.WideMode.HORIZONTAL) {
            gameBundles.push("game_background_horizontal");
        } else {
            gameBundles.push("game_background_vertical");
        }

        if (styling) {
            gameBundles.push(styling.bundle);
        }

        if (level) {
            gameBundles.push(level.getImageBundle());

            var cellSkins = level.getLevelCellSkins();
            if (cellSkins) {
                cleverapps.values(cellSkins).forEach(function (skin) {
                    if (bundles[skin]) {
                        var baseSkin = skin.substring(0, skin.indexOf("_reskin"));
                        var baseInd = gameBundles.indexOf(baseSkin);
                        gameBundles.splice(baseInd, baseInd > 0 ? 1 : 0, skin);
                    }
                });
            }

            if (cleverapps.config.rpg) {
                gameBundles.push("enemies");
            }
        }

        if (cleverapps.config.subtype === "stacks") {
            gameBundles.push("extra_" + cleverapps.settings.language);
        }

        if (bundles["letters_" + cleverapps.settings.language]) {
            gameBundles.push("letters_" + cleverapps.settings.language);
        }

        return gameBundles.filter(function (bundle) {
            return bundle && bundles[bundle];
        });
    },

    animateZoom: function (duration, scale, f) {
        if (!duration) {
            this.scene.setScale(scale);
            f();
            return;
        }

        this.scene.runAction(new cc.Sequence(
            new cc.ScaleTo(duration, scale),
            new cc.CallFunc(f)
        ));
    }
});

GameSceneBase.ChooseStyling = function (level) {
    if (bundles[level.episode.bundleId()] && bundles[level.episode.bundleId()].urls.background) {
        return {
            bundle: level.episode.bundleId()
        };
    }

    if (cleverapps.environment.isEditorScene() && cleverapps.styles.GameScene.STYLING.editor) {
        return cleverapps.styles.GameScene.STYLING.editor;
    }

    var styling = cleverapps.styles.GameScene.STYLING;

    var data = styling.regular[(level.getHumanReadableNumber()) % styling.regular.length];

    if (level.episode.isBonusRound()) {
        data = styling.bonus;
    } else if (level.isHard() || cleverapps.isRumble() && cleverapps.meta.getRumble().getCurrentRound().isLast()) {
        data = styling.hard;
    } else if (level.isTricky()) {
        data = styling.tricky;
    }

    data = cleverapps.clone(data);

    var bundle = data.bundle;
    if (cleverapps.wideMode.mode !== cleverapps.WideMode.VERTICAL) {
        bundle = bundle.replace("_vertical", "_horizontal");
    }
    data.bundle = bundles[bundle] ? bundle : data.bundle.replace("_vertical", "_" + cleverapps.config.orientation);

    if (level.episode.isBonusWorldLevel()) {
        var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD);
        if (mission) {
            data.bundle = mission.bonusWorld.backgroundBundleId();
            data.music = bundles[data.bundle].urls.music;
            data.skin = undefined;
        }
    }

    return data;
};

cleverapps.styles.GameScene = {
    exclamation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    actor: undefined,
    startAnimation: {
        scale: 1.05
    },

    sound: {
        delay: 1
    },

    debugLevelNo: {
        x: { align: "right", dx: -100 },
        y: { align: "bottom", dy: 100 }
    }
};

cleverapps.styles.GameScene.STYLING = {
    bonus: {
        backgroundId: "background_bonus",
        bundle: "game_background_vertical",
        music: bundles.game_music.urls.background_music_game
    },

    hard: {
        backgroundId: "background_hard",
        bundle: "game_background_vertical",
        music: bundles.game_music.urls.background_music_hard
    },

    regular: [
        {
            bundle: "game_background_vertical",
            music: bundles.game_music.urls.background_music_game
        }
    ]
};

if (["wordsoup", "olympics", "crocword"].indexOf(cleverapps.config.name) !== -1) {
    cleverapps.styles.GameScene.STYLING.bonus.patternId = "background_bonus";
}

if (["wordsoup", "olympics", "differences"].indexOf(cleverapps.config.name) !== -1) {
    cleverapps.styles.GameScene.STYLING.hard.patternId = "background_hard";
    cleverapps.styles.GameScene.STYLING.regular[0].patternId = "background";
}

(function () {
    if (bundles.editor && bundles.editor.urls.background) {
        cleverapps.styles.GameScene.STYLING.editor = {
            bundle: "editor"
        };
    }

    var example = cleverapps.styles.GameScene.STYLING.regular[0];

    for (var i = 2; ; i++) {
        if (!bundles.game_background_vertical) {
            if (!bundles.game_background_horizontal.urls["background_" + i]) {
                break;
            }
        } else if (!bundles.game_background_vertical.urls["background_" + i]) {
            break;
        }

        cleverapps.styles.GameScene.STYLING.regular.push({
            backgroundId: "background_" + i,
            bundle: example.bundle,
            music: example.music,
            patternId: example.patternId
        });
    }
}());
