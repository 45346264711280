/**
 * Created by slava on 4/21/17.
 */

cleverapps.MobileMyMailRuSocial = function () {
    console.log("Choosing mobile-MyMailRu");

    cleverapps.MyMailRuSocial.call(this);
};

cleverapps.MobileMyMailRuSocial.prototype = Object.create(cleverapps.MyMailRuSocial.prototype);
cleverapps.MobileMyMailRuSocial.prototype.constructor = cleverapps.MobileMyMailRuSocial;

cleverapps.MobileMyMailRuSocial.prototype.createHeaderAndFooter = function (callback) {
    var req = this.getRequestParameters();

    mailru.callhf = function (data) {
        var header = document.createElement("div");
        header.style.position = "absolute";
        header.style.top = "0";
        header.style.height = "48px";
        header.style.width = "100%";
        header.style.zIndex = "2";
        header.innerHTML = data.header;
        document.body.insertBefore(header, document.body.firstChild);

        var footer = document.createElement("div");
        footer.style.position = "absolute";
        footer.style.bottom = "0";
        footer.style.height = "27px";
        footer.style.width = "100%";
        footer.style.zIndex = "2";
        footer.innerHTML = data.footer;
        document.body.appendChild(footer);

        Resolution.setContainerPadding({
            top: 48,
            bottom: 27
        });

        callback();
    };

    var params = {
        method: "mobile.getCanvas",
        app_id: req.app_id,
        cb: "mailru.callhf",
        session_key: req.session_key,
        mobile_spec: req.mobile_spec
    };
    params = mailru.utils.sign(params);

    mailru.utils.apiOverJSONP(params, "https://appsmail.ru/platform/api");
};

cleverapps.MobileMyMailRuSocial.prototype.inviteFriends = function (options, callback) {
    cleverapps.notification.create("mobileNotAvailable");
    callback(cleverapps.Social.CODE_CANCELLED);
};

cleverapps.MobileMyMailRuSocial.prototype.request = function (options, callback) {
    cleverapps.notification.create("mobileNotAvailable");
    callback(cleverapps.Social.CODE_CANCELLED);
};

cleverapps.MobileMyMailRuSocial.prototype.shareDialog = function (data, callback) {
    cleverapps.notification.create("mobileNotAvailable");
    callback(cleverapps.Social.CODE_CANCELLED);
};