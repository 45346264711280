/**
 * Created by andrey on 11.01.18.
 */

cleverapps.LocalPushes.AndroidWebViewSender = {
    init: function () {
        LocalPushesPlugin.init && LocalPushesPlugin.init();
    },

    sendPush: function (info, timeout) {
        var data = cleverapps.LocalPushes.GetPushData(info);

        var id = info.id;
        var code = info.code;

        var title = data.title;
        var message = data.text;

        if (!message || !title) {
            cleverapps.throwAsync("empty android push - " + id
                + "\ninfo: " + JSON.stringify(info) + "\ndata: " + JSON.stringify(data)
                + "\nMessages length: " + Messages && Messages.dictionary && Object.keys(Messages.dictionary).length);
            return;
        }

        if (!LocalPushesPlugin.scheduleNotification) {
            return;
        }

        var result = LocalPushesPlugin.scheduleNotification(title, message, timeout, id, code);
        if (result) {
            cleverapps.throwAsync("Android send push error " + result);
        }
    },

    cancelPush: function (info) {
        if (!LocalPushesPlugin.cancelNotification) {
            return;
        }

        var result = LocalPushesPlugin.cancelNotification(info.id, info.code);
        if (result) {
            cleverapps.throwAsync("Android cancel push error " + result);
        }
    }
};