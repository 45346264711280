/**
 * Created by iamso on 12.05.21
 */

var TaskManager = function () {
    this.localWaitingList = [];
    this.scheduleUpdates();
};

TaskManager.prototype.addTask = function (type, params) {
    var task = new WorkerTask(type, params);

    this.isScheduledTask(task, function (status) {
        if (status === TaskManager.STATUS.SCHEDULED) {
            cleverapps.notification.create("Already scheduled task " + task.code);
            return;
        }

        task.start(function () {
            this.localWaitingList.push(task);
            if (params.onCreated) {
                params.onCreated();
            }
        }.bind(this));
    }.bind(this));
};

TaskManager.prototype.removeTask = function (task) {
    var ind = this.localWaitingList.indexOf(task);
    if (ind === -1) {
        return;
    }

    this.localWaitingList.splice(ind, 1);
};

TaskManager.prototype.isScheduledTask = function (task, callback) {
    if (!(task instanceof WorkerTask)) {
        task = new WorkerTask(task.type, task.params);
    }

    if (this.localWaitingList.some(function (waiting) {
        return waiting.type === task.type && waiting.code === task.code && waiting.options.project === task.options.project;
    })) {
        callback(TaskManager.STATUS.SCHEDULED);
        return;
    }

    var query = cleverapps.RestClient.createQueryString({
        code: task.code,
        type: task.type,
        project: task.options.project
    });

    cleverapps.RestClient.get(
        TaskManager.GetRestFullPath("/tasks/ispending?" + query), 
        {},
        function (response) {
            callback(response ? TaskManager.STATUS.SCHEDULED : TaskManager.STATUS.READY);
        },
        function (err) {
            console.log("Taskmanager ispending fail", err);
            callback(TaskManager.STATUS.NO_INFO);
        }
    );
};

TaskManager.prototype.scheduleUpdates = function () {
    setInterval(this.update.bind(this), TaskManager.CHECK_INTERVAL);
};

TaskManager.prototype.update = function () {
    var ids = this.localWaitingList.map(function (task) {
        return task.dbId;
    });

    if (!ids.length) {
        return;
    }

    cleverapps.RestClient.get(
        TaskManager.GetRestFullPath("/tasks/getfinished?" + cleverapps.RestClient.createQueryString({ ids: ids })), 
        {},
        function (response) {
            var completed = [];

            this.localWaitingList.forEach(function (waitingTask) {
                if (response[waitingTask.dbId]) {
                    completed.push(waitingTask);
                }
            });

            completed.forEach(function (task) {
                task.complete(response[task.dbId]);
            });
        }.bind(this),
        function (err) {
            console.log("Taskmanager update fail", err);
        }
    );
};

TaskManager.prototype.taskStatus = function (taskCode, callback) {
    cleverapps.RestClient.get(
        TaskManager.GetRestFullPath("/tasks/list"),
        {},
        function (response) {
            var found = false;
            response.forEach(function (task, index) {
                if (task.code === taskCode && !found) {
                    found = true;

                    var result = {};
                    result.status = task.status === "finished" ? TaskManager.STATUS.READY : TaskManager.STATUS.SCHEDULED;
                    result.info = task.result || "";
                    if (task.status === "finished") {
                        result.info = "FINISHED. " + result.info;
                    } else if (index > 0) {
                        result.info += "WAITING. Index in queue: " + index;
                    } else {
                        result.info += "IN PROGRESS";
                    }

                    if (result.info.indexOf(" - Message: ") !== -1) {
                        result.info = result.info.split(" - Message: ")[1];
                    }
                    if (result.info.indexOf(" - Line: ") !== -1) {
                        result.info = result.info.split(" - Line: ")[0];
                    }

                    if (result.info.length > 140) {
                        result.info = result.info.substring(0, 140) + "...";
                    }

                    callback(result);
                }
            });

            if (!found) {
                callback();
            }
        },
        function (err) {
            console.log("Taskmanager taskStatus fail", err);
            callback();
        }
    );
};

TaskManager.GetRestFullPath = function (path) {
    return (cleverapps.isLocalhost() ? "http://localhost:3200" : "https://stands.labsystech.ru/taskmanager-rest") + path;
};

TaskManager.CHECK_INTERVAL = 10 * 1000;

TaskManager.STATUS = {
    NO_INFO: "No info",
    SCHEDULED: "Scheduled",
    READY: "Ready"
};