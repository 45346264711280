/**
 * Created by Andrey Popov on 20.10.20
 */

var SimpleMethaDataProvider = function () {
    this.reset();
};

SimpleMethaDataProvider.prototype.reset = function () {
    this.data = undefined;
};

SimpleMethaDataProvider.prototype.getData = function () {
    if (this.data) {
        return this.data;
    }

    if (!["scramble", "tripeaks"].includes(cleverapps.config.name)) {
        this.data = {};
        return this.data;
    }

    var data = {};

    var addBgLevel = function (episode, level, bgIndex) {
        data[episode + "_" + level] = {
            text: "BG" + bgIndex,
            icon: bundles.admin.frames.feature_bg,
            tooltip: Messages.has("background_name_" + bgIndex) ? Messages.get("background_name_" + bgIndex) : ""
        };
    };

    addBgLevel(0, 0, 0);

    var episodesToCheck = cleverapps.episodes._calcAmountEpisodes(true);

    var stars = 0;
    var current = 0;

    for (var episodeNo = 0; episodeNo < episodesToCheck && current < cleverapps.simple.bgsAmount; episodeNo++) {
        var bundleName = Episode.BundleId(episodeNo);
        var bundle = bundles[bundleName];
        for (var levelNo = 0; levelNo < Episode.LEVELS_PER_EPISODE; levelNo++) {
            if (stars >= cleverapps.simple.getRequiredStars(current)) {
                stars = 0;
                current++;

                addBgLevel(episodeNo, levelNo, current);
            }

            stars += bundle.episode.levels && bundle.episode.levels[levelNo].hard ? 3 : 1;
        }
    }

    this.data = data;
    return this.data;
};

SimpleMethaDataProvider.prototype.getEvents = function (episodeNo, levelNo) {
    var data = this.getData();

    var event = data[episodeNo + "_" + levelNo];
    return event ? [event] : [];
};
