/**
 * Created by Andrey Popov on 3/15/21.
 */

var MinimalDialogue = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options || {};
        this.setAnchorPoint2();
        this.setLocalZOrder(20);

        if (this.options.text) {
            this.items = [this.options.text];
        }

        if (this.options.items) {
            this.items = this.options.items;
        }
    },

    display: function () {
        this.createContent({ person: true });

        if (!this.calculatePosition()) {
            this.createContent({ person: false, maxDimensions: this.calcMaxDimensions() });

            if (!this.calculatePosition()) {
                this.logError();
            }
        }

        if (this.debugInfo) {
            this.debugInfo.show();
        }

        if (!this.options.hided) {
            this.showUp();
        }
    },

    updatePosition: function () {
        this.calculatePosition();
    },

    logError: function () {
        var frameSize = cc.view.getFrameSize();

        var msg = "Could not position minimal dialog.";

        var rects = this.listRects();

        msg = msg + " rects: " + JSON.stringify(rects);

        var dialogRects = this.listDialogueRects();

        msg = msg + " dialogRects: " + JSON.stringify(dialogRects);

        var itemsInfo = this.items.map(function (item, index) {
            var contentItem = this.contentItems[index];
            if (typeof item === "string") {
                return JSON.stringify({
                    text: item,
                    fontSize: contentItem.getFontSize(),
                    font: contentItem.font,
                    checkFont: document.fonts && document.fonts.check(contentItem.font),
                    width: contentItem.width,
                    height: contentItem.height
                });
            }

            var data = {};
            if (item.debugInfo) {
                data = item.debugInfo;
            }

            return JSON.stringify(Object.assign(data, {
                width: item.width,
                height: item.height,
                scale: item.scale
            }));
        }, this);

        msg = msg + ", items: " + itemsInfo.join(" + ");

        msg = msg + ", frame size:" + frameSize.width + " by " + frameSize.height;

        var scene = cleverapps.scenes.getRunningScene(this);
        msg = msg + ", scene size:" + scene.width + " by " + scene.height;

        msg = msg + ", resolutionScale:" + resolutionScale;

        msg = msg + ", dpr:" + window.devicePixelRatio;

        if ((scene.width + scene.height) > 800) {
            cleverapps.throwAsync(msg);
        }
    },

    removeContent: function () {
        if (this.options.items) {
            this.options.items.forEach(function (item) {
                if (item.parent) {
                    item.removeFromParent(true);
                    item.setPosition(0, 0);
                }
            });
        }

        if (this.bg) {
            this.bg.removeAllChildren();
            this.bg.removeFromParent(true);
        }

        if (this.personNode) {
            this.personNode.removeFromParent();
            this.personNode = undefined;
        }
    },

    createContent: function (options) {
        this.removeContent();

        var styles = cleverapps.styles.MinimalDialogue;

        var personSize = cc.size(0, 0);
        var personNode;

        if (options.person) {
            personNode = this.personNode = this.createPerson();
            personSize = cc.size(personNode.width + styles.person.margin, personNode.height);
        }

        var scene = cleverapps.scenes.getRunningScene(this);
        var availableRect = cc.rectSubPadding(scene.getBoundingBox(), cc.padding(styles.padding));
        availableRect.width = Math.min(availableRect.width, styles.maxWidth);

        var availableBgRect = cc.rect(
            availableRect.x,
            availableRect.y,
            availableRect.width - personSize.width,
            Math.min(availableRect.height, styles.text.maxHeight)
        );

        if (options.maxDimensions) {
            availableBgRect.width = Math.min(availableRect.width, options.maxDimensions.width);
            availableBgRect.height = Math.min(availableRect.height, options.maxDimensions.height);
        }

        var textPadding = cc.padding(styles.text.padding);
        var availableTextRect = cc.rectSubPadding(availableBgRect, textPadding);

        var items = this.items.map(function (item) {
            if (typeof item === "string") {
                var font = cleverapps.wideMode.resizeFont(cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT);
                var text = cleverapps.UI.generateOnlyText(item, font);
                text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
                if (text.width > availableTextRect.width) {
                    text.setDimensions(availableTextRect.width, 0);
                }
                return text;
            }

            cleverapps.UI.fitToBox(item, {
                width: availableTextRect.width
            });

            return item;
        });

        var height = items.reduce(function (height, item) {
            return height + item.height * item.scaleY;
        }, 0);

        if (height > availableTextRect.height) {
            items.forEach(function (item) {
                var itemHeight = item.height * availableTextRect.height / height;
                if (item.fitTo) {
                    item.fitTo(availableTextRect.width, itemHeight);
                } else {
                    cleverapps.UI.fitToBox(item, {
                        width: availableTextRect.width,
                        height: itemHeight
                    });
                }
            });
        }

        this.contentItems = items;
        var direction = this.options.direction !== "undefined" ? this.options.direction : cleverapps.UI.VERTICAL;
        this.content = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: direction
        });

        var bgRect = cc.rectAddPadding(cc.rect(0, 0, this.content.width, this.content.height), textPadding);
        this.bg = cleverapps.UI.createScale9Sprite(bundles.dialogues_minimal.frames.bg_minimal_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.bg.setAnchorPoint(0, 0.5);
        this.bg.setContentSize2(bgRect);
        this.bg.setPositionRound({ align: "right" }, { align: "center" });

        this.bg.addChild(this.content);
        if (styles.text.arrow && personNode) {
            this.textBgArrow = new cc.Sprite(bundles.dialogues_minimal.frames.mini_arrow_bg_png);
            this.bg.addChild(this.textBgArrow);
            this.textBgArrow.setPositionRound(styles.text.arrow);
        }

        this.content.setPositionRound(
            textPadding.left + this.content.width / 2,
            textPadding.bottom + this.content.height / 2
        );

        this.bg.setCascadeOpacityEnabledRecursively(true);
        this.bg.setOpacity(0);
        this.content.setCascadeOpacityEnabledRecursively(true);

        this.setContentSize2(personSize.width + bgRect.width, Math.max(personSize.height, bgRect.height));

        this.addChild(this.bg);

        if (personNode) {
            this.addChild(personNode);
        }

        this.setVisible(false);
    },

    createPerson: function () {
        var styles = cleverapps.styles.MinimalDialogue;
        
        var personNode = new cc.Node();
        personNode.setAnchorPoint2();
        personNode.setContentSize2(styles.person);
        personNode.setPositionRound({ align: "left" }, { align: "center" });

        var role = "hero";
        if (this.options.person && PersonsLibrary.getMinimalJson(this.options.person)) {
            role = cleverapps.skins.getSlot(this.options.person) || this.options.person;
        }
        var json = PersonsLibrary.getMinimalJson(role);

        this.personAnimation = new cleverapps.Spine(json);
        this.personAnimation.setPositionRound(personNode.width / 2, personNode.height / 2);
        if (this.options.onCompleteAnimation) {
            this.personAnimation.setCompleteListener(this.options.onCompleteAnimation);
        }
        personNode.addChild(this.personAnimation);

        var skinSlot = role + "_minimal";
        var skin = cleverapps.skins.getSlot(skinSlot);
        if (skin) {
            this.personAnimation.setSkin(skin);
        }
        return personNode;
    },

    calculatePosition: function () {
        var winSize = cleverapps.UI.getSceneSize();
        var styles = cleverapps.styles.MinimalDialogue;

        if (this.options.forcePosition) {
            if (this.options.forcePosition === Dialogue.POSITIONS.TOP) {
                this.setPositionRound(winSize.width / 2, winSize.height - this.height / 2 - styles.positions.top.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.TOP_LOWER) {
                this.setPositionRound(winSize.width / 2, winSize.height - this.height / 2 - styles.positions.topLower.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.BOTTOM) {
                this.setPositionRound(winSize.width / 2, this.height / 2 + styles.positions.bottom.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.CENTER) {
                this.setPositionRound(winSize.width / 2, winSize.height / 2);
            } else {
                this.setPositionRound(this.options.forcePosition);
            }
        } else if (this.options.rects && this.options.rects.length) {
            return this.calcDynamicPosition();
        } else {
            this.setPositionRound(winSize.width / 2, this.height / 2 + styles.positions.bottom.y);
        }
        return true;
    },

    listRects: function () {
        var styles = cleverapps.styles.MinimalDialogue;
        var offset = cleverapps.wideMode.mode === cleverapps.WideMode.VERTICAL ? styles.targetOffsetMobile : styles.targetOffset;

        var rects = this.options.rects.slice();

        var restoreProgress = cleverapps.scenes.getRunningScene(this).restoreProgress;
        if (restoreProgress) {
            rects.push(restoreProgress.getGlobalBoundingBox());
        }

        return rects.map(function (rect) {
            return cc.rect(rect.x - offset, rect.y - offset, rect.width + offset * 2, rect.height + offset * 2);
        });
    },

    fitIntoScene: function (point) {
        var sceneOffsetY = cleverapps.UI.getSceneOffsetY();
        var sceneSize = cleverapps.UI.getSceneSize();
        return cc.p(
            Math.max(this.width / 2, Math.min(point.x, sceneSize.width - this.width / 2)),
            Math.max(-sceneOffsetY + this.height / 2, Math.min(point.y, sceneSize.height + sceneOffsetY - this.height / 2))
        );
    },

    listAllPositions: function (rects) {
        var positions = [];
        for (var i = 0; i < rects.length; i++) {
            var rect = rects[i];
            var slots = this.calcPositionSlots(rect);

            for (var j = 0; j < slots.length; j++) {
                positions.push(this.fitIntoScene(slots[j]));
            }
        }

        return positions;
    },

    calcRectArea: function (rect) {
        var threshold = 2;

        return (rect.width > threshold ? rect.width : 0) * (rect.height > threshold ? rect.height : 0);
    },

    listDialogueRects: function (options) {
        options = options || {};

        var dialogueRects = [
            this.bg.getGlobalBoundingBox()
        ];

        if (this.personNode && !options.withoutPerson) {
            dialogueRects.unshift(this.personNode.getGlobalBoundingBox());
        }

        return dialogueRects;
    },

    calcIntersection: function (dialogueRects, rects) {
        var maxIntersectionArea = 0;
        var maxIntersectionRect;

        dialogueRects.forEach(function (dialogueRect) {
            rects.forEach(function (rect) {
                var intersection = cc.rectIntersection(dialogueRect, rect);

                var area = this.calcRectArea(intersection);
                if (area > maxIntersectionArea) {
                    maxIntersectionArea = area;
                    maxIntersectionRect = intersection;
                }
            }, this);
        }, this);

        if (maxIntersectionArea > 0) {
            return {
                area: maxIntersectionArea,
                rect: maxIntersectionRect
            };
        }
    },

    calcMaxDimensions: function () {
        var rects = this.listRects();
        var dialogueRect = this.listDialogueRects({ withoutPerson: true }).reduce(function (dialogueRect, rect) {
            return cc.rectUnion(dialogueRect, rect);
        });

        var cumulative = cc.size();
        var center = cc.rectGetCenter(dialogueRect);

        rects.forEach(function (rect) {
            var intersection = cc.rectIntersection(dialogueRect, rect);
            var intCenter = cc.rectGetCenter(intersection);

            var height = intersection.y - dialogueRect.y + intersection.height;
            if (intCenter.y > center.y) {
                height = dialogueRect.height - intersection.y - dialogueRect.y;
            }

            var width = intersection.x - dialogueRect.x + intersection.width;
            if (intCenter.x > center.x) {
                width = dialogueRect.width - intersection.x - dialogueRect.x;
            }

            cumulative.width = width > cumulative.width ? width : cumulative.width;
            cumulative.height = height > cumulative.height ? height : cumulative.height;
        });

        if (cumulative.width / dialogueRect.width < cumulative.height / dialogueRect.height) {
            cumulative.height = 0;
        } else {
            cumulative.width = 0;
        }

        return cc.size(
            dialogueRect.width - cumulative.width,
            dialogueRect.height - cumulative.height
        );
    },

    pickVariant: function (variants) {
        var scene = cleverapps.scenes.getRunningScene(this);

        var center = cc.p(scene.width / 2, scene.height / 2);

        var invalidVariants = variants.filter(function (variant) {
            return variant.intersection;
        });

        var validVariants = variants.filter(function (variant) {
            return !variant.intersection;
        });
        if (validVariants.length > 0) {
            validVariants.sort(function (a, b) {
                return cc.pDistance(a.position, center) - cc.pDistance(b.position, center);
            });

            return validVariants[0];
        }

        invalidVariants.sort(function (a, b) {
            return a.intersection.area - b.intersection.area;
        });

        return invalidVariants[0];
    },

    calcDynamicPosition: function () {
        var scene = cleverapps.scenes.getRunningScene(this);

        var rects = this.listRects();

        var variants = [];

        this.listAllPositions(rects).forEach(function (position) {
            var localPosition = this.getParent().convertToNodeSpace(scene.convertToWorldSpace(position));
            this.setPositionRound(localPosition);

            var dialogueRects = this.listDialogueRects();

            variants.push({
                localPosition: localPosition,
                position: position,
                dialogueRects: dialogueRects,
                intersection: this.calcIntersection(dialogueRects, rects)
            });
        }, this);

        var pickedVariant = this.pickVariant(variants);

        if (cleverapps.flags.debugMinimalDialogue) {
            if (!this.debugInfo) {
                this.debugInfo = new MinimalDialogueDebug(this);
            }

            this.debugInfo.addVariants(variants);
            this.debugInfo.setPickedVariant(pickedVariant);
        }

        this.setPositionRound(pickedVariant.localPosition);

        return !pickedVariant.intersection;
    },

    calcPositionSlots: function (rect) {
        var left = { x: rect.x - this.width / 2, y: rect.y + rect.height / 2 },
            right = { x: rect.x + rect.width + this.width / 2, y: rect.y + rect.height / 2 },
            up = { x: rect.x + rect.width / 2, y: rect.y + rect.height + this.height / 2 },
            down = { x: rect.x + rect.width / 2, y: rect.y - this.height / 2 };

        var sceneSize = cleverapps.UI.getSceneSize();

        var x = rect.x + rect.width / 2, y = rect.y + rect.height / 2;
        if (x < sceneSize.width / 6 || x > sceneSize.width / 6 * 5) {
            return [left, right, up, down];
        }

        if (y < sceneSize.height / 6 || y > sceneSize.height / 6 * 5) {
            return [up, down, left, right];
        }

        return [left, right, up, down];
    },

    showUp: function () {
        if (this.shown) {
            return;
        }
        this.shown = true;
        this.stopAllActions();
        this.bg.stopAllActions();
        this.content.stopAllActions();
        this.bg.setOpacity(0);
        var styles = cleverapps.styles.MinimalDialogue;
        var showAnimation = styles.showAnimation === "bubble" ? this.bubbleShowUp.bind(this) : this.fadeInShowUp.bind(this);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.delay !== undefined ? this.options.delay : 0.5),
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.dialogues.urls.dialogue_effect);
                if (this.personAnimation) {
                    this.personAnimation.setVisible(true);
                    this.personAnimation.setAnimationAndIdleAfter("minimal_showup", "talk_minimal");
                }
            }.bind(this)),
            new cc.CallFunc(showAnimation)
        ));
    },

    fadeInShowUp: function () {
        this.content.setOpacity(0);
        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.dialogues.urls.dialogue_effect);
                if (this.personAnimation) {
                    this.personAnimation.setVisible(true);
                    this.personAnimation.setAnimationAndIdleAfter("minimal_showup", "talk_minimal");
                }
                this.bg.runAction(new cc.Sequence(
                    new cc.FadeIn(0.3),
                    new cc.CallFunc(function () {
                        this.content.runAction(new cc.FadeIn(0.6));
                    }.bind(this))
                ));
            }.bind(this))
        ));
    },

    bubbleShowUp: function () {
        var basePosition = this.bg.getPosition();
        var startPosition = cc.p(basePosition.x - 100, basePosition.y);
        this.bg.setPosition(startPosition);
        this.bg.setScale(0.46);
        this.bg.setOpacity(0);

        this.bg.runAction(new cc.Spawn(
            new cc.FadeIn(0.17),
            new cc.MoveTo(0.3, basePosition)
        ));

        this.bg.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 1.16, 1.26).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.2, 1).easing(cc.easeOut(1))
        ));
    },

    hide: function (callback, silent) {
        if (!this.shown) {
            callback && callback();
            return;
        }

        this.stopAllActions();
        this.bg.stopAllActions();
        this.content.stopAllActions();

        if (silent) {
            this.bg.setOpacity(0);

            this.personAnimation.setVisible(false);

            this.shown = false;

            callback && callback();

            return;
        }

        if (this.personAnimation) {
            this.personAnimation.setAnimation(0, "minimal_hide", false);
            this.personAnimation.setCompleteListenerOnce(function () {
                this.personAnimation.setVisible(false);
            }.bind(this));
        }

        if (cleverapps.styles.MinimalDialogue.showAnimation === "bubble") {
            var basePosition = this.bg.getPosition();
            var startPosition = cc.p(basePosition.x - 100, basePosition.y);
            this.bg.runAction(new cc.Sequence(
                new cc.DelayTime(0.13),
                new cc.MoveTo(0.3, startPosition)
            ));
            this.bg.runAction(new cc.Sequence(
                new cc.DelayTime(0.13),
                new cc.ScaleTo(0.17, 1.16, 1.26).easing(cc.easeIn(1)),
                new cc.ScaleTo(0.17, 0).easing(cc.easeOut(1)),
                new cc.CallFunc(function () {
                    this.bg.setPosition(basePosition);
                }.bind(this))
            ));
        } else {
            this.bg.runAction(new cc.FadeOut(0.3));
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                callback && callback();
            })
        ));

        this.shown = false;
    },

    remove: function (callback, silent) {
        this.hide(function () {
            this.removeFromParent();

            callback && callback();
        }.bind(this), silent);
    }
});

cleverapps.styles.MinimalDialogue = {
    targetOffset: 30,
    targetOffsetMobile: 10,

    maxWidth: 1000,
    margin: 10,

    padding: {
        x: 10,
        y: 10
    },

    person: {
        width: 240,
        height: 240,
        margin: 0
    },

    text: {
        maxHeight: 450,

        padding: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 30
        }
    },

    positions: {
        topLower: {
            y: 200
        },
        top: {
            y: 100
        },
        bottom: {
            y: 20
        }
    }
};

if (cleverapps.config.debugMode) {
    var MinimalDialogueDebug = function (minimal) {
        minimal.addCleaner(this.hide.bind(this));

        this.minimal = minimal;
        this.rects = minimal.listRects();
        this.variants = [];
        this.pickedVariant = undefined;
    };

    MinimalDialogueDebug.prototype.addVariants = function (variants) {
        this.variants = this.variants.concat(variants);
    };

    MinimalDialogueDebug.prototype.setPickedVariant = function (pickedVariant) {
        this.pickedVariant = pickedVariant;
    };

    MinimalDialogueDebug.prototype.show = function () {
        this.currentNodes = [];

        this.currentStep = this.variants.indexOf(this.pickedVariant);
        this.drawCurrentStep();

        this.keyboardListener = cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,
            onKeyPressed: function (keyCode) {
                if (keyCode === cc.KEY.right) {
                    if (this.currentStep < this.variants.length - 1) {
                        this.currentStep++;
                        this.drawCurrentStep();
                    }
                }
                if (keyCode === cc.KEY.left) {
                    if (this.currentStep > 0) {
                        this.currentStep--;
                        this.drawCurrentStep();
                    }
                }
            }.bind(this)
        }, this.minimal);
    };

    MinimalDialogueDebug.prototype.drawCurrentStep = function () {
        this.clearCurrentStep();

        var step = this.variants[this.currentStep];

        this.rects.forEach(function (rect) {
            this.currentNodes.push(this.drawRect(rect, new cc.Color(0, 0, 255, 255)));
        }, this);

        step.dialogueRects.forEach(function (rect) {
            var color = step.intersection && step.intersection.rect ? new cc.Color(255, 0, 0, 255) : new cc.Color(0, 255, 0, 255);
            this.currentNodes.push(this.drawRect(rect, color));
        }, this);

        if (step.intersection && step.intersection.rect) {
            this.currentNodes.push(this.drawRect(step.intersection.rect, new cc.Color(255, 0, 0, 255), new cc.Color(255, 0, 0, 255)));
        }
    };

    MinimalDialogueDebug.prototype.clearCurrentStep = function () {
        this.currentNodes.forEach(function (node) {
            node.removeFromParent();
        });

        this.currentNodes = [];
    };

    MinimalDialogueDebug.prototype.drawRect = function (rect, color, fillColor) {
        fillColor = fillColor || new cc.Color(0, 0, 0, 0);

        var node = new cc.DrawNode();
        node.setAnchorPoint(0, 0);
        node.setContentSize2(rect.width, rect.height);
        node.setPositionRound(rect.x, rect.y);
        node.drawRect(cc.p(0, 0), cc.p(rect.width, rect.height), fillColor, 3, color);
        node.setLocalZOrder(1000);
        cleverapps.scenes.getRunningScene().addChild(node);

        return node;
    };

    MinimalDialogueDebug.prototype.hide = function () {
        cc.eventManager.removeListener(this.keyboardListener);
        delete this.keyboardListener;

        this.clearCurrentStep();
    };
}