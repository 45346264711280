/**
 * Created by r4zi4l on 09.09.2022
 */

Snapshots.extractors = {
    abtest: function () {
        return cleverapps.abTest.groups;
    },

    scene: function () {
        if (Game.currentGame) {
            return {
                episodeNo: Game.currentGame.level.episodeNo,
                levelNo: Game.currentGame.level.levelNo
            };
        }
    },

    flags: function () {
        return Object.keys(cleverapps.gameModes).filter(function (flagName) {
            return cleverapps.gameModes[flagName];
        });
    },

    users: function () {
        var data = CustomSyncers.extractors.users();
        if (data) {
            data.forcedVisited = data.visited;
        }
        return data;
    },

    placements: function () {
        if (cleverapps.placements) {
            cleverapps.placements.save();
        }

        return cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.PLACEMENTS);
    },

    games: function () {
        if (GameBase.isGameWithSaves()) {
            return CustomSyncers.extractors.games();
        }

        var data = Game.currentGame && Game.currentGame.getInfo();
        if (data) {
            return Object.assign(data, {
                level: Game.currentGame.level.levelNo,
                episode: Game.currentGame.level.episodeNo
            });
        }
    },

    travelBook: function () {
        var data = {};
        TravelBook.PAGES.forEach(function (page) {
            var pageData = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.TRAVEL_BOOK_EVENTS + page.id);
            if (pageData) {
                data[page.id] = pageData;
            }
        });

        return data;
    },

    travelBookAttention: function () {
        var data = {};
        TravelBook.PAGES.forEach(function (page) {
            var attention = cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.TRAVEL_BOOK_ATTENTION + page.id);
            if (attention) {
                data[page.id] = attention;
            }
        });

        return data;
    },

    clan: function () {
        if (!cleverapps.userClan || !cleverapps.userClan.id) {
            return;
        }

        var data = cleverapps.userClan.getInfo();
        data.players = cleverapps.userClan.players.map(function (player) {
            return player.getInfo();
        });
        data.messages = cleverapps.clanMessages.listMessages().map(function (message) {
            return message.getInfo();
        });

        return data;
    },

    packs: function () {
        var packs = cleverapps.packManager;
        if (!packs) {
            return;
        }

        return {
            used: packs.used,
            whenBuy: packs.whenBuy,
            lastShown: packs.lastShown
        };
    },

    ads: function () {
        return RewardedAdsManager.IsEnabled();
    }
};

Snapshots.importers = {
    abtest: function (serverData) {
        cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.ABTEST, serverData);
        cleverapps.abTest.updateGroups();
    },

    users: function (serverData) {
        serverData.forcedVisited = serverData.forcedVisited || Date.now();

        CustomSyncers.importerData.users(serverData, true);
        cleverapps.abTest.updateGroups();
    },

    settings: function (serverData) {
        if (!serverData.language || cleverapps.config.wysiwygMode) {
            return;
        }

        cleverapps.settings.setLanguage(serverData.language);
        cleverapps.settings.updateDependents();

        Messages.preload(serverData.language, function () {});
    },

    flags: function (serverData) {
        if (!serverData) {
            return;
        }

        Object.keys(cleverapps.gameModes).forEach(function (flagName) {
            cleverapps.gameModes[flagName] = serverData.indexOf(flagName) !== -1;
        });
        cleverapps.gameModes.save();
    },

    placements: function (data) {
        if (data) {
            cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.PLACEMENTS, data);
        }

        if (cleverapps.placements) {
            cleverapps.placements.load();
        }
    },

    travelBook: function (data) {
        TravelBook.PAGES.forEach(function (page) {
            if (data[page.id]) {
                cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.TRAVEL_BOOK_EVENTS + page.id, data[page.id]);
            } else {
                cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.TRAVEL_BOOK_EVENTS + page.id);
            }
        });
    },

    travelBookAttention: function (data) {
        TravelBook.PAGES.forEach(function (page) {
            if (data[page.id]) {
                cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.TRAVEL_BOOK_ATTENTION + page.id, data[page.id]);
            } else {
                cleverapps.DataLoader.remove(cleverapps.DataLoaderTypes.TRAVEL_BOOK_ATTENTION + page.id);
            }

            var travelBookPage = cleverapps.travelBook.pages.find(function (p) {
                return p.id === page.id;
            });
            if (travelBookPage) {
                travelBookPage.setAttentionEvent(!!data[page.id]);
            }
        });
    },

    clan: function () {
        cleverapps.userClan.load();
        cleverapps.clanMessages.loadMessages();
    },

    packs: function (data) {
        var packs = cleverapps.packManager;
        if (!packs || !data) {
            return;
        }

        packs.used = data.used;
        packs.whenBuy = data.whenBuy;
        packs.lastShown = data.lastShown;
        packs.save(false);
    },

    ads: function (isEnabled) {
        if (!isEnabled) {
            cleverapps.rewardedAdsManager.plugin = undefined;
        }
    },

    subscription: function (data) {
        data[Subscription.calcSource()] = cleverapps.values(data)[0];
        cleverapps.subscription.updateInfo(data);
    }
};

Snapshots.registerBySlots = function (name, extractor, importer) {
    CustomSyncers.SLOTS.forEach(function (slot) {
        this.extractors[name + slot] = function () {
            return extractor(slot);
        };

        this.importers[name + slot] = function (serverData) {
            return importer(slot, serverData);
        };
    }.bind(this));

    this.extractors[name + "_snapshot"] = extractor;
    this.importers[name + "_snapshot"] = importer;

    extractor.useSlots = true;
    importer.useSlots = true;
};

Snapshots.registerBySlots("units", function (slot) {
    if (!cleverapps.unitSavers) {
        return;
    }
    var unitSaver = cleverapps.unitSavers.getInstance(slot);
    var unitSyncer = unitSaver.unitSyncer;
    var result = {};

    for (var syncerCode in unitSyncer.syncers) {
        var familySyncer = unitSyncer.syncers[syncerCode];

        result[familySyncer.code] = unitSyncer.getInfo(syncerCode);
    }

    return result;
}, function (slot, serverData) {
    if (!cleverapps.unitSavers) {
        return;
    }
    var unitSaver = cleverapps.unitSavers.getInstance(slot);
    var unitSyncer = unitSaver.unitSyncer;
    unitSyncer.updateInfo(serverData, true);
});

Snapshots.registerBySlots("map2d", function (slot) {
    return cleverapps.DataLoader.load(cleverapps.DataLoaderTypes.MAP2D + slot);
}, function (slot, serverData) {
    cleverapps.DataLoader.save(cleverapps.DataLoaderTypes.MAP2D + slot, serverData);
});
