/**
 * Created by vladislav on 25.02.2020
 */

var WardRobeMissionLogic = function (mission) {
    this.mission = mission;

    this.refreshSkin();
};

WardRobeMissionLogic.prototype.onUpdateMission = function () {
    this.refreshSkin();
};

WardRobeMissionLogic.prototype.refreshSkin = function () {
    var features = cleverapps.eventManager.listCurrentFeatures();

    var event = ["memorial", "xmas", "valentines"].filter(function (event) {
        return features.indexOf(event) !== -1;
    })[0];

    var bundle = event || "person_hero";

    var json = bundles[bundle].jsons.hero_json;
    var skin = "skin_" + this.getCurrentStage(this.mission).stage;
    cleverapps.Spine.updateSkin(json, skin);
    cleverapps.user.trigger("heroSkinUpdated", skin);
};

WardRobeMissionLogic.prototype.skipNext = function () {
    return this.mission.isRunning() && this.stageToShow;
};

WardRobeMissionLogic.prototype.getCurrentStage = function () {
    var stage = 0;
    var result = this.mission.result;
    while (result >= WardrobeWindow.stages[stage]) {
        result -= WardrobeWindow.stages[stage];
        stage++;
    }

    if (stage === WardrobeWindow.stages.length) {
        return {
            stage: stage,
            finished: true
        };
    }

    return {
        stage: stage,
        progress: result
    };
};

WardRobeMissionLogic.prototype.getProgressForStage = function (stage) {
    var result = 0;
    WardrobeWindow.stages.slice(0, stage).forEach(function (amount) {
        result += amount;
    });
    return result;
};

WardRobeMissionLogic.prototype.runReceiveDialogue = function (f) {
    var stage = this.getCurrentStage().stage - 1;
    var data = [];
    var dictionaryKey = "WardrobeDialogue.stage" + stage + ".text";
    for (var i = 0; Messages.has(dictionaryKey + i); i++) {
        data.push({ text: Messages.get(dictionaryKey + i) });
    }

    if (!data.length) {
        f();
        return;
    }

    var dialogue = new Dialogue(data, { showUp: true });
    var closeDialogue = function () {
        if (dialogue.stage >= dialogue.data.length - 1) {
            dialogue.close();
        }
    };
    dialogue.on("afterClose", f);
    dialogue.on("buttonClicked", closeDialogue);
    dialogue.on("screenClicked", function () {
        if (!dialogue.typing) {
            closeDialogue();
        }
    });

    new DialogueView(dialogue);
};

WardRobeMissionLogic.prototype.givePrize = function (stage, f) {
    var prize = WardrobeWindow.prizes[stage];

    if (prize) {
        RewardWindow.createWardRobeWindow(prize.prize);
        cleverapps.windows.onceNoWindowsListener = f;
    } else {
        f();
    }
};

WardRobeMissionLogic.prototype.processEvent = function () {
    var oldStage = this.getCurrentStage().stage;
    this.mission.update(1);
    var stage = this.getCurrentStage().stage;
    if (stage !== oldStage) {
        this.stageToShow = stage;
    }
};
