/**
 * Created by mac on 4/8/18
 */

var VKAds = function () {
    AdsPlugin.call(this);
};

VKAds.prototype = Object.create(AdsPlugin.prototype);
VKAds.prototype.constructor = VKAds;

VKAds.isAppropriate = function () {
    return cleverapps.platform instanceof VKPlatform;
};

VKAds.prototype.init = function (callback) {
    cleverapps.social.whenInitialized("vkads", callback);
};

VKAds.prototype._cache = function (name, callback) {
    vkBridge.send("VKWebAppCheckNativeAds", {
        "ad_format": name === RewardedAdsManager.REWARDED ? "reward" : "interstitial"
    }).then(function (data) {
        if (data.result) {
            console.log("Vk ads: have ads!");
            callback(AdsPlugin.CODE_SUCCEED, true);
        } else {
            console.log("Vk ads: NO ads!");
            callback(AdsPlugin.CODE_FAILED);
        }
    });
};

VKAds.prototype._playAd = function (name, ad, callback) {
    vkBridge.send("VKWebAppShowNativeAds", {
        ad_format: name === RewardedAdsManager.REWARDED ? "reward" : "interstitial"
    }).then(function (data) {
        if (data.result && name === RewardedAdsManager.REWARDED) {
            callback(AdsPlugin.CODE_REWARD);
        } else {
            callback();
        }
    }).catch(function (error) {
        console.log(error);
        callback();
    });
};

VKAds.prototype.getECPM = function () {
    return RewardedAdsManager.eCPM.VK;
};
