/**
 * Created by Ivan on 31.05.2023
 */

var AdsFinger = AdsSprite.extend({
    ctor: function (properties) {
        var clipProps = cleverapps.wysiwyg.hierarchy.getSelectedClip().properties;
        properties.image = clipProps.finger;
        this._super(properties);
    }
});

AdsFinger.BUNDLES = ["ads_finger"];