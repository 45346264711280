/**
 * Created by mac on 1/30/20
 */

cleverapps.SkinManager.SKINS.valentines = {
    bundle: "valentines",
    localization: {
        heroes: {
            "Missions.TulpanTournament.Title": "Valentine.Missions.TulpanTournament.Title",
            "Missions.TulpanTournament.Rules1": "Valentine.Missions.TulpanTournament.Rules1",
            "Missions.TulpanTournament.AnimationMainTextWin": "Valentine.Missions.TulpanTournament.AnimationMainTextWin",
            "Missions.TulpanTournament.AnimationMainTextLose": "Valentine.Missions.TulpanTournament.AnimationMainTextLose",
            "Missions.TulpanTournament.AnimationMainTextInfo0": "Valentine.Missions.TulpanTournament.AnimationMainTextInfo0",
            "Missions.TulpanTournament.AnimationMainTextInfo1": "Valentine.Missions.TulpanTournament.AnimationMainTextInfo1"
        },
        riddles: {
            "Missions.TulpanTournament.Title": "Valentine.Missions.TulpanTournament.Title",
            "Missions.TulpanTournament.Rules1": "Valentine.Missions.TulpanTournament.Rules1",
            "Missions.TulpanTournament.AnimationMainTextWin": "Valentine.Missions.TulpanTournament.AnimationMainTextWin",
            "Missions.TulpanTournament.AnimationMainTextLose": "Valentine.Missions.TulpanTournament.AnimationMainTextLose",
            "Missions.TulpanTournament.AnimationMainTextInfo0": "Valentine.Missions.TulpanTournament.AnimationMainTextInfo0",
            "Missions.TulpanTournament.AnimationMainTextInfo1": "Valentine.Missions.TulpanTournament.AnimationMainTextInfo1",
            "AddMovesWindow.mission.TulpanTournament": "Valentine.AddMovesWindow.mission.TulpanTournament",
            "AddMovesWindow.missionGiveUp.TulpanTournament": "Valentine.AddMovesWindow.missionGiveUp.TulpanTournament"
        },
        scramble: {
            "Missions.LetterTournament.Title": "Valentine.Missions.LetterTournament.Title",
            "MissionRulesWindow.LetterTournament.text2": "Valentine.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Valentine.MissionRulesWindow.LetterTournament.text3"
        },
        wordsoup: {
            "Missions.LetterTournament.Title": "Valentine.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Valentine.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Valentine.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Valentine.MissionRulesWindow.LetterTournament.text3"
        },
        crocword: {
            "Missions.LetterTournament.Title": "Valentine.Missions.LetterTournament.Title",
            "Missions.LetterTournament.Rules1": "Valentine.Missions.LetterTournament.Rules1",
            "MissionRulesWindow.LetterTournament.text2": "Valentine.MissionRulesWindow.LetterTournament.text2",
            "MissionRulesWindow.LetterTournament.text3": "Valentine.MissionRulesWindow.LetterTournament.text3"
        }
    },
    slots: {
        localization: function (code) {
            var localization = cleverapps.SkinManager.SKINS.easter.localization;
            return localization && localization[cleverapps.config.name] && localization[cleverapps.config.name][code];
        },
        removeMissions: function () {
            if (cleverapps.config.name === "riddles" || cleverapps.config.name === "heroes") {
                return [Mission.TYPE_COLLECT_MARK, Mission.TYPE_COMBO];
            }
        },
        menuBarItem: function () {
            if (cleverapps.config.name === "crocword") {
                return cleverapps.styles.SceneDecors.menuBarItemValentines;
            }

            if (["wordsoup", "riddles", "heroes", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.SceneDecors.menuBarItemValentines;
            }
        },
        sceneControlButton: function () {
            if (["crocword", "wordsoup", "riddles", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.SceneDecors.sceneControlButtonValentines;
            }
        },
        progressViewDecor: function () {
            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.SceneDecors.progressViewDecorValentines;
            }
        },
        sidebarIcon: function () {
            if (["crocword", "heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.valentines.frames.valentines_icon_bg_png;
            }
        },
        toolbarDecors: function () {
            if (["crocword", "wordsoup", "heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.SceneDecors.toolbarViewValentines;
            }
        },
        windowDecors: function (options) {
            var decors = options.decors && cleverapps.clone(options.decors, true) || {};

            if (["wordsoup", "crocword", "heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                if (!(options.window instanceof LeadersWindow)) {
                    decors.valentines_decor_right = true;
                    decors.valentines_decor_right2 = true;
                }
                decors.valentines_decor_left = true;
                decors.valentines_decor_left2 = true;
                if (["heroes", "wordsoup", "riddles", "crocword"].indexOf(cleverapps.config.name) !== -1) {
                    decors.valentines_decor_hearts = true;
                    decors.valentines_decor_top_1 = true;
                    decors.valentines_decor_top_2 = true;
                    decors.valentines_decor_top_3 = true;
                    decors.valentines_decor_heart_top_1 = true;
                    decors.valentines_decor_heart_top_2 = true;
                    decors.valentines_decor_heart_top_3 = true;
                    decors.valentines_decor_heart_top_4 = true;

                    decors.gears = false;
                    decors.star = false;
                    decors.present = false;
                }
            }

            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                decors.valentines_decor_bottom = true;
                decors.valentines_decor_top = true;
                if (options.window instanceof LeadersWindow) {
                    decors.valentines_decor_bottom = false;
                }
                if (options.window instanceof MissionWindow) {
                    decors.valentines_decor_top = false;
                }
                if (options.window instanceof ExtraWindow) {
                    decors.valentines_decor_top = false;
                }
                if (options.window instanceof VictoryWindow) {
                    decors.valentines_decor_top = false;
                }
            }

            return decors;
        },
        missionIcon: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.valentines.frames.mission_icon_png;
            }

            if (["riddles", "heroes"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_BURN_NEARBY) {
                return bundles.valentines.frames.mission_icon_png;
            }
        },
        missionStar: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                return bundles.valentines.frames["mission_star" + options.type + "_png"];
            }
        },
        missionRulesWindow: function (options) {
            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_LETTER) {
                return bundles.valentines.jsons.letter_rules_json;
            }
        },
        missionWindowHero: function () {
            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                return cleverapps.styles.Valentines.heroTitleAnimation;
            }
        },
        spine: function (options) {
            var spines = {};
            if (["heroes", "riddles"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.tulpan_tournament.jsons.json] = bundles.valentines.jsons.json;
                spines[bundles.main.jsons.tournament_tulpan_icon_json] = bundles.valentines.jsons.icon_json;
            }

            if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.letter_tournament.jsons.mission_window_json] = bundles.valentines.jsons.mission_window_json;
            }

            if (["crocword", "wordsoup", "scramble"].indexOf(cleverapps.config.name) !== -1) {
                spines[bundles.sidebar.jsons.mission_letter_icon_json] = bundles.sidebar.jsons.valentine_letter_json;
            }

            if (["crocword", "wordsoup"].indexOf(cleverapps.config.name) !== -1 && options.type === Mission.TYPE_WARDROBE) {
                spines[bundles.sidebar.jsons.wardrobe_icon_json] = bundles.sidebar.jsons.wardrobe_valentine_icon_json;
            }

            return spines[options.json] ? spines[options.json] : options.json;
        },
        gameSceneBg: function () {
            if (cleverapps.config.name === "wordsoup") {
                return {
                    bundle: "valentines",
                    patternId: "valentines_pattern_bg_png"
                };
            }
        },
        gameAudio: function () {
        },
        windowTitle: function () {
            if (cleverapps.config.name === "heroes") {
                return bundles.valentines.frames.valentines_window_title_bg_png;
            }
        },
        missionWindowTitleFont: function () {
            return cleverapps.styles.FONTS.VALENTINE_MISSION_WINDOW_TITLE_TEXT;
        },
        wardrobeWindowBanner: function () {
            return bundles.valentines.frames.wardrobe_window_banner;
        },
        wardrobeBundle: function () {
            return "valentines";
        }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VALENTINE_MISSION_WINDOW_TITLE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: {
            direction: cc.size(0, -5),
            blur: 1,
            color: new cc.Color(106, 2, 88, 255)
        }
    }
});
