/**
 * Created by mac on 5/23/20
 */

/**
 * In order for the canvas to be recorded without artifacts in the Chrome
 * browser, do the following:
 * 1. chrome://flags --> Accelerated 2D canvas --> Disabled
 *
 * To get sound into the video, you need to redirect the signal from the
 * speaker to the microphone. You need to do the following:
 * 1. sudo apt-get install pavucontrol
 * 2. launch PulseAudio Volume Control when the game starts
 * 3. Recording tab --> Google Chrome input: RecordStream from --> YOUR SPEAKER
 *
 * Use the ffmpeg to convert webm to mp4 and overlaying background music
 * version required >= 4.2.2
 */

cleverapps.Recorder = {
    init: function () {
        if (cleverapps.Recorder.canvas) {
            return;
        }

        cleverapps.Recorder.canvas = document.getElementById("GameCanvas");
    },

    start: function (params) {
        cleverapps.Recorder.init();

        navigator.mediaDevices.getUserMedia({
            // https://www.w3.org/TR/mediacapture-streams/#def-constraint-channelCount
            audio: {
                echoCancellation: false,
                autoGainControl: false,
                noiseSuppression: false,
                latency: 0,
                channelCount: 2
            }
            // video: true
        }).then(function (stream) {
            var canvasStream = cleverapps.Recorder.canvas.captureStream(60);
            var mixedStream = new MediaStream([
                canvasStream.getVideoTracks()[0],
                stream.getAudioTracks()[0]
            ]);

            cleverapps.Recorder.recorder = new MediaRecorder(mixedStream, {
                mimeType: cleverapps.Recorder.MIME_TYPE,
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 8400000
            });

            cleverapps.Recorder.recordedBlobs = cleverapps.Recorder.recordedBlobs || [];

            cleverapps.Recorder.recorder.ondataavailable = function (event) {
                cleverapps.Recorder.recordedBlobs.push(event.data);
            };

            cleverapps.Recorder.recorder.onstop = function (event) {
                console.log("Recorder stopped: ", event);

                canvasStream.getTracks().forEach(function (track) {
                    track.stop();
                });

                stream.getTracks().forEach(function (track) {
                    track.stop();
                });
            };

            cleverapps.Recorder.recorder.onstart = function (event) {
                console.log("Recorder started: ", event);

                if (params.frameCapture) {
                    var startCaptureFrames = function () {
                        cleverapps.Recorder.captureFrames(params.frameCapture);
                    };

                    if (params.frameCapture.amount === 1) {
                        setTimeout(startCaptureFrames, params.frameCapture.frequency || 0);
                    } else {
                        startCaptureFrames();
                    }
                }
            };

            cleverapps.Recorder.recorder.start(10);
        }).catch(function (error) {
            console.log(error.message);
        });
    },

    pause: function (f) {
        if (cleverapps.Recorder.recorder && cleverapps.Recorder.recorder.state === "recording") {
            cleverapps.Recorder.recorder.pause();
            console.log("Recorder paused...");
        }
        f && f();
    },

    resume: function (f) {
        if (cleverapps.Recorder.recorder && cleverapps.Recorder.recorder.state === "paused") {
            cleverapps.Recorder.recorder.resume();
            console.log("Recorder resumed...");
        }
        f && f();
    },

    stop: function (f) {
        if (cleverapps.Recorder.recorder) {
            cleverapps.Recorder.recorder.stop();

            var blob = new Blob(cleverapps.Recorder.recordedBlobs, {
                type: cleverapps.Recorder.MIME_TYPE
            });

            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (event) {
                if (cleverapps.Recorder.data && cleverapps.Recorder.data.data) {
                    cleverapps.Recorder.data.data.push(event.target.result);
                } else {
                    cleverapps.Recorder.data = { data: [event.target.result] };
                }
                cleverapps.Recorder.ext = ".webm";
                f();
            };
        } else {
            f();
        }
    },

    captureFrames: function (params) {
        var cnt = 0;

        var capture = function () {
            if (cleverapps.Recorder.recorder.state === "recording") {
                cnt++;

                if (cnt < params.amount) {
                    setTimeout(capture, params.frequency);
                }

                var framesDir = params.name + "_frames";
                var prefix = cnt === 1 ? "" : cnt + "_";
                prefix = (params.filenamePrefix || "") + prefix;
                var frameName = prefix + params.name + ".jpg";
                var fullName = cnt === 1 ? params.path + frameName : params.path + framesDir + "/" + frameName;
                var data = {
                    data: cleverapps.Recorder.canvas.toDataURL("image/jpeg", cleverapps.Recorder.IMAGE_QUALITY),
                    processed: cnt,
                    total: params.amount || 1,
                    frameCapture: true
                };

                this.post("/recorder/" + fullName, data, undefined, undefined, 120000);
            }
        }.bind(this);

        capture();
    },

    downloadRecordedData: function (url, name) {
        var element = document.createElement("a");
        element.href = url;
        element.download = name;
        element.style.display = "none";

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    screenshot: function (name) {
        cleverapps.Recorder.init();
        this.downloadRecordedData(cleverapps.Recorder.canvas.toDataURL("image/jpeg", cleverapps.Recorder.IMAGE_QUALITY), name + "_image.jpg");
    },

    saveVideo: function (name) {
        var blob = new Blob(cleverapps.Recorder.recordedBlobs, {
            type: cleverapps.Recorder.MIME_TYPE
        });
        this.downloadRecordedData(URL.createObjectURL(blob), name + ".webm");
    },

    save: function (path, name, options, onSuccess) {
        var fullName = path + "/" + name + cleverapps.Recorder.ext;

        var data = Object.assign(cleverapps.clone(cleverapps.Recorder.data), options || {});

        this.post("/recorder/" + fullName, data, onSuccess, undefined, 120000);

        cleverapps.Recorder.data = undefined;
        cleverapps.Recorder.ext = undefined;
    },

    runTransform: function (options) {
        options.crf = 23;

        this.post("/recorder/transform", options);
    },

    terminate: function () {
        this.post("/recorder/terminate", {});
    },

    post: function (path, data, onSuccess, onError, options) {
        if (cleverapps.config.workerTaskId && path.indexOf("http") !== 0) {
            path = "http://localhost:3201" + path;
        }

        data.project = cleverapps.config.name;
        data.workerTaskId = cleverapps.config.workerTaskId;

        cleverapps.RestClient.post(path, data, onSuccess, onError, options);
    }
};

cleverapps.Recorder.MIME_TYPE = "video/webm;codecs=\"vp8,opus\"";
cleverapps.Recorder.IMAGE_QUALITY = 0.9;