/**
 * Created by slava on 4/12/17
 */

var Resolution = {
    showCanvas: function () {
        if (this._shown) {
            return;
        }
        this._shown = true;

        var gameContainer = cc.container;
        gameContainer.style.display = "block";

        var gameCanvas = document.getElementById("GameCanvas");
        gameCanvas.style.display = "block";
        gameCanvas.style.left = null;
        gameCanvas.style.top = null;

        var cocosLoading = document.getElementById("cocosLoading");
        if (cocosLoading) {
            document.body.removeChild(cocosLoading);
        }

        this.setupDesignResolution();
    },

    setContainerPadding: function (padding) {
        this._containerPadding = cc.padding(padding);
        this.setupDesignResolution();
    },

    getContainerPadding: function () {
        return cc.padding(this._containerPadding);
    },

    setupResolutionScale: function () {
        resolutionScale = resolutions[cleverapps.settings.calcResolution()].scale;

        cleverapps.UI.updateSizes(resolutionScale);

        cleverapps.UI.selectSkinResources(cleverapps.settings.calcResolution());

        cleverapps.skins && cleverapps.skins.prepareBundlesSkins();

        cleverapps.home && cleverapps.home.setupResolution();
        cleverapps.farm && cleverapps.farm.setupResolution();
    },

    calculateSceneSize: function (windowSize) {
        var c = windowSize.width / windowSize.height;

        var SCENE_HEIGHT = cleverapps.styles.SCENE_HEIGHT;
        var SCENE_WIDTH = SCENE_HEIGHT * c;

        if (SCENE_WIDTH < cleverapps.styles.SCENE_MIN_WIDTH) {
            SCENE_WIDTH = cleverapps.styles.SCENE_MIN_WIDTH;
            SCENE_HEIGHT = SCENE_WIDTH / c;
        }

        if (SCENE_WIDTH > cleverapps.styles.SCENE_MAX_WIDTH) {
            SCENE_WIDTH = cleverapps.styles.SCENE_MAX_WIDTH;
        }

        return cc.size(SCENE_WIDTH, SCENE_HEIGHT);
    },

    getFrameSize: function () {
        return cc.view._isRotated ? cc.size(window.innerHeight, window.innerWidth) : cc.size(window.innerWidth, window.innerHeight);
    },

    getDeviceOrientation: function () {
        var size = this.getFrameSize();
        return size.width >= size.height ? cc.ORIENTATION_LANDSCAPE : cc.ORIENTATION_PORTRAIT;
    },

    getOrientation: function () {
        var frameSize = this.getFrameSize();

        var isMobile = cleverapps.platform.oneOf(MobileOK, MobileMyMailRu, SPMobage)
            || cc.sys.isMobile && !cc.sys.isNative;
        var isTab = isMobile && (Math.min(frameSize.width, frameSize.height) > Math.max(frameSize.width, frameSize.height) * 0.65);

        if (!isMobile || isTab && cleverapps.config.orientation === "horizontal") {
            return cc.ORIENTATION_AUTO;
        }

        return cleverapps.config.orientation === "horizontal" ? cc.ORIENTATION_LANDSCAPE : cc.ORIENTATION_PORTRAIT;
    },

    setupDesignResolution: function (options) {
        options = options || {};

        var frameSize = this.getFrameSize();
        this.currentFrameSize = frameSize;

        // OK call cc.view.setResizeCallback after return to our frame with values width = 1 (invalid value), height = 700, it breaks everything below
        // fotostrana request window width 300 height 700
        if (frameSize.width < cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE
            || frameSize.height < cleverapps.SETUP_RESOLUTION_MIN_FRAME_SIZE) {
            return;
        }

        var orientation = this.getOrientation();
        if (orientation !== cc.view._orientation) {
            cc.view.setOrientation(orientation);
            this.setupDesignResolution(options);
            return;
        }

        if (options.sceneMaxWidthOverride) {
            cleverapps.styles.SCENE_MAX_WIDTH = options.sceneMaxWidthOverride;
        }

        if (options.width) {
            frameSize.width = options.width;
        }

        if (options.height) {
            frameSize.height = options.height;
        }

        if (frameSize.width && frameSize.height) {
            var data = {
                width: frameSize.width,
                height: frameSize.height
            };

            cleverapps.info.setKeyValue("resolution", data);
        }

        var padding = this.getContainerPadding();

        var containerRect = cc.view._isRotated ? cc.rect(
            Math.round(padding.top),
            Math.round(padding.left),
            Math.round(frameSize.width - padding.top - padding.bottom),
            Math.round(frameSize.height - padding.left - padding.right)
        ) : cc.rect(
            Math.round(padding.left),
            Math.round(padding.top),
            Math.round(frameSize.width - padding.left - padding.right),
            Math.round(frameSize.height - padding.top - padding.bottom)
        );

        var sceneSize = this.calculateSceneSize(containerRect);

        var policy = new cc.ResolutionPolicy(new ProportionalToFrameWithBorders(containerRect), cc.ContentStrategy.NO_BORDER);
        cc.view.setDesignResolutionSize(sceneSize.width, sceneSize.height, policy);
        if (this.oldRotated !== cc.view._isRotated) {
            this.oldRotated = cc.view._isRotated;
            console.log("isRotated changed - " + cc.view._isRotated);
        }

        cleverapps.wideMode.refresh();
        cleverapps.bannerAd.onResize();

        this.changeSceneSize(sceneSize);

        if (cc.view._isRotated && !options.adjustRotated) {
            Resolution.setupDesignResolution({ adjustRotated: true });
        }
    },

    changeSceneSize: function (sceneSize) {
        var scene = cleverapps.scenes.getRunningScene();
        if (!scene || cc.sizeEqualToSize(sceneSize, scene)) {
            return;
        }

        scene.setContentSize2(sceneSize);

        if (scene.isReadyToResize()) {
            scene.updateSize();
            scene.updatePosition();
        }

        var force = cleverapps.forces && cleverapps.forces.getRunningForce();
        force && force.beforeResize && force.beforeResize();
        Game.currentGame && Game.currentGame.beforeResize();

        var q = [], i;
        scene.performRecursive(function (node) {
            if (node.isReadyToResize && !node.isReadyToResize()) {
                return;
            }

            if (node.updateSize || node.hideHandler || node.updatePosition) {
                q.push(node);
            }
        });

        for (i = q.length - 1; i >= 0; i--) {
            q[i].updateSize && q[i].updateSize();
        }

        for (i = q.length - 1; i >= 0; i--) {
            q[i].updatePosition && q[i].updatePosition();
        }

        for (i = q.length - 1; i >= 0; i--) {
            q[i].hideHandler && q[i].hideHandler.refresh();
        }

        force && force.afterResize && force.afterResize();
        Game.currentGame && Game.currentGame.afterResize();
    },

    setupResolution: function () {
        // Pass true to enable retina display, on Android disabled by default to improve performance
        // cc.view.enableRetina(cc.sys.os === cc.sys.OS_IOS ? true : false);
        cc.view.enableRetina(true);

        // Disable auto full screen on baidu and wechat, you might also want to eliminate sys.BROWSER_TYPE_MOBILE_QQ
        // if (cc.sys.isMobile &&
        //     cc.sys.browserType !== cc.sys.BROWSER_TYPE_BAIDU &&
        //     cc.sys.browserType !== cc.sys.BROWSER_TYPE_WECHAT) {
        //     cc.view.enableAutoFullScreen(true);
        // }

        // Adjust viewport meta
        cc.view.adjustViewPort(true);

        // Setup the resolution policy and design resolution size

        // Uncomment the following line to set a fixed orientation for your game
        cc.view._orientationChanging = false;

        // The game will be resized when browser size change by custom event listener
        cc.view.resizeWithBrowserSize(false);
        cc.view.__resizeWithBrowserSize = true;
        if (cc.sys.isMobile) {
            window.removeEventListener("orientationchange", cc.view._orientationChange);
        }

        var counter = 0;
        var timeoutHandler = undefined;
        var timeouts = [50, 100, 200, 300, 500, 1000, 2000, 4000];

        var scheduleCheck = function () {
            if (counter === timeouts.length) {
                timeoutHandler = undefined;
                return;
            }

            if (timeoutHandler) {
                cleverapps.timeouts.clearTimeout(timeoutHandler);
            }

            timeoutHandler = cleverapps.timeouts.setTimeout(function () {
                if (!cc.sizeEqualToSize(this.currentFrameSize, this.getFrameSize())) {
                    cc.view._resizeEvent();
                }

                counter++;
                scheduleCheck();
            }.bind(this), timeouts[counter]);
        }.bind(this);

        var resizeHandler = function () {
            cc.view._resizeEvent();

            counter = 0;
            scheduleCheck();
        };

        window.addEventListener("resize", resizeHandler, false);
        window.addEventListener("orientationchange", resizeHandler, false);

        cc.view.setResizeCallback(this.setupDesignResolution.bind(this));

        this.setupResolutionScale();
        this.setupDesignResolution();
    },

    checkWebpSupport: function (done) {
        if (cleverapps.config.webpSupport !== undefined) {
            done(cleverapps.config.webpSupport);
            return;
        }

        done = cleverapps.extendFunc(done, function (result) {
            cleverapps.config.webpSupport = result;
            this._super(result);
        });

        // try off for user on safari https://crm.cleverappssg.com/#/folder/Inbox:Wondermerge/user/5939924406133403
        // on Chrome works on Safari fails
        if (cleverapps.user.id === "5939924406133403") {
            done(false);
            return;
        }
 
        // https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
        var kTestImages = {
            lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
            lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
            alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA=="
        };

        var checkWebpFeature = function (feature, callback) {
            var img = new Image();
            img.onload = function () {
                var result = (img.width > 0) && (img.height > 0);
                callback(feature, result);
            };
            img.onerror = function () {
                callback(feature, false);
            };
            img.src = "data:image/webp;base64," + kTestImages[feature];
        };

        var count = 0;
        var supported = 0;

        var callback = cleverapps.waitNoMore(5000, cleverapps.once(function () {
            done(supported === Object.keys(kTestImages).length);
        }));

        Object.keys(kTestImages).forEach(function (feature) {
            checkWebpFeature(feature, function (feature, result) {
                if (!result) {
                    console.log("webp " + feature + " not supported");
                }

                count++;
                if (result) {
                    supported++;
                }

                if (!result || count === Object.keys(kTestImages).length) {
                    callback();
                }
            });
        });
    }
};
