/**
 * Created by iamso on 16.08.2021
 */

var RestoreProgress = function () {
    this.onShow = function () {};
    this.onHide = function () {};
};

RestoreProgress.prototype.isAvailable = function () {
    if (cleverapps.config.adminMode || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return false;
    }

    if (!cleverapps.social.isInitialized() || cleverapps.flags.nologin || cleverapps.social.isLoggedIn()) {
        return false;
    }

    if (cleverapps.config.type === "merge") {
        return cleverapps.user.level < 2;
    }

    return cleverapps.user.getFloatLevel() < 0.5;
};

RestoreProgress.prototype.isVisible = function () {
    if (cleverapps.environment.isComicsScene()) {
        return true;
    }

    if (cleverapps.meta.getType() === Metha.LOOP) {
        return true;
    }

    if (!cleverapps.environment.isMainScene() && !cleverapps.environment.isGameScene()) {
        return false;
    }

    var window = cleverapps.windows.currentWindow();

    if (!cleverapps.meta.isFocused() && !window) {
        return ["board", "solitaire", "differences"].indexOf(cleverapps.config.type) !== -1 && cleverapps.environment.isGameScene();
    }

    if (window && (!window.closed || cleverapps.windows.list.length > 1)) {
        return window instanceof ForceView || window instanceof DialogueView;
    }

    if (cleverapps.scenes.transition || cleverapps.meta.focus === "ChangeOutcome") {
        return false;
    }

    return ["merge", "match3"].indexOf(cleverapps.config.type) !== -1;
};

RestoreProgress.prototype.update = function () {
    if (this.disabled) {
        return;
    }

    if (!this.isAvailable()) {
        this.disabled = true;
        this.onHide();
        return;
    }

    if (this.isVisible()) {
        this.onShow();
    } else {
        this.onHide();
    }
};

RestoreProgress.prototype.restore = function () {
    if (cleverapps.windows.currentWindow() instanceof FbConnectWindow) {
        return;
    }

    if (cleverapps.meta.isFocused()) {
        cleverapps.meta.lock();

        new FbConnectWindow({
            restoreProgress: true,
            onSuccess: function () {
                cleverapps.meta.unlock();
            },
            onFailure: function () {
                cleverapps.meta.unlock();
            }
        });
        return;
    }

    cleverapps.meta.display({
        focus: "RestoreProgress",
        action: function (f) {
            new FbConnectWindow({
                restoreProgress: true,
                onSuccess: function () {},
                onFailure: function () {}
            });
            cleverapps.meta.onceNoWindowsListener = f;
        }
    });
};
